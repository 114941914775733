import React, { useContext } from "react";
import sld from "../../images/sld/Rectangle.svg";

import Ecc from "../../components/PVHYBRIDIZATION/Ecc";
import Busbar from "../../components/ReusableSvg/Busbar";
import Utility from "../../components/PVHYBRIDIZATION/Utility";
import Genset from "../../components/PVHYBRIDIZATION/Genset";
import PV1 from "../../components/PVHYBRIDIZATION/PV1";
import CHP from "../../components/PVHYBRIDIZATION/CHP";
import Load from "../../components/ReusableSvg/Load";

import { DesignAssetContext } from "../CustomHooks/ContextApi";

const PVHybridizationSLD = () => {
  const { CBAssetList, sldLoadCompleted } = useContext(DesignAssetContext);
  return (
    <div
      id="SLD-Image"
      className="col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0"
    >
      <svg viewBox="0 0 1300 800">
        <g>
          <svg x="0" y="0" viewBox="0 0 1300 800">
            <g id="grid">
              <svg>
                <image href={sld} x="0" y="0" />
              </svg>
            </g>

            <g
              id="components-sld"
              style={sldLoadCompleted ? {} : { display: "none" }}
            >
              <Ecc />
              <Busbar secondBusbar={false} />
              <Utility />
              <Genset />
              <PV1 />
              {CBAssetList[6].uiData.status && <CHP />}
              <Load />
            </g>
          </svg>
        </g>
      </svg>
    </div>
  );
};

export default PVHybridizationSLD;
