import React from "react";

import seConfigure from "../../images/se-configure.png";
import seDeploy from "../../images/se-iPC.jpg";
import sePLC from "../../images/se-plc.jpg";
import seVijeo from "../../images/se-vijeo.jpg";

import embLabels from "../../config/emb-labels-en.json";
import isTouchDevice from "../SE-Component/Tooltip";

const Services = ({
  UserScope,
  gridSetting,
  unlockDeploy,
  siteType,
  clientType,
  architectureType,
  keyExpert,
  keyOperations,
  emaSiteStatus,
  versions,
  createSiteInEMA,
  deleteSiteInEMA,
  downloadIpcFile,
  handleSerialNoPopUp,
  downloadHMI,
}) => {
  return (
    <div className="row justify-content-center">
      {gridSetting.remoteMonitoringControl && (
        <div className="col-md-3 padding-left  remove-padding-right ">
          <div className="deploy-border padding-1px se-white-background deploy-card-min-height">
            <div className="labelCircle-contanier">
              <img
                src={seConfigure}
                className="img-fluid img-height-deploy"
                alt="Configure EMA"
              />
              <span className="labelCircle-deploy">
                {unlockDeploy ||
                ([embLabels.ISLANDABLE, embLabels.GRID_CONNECTED].includes(
                  siteType
                )
                  ? keyExpert.expertStatus
                  : [
                      embLabels.PV_HYBRIDIZATION,
                      embLabels.ISLANDABLE_GSX,
                    ].includes(siteType)
                  ? keyOperations.operationsStatus
                  : false) ? (
                  <se-icon size="large">action_lock_off</se-icon>
                ) : (
                  <se-icon size="large">action_lock_on</se-icon>
                )}
              </span>
            </div>

            <div className="col-md-12 mt-2 min-height-88">
              <span className="font-block-header">
                {siteType === embLabels.PV_HYBRIDIZATION ||
                (siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS)
                  ? "Remote monitoring  / cloud      services"
                  : "EcoStruxure Microgrid Advisor cloud services"}
              </span>
              <se-tooltip
                action={isTouchDevice() ? "click" : "hover"}
                position="top"
              >
                <se-icon
                  option="raised"
                  color="secondary"
                  size="small"
                  slot="tooltip"
                >
                  information_circle
                </se-icon>
                <se-tooltip-content>
                  Click on configure cloud services
                </se-tooltip-content>
              </se-tooltip>
            </div>

            <div className="col-md-12 deploy-license-min-height">
              <span
                className={
                  [embLabels.GRID_CONNECTED, embLabels.ISLANDABLE].includes(
                    siteType
                  )
                    ? keyExpert.expertStatus
                      ? "dot-green"
                      : keyExpert.expertExpired
                      ? "dot-red"
                      : "dot-red"
                    : [
                        embLabels.PV_HYBRIDIZATION,
                        embLabels.ISLANDABLE_GSX,
                      ].includes(siteType)
                    ? keyOperations.operationsStatus
                      ? "dot-green"
                      : keyOperations.operationExpired
                      ? "dot-red"
                      : "dot-red"
                    : "dot-red"
                }
              ></span>
              <span>
                {[embLabels.GRID_CONNECTED, embLabels.ISLANDABLE].includes(
                  siteType
                )
                  ? keyExpert.expertStatus
                    ? " License key has been validated"
                    : keyExpert.expertKeyInvalid
                    ? " License key is invalid"
                    : keyExpert.expertExpired
                    ? " License key has expired"
                    : " Licence key missing"
                  : [
                      embLabels.PV_HYBRIDIZATION,
                      embLabels.ISLANDABLE_GSX,
                    ].includes(siteType)
                  ? keyOperations.operationsStatus
                    ? " License key has been validated"
                    : keyOperations.operationKeyInvalid
                    ? " License key is invalid"
                    : keyOperations.operationExpired
                    ? " License key has expired"
                    : " Licence key missing"
                  : " Licence key missing"}
              </span>
            </div>

            <div className="col-md-12 text-right mt-2 align-items-end">
              {emaSiteStatus === false ? (
                <se-button
                  size="small"
                  disabled={
                    unlockDeploy ||
                    UserScope.access.unlockBypass === true ||
                    ([embLabels.GRID_CONNECTED, embLabels.ISLANDABLE].includes(
                      siteType
                    )
                      ? keyExpert.expertStatus
                      : [
                          embLabels.PV_HYBRIDIZATION,
                          embLabels.ISLANDABLE_GSX,
                        ].includes(siteType)
                      ? keyOperations.operationsStatus
                      : false)
                      ? false
                      : true
                  }
                  onClick={createSiteInEMA}
                >
                  Configure Cloud services{" "}
                </se-button>
              ) : (
                <se-button
                  size="small"
                  disabled={
                    unlockDeploy ||
                    UserScope.access.unlockBypass === true ||
                    ([embLabels.GRID_CONNECTED, embLabels.ISLANDABLE].includes(
                      siteType
                    )
                      ? keyExpert.expertStatus
                      : [
                          embLabels.PV_HYBRIDIZATION,
                          embLabels.ISLANDABLE_GSX,
                        ].includes(siteType)
                      ? keyOperations.operationsStatus
                      : false)
                      ? false
                      : true
                  }
                  onClick={deleteSiteInEMA}
                >
                  Delete Cloud services{" "}
                </se-button>
              )}
            </div>
          </div>
        </div>
      )}

      {gridSetting.remoteMonitoringControl && (
        <div className="col-md-3 padding-left remove-padding-right ">
          <div className="deploy-border padding-1px se-white-background deploy-card-min-height">
            <div className="labelCircle-contanier">
              <img
                src={seDeploy}
                className="img-fluid img-height-deploy"
                alt="Deploy"
              />
              <span className="labelCircle-deploy">
                {unlockDeploy ||
                ([embLabels.ISLANDABLE, embLabels.GRID_CONNECTED].includes(
                  siteType
                )
                  ? keyExpert.expertStatus
                  : [
                      embLabels.PV_HYBRIDIZATION,
                      embLabels.ISLANDABLE_GSX,
                    ].includes(siteType)
                  ? keyOperations.operationsStatus
                  : false) ? (
                  <se-icon size="large">action_lock_off</se-icon>
                ) : (
                  <se-icon size="large">action_lock_on</se-icon>
                )}
              </span>
            </div>

            <div className="col-md-12 mt-2 min-height-88">
              <span className="font-block-header">
                {siteType === embLabels.PV_HYBRIDIZATION &&
                siteType === embLabels.ISLANDABLE_GSX &&
                architectureType === embLabels.PV_GENSET_ATS
                  ? "Remote monitoring communication gateway / EMA controller"
                  : "EcoStruxure Microgrid Advisor Controller (Harmony iPC)"}
              </span>
              <se-tooltip
                action={isTouchDevice() ? "click" : "hover"}
                position="top"
              >
                <se-icon
                  option="raised"
                  color="secondary"
                  size="small"
                  slot="tooltip"
                >
                  information_circle
                </se-icon>
                <se-tooltip-content>
                  Download « .zip » configuration file on your computer & import
                  it into EMA controller Via EMA controller interface.
                  <br></br>
                  <br></br>
                  For more details please refer to System Installation Guide.
                </se-tooltip-content>
              </se-tooltip>
            </div>

            <div className="col-md-12 deploy-license-min-height">
              <span
                className={
                  [embLabels.GRID_CONNECTED, embLabels.ISLANDABLE].includes(
                    siteType
                  )
                    ? keyExpert.expertStatus
                      ? "dot-green"
                      : keyExpert.expertExpired
                      ? "dot-red"
                      : "dot-red"
                    : [
                        embLabels.PV_HYBRIDIZATION,
                        embLabels.ISLANDABLE_GSX,
                      ].includes(siteType)
                    ? keyOperations.operationsStatus
                      ? "dot-green"
                      : keyOperations.operationExpired
                      ? "dot-red"
                      : "dot-red"
                    : "dot-red"
                }
              ></span>
              <span>
                {[embLabels.GRID_CONNECTED, embLabels.ISLANDABLE].includes(
                  siteType
                )
                  ? keyExpert.expertStatus
                    ? " License key has been validated"
                    : keyExpert.expertKeyInvalid
                    ? " License key is invalid"
                    : keyExpert.expertExpired
                    ? " License key has expired"
                    : " Licence key missing"
                  : [
                      embLabels.PV_HYBRIDIZATION,
                      embLabels.ISLANDABLE_GSX,
                    ].includes(siteType)
                  ? keyOperations.operationsStatus
                    ? " License key has been validated"
                    : keyOperations.operationKeyInvalid
                    ? " License key is invalid"
                    : keyOperations.operationExpired
                    ? " License key has expired"
                    : " Licence key missing"
                  : " Licence key missing"}
              </span>
            </div>

            <div className="col-md-12 text-right mt-2">
              <se-button
                disabled={
                  unlockDeploy ||
                  UserScope.access.unlockBypass === true ||
                  ([embLabels.GRID_CONNECTED, embLabels.ISLANDABLE].includes(
                    siteType
                  )
                    ? keyExpert.expertStatus
                    : [
                        embLabels.PV_HYBRIDIZATION,
                        embLabels.ISLANDABLE_GSX,
                      ].includes(siteType)
                    ? keyOperations.operationsStatus
                    : false)
                    ? false
                    : true
                }
                size="small"
                onClick={downloadIpcFile}
              >
                Download configuration
              </se-button>
            </div>
          </div>
        </div>
      )}

      <div className="col-md-3 padding-left remove-padding-right ">
        <div className="deploy-border padding-1px se-white-background deploy-card-min-height">
          <div className="labelCircle-contanier">
            <img
              src={sePLC}
              alt="PLC Project for Machine Expert"
              className="img-fluid img-height-deploy"
            />
            <span className="labelCircle-deploy">
              {unlockDeploy ||
              ([
                embLabels.ISLANDABLE,
                embLabels.PV_HYBRIDIZATION,
                embLabels.ISLANDABLE_GSX,
              ].includes(siteType)
                ? keyOperations.operationsStatus
                : [embLabels.GRID_CONNECTED].includes(siteType)
                ? keyExpert.expertStatus
                : false) ? (
                <se-icon size="large">action_lock_off</se-icon>
              ) : (
                <se-icon size="large">action_lock_on</se-icon>
              )}
            </span>
          </div>

          <div className="col-md-12 mt-2 min-height-65">
            {siteType === embLabels.GRID_CONNECTED ? (
              <span className="font-block-header">
                M251 PLC Communication Gateway
              </span>
            ) : (
              <span className="font-block-header">
                <span className="font-block-header">
                  EcoStruxure Microgrid Operation - Medium Controller (M251 PLC)
                </span>
              </span>
            )}
            <se-tooltip
              action={isTouchDevice() ? "click" : "hover"}
              position="right"
            >
              <se-icon
                option="raised"
                color="secondary"
                size="small"
                slot="tooltip"
              >
                information_circle
              </se-icon>
              <se-tooltip-content>
                <p>
                  1. Download ".zip" file on your computer
                  <br></br>
                  <br></br>
                  2. Unzip in a selected folder
                  <br></br>
                  <br></br>
                  3. Open Ecostruxure Machine Expert Tool
                  <br></br>
                  <br></br>
                  4. Open .py file :<br></br>
                  In Ecostuxure machine tool click on "tools/scripting/execute
                  script file"
                  <br></br>
                  Select ".py" file in the unziped folder
                  <br></br>
                  Execute
                  <br></br>⇒ the application automatically built
                  <br></br>
                  <br></br>
                  5. Build the program in EME by clicking on "Build/build"
                  <br></br>
                  <br></br>
                  6. Transfer the program to the controller with USB interface.
                  (use BMXCAUSB018 cable)
                  <br></br>
                  Click on "online/login". Select the controller detected.
                  <br></br>
                  <br></br>
                  For more details please refer to System Installation Guide.
                </p>
              </se-tooltip-content>
            </se-tooltip>
          </div>

          <div className="col-md-12 deploy-version-min-height">
            <span>
              EcoStruxure Machine Expert version {versions.plcVersion}
            </span>
          </div>

          <div className="col-md-12">
            <span
              className={
                [embLabels.GRID_CONNECTED].includes(siteType)
                  ? keyExpert.expertStatus
                    ? "dot-green"
                    : keyExpert.expertExpired
                    ? "dot-red"
                    : "dot-red"
                  : [
                      embLabels.ISLANDABLE,
                      embLabels.PV_HYBRIDIZATION,
                      embLabels.ISLANDABLE_GSX,
                    ].includes(siteType)
                  ? keyOperations.operationsStatus
                    ? "dot-green"
                    : keyOperations.operationExpired
                    ? "dot-red"
                    : "dot-red"
                  : "dot-red"
              }
            ></span>
            <span>
              {[embLabels.GRID_CONNECTED].includes(siteType)
                ? keyExpert.expertStatus
                  ? " License key has been validated"
                  : keyExpert.expertKeyInvalid
                  ? " License key is invalid"
                  : keyExpert.expertExpired
                  ? " License key has expired"
                  : " Licence key missing"
                : [
                    embLabels.PV_HYBRIDIZATION,
                    embLabels.ISLANDABLE,
                    embLabels.ISLANDABLE_GSX,
                  ].includes(siteType)
                ? keyOperations.operationsStatus
                  ? " License key has been validated"
                  : keyOperations.operationKeyInvalid
                  ? " License key is invalid"
                  : keyOperations.operationExpired
                  ? " License key has expired"
                  : " Licence key missing"
                : " Licence key missing"}
            </span>
          </div>

          <div className="col-md-12 text-right mt-2">
            <se-button
              size="small"
              disabled={
                unlockDeploy ||
                UserScope.access.unlockBypass === true ||
                (siteType === embLabels.GRID_CONNECTED
                  ? keyExpert.expertStatus
                  : [
                      embLabels.ISLANDABLE,
                      embLabels.PV_HYBRIDIZATION,
                      embLabels.ISLANDABLE_GSX,
                    ].includes(siteType)
                  ? keyOperations.operationsStatus
                  : false)
                  ? false
                  : true
              }
              onClick={handleSerialNoPopUp}
            >
              Download Program <se-icon size="small">action_download</se-icon>
            </se-button>
          </div>
        </div>
      </div>

      {clientType === embLabels.GENERIC_PROJECT &&
      (siteType === embLabels.GRID_CONNECTED ||
        gridSetting.userInterface === false) ? (
        ""
      ) : (
        <div className="col-md-3 padding-left remove-padding-right">
          <div className="deploy-border padding-1px se-white-background deploy-card-min-height">
            <div className="labelCircle-contanier">
              <img
                src={seVijeo}
                alt="Download"
                className="img-fluid img-height-deploy"
              />
              <span className="labelCircle-deploy">
                {unlockDeploy || keyOperations.operationsStatus ? (
                  <se-icon size="large">action_lock_off</se-icon>
                ) : (
                  <se-icon size="large">action_lock_on</se-icon>
                )}
              </span>
            </div>

            <div className="col-md-12 mt-2 min-height-65">
              <span className="font-block-header">
                EcoStruxure Microgrid Operation - Medium HMI (Magelis HMI)
              </span>
              <se-tooltip
                action={isTouchDevice() ? "click" : "hover"}
                position="left"
              >
                <se-icon
                  option="raised"
                  color="secondary"
                  size="small"
                  slot="tooltip"
                >
                  information_circle
                </se-icon>
                <se-tooltip-content>
                  Download « .zip » folder file on your computer & unzip the
                  folder Open Vijeo designer tool & Open .vdz file in the tool
                  and download into the HMI.
                  <br></br>
                  <br></br>
                  For more details please refer to System Installation Guide.
                </se-tooltip-content>
              </se-tooltip>
            </div>

            <div className="col-md-12 deploy-version-min-height">
              <span>Vijeo designer version {versions.hmiVersion}</span>
            </div>

            <div className="col-md-12">
              <span
                className={
                  keyOperations.operationsStatus
                    ? "dot-green"
                    : keyOperations.operationExpired
                    ? "dot-red"
                    : "dot-red"
                }
              ></span>
              <span>
                {keyOperations.operationsStatus
                  ? " License key has been validated"
                  : keyOperations.operationKeyInvalid
                  ? " License key is invalid"
                  : keyOperations.operationExpired
                  ? " License key has expired"
                  : " Licence key missing"}
              </span>
            </div>

            <div className="col-md-12 text-right mt-2">
              <se-button
                size="small"
                disabled={
                  keyOperations.operationsStatus ||
                  unlockDeploy ||
                  UserScope.access.unlockBypass === true
                    ? false
                    : true
                }
                onClick={downloadHMI}
              >
                Download Program <se-icon size="small">action_download</se-icon>
              </se-button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;
