import React, { useState, useEffect } from "react";

const InviteUser = ({
  userRoles,
  addUserPopUp,
  deleteUserPopUp,
  closeAddUser,
  addUserToProjectList,
}) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("Email Required.");
  const [mandatoryMsgDisplay, setMandatoryMsgDisplay] = useState(false);
  const [role, setRole] = useState(0);
  const [, setRolePermission] = useState("");
  const [, setRoleName] = useState("");
  const [rolesListInside, setRolesListInside] = useState([]);

  useEffect(() => {
    setRolePermission("");
    setRole(0);
    setRoleName("");
    setEmailAddress("");
    let rolesListInsideNew = [];
    userRoles.rolesList.forEach(role => {
      role.permission = userRoles.rolesPermission[role.role];
      rolesListInsideNew.push(role);
    });
    setRolesListInside(rolesListInsideNew);
  }, [userRoles]);

  const handleEmailChange = e => setEmailAddress(e.target.value);

  const handleRoleChange = e => {
    setRole(e.target.value);
    const rolePermission = rolesListInside.filter(
      role => role.tag === e.target.value
    )[0].permission;
    setRolePermission(rolePermission);
  };

  const addUser = () => {
    const patternCheckRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim;

    if (patternCheckRegex.test(emailAddress)) {
      addUserPopUp(emailAddress, role);
      setEmailAddress("");
      setRole(0);
      setMandatoryMsgDisplay(false);
    } else {
      setEmailAddress("");
      setRole(0);
      setEmailErrorMessage("Enter Valid Email.");
      setMandatoryMsgDisplay(true);
    }
  };

  return (
    <se-dialog
      id="dialog-complex"
      size="medium"
      open={userRoles.addUserPopUpstatus}
      can-backdrop="false"
    >
      <se-dialog-header>
        {userRoles.type === "addUserToProject"
          ? "Invite User(s) to the Project"
          : "Invite User(s)"}
      </se-dialog-header>

      <se-dialog-content className="se-dialog-content">
        <se-loading loading={userRoles.addUserPopUpLoading}></se-loading>
        <se-loading
          loading={userRoles.rolesList.length === 0 ? true : false}
        ></se-loading>

        <div className="row">
          <div className="col-md-12">
            <div className="form-group row mb-0">
              <label
                htmlFor="emailaddress"
                className="col-sm-9 col-form-label ml-3"
              >
                Enter e-mail address
              </label>
              <div className="col-md-2 col-sm-2 col-lg-2 text-right ">
                <se-button
                  option="flat"
                  size="nano"
                  disabled={
                    emailAddress !== "" &&
                    role !== 0 &&
                    userRoles.addUserPopUpList.length !== 5
                      ? false
                      : true
                  }
                  onClick={addUser}
                >
                  Add
                </se-button>
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-12">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control form-control-sm"
                  id="emailaddress"
                  name="emailAddress"
                  maxLength="50"
                  value={emailAddress}
                  onChange={handleEmailChange}
                />
                <div
                  className="invalid-feedback-custom"
                  style={
                    !emailAddress && mandatoryMsgDisplay
                      ? {}
                      : { display: "none" }
                  }
                >
                  {emailErrorMessage}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group ">
              <div className="col-sm-12">
                <select
                  className="form-control form-control-sm "
                  name="role"
                  id="roleSelection"
                  value={role}
                  onChange={handleRoleChange}
                >
                  <option value="0">Select the role</option>
                  {rolesListInside.map(data => (
                    <option key={data.id} value={data.tag}>
                      {" "}
                      Invited as {data.role} - "{data.permission}"
                    </option>
                  ))}
                </select>
                <div
                  className="invalid-feedback-custom"
                  style={
                    !role !== 0 && mandatoryMsgDisplay
                      ? {}
                      : { display: "none" }
                  }
                >
                  Role is required.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={
            userRoles.addUserPopUpList.length === 0 ? { display: "none" } : {}
          }
        >
          <div className="col-md-12 col-sm-12 col-lg-12 ">
            <se-divider></se-divider>
          </div>
        </div>
        {userRoles.addUserPopUpList.length !== 0 && (
          <div className="row mt-1">
            <div className="col-md-12 col-sm-12 col-lg-12 ">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Email Address</th>
                    <th>Invites as</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userRoles.addUserPopUpList.map(userData => (
                    <tr key={userData.email}>
                      <td>{userData.email}</td>
                      <td>{userData.role}</td>
                      <td>
                        <span className="pointer">
                          <se-icon
                            size="small"
                            onClick={() => deleteUserPopUp(userData.email)}
                          >
                            action_delete
                          </se-icon>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </se-dialog-content>
      <se-dialog-footer>
        <se-button option="outline" onClick={closeAddUser}>
          Cancel
        </se-button>

        <se-button
          option="flat"
          disabled={userRoles.addUserPopUpList.length === 0 ? true : false}
          onClick={addUserToProjectList}
        >
          Send Invitation
        </se-button>
      </se-dialog-footer>
    </se-dialog>
  );
};

export default InviteUser;
