import React from "react";

const SLDEcc = ({ totalDer }) => (
  <svg>
    {" "}
    <g id="ECC">
      {" "}
      <svg>
        {" "}
        <g
          id="ECC"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          {" "}
          <g id="ECC" transform="translate(-532.000000, -514.000000)">
            {" "}
            <g
              id="SLD/Infos/Microgrid_Background"
              transform="translate(532.000000, 514.000000)"
            >
              {" "}
              <rect
                id="Rectangle-Copy-24"
                stroke="#333333"
                stroke-width="3"
                x="360"
                y="269"
                width={totalDer === 4 ? "635" : "530"}
                height="180"
                rx="16"
              />{" "}
              <text
                id="AAA"
                font-family="Nunito-Regular, Nunito"
                font-size="18"
                font-weight="normal"
                fill="#333333"
              >
                {" "}
                <tspan x="392" y="310">
                  Low Voltage{" "}
                </tspan>
                /{" "}
                <tspan x="392" y="330">
                  Switchboard
                </tspan>{" "}
              </text>{" "}
            </g>{" "}
          </g>{" "}
        </g>{" "}
      </svg>{" "}
    </g>{" "}
  </svg>
);

export default SLDEcc;
