import React from "react";

import PowerMeterDetails from "./PowerMeterDetails";
import CBConfigDetails from "./CBConfigDetails";

import cbImage from "../../images/CB.png";
import powerMeter from "../../images/power-meter-image.png";
import embLabels from "../../config/emb-labels-en.json";

import isTouchDevice from "../SE-Component/Tooltip";

const CircuitBreakerDetails = ({
  siteType,
  architectureType,
  selectedValues,
}) => {
  return (
    <div name="CB" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 se-white-background">
        <div className="row">
          <div className="col-md-8">
            <img
              src={
                siteType === embLabels.PV_HYBRIDIZATION ||
                (siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS)
                  ? powerMeter
                  : cbImage
              }
              className="asset-image-icon"
              alt={
                siteType === embLabels.PV_HYBRIDIZATION ||
                (siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS)
                  ? "Power Meter"
                  : "circuit breaker"
              }
            />

            <span className="align-top line-height-30px">
              {siteType === embLabels.PV_HYBRIDIZATION ||
              (siteType === embLabels.ISLANDABLE_GSX &&
                architectureType === embLabels.PV_GENSET_ATS)
                ? "Power Meter"
                : "Circuit Breaker"}
            </span>
          </div>
          <div
            className={`col-md-4 ${
              siteType === embLabels.PV_HYBRIDIZATION ||
              (siteType === embLabels.ISLANDABLE_GSX &&
                architectureType === embLabels.PV_GENSET_ATS)
                ? "hidden"
                : ""
            }`}
          >
            <span className="float-right">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                disabled
              >
                Remove
              </button>
            </span>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-sm-12 pt-2 se-grey-background se-border">
        <div className="row">
          <div className="col-md-3">
            <img
              src={
                siteType === embLabels.PV_HYBRIDIZATION ||
                (siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS)
                  ? powerMeter
                  : cbImage
              }
              className="asset-image-display"
              alt={
                siteType === embLabels.PV_HYBRIDIZATION ||
                (siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS)
                  ? "Power Meter"
                  : "circuit breaker"
              }
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12 bold">Schneider Electric</div>
            </div>
            <div className="row">
              <div className="col-md-9">
                {siteType === embLabels.PV_HYBRIDIZATION ||
                (siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS)
                  ? "Power Meter"
                  : "Circuit Breaker"}
              </div>
              <div className="col-md-3 text-right">
                <se-tooltip
                  position="left"
                  action={isTouchDevice() ? "click" : "hover"}
                >
                  <se-icon option="raised" color="secondary" slot="tooltip">
                    information_circle
                  </se-icon>

                  <se-tooltip-content>
                    {architectureType === embLabels.PV_GENSET_ATS
                      ? selectedValues.selectedAssetPM === 3
                        ? "PM5563"
                        : "A9MEM3455"
                      : siteType === embLabels.PV_HYBRIDIZATION
                      ? "PM3250"
                      : "PowerPact NSX"}
                  </se-tooltip-content>
                </se-tooltip>
              </div>
              <div className="col-md-12">
                {architectureType === embLabels.PV_GENSET_ATS
                  ? selectedValues.selectedAssetPM === 3
                    ? "PM5563"
                    : "A9MEM3455"
                  : siteType === embLabels.PV_HYBRIDIZATION
                  ? "PM3250"
                  : "PowerPact NSX"}
              </div>
            </div>
          </div>
        </div>
      </div>

      {(siteType === embLabels.PV_HYBRIDIZATION ||
        (siteType === embLabels.ISLANDABLE_GSX &&
          architectureType === embLabels.PV_GENSET_ATS)) && (
        <PowerMeterDetails />
      )}

      {siteType === embLabels.ISLANDABLE && <CBConfigDetails />}
    </div>
  );
};

export default CircuitBreakerDetails;
