import React, {
  useState,
  useEffect,
  useReducer,
  useRef,
  useCallback,
} from "react";
import _ from "lodash";

import embLabels from "../config/emb-labels-en.json";
import ACTIONS from "./DesignAssets/Design_Asset_Actions.json";

//* reducer import

import {
  initialCBAssetList,
  CBAssetListReducer,
} from "./DesignAssets/Reducers/CBAssetListReducer";
import {
  initialCBEVList,
  CBEVListReducer,
} from "./DesignAssets/Reducers/CBEVListReducer";
import {
  initialCBLoadList,
  CBLoadListReducer,
} from "./DesignAssets/Reducers/CBLoadListReducer";
import {
  initialPVDetails,
  PVDetailsReducer,
} from "./DesignAssets/Reducers/PVDetailsReducer";

import {
  initialCount,
  countReducer,
} from "./DesignAssets/Reducers/CountReducer";

//* custom hook

import useMountEffect from "./CustomHooks/useMountEffect";
import userScope from "./CustomHooks/userScope";

//* context api

import {
  DesignAssetProvider,
  AssetBlockContextProvider,
} from "./CustomHooks/ContextApi";
import Snackbar from "./SE-Component/Snackbar";
import AlertIcon from "./SE-Component/AlertIcon";
import Confirm from "./SE-Component/Confirm";

//* child import

import IslandableSLD from "./DesignAssets/IslandableSLD";
import GridConnectedRosette from "./DesignAssets/GridConnectedRosette";
import PVHybridizationSLD from "./DesignAssets/PVHybridizationSLD";
import PvGensetAtsSLD from "./DesignAssets/PvGensetAtsSLD";
import AddEquipment from "./DesignAssets/AddEquipment";
import DesignAssetDescription from "./DesignAssets/DesignAssetDescription";
import UtilityAsset from "./DesignAssets/UtilityAsset";
import GensetAsset from "./DesignAssets/GensetAsset";
import GensetAssetPvHyb from "./DesignAssets/GensetAssetPvHyb";
import BatteryAsset from "./DesignAssets/BatteryAsset";
import PVAsset from "./DesignAssets/PVAsset";
import CHPAsset from "./DesignAssets/CHPAsset";
import CircuitBreakerDetails from "./DesignAssets/CircuitBreakerDetails";
import LoadAsset from "./DesignAssets/LoadAsset";
import LoadCB from "./DesignAssets/LoadCB";
import EvCluster from "./DesignAssets/EvCluster";
import EvCB from "./DesignAssets/EvCB";
import NoAssetSelected from "./DesignAssets/NoAssetSelected";
import AssetSelectionDER from "../components/AssetSelectionDer";
import AssetSelectionPV from "../components/AssetSelectionPV";
import AddPvModals from "../components/AddPvModals";

//*api call
import ApiSession from "../api/ApiSession";
const apiSession = new ApiSession();

const alphanumericRegWithHyphen = /^[-_0-9a-zA-Z]+$/;

const DesignAsset = props => {
  //*state hooks

  const [UserScope] = userScope();
  const [loading, setLoading] = useState(false);
  const [displayPage, setDisplayPage] = useState(false);
  const [sldLoadCompleted, setSldLoadCompleted] = useState(false);
  const [useCaseValidGenset, setUseCaseValidGenset] = useState(false);
  const [deleteOneDER, setDeleteOneDER] = useState(false);
  const [defaultConfigurationStatus, setDefaultConfigurationStatus] =
    useState(false);
  const [siteCreated, setSiteCreated] = useState(false);

  const [siteType, setSiteType] = useState("");
  const [clientType, setClientType] = useState("");
  const [architectureType, setArchitectureType] = useState(null);

  const [defaultPreFillData, setDefaultPreFillData] = useState([]);
  const [availableDer, setAvailableDer] = useState([]);

  const [batteryCommunicationData, setBatteryCommunicationData] = useState({
    ipaddress: "",
    subnetmask: "",
    defaultGateway: "",
    modbusSlaveId: "",
  });
  const [evCommunicationData, setEvCommunicationData] = useState({
    ipaddress: "",
    subnetmask: "",
    defaultGateway: "",
    modbusSlaveId: "",
  });

  const [powerMeter, setPowerMeter] = useState({
    assetId: "",
    assetType: "Power Meter",
    manufacturer: "",
    communicationModel: "PM3250",
    imageLink: null,
    assetInfo: "",
    assetEnabled: false,
    assetProperties: [],
    assetAttributes: [],
    comments: null,
  });
  const [communicationState, setCommunicationState] = useState({
    ifeIpAddress: "",
    ifeSubnetMask: "",
    ifeGateway: "",
    ifeIpAddress2: "",
    ifeSubnetMask2: "",
    ifeGateway2: "",
    ifeIpAddress3: "",
    ifeSubnetMask3: "",
    ifeGateway3: "",
    ifeIpAddress4: "",
    ifeSubnetMask4: "",
    ifeGateway4: "",
  });
  const [detailsBlock, setDetailsBlock] = useState({
    cbAssetDetailsBlock: false,
    cbLoadDetailsBlock: false,
    cbEvDetailsBlock: false,
    loadDetailsBlock: false,
    evDetailsBlock: false,
    assetUtilityDetailsBlock: false,
    assetGensetDetailsBlock: false,
    assetBatteryDetailsBlock: false,
    assetPvDetailsBlock: false,
    assetCHPDetailsBlock: false,
  });
  const [selectedValues, setSelectedValues] = useState({
    selectedAsset: "",
    selectedAssetCB: "",
    selectedAssetPM: "",
    selectedLoad: "",
    selectedLoadCB: "",
    selectedEv: "",
    selectedEvCB: "",
    selectedPv: "",
    selectSubPv: "",
  });

  const [configData, setConfigData] = useState({
    utilityConfigData: {
      name: "",
      description: "",
      utilityCO2: "",
      maximum_available_power: 0,
    },
    gensetConfigData: {
      name: "",
      description: "",
      min_power: "",
      max_power: "",
      gensetCO2: "",
      starting_timer: "",
      cooling_timer: "",
      minimum_running_period: "",
    },
    batteryConfigData: {
      name: "",
      description: "",
      min_power: "",
      max_power: "",
      capacity: 0,
      soc_max: 90,
      soc_min: 10,
      starting_timer: "",
    },
    loadConfigData: {
      name: "",
      description: "",
      maximum_available_power: 0,
    },
    evConfigData: {
      name: "",
      description: "",
      maximum_available_power: 0,
    },
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
    },
    cbConfigDataSample: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
    },
    sampleCbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
    },
    sampleConfigData: {
      name: "",
      description: "",
      min_power: "",
      max_power: "",
    },
    configData: {
      name: "",
      description: "",
      min_power: "",
      max_power: "",
    },
    powerMeterData: {
      name: "",
      description: "",
      powerMeterModbusId: "",
    },
    chpConfigData: {
      name: "",
      description: "",
      nominal_power: "",
    },
  });

  const [gsxExternalId, setGsxExternalId] = useState({
    utilityExternalId: "",
    gensetExternalId: "",
  });

  const [assetDetailsDisplay, setAssetDetailsDisplay] = useState({
    assetId: "",
    assetName: "",
    assetType: "",
    manufacturer: "",
    assetInfo: "",
    communicationModel: "",
    imageLink: "",
    assetProperties: [],
  });

  const [assetInfo, setAssetInfo] = useState({
    assetSelected: false,
    manufacturerLoading: false,
    assets: [],
    assetManufacturer: [],
    assetModelType: [],
    selectedManufacturer: "",
    selectdModelType: "",
  });
  const [CBValues, setCBValues] = useState({});
  const [defaultsMinMax, setDefaultsMinMax] = useState({});
  const [coordinates, setCoordinates] = useState({
    xDer: 8,
    xDerCB: 108,
    derDiff: 110,
    xDerPvHyb: 380,
    xDerCBPvHyb: 480,
    derDiffPvHyb: 120,
    xLoad: 10,
    xLoadCB: 55,
    xLoadNew: 65,
    xLoadCBNew: 110,
    loadDiff: 110,
    xAxisLoadCBDiff: 55,
    ecc: {
      width: 690,
    },
    busbar: {
      x2: 755,
    },
    eccwidth: 208,
    busbarx2: 208,
    CX: 600,
    CY: 350,
    radius: 46,
    angle: 0,
    busbarCordinates: {
      x11: 168,
      y11: 335,
      x21: 408,
      y21: 335,
      x12: 650,
      y12: 425,
      x22: 890,
      y22: 425,
    },
  });
  const [snackBar, setSnackBar] = useState({ type: "", message: "" });
  const [alert, setAlert] = useState({
    message: "",
    alertPopUpStatus: false,
    title: "",
    isRoute: false,
    routePath: "",
  });
  const [confirm, setConfirm] = useState({
    confirmMessage: "",
    confirmPopupStatus: false,
  });

  const [duplicateUpdate, setDuplicateUpdate] = useState({
    der: "",
    load: "",
    pv: "",
    ev: "",
  });

  const [runCalculateCoordinates, setRunCalculateCoordinates] = useState({
    componentPush: false,
  });

  const [runGenerateComponentsBlock, setRunGenerateComponentsBlock] = useState({
    generateComponentsBlock: false,
  });

  //* reducer hooks

  const [CBAssetList, CBAssetListDispatch] = useReducer(
    CBAssetListReducer,
    initialCBAssetList
  );
  const [CBEVList, CBEVListDispatch] = useReducer(
    CBEVListReducer,
    initialCBEVList
  );
  const [CBLoadList, CBLoadListDispatch] = useReducer(
    CBLoadListReducer,
    initialCBLoadList
  );

  const [count, countDispatch] = useReducer(countReducer, initialCount);

  const [PVDetails, PVDetailsDispatch] = useReducer(
    PVDetailsReducer,
    initialPVDetails
  );

  //* ref hook

  const initialRenderCoordinates = useRef(true);
  const initialRenderPvConfigured = useRef(true);
  const snackBarRef = useRef(null);

  //* effect hook

  useMountEffect(() => {
    if (sessionStorage.getItem("projectId") !== "null") {
      setDisplayPage(true);
      fetchDefaultDataValues();
    } else {
      setAlertMessage(
        `Asset Page`,
        true,
        `information`,
        `Please create project and then visit asset page.`
      );
    }
    localStorage.setItem(
      "derStatus",
      JSON.stringify([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ])
    );
    localStorage.setItem(
      "derHistory",
      JSON.stringify({ Utility: {}, Genset: {}, BESS: {}, EV: {}, CHP: {} })
    );
    localStorage.setItem(
      "pvHistory",
      JSON.stringify({ pv1: [], pv2: [], pv3: [] })
    );
    localStorage.setItem("cbEvHistory", []);
    localStorage.setItem("cbLoadHistory", []);
    localStorage.setItem("cbAssetHistory", []);
    localStorage.setItem("pmAssetHistory", []);
    localStorage.setItem("loadHistory", []);
    localStorage.setItem("assetEditStatus", "");
    localStorage.setItem(
      "pvGroupPower",
      JSON.stringify({ pv1: "", pv2: "", pv3: "" })
    );
  }, []);

  //* To calculate coordinates

  useMountEffect(() => {
    if (initialRenderCoordinates.current) {
      initialRenderCoordinates.current = false;
    } else {
      calculateCoOridinates(runCalculateCoordinates.componentPush);
    }
  }, [runCalculateCoordinates]);

  useMountEffect(() => {
    if (runGenerateComponentsBlock.generateComponentsBlock) {
      generateComponentsBlock();
    }
  }, [runGenerateComponentsBlock]);

  useEffect(() => {
    if (initialRenderPvConfigured.current) {
      initialRenderPvConfigured.current = false;
    } else {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_PV_CONFIRGURED,
        payload: { PVDetails },
      });
    }
  }, [PVDetails]);

  useMountEffect(() => {
    if (availableDer.length) {
      checkUseCaseChangeByUser();
    }
  }, [availableDer]);

  //* functions

  const fetchDefaultDataValues = () => {
    setLoading(true);
    apiSession
      .getAssetDefaultValues(sessionStorage.getItem("projectId"))
      .then(json => {
        assignDefaultValues(
          json.defaultValues,
          json.gridSettings,
          json.defaults,
          json.siteType,
          json.genset,
          json.powerMeter,
          json.architecture,
          json.clientType,
          json.pm_Utility,
          json.pm_Genset,
          json.pm_PV
        );
      })
      .catch(() => callSnackbar("error", embLabels.errorInLoadingData));
  };

  const assignDefaultValues = (
    defaultValues,
    gridSettings,
    defaults,
    type,
    genset,
    powerMeter,
    architecture,
    clientType,
    pmUtility,
    pmGenset,
    pmPv
  ) => {
    const dataPreFill = [];
    dataPreFill[0] = defaultValues;
    dataPreFill[1] = gridSettings;
    setDefaultPreFillData(dataPreFill);
    CBAssetListDispatch({
      type: ACTIONS.ASSIGN_DEFAULT_VALUES,
      payload: {
        defaultValues,
        type,
        architecture,
        genset,
        pmUtility,
        pmGenset,
        pmPv,
      },
    });
    setBatteryCommunicationData({
      ipaddress: defaultValues.BESS.ipAddress,
      subnetmask: defaultValues.BESS.subnetMask,
      defaultGateway: defaultValues.BESS.defaultGateway,
      modbusSlaveId: defaultValues.BESS.modbusId,
    });
    if ([embLabels.PV_HYBRIDIZATION, embLabels.GRID_CONNECTED].includes(type)) {
      setPowerMeter({
        assetAttributes: powerMeter.assetAttributes,
        assetEnabled: powerMeter.assetEnabled,
        assetId: powerMeter.assetId,
        assetInfo: powerMeter.assetInfo,
        assetProperties: powerMeter.assetProperties,
        assetType: powerMeter.assetType,
        comments: powerMeter.comments,
        communicationModel: powerMeter.communicationModel,
        imageLink: powerMeter.imageLink,
        manufacturer: powerMeter.manufacturer,
      });
    }

    CBEVListDispatch({
      type: ACTIONS.ASSIGN_DEFAULT_VALUES,
      payload: {
        defaultValues,
      },
    });
    setEvCommunicationData({
      ipaddress: defaultValues.EV_charging_station.ipAddress,
      subnetmask: defaultValues.EV_charging_station.subnetMask,
      defaultGateway: defaultValues.EV_charging_station.defaultGateway,
      modbusSlaveId: defaultValues.EV_charging_station.modbusId,
    });

    CBLoadListDispatch({
      type: ACTIONS.ASSIGN_DEFAULT_VALUES,
      payload: {
        defaultValues,
      },
    });

    PVDetailsDispatch({
      type: ACTIONS.ASSIGN_DEFAULT_VALUES,
      payload: {
        defaultValues,
      },
    });

    setCommunicationState({
      ifeIpAddress: defaultValues["Circuit Breaker"].ipAddress,
      ifeIpAddress2: defaultValues["Circuit Breaker"].ipAddress2,
      ifeIpAddress3: defaultValues["Circuit Breaker"].ipAddress3,
      ifeIpAddress4: defaultValues["Circuit Breaker"].ipAddress4,
      ifeSubnetMask: defaultValues["Circuit Breaker"].subnetMask,
      ifeSubnetMask2: defaultValues["Circuit Breaker"].subnetMask2,
      ifeSubnetMask3: defaultValues["Circuit Breaker"].subnetMask3,
      ifeSubnetMask4: defaultValues["Circuit Breaker"].subnetMask4,
      ifeGateway: defaultValues["Circuit Breaker"].defaultGateway,
      ifeGateway2: defaultValues["Circuit Breaker"].defaultGateway2,
      ifeGateway3: defaultValues["Circuit Breaker"].defaultGateway3,
      ifeGateway4: defaultValues["Circuit Breaker"].defaultGateway4,
    });

    const defaultsData = {};

    for (let defaultData of defaults) {
      defaultsData[defaultData["param"]] = defaultData["value"];
    }
    setDefaultsMinMax(defaultsData);
    fetchComponentsExisted();
    setSiteType(type);
    setClientType(clientType);
    setArchitectureType(architecture);
  };

  const fetchComponentsExisted = () => {
    apiSession
      .getComponents(sessionStorage.getItem("projectId"))
      .then(json => {
        localStorage.setItem("programPage", json.programPage);
        localStorage.setItem("communicationValidated", json.systemsPage);
        setSiteCreated(json.siteCreated);
        if (
          json.components.length > 0 &&
          json.diagramChangeRequired === false
        ) {
          getCBDetails(json.components);
          assignComponents(json);
          const defaultConfigurationStatusNew = false;
          fetchUseCase(defaultConfigurationStatusNew);
        } else {
          const defaultConfigurationStatusNew = true;
          setDefaultConfigurationStatus(defaultConfigurationStatusNew);
          fetchUseCase(defaultConfigurationStatusNew);
        }
      })
      .catch(() => {
        setLoading(false);
        setSldLoadCompleted(false);
        callSnackbar("error", embLabels.errorInLoadingData);
      });
  };

  const fetchUseCase = defaultConfigurationStatusNew => {
    apiSession
      .fetchUseCase(sessionStorage.getItem("projectId"))
      .then(json => {
        setSldLoadCompleted(true);
        if (defaultConfigurationStatusNew) {
          loadUseCase(json, defaultConfigurationStatusNew);
        } else {
          setAvailableDer(json);
          checkUseCase(json);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const loadUseCase = (usecase, defaultConfigurationStatusNew) => {
    usecase.forEach(usecaseData => {
      switch (usecaseData) {
        case embLabels.UTILITY:
          CBAssetListDispatch({ type: ACTIONS.ASSIGN_USECASE_UTILITY });
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });
          break;

        case embLabels.GENERATOR:
          CBAssetListDispatch({ type: ACTIONS.ASSIGN_USECASE_GENSET });
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });
          setUseCaseValidGenset(true);
          break;

        case embLabels.BESS:
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });
          CBAssetListDispatch({ type: ACTIONS.ASSIGN_USECASE_BESS });
          break;

        case embLabels.PV_INVERTER:
          CBAssetListDispatch({ type: ACTIONS.ASSIGN_USECASE_PV });

          //* assign pv to pvset1

          PVDetailsDispatch({
            type: ACTIONS.ASSIGN_USECASE_PVSET1,
            payload: {
              count: 3,
            },
          });

          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });

          break;

        case embLabels.LOAD:
          CBLoadListDispatch({ type: ACTIONS.ASSIGN_USECASE_LOAD });
          countDispatch({
            type: ACTIONS.UPDATE_LOAD_COUNT,
            payload: { count: 1 },
          });
          break;
        default:
          break;
      }
    });

    if (defaultConfigurationStatusNew) {
      const runCalculateCoordinatesNew = { componentPush: true };
      setRunCalculateCoordinates(runCalculateCoordinatesNew);
    } else {
      const runCalculateCoordinatesNew = { componentPush: false };
      setRunCalculateCoordinates(runCalculateCoordinatesNew);
    }

    setLoading(false);
  };

  const checkUseCaseChangeByUser = () => {
    const foundGenerator = availableDer.indexOf(embLabels.GENERATOR);
    const foundBESS = availableDer.indexOf(embLabels.BESS);

    if (foundGenerator === -1 && siteType === embLabels.ISLANDABLE) {
      if (CBAssetList[1].uiData.status) {
        removeGenset();
      }
    } else if (foundGenerator !== -1) {
      if (
        !CBAssetList[1].uiData.status &&
        localStorage.getItem("useCaseChanged") === "true"
      ) {
        checkGensetDeleteOneDER(true);
      }
    }

    if (foundBESS !== -1) {
      if (
        !CBAssetList[2].uiData.status &&
        localStorage.getItem("useCaseChanged") === "true"
      ) {
        if (checkSLDCount()) {
          setLoading(true);
          setTimeout(() => {
            if (UserScope.access.saveSLD === true) {
              if (siteType === embLabels.ISLANDABLE) {
                addBattery();
              }
            }
            setLoading(false);
          }, 800);
        } else {
          setAlertMessage(`Alert`, false, ``, embLabels.deleteOneDERToAddGeset);
        }
      }
    }
    localStorage.setItem("useCaseChanged", false);
  };

  const checkGensetDeleteOneDER = existedInUsecase => {
    if (existedInUsecase) {
      if (checkSLDCount()) {
        setDeleteOneDER(false);
        setLoading(false);
        setTimeout(() => {
          if (siteType === embLabels.ISLANDABLE) {
            addGenset();
          }
        }, 800);
      } else {
        setAlertMessage(`Alert`, false, ``, embLabels.deleteOneDERToAddGeset);
        localStorage.setItem("deploymentReady", false);
        setDeleteOneDER(true);
      }
    }
  };

  const checkUseCase = usecase => {
    const hasGenerator = usecase.includes(embLabels.GENERATOR);
    if (hasGenerator) setUseCaseValidGenset(true);
  };

  const calculateCoOridinates = componentPush => {
    if (siteType === embLabels.ISLANDABLE) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES,
        payload: { coordinates: coordinates },
      });
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES,
        payload: { coordinates: coordinates, count: count },
      });
      CBEVListDispatch({
        type: ACTIONS.UPDATE_CBEVLIST_COORDINATES,
        payload: { coordinates: coordinates, count: count },
      });

      let ecc = { ...coordinates.ecc };
      let busbar = { ...coordinates.busbar };
      const totalLoads = count.loadCount + count.evCount;

      if (count.derCount > count.loadCount + count.evCount) {
        if (totalLoads >= 10) {
          ecc.width = 1250;
          busbar.x2 = 1255;
        } else {
          ecc.width = coordinates.eccwidth + (count.derCount - 1) * 110;
          busbar.x2 = coordinates.busbarx2 + (count.derCount - 1) * 110;
        }
      } else {
        if (totalLoads >= 11) {
          ecc.width = 1250;
          busbar.x2 = 1255;
        } else {
          ecc.width = coordinates.eccwidth + (totalLoads - 1) * 110;
          busbar.x2 = coordinates.busbarx2 + (totalLoads - 1) * 110;
        }
      }

      setCoordinates(prevCoordinates => ({
        ...prevCoordinates,
        ecc,
        busbar,
      }));

      if (componentPush) {
        generateComponentsBlock();
      }
      checkAssetStatus();
    }

    if (siteType === embLabels.GRID_CONNECTED) {
      let totalCount = count.derCount + count.loadCount + count.evCount;
      let counts;

      if (CBAssetList[0].uiData.status) {
        counts = 0;
        const positions = getLinePosition(counts);
        const petal = [464, 64];

        CBAssetListDispatch({
          type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
          payload: { index: 0, positions: positions, petal: petal },
        });
      }

      if (CBAssetList[1].uiData.status) {
        counts++;
        let petal = [];
        const positions = getLinePosition(counts);

        if (totalCount === 7) {
          petal = [658, 168];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 1, positions: positions, petal: petal },
          });
        } else if (totalCount === 6) {
          petal = [675, 199];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 1, positions: positions, petal: petal },
          });
        } else if (totalCount === 5) {
          petal = [690, 226.5];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 1, positions: positions, petal: petal },
          });
        } else if (totalCount === 4) {
          petal = [700, 300];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 1, positions: positions, petal: petal },
          });
        } else if (totalCount === 3) {
          petal = [672, 418.5];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 1, positions: positions, petal: petal },
          });
        }
      }

      if (CBAssetList[3].uiData.status) {
        counts++;
        let petal = [];
        const positions = getLinePosition(counts);

        if (totalCount === 7) {
          petal = [695, 350];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 3, positions: positions, petal: petal },
          });
        } else if (totalCount === 6 && CBAssetList[1].uiData.status) {
          petal = [675, 409];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 3, positions: positions, petal: petal },
          });
        } else if (totalCount === 6 && !CBAssetList[1].uiData.status) {
          petal = [675, 199];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 3, positions: positions, petal: petal },
          });
        } else if (totalCount === 5 && CBAssetList[1].uiData.status) {
          petal = [605, 490];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 3, positions: positions, petal: petal },
          });
        } else if (totalCount === 5 && !CBAssetList[1].uiData.status) {
          petal = [690, 230];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 3, positions: positions, petal: petal },
          });
        } else if (totalCount === 4 && CBAssetList[1].uiData.status) {
          petal = [465, 538];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 3, positions: positions, petal: petal },
          });
        } else if (totalCount === 4 && !CBAssetList[1].uiData.status) {
          petal = [700, 300];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 3, positions: positions, petal: petal },
          });
        } else if (totalCount === 3) {
          petal = [672, 418.5];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 3, positions: positions, petal: petal },
          });
        }
      }

      if (CBAssetList[6].uiData.status) {
        counts++;
        let petal = [];
        const positions = getLinePosition(counts);

        if (totalCount === 7) {
          petal = [560, 518];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 6, positions: positions, petal: petal },
          });
        } else if (
          totalCount === 6 &&
          CBAssetList[1].uiData.status &&
          CBAssetList[3].uiData.status
        ) {
          petal = [465, 538];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 6, positions: positions, petal: petal },
          });
        } else if (
          totalCount === 6 &&
          (!CBAssetList[1].uiData.status || !CBAssetList[3].uiData.status)
        ) {
          petal = [675, 409];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 6, positions: positions, petal: petal },
          });
        } else if (
          totalCount === 5 &&
          CBAssetList[1].uiData.status &&
          CBAssetList[3].uiData.status
        ) {
          petal = [325, 492];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 6, positions: positions, petal: petal },
          });
        } else if (
          totalCount === 5 &&
          !CBAssetList[1].uiData.status &&
          !CBAssetList[3].uiData.status
        ) {
          petal = [690, 226.5];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 6, positions: positions, petal: petal },
          });
        } else if (
          totalCount === 5 &&
          (!CBAssetList[1].uiData.status || !CBAssetList[3].uiData.status)
        ) {
          petal = [605, 490];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 6, positions: positions, petal: petal },
          });
        } else if (
          totalCount === 4 &&
          !CBAssetList[2].uiData.status &&
          !CBEVList[0].uiData.status
        ) {
          petal = [465, 538];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 6, positions: positions, petal: petal },
          });
        } else if (
          totalCount === 4 &&
          !CBAssetList[1].uiData.status &&
          !CBAssetList[3].uiData.status
        ) {
          petal = [700, 300];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 6, positions: positions, petal: petal },
          });
        } else if (totalCount === 3) {
          petal = [672, 418.5];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 6, positions: positions, petal: petal },
          });
        }
      }

      if (CBAssetList[2].uiData.status) {
        counts++;
        let petal = [];
        const positions = getLinePosition(counts);

        if (totalCount === 7) {
          petal = [372, 519];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 2, positions: positions, petal: petal },
          });
        } else if (totalCount === 6 && !CBEVList[0].uiData.status) {
          petal = [255, 410];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 2, positions: positions, petal: petal },
          });
        } else if (totalCount === 6 && CBEVList[0].uiData.status) {
          petal = [465, 539];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 2, positions: positions, petal: petal },
          });
        } else if (totalCount === 5 && !CBEVList[0].uiData.status) {
          petal = [322, 492];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 2, positions: positions, petal: petal },
          });
        } else if (totalCount === 5 && CBEVList[0].uiData.status) {
          petal = [605, 490];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 2, positions: positions, petal: petal },
          });
        } else if (totalCount === 4 && !CBEVList[0].uiData.status) {
          petal = [465, 538];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 2, positions: positions, petal: petal },
          });
        } else if (totalCount === 4 && CBEVList[0].uiData.status) {
          petal = [700, 300];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 2, positions: positions, petal: petal },
          });
        } else if (totalCount === 3) {
          petal = [672, 418.5];
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 2, positions: positions, petal: petal },
          });
        }
      }

      if (CBEVList[0].uiData.status) {
        counts++;
        let petal = [];
        const positions = getLinePosition(counts);

        if (totalCount === 7) {
          petal = [290, 230];
          CBEVListDispatch({
            type: ACTIONS.UPDATE_CBEVLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 0, positions: positions, petal: petal },
          });
        } else if (totalCount === 6) {
          petal = [315, 290];

          CBEVListDispatch({
            type: ACTIONS.UPDATE_CBEVLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 0, positions: positions, petal: petal },
          });
        } else if (totalCount === 5) {
          petal = [380, 373];
          CBEVListDispatch({
            type: ACTIONS.UPDATE_CBEVLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 0, positions: positions, petal: petal },
          });
        } else if (totalCount === 4) {
          petal = [520, 420];

          CBEVListDispatch({
            type: ACTIONS.UPDATE_CBEVLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 0, positions: positions, petal: petal },
          });
        } else if (totalCount === 3) {
          petal = [728, 300];
          CBEVListDispatch({
            type: ACTIONS.UPDATE_CBEVLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 0, positions: positions, petal: petal },
          });
        }
      }

      //* LOAD
      if (CBLoadList[0].uiData.status) {
        counts++;
        let petal = [];
        const positions = getLinePosition(counts);

        if (totalCount === 7) {
          petal = [330, 50];
          CBLoadListDispatch({
            type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 0, positions: positions, petal: petal },
          });
        } else if (totalCount === 6) {
          petal = [313, 80];

          CBLoadListDispatch({
            type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 0, positions: positions, petal: petal },
          });
        } else if (totalCount === 5) {
          petal = [300, 110];

          CBLoadListDispatch({
            type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 0, positions: positions, petal: petal },
          });
        } else if (totalCount === 4) {
          petal = [286, 180];

          CBLoadListDispatch({
            type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 0, positions: positions, petal: petal },
          });
        } else if (totalCount === 3) {
          petal = [320, 300];

          CBLoadListDispatch({
            type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 0, positions: positions, petal: petal },
          });
        } else if (totalCount === 2) {
          petal = [523, 420];

          CBLoadListDispatch({
            type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GRIDCONNECTED,
            payload: { index: 0, positions: positions, petal: petal },
          });
        }
      }

      if (componentPush) {
        generateComponentsBlock();
      }
      checkAssetStatus();
    }

    if (siteType === embLabels.PV_HYBRIDIZATION) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVHYBRIDIZATION,
        payload: { coordinates: coordinates },
      });
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVHYBRIDIZATION,
      });
      setCoordinates(prevCoordinates => ({
        ...prevCoordinates,
        busbarCordinates: {
          ...prevCoordinates.busbarCordinates,
          x11: "426",
          y11: "360",
          x21: "815",
          y21: "360",
        },
      }));
      if (componentPush) {
        generateComponentsBlock();
      }
      checkAssetStatus();
    }

    if (
      siteType === embLabels.ISLANDABLE_GSX &&
      clientType === embLabels.GREENSTRUXURE_PROJECT &&
      architectureType === embLabels.PV_GENSET_ATS
    ) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVGENSET_ATS,
      });
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVGENSET_ATS,
      });
      setCoordinates(prevCoordinates => ({
        ...prevCoordinates,
        busbarCordinates: {
          ...prevCoordinates.busbarCordinates,
          x11: "168",
          y11: "335",
          x21: "408",
          y21: "335",
          x12: "670",
          y12: "430",
          x22: "840",
          y22: "430",
        },
      }));
      if (componentPush) {
        generateComponentsBlock();
      }
      checkAssetStatus();
    }
  };

  const getLinePosition = total => {
    let centerX = coordinates.CX;
    let centerY = coordinates.CY;
    let radius = coordinates.radius + 3;
    let totalCount = count.loadCount + count.derCount + count.evCount;
    let angle = 360 / totalCount;
    const degreeToRadian = angleInDeg => angleInDeg * (Math.PI / 180);
    let x1pos = centerX + radius * Math.cos(degreeToRadian(angle * total - 90));
    let y1pos = centerY + radius * Math.sin(degreeToRadian(angle * total - 90));
    let x2pos = x1pos + 145 * Math.cos(degreeToRadian(angle * total - 90));
    let y2pos = y1pos + 145 * Math.sin(degreeToRadian(angle * total - 90));
    let positions = [x1pos, y1pos, x2pos, y2pos];
    return positions;
  };

  const checkAssetStatus = () => {
    let componentConfigStatus = true;
    CBAssetList.forEach((componentData, index) => {
      if (componentData.uiData.status && [0, 1, 2, 6].includes(index)) {
        if (!componentData.uiData.configured) {
          componentConfigStatus = false;
        }
      }
    });

    CBEVList.forEach(componentData => {
      if (componentData.uiData.status) {
        if (!componentData.uiData.configured) {
          componentConfigStatus = false;
        }
      }
    });
    if ([embLabels.ISLANDABLE, embLabels.GRID_CONNECTED].includes(siteType)) {
      CBLoadList.forEach(componentData => {
        if (componentData.uiData.status) {
          if (!componentData.uiData.configured) {
            componentConfigStatus = false;
          }
        }
      });
    }

    PVDetails.pvset1Data.forEach(pvData => {
      if (!pvData.status) {
        componentConfigStatus = false;
      }
    });
    PVDetails.pvset2Data.forEach(pvData => {
      if (!pvData.status) {
        componentConfigStatus = false;
      }
    });
    PVDetails.pvset3Data.forEach(pvData => {
      if (!pvData.status) {
        componentConfigStatus = false;
      }
    });
    localStorage.setItem("deploymentReady", componentConfigStatus);
  };

  const checkExportOptimizationCondition = () => {
    if (
      defaultPreFillData[1].exportOptimization &&
      !CBAssetList[2].uiData.status
    ) {
      if (UserScope.access.saveSLD) {
        setTimeout(() => {
          if (siteType !== embLabels.GRID_CONNECTED) {
            addBattery();
          }
        }, 300);
      }
    }
  };

  const padNumberFormat = number => (number < 10 ? "0" : "") + number;

  const generateComponentsBlock = () => {
    const componentJson = {
      projectId: "",
      deploymentReady: true,
      programPage: true,
      components: [],
    };
    componentJson.projectId = sessionStorage.getItem("projectId")
      ? sessionStorage.getItem("projectId")
      : "1";
    componentJson.userId = 1;
    let arrayComponent = [];
    let assetCB = 10;
    let assetI = 1;

    //* assets
    const CBAssetListNew = _.cloneDeep(CBAssetList);
    CBAssetListNew.map((cbData, key) => {
      if (cbData.uiData.status) {
        let CBComponentIdx = "CB_10";
        switch (cbData.assetType) {
          case embLabels.UTILITY:
          case embLabels.GENSET:
          case embLabels.BESS:
          case embLabels.CHP:
            let asset = {};
            if (cbData.assetType === embLabels.UTILITY) {
              asset.assetId = 1;
              CBComponentIdx = "CB_10";
              asset.componentType = "Utility";
              asset.externalId = gsxExternalId.utilityExternalId
                ? gsxExternalId.utilityExternalId
                : null;
            }
            if (cbData.assetType === embLabels.GENSET) {
              if (cbData.assetDetails.length) {
                asset.assetId = cbData.assetDetails[0].assetId;
              } else {
                asset.assetId = 2;
              }
              CBComponentIdx = "CB_20";
              asset.componentType = "Genset";
              asset.externalId = gsxExternalId.gensetExternalId
                ? gsxExternalId.gensetExternalId
                : null;
            }
            if (cbData.assetType === embLabels.BESS) {
              if (cbData.assetDetails.length) {
                asset.assetId = cbData.assetDetails[0].assetId;
              } else {
                asset.assetId = 3;
              }
              CBComponentIdx = "CB_30";
              asset.componentType = "BESS";
            }

            //* CHP

            if (cbData.assetType === embLabels.CHP) {
              if (cbData.assetDetails.length) {
                asset.assetId = cbData.assetDetails[0].assetId;
              } else {
                asset.assetId = 3;
              }
              CBComponentIdx = "CB_80";
              asset.componentType = "CHP";
            }

            asset.userId = 1;
            asset.componentIdx = "ASSET_" + padNumberFormat(assetI);
            asset.componentName = cbData.configData.name;
            componentJson.deploymentReady =
              componentJson.deploymentReady && cbData.uiData.configured
                ? true
                : false;
            asset.connections = [];
            asset.communications = [];
            asset.uiAttributes = JSON.stringify(cbData.uiData);

            let assetConnections = {
              componentIdx: CBComponentIdx,
              endpointIdX: "1",
              connectionType: "electrical_connection",
            };
            let assetAttributes = {};
            assetAttributes = cbData.configData;
            if (cbData.assetType === embLabels.GENSET) {
              delete assetAttributes.min_power;
            }
            asset.attributes = JSON.stringify(assetAttributes);
            if (
              [embLabels.ISLANDABLE, embLabels.PV_HYBRIDIZATION].includes(
                siteType
              ) ||
              architectureType === embLabels.PV_GENSET_ATS
            ) {
              asset.connections.push(assetConnections);
            }

            if (cbData.assetType === embLabels.GENSET) {
              if (
                cbData.assetDetails.length &&
                cbData.assetDetails[0].assetInfo === "Hardwired"
              ) {
                //*do nothing
              }
            }

            if (cbData.communicationData.modbusSlaveId) {
              let assetCommunications = {
                ip: cbData.communicationData.ipaddress,
                subnetmask: cbData.communicationData.subnetmask,
                defaultGateway: cbData.communicationData.defaultGateway,
                modbusSlaveId: cbData.communicationData.modbusSlaveId,
              };

              asset.communications.push(assetCommunications);

              if (cbData.assetType === embLabels.GENSET || embLabels.BESS) {
                if (cbData.assetDetails.length) {
                  if (cbData.assetDetails[0].assetInfo === "Hardwired") {
                    asset.communications = [];
                  }
                }
              }
            }

            arrayComponent.push(asset);

            if (siteType === embLabels.ISLANDABLE) {
              let cb = {};
              cb.assetId = 7;
              cb.userId = 1;
              cb.componentIdx = CBComponentIdx;
              cb.componentName = cbData.cbConfigData.name;
              cb.componentType = "Circuit Breaker";
              cb.connections = [];
              cb.communications = [];
              cb.uiAttributes = JSON.stringify(cbData.uiData);
              let connections = {
                componentIdx: "ASSET_" + padNumberFormat(assetI),
                endpointIdX: "1",
                connectionType: "electrical_connection",
              };

              let attributes = {
                name: cbData.cbConfigData.name,
                description: cbData.cbConfigData.description,
                motorized: cbData.cbConfigData.motorized ? 1 : 0,
                loadCategory: cbData.cbConfigData.loadCategory,
                loadPriority: cbData.cbConfigData.loadPriority,
              };
              cb.attributes = JSON.stringify(attributes);

              //cb values
              let communications = {};

              if (cbData.assetType === embLabels.UTILITY) {
                communications = {
                  ip: CBValues.CB_10
                    ? CBValues.CB_10.ip
                    : communicationState.ifeIpAddress,
                  subnetmask: CBValues.CB_10
                    ? CBValues.CB_10.mask
                    : communicationState.ifeSubnetMask,
                  defaultGateway: CBValues.CB_10
                    ? CBValues.CB_10.gateway
                    : communicationState.ifeGateway,
                  modbusSlaveId: CBValues.CB_10
                    ? CBValues.CB_10.modbus
                    : cbData.modbusSlaveId,
                };
              }
              if (cbData.assetType === embLabels.GENSET) {
                communications = {
                  ip: CBValues.CB_20
                    ? CBValues.CB_20.ip
                    : count.loadCount + count.derCount + count.evCount > 21
                    ? communicationState.ifeIpAddress4
                    : count.loadCount + count.derCount + count.evCount > 14
                    ? communicationState.ifeIpAddress3
                    : count.loadCount + count.derCount + count.evCount > 7
                    ? communicationState.ifeIpAddress2
                    : communicationState.ifeIpAddress,
                  subnetmask: CBValues.CB_20
                    ? CBValues.CB_20.mask
                    : count.loadCount + count.derCount + count.evCount > 21
                    ? communicationState.ifeSubnetMask4
                    : count.loadCount + count.derCount + count.evCount > 14
                    ? communicationState.ifeSubnetMask3
                    : count.loadCount + count.derCount + count.evCount > 7
                    ? communicationState.ifeSubnetMask2
                    : communicationState.ifeSubnetMask,
                  defaultGateway: CBValues.CB_20
                    ? CBValues.CB_20.gateway
                    : count.loadCount + count.derCount + count.evCount > 21
                    ? communicationState.ifeGateway4
                    : count.loadCount + count.derCount + count.evCount > 14
                    ? communicationState.ifeGateway3
                    : count.loadCount + count.derCount + count.evCount > 7
                    ? communicationState.ifeGateway2
                    : communicationState.ifeGateway,
                  modbusSlaveId: CBValues.CB_20
                    ? CBValues.CB_20.modbus
                    : (count.loadCount + count.derCount + count.evCount - 1) %
                        7 ===
                      0
                    ? "255"
                    : cbData.modbusSlaveId,
                };
              }
              if (cbData.assetType === embLabels.BESS) {
                communications = {
                  ip: CBValues.CB_30
                    ? CBValues.CB_30.ip
                    : count.loadCount + count.derCount + count.evCount > 21
                    ? communicationState.ifeIpAddress4
                    : count.loadCount + count.derCount + count.evCount > 14
                    ? communicationState.ifeIpAddress3
                    : count.loadCount + count.derCount + count.evCount > 7
                    ? communicationState.ifeIpAddress2
                    : communicationState.ifeIpAddress,
                  subnetmask: CBValues.CB_30
                    ? CBValues.CB_30.mask
                    : count.loadCount + count.derCount + count.evCount > 21
                    ? communicationState.ifeSubnetMask4
                    : count.loadCount + count.derCount + count.evCount > 14
                    ? communicationState.ifeSubnetMask3
                    : count.loadCount + count.derCount + count.evCount > 7
                    ? communicationState.ifeSubnetMask2
                    : communicationState.ifeSubnetMask,
                  defaultGateway: CBValues.CB_30
                    ? CBValues.CB_30.gateway
                    : count.loadCount + count.derCount + count.evCount > 21
                    ? communicationState.ifeGateway4
                    : count.loadCount + count.derCount + count.evCount > 14
                    ? communicationState.ifeGateway3
                    : count.loadCount + count.derCount + count.evCount > 7
                    ? communicationState.ifeGateway2
                    : communicationState.ifeGateway,
                  modbusSlaveId: CBValues.CB_30
                    ? CBValues.CB_30.modbus
                    : (count.loadCount + count.derCount + count.evCount - 1) %
                        7 ===
                      0
                    ? "255"
                    : cbData.modbusSlaveId,
                };
              }
              if (cbData.assetType === embLabels.CHP) {
                communications = {
                  ip: CBValues.CB_80
                    ? CBValues.CB_80.ip
                    : communicationState.ifeIpAddress,
                  subnetmask: CBValues.CB_80
                    ? CBValues.CB_80.mask
                    : communicationState.ifeSubnetMask,
                  defaultGateway: CBValues.CB_80
                    ? CBValues.CB_80.gateway
                    : communicationState.ifeGateway,
                  modbusSlaveId: CBValues.CB_80
                    ? CBValues.CB_80.modbus
                    : cbData.modbusSlaveId,
                };
              }

              cb.connections.push(connections);
              cb.communications.push(communications);
              arrayComponent.push(cb);
              assetCB = assetCB + 10;
            }

            //* for pvhybridization

            if (
              siteType === embLabels.PV_HYBRIDIZATION ||
              architectureType === embLabels.PV_GENSET_ATS
            ) {
              let cb = {};
              cb.assetId = powerMeter.assetId
                ? powerMeter.assetId
                : cbData.powerMeterData.pmAsset.assetId;
              cb.userId = 1;
              cb.componentIdx = CBComponentIdx;
              cb.componentName = cbData.powerMeterData.name;
              cb.componentType = "Power Meter";
              cb.connections = [];
              cb.communications = [];
              cb.uiAttributes = JSON.stringify(cbData.uiData);
              let connections = {
                componentIdx: "ASSET_" + padNumberFormat(assetI),
                endpointIdX: "1",
                connectionType: "electrical_connection",
              };
              let attributes = {
                name: cbData.powerMeterData.name,
                description: cbData.powerMeterData.description,
                powerMeterModbusId: cbData.powerMeterData.powerMeterModbusId,
              };
              cb.attributes = JSON.stringify(attributes);

              //*cb values

              let communications = {};
              communications = {
                ip: "",
                subnetmask: "",
                defaultGateway: "",
                modbusSlaveId: cbData.powerMeterData.powerMeterModbusId,
              };
              cb.connections.push(connections);
              cb.communications.push(communications);
              arrayComponent.push(cb);
              assetCB = assetCB + 10;
            }

            //*for grid_connected
            if (
              siteType === embLabels.GRID_CONNECTED &&
              cbData.assetType === embLabels.UTILITY
            ) {
              let cb = {};
              cb.assetId = powerMeter.assetId;
              cb.userId = 1;
              cb.componentIdx = CBComponentIdx;
              cb.componentName = "PM_Utility";
              cb.componentType = "Power Meter";
              cb.connections = [];
              cb.communications = [];
              cb.uiAttributes = JSON.stringify({});
              let connections = {
                componentIdx: "ASSET_" + padNumberFormat(assetI),
                endpointIdX: "1",
                connectionType: "electrical_connection",
              };
              let attributes = {
                name: cbData.powerMeterData.name || "PM_Utility",
                description: cbData.powerMeterData.description || "",
                powerMeterModbusId:
                  cbData.powerMeterData.powerMeterModbusId || "100",
              };
              cb.attributes = JSON.stringify(attributes);

              //*cb values

              let communications = {};
              communications = {
                ip: "",
                subnetmask: "",
                defaultGateway: "",
                modbusSlaveId:
                  cbData.powerMeterData.powerMeterModbusId || "100",
              };
              cb.connections.push(connections);
              cb.communications.push(communications);
              arrayComponent.push(cb);
              assetCB = assetCB + 10;
            }
            assetI = assetI + 1;

            break;
          case embLabels.INVERTER:
            let connectionList = [];
            if (cbData.pv === 1) {
              CBComponentIdx = "CB_40";
              PVDetails.pvset1Data.map((data, key) => {
                let asset = {};
                if (cbData.assetType === embLabels.INVERTER) {
                  asset.componentType = "Inverter";
                }

                asset.userId = 1;
                asset.componentIdx = `ASSET_${padNumberFormat(assetI)}`;
                asset.componentName = PVDetails.pvset1Data[key].name;
                asset.externalId = PVDetails.pvset1Data[key].pvGsxExternalId
                  ? PVDetails.pvset1Data[key].pvGsxExternalId
                  : null;
                if (PVDetails.pvset1Data[key].assetDetails[0] !== undefined) {
                  asset.assetId =
                    PVDetails.pvset1Data[key].assetDetails[0].assetId;
                } else {
                  asset.assetId = 4;
                }

                asset.connections = [];
                asset.communications = [];
                cbData.uiData.pv = 1;
                cbData.uiData.groupName = PVDetails.pvset1Data[key].groupName;
                cbData.uiData.configured = PVDetails.pvset1Data[key].status;
                asset.uiAttributes = JSON.stringify(cbData.uiData);
                componentJson.deploymentReady =
                  componentJson.deploymentReady && cbData.uiData.configured
                    ? true
                    : false;
                let assetConnections = {
                  componentIdx: CBComponentIdx,
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };
                let assetAttributes = {
                  name: PVDetails.pvset1Data[key].name,
                  description: PVDetails.pvset1Data[key].description,
                  max_power: PVDetails.pvset1Data[key].max_power,
                  groupName: PVDetails.pvset1Data[key].groupName,
                  group_max_power: PVDetails.pvset1Data[key].group_max_power,
                };
                asset.attributes = JSON.stringify(assetAttributes);

                if (
                  [embLabels.ISLANDABLE, embLabels.PV_HYBRIDIZATION].includes(
                    siteType
                  ) ||
                  architectureType === embLabels.PV_GENSET_ATS
                ) {
                  asset.connections.push(assetConnections);
                }

                //* no communication for hardwired

                let assetCommunications;

                if (
                  PVDetails.pvset1Data[key].assetDetails.length &&
                  PVDetails.pvset1Data[key].assetDetails[0].assetInfo ===
                    "Hardwired"
                ) {
                  assetCommunications = {};
                } else {
                  assetCommunications = {
                    ip: PVDetails.pvset1Data[key].ipaddress,
                    subnetmask: PVDetails.pvset1Data[key].subnetmask,
                    defaultGateway: PVDetails.pvset1Data[key].defaultGateway,
                    modbusSlaveId: PVDetails.pvset1Data[key].modbusSlaveId,
                  };
                }
                asset.communications.push(assetCommunications);
                arrayComponent.push(asset);
                let connectionNew = {
                  componentIdx: `ASSET_${padNumberFormat(assetI)}`,
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };
                connectionList.push(connectionNew);
                assetI = assetI + 1;
                return null;
              });

              if (siteType === embLabels.ISLANDABLE) {
                let cbNew = {};
                cbNew.assetId = 7;
                cbNew.userId = 1;
                cbNew.componentIdx = CBComponentIdx;
                cbNew.componentName = cbData.cbConfigData.name;
                cbNew.componentType = "Circuit Breaker";
                cbNew.connections = connectionList;
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);

                let attributesnew = {
                  name: cbData.cbConfigData.name,
                  description: cbData.cbConfigData.description,
                  motorized: cbData.cbConfigData.motorized ? 1 : 0,
                  loadCategory: cbData.cbConfigData.loadCategory,
                  loadPriority: cbData.cbConfigData.loadPriority,
                };
                cbNew.attributes = JSON.stringify(attributesnew);

                let communicationsnew = {
                  ip: CBValues.CB_40
                    ? CBValues.CB_40.ip
                    : count.loadCount + count.derCount + count.evCount > 21
                    ? communicationState.ifeIpAddress4
                    : count.loadCount + count.derCount + count.evCount > 14
                    ? communicationState.ifeIpAddress3
                    : count.loadCount + count.derCount + count.evCount > 7
                    ? communicationState.ifeIpAddress2
                    : communicationState.ifeIpAddress,
                  subnetmask: CBValues.CB_40
                    ? CBValues.CB_40.mask
                    : count.loadCount + count.derCount + count.evCount > 21
                    ? communicationState.ifeSubnetMask4
                    : count.loadCount + count.derCount + count.evCount > 14
                    ? communicationState.ifeSubnetMask3
                    : count.loadCount + count.derCount + count.evCount > 7
                    ? communicationState.ifeSubnetMask2
                    : communicationState.ifeSubnetMask,
                  defaultGateway: CBValues.CB_40
                    ? CBValues.CB_40.gateway
                    : count.loadCount + count.derCount + count.evCount > 21
                    ? communicationState.ifeGateway4
                    : count.loadCount + count.derCount + count.evCount > 14
                    ? communicationState.ifeGateway3
                    : count.loadCount + count.derCount + count.evCount > 7
                    ? communicationState.ifeGateway2
                    : communicationState.ifeGateway,
                  modbusSlaveId: CBValues.CB_40
                    ? CBValues.CB_40.modbus
                    : (count.loadCount + count.derCount + count.evCount - 1) %
                        7 ===
                      0
                    ? "255"
                    : cbData.modbusSlaveId,
                };

                cbNew.communications.push(communicationsnew);
                arrayComponent.push(cbNew);
              }
              if (siteType === embLabels.PV_HYBRIDIZATION) {
                let cbNew = {};
                cbNew.assetId = powerMeter.assetId;
                cbNew.userId = 1;
                cbNew.componentIdx = CBComponentIdx;
                cbNew.componentName = cbData.powerMeterData.name;
                cbNew.componentType = "Power Meter";
                cbNew.connections = connectionList;
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);
                let attributesnew = {
                  name: cbData.powerMeterData.name,
                  description: cbData.powerMeterData.description,
                  powerMeterModbusId: cbData.powerMeterData.powerMeterModbusId,
                };

                cbNew.attributes = JSON.stringify(attributesnew);

                //* cb values

                let communicationsnew = {
                  ip: "",
                  subnetmask: "",
                  defaultGateway: "",
                  modbusSlaveId: cbData.powerMeterData.powerMeterModbusId,
                };

                cbNew.communications.push(communicationsnew);
                arrayComponent.push(cbNew);
              }
              if (
                siteType === embLabels.ISLANDABLE_GSX &&
                architectureType === embLabels.PV_GENSET_ATS
              ) {
                let cbNew = {};
                cbNew.assetId = cbData.powerMeterData.pmAsset.assetId;
                cbNew.userId = 1;
                cbNew.componentIdx = CBComponentIdx;
                cbNew.componentName = cbData.powerMeterData.name;
                cbNew.componentType = "Power Meter";
                cbNew.connections = connectionList;
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);
                let attributesnew = {
                  name: cbData.powerMeterData.name,
                  description: cbData.powerMeterData.description,
                  powerMeterModbusId: cbData.powerMeterData.powerMeterModbusId,
                };

                cbNew.attributes = JSON.stringify(attributesnew);

                //* cb values

                let communicationsnew = {
                  ip: cbData.powerMeterData.ipAddress,
                  subnetmask: cbData.powerMeterData.mask,
                  defaultGateway: cbData.powerMeterData.gatewayAddress,
                  modbusSlaveId: cbData.powerMeterData.powerMeterModbusId,
                };

                cbNew.communications.push(communicationsnew);
                arrayComponent.push(cbNew);
              }
            } else if (cbData.pv === 2) {
              CBComponentIdx = "CB_41";
              PVDetails.pvset2Data.map((data, key) => {
                let asset = {};
                if (cbData.assetType === embLabels.INVERTER) {
                  asset.componentType = "Inverter";
                }
                asset.userId = 1;
                asset.componentIdx = "ASSET_" + padNumberFormat(assetI);
                asset.componentName = PVDetails.pvset2Data[key].name;
                if (PVDetails.pvset2Data[key].assetDetails[0] !== undefined) {
                  asset.assetId =
                    PVDetails.pvset2Data[key].assetDetails[0].assetId;
                } else {
                  asset.assetId = 4;
                }
                asset.connections = [];
                asset.communications = [];
                cbData.uiData.pv = 2;
                cbData.uiData.groupName = PVDetails.pvset2Data[key].groupName;
                cbData.uiData.configured = PVDetails.pvset2Data[key].status;
                asset.uiAttributes = JSON.stringify(cbData.uiData);
                componentJson.deploymentReady =
                  componentJson.deploymentReady && cbData.uiData.configured
                    ? true
                    : false;
                let assetConnections = {
                  componentIdx: CBComponentIdx,
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };
                let assetAttributes = {
                  name: PVDetails.pvset2Data[key].name,
                  description: PVDetails.pvset2Data[key].description,
                  max_power: PVDetails.pvset2Data[key].max_power,
                  groupName: PVDetails.pvset2Data[key].groupName,
                  group_max_power: PVDetails.pvset2Data[key].group_max_power,
                };
                asset.attributes = JSON.stringify(assetAttributes);

                if (siteType === embLabels.ISLANDABLE) {
                  asset.connections.push(assetConnections);
                }

                //* no communication for hardwired

                let assetCommunications;

                if (
                  PVDetails.pvset2Data[key].assetDetails.length &&
                  PVDetails.pvset2Data[key].assetDetails[0].assetInfo ===
                    "Hardwired"
                ) {
                  assetCommunications = {};
                } else {
                  assetCommunications = {
                    ip: PVDetails.pvset2Data[key].ipaddress,
                    subnetmask: PVDetails.pvset2Data[key].subnetmask,
                    defaultGateway: PVDetails.pvset2Data[key].defaultGateway,
                    modbusSlaveId: PVDetails.pvset2Data[key].modbusSlaveId,
                  };
                }

                asset.communications.push(assetCommunications);
                arrayComponent.push(asset);
                let connectionNew = {
                  componentIdx: "ASSET_" + padNumberFormat(assetI),
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };
                connectionList.push(connectionNew);
                assetI = assetI + 1;
                return null;
              });

              if (siteType === embLabels.ISLANDABLE) {
                let cbNew = {};
                cbNew.assetId = 7;
                cbNew.userId = 1;
                cbNew.componentIdx = CBComponentIdx;
                cbNew.componentName = cbData.cbConfigData.name;
                cbNew.componentType = "Circuit Breaker";
                cbNew.connections = connectionList;
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);
                let attributesnew = {
                  name: cbData.cbConfigData.name,
                  description: cbData.cbConfigData.description,
                  motorized: cbData.cbConfigData.motorized ? 1 : 0,
                  loadCategory: cbData.cbConfigData.loadCategory,
                  loadPriority: cbData.cbConfigData.loadPriority,
                };
                cbNew.attributes = JSON.stringify(attributesnew);

                let communicationsnew = {
                  ip: CBValues.CB_41
                    ? CBValues.CB_41.ip
                    : count.loadCount + count.derCount + count.evCount > 21
                    ? communicationState.ifeIpAddress4
                    : count.loadCount + count.derCount + count.evCount > 14
                    ? communicationState.ifeIpAddress3
                    : count.loadCount + count.derCount + count.evCount > 7
                    ? communicationState.ifeIpAddress2
                    : communicationState.ifeIpAddress,
                  subnetmask: CBValues.CB_41
                    ? CBValues.CB_41.mask
                    : count.loadCount + count.derCount + count.evCount > 21
                    ? communicationState.ifeSubnetMask4
                    : count.loadCount + count.derCount + count.evCount > 14
                    ? communicationState.ifeSubnetMask3
                    : count.loadCount + count.derCount + count.evCount > 7
                    ? communicationState.ifeSubnetMask2
                    : communicationState.ifeSubnetMask,
                  defaultGateway: CBValues.CB_41
                    ? CBValues.CB_41.gateway
                    : count.loadCount + count.derCount + count.evCount > 21
                    ? communicationState.ifeGateway4
                    : count.loadCount + count.derCount + count.evCount > 14
                    ? communicationState.ifeGateway3
                    : count.loadCount + count.derCount + count.evCount > 7
                    ? communicationState.ifeGateway2
                    : communicationState.ifeGateway,
                  modbusSlaveId: CBValues.CB_41
                    ? CBValues.CB_41.modbus
                    : (count.loadCount + count.derCount + count.evCount - 1) %
                        7 ===
                      0
                    ? "255"
                    : cbData.modbusSlaveId,
                };

                cbNew.communications.push(communicationsnew);
                arrayComponent.push(cbNew);
              }
            } else if (cbData.pv === 3) {
              CBComponentIdx = "CB_42";
              PVDetails.pvset3Data.map((data, key) => {
                let asset = {};
                if (cbData.assetType === embLabels.INVERTER) {
                  asset.componentType = "Inverter";
                }
                asset.userId = 1;
                asset.componentIdx = "ASSET_" + padNumberFormat(assetI);
                asset.componentName = PVDetails.pvset3Data[key].name;
                if (PVDetails.pvset3Data[key].assetDetails[0] !== undefined) {
                  asset.assetId =
                    PVDetails.pvset3Data[key].assetDetails[0].assetId;
                } else {
                  asset.assetId = 4;
                }
                asset.connections = [];
                asset.communications = [];
                cbData.uiData.pv = 3;
                cbData.uiData.groupName = PVDetails.pvset3Data[key].groupName;
                cbData.uiData.configured = PVDetails.pvset3Data[key].status;
                asset.uiAttributes = JSON.stringify(cbData.uiData);
                componentJson.deploymentReady =
                  componentJson.deploymentReady && cbData.uiData.configured
                    ? true
                    : false;
                let assetConnections = {
                  componentIdx: CBComponentIdx,
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };
                let assetAttributes = {
                  name: PVDetails.pvset3Data[key].name,
                  description: PVDetails.pvset3Data[key].description,
                  max_power: PVDetails.pvset3Data[key].max_power,
                  groupName: PVDetails.pvset3Data[key].groupName,
                  group_max_power: PVDetails.pvset3Data[key].group_max_power,
                };
                asset.attributes = JSON.stringify(assetAttributes);

                if (siteType === embLabels.ISLANDABLE) {
                  asset.connections.push(assetConnections);
                }

                //* no communication for hardwired

                let assetCommunications;

                if (
                  PVDetails.pvset3Data[key].assetDetails.length &&
                  PVDetails.pvset3Data[key].assetDetails[0].assetInfo ===
                    "Hardwired"
                ) {
                  assetCommunications = {};
                } else {
                  assetCommunications = {
                    ip: PVDetails.pvset3Data[key].ipaddress,
                    subnetmask: PVDetails.pvset3Data[key].subnetmask,
                    defaultGateway: PVDetails.pvset3Data[key].defaultGateway,
                    modbusSlaveId: PVDetails.pvset3Data[key].modbusSlaveId,
                  };
                }
                asset.communications.push(assetCommunications);
                arrayComponent.push(asset);
                let connectionNew = {
                  componentIdx: "ASSET_" + padNumberFormat(assetI),
                  endpointIdX: "1",
                  connectionType: "electrical_connection",
                };
                connectionList.push(connectionNew);
                assetI = assetI + 1;
                return null;
              });

              if (siteType === embLabels.ISLANDABLE) {
                let cbNew = {};
                cbNew.assetId = 7;
                cbNew.userId = 1;
                cbNew.componentIdx = CBComponentIdx;
                cbNew.componentName = cbData.cbConfigData.name;
                cbNew.componentType = "Circuit Breaker";
                cbNew.connections = connectionList;
                cbNew.communications = [];
                cbNew.uiAttributes = JSON.stringify(cbData.uiData);

                let attributesnew = {
                  name: cbData.cbConfigData.name,
                  description: cbData.cbConfigData.description,
                  motorized: cbData.cbConfigData.motorized ? 1 : 0,
                  loadCategory: cbData.cbConfigData.loadCategory,
                  loadPriority: cbData.cbConfigData.loadPriority,
                };
                cbNew.attributes = JSON.stringify(attributesnew);

                //* cb values
                let communicationsnew = {
                  ip: CBValues.CB_42
                    ? CBValues.CB_42.ip
                    : count.loadCount + count.derCount + count.evCount > 21
                    ? communicationState.ifeIpAddress4
                    : count.loadCount + count.derCount + count.evCount > 14
                    ? communicationState.ifeIpAddress3
                    : count.loadCount + count.derCount + count.evCount > 7
                    ? communicationState.ifeIpAddress2
                    : communicationState.ifeIpAddress,
                  subnetmask: CBValues.CB_42
                    ? CBValues.CB_42.mask
                    : count.loadCount + count.derCount + count.evCount > 21
                    ? communicationState.ifeSubnetMask4
                    : count.loadCount + count.derCount + count.evCount > 14
                    ? communicationState.ifeSubnetMask3
                    : count.loadCount + count.derCount + count.evCount > 7
                    ? communicationState.ifeSubnetMask2
                    : communicationState.ifeSubnetMask,
                  defaultGateway: CBValues.CB_42
                    ? CBValues.CB_42.gateway
                    : count.loadCount + count.derCount + count.evCount > 21
                    ? communicationState.ifeGateway4
                    : count.loadCount + count.derCount + count.evCount > 14
                    ? communicationState.ifeGateway3
                    : count.loadCount + count.derCount + count.evCount > 7
                    ? communicationState.ifeGateway2
                    : communicationState.ifeGateway,
                  modbusSlaveId: CBValues.CB_42
                    ? CBValues.CB_42.modbus
                    : (count.loadCount + count.derCount + count.evCount - 1) %
                        7 ===
                      0
                    ? "255"
                    : cbData.modbusSlaveId,
                };
                cbNew.communications.push(communicationsnew);
                arrayComponent.push(cbNew);
              }
            }

            break;

          default:
            break;
        }
      }
      return null;
    });

    //* load
    let loadCBI = 50;
    CBLoadList.map((cbData, key) => {
      let load = {};
      load.assetId = 6;
      load.userId = 1;
      load.componentIdx = `ASSET_${padNumberFormat(assetI)}`;
      load.componentName = cbData.configData.name;
      load.componentType = "Load";
      load.connections = [];
      load.communications = [];
      load.uiAttributes = JSON.stringify(cbData.uiData);
      if (
        [
          embLabels.ISLANDABLE,
          embLabels.GRID_CONNECTED,
          embLabels.OFF_GRID,
        ].includes(siteType)
      ) {
        componentJson.deploymentReady =
          componentJson.deploymentReady && cbData.uiData.configured
            ? true
            : false;
      }

      if (
        siteType === embLabels.PV_HYBRIDIZATION ||
        architectureType === embLabels.PV_GENSET_ATS
      ) {
        componentJson.deploymentReady =
          componentJson.deploymentReady === true ? true : false;
      }
      let connectionsLoad = {
        componentIdx: "CB_" + loadCBI,
        endpointIdX: "1",
        connectionType: "electrical_connection",
      };
      let loadattributes = {
        name: cbData.configData.name,
        description: cbData.configData.description,
        maximum_available_power: cbData.configData.maximum_available_power,
      };
      load.attributes = JSON.stringify(loadattributes);

      if (siteType === embLabels.ISLANDABLE) {
        load.connections.push(connectionsLoad);
      }

      arrayComponent.push(load);

      if (siteType === embLabels.ISLANDABLE) {
        let cb = {};
        cb.assetId = 7;
        cb.userId = 1;
        cb.componentIdx = `CB_${loadCBI}`;
        cb.componentName = cbData.cbConfigData.name;
        cb.componentType = "Circuit Breaker";
        cb.connections = [];
        cb.communications = [];
        cb.uiAttributes = JSON.stringify(cbData.uiData);
        let connectionsCb = {
          componentIdx: "ASSET_" + padNumberFormat(assetI),
          endpointIdX: "1",
          connectionType: "electrical_connection",
        };

        //* copying cb values

        let communications = {
          ip: CBValues["CB_" + loadCBI]
            ? CBValues["CB_" + loadCBI].ip
            : count.loadCount + count.derCount + count.evCount > 21
            ? communicationState.ifeIpAddress4
            : count.loadCount + count.derCount + count.evCount > 14
            ? communicationState.ifeIpAddress3
            : count.loadCount + count.derCount + count.evCount > 7
            ? communicationState.ifeIpAddress2
            : communicationState.ifeIpAddress,
          subnetmask: CBValues[`CB_${loadCBI}`]
            ? CBValues[`CB_${loadCBI}`].mask
            : count.loadCount + count.derCount + count.evCount > 21
            ? communicationState.ifeSubnetMask4
            : count.loadCount + count.derCount + count.evCount > 14
            ? communicationState.ifeSubnetMask3
            : count.loadCount + count.derCount + count.evCount > 7
            ? communicationState.ifeSubnetMask2
            : communicationState.ifeSubnetMask,
          defaultGateway: CBValues[`CB_${loadCBI}`]
            ? CBValues[`CB_${loadCBI}`].gateway
            : count.loadCount + count.derCount + count.evCount > 21
            ? communicationState.ifeGateway4
            : count.loadCount + count.derCount + count.evCount > 14
            ? communicationState.ifeGateway3
            : count.loadCount + count.derCount + count.evCount > 7
            ? communicationState.ifeGateway2
            : communicationState.ifeGateway,
          modbusSlaveId: CBValues[`CB_${loadCBI}`]
            ? CBValues[`CB_${loadCBI}`].modbus
            : cbData.modbusSlaveId,
        };
        let attributes = {
          name: cbData.cbConfigData.name,
          description: cbData.cbConfigData.description,
          motorized: cbData.cbConfigData.motorized ? 1 : 0,
          loadCategory: cbData.cbConfigData.loadCategory,
          loadPriority: cbData.cbConfigData.loadPriority,
        };
        cb.attributes = JSON.stringify(attributes);

        cb.connections.push(connectionsCb);
        cb.communications.push(communications);
        arrayComponent.push(cb);
        loadCBI = loadCBI + 1;

        //* skip 60 for ev
        if (loadCBI === 60) loadCBI = 61;
      }
      assetI = assetI + 1;

      return null;
    });

    //* ev

    let evCBI = 60;
    CBEVList.map((cbData, key) => {
      if (cbData.uiData.status) {
        let ev = {};
        if (cbData.assetDetails.length) {
          ev.assetId = cbData.assetDetails[0].assetId;
        } else {
          ev.assetId = 5;
        }
        ev.userId = 1;
        ev.componentIdx = "ASSET_" + padNumberFormat(assetI);
        ev.componentName = cbData.configData.name;
        ev.componentType = "EV_charging_station";
        ev.connections = [];
        ev.communications = [];
        ev.uiAttributes = JSON.stringify(cbData.uiData);
        componentJson.deploymentReady =
          componentJson.deploymentReady && cbData.uiData.configured
            ? true
            : false;
        let connectionsEv = {
          componentIdx: "CB_" + evCBI,
          endpointIdX: "1",
          connectionType: "electrical_connection",
        };
        let evattributes = {
          name: cbData.configData.name,
          description: cbData.configData.description,
          maximum_available_power: cbData.configData.maximum_available_power,
        };
        ev.attributes = JSON.stringify(evattributes);

        if (siteType === embLabels.ISLANDABLE) {
          ev.connections.push(connectionsEv);
        }

        if (cbData.communicationData.ipaddress) {
          let evCommunications = {
            ip: cbData.communicationData.ipaddress,
            subnetmask: cbData.communicationData.subnetmask,
            defaultGateway: cbData.communicationData.defaultGateway,
            modbusSlaveId: cbData.communicationData.modbusSlaveId,
          };
          ev.communications.push(evCommunications);
        }
        arrayComponent.push(ev);

        if (siteType === embLabels.ISLANDABLE) {
          let cb = {};
          cb.assetId = 7;
          cb.userId = 1;
          cb.componentIdx = "CB_" + evCBI;
          cb.componentName = cbData.cbConfigData.name;
          cb.componentType = "Circuit Breaker";
          cb.connections = [];
          cb.communications = [];
          cb.uiAttributes = JSON.stringify(cbData.uiData);
          let connectionsCb = {
            componentIdx: "ASSET_" + padNumberFormat(assetI),
            endpointIdX: "1",
            connectionType: "electrical_connection",
          };
          let communications = {
            ip: CBValues.CB_60
              ? CBValues.CB_60.ip
              : count.loadCount + count.derCount + count.evCount > 21
              ? communicationState.ifeIpAddress4
              : count.loadCount + count.derCount + count.evCount > 14
              ? communicationState.ifeIpAddress3
              : count.loadCount + count.derCount + count.evCount > 7
              ? communicationState.ifeIpAddress2
              : communicationState.ifeIpAddress,
            subnetmask: CBValues.CB_60
              ? CBValues.CB_60.mask
              : communicationState.ifeSubnetMask,
            defaultGateway: CBValues.CB_60
              ? CBValues.CB_60.gateway
              : communicationState.ifeGateway,
            modbusSlaveId: CBValues.CB_60
              ? CBValues.CB_60.modbus
              : cbData.modbusSlaveId,
          };

          let attributes = {
            name: cbData.cbConfigData.name,
            description: cbData.cbConfigData.description,
            motorized: cbData.cbConfigData.motorized ? 1 : 0,
            loadCategory: cbData.cbConfigData.loadCategory,
            loadPriority: cbData.cbConfigData.loadPriority,
          };
          cb.attributes = JSON.stringify(attributes);

          cb.connections.push(connectionsCb);
          cb.communications.push(communications);
          arrayComponent.push(cb);
        }
      }

      return null;
    });
    componentJson.components = arrayComponent;
    componentJson.diagramChangeRequired = false;

    if (UserScope.access.saveSLD === true) {
      updateComponentRemote(componentJson);
      checkExportOptimizationCondition();
      checkAssetStatus();
    }
  };

  const updateComponentRemote = componentJson => {
    setLoading(true);
    apiSession
      .updateComponents(componentJson)
      .then(json => {
        getCBDetails(json.components);

        //* update pv communication

        PVDetailsDispatch({
          type: ACTIONS.UPDATE_PV_COMMUNICATION_COMPONENT,
          payload: {
            components: json.components,
          },
        });

        setLoading(false);
        localStorage.setItem("programPage", true);
        localStorage.setItem("communicationValidated", json.systemsPage);
        setDuplicateUpdate({
          der: "",
          load: "",
          pv: "",
          ev: "",
        });
        if (defaultConfigurationStatus) {
          callSnackbar("success", embLabels.defaultConfigurationCreated);
          const defaultConfigurationStatusNew = false;
          setDefaultConfigurationStatus(defaultConfigurationStatusNew);
        } else {
          callSnackbar("success", embLabels.configurationUpdatedSuccess);
        }
        if (deleteOneDER) {
          localStorage.setItem("deploymentReady", false);
          checkGensetDeleteOneDER(true);
        }
      })
      .catch(error => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 400) {
            if (duplicateUpdate.der !== "") {
              CBAssetListDispatch({
                type: ACTIONS.DUPLICATE_DER_UPDATE,
                payload: {
                  selectedAsset: duplicateUpdate.der,
                },
              });
            }
            if (duplicateUpdate.load !== "") {
              CBLoadListDispatch({
                type: ACTIONS.DUPLICATE_LOAD_UPDATE,
                payload: {
                  selectedLoad: duplicateUpdate.load,
                },
              });
            }
            if (duplicateUpdate.ev !== "") {
              CBEVListDispatch({
                type: ACTIONS.DUPLICATE_EV_UPDATE,
                payload: {
                  selectedEv: duplicateUpdate.ev,
                },
              });
            }
            if (duplicateUpdate.pv !== "") {
              PVDetailsDispatch({
                type: ACTIONS.DUPLICATE_PV_UPDATE,
              });
              PVDetailsDispatch({
                type: ACTIONS.PV_CONFIGURED_STATUS,
              });
            }
          }
          callSnackbar("error", error.response.data.message);
        } else {
          callSnackbar("error", embLabels.errorInUpdatingConfiguration);
        }
        if (deleteOneDER) {
          localStorage.setItem("deploymentReady", false);
          checkGensetDeleteOneDER(true);
        }
      });
  };

  const getCBDetails = components => {
    const CBDETAILS = components
      .filter(
        component =>
          component.communications.length &&
          component.componentType === "Circuit Breaker"
      )
      .map(component => ({
        [component.componentIdx]: {
          ip: component.communications[0].ip,
          gateway: component.communications[0].defaultGateway,
          mask: component.communications[0].subnetmask,
          modbus: component.communications[0].modbusSlaveId,
        },
      }));

    const CBVALUES = Object.assign({}, ...CBDETAILS);
    setCBValues(CBVALUES);
  };

  const assignComponents = component => {
    let components = component.components;
    let loadCBIndex = 0;
    let loadIndex = 0;
    let pvNo1 = 0;
    let pvNo2 = 0;
    let pvNo3 = 0;

    components.sort((a, b) => a.componentIdx.localeCompare(b.componentIdx));

    //* update pv communication
    PVDetailsDispatch({
      type: ACTIONS.UPDATE_EMPTY_PVCOMMUNICATION,
      payload: {
        componentsData: components,
      },
    });

    components.map(componentsData => {
      switch (componentsData.componentType) {
        case embLabels.UTILITY:
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_UTILITY,
            payload: { componentsData: componentsData },
          });
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });
          if (component.siteType === embLabels.ISLANDABLE_GSX) {
            setGsxExternalId(prevState => ({
              ...prevState,
              utilityExternalId: componentsData.externalId
                ? componentsData.externalId
                : "",
            }));
          }
          break;

        case embLabels.GENSET:
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_GENSET,
            payload: { componentsData: componentsData },
          });
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });
          if (component.siteType === embLabels.ISLANDABLE_GSX) {
            setGsxExternalId(prevState => ({
              ...prevState,
              gensetExternalId: componentsData.externalId
                ? componentsData.externalId
                : "",
            }));
          }
          break;

        case embLabels.BESS:
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_BESS,
            payload: {
              componentsData: componentsData,
              batteryCommunicationData,
              setBatteryCommunicationData,
            },
          });
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });
          break;

        case embLabels.INVERTER:
          const uiData = JSON.parse(componentsData.uiAttributes);

          PVDetailsDispatch({
            type: ACTIONS.UPDATE_PV_COMMUNICATION,
            payload: {
              componentsData: componentsData,
            },
          });

          if (uiData.pv === 1) {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_PV,
              payload: {
                componentsData,
                pvArray: 3,
              },
            });

            if (pvNo1 === 0) {
              countDispatch({
                type: ACTIONS.UPDATE_DER_COUNT,
                payload: { count: 1 },
              });
            }

            PVDetailsDispatch({
              type: ACTIONS.UPDATE_PVSET,
              payload: {
                componentsData,
                pvArray: 3,
              },
            });

            pvNo1++;
          } else if (uiData.pv === 2) {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_PV,
              payload: {
                componentsData,
                pvArray: 4,
              },
            });

            if (pvNo2 === 0) {
              countDispatch({
                type: ACTIONS.UPDATE_DER_COUNT,
                payload: { count: 1 },
              });
            }

            PVDetailsDispatch({
              type: ACTIONS.UPDATE_PVSET,
              payload: {
                componentsData,
                pvArray: 4,
              },
            });
            pvNo2++;
          } else if (uiData.pv === 3) {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_PV,
              payload: {
                componentsData,
                pvArray: 5,
              },
            });
            if (pvNo3 === 0) {
              countDispatch({
                type: ACTIONS.UPDATE_DER_COUNT,
                payload: { count: 1 },
              });
            }

            PVDetailsDispatch({
              type: ACTIONS.UPDATE_PVSET,
              payload: {
                componentsData,
                pvArray: 5,
              },
            });

            pvNo3++;
          }

          break;

        case embLabels.CHP:
          CBAssetListDispatch({
            type: ACTIONS.UPDATE_CHP,
            payload: {
              componentsData: componentsData,
            },
          });

          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });
          break;

        case embLabels.LOAD:
          countDispatch({
            type: ACTIONS.UPDATE_LOAD_COUNT,
            payload: { count: 1 },
          });
          CBLoadListDispatch({
            type: ACTIONS.UPDATE_LOAD,
            payload: { componentsData, loadIndex },
          });

          //   CBLoadListUpdated = newLoadData;
          loadIndex++;

          break;

        case embLabels.EV_CHARGING_STATION:
          countDispatch({
            type: ACTIONS.UPDATE_EV_COUNT,
            payload: { count: 1 },
          });
          CBEVListDispatch({
            type: ACTIONS.UPDATE_EV,
            payload: {
              componentsData,
              evCommunicationData,
              setEvCommunicationData,
            },
          });

          break;

        case embLabels.CIRCUIT_BREAKER:
          if (componentsData.componentIdx === "CB_10") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB,
              payload: {
                componentsData,
                value: 0,
              },
            });
          } else if (componentsData.componentIdx === "CB_20") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB,
              payload: {
                componentsData,
                value: 1,
              },
            });
          } else if (componentsData.componentIdx === "CB_30") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB,
              payload: {
                componentsData,
                value: 2,
              },
            });
          } else if (componentsData.componentIdx === "CB_80") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB,
              payload: {
                componentsData,
                value: 6,
              },
            });
          } else if (componentsData.componentIdx === "CB_40") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB,
              payload: {
                componentsData,
                value: 3,
              },
            });
          } else if (componentsData.componentIdx === "CB_41") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB,
              payload: {
                componentsData,
                value: 4,
              },
            });
          } else if (componentsData.componentIdx === "CB_42") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_CB,
              payload: {
                componentsData,
                value: 5,
              },
            });
          } else if (componentsData.componentIdx === "CB_60") {
            CBEVListDispatch({
              type: ACTIONS.UPDATE_EV_CB,
              payload: { componentsData },
            });
          } else {
            CBLoadListDispatch({
              type: ACTIONS.UPDATE_LOAD_CB,
              payload: { componentsData, loadCBIndex },
            });
            loadCBIndex++;
          }
          break;

        case embLabels.POWER_METER:
          if (componentsData.componentIdx === "CB_10") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_PM,
              payload: {
                componentsData,
                value: 0,
              },
            });
          } else if (componentsData.componentIdx === "CB_20") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_PM,
              payload: {
                componentsData,
                value: 1,
              },
            });
          } else if (componentsData.componentIdx === "CB_40") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_PM,
              payload: {
                componentsData,
                value: 3,
              },
            });
          } else if (componentsData.componentIdx === "CB_80") {
            CBAssetListDispatch({
              type: ACTIONS.UPDATE_DER_PM,
              payload: {
                componentsData,
                value: 6,
              },
            });
          } else {
            // do nothing
          }

          break;

        default:
          break;
      }
      return null;
    });

    const runCalculateCoordinatesNew = { componentPush: false };
    setRunCalculateCoordinates(runCalculateCoordinatesNew);
    setSldLoadCompleted(true);
    setLoading(false);

    // let CBAssetList = _.clone(this.state.CBAssetList);
    // let pvStatus1 = true;
    // this.state.pvset1Data.map(data => {
    //   if (pvStatus1 === true && data.status === true) {
    //     // nothing
    //   } else {
    //     pvStatus1 = false;
    //   }
    //   return null;
    // });
    // newPvData[3].uiData.configured = pvStatus1;
    // let pvStatus2 = true;
    // this.state.pvset2Data.map(data => {
    //   if (pvStatus2 === true && data.status === true) {
    //     // nothing
    //   } else {
    //     pvStatus2 = false;
    //   }
    //   return null;
    // });
    // newPvData[4].uiData.configured = pvStatus2;

    // let pvStatus3 = true;
    // this.state.pvset3Data.map(data => {
    //   if (pvStatus3 === true && data.status === true) {
    //     pvStatus3 = true;
    //   } else {
    //     pvStatus3 = false;
    //   }
    //   return null;
    // });
    // newPvData[5].uiData.configured = pvStatus3;

    // this.setState({
    //   CBAssetList: newPvData,
    // });
  };

  const setAssetSelection = assetName => {
    PVDetailsDispatch({
      type: ACTIONS.CLOSE_PVDISPLAY_CONTENT,
    });

    const newAssetInfo = { ...assetInfo, assetSelected: true };
    setAssetInfo(newAssetInfo);
    getAssets(assetName);
  };

  const getAssets = type => {
    setAssetInfo(prevState => ({ ...prevState, manufacturerLoading: true }));
    apiSession
      .getAssetsList(type)
      .then(json => {
        const manufacturer = [];
        let standardTemplate = "";
        json.assetCatalogue[0].assetList.forEach(data => {
          if (type === embLabels.GENERATOR) {
            if (data.manufacturer === "STANDARD GENSET TEMPLATE") {
              standardTemplate = data.manufacturer;
            } else {
              manufacturer.push(data.manufacturer);
            }
          } else if (type === embLabels.BESS) {
            if (data.manufacturer === "STANDARD BESS TEMPLATE") {
              standardTemplate = data.manufacturer;
            } else {
              manufacturer.push(data.manufacturer);
            }
          } else if (type === embLabels.PV_INVERTER) {
            if (data.manufacturer === "STANDARD PV INVERTER SUNSPEC TEMPLATE") {
              standardTemplate = data.manufacturer;
            } else {
              manufacturer.push(data.manufacturer);
            }
          } else {
            manufacturer.push(data.manufacturer);
          }
        });
        manufacturer.sort();

        if (standardTemplate !== "") {
          manufacturer.splice(0, 0, standardTemplate);
        }

        const modelType = [];
        json.assetCatalogue[0].assetList.forEach(data => {
          if (modelType[data.manufacturer]) {
            modelType[data.manufacturer].push(data.communicationModel);
          } else {
            modelType[data.manufacturer] = [];
            modelType[data.manufacturer].push(data.communicationModel);
          }
          modelType[data.manufacturer].sort();
        });

        const uniqueManufactures = [...new Set(manufacturer)];

        const newAssetInfo = {
          ...assetInfo,
          assets: json.assetCatalogue[0].assetList,
          assetManufacturer: uniqueManufactures,
          assetModelType: modelType,
          manufacturerLoading: false,
          assetSelected: true,
        };
        setAssetInfo(newAssetInfo);
      })
      .catch(() => {
        setAssetInfo(prevState => ({
          ...prevState,
          manufacturerLoading: false,
        }));
      });
  };

  const selectManufacturer = e => {
    const newAssetInfo = { ...assetInfo, selectedManufacturer: e.target.value };
    setAssetInfo(newAssetInfo);
  };

  const closeAssetSelectionBlock = () => {
    setAssetInfo(prevState => ({
      ...prevState,
      assetSelected: false,
      selectedManufacturer: "",
    }));
  };

  const selectAssetFromList = data => {
    let selectedAsset = {};
    const assetDetailsData = { ...assetDetailsDisplay };
    assetInfo.assets.forEach(assetData => {
      if (parseInt(assetData.assetId) === parseInt(data)) {
        assetDetailsData.assetId = assetData.assetId;
        assetDetailsData.manufacturer = assetData.manufacturer;
        assetDetailsData.communicationModel = assetData.communicationModel;
        assetDetailsData.assetInfo = assetData.assetInfo;
        assetDetailsData.imageLink = assetData.imageLink;
        assetDetailsData.assetName = assetData.assetType;
        selectedAsset = assetData;
      }
    });
    setAssetDetailsDisplay(assetDetailsData);
    setAssetInfo(prevState => ({
      ...prevState,
      assetSelected: false,
      selectedManufacturer: "",
    }));
    const configDataNew = { ...configData.configData };
    const preFillDefault = defaultPreFillData[0];
    switch (selectedAsset.assetType) {
      //* GENSET

      case embLabels.GENERATOR:
        CBAssetListDispatch({
          type: ACTIONS.SELECT_DER_ASSET,
          payload: {
            index: 1,
            selectedAsset,
          },
        });
        if (selectedAsset.assetAttributes.length) {
          selectedAsset.assetAttributes.forEach((data, index) => {
            switch (selectedAsset.assetAttributes[index].type) {
              case "max_power":
                configDataNew.max_power =
                  selectedAsset.assetAttributes[index].value;
                break;
              case "starting_timer":
                configDataNew.starting_timer =
                  selectedAsset.assetAttributes[index].value;
                break;
              case "cooling_timer":
                configDataNew.cooling_timer =
                  selectedAsset.assetAttributes[index].value;
                break;
              case "minimum_running_period":
                configDataNew.minimum_running_period =
                  selectedAsset.assetAttributes[index].value;
                break;
              default:
                break;
            }
          });
        } else {
          configDataNew.max_power = defaultsMinMax.maxPowerGensetDefault;
          configDataNew.min_power = "";
          configDataNew.gensetCO2 = defaultsMinMax.gensetCO2Default;
          configDataNew.starting_timer = defaultsMinMax.startUpGensetDefault;
          configDataNew.cooling_timer =
            defaultsMinMax.coolingDurationGensetDefault;
          configDataNew.minimum_running_period =
            defaultsMinMax.minRunningDurationGensetDefault;
        }
        if (selectedAsset.assetInfo === embLabels.SERIAL) {
          const newModbusId = preFillDefault.Generator.ipAddress.split(".")[3];
          CBAssetListDispatch({
            type: ACTIONS.SELECT_SERIAL_ASSET,
            payload: {
              index: 1,
              modbusSlaveId: newModbusId,
            },
          });
        } else if (
          CBAssetList[1].communicationData.ipaddress === "" &&
          selectedAsset.assetInfo === embLabels.COMMUNICATING
        ) {
          CBAssetListDispatch({
            type: ACTIONS.SELECT_COMMUNICATING_ASSET,
            payload: {
              index: 1,
              preFillDefault: preFillDefault.Generator,
            },
          });
        }
        break;

      //* BESS

      case embLabels.BESS:
        CBAssetListDispatch({
          type: ACTIONS.SELECT_DER_ASSET,
          payload: {
            index: 2,
            selectedAsset,
          },
        });

        if (selectedAsset.assetAttributes.length) {
          selectedAsset.assetAttributes.forEach((data, index) => {
            switch (selectedAsset.assetAttributes[index].type) {
              case "max_power":
                configDataNew.max_power =
                  selectedAsset.assetAttributes[index].value;
                break;
              case "starting_timer":
                configDataNew.starting_timer =
                  selectedAsset.assetAttributes[index].value;
                break;
              case "soc_max":
                configDataNew.soc_max =
                  selectedAsset.assetAttributes[index].value;
                break;
              case "capacity":
                configDataNew.capacity =
                  selectedAsset.assetAttributes[index].value;
                break;
              case "soc_min":
                configDataNew.soc_min =
                  selectedAsset.assetAttributes[index].value;
                break;
              default:
                break;
            }
          });
        } else {
          configDataNew.max_power = defaultsMinMax.maxPowerBessDefault;
          configDataNew.starting_timer = "";
          configDataNew.capacity = 0;
          configDataNew.soc_max = defaultsMinMax.socMaxDefault;
          configDataNew.soc_min = defaultsMinMax.socMinDefault;
        }

        if (selectedAsset.assetInfo === embLabels.SERIAL) {
          const newModbusId = preFillDefault.BESS.ipAddress.split(".")[3];
          CBAssetListDispatch({
            type: ACTIONS.SELECT_SERIAL_ASSET,
            payload: {
              index: 2,
              modbusSlaveId: newModbusId,
            },
          });
        } else if (CBAssetList[2].communicationData.ipaddress === "") {
          CBAssetListDispatch({
            type: ACTIONS.SELECT_COMMUNICATING_ASSET,
            payload: {
              index: 2,
              preFillDefault: preFillDefault.BESS,
            },
          });
        }
        break;

      //* CHP

      case embLabels.CHP:
        CBAssetListDispatch({
          type: ACTIONS.SELECT_DER_ASSET,
          payload: {
            index: 6,
            selectedAsset,
          },
        });

        if (selectedAsset.assetAttributes.length) {
          selectedAsset.assetAttributes.forEach((data, index) => {
            switch (selectedAsset.assetAttributes[index].type) {
              case "nominalPower":
                configDataNew.nominal_power =
                  selectedAsset.assetAttributes[index].value;
                break;
              default:
                break;
            }
          });
        } else {
          configDataNew.nominal_power = defaultsMinMax.chpNominalPowerMax;
        }

        break;

      //* PV

      case embLabels.PV_inverter_assettype:
        CBAssetListDispatch({
          type: ACTIONS.INDIVIDUAL_PV_ASSET_SELECT,
          payload: {
            selectedPv: PVDetails.selectedPv,
          },
        });

        if (PVDetails.selectedPv === 3) {
          PVDetailsDispatch({
            type: ACTIONS.UPDATE_PV_CONFIG,
            payload: {
              selectedAsset,
              selectedPv: 3,
              defaultsMinMax: defaultsMinMax,
            },
          });
        }
        if (PVDetails.selectedPv === 4) {
          PVDetailsDispatch({
            type: ACTIONS.UPDATE_PV_CONFIG,
            payload: {
              selectedAsset,
              selectedPv: 4,
              defaultsMinMax: defaultsMinMax,
            },
          });
        }
        if (PVDetails.selectedPv === 5) {
          PVDetailsDispatch({
            type: ACTIONS.UPDATE_PV_CONFIG,
            payload: {
              selectedAsset,
              selectedPv: 5,
              defaultsMinMax: defaultsMinMax,
            },
          });
        }

        //     this.onChangePvSubSelection(this.state.selectSubPv);
        break;

      //* ev

      case embLabels.EV_CHARGING_STATION:
        CBEVListDispatch({
          type: ACTIONS.SELECT_EV_ASSET,
          payload: {
            index: selectedValues.selectedEv,
            selectedAsset,
          },
        });

        if (selectedAsset.assetAttributes.length) {
          selectedAsset.assetAttributes.forEach(attribute => {
            switch (attribute.type) {
              case "max_power":
                configDataNew.maximum_available_power = attribute.value;
                break;
              default:
                break;
            }
          });
        } else {
          configDataNew.maximum_available_power = 0;
        }

        if (selectedAsset.assetInfo === embLabels.SERIAL) {
          const newModbusId =
            preFillDefault.EV_charging_station.ipAddress.split(".")[3];

          CBEVListDispatch({
            type: ACTIONS.SELECT_SERIAL_ASSET,
            payload: {
              index: selectedValues.selectedEv,
              modbusSlaveId: newModbusId,
            },
          });
        } else if (
          CBEVList[selectedValues.selectedEv].communicationData.ipaddress === ""
        ) {
          CBAssetListDispatch({
            type: ACTIONS.SELECT_COMMUNICATING_ASSET,
            payload: {
              index: selectedValues.selectedEv,
              preFillDefault: preFillDefault.EV_charging_station,
            },
          });
        }

        break;

      default:
        break;
    }
    setConfigData(prevState => ({ ...prevState, configData: configDataNew }));

    localStorage.setItem("deploymentReady", false);
  };

  const assetDetailsReset = () => {
    CBAssetListDispatch({
      type: ACTIONS.CLOSEDETAILS_ASSET,
    });
    CBLoadListDispatch({
      type: ACTIONS.CLOSEDETAILS_LOAD,
    });
    CBEVListDispatch({
      type: ACTIONS.CLOSEDETAILS_EV,
    });
    const newAssetDetails = { ...detailsBlock };
    Object.keys(newAssetDetails).forEach(
      block => (newAssetDetails[block] = false)
    );
    const configData = {
      name: "",
      description: "",
      min_power: "",
      max_power: "",
    };
    const cbConfigData = {
      name: "",
      description: "",
    };
    const newAssetDetailsDisplay = { ...assetDetailsDisplay };
    Object.keys(newAssetDetailsDisplay).forEach(
      details => (newAssetDetailsDisplay[details] = "")
    );
    setDetailsBlock(newAssetDetails);
    setConfigData(prevState => ({ ...prevState, configData, cbConfigData }));
    setAssetDetailsDisplay(newAssetDetailsDisplay);
  };

  const selectedValuesHandler = (
    index,
    selectedAssetValue,
    selectedAssetBlcok
  ) => {
    setSelectedValues(prevState => ({
      ...prevState,
      [selectedAssetValue]: index,
    }));
    setDetailsBlock(prevState => ({
      ...prevState,
      [selectedAssetBlcok]: true,
    }));
  };

  const selectedSubPvHandler = index =>
    setSelectedValues(prevState => ({
      ...prevState,
      selectSubPv: index,
    }));

  const selectedAssetHandler = (type, index) => {
    let assetArrayIn;
    switch (type) {
      case embLabels.ASSETS:
        assetArrayIn = [...CBAssetList];
        break;
      case embLabels.EV_CHARGING_STATION:
        assetArrayIn = [...CBEVList];
        break;
      default:
        break;
    }

    const assetDetailsData = { ...assetDetailsDisplay };
    if (assetArrayIn[index].assetDetails !== undefined) {
      if (assetArrayIn[index].assetDetails.length) {
        assetDetailsData.assetId = assetArrayIn[index].assetDetails[0].assetId;
        assetDetailsData.manufacturer =
          assetArrayIn[index].assetDetails[0].manufacturer;
        assetDetailsData.communicationModel =
          assetArrayIn[index].assetDetails[0].communicationModel;
        assetDetailsData.assetInfo =
          assetArrayIn[index].assetDetails[0].assetInfo;
        assetDetailsData.assetName =
          assetArrayIn[index].assetDetails[0].assetName;
        assetDetailsData.imageLink =
          assetArrayIn[index].assetDetails[0].imageLink;
        setAssetDetailsDisplay(assetDetailsData);
      } else {
        const newAssetDetailsDisplay = { ...assetDetailsDisplay };
        Object.keys(newAssetDetailsDisplay).forEach(
          details => (newAssetDetailsDisplay[details] = "")
        );
        setAssetDetailsDisplay(newAssetDetailsDisplay);
      }
    }
  };

  const selectedAssetHandlerPv = (selectedPv, selectSubPv) => {
    let indexValue = parseInt(selectSubPv - 1);
    let newPvSubData = [];

    switch (selectedPv) {
      case 3:
        newPvSubData = [...PVDetails.pvset1Data];
        break;
      case 4:
        newPvSubData = [...PVDetails.pvset2Data];
        break;
      case 5:
        newPvSubData = [...PVDetails.pvset3Data];
        break;
      default:
        break;
    }

    const assetDetailsData = { ...assetDetailsDisplay };

    if (newPvSubData[indexValue].assetDetails !== undefined) {
      if (newPvSubData[indexValue].assetDetails.length) {
        assetDetailsData.assetId =
          newPvSubData[indexValue].assetDetails[0].assetId;
        assetDetailsData.manufacturer =
          newPvSubData[indexValue].assetDetails[0].manufacturer;
        assetDetailsData.communicationModel =
          newPvSubData[indexValue].assetDetails[0].communicationModel;
        assetDetailsData.assetInfo =
          newPvSubData[indexValue].assetDetails[0].assetInfo;
        assetDetailsData.assetName =
          newPvSubData[indexValue].assetDetails[0].assetName;
        assetDetailsData.imageLink =
          newPvSubData[indexValue].assetDetails[0].imageLink;
        setAssetDetailsDisplay(assetDetailsData);
      } else {
        const newAssetDetailsDisplay = { ...assetDetailsDisplay };
        Object.keys(newAssetDetailsDisplay).forEach(
          details => (newAssetDetailsDisplay[details] = "")
        );
        setAssetDetailsDisplay(newAssetDetailsDisplay);
      }
    }
  };

  //* GET CONFIG DATA

  const assetConfigDataHandler = index => {
    const CBASSETLISTNEW = _.cloneDeep(CBAssetList);
    if (CBASSETLISTNEW[index].configData.name) {
      let assetConfigDataNew = {};
      if (CBASSETLISTNEW[index].assetType === embLabels.UTILITY) {
        assetConfigDataNew = Object.assign({}, configData.utilityConfigData);
      } else if (CBASSETLISTNEW[index].assetType === embLabels.GENSET) {
        assetConfigDataNew = Object.assign({}, configData.gensetConfigData);
      } else if (CBASSETLISTNEW[index].assetType === embLabels.BESS) {
        assetConfigDataNew = Object.assign({}, configData.batteryConfigData);
        assetConfigDataNew.soc_max = defaultPreFillData[0].BESS.soc_max;
        assetConfigDataNew.soc_min = defaultPreFillData[0].BESS.soc_min;
      } else if (CBASSETLISTNEW[index].assetType === embLabels.CHP) {
        assetConfigDataNew = Object.assign({}, configData.chpConfigData);
      } else {
        assetConfigDataNew = Object.assign({}, configData.sampleConfigData);
      }

      assetConfigDataNew = CBASSETLISTNEW[index].configData;
      assetConfigDataNew.name = CBASSETLISTNEW[index].configData.name;
      assetConfigDataNew.description =
        CBASSETLISTNEW[index].configData.description;
      const derHistory = JSON.parse(localStorage.getItem("derHistory"));
      if (index === 0) {
        Object.entries(derHistory[embLabels.UTILITY]).forEach(
          ([key, value]) => {
            assetConfigDataNew[key] = value;
          }
        );
      } else if (index === 1) {
        Object.entries(derHistory[embLabels.GENSET]).forEach(([key, value]) => {
          assetConfigDataNew[key] = value;
        });
      } else if (index === 2) {
        Object.entries(derHistory[embLabels.BESS]).forEach(([key, value]) => {
          assetConfigDataNew[key] = value;
        });
      } else if (index === 6) {
        Object.entries(derHistory[embLabels.CHP]).forEach(([key, value]) => {
          assetConfigDataNew[key] = value;
        });
      }

      setConfigData(prevState => ({
        ...prevState,
        configData: assetConfigDataNew,
      }));
    } else {
      const assetConfigDataNew = { ...configData.sampleConfigData };
      assetConfigDataNew.name = "";
      assetConfigDataNew.description = "";
      setConfigData(prevState => ({
        ...prevState,
        configData: assetConfigDataNew,
      }));
    }
  };

  const loadConfigDataHandler = index => {
    const loadArray = [...CBLoadList];

    if (loadArray[index].configData.name) {
      let cbConfigDataNew = { ...configData.loadConfigData };
      cbConfigDataNew.name = loadArray[index].configData.name;
      cbConfigDataNew.description = loadArray[index].configData.description;
      cbConfigDataNew.maximum_available_power =
        loadArray[index].configData.maximum_available_power;

      let derStatus = JSON.parse(localStorage.getItem("derStatus"));
      let loadHistory = [];
      if (derStatus[4] === true) {
        loadHistory = JSON.parse(localStorage.getItem("loadHistory"));
        if (loadHistory[index] !== undefined && loadHistory[index] !== null) {
          Object.entries(loadHistory[index]).forEach(([key, value]) => {
            cbConfigDataNew[key] = value;
          });
        }
      }

      setConfigData(prevState => ({
        ...prevState,
        configData: cbConfigDataNew,
      }));
    } else {
      let cbConfigDataNew = { ...configData.loadConfigData };
      cbConfigDataNew.name = "";
      cbConfigDataNew.description = "";
      cbConfigDataNew.maximum_available_power = 0;
      setConfigData(prevState => ({
        ...prevState,
        configData: cbConfigDataNew,
      }));
    }
  };

  const evConfigDataHandler = index => {
    if (CBEVList[index].configData.name) {
      let evConfigDataNew = { ...configData.evConfigData };
      evConfigDataNew = CBEVList[index].configData;
      evConfigDataNew.name = CBEVList[index].configData.name;
      evConfigDataNew.description = CBEVList[index].configData.description;
      evConfigDataNew.maximum_available_power =
        CBEVList[index].configData.maximum_available_power;

      let derHistory = JSON.parse(localStorage.getItem("derHistory"));

      Object.entries(derHistory["EV"]).forEach(([key, value]) => {
        evConfigDataNew[key] = value;
      });

      setConfigData(prevState => ({
        ...prevState,
        configData: evConfigDataNew,
      }));
    } else {
      const evConfigDataNew = { ...configData.evConfigData };
      evConfigDataNew.name = "";
      evConfigDataNew.description = "";
      setConfigData(prevState => ({
        ...prevState,
        configData: evConfigDataNew,
      }));
    }
  };

  //* GET CB CONFIG DATA

  const cbConfigDataHandler = index => {
    const CBASSETLISTNEW = _.cloneDeep(CBAssetList);
    if (CBASSETLISTNEW[index]) {
      const cbConfigDataNew = { ...configData.sampleCbConfigData };
      if (siteType === embLabels.PV_HYBRIDIZATION) {
        cbConfigDataNew.name = CBASSETLISTNEW[index].powerMeterData.name;
        cbConfigDataNew.description =
          CBASSETLISTNEW[index].powerMeterData.description;
      } else if (
        [
          embLabels.ISLANDABLE,
          embLabels.GRID_CONNECTED,
          embLabels.OFF_GRID,
        ].includes(siteType)
      ) {
        cbConfigDataNew.name = CBASSETLISTNEW[index].cbConfigData.name;
        cbConfigDataNew.description =
          CBASSETLISTNEW[index].cbConfigData.description;
        cbConfigDataNew.motorized =
          CBASSETLISTNEW[index].cbConfigData.motorized;
      } else {
        //* do nothing
      }

      const derStatus = JSON.parse(localStorage.getItem("derStatus"));
      let cbAssetHistory = [];
      if (derStatus[6]) {
        if (localStorage.getItem("cbAssetHistory") === "") {
          cbAssetHistory = [];
        } else {
          cbAssetHistory = JSON.parse(localStorage.getItem("cbAssetHistory"));
        }
        if (
          cbAssetHistory[index] !== undefined &&
          cbAssetHistory[index] !== null
        ) {
          Object.entries(cbAssetHistory[index]).forEach(([key, value]) => {
            cbConfigDataNew[key] = value;
          });
        }
      }

      setConfigData(prevState => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    } else {
      const cbConfigDataNew = { ...configData.sampleCbConfigData };
      if (siteType === embLabels.PV_HYBRIDIZATION) {
        cbConfigDataNew.name = "";
        cbConfigDataNew.description = "";
      } else if (
        [
          embLabels.ISLANDABLE,
          embLabels.GRID_CONNECTED,
          embLabels.OFF_GRID,
        ].includes(siteType)
      ) {
        cbConfigDataNew.name = "";
        cbConfigDataNew.description = "";
        cbConfigDataNew.motorized = true;
      } else {
        //* do nothing
      }
      setConfigData(prevState => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    }
  };

  const loadCbConfigDataHandler = index => {
    const loadArray = [...CBLoadList];
    if (loadArray[index]) {
      let cbConfigDataNew = { ...configData.cbConfigData };
      cbConfigDataNew.name = loadArray[index].cbConfigData.name;
      cbConfigDataNew.description = loadArray[index].cbConfigData.description;
      cbConfigDataNew.motorized = loadArray[index].cbConfigData.motorized;

      let derStatus = JSON.parse(localStorage.getItem("derStatus"));
      let cbLoadHistory = [];
      if (derStatus[6] === true) {
        if (localStorage.getItem("cbLoadHistory") === "") {
          cbLoadHistory = [];
        } else {
          cbLoadHistory = JSON.parse(localStorage.getItem("cbLoadHistory"));
        }

        if (
          cbLoadHistory[index] !== undefined &&
          cbLoadHistory[index] !== null
        ) {
          Object.entries(cbLoadHistory[index]).forEach(([key, value]) => {
            cbConfigDataNew[key] = value;
          });
        }
      }

      setConfigData(prevState => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    } else {
      let cbConfigDataNew = { ...configData.cbConfigData };
      cbConfigDataNew.name = "";
      cbConfigDataNew.description = "";
      cbConfigDataNew.motorized = true;
      setConfigData(prevState => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    }
  };

  const evCbConfigDataHandler = index => {
    const cbArray = [...CBEVList];
    if (cbArray[index]) {
      let cbConfigDataNew = { ...configData.sampleCbConfigData };
      cbConfigDataNew.name = cbArray[index].cbConfigData.name;
      cbConfigDataNew.description = cbArray[index].cbConfigData.description;
      cbConfigDataNew.motorized = cbArray[index].cbConfigData.motorized;

      let derStatus = JSON.parse(localStorage.getItem("derStatus"));
      let cbEvHistory = [];
      if (derStatus[6]) {
        if (localStorage.getItem("cbEvHistory") === "") {
          cbEvHistory = [];
        } else {
          cbEvHistory = JSON.parse(localStorage.getItem("cbEvHistory"));
        }

        if (cbEvHistory[index] !== undefined && cbEvHistory[index] !== null) {
          Object.entries(cbEvHistory[index]).forEach(([key, value]) => {
            cbConfigDataNew[key] = value;
          });
        }
      }
      setConfigData(prevState => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    } else {
      let cbConfigDataNew = { ...configData.sampleCbConfigData };
      cbConfigDataNew.name = "";
      cbConfigDataNew.description = "";
      cbConfigDataNew.motorized = true;
      setConfigData(prevState => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    }
  };

  const pmConfigDataHandler = index => {
    const CBASSETLISTNEW = _.cloneDeep(CBAssetList);
    if (CBASSETLISTNEW[index]) {
      const cbConfigDataNew = { ...configData.powerMeterData };
      cbConfigDataNew.name = CBASSETLISTNEW[index].powerMeterData.name;
      cbConfigDataNew.description =
        CBASSETLISTNEW[index].powerMeterData.description;

      const derStatus = JSON.parse(localStorage.getItem("derStatus"));
      let pmAssetHistory = [];
      if (derStatus[8]) {
        if (localStorage.getItem("pmAssetHistory") === "") {
          pmAssetHistory = [];
        } else {
          pmAssetHistory = JSON.parse(localStorage.getItem("pmAssetHistory"));
        }
        if (
          pmAssetHistory[index] !== undefined &&
          pmAssetHistory[index] !== null
        ) {
          Object.entries(pmAssetHistory[index]).forEach(([key, value]) => {
            cbConfigDataNew[key] = value;
          });
        }
      }

      setConfigData(prevState => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    } else {
      const cbConfigDataNew = { ...configData.sampleCbConfigData };
      cbConfigDataNew.name = "";
      cbConfigDataNew.description = "";
      setConfigData(prevState => ({
        ...prevState,
        cbConfigData: cbConfigDataNew,
      }));
    }
  };

  //* change handlers

  const handleUtilityChange = e => {
    const dataConfig = { ...configData.configData };
    const onlyNumber = /^[0-9]?\d*\.?\d*$/;
    switch (e.target.name) {
      case "utilityCO2":
        if (!isNaN(e.target.value) && e.target.value >= 0) {
          dataConfig[e.target.name] = e.target.value;
          setConfigData(prevState => ({
            ...prevState,
            configData: dataConfig,
          }));

          assetEditStatusUpdateDER(e, embLabels.UTILITY, true);
        }
        break;
      case "maximum_available_power":
        if (onlyNumber.test(e.target.value)) {
          dataConfig[e.target.name] = e.target.value;
          setConfigData(prevState => ({
            ...prevState,
            configData: dataConfig,
          }));

          assetEditStatusUpdateDER(e, embLabels.UTILITY, true);
        }
        break;
      case "externalId":
        const { value } = e.target;
        if (value.trim() !== "") {
          if (alphanumericRegWithHyphen.test(value)) {
            setGsxExternalId(prevState => ({
              ...prevState,
              utilityExternalId: value,
            }));
          }
          assetEditStatusUpdateDER(e, embLabels.UTILITY, true);
        } else {
          setGsxExternalId(prevState => ({
            ...prevState,
            utilityExternalId: "",
          }));
        }

        break;
      default:
        dataConfig[e.target.name] = e.target.value;
        if (isValidName(e.target.value)) {
          setConfigData(prevState => ({
            ...prevState,
            configData: dataConfig,
          }));

          assetEditStatusUpdateDER(e, embLabels.UTILITY, true);
        }
    }
  };

  const handleGensetChange = e => {
    const dataConfig = { ...configData.configData };
    const onlyNumber = /^[0-9]?\d*\.?\d*$/;
    if (e.target.name === "gensetCO2") {
      if (!isNaN(e.target.value) && e.target.value >= 0) {
        dataConfig[e.target.name] = e.target.value;
        setConfigData(prevState => ({ ...prevState, configData: dataConfig }));
        assetEditStatusUpdateDER(e, embLabels.GENSET, true);
      }
    } else if (
      [
        "starting_timer",
        "cooling_timer",
        "minimum_running_period",
        "max_power",
      ].includes(e.target.name)
    ) {
      if (onlyNumber.test(e.target.value)) {
        if (parseInt(e.target.value) === 0) {
          dataConfig[e.target.name] = e.target.value;
        } else {
          dataConfig[e.target.name] = parseInt(e.target.value)
            ? parseInt(e.target.value)
            : "";
        }
        setConfigData(prevState => ({ ...prevState, configData: dataConfig }));
        assetEditStatusUpdateDER(e, embLabels.GENSET, true);
      }
    } else if (e.target.name === "externalId") {
      const { value } = e.target;
      if (value.trim() !== "") {
        if (alphanumericRegWithHyphen.test(value)) {
          setGsxExternalId(prevState => ({
            ...prevState,
            gensetExternalId: value,
          }));
        }
        assetEditStatusUpdateDER(e, embLabels.GENSET, true);
      } else {
        setGsxExternalId(prevState => ({
          ...prevState,
          gensetExternalId: "",
        }));
      }
    } else {
      dataConfig[e.target.name] = e.target.value;
      if (isValidName(e.target.value)) {
        setConfigData(prevState => ({ ...prevState, configData: dataConfig }));
        assetEditStatusUpdateDER(e, embLabels.GENSET, true);
      }
    }
  };

  const handleBatteryChange = e => {
    const dataConfig = { ...configData.configData };
    const onlyNumber = /^[0-9]?\d*\.?\d*$/;
    if (
      ["max_power", "capacity", "soc_max", "soc_min"].includes(e.target.name)
    ) {
      if (onlyNumber.test(e.target.value)) {
        dataConfig[e.target.name] = e.target.value;
        assetEditStatusUpdateDER(e, embLabels.BESS, true);
      } else {
        dataConfig[e.target.name] = dataConfig[e.target.name];
      }
      setConfigData(prevState => ({ ...prevState, configData: dataConfig }));
    } else {
      dataConfig[e.target.name] = e.target.value;
      if (isValidName(e.target.value)) {
        setConfigData(prevState => ({
          ...prevState,
          configData: dataConfig,
        }));
      }
      assetEditStatusUpdateDER(e, embLabels.BESS, true);
    }
  };

  const handleChpChange = e => {
    let dataConfig = { ...configData.configData };
    const onlyNumber = /^[0-9]?\d*\.?\d*$/;
    switch (e.target.name) {
      case "nominal_power":
        if (onlyNumber.test(e.target.value)) {
          dataConfig[e.target.name] = e.target.value;

          assetEditStatusUpdateDER(e, embLabels.CHP, true);
        } else {
          dataConfig[e.target.name] = configData.configData.nominal_power;
        }
        setConfigData(prevState => ({ ...prevState, configData: dataConfig }));
        break;

      default:
        dataConfig[e.target.name] = e.target.value;
        if (isValidName(e.target.value)) {
          setConfigData(prevState => ({
            ...prevState,
            configData: dataConfig,
          }));
          assetEditStatusUpdateDER(e, embLabels.CHP, true);
        }
    }
  };

  const handlePvChange = e => {
    if (isValidName(e.target.value)) {
      PVDetailsDispatch({
        type: ACTIONS.HANDLE_PV_CHANGE,
        payload: {
          data: e.target,
        },
      });
    }
  };

  const handleLoadChange = e => {
    let newConfigData = { ...configData.configData };
    const onlyNumber = /^[0-9]?\d*\.?\d*$/;
    switch (e.target.name) {
      case "maximum_available_power":
        if (onlyNumber.test(e.target.value)) {
          newConfigData[e.target.name] = e.target.value;
          assetEditStatusUpdateLoad(e, true, selectedValues.selectedLoad);
        } else {
          newConfigData[e.target.name] =
            configData.configData.maximum_available_power;
        }
        setConfigData(prevState => ({
          ...prevState,
          configData: newConfigData,
        }));

        break;
      default:
        newConfigData[e.target.name] = e.target.value;
        if (isValidName(e.target.value)) {
          setConfigData(prevState => ({
            ...prevState,
            configData: newConfigData,
          }));
          assetEditStatusUpdateLoad(e, true, selectedValues.selectedLoad);
        }
    }
  };

  const handleEvChange = e => {
    let dataConfig = { ...configData.configData };
    const onlyNumber = /^[0-9]?\d*\.?\d*$/;
    switch (e.target.name) {
      case "maximum_available_power":
        if (onlyNumber.test(e.target.value)) {
          dataConfig[e.target.name] = e.target.value;
          assetEditStatusUpdateDER(e, embLabels.EV, true);
        } else {
          dataConfig[e.target.name] =
            configData.configData.maximum_available_power;
        }
        setConfigData(prevState => ({
          ...prevState,
          configData: dataConfig,
          evConfigData: dataConfig,
        }));
        break;
      default:
        dataConfig[e.target.name] = e.target.value;
        if (isValidName(e.target.value)) {
          setConfigData(prevState => ({
            ...prevState,
            configData: dataConfig,
            evConfigData: dataConfig,
          }));
          assetEditStatusUpdateDER(e, embLabels.EV, true);
        }
    }
  };

  const handleCBConfigChange = e => {
    const newCbConfigData = { ...configData.cbConfigData };
    if (e.target.name === "motorized") {
      newCbConfigData[e.target.name] = true;
    } else {
      newCbConfigData[e.target.name] = e.target.value;
    }
    if (isValidName(e.target.value)) {
      setConfigData(prevState => ({
        ...prevState,
        cbConfigData: newCbConfigData,
      }));
    }
  };

  const handleCBConfigChangeLoad = e => {
    const newCbConfigData = { ...configData.cbConfigData };
    if (e.target.name === "motorized") {
      newCbConfigData[e.target.name] = e.target.checked;
      newCbConfigData.loadCategory = 0;
      newCbConfigData.loadCategory = 0;
    } else {
      newCbConfigData[e.target.name] = e.target.value;
    }

    if (isValidName(e.target.value)) {
      setConfigData(prevState => ({
        ...prevState,
        cbConfigData: newCbConfigData,
      }));
    }
  };

  const handleCBConfigChangeEv = e => {
    const newCbConfigData = { ...configData.cbConfigData };
    if (e.target.name === "motorized") {
      newCbConfigData[e.target.name] = e.target.checked;
      newCbConfigData.loadCategory = 0;
      newCbConfigData.loadCategory = 0;
    } else {
      newCbConfigData[e.target.name] = e.target.value;
    }

    if (isValidName(e.target.value)) {
      setConfigData(prevState => ({
        ...prevState,
        cbConfigData: newCbConfigData,
      }));
    }
  };

  //* Update config data's

  const updateUtilitytConfig = () => {
    if (
      checkForDuplicateName(
        configData.configData.name,
        CBAssetList[0].configData.name
      )
    ) {
      callSnackbar(
        `error`,
        `"${configData.configData.name}" already exists, please provide an unique name`
      );
    } else {
      if (
        configData.configData.name &&
        parseInt(configData.configData.utilityCO2) >=
          defaultsMinMax.utilityCO2Min &&
        parseInt(configData.configData.utilityCO2) <=
          defaultsMinMax.utilityCO2Max &&
        parseInt(configData.configData.maximum_available_power) >=
          defaultsMinMax.maxAvailablePowerUtilityMin &&
        parseInt(configData.configData.maximum_available_power) <=
          defaultsMinMax.maxAvailablePowerUtilityMax
      ) {
        CBAssetListDispatch({
          type: ACTIONS.UPDATE_ASSET_CONFIG,
          payload: {
            selectedAsset: selectedValues.selectedAsset,
            configData: configData.configData,
          },
        });
        if (duplicateUpdate.der === "") {
          setDuplicateUpdate({
            der: 0,
            load: "",
            pv: "",
            ev: "",
          });
        }
        updateDerHistory(0);
        updateDerStatus(0);
        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      } else if (
        configData.configData.name === "" ||
        configData.configData.maximum_available_power.toString() === "" ||
        configData.configData.utilityCO2.toString() === ""
      ) {
        callSnackbar("error", embLabels.mandatoryFields);
      } else if (
        parseInt(configData.configData.utilityCO2) <
          defaultsMinMax.utilityCO2Min ||
        parseInt(configData.configData.utilityCO2) >
          defaultsMinMax.utilityCO2Max
      ) {
        callSnackbar(
          `error`,
          `Utility CO2 Emission should be in range(${defaultsMinMax.utilityCO2Min}-${defaultsMinMax.utilityCO2Max} g/kWh)`
        );
      } else if (
        parseInt(configData.configData.maximum_available_power) >=
          defaultsMinMax.maxAvailablePowerUtilityMin ||
        parseInt(configData.configData.maximum_available_power) <=
          defaultsMinMax.maxAvailablePowerUtilityMax
      ) {
        callSnackbar(
          `error`,
          `Maximum Available Power should be in range
          (${defaultsMinMax.maxAvailablePowerUtilityMin}-${defaultsMinMax.maxAvailablePowerUtilityMax} kW)`
        );
      } else {
        callSnackbar("error", embLabels.mandatoryFields);
      }
    }
  };

  const updateGensetConfig = () => {
    if (
      checkForDuplicateName(
        configData.configData.name,
        CBAssetList[1].configData.name
      )
    ) {
      callSnackbar(
        "error",
        `"${configData.configData.name}" already exists, please provide an unique name`
      );
    } else {
      if (
        configData.configData.name &&
        parseInt(configData.configData.max_power) >=
          defaultsMinMax.maxPowerGensetMin &&
        parseInt(configData.configData.max_power) <=
          defaultsMinMax.maxPowerGensetMax &&
        parseInt(configData.configData.gensetCO2) >=
          defaultsMinMax.gensetCO2Min &&
        parseInt(configData.configData.gensetCO2) <=
          defaultsMinMax.gensetCO2Max &&
        parseInt(configData.configData.starting_timer) >=
          defaultsMinMax.startUpGensetMin &&
        parseInt(configData.configData.starting_timer) <=
          defaultsMinMax.startUpGensetMax &&
        parseInt(configData.configData.cooling_timer) >=
          defaultsMinMax.coolingDurationGensetMin &&
        parseInt(configData.configData.cooling_timer) <=
          defaultsMinMax.coolingDurationGensetMax &&
        parseInt(configData.configData.minimum_running_period) >=
          defaultsMinMax.minRunningDurationGensetMin &&
        parseInt(configData.configData.minimum_running_period) <=
          defaultsMinMax.minRunningDurationGensetMax
      ) {
        CBAssetListDispatch({
          type: ACTIONS.UPDATE_ASSET_CONFIG,
          payload: {
            selectedAsset: selectedValues.selectedAsset,
            configData: configData.configData,
          },
        });
        if (duplicateUpdate.der === "") {
          setDuplicateUpdate({
            der: 1,
            load: "",
            pv: "",
            ev: "",
          });
        }

        // newGensetData[this.state.selectedAsset].communicationData =
        //   Object.assign({}, this.state.communicationData);

        updateDerHistory(1);
        updateDerStatus(1);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      } else if (
        configData.configData.name.toString() === "" ||
        configData.configData.max_power.toString() === "" ||
        configData.configData.gensetCO2.toString() === "" ||
        configData.configData.starting_timer.toString() === "" ||
        configData.configData.cooling_timer.toString() === "" ||
        configData.configData.minimum_running_period.toString() === ""
      ) {
        callSnackbar("error", embLabels.mandatoryFields);
      } else if (
        parseInt(configData.configData.max_power) <
          defaultsMinMax.maxPowerGensetMin ||
        parseInt(configData.configData.max_power) >
          defaultsMinMax.maxPowerGensetMax
      ) {
        callSnackbar(
          `error`,
          `Max Power should be in range (${defaultsMinMax.maxPowerGensetMin}-${defaultsMinMax.maxPowerGensetMax} kW)`
        );
      } else if (
        parseInt(configData.configData.gensetCO2) <
          defaultsMinMax.gensetCO2Min ||
        parseInt(configData.configData.gensetCO2) > defaultsMinMax.gensetCO2Max
      ) {
        callSnackbar(
          `error`,
          `Genset CO2 should be in range (${defaultsMinMax.gensetCO2Min}-${defaultsMinMax.gensetCO2Max} kg/MWh)`
        );
      } else if (
        parseInt(configData.configData.starting_timer) <
          defaultsMinMax.startUpGensetMin ||
        parseInt(configData.configData.starting_timer) >
          defaultsMinMax.startUpGensetMax
      ) {
        callSnackbar(
          `error`,
          `Start-up duration should be in range (${defaultsMinMax.startUpGensetMin}-${defaultsMinMax.startUpGensetMax} s)`
        );
      } else if (
        parseInt(configData.configData.cooling_timer) <
          defaultsMinMax.coolingDurationGensetMin ||
        parseInt(configData.configData.cooling_timer) >
          defaultsMinMax.coolingDurationGensetMax
      ) {
        callSnackbar(
          `error`,
          `Cooling Duration should be in range (${defaultsMinMax.coolingDurationGensetMin}-${defaultsMinMax.coolingDurationGensetMax} s)`
        );
      } else if (
        parseInt(configData.configData.minimum_running_period) <
          defaultsMinMax.minRunningDurationGensetMin ||
        parseInt(configData.configData.minimum_running_period) >
          defaultsMinMax.minRunningDurationGensetMax
      ) {
        callSnackbar(
          `error`,
          `Minimum running duration should be in range (${defaultsMinMax.minRunningDurationGensetMin}-${defaultsMinMax.minRunningDurationGensetMax} s)`
        );
      } else {
        callSnackbar("error", embLabels.mandatoryFields);
      }
    }
  };

  const updateBatteryConfig = () => {
    if (
      checkForDuplicateName(
        configData.configData.name,
        CBAssetList[2].configData.name
      )
    ) {
      callSnackbar(
        `error`,
        `"${configData.configData.name}" already exists, please provide an unique name"`
      );
    } else {
      if (
        configData.configData.name &&
        parseInt(configData.configData.max_power) >=
          defaultsMinMax.maxPowerBessMin &&
        parseInt(configData.configData.max_power) <=
          defaultsMinMax.maxPowerBessMax &&
        parseInt(configData.configData.capacity) >=
          defaultsMinMax.capacityBessMin &&
        parseInt(configData.configData.capacity) <=
          defaultsMinMax.capacityBessMax &&
        parseInt(configData.configData.soc_max) >= defaultsMinMax.socMaxMin &&
        parseInt(configData.configData.soc_max) <= defaultsMinMax.socMaxMax &&
        parseInt(configData.configData.soc_min) >= defaultsMinMax.socMinMin &&
        parseInt(configData.configData.soc_min) <= defaultsMinMax.socMinMax
      ) {
        CBAssetListDispatch({
          type: ACTIONS.UPDATE_ASSET_CONFIG,
          payload: {
            selectedAsset: selectedValues.selectedAsset,
            configData: configData.configData,
          },
        });

        // newBatteryData[this.state.selectedAsset].communicationData =
        // Object.assign({}, this.state.batteryCommunicationData);
        if (duplicateUpdate.der === "") {
          setDuplicateUpdate({
            der: 2,
            load: "",
            pv: "",
            ev: "",
          });
        }

        updateDerHistory(2);
        updateDerStatus(2);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      } else if (
        [
          configData.configData.name,
          configData.configData.max_power.toString(),
          configData.configData.capacity.toString(),
          configData.configData.soc_max.toString(),
          configData.configData.soc_min.toString(),
        ].includes("")
      ) {
        callSnackbar(`error`, embLabels.mandatoryFields);
      } else if (
        parseInt(configData.configData.max_power) <
          defaultsMinMax.maxPowerBessMin ||
        parseInt(configData.configData.max_power) >
          defaultsMinMax.maxPowerBessMax
      ) {
        callSnackbar(
          `error`,
          `${embLabels.maxPowerRange} ${embLabels.shouldBeInRangeOpen}${defaultsMinMax.maxPowerBessMin}-${defaultsMinMax.maxPowerBessMax}
           ${embLabels.kiloWatt}${embLabels.shouldBeInRangeClose}`
        );
      } else if (
        parseInt(configData.configData.capacity) <
          defaultsMinMax.capacityBessMin ||
        parseInt(configData.configData.capacity) >
          defaultsMinMax.capacityBessMax
      ) {
        callSnackbar(
          `error`,
          `${embLabels.capacity} ${embLabels.shouldBeInRangeOpen}${defaultsMinMax.capacityBessMin}-${defaultsMinMax.capacityBessMax}
           ${embLabels.kiloWattHour}${embLabels.shouldBeInRangeClose}`
        );
      } else if (
        parseInt(configData.configData.soc_max) < defaultsMinMax.socMaxMin ||
        parseInt(configData.configData.soc_max) > defaultsMinMax.socMaxMax
      ) {
        callSnackbar(
          `error`,
          `${embLabels.socMax} ${embLabels.shouldBeInRangeOpen}${defaultsMinMax.socMaxMin}-${defaultsMinMax.socMaxMax}
           ${embLabels.perentageOfMaxpower}${embLabels.shouldBeInRangeClose}`
        );
      } else if (
        parseInt(configData.configData.soc_min) < defaultsMinMax.socMinMin ||
        parseInt(configData.configData.soc_min) > defaultsMinMax.socMinMax
      ) {
        callSnackbar(
          `error`,
          `${embLabels.socMin} ${embLabels.shouldBeInRangeOpen}${defaultsMinMax.socMinMin}-${defaultsMinMax.socMinMax}
           ${embLabels.perentageOfMaxpower}${embLabels.shouldBeInRangeClose}`
        );
      } else {
        callSnackbar("error", embLabels.mandatoryFields);
      }
    }
  };

  const updateChpConfig = () => {
    if (
      checkForDuplicateName(
        configData.configData.name,
        CBAssetList[6].configData.name
      )
    ) {
      callSnackbar(
        `error`,
        `"${configData.configData.name}" already exists, please provide an unique name"`
      );
    } else {
      if (
        configData.configData.name &&
        parseInt(configData.configData.nominal_power) >=
          defaultsMinMax.chpNominalPowerMin &&
        parseInt(configData.configData.nominal_power) <=
          defaultsMinMax.chpNominalPowerMax
      ) {
        CBAssetListDispatch({
          type: ACTIONS.UPDATE_ASSET_CONFIG,
          payload: {
            selectedAsset: selectedValues.selectedAsset,
            configData: configData.configData,
          },
        });
        if (duplicateUpdate.der === "") {
          setDuplicateUpdate({
            der: 6,
            load: "",
            pv: "",
            ev: "",
          });
        }

        updateDerHistory(6);
        updateDerStatus(7);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      } else if (
        configData.configData.name === "" ||
        configData.configData.nominal_power.toString() === ""
      ) {
        callSnackbar("error", embLabels.mandatoryFields);
      } else if (
        parseInt(configData.configData.nominal_power) <
          defaultsMinMax.chpNominalPowerMin ||
        parseInt(configData.configData.nominal_power) >
          defaultsMinMax.chpNominalPowerMax
      ) {
        callSnackbar(
          `error`,
          `${embLabels.nominalPowerRange}${embLabels.shouldBeInRangeOpen}${defaultsMinMax.chpNominalPowerMin}-${defaultsMinMax.chpNominalPowerMax}${embLabels.kiloWatt}${embLabels.shouldBeInRangeClose}
      `
        );
      } else {
        callSnackbar("error", embLabels.mandatoryFields);
      }
    }
  };

  const arrayMaxPowerFinder = () => {
    switch (parseInt(selectedValues.selectedPv)) {
      case 3:
        return PVDetails.pvset1Data.reduce(
          (prev, cur) => prev + +cur.max_power,
          0
        );
      case 4:
        return PVDetails.pvset2Data.reduce(
          (prev, cur) => prev + +cur.max_power,
          0
        );
      case 5:
        return PVDetails.pvset3Data.reduce(
          (prev, cur) => prev + +cur.max_power,
          0
        );
      default:
        return 0;
    }
  };

  const updatePvArrayConfig = () => {
    const index = PVDetails.selectSubPv - 1;
    const selectedPv = PVDetails.selectedPv;
    if (
      PVDetails.pvConfig.groupName &&
      arrayMaxPowerFinder() < parseInt(PVDetails.pvConfig.group_max_power)
    ) {
      callSnackbar(
        "error",
        `Array Max Power should not exceed sum of all pv inverter max power.`
      );
    } else if (
      PVDetails.pvConfig.groupName &&
      parseInt(PVDetails.pvConfig.group_max_power) >= 0
    ) {
      PVDetailsDispatch({
        type: ACTIONS.UPDATE_PV_ARRAY,
      });

      updatePvHistory(index, selectedPv);
      const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
      setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
    } else {
      callSnackbar("error", embLabels.mandatoryFields);
    }
  };

  const updatePvConfig = () => {
    const pvNumber = PVDetails.selectedPv;
    let whichSubPv = PVDetails.selectSubPv - 1;
    let removeName;

    switch (pvNumber) {
      case 3:
        removeName = PVDetails.pvset1Data[whichSubPv].name;
        break;
      case 4:
        removeName = PVDetails.pvset2Data[whichSubPv].name;
        break;
      case 5:
        removeName = PVDetails.pvset3Data[whichSubPv].name;
        break;
      default:
        break;
    }

    if (checkForDuplicateName(PVDetails.pvConfig.name, removeName)) {
      callSnackbar(
        `error`,
        `"${PVDetails.pvConfig.name}" already exists, please provide an unique name`
      );
    } else {
      if (
        PVDetails.pvConfig.name &&
        parseInt(PVDetails.pvConfig.max_power) >=
          defaultsMinMax.maxPowerPvMin &&
        parseInt(PVDetails.pvConfig.max_power) <= defaultsMinMax.maxPowerPvMax
      ) {
        setDuplicateUpdate({
          der: "",
          load: "",
          pv: "pv",
          ev: "",
        });
        PVDetailsDispatch({
          type: ACTIONS.UPDATE_PV_CONFIG_SAVE,
        });
        PVDetailsDispatch({
          type: ACTIONS.PV_CONFIGURED_STATUS,
        });

        updatePvHistory(PVDetails.selectSubPv - 1, PVDetails.selectedPv);
        updatePvHistoryGroupPower(PVDetails.selectedPv);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      } else if (
        PVDetails.pvConfig.name.toString() === "" ||
        PVDetails.pvConfig.max_power.toString() === ""
      ) {
        callSnackbar("error", embLabels.mandatoryFields);
      } else if (
        parseInt(PVDetails.pvConfig.max_power) < defaultsMinMax.maxPowerPvMin ||
        parseInt(PVDetails.pvConfig.max_power) > defaultsMinMax.maxPowerPvMax
      ) {
        callSnackbar(
          `error`,
          `Max Power should be in range(${defaultsMinMax.maxPowerPvMin}-${defaultsMinMax.maxPowerPvMax} kW)`
        );
      } else {
        callSnackbar("error", embLabels.mandatoryFields);
      }
    }
  };

  const updateLoadConfig = () => {
    if (
      checkForDuplicateName(
        configData.configData.name,
        CBLoadList[selectedValues.selectedLoad].configData.name
      )
    ) {
      callSnackbar(
        `error`,
        `"${configData.configData.name}" already exists, please provide an unique name"`
      );
    } else {
      if (
        configData.configData.name &&
        parseInt(configData.configData.maximum_available_power) >=
          defaultsMinMax.maxPowerLoadMin &&
        parseInt(configData.configData.maximum_available_power) <=
          defaultsMinMax.maxPowerLoadMax
      ) {
        CBLoadListDispatch({
          type: ACTIONS.UPDATE_LOAD_CONFIG,
          payload: {
            selectedLoad: selectedValues.selectedLoad,
            configData: configData.configData,
          },
        });

        setDuplicateUpdate({
          der: "",
          load: selectedValues.selectedLoad,
          pv: "",
          ev: "",
        });

        updateLoadHistory(selectedValues.selectedLoad);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      } else if (
        configData.configData.name === "" ||
        configData.configData.maximum_available_power.toString() === ""
      ) {
        callSnackbar("error", embLabels.mandatoryFields);
      } else if (
        parseInt(configData.configData.maximum_available_power) <
          defaultsMinMax.maxPowerLoadMin ||
        parseInt(configData.configData.maximum_available_power) >
          defaultsMinMax.maxPowerLoadMax
      ) {
        callSnackbar(
          `error`,
          `Maximum Available Power should be in range (${defaultsMinMax.maxPowerLoadMin}-${defaultsMinMax.maxPowerLoadMax} kW)`
        );
      } else {
        callSnackbar("error", embLabels.mandatoryFields);
      }
    }
  };

  const updateEvConfig = () => {
    if (
      checkForDuplicateName(
        configData.configData.name,
        CBEVList[0].configData.name
      )
    ) {
      callSnackbar(
        `error`,
        `"${configData.configData.name}" already exists, please provide an unique name`
      );
    } else {
      if (
        configData.configData.name &&
        parseInt(configData.configData.maximum_available_power) >=
          defaultsMinMax.maxPowerEvMin &&
        parseInt(configData.configData.maximum_available_power) <=
          defaultsMinMax.maxPowerEvMax
      ) {
        CBEVListDispatch({
          type: ACTIONS.UPDATE_EV_CONFIG,
          payload: {
            selectedEv: selectedValues.selectedEv,
            configData: configData.configData,
          },
        });
        setDuplicateUpdate({
          der: "",
          load: "",
          pv: "",
          ev: selectedValues.selectedEv,
        });
        updateDerHistory(5);
        updateDerStatus(5);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
      } else if (
        configData.configData.name === "" ||
        configData.configData.maximum_available_power.toString() === ""
      ) {
        callSnackbar("error", embLabels.mandatoryFields);
      } else if (
        parseInt(configData.configData.maximum_available_power) <
          defaultsMinMax.maxPowerEvMin ||
        parseInt(configData.configData.maximum_available_power) >
          defaultsMinMax.maxPowerEvMax
      ) {
        callSnackbar(
          `error`,
          `Maximum Available Power should be in range (${defaultsMinMax.maxPowerEvMin}-${defaultsMinMax.maxPowerEvMax} kW)`
        );
      } else {
        callSnackbar("error", embLabels.mandatoryFields);
      }
    }
  };

  const updateCbConfigData = () => {
    if (configData.cbConfigData.name) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_CB_CONFIG,
        payload: {
          selectedAssetCB: selectedValues.selectedAssetCB,
          cbConfigData: configData.cbConfigData,
        },
      });

      updateAssetCbHistory(selectedValues.selectedAssetCB);

      const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
      setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
    } else {
      callSnackbar("error", embLabels.mandatoryFields);
    }
  };

  const updateEvCbConfigData = () => {
    if (configData.cbConfigData.name) {
      CBEVListDispatch({
        type: ACTIONS.UPDATE_EV_CB_CONFIG,
        payload: {
          selectedEvCB: selectedValues.selectedEvCB,
          cbConfigData: configData.cbConfigData,
        },
      });

      updateEvCbHistory(selectedValues.selectedEvCB);
      const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
      setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
    } else {
      callSnackbar("error", embLabels.mandatoryFields);
    }
  };

  const updateLoadCbConfigData = () => {
    if (configData.cbConfigData.name) {
      CBLoadListDispatch({
        type: ACTIONS.UPDATE_LOAD_CB_CONFIG,
        payload: {
          selectedLoadCB: selectedValues.selectedLoadCB,
          cbConfigData: configData.cbConfigData,
        },
      });

      updateLoadCbHistory(selectedValues.selectedLoadCB);

      const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
      setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
    } else {
      callSnackbar("error", embLabels.mandatoryFields);
    }
  };

  const updatePowerMeterData = () => {
    if (configData.cbConfigData.name) {
      CBAssetListDispatch({
        type: ACTIONS.UPDATE_POWERMETER_CONFIG,
        payload: {
          selectedAssetPM: selectedValues.selectedAssetPM,
          cbConfigData: configData.cbConfigData,
        },
      });

      updateAssetPmHistory(selectedValues.selectedAssetPM);

      const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
      setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
    } else {
      callSnackbar("error", embLabels.mandatoryFields);
    }
  };

  const pv1SelectHandler = () => {
    if (UserScope.access.saveSLD) {
      assetDetailsReset();
      const assetBlock = "assetPvDetailsBlock";
      const selectedAssetValue = "selectedPv";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_SELECT,
        payload: {
          index: 3,
        },
      });
      selectedValuesHandler(3, selectedAssetValue, assetBlock);
      selectedSubPvHandler(1);
      PVDetailsDispatch({
        type: ACTIONS.PV_SELECT,
        payload: {
          selectedPv: 3,
          selectSubPv: 1,
        },
      });
      selectedAssetHandlerPv(3, 1);
      PVDetailsDispatch({
        type: ACTIONS.PV_CONFIGURED_STATUS,
      });
      PVDetailsDispatch({
        type: ACTIONS.PV_CONFIG_DATA,
      });
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const pv2SelectHandler = () => {
    if (UserScope.access.saveSLD) {
      assetDetailsReset();
      const assetBlock = "assetPvDetailsBlock";
      const selectedAssetValue = "selectedPv";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_SELECT,
        payload: {
          index: 4,
        },
      });
      selectedValuesHandler(4, selectedAssetValue, assetBlock);
      selectedSubPvHandler(1);
      PVDetailsDispatch({
        type: ACTIONS.PV_SELECT,
        payload: {
          selectedPv: 4,
          selectSubPv: 1,
        },
      });
      selectedAssetHandlerPv(4, 1);
      PVDetailsDispatch({
        type: ACTIONS.PV_CONFIGURED_STATUS,
      });
      PVDetailsDispatch({
        type: ACTIONS.PV_CONFIG_DATA,
      });
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const pv3SelectHandler = () => {
    if (UserScope.access.saveSLD) {
      assetDetailsReset();
      const assetBlock = "assetPvDetailsBlock";
      const selectedAssetValue = "selectedPv";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_SELECT,
        payload: {
          index: 5,
        },
      });
      selectedValuesHandler(5, selectedAssetValue, assetBlock);
      selectedSubPvHandler(1);
      PVDetailsDispatch({
        type: ACTIONS.PV_SELECT,
        payload: {
          selectedPv: 5,
          selectSubPv: 1,
        },
      });
      selectedAssetHandlerPv(5, 1);
      PVDetailsDispatch({
        type: ACTIONS.PV_CONFIGURED_STATUS,
      });
      PVDetailsDispatch({
        type: ACTIONS.PV_CONFIG_DATA,
      });
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const checkForDuplicateName = (name, currentName) => {
    const ALLNAMES = getAllNames();
    const OTHERNAMES = ALLNAMES.filter(name => name !== currentName);

    if (OTHERNAMES.indexOf(name.trim()) !== -1) {
      return true;
    } else {
      return false;
    }
  };

  const getAllNames = () => {
    const ASSETNAMES = CBAssetList.map(
      assetName => assetName.configData.name
    ).filter(name => name !== "");
    const LOADNAME = CBLoadList.map(loadNames => loadNames.configData.name);
    const EVNAMES = CBEVList.filter(ev => ev.uiData.status).map(
      ev => ev.configData.name
    );
    const PV1NAME = PVDetails.pvset1Data.map(pv1names => pv1names.name);
    const PV2NAME = PVDetails.pvset2Data.map(pv2names => pv2names.name);
    const PV3NAME = PVDetails.pvset3Data.map(pv3names => pv3names.name);
    return [
      ...ASSETNAMES,
      ...PV1NAME,
      ...PV2NAME,
      ...PV3NAME,
      ...LOADNAME,
      ...EVNAMES,
    ];
  };

  //* add assets start

  const addGensetVerify = () => {
    if (siteType === embLabels.GRID_CONNECTED) {
      addGenset();
    } else {
      if (useCaseValidGenset) {
        setLoading(true);
        setTimeout(() => {
          addGenset();
        }, 800);
      } else {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `'BESS Main No Genset' usecase doesn't need Genset system.`
        );
      }
    }
  };

  const addGenset = () => {
    if (UserScope.access.saveSLD) {
      if (checkSLDCount()) {
        if (!CBAssetList[1].uiData.status) {
          assetDetailsReset();
          CBAssetListDispatch({
            type: ACTIONS.ADD_GENSET,
            payload: {
              defaultPreFill: defaultPreFillData[0],
              configData: configData,
            },
          });
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });

          const runCalculateCoordinatesNew = { componentPush: true };

          setTimeout(() => {
            setRunCalculateCoordinates(runCalculateCoordinatesNew);
          }, 100);
        } else {
          setLoading(false);
          callSnackbar(`error`, `Max one genset is allowed`);
        }
      } else {
        setLoading(false);
        callSnackbar(`error`, `Max 24 circuit breakers are allowed`);
      }
    } else {
      setLoading(false);
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const addBattery = () => {
    if (UserScope.access.saveSLD) {
      if (checkSLDCount()) {
        if (!CBAssetList[2].uiData.status) {
          assetDetailsReset();

          CBAssetListDispatch({
            type: ACTIONS.ADD_BESS,
            payload: {
              defaultPreFill: defaultPreFillData[0],
              configData: configData,
            },
          });
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });

          const runCalculateCoordinatesNew = { componentPush: true };

          setTimeout(() => {
            setRunCalculateCoordinates(runCalculateCoordinatesNew);
          }, 100);

          // this.setState({
          //   batteryCommunicationData: newBatteryData[2].communicationData,
          // });
        } else {
          callSnackbar(`error`, `Max one battery is allowed`);
        }
      } else {
        callSnackbar(`error`, `Max 24 circuit breakers are allowed`);
      }
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const addPv = () => {
    if (UserScope.access.saveSLD) {
      PVDetailsDispatch({
        type: ACTIONS.ADD_PV_EQUIPMENT,
        payload: {
          CBAssetList: CBAssetList,
        },
      });
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const addPVSetWithCB = () => {
    if (PVDetails.pvCount) {
      let overLoadAsset = 0;
      let overLoadPvCB = 1;
      let selectedPv = 3;

      if (PVDetails.existOrNew === "new") {
        if (checkSLDCount()) {
          overLoadAsset = 1;

          if (!CBAssetList[3].uiData.status) {
            selectedPv = 3;
          } else if (!CBAssetList[4].uiData.status) {
            selectedPv = 4;
          } else if (!CBAssetList[5].uiData.status) {
            selectedPv = 5;
          } else {
            overLoadPvCB = 0;
          }
        } else {
          overLoadAsset = 0;
        }
      } else if (PVDetails.existOrNew === "exist") {
        overLoadAsset = 1;
        selectedPv = parseInt(PVDetails.branchName);
      }

      //* selected pv is  1

      if (selectedPv === 3) {
        assetDetailsReset();
        if (overLoadAsset && overLoadPvCB) {
          CBAssetListDispatch({
            type: ACTIONS.ADD_PV_TO_CBASSET_LIST,
            payload: {
              selectedPv: selectedPv,
              PVDetails: PVDetails,
              configData: configData,
              preFillDefault: defaultPreFillData[0],
            },
          });
          if (PVDetails.existOrNew === "new") {
            countDispatch({
              type: ACTIONS.UPDATE_DER_COUNT,
              payload: { count: 1 },
            });
          }

          PVDetailsDispatch({
            type: ACTIONS.CONFIRM_ADD_PV_WITH_CB,
            payload: {
              selectedPv: selectedPv,
            },
          });

          const runCalculateCoordinatesNew = { componentPush: true };

          setTimeout(() => {
            setRunCalculateCoordinates(runCalculateCoordinatesNew);
          }, 100);
        } else {
          if (!overLoadPvCB) {
            callSnackbar(
              "error",
              `Max 3 circuit breakers are allowed for photo voltaic`
            );
          } else {
            callSnackbar("error", "Max 24 circuit breakers are allowed");
          }
          PVDetailsDispatch({
            type: ACTIONS.RESET_PV_DETAILS,
          });
        }
      }

      //* selected pv is 2
      else if (selectedPv === 4) {
        assetDetailsReset();
        if (overLoadAsset && overLoadPvCB) {
          CBAssetListDispatch({
            type: ACTIONS.ADD_PV_TO_CBASSET_LIST,
            payload: {
              selectedPv: selectedPv,
              PVDetails: PVDetails,
              configData: configData,
              preFillDefault: defaultPreFillData[0],
            },
          });
          if (PVDetails.existOrNew === "new") {
            countDispatch({
              type: ACTIONS.UPDATE_DER_COUNT,
              payload: { count: 1 },
            });
          }
          PVDetailsDispatch({
            type: ACTIONS.CONFIRM_ADD_PV_WITH_CB,
            payload: {
              selectedPv: selectedPv,
            },
          });
          const runCalculateCoordinatesNew = { componentPush: true };

          setTimeout(() => {
            setRunCalculateCoordinates(runCalculateCoordinatesNew);
          }, 100);
        } else {
          if (!overLoadPvCB) {
            callSnackbar(
              "error",
              `Max 3 circuit breakers are allowed for photo voltaic`
            );
          } else {
            callSnackbar("error", "Max 24 circuit breakers are allowed");
          }
          PVDetailsDispatch({
            type: ACTIONS.RESET_PV_DETAILS,
          });
        }
      }

      //* selected pv is 3
      else if (selectedPv === 5) {
        assetDetailsReset();
        if (overLoadAsset && overLoadPvCB) {
          CBAssetListDispatch({
            type: ACTIONS.ADD_PV_TO_CBASSET_LIST,
            payload: {
              selectedPv: selectedPv,
              PVDetails: PVDetails,
              configData: configData,
              preFillDefault: defaultPreFillData[0],
            },
          });
          if (PVDetails.existOrNew === "new") {
            countDispatch({
              type: ACTIONS.UPDATE_DER_COUNT,
              payload: { count: 1 },
            });
          }

          PVDetailsDispatch({
            type: ACTIONS.CONFIRM_ADD_PV_WITH_CB,
            payload: {
              selectedPv: selectedPv,
            },
          });

          const runCalculateCoordinatesNew = { componentPush: true };

          setTimeout(() => {
            setRunCalculateCoordinates(runCalculateCoordinatesNew);
          }, 100);
        } else {
          if (!overLoadPvCB) {
            callSnackbar(
              "error",
              `Max 3 circuit breakers are allowed for photo voltaic`
            );
          } else {
            callSnackbar("error", "Max 24 circuit breakers are allowed");
          }
          PVDetailsDispatch({
            type: ACTIONS.RESET_PV_DETAILS,
          });
        }
      }
    }
  };

  const addCHPVerify = () => {
    if (!defaultPreFillData[1].remoteMonitoringControl) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `Remote monitoring control is required for CHP.`
      );
    } else {
      addCHP();
    }
  };

  const addCHP = () => {
    if (UserScope.access.saveSLD) {
      if (checkSLDCount()) {
        if (!CBAssetList[6].uiData.status) {
          assetDetailsReset();

          CBAssetListDispatch({
            type: ACTIONS.ADD_CHP,
            payload: {
              defaultPreFill: defaultPreFillData[0],
              configData: configData,
              siteType,
            },
          });
          countDispatch({
            type: ACTIONS.UPDATE_DER_COUNT,
            payload: { count: 1 },
          });

          const runCalculateCoordinatesNew = { componentPush: true };

          setTimeout(() => {
            setRunCalculateCoordinates(runCalculateCoordinatesNew);
          }, 100);
        } else {
          callSnackbar(`error`, `Max one CHP is allowed`);
        }
      } else {
        callSnackbar(`error`, `Max 24 circuit breakers are allowed`);
      }
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const addLoad = () => {
    if (UserScope.access.saveSLD) {
      if (siteType === embLabels.GRID_CONNECTED) {
        callSnackbar(
          `error`,
          `More than one Load is not allowed in Grid Connected use case.`
        );
      } else if (count.loadCount + count.evCount >= 22) {
        callSnackbar("error", "Max 22 loads are allowed");
      } else {
        if (checkSLDCount()) {
          if (count.loadCount + count.evCount < 22) {
            countDispatch({
              type: ACTIONS.UPDATE_LOAD_COUNT,
              payload: { count: 1 },
            });

            CBLoadListDispatch({
              type: ACTIONS.ADD_LOAD,
              payload: {
                defaultPreData: defaultPreFillData[0],
                count: count,
              },
            });

            const runCalculateCoordinatesNew = { componentPush: true };

            setTimeout(() => {
              setRunCalculateCoordinates(runCalculateCoordinatesNew);
            }, 100);
          }
        } else {
          callSnackbar(`error`, `Max 24 circuit breakers are allowed`);
        }
      }
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const addEv = () => {
    if (UserScope.access.saveSLD) {
      if (count.loadCount + count.evCount >= 22) {
        callSnackbar("error", "Max 22 loads are allowed");
      } else {
        if (checkSLDCount()) {
          if (!CBEVList[0].uiData.status) {
            assetDetailsReset();

            CBEVListDispatch({
              type: ACTIONS.ADD_EV,
              payload: {
                defaultPreFill: defaultPreFillData[0],
                configData: configData,
                count: count,
              },
            });

            countDispatch({
              type: ACTIONS.UPDATE_EV_COUNT,
              payload: { count: 1 },
            });

            const runCalculateCoordinatesNew = { componentPush: true };

            setTimeout(() => {
              setRunCalculateCoordinates(runCalculateCoordinatesNew);
            }, 100);
          } else {
            callSnackbar("error", "Max one EV is allowed");
          }
        } else {
          callSnackbar(`error`, `Max 24 circuit breakers are allowed`);
        }
      }
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const addTransferSwitch = () =>
    setAlertMessage(
      `Alert`,
      false,
      ``,
      `Contact SE representative in order to integrate external transfer switch.`
    );

  //* add assets end

  //* remove assets start

  const checkGensetRemoveCondition = () => {
    if (defaultPreFillData[1].mode === "FUEL_SAVER") {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `'Genset main, BESS grid tied' usecase requires a generator.`
      );
    } else {
      removeGenset();
    }
  };

  const removeGenset = () => {
    CBAssetListDispatch({
      type: ACTIONS.REMOVE_ASSET_DER,
      payload: {
        index: 1,
        configData,
      },
    });
    setDetailsBlock(prevState => ({
      ...prevState,
      assetGensetDetailsBlock: false,
    }));
    countDispatch({
      type: ACTIONS.UPDATE_DER_COUNT,
      payload: { count: -1 },
    });

    updateDerHistory(1);
    updateDerStatus(1);
    updateAssetCbHistory(1);

    const runCalculateCoordinatesNew = { componentPush: true };

    setTimeout(() => {
      setRunCalculateCoordinates(runCalculateCoordinatesNew);
    }, 100);
  };

  const checkBatteryRemoveCondition = () => {
    if (siteType === embLabels.GRID_CONNECTED) {
      if (!defaultPreFillData[1].exportOptimization) {
        removeBattery();
      } else {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `Export management predictive optimization requires a battery system.`
        );
      }
    } else {
      if (defaultPreFillData[1].mode === "NO_FUEL") {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `'BESS main, No Genset' usease requires a battery system. `
        );
      } else if (defaultPreFillData[1].mode === "FUEL_ERASER") {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `'BESS main, Genset as backup' usease requires a battery system.`
        );
      } else {
        if (!defaultPreFillData[1].exportOptimization) {
          removeBattery();
        } else {
          setAlertMessage(
            `Alert`,
            false,
            ``,
            `Export management predictive optimization requires a battery system.`
          );
        }
      }
    }
  };

  const removeBattery = () => {
    CBAssetListDispatch({
      type: ACTIONS.REMOVE_ASSET_DER,
      payload: {
        index: 2,
        configData,
      },
    });

    setDetailsBlock(prevState => ({
      ...prevState,
      assetBatteryDetailsBlock: false,
    }));

    countDispatch({
      type: ACTIONS.UPDATE_DER_COUNT,
      payload: { count: -1 },
    });

    updateDerHistory(2);
    updateDerStatus(2);
    updateAssetCbHistory(2);

    const runCalculateCoordinatesNew = { componentPush: true };

    setTimeout(() => {
      setRunCalculateCoordinates(runCalculateCoordinatesNew);
    }, 100);
  };

  const removePvWithAlert = subPv => {
    if (
      siteType === embLabels.ISLANDABLE_GSX &&
      PVDetails.pvset1Data.length === 1
    ) {
      if (architectureType === embLabels.PV_GENSET_ATS) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `'‌PV + backup Genset with ATS' usecase requires minimum one PV`
        );
      }
    } else if (
      siteType === embLabels.PV_HYBRIDIZATION &&
      PVDetails.pvset1Data.length === 1
    ) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `'Genset/PV Hybridization' usecase requires minimum one PV`
      );
    } else {
      setSelectedValues(prevState => ({ ...prevState, selectSubPv: subPv }));

      setConfirmMessage(`Do you confirm to delete pv from  pv set ?`);
    }
  };

  const removePv = () => {
    if (siteType === embLabels.ISLANDABLE_GSX) {
      if (architectureType === embLabels.PV_GENSET_ATS) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `'‌PV + backup Genset with ATS' usecase requires minimum one PV`
        );
      }
    } else if (siteType === embLabels.PV_HYBRIDIZATION) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `'Genset/PV Hybridization' usecase requires minimum one PV`
      );
    } else {
      CBAssetListDispatch({
        type: ACTIONS.REMOVE_ASSET_DER_PV,
        payload: {
          selectedPv: PVDetails.selectedPv,
        },
      });
      setDetailsBlock(prevState => ({
        ...prevState,
        assetPvDetailsBlock: false,
      }));

      countDispatch({
        type: ACTIONS.UPDATE_DER_COUNT,
        payload: { count: -1 },
      });

      let removedPvSet;
      switch (PVDetails.selectedPv) {
        case 3:
          removedPvSet = "pvset1Data";
          break;
        case 4:
          removedPvSet = "pvset2Data";

          break;
        case 5:
          removedPvSet = "pvset3Data";

          break;
        default:
          break;
      }
      PVDetailsDispatch({
        type: ACTIONS.REMOVE_ALL_PV_FROM_SET,
        payload: {
          removedPvSet: removedPvSet,
        },
      });

      updatePvHistoryFull(PVDetails.selectedPv);
      updateAssetCbHistory(PVDetails.selectedPv);
      updatePvHistoryGroupPower(PVDetails.selectedPv);

      const runCalculateCoordinatesNew = { componentPush: true };

      setTimeout(() => {
        setRunCalculateCoordinates(runCalculateCoordinatesNew);
      }, 100);
    }
  };

  const removeCHP = () => {
    CBAssetListDispatch({
      type: ACTIONS.REMOVE_ASSET_DER,
      payload: {
        index: 6,
        configData,
      },
    });
    setDetailsBlock(prevState => ({
      ...prevState,
      assetCHPDetailsBlock: false,
    }));
    countDispatch({
      type: ACTIONS.UPDATE_DER_COUNT,
      payload: { count: -1 },
    });

    updateDerHistory(6);
    updateDerStatus(7);
    updateAssetCbHistory(6);

    const runCalculateCoordinatesNew = { componentPush: true };

    setTimeout(() => {
      setRunCalculateCoordinates(runCalculateCoordinatesNew);
    }, 100);
  };

  const removeLoad = () => {
    if (siteType === embLabels.GRID_CONNECTED) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `Asset configuration needs minimum one load.`
      );
    } else if (parseInt(count.loadCount) === 1) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `Asset configuration needs minimum one load.`
      );
    } else {
      CBLoadListDispatch({
        type: ACTIONS.REMOVE_LOAD,
        payload: {
          selectedLoad: selectedValues.selectedLoad,
        },
      });

      countDispatch({
        type: ACTIONS.UPDATE_LOAD_COUNT,
        payload: { count: -1 },
      });

      setDetailsBlock(prevState => ({
        ...prevState,
        loadDetailsBlock: false,
      }));

      updateLoadHistory(selectedValues.selectedLoad);
      updateLoadCbHistory(selectedValues.selectedLoad);

      const runCalculateCoordinatesNew = { componentPush: true };

      setTimeout(() => {
        setRunCalculateCoordinates(runCalculateCoordinatesNew);
      }, 100);
    }
  };

  const removeEv = () => {
    CBEVListDispatch({
      type: ACTIONS.REMOVE_ASSET_EV,
      payload: {
        index: selectedValues.selectedEv,
        configData,
      },
    });
    setDetailsBlock(prevState => ({
      ...prevState,
      evDetailsBlock: false,
    }));

    countDispatch({
      type: ACTIONS.UPDATE_EV_COUNT,
      payload: { count: -1 },
    });

    updateDerHistory(5);
    updateDerStatus(5);
    updateAssetCbHistory(6);

    const runCalculateCoordinatesNew = { componentPush: true };

    setTimeout(() => {
      setRunCalculateCoordinates(runCalculateCoordinatesNew);
    }, 100);
  };

  //* remove assets end

  const isNoAssetSelected = () =>
    Object.keys(detailsBlock).every(block => !detailsBlock[block]);

  const checkSLDCount = () =>
    count.derCount + count.loadCount + count.evCount >= 24 ? false : true;

  const isValidName = value =>
    /^[a-zA-Z0-9_-\s]*$/.test(value) ? true : false;

  const callSnackbar = useCallback((type, message) => {
    setSnackBar({ type, message });
    snackBarRef.current.open = true;
  }, []);

  const setAlertMessage = (title, isRoute, routePath, message) => {
    setAlert({
      title,
      isRoute,
      routePath,
      message,
      alertPopUpStatus: true,
    });
  };

  const closeAlertMessage = useCallback(() => {
    setAlert({
      message: "",
      alertPopUpStatus: false,
      title: "",
      isRoute: false,
      routePath: "",
    });
    if (alert.isRoute) {
      let { history } = props;
      history.push({
        pathname: `/${alert.routePath}`,
      });
    }
  }, [alert, props]);

  const setConfirmMessage = message => {
    setConfirm({
      confirmPopupStatus: true,
      confirmMessage: message,
    });
  };

  const closeConfirmMessage = () => {
    setConfirm({
      confirmPopupStatus: false,
      confirmMessage: "",
    });
  };

  const okConfirmMessage = () => {
    if (PVDetails.selectedPv === 3) {
      if (PVDetails.pvset1Data.length > 1) {
        let pvConStatusOne = true;

        PVDetails.pvset1Data.forEach((pv1, index) => {
          if (index !== selectedValues.selectSubPv - 1 && !pv1.status) {
            pvConStatusOne = false;
          }
        });

        CBAssetListDispatch({
          type: ACTIONS.REDUCE_PV_COUNT_FROM_CB,
          payload: {
            selectedPv: 3,
            pvConStatusOne: pvConStatusOne,
          },
        });

        PVDetailsDispatch({
          type: ACTIONS.REMOVE_PV_FROM_SET,
          payload: {
            selectedPv: selectedValues.selectedPv,
            subPv: selectedValues.selectSubPv,
          },
        });

        if (!PVDetails.pvset1Data[0].assetDetails.length) {
          const newAssetDetailsDisplay = { ...assetDetailsDisplay };
          Object.keys(newAssetDetailsDisplay).forEach(
            details => (newAssetDetailsDisplay[details] = "")
          );
          setAssetDetailsDisplay(newAssetDetailsDisplay);
        }

        //this.calculatePvStatusConfigured(newPvData1Set);

        let subPv = selectedValues.selectSubPv - 1;

        updatePvHistory(subPv, 3);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);

        pv1SelectHandler();
      } else {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          "Unable to delete. Minimum one Pv should be is present in PV set"
        );
      }
    }
    if (PVDetails.selectedPv === 4) {
      if (PVDetails.pvset2Data.length > 1) {
        let pvConStatusOne = true;

        PVDetails.pvset2Data.forEach((pv2, index) => {
          if (index !== selectedValues.selectSubPv - 1 && !pv2.status) {
            pvConStatusOne = false;
          }
        });

        CBAssetListDispatch({
          type: ACTIONS.REDUCE_PV_COUNT_FROM_CB,
          payload: {
            selectedPv: 4,
            pvConStatusOne: pvConStatusOne,
          },
        });

        PVDetailsDispatch({
          type: ACTIONS.REMOVE_PV_FROM_SET,
          payload: {
            selectedPv: selectedValues.selectedPv,
            subPv: selectedValues.selectSubPv,
          },
        });

        if (!PVDetails.pvset2Data[0].assetDetails.length) {
          const newAssetDetailsDisplay = { ...assetDetailsDisplay };
          Object.keys(newAssetDetailsDisplay).forEach(
            details => (newAssetDetailsDisplay[details] = "")
          );
          setAssetDetailsDisplay(newAssetDetailsDisplay);
        }

        //this.calculatePvStatusConfigured(newPvData1Set);

        let subPv = selectedValues.selectSubPv - 1;

        updatePvHistory(subPv, 4);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);

        pv2SelectHandler();
      } else {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          "Unable to delete. Minimum one Pv should be is present in PV set"
        );
      }
    }
    if (PVDetails.selectedPv === 5) {
      if (PVDetails.pvset3Data.length > 1) {
        let pvConStatusOne = true;

        PVDetails.pvset3Data.forEach((pv3, index) => {
          if (index !== selectedValues.selectSubPv - 1 && !pv3.status) {
            pvConStatusOne = false;
          }
        });

        CBAssetListDispatch({
          type: ACTIONS.REDUCE_PV_COUNT_FROM_CB,
          payload: {
            selectedPv: 5,
            pvConStatusOne: pvConStatusOne,
          },
        });

        PVDetailsDispatch({
          type: ACTIONS.REMOVE_PV_FROM_SET,
          payload: {
            selectedPv: selectedValues.selectedPv,
            subPv: selectedValues.selectSubPv,
          },
        });

        if (!PVDetails.pvset3Data[0].assetDetails.length) {
          const newAssetDetailsDisplay = { ...assetDetailsDisplay };
          Object.keys(newAssetDetailsDisplay).forEach(
            details => (newAssetDetailsDisplay[details] = "")
          );
          setAssetDetailsDisplay(newAssetDetailsDisplay);
        }

        //this.calculatePvStatusConfigured(newPvData1Set);

        let subPv = selectedValues.selectSubPv - 1;

        updatePvHistory(subPv, 5);

        const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
        setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);

        pv3SelectHandler();
      } else {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          "Unable to delete. Minimum one Pv should be is present in PV set"
        );
      }
    }
    closeConfirmMessage();
  };

  const closePvDetailsBlock = () => {
    PVDetailsDispatch({
      type: ACTIONS.CLOSE_PVDISPLAY_CONTENT,
    });
  };

  const isCopyAllButtonDisabled = selectedPv => {
    switch (selectedPv) {
      case 3:
        const configured1pv = PVDetails.pvset1Data.filter(pv1 => pv1.status);
        const configured1pvSameAsset = configured1pv.every(
          pv1 =>
            pv1.assetDetails[0].assetId ===
            configured1pv[0].assetDetails[0].assetId
        );
        if (PVDetails.pvset1Data.every(pv1 => pv1.status)) return false;
        else return configured1pvSameAsset && configured1pv.length;
      case 4:
        const configured2pv = PVDetails.pvset2Data.filter(pv2 => pv2.status);
        const configured2pvSameAsset = configured2pv.every(
          pv2 =>
            pv2.assetDetails[0].assetId ===
            configured2pv[0].assetDetails[0].assetId
        );
        if (PVDetails.pvset2Data.every(pv2 => pv2.status)) return false;
        else return configured2pvSameAsset && configured2pv.length;
      case 5:
        const configured3pv = PVDetails.pvset3Data.filter(pv3 => pv3.status);
        const configured3pvSameAsset = configured3pv.every(
          pv3 =>
            pv3.assetDetails[0].assetId ===
            configured3pv[0].assetDetails[0].assetId
        );
        if (PVDetails.pvset3Data.every(pv3 => pv3.status)) return false;
        else return configured3pvSameAsset && configured3pv.length;
      default:
        break;
    }
  };

  const copyAllPvs = () => {
    PVDetailsDispatch({
      type: ACTIONS.COPY_ALL_PV,
    });
    PVDetailsDispatch({
      type: ACTIONS.PV_CONFIGURED_STATUS,
    });

    // this.updatePvHistory(index, selectedPV);
    const runGenerateComponentsBlockNew = { generateComponentsBlock: true };
    setRunGenerateComponentsBlock(runGenerateComponentsBlockNew);
  };

  //* LOCAL STORAGE

  const assetEditStatusUpdateDER = (e, type, status) => {
    let derHistory = JSON.parse(localStorage.getItem("derHistory"));
    switch (type) {
      case embLabels.BESS:
        derHistory.BESS[e.target.name] = e.target.value;
        checkUpdatedStatus(2, status);
        break;

      case embLabels.GENSET:
        derHistory.Genset[e.target.name] = e.target.value;
        checkUpdatedStatus(1, status);
        break;

      case embLabels.UTILITY:
        derHistory.Utility[e.target.name] = e.target.value;
        checkUpdatedStatus(0, status);
        break;

      case embLabels.EV:
        derHistory.EV[e.target.name] = e.target.value;
        checkUpdatedStatus(5, status);
        break;

      case embLabels.CHP:
        derHistory.CHP[e.target.name] = e.target.value;
        checkUpdatedStatus(7, status);
        break;

      default:
        break;
    }
    localStorage.setItem("derHistory", JSON.stringify(derHistory));
  };

  const assetEditStatusUpdateLoad = (e, status, selectedLoad) => {
    let loadHistory = [];
    if (localStorage.getItem("loadHistory") !== "") {
      loadHistory = JSON.parse(localStorage.getItem("loadHistory"));
    }

    if (loadHistory[selectedLoad] === undefined) {
      loadHistory[selectedLoad] = {};
    }
    if (loadHistory[selectedLoad] === null) {
      loadHistory[selectedLoad] = {};
    }
    let loadHistoryRef = loadHistory[selectedLoad];
    loadHistoryRef[e.target.name] = e.target.value;
    loadHistory[selectedLoad] = loadHistoryRef;
    localStorage.setItem("loadHistory", JSON.stringify(loadHistory));
    checkUpdatedStatus(4, status);
  };

  const assetEditStatusUpdateAssetCB = (e, status, selectedAssetCb) => {
    let cbAssetHistory = [];
    if (localStorage.getItem("cbAssetHistory") !== "") {
      cbAssetHistory = JSON.parse(localStorage.getItem("cbAssetHistory"));
    }

    if (cbAssetHistory[selectedAssetCb] === undefined) {
      cbAssetHistory[selectedAssetCb] = {};
    }
    if (cbAssetHistory[selectedAssetCb] === null) {
      cbAssetHistory[selectedAssetCb] = {};
    }

    let cbAssetHistoryRef = cbAssetHistory[selectedAssetCb];
    if (e.target.name === "motorized") {
      cbAssetHistoryRef[e.target.name] = true;
    } else {
      cbAssetHistoryRef[e.target.name] = e.target.value;
    }
    cbAssetHistory[selectedAssetCb] = cbAssetHistoryRef;
    localStorage.setItem("cbAssetHistory", JSON.stringify(cbAssetHistory));
    checkUpdatedStatus(6, status);
  };

  const assetEditStatusUpdateLoadCb = (e, status, selectedLoadCb) => {
    let cbLoadHistory = [];
    if (localStorage.getItem("cbLoadHistory") !== "") {
      cbLoadHistory = JSON.parse(localStorage.getItem("cbLoadHistory"));
    }

    if (cbLoadHistory[selectedLoadCb] === undefined) {
      cbLoadHistory[selectedLoadCb] = {};
    }

    if (cbLoadHistory[selectedLoadCb] === null) {
      cbLoadHistory[selectedLoadCb] = {};
    }

    let cbLoadHistoryRef = cbLoadHistory[selectedLoadCb];
    if (e.target.name === "motorized") {
      cbLoadHistoryRef[e.target.name] = e.target.checked;
    } else {
      cbLoadHistoryRef[e.target.name] = e.target.value;
    }
    cbLoadHistory[selectedLoadCb] = cbLoadHistoryRef;
    localStorage.setItem("cbLoadHistory", JSON.stringify(cbLoadHistory));
    checkUpdatedStatus(6, status);
  };

  const assetEditStatusUpdateEvCb = (e, status, selectedEvCb) => {
    let cbEvHistory = [];
    if (localStorage.getItem("cbEvHistory") !== "") {
      cbEvHistory = JSON.parse(localStorage.getItem("cbEvHistory"));
    }

    if (cbEvHistory[selectedEvCb] === undefined) {
      cbEvHistory[selectedEvCb] = {};
    }

    if (cbEvHistory[selectedEvCb] === undefined) {
      cbEvHistory[selectedEvCb] = {};
    }

    let cbEvHistoryRef = cbEvHistory[selectedEvCb];
    if (e.target.name === "motorized") {
      cbEvHistoryRef[e.target.name] = e.target.checked;
    } else {
      cbEvHistoryRef[e.target.name] = e.target.value;
    }

    cbEvHistory[selectedEvCb] = cbEvHistoryRef;
    localStorage.setItem("cbEvHistory", JSON.stringify(cbEvHistory));
    checkUpdatedStatus(6, status);
  };

  const assetEditStatusUpdatePv = (e, status, selectedPv, selectedSubPvRaw) => {
    let selectedSubPv = selectedSubPvRaw - 1;
    const onlyNumber = /^[0-9]?\d*\.?\d*$/;
    let pvHistory = JSON.parse(localStorage.getItem("pvHistory"));
    if (selectedPv === 3) {
      if (pvHistory.pv1[selectedSubPv] === undefined) {
        pvHistory.pv1[selectedSubPv] = {};
      }
      if (pvHistory.pv1[selectedSubPv] === null) {
        pvHistory.pv1[selectedSubPv] = {};
      }
      let pvHistoryRef = pvHistory.pv1[selectedSubPv];

      switch (e.target.name) {
        case "groupName":
          pvHistoryRef[e.target.name] = e.target.value;
          break;
        case "group_max_power":
          if (onlyNumber.test(e.target.value) && e.target.value <= 99999) {
            pvHistoryRef[e.target.name] = e.target.value;
          }
          break;
        case "max_power":
          if (!isNaN(e.target.value)) {
            pvHistoryRef[e.target.name] = e.target.value;
          }
          break;
        default:
          pvHistoryRef[e.target.name] = e.target.value;
          break;
      }

      pvHistory.pv1[selectedSubPv] = pvHistoryRef;
    } else if (selectedPv === 4) {
      if (pvHistory.pv2[selectedSubPv] === undefined) {
        pvHistory.pv2[selectedSubPv] = {};
      }
      if (pvHistory.pv2[selectedSubPv] === null) {
        pvHistory.pv2[selectedSubPv] = {};
      }
      let pvHistoryRef = pvHistory.pv2[selectedSubPv];
      switch (e.target.name) {
        case "groupName":
          pvHistoryRef[e.target.name] = e.target.value;
          break;
        case "group_max_power":
          if (onlyNumber.test(e.target.value) && e.target.value <= 99999) {
            pvHistoryRef[e.target.name] = e.target.value;
          }
          break;
        case "max_power":
          if (!isNaN(e.target.value)) {
            pvHistoryRef[e.target.name] = e.target.value;
          }
          break;
        default:
          pvHistoryRef[e.target.name] = e.target.value;
          break;
      }
      pvHistory.pv2[selectedSubPv] = pvHistoryRef;
    } else if (selectedPv === 5) {
      if (pvHistory.pv3[selectedSubPv] === undefined) {
        pvHistory.pv3[selectedSubPv] = {};
      }
      if (pvHistory.pv3[selectedSubPv] === null) {
        pvHistory.pv3[selectedSubPv] = {};
      }
      let pvHistoryRef = pvHistory.pv3[selectedSubPv];
      switch (e.target.name) {
        case "groupName":
          pvHistoryRef[e.target.name] = e.target.value;
          break;
        case "group_max_power":
          if (onlyNumber.test(e.target.value) && e.target.value <= 99999) {
            pvHistoryRef[e.target.name] = e.target.value;
          }
          break;
        case "max_power":
          if (!isNaN(e.target.value)) {
            pvHistoryRef[e.target.name] = e.target.value;
          }
          break;
        default:
          pvHistoryRef[e.target.name] = e.target.value;
          break;
      }
      pvHistory.pv3[selectedSubPv] = pvHistoryRef;
    }
    localStorage.setItem("pvHistory", JSON.stringify(pvHistory));
    checkUpdatedStatus(3, status);
  };

  const assetEditStatusUpdateAssetPM = (e, status, selectedAssetPm) => {
    let pmAssetHistory = [];
    if (localStorage.getItem("pmAssetHistory") !== "") {
      pmAssetHistory = JSON.parse(localStorage.getItem("pmAssetHistory"));
    }

    if (pmAssetHistory[selectedAssetPm] === undefined) {
      pmAssetHistory[selectedAssetPm] = {};
    }
    if (pmAssetHistory[selectedAssetPm] === null) {
      pmAssetHistory[selectedAssetPm] = {};
    }

    let pmAssetHistoryRef = pmAssetHistory[selectedAssetPm];
    pmAssetHistoryRef[e.target.name] = e.target.value;
    pmAssetHistory[selectedAssetPm] = pmAssetHistoryRef;
    localStorage.setItem("pmAssetHistory", JSON.stringify(pmAssetHistory));
    checkUpdatedStatus(8, status);
  };

  const checkUpdatedStatus = (type, status) => {
    let derStatus = [];
    if (localStorage.getItem("derStatus") !== "") {
      derStatus = JSON.parse(localStorage.getItem("derStatus"));
    }

    derStatus[type] = status;

    localStorage.setItem("derStatus", JSON.stringify(derStatus));
    detailsModifiedStataus();
  };

  const detailsModifiedStataus = () => {
    let derStatus = [];
    if (localStorage.getItem("derStatus") !== "") {
      derStatus = JSON.parse(localStorage.getItem("derStatus"));
    }
    localStorage.setItem("assetEditStatus", "");
    let assetEditStatus = [];
    for (let i = 0; i < derStatus.length; i++) {
      if (derStatus[i] === true) {
        switch (i) {
          case 0:
            assetEditStatus.push("Utility");
            assetEditStatus.push(true);
            break;
          case 1:
            assetEditStatus.push("Genset");
            assetEditStatus.push(true);
            break;
          case 2:
            assetEditStatus.push("BESS");
            assetEditStatus.push(true);
            break;
          case 3:
            assetEditStatus.push("PV");
            assetEditStatus.push(true);
            break;
          case 4:
            assetEditStatus.push("Load");
            assetEditStatus.push(true);
            break;
          case 5:
            assetEditStatus.push("EV");
            assetEditStatus.push(true);
            break;
          case 6:
            assetEditStatus.push("CB");
            assetEditStatus.push(true);
            break;
          case 7:
            assetEditStatus.push("CHP");
            assetEditStatus.push(true);
            break;
          case 8:
            assetEditStatus.push("Power meter");
            assetEditStatus.push(true);
            break;
          default:
            break;
        }
        localStorage.setItem("assetEditStatus", assetEditStatus);
        break;
      }
    }
  };

  const updateDerHistory = index => {
    let derHistory = JSON.parse(localStorage.getItem("derHistory"));
    if (index === 0) {
      derHistory.Utility = {};
    } else if (index === 1) {
      derHistory.Genset = {};
    } else if (index === 2) {
      derHistory.BESS = {};
    } else if (index === 5) {
      derHistory.EV = {};
    } else if (index === 7) {
      derHistory.CHP = {};
    }

    localStorage.setItem("derHistory", JSON.stringify(derHistory));
  };

  const updateLoadHistory = index => {
    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    let loadHistory = [];
    if (derStatus[4] === true) {
      loadHistory = JSON.parse(localStorage.getItem("loadHistory"));
    }

    if (loadHistory.length) {
      delete loadHistory[index];
    }
    localStorage.setItem("loadHistory", JSON.stringify(loadHistory));

    if (loadHistory.length) {
      let status = false;
      for (let i = 0; i < loadHistory.length; i++) {
        if (loadHistory[i] === null) {
          status = status === false ? false : true;
        } else if (loadHistory[i] === undefined) {
          status = status === false ? false : true;
        } else {
          status = true;
        }
      }
      derStatus[4] = status;
    } else {
      derStatus[4] = false;
    }
    localStorage.setItem("derStatus", JSON.stringify(derStatus));
    detailsModifiedStataus();
  };

  const updateEvCbHistory = index => {
    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    let cbEvHistory = [];
    if (derStatus[6] === true) {
      cbEvHistory = JSON.parse(localStorage.getItem("cbEvHistory"));
    }

    if (cbEvHistory.length) {
      delete cbEvHistory[index];
    }

    localStorage.setItem("cbEvHistory", JSON.stringify(cbEvHistory));
    checkCBStatus();
  };

  const updateLoadCbHistory = index => {
    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    let cbLoadHistory = [];
    if (derStatus[6] === true) {
      cbLoadHistory = JSON.parse(localStorage.getItem("cbLoadHistory"));
    }

    if (cbLoadHistory.length) {
      delete cbLoadHistory[index];
    }
    localStorage.setItem("cbLoadHistory", JSON.stringify(cbLoadHistory));
    checkCBStatus();
  };

  const updateAssetCbHistory = index => {
    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    let cbAssetHistory = [];
    if (derStatus[6] === true) {
      cbAssetHistory = JSON.parse(localStorage.getItem("cbAssetHistory"));
    }

    if (cbAssetHistory.length) {
      delete cbAssetHistory[index];
    }
    localStorage.setItem("cbAssetHistory", JSON.stringify(cbAssetHistory));
    checkCBStatus();
  };

  const updateAssetPmHistory = index => {
    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    let pmAssetHistory = [];
    if (derStatus[8] === true) {
      pmAssetHistory = JSON.parse(localStorage.getItem("pmAssetHistory"));
    }

    if (pmAssetHistory.length) {
      delete pmAssetHistory[index];
    }
    localStorage.setItem("pmAssetHistory", JSON.stringify(pmAssetHistory));
    checkPmStatus();
  };

  const checkCBStatus = () => {
    let derStatus = JSON.parse(localStorage.getItem("derStatus"));

    let cbLoadHistory = [];
    if (localStorage.getItem("cbLoadHistory") === "") {
      cbLoadHistory = [];
    } else {
      cbLoadHistory = JSON.parse(localStorage.getItem("cbLoadHistory"));
    }
    let status = false;
    if (cbLoadHistory.length) {
      for (let i = 0; i < cbLoadHistory.length; i++) {
        if (cbLoadHistory[i] === null) {
          status = status === false ? false : true;
        } else if (cbLoadHistory[i] === undefined) {
          status = status === false ? false : true;
        } else {
          status = true;
        }
      }
    } else {
      status = status === false ? false : true;
    }

    let cbAssetHistory = [];
    if (localStorage.getItem("cbAssetHistory") === "") {
      cbAssetHistory = [];
    } else {
      cbAssetHistory = JSON.parse(localStorage.getItem("cbAssetHistory"));
    }

    if (cbAssetHistory.length) {
      for (let i = 0; i < cbAssetHistory.length; i++) {
        if (cbAssetHistory[i] === null) {
          status = status === false ? false : true;
        } else if (cbAssetHistory[i] === undefined) {
          status = status === false ? false : true;
        } else {
          status = true;
        }
      }
    } else {
      status = status === false ? false : true;
    }

    let cbEvHistory = [];
    if (localStorage.getItem("cbEvHistory") === "") {
      cbEvHistory = [];
    } else {
      cbEvHistory = JSON.parse(localStorage.getItem("cbEvHistory"));
    }
    if (cbEvHistory.length) {
      for (let i = 0; i < cbEvHistory.length; i++) {
        if (cbEvHistory[i] === null) {
          status = status === false ? false : true;
        } else if (cbEvHistory[i] === undefined) {
          status = status === false ? false : true;
        } else {
          status = true;
        }
      }
    } else {
      status = status === false ? false : true;
    }

    derStatus[6] = status;
    localStorage.setItem("derStatus", JSON.stringify(derStatus));
    detailsModifiedStataus();
  };

  const checkPmStatus = () => {
    let derStatus = JSON.parse(localStorage.getItem("derStatus"));

    let pmAssetHistory = [];
    if (localStorage.getItem("pmAssetHistory") === "") {
      pmAssetHistory = [];
    } else {
      pmAssetHistory = JSON.parse(localStorage.getItem("pmAssetHistory"));
    }
    let status = false;
    if (pmAssetHistory.length) {
      for (let i = 0; i < pmAssetHistory.length; i++) {
        if (pmAssetHistory[i] === null) {
          status = status === false ? false : true;
        } else if (pmAssetHistory[i] === undefined) {
          status = status === false ? false : true;
        } else {
          status = true;
        }
      }
    } else {
      status = status === false ? false : true;
    }

    derStatus[8] = status;
    localStorage.setItem("derStatus", JSON.stringify(derStatus));
    detailsModifiedStataus();
  };

  const updatePvHistoryFull = mainIndex => {
    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    let pvHistory = JSON.parse(localStorage.getItem("pvHistory"));
    let status = false;
    if (mainIndex === 3) {
      pvHistory.pv1 = [];
      status = false;
    } else if (mainIndex === 4) {
      pvHistory.pv2 = [];
      status = false;
    } else if (mainIndex === 5) {
      pvHistory.pv3 = [];
      status = false;
    }

    derStatus[3] = status;
    localStorage.setItem("derStatus", JSON.stringify(derStatus));
    localStorage.setItem("pvHistory", JSON.stringify(pvHistory));

    detailsModifiedStataus();
  };

  const updatePvHistory = (subIndex, mainIndex) => {
    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    let pvHistory = JSON.parse(localStorage.getItem("pvHistory"));
    let status = false;
    if (mainIndex === 3) {
      if (pvHistory.pv1.length) {
        delete pvHistory.pv1[subIndex];
        for (let i = 0; i < pvHistory.pv1.length; i++) {
          if (pvHistory.pv1[i] === null) {
            status = status === false ? false : true;
          } else if (pvHistory.pv1[i] === undefined) {
            status = status === false ? false : true;
          } else {
            status = true;
          }
        }
      } else {
        status = false;
      }
    } else if (mainIndex === 4) {
      if (pvHistory.pv2.length) {
        delete pvHistory.pv2[subIndex];
        for (let i = 0; i < pvHistory.pv2.length; i++) {
          if (pvHistory.pv2[i] === null) {
            status = status === false ? false : true;
          } else if (pvHistory.pv2[i] === undefined) {
            status = status === false ? false : true;
          } else {
            status = true;
          }
        }
      } else {
        status = false;
      }
    } else if (mainIndex === 5) {
      if (pvHistory.pv3.length) {
        delete pvHistory.pv3[subIndex];
        for (let i = 0; i < pvHistory.pv2.length; i++) {
          if (pvHistory.pv2[i] === null) {
            status = status === false ? false : true;
          } else if (pvHistory.pv3[i] === undefined) {
            status = status === false ? false : true;
          } else {
            status = true;
          }
        }
      } else {
        status = false;
      }
    }

    derStatus[3] = status;
    localStorage.setItem("derStatus", JSON.stringify(derStatus));
    localStorage.setItem("pvHistory", JSON.stringify(pvHistory));

    detailsModifiedStataus();
  };

  const updateDerStatus = index => {
    let derStatus = JSON.parse(localStorage.getItem("derStatus"));
    derStatus[index] = false;
    localStorage.setItem("derStatus", JSON.stringify(derStatus));
    detailsModifiedStataus();
  };

  const updatePvHistoryGroupPower = selectedPv => {
    let pvGroupPower = JSON.parse(localStorage.getItem("pvGroupPower"));
    if (selectedPv === 3) {
      pvGroupPower.pv1 = "";
    } else if (selectedPv === 4) {
      pvGroupPower.pv2 = "";
    } else if (selectedPv === 5) {
      pvGroupPower.pv3 = "";
    }
    localStorage.setItem("pvGroupPower", JSON.stringify(pvGroupPower));
  };

  // const  captureSLD = () => {
  //   var node = document.getElementById("SLD-Image");
  //   domtoimage.toPng(node, { bgcolor: "#FFFFFF" }).then(function (dataUrl) {
  //     fetch(dataUrl)
  //       .then(res => res.blob())
  //       .then(response => {
  //         let formData = new FormData();
  //         formData.append("file", response, "SLD.png");
  //         formData.append("projectId", sessionStorage.getItem("projectId"));
  //         // Post via axios or other transport method
  //         apiSession
  //           .uploadSLDImage(formData)
  //           .then(json => {})
  //           .catch(error => {});
  //       });
  //   });
  // };

  //* LOCAL STORAGE

  // JSX starts here
  return (
    <div
      className={`container-fluid h-100 ${
        siteCreated ? "se-block-disabled" : ""
      }`}
    >
      <se-loading loading={loading}></se-loading>
      <div className="row h-100" style={displayPage ? {} : { display: "none" }}>
        <AddEquipment
          siteType={siteType}
          clientType={clientType}
          architectureType={architectureType}
          onAddGensetVerify={addGensetVerify}
          onAddBattery={addBattery}
          onAddPv={addPv}
          onAddCHPVerify={addCHPVerify}
          onAddLoad={addLoad}
          onAddEv={addEv}
          onAddTransferSwitch={addTransferSwitch}
        />
        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 background-SLD">
          <DesignAssetDescription siteCreated={siteCreated} />
          <div className="row">
            <DesignAssetProvider
              value={{
                coordinates,
                CBAssetList,
                CBEVList,
                CBLoadList,
                PVDetails,
                UserScope,
                sldLoadCompleted,
                count,
                pv1SelectHandler,
                pv2SelectHandler,
                pv3SelectHandler,
                CBAssetListDispatch,
                CBLoadListDispatch,
                CBEVListDispatch,
                PVDetailsDispatch,
                assetDetailsReset,
                callSnackbar,
                selectedValuesHandler,
                selectedSubPvHandler,
                selectedAssetHandler,
                selectedAssetHandlerPv,
                assetConfigDataHandler,
                cbConfigDataHandler,
                loadConfigDataHandler,
                loadCbConfigDataHandler,
                pmConfigDataHandler,
                evConfigDataHandler,
                evCbConfigDataHandler,
                padNumberFormat,
              }}
            >
              {clientType === embLabels.GENERIC_PROJECT &&
                siteType === embLabels.ISLANDABLE && <IslandableSLD />}
              {clientType === embLabels.GENERIC_PROJECT &&
                siteType === embLabels.GRID_CONNECTED && (
                  <GridConnectedRosette />
                )}
              {clientType === embLabels.GENERIC_PROJECT &&
                siteType === embLabels.PV_HYBRIDIZATION && (
                  <PVHybridizationSLD />
                )}
              {clientType === embLabels.GREENSTRUXURE_PROJECT &&
                siteType === embLabels.ISLANDABLE_GSX &&
                architectureType === embLabels.PV_GENSET_ATS && (
                  <PvGensetAtsSLD />
                )}
            </DesignAssetProvider>
          </div>
        </div>

        {/* configuration*/}
        <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 border se-white-background">
          <div className="row">
            <div className="col-md-12 pl-0 pr-0 border se-white-background">
              <div className="col-md-12 mt-2 se-life-green bold">
                CONFIGURATION
              </div>
            </div>
            <AssetBlockContextProvider
              value={{
                configData,
                defaultsMinMax,
                assetDetailsDisplay,
                siteType,
                architectureType,
                selectedValues,
                PVDetails,
                gsxExternalId,
                PVDetailsDispatch,
                handleUtilityChange,
                handleGensetChange,
                handleBatteryChange,
                handleChpChange,
                handleLoadChange,
                handleEvChange,
                handleCBConfigChange,
                handleCBConfigChangeLoad,
                handleCBConfigChangeEv,
                updateUtilitytConfig,
                updateGensetConfig,
                updateBatteryConfig,
                updateChpConfig,
                updatePvArrayConfig,
                updateLoadConfig,
                updateEvConfig,
                updatePowerMeterData,
                updateCbConfigData,
                updateLoadCbConfigData,
                updateEvCbConfigData,
                setAssetSelection,
                selectedAssetHandlerPv,
                assetEditStatusUpdateDER,
                assetEditStatusUpdateAssetCB,
                assetEditStatusUpdateLoad,
                assetEditStatusUpdateLoadCb,
                assetEditStatusUpdateEvCb,
                assetEditStatusUpdateAssetPM,
                assetEditStatusUpdatePv,
                checkGensetRemoveCondition,
                checkBatteryRemoveCondition,
                removeCHP,
                removePvWithAlert,
                removePv,
                removeLoad,
                removeEv,
                isCopyAllButtonDisabled,
                copyAllPvs,
                arrayMaxPowerFinder,
              }}
            >
              {detailsBlock.assetUtilityDetailsBlock && <UtilityAsset />}
              {detailsBlock.assetGensetDetailsBlock &&
                [embLabels.GRID_CONNECTED, embLabels.ISLANDABLE].includes(
                  siteType
                ) && <GensetAsset />}
              {detailsBlock.assetGensetDetailsBlock &&
                ([embLabels.PV_HYBRIDIZATION].includes(siteType) ||
                  ([embLabels.ISLANDABLE_GSX].includes(siteType) &&
                    architectureType === embLabels.PV_GENSET_ATS)) && (
                  <GensetAssetPvHyb />
                )}
              {detailsBlock.assetBatteryDetailsBlock && <BatteryAsset />}
              {detailsBlock.assetPvDetailsBlock && <PVAsset />}
              {detailsBlock.assetCHPDetailsBlock && <CHPAsset />}
              {detailsBlock.loadDetailsBlock && <LoadAsset />}
              {detailsBlock.cbLoadDetailsBlock && <LoadCB />}
              {detailsBlock.evDetailsBlock && <EvCluster />}
              {detailsBlock.cbEvDetailsBlock && <EvCB />}
              {detailsBlock.cbAssetDetailsBlock && (
                <CircuitBreakerDetails
                  siteType={siteType}
                  architectureType={architectureType}
                  selectedValues={selectedValues}
                />
              )}
              {isNoAssetSelected() && <NoAssetSelected />}
            </AssetBlockContextProvider>
          </div>
        </div>
      </div>

      <Snackbar snackBar={snackBar} ref={snackBarRef} />
      <AlertIcon alert={alert} onClose={closeAlertMessage} />
      <Confirm
        confirm={confirm}
        onClose={closeConfirmMessage}
        onOk={okConfirmMessage}
      />

      <AssetSelectionDER
        assetInfo={assetInfo}
        onSelectManufacturer={selectManufacturer}
        closeAssetSelectionBlock={closeAssetSelectionBlock}
        onSelectAssetInModal={selectAssetFromList}
      />

      <AssetSelectionPV
        siteType={siteType}
        architectureType={architectureType}
        assetDetailsDisplay={assetDetailsDisplay}
        PVDetails={PVDetails}
        onHandlePvChange={handlePvChange}
        onsetAssetSelection={setAssetSelection}
        assetEditStatusUpdatePv={assetEditStatusUpdatePv}
        defaultsMinMax={defaultsMinMax}
        onClosePvDetailsBlock={closePvDetailsBlock}
        onUpdatePvConfig={updatePvConfig}
      />

      <AddPvModals
        CBAssetList={CBAssetList}
        siteType={siteType}
        clientType={clientType}
        architectureType={architectureType}
        PVDetails={PVDetails}
        onPvDetailsDispatch={PVDetailsDispatch}
        onAddPVSetWithCB={addPVSetWithCB}
      />
    </div>
  );
};

export default DesignAsset;
