import React, { Component } from "react";

class SLDEcc extends Component {
  render() {
    // const { ecc } = this.props;
    // return [
    //     <svg>
    //         <g id="ECC">
    //             <svg>
    //                 <g id="ECC" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    //                     <g id="ECC" transform="translate(-532.000000, -514.000000)">
    //                         <g id="SLD/Infos/Microgrid_Background" transform="translate(532.000000, 514.000000)">
    //                             <rect id="Rectangle-Copy-24" stroke="#333333" stroke-width="3" x="90" y="195" width={ecc.width} height="180" rx="16" />
    //                             <text id="AAA" font-family="Nunito-Regular, Nunito" font-size="18" font-weight="normal" fill="#333333">
    //                                 <tspan x="95" y="225">Low Voltage </tspan>
    //                                 <tspan x="95" y="245">Switchboard</tspan>
    //                             </text>
    //                         </g>
    //                     </g>
    //                 </g>
    //             </svg>
    //         </g>
    //     </svg>
    // ];
    const { ecc } = this.props;
    return [
      <svg>
        {" "}
        <g id="ECC">
          {" "}
          <svg>
            {" "}
            <g
              id="ECC"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              {" "}
              <g id="ECC" transform="translate(-532.000000, -514.000000)">
                {" "}
                <g
                  id="SLD/Infos/Microgrid_Background"
                  transform="translate(532.000000, 514.000000)"
                >
                  {" "}
                  <rect
                    id="Rectangle-Copy-24"
                    stroke="#333333"
                    stroke-width="3"
                    x="20"
                    y="195"
                    width={ecc.width}
                    height="180"
                    rx="16"
                  />{" "}
                  <text
                    id="AAA"
                    font-family="Nunito-Regular, Nunito"
                    font-size="18"
                    font-weight="normal"
                    fill="#333333"
                  >
                    {" "}
                    <tspan x="25" y="225">
                      Low Voltage{" "}
                    </tspan>
                    /{" "}
                    <tspan x="25" y="245">
                      Switchboard
                    </tspan>{" "}
                  </text>{" "}
                </g>{" "}
              </g>{" "}
            </g>{" "}
          </svg>{" "}
        </g>{" "}
      </svg>,
    ];
  }
}

export default SLDEcc;
