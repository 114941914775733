import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";
import embLabels from "../../config/emb-labels-en.json";

const Ev = ({ index, status }) => {
  const {
    CBEVList: [{ uiData: ev, configData }],
    UserScope,
    CBEVListDispatch,
    assetDetailsReset,
    selectedValuesHandler,
    selectedAssetHandler,
    evConfigDataHandler,
    callSnackbar,
  } = useContext(DesignAssetContext);

  const evSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "evDetailsBlock";
      const selectedAssetValue = "selectedEv";
      CBEVListDispatch({
        type: ACTIONS.EV_SELECT,
        payload: {
          index,
        },
      });
      selectedValuesHandler(index, selectedAssetValue, assetBlock);
      selectedAssetHandler(embLabels.EV_CHARGING_STATION, index);
      evConfigDataHandler(index);
    } else {
      callSnackbar(
        "error",
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  return (
    <svg className={`pointer`} style={status ? {} : { display: "none" }}>
      <g id="ev">
        <svg
          x={ev.xAxis}
          y={ev.yAxis}
          width="149px"
          height="475px"
          viewBox="0 0 90 550"
          onClick={evSelectHandler}
        >
          <title>{configData.name}</title>
          <g
            id="Design"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Design/Assets-_-00"
              transform="translate(-604.000000, -372.000000)"
            >
              <g
                id="SLD/Production/Uniline/Undefined"
                transform="translate(604.000000, 372.000000)"
              >
                <g id="SLD/Production/Uniline/Tobedefine">
                  <g id="Group-7">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="95"
                      width="96"
                      height="148"
                    ></rect>
                    <circle
                      id="Oval"
                      stroke="#42B4E6"
                      strokeWidth="3"
                      cx="48"
                      cy="193.5"
                      r="55"
                      className={ev.selected ? "" : "hidden"}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="rgba(0, 135, 205, 0.2)"
                    ></circle>

                    <circle
                      id="Oval"
                      strokeWidth="3"
                      fill="#FFFFFF"
                      cx="48"
                      cy="193.5"
                      r="46.5"
                      stroke={ev.configured ? "#333" : "#E47F00"}
                    ></circle>

                    <text
                      id="segment_energy_infra"
                      fontFamily="se-icon"
                      fontSize="56"
                      fontWeight="normal"
                      fill="#333333"
                    >
                      <tspan x="20" y="210">
                        electric_vehicle_terminal
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <line
          x1={ev.x1pos}
          y1={ev.y1pos}
          x2={ev.x2pos}
          y2={ev.y2pos}
          id="Line-2"
          strokeWidth="4"
          strokeDasharray="6 8"
          strokeLinecap="round"
          stroke={ev.configured ? "#333333" : "#E47F00"}
        ></line>
      </g>
    </svg>
  );
};

export default Ev;
