import React, { useContext } from "react";
import embLabels from "../../config/emb-labels-en.json";

import { AssetBlockContext } from "../CustomHooks/ContextApi";

const UtilityAsset = () => {
  const {
    siteType,
    architectureType,
    gsxExternalId,
    configData,
    defaultsMinMax,
    handleUtilityChange,
    updateUtilitytConfig,
  } = useContext(AssetBlockContext);
  const utilityChangeHandler = e => {
    handleUtilityChange(e);
  };
  const utilityUpdateHandler = e => updateUtilitytConfig(e);
  return (
    <div name="utility" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 pl-0 se-white-background">
        <div className="col-md-12 pb-2 se-white-background ">
          <se-icon
            color="primary"
            className="se-icon icon_button se-life-green"
            size="medium"
          >
            segment_energy_infrastructure
          </se-icon>
          <span className="ml-2 bold">Utility</span>
        </div>
      </div>

      <div className="col-md-12 mt-1 mb-1 se-white-background align-middle se-border">
        <div className="row">
          <label className=" col-md-6 bold mt-2 mb-2 pl-1 se-font-14px">
            Asset Settings
          </label>
          <div className="col-md-6 mt-1 text-right">
            <button
              type="button"
              className="btn btn-secondary btn-sm se-btn-primary"
              onClick={utilityUpdateHandler}
            >
              <se-icon size="nano">action_save</se-icon>
              <span>&nbsp;</span>Save
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-12 pt-2 pb-2 se-grey-background">
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">
            Name <span className="se-mandatory">*</span>
          </label>
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm inputFocus"}
            id="exampleFormControlInput1"
            name="name"
            maxLength="50"
            value={configData.configData.name}
            onChange={utilityChangeHandler}
            placeholder="Name"
          />
          <div
            className="invalid-feedback-custom"
            style={!configData.configData.name ? {} : { display: "none" }}
          >
            {embLabels.nameRequired}
          </div>
        </div>
        {[embLabels.ISLANDABLE_GSX].includes(siteType) &&
          architectureType === embLabels.PV_GENSET_ATS && (
            <div className="form-group mb-1">
              <label htmlFor="exampleFormControlInput1">External ID</label>
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-sm"
                id="exampleFormControlInput1"
                placeholder="External ID"
                onChange={utilityChangeHandler}
                name="externalId"
                maxLength="64"
                value={gsxExternalId.utilityExternalId}
              />
            </div>
          )}

        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">Description</label>
          <input
            type="text"
            autoComplete="none"
            className="form-control form-control-sm"
            id="exampleFormControlInput1"
            placeholder="Description"
            onChange={utilityChangeHandler}
            name="description"
            maxLength="100"
            value={configData.configData.description}
          />
        </div>

        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput2">
            Maximum Available Power (kW) <span className="se-mandatory">*</span>
          </label>
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput2"
            name="maximum_available_power"
            maxLength="4"
            value={configData.configData.maximum_available_power}
            onChange={utilityChangeHandler}
            placeholder="Maximum Available Power (kW)"
          />
          {configData.configData.maximum_available_power === "" && (
            <div className="invalid-feedback-custom">
              Maximum Available Power (kW) is required.
            </div>
          )}
          {configData.configData.maximum_available_power !== "" &&
          (parseInt(configData.configData.maximum_available_power) <
            defaultsMinMax.maxAvailablePowerUtilityMin ||
            parseInt(configData.configData.maximum_available_power) >
              defaultsMinMax.maxAvailablePowerUtilityMax) ? (
            <div className="invalid-feedback-custom">
              Maximum Available Power should be in range(
              {" " + defaultsMinMax.maxAvailablePowerUtilityMin} -{" "}
              {defaultsMinMax.maxAvailablePowerUtilityMax} kW).
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput2">
            Utility CO2 Emission Rate (g/kWh){" "}
            <span className="se-mandatory">*</span>
          </label>
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput2"
            name="utilityCO2"
            maxLength="4"
            value={configData.configData.utilityCO2}
            onChange={utilityChangeHandler}
            placeholder="Utility CO2 Emission Rate"
          />
          {configData.configData.utilityCO2 === "" && (
            <div className="invalid-feedback-custom">
              Utility CO2 Emission g/kWh is required.
            </div>
          )}

          {configData.configData.utilityCO2 !== "" &&
            (parseInt(configData.configData.utilityCO2) <
              defaultsMinMax.utilityCO2Min ||
              parseInt(configData.configData.utilityCO2) >
                defaultsMinMax.utilityCO2Max) && (
              <div className="invalid-feedback-custom">
                Utility CO2 Emission should be in range(
                {defaultsMinMax.utilityCO2Min} - {defaultsMinMax.utilityCO2Max}{" "}
                g/kWh).
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default UtilityAsset;
