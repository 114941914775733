import React, { Component } from "react";

class SLDGenset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOverData: false,
    };
  }
  onCBMouseOver() {
    this.setState({ mouseOverData: !this.state.mouseOverData });
  }

  render() {
    const {
      genset,
      onGensetSelect,
      onGensetCBSelect,
      gensetName,
      gensetCbName,
    } = this.props;
    return [
      <svg style={genset.status ? {} : { display: "none" }} className="pointer">
        <g id="genset">
          <line
            x1="575"
            y1="320"
            x2="630"
            y2="320"
            id="Line-2"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="8,8"
          ></line>
          <svg
            x="500"
            y="141"
            width="195px"
            height="130px"
            viewBox="0 0 10 149"
            onClick={onGensetSelect}
          >
            <title className="se-custom-tooltip">{gensetName}</title>
            <g id="Design" fill="none">
              <g
                id="Design/Assets-_-00"
                transform="translate(-748.000000, -372.000000)"
              >
                <g
                  id="SLD/Genset/Uniline/OK"
                  transform="translate(748.000000, 372.000000)"
                >
                  <rect
                    id="Rectangle"
                    x="0"
                    y="0"
                    width="96"
                    height="144"
                  ></rect>
                  <line
                    x1="48.5"
                    y1="96"
                    x2="48.5"
                    y2="144"
                    id="Line-2"
                    stroke={genset.configured ? "#333333" : "#E47F00"}
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></line>
                  <circle
                    id="Oval"
                    stroke="#42B4E6"
                    stroke-width="3"
                    cx="48"
                    cy="56"
                    r="55"
                    style={genset.selected ? {} : { display: "none" }}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    fill="rgba(0, 135, 205, 0.2)"
                  ></circle>
                  <circle
                    id="Oval"
                    stroke-width="3"
                    stroke={genset.configured ? "#333333" : "#E47F00"}
                    fill="#FFFFFF"
                    fill-rule="evenodd"
                    cx="48"
                    cy="56"
                    r="46.5"
                  ></circle>
                  <text
                    id="segment_energy_infra"
                    font-family="se-icon"
                    font-size="50"
                    stroke="#333333"
                    font-weight="normal"
                    fill="#0F0F0F"
                  >
                    <tspan x="25" y="74">
                      genset_01
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </svg>
        </g>
        <g id="CB2-opened">
          <svg
            width="75px"
            height="90px"
            viewBox="0 0 10 149"
            x="600"
            y="270"
            onClick={onGensetCBSelect}
          >
            <title className="se-custom-tooltip">{gensetCbName}</title>
            <g
              id="Design"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Design/Assets-_-00"
                transform="translate(-795.000000, -512.000000)"
              >
                <g
                  id="SLD/CB/Vertical/Uniline/Open/Black_NEMA"
                  transform="translate(748.000000, 514.000000)"
                  onMouseOver={this.onCBMouseOver.bind(this)}
                  onMouseOut={this.onCBMouseOver.bind(this)}
                >
                  <g
                    id="Group-19"
                    stroke-width="4"
                    fill-rule="evenodd"
                    fill={
                      genset.CBSelected
                        ? "rgba(65, 181, 230, 0.35)"
                        : this.state.mouseOverData
                        ? "rgba(65, 181, 230, 0.35)"
                        : "none"
                    }
                    stroke={genset.CBSelected ? "#42B4E6" : "none"}
                  >
                    <rect
                      id="Rectangle-Copy-4"
                      x="0"
                      y="12"
                      width="96"
                      height="122"
                    ></rect>
                    <line
                      x1="48.501111"
                      y1="3.55271368e-15"
                      x2="48.501111"
                      y2="48"
                      id="Line-2-Copy-13"
                      stroke={genset.CBConfigured ? "#333333" : "#E47F00"}
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></line>
                    <line
                      x1="48.501111"
                      y1="96"
                      x2="48.501111"
                      y2="144"
                      id="Line-2-Copy-15"
                      stroke={genset.CBConfigured ? "#333333" : "#E47F00"}
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></line>
                  </g>
                  <path
                    d="M59.9160156,48.3289474 C67.860026,64.1096491 67.860026,80 59.9160156,96"
                    id="Path-11"
                    stroke={genset.CBConfigured ? "#333333" : "#E47F00"}
                    stroke-width="4"
                    stroke-linecap="round"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </g>
      </svg>,
    ];
  }
}

export default SLDGenset;
