import React, { Component } from "react";
import _ from "lodash";
import SLDEcc from "../components/SLD/sldecc";
import SLDBusbar from "../components/SLD/sldbusbar";
import SLDUtility from "../components/SLD/sldutility";
import SLDGenset from "../components/SLD/sldgenset";
import SLDBattery from "../components/SLD/sldbattery";
import SLDPv1 from "../components/SLD/sldpv1";
import SLDPv2 from "../components/SLD/sldpv2";
import SLDPv3 from "../components/SLD/sldpv3";
import SLDCHP from "../components/SLD/CHP";

import SLDLoad1 from "../components/SLD/sldload1";
import SLDEv from "../components/SLD/sldev";

import SLDEccOnGrid from "../components/SLDONGRID/sldecc";
import SLDUtilityOnGrid from "../components/SLDONGRID/sldutility";
import SLDGensetOnGrid from "../components/SLDONGRID/sldgenset";
import SLDBatteryOnGrid from "../components/SLDONGRID/sldbattery";
import SLDPv1OnGrid from "../components/SLDONGRID/sldpv1";
import SLDLoad1OnGrid from "../components/SLDONGRID/sldload1";
import SLDEvOnGrid from "../components/SLDONGRID/sldev";
import CHPOnGrid from "../components/SLDONGRID/CHP";

import SLDBusbarPVH from "../components/SLDPVHYBRIDIZATION/sldBusBar";
import SLDEccPVH from "../components/SLDPVHYBRIDIZATION/sldECC";
import SLDGensetPVH from "../components/SLDPVHYBRIDIZATION/sldGenset";
import SLDLoadPVH from "../components/SLDPVHYBRIDIZATION/sldLoad";
import SLDPvPVH from "../components/SLDPVHYBRIDIZATION/sldPV";
import SLDUtilityPVH from "../components/SLDPVHYBRIDIZATION/sldUtility";
import SLDCHPPVH from "../components/SLDPVHYBRIDIZATION/sldChp";

import domtoimage from "dom-to-image";
import ApiSession from "../api/ApiSession";

const apiSession = new ApiSession();

class DesignAssetSLD extends Component {
  constructor(props) {
    super(props);
    let userSampleData = {
      name: "",
      role: "",
      access: {},
    };
    this.state = {
      sldLoadCompleted: false,

      displayPage: false,

      utilityConfigData: {
        name: "",
        description: "",
        utilityCO2: "",
        maximum_available_power: 0,
      },
      gensetConfigData: {
        name: "",
        description: "",
        min_power: "",
        max_power: "",
        gensetCO2: "",
        starting_timer: "",
        cooling_timer: "",
        minimum_running_period: "",
      },
      batteryConfigData: {
        name: "",
        description: "",
        min_power: "",
        max_power: "",
        capacity: 0,
        soc_max: 90,
        soc_min: 10,
        starting_timer: "",
      },
      chpConfigData: {
        name: "",
        description: "",
        nominal_power: "",
      },
      loadConfigData: {
        name: "",
        description: "",
        maximum_available_power: 0,
      },
      evConfigData: {
        name: "",
        description: "",
        maximum_available_power: 0,
      },
      cbConfigData: {
        name: "",
        description: "",
        motorized: true,
        loadCategory: 0,
        loadPriority: 0,
      },
      cbConfigDataSample: {
        name: "",
        description: "",
        motorized: true,
        loadCategory: 0,
        loadPriority: 0,
      },
      sampleCbConfigData: {
        name: "",
        description: "",
        motorized: true,
        loadCategory: 0,
        loadPriority: 0,
      },
      sampleConfigData: {
        name: "",
        description: "",
        min_power: "",
        max_power: "",
      },
      configData: {
        name: "",
        description: "",
        min_power: "",
        max_power: "",
      },

      cbCount: ["CB_10", "CB_20", "CB_30", "CB_80"],

      assetCount: ["Utility", "Genset", "Battery", "CHP"],

      batteryCommunicationData: {
        ipaddress: "",
        subnetmask: "",
        defaultGateway: "",
        modbusSlaveId: "",
      },

      communicationData: {
        ipaddress: "",
        subnetmask: "",
        defaultGateway: "",
        modbusSlaveId: "",
      },
      communicationDataSample: {
        ipaddress: "",
        subnetmask: "",
        defaultGateway: "",
        modbusSlaveId: "",
      },

      evCommunicationData: {
        ipaddress: "",
        subnetmask: "",
        defaultGateway: "",
        modbusSlaveId: "",
      },
      cbAssetDetailsBlock: false,
      cbLoadDetailsBlock: false,
      cbEvDetailsBlock: false,
      loadDetailsBlock: false,
      evDetailsBlock: false,
      assetUtilityDetailsBlock: false,
      assetGensetDetailsBlock: false,
      assetBatteryDetailsBlock: false,
      assetPvDetailsBlock: false,
      assetCHPDetailsBlock: false,

      CBAssetList: [
        {
          modbusSlaveId: "255",
          assetType: "Utility",
          cbConfigData: {
            name: "",
            description: "",
            motorized: true,
            loadCategory: 0,
            loadPriority: 0,
          },
          configData: {
            name: "",
            description: "",
            utilityCO2: "",
            maximum_available_power: 0,
          },
          uiData: {
            configured: false,
            configurable: false,
            status: false,
            detailsBlock: false,
            selected: false,
            CBSelected: false,
            CBConfigured: true,
            xAxisCB: 250,
            yAxisCB: 192,
            xAxis: 150,
            yAxis: 70,
          },
          communicationData: {},
        },
        {
          modbusSlaveId: "2",
          assetType: "Genset",
          cbConfigData: {
            name: "",
            description: "",
            motorized: true,
            loadCategory: 0,
            loadPriority: 0,
          },
          configData: {
            name: "",
            description: "",
            min_power: "",
            max_power: "",
            gensetCO2: "",
            starting_timer: "",
            cooling_timer: "",
            minimum_running_period: "",
          },
          uiData: {
            configured: false,
            status: false,
            configurable: false,
            detailsBlock: false,
            selected: false,
            CBSelected: false,
            CBConfigured: true,
            xAxisCB: 360,
            yAxisCB: 192,
            xAxis: 260,
            yAxis: 70,
          },
          assetDetails: [],
          communicationData: {},
        },
        {
          modbusSlaveId: "3",
          assetType: "BESS",
          cbConfigData: {
            name: "",
            description: "",
            motorized: true,
            loadCategory: 0,
            loadPriority: 0,
          },
          configData: {
            name: "",
            description: "",
            max_power: "",
            capacity: 0,
            soc_max: "",
            soc_min: "",
            starting_timer: "",
          },
          uiData: {
            configured: false,
            status: false,
            detailsBlock: false,
            selected: false,
            CBConfigured: true,
            xAxisCB: 470,
            yAxisCB: 192,
            xAxis: 370,
            yAxis: 70,
          },
          assetDetails: [],
          communicationData: {},
        },
        {
          modbusSlaveId: "4",
          assetType: "Inverter",
          pv: 1,
          cbConfigData: {
            name: "",
            description: "",
            motorized: true,
            loadCategory: 0,
            loadPriority: 0,
          },
          configData: {
            name: "",
            description: "",
          },
          uiData: {
            set: 1,
            groupName: "",
            group_max_power: 0,
            configured: false,
            status: false,
            detailsBlock: false,
            selected: false,
            CBConfigured: true,
            count: 0,
            xAxisCB: 470,
            yAxisCB: 192,
            xAxis: 370,
            yAxis: 70,
          },
          communicationData: {},
          subPvData: [],
        },
        {
          modbusSlaveId: "5",
          assetType: "Inverter",
          pv: 2,
          cbConfigData: {
            name: "",
            description: "",
            motorized: true,
            loadCategory: 0,
            loadPriority: 0,
          },
          configData: {
            name: "",
            description: "",
          },
          uiData: {
            set: 2,
            groupName: "",
            group_max_power: 0,
            configured: false,
            status: false,
            detailsBlock: false,
            selected: false,
            CBConfigured: true,
            count: 0,
            xAxisCB: 470,
            yAxisCB: 192,
            xAxis: 370,
            yAxis: 70,
          },
          communicationData: {},
          subPvData: [],
        },
        {
          modbusSlaveId: "6",
          assetType: "Inverter",
          pv: 3,
          cbConfigData: {
            name: "",
            description: "",
            motorized: true,
            loadCategory: 0,
            loadPriority: 0,
          },
          configData: {
            name: "",
            description: "",
          },
          uiData: {
            set: 3,
            groupName: "",
            group_max_power: 0,
            configured: false,
            status: false,
            detailsBlock: false,
            selected: false,
            CBConfigured: true,
            count: 0,
            xAxisCB: 470,
            yAxisCB: 192,
            xAxis: 370,
            yAxis: 70,
          },
          communicationData: {},
          subPvData: [],
        },
        {
          modbusSlaveId: "7",
          assetType: "CHP",
          cbConfigData: {
            name: "",
            description: "",
            motorized: true,
            loadCategory: 0,
            loadPriority: 0,
          },
          configData: {
            name: "",
            description: "",
            nominal_power: "",
          },
          uiData: {
            configured: false,
            status: false,
            configurable: false,
            detailsBlock: false,
            selected: false,
            CBSelected: false,
            CBConfigured: true,
            xAxisCB: 360,
            yAxisCB: 192,
            xAxis: 260,
            yAxis: 70,
            x1pos: 675,
            y1pos: 350,
            x2pos: 790,
            y2pos: 350,
          },
          assetDetails: [],
          communicationData: {},
          powerMeterData: {
            name: "",
            description: "",
            powerMeterModbusId: "",
          },
        },
      ],

      CBLoadList: [
        {
          modbusSlaveId: "21",
          configData: {
            name: "",
            description: "",
            maximum_available_power: 0,
          },
          uiData: {
            status: false,
            configured: false,
            CBSelected: false,
            selected: false,
            CBConfigured: true,
            xAxisCB: 205,
            yAxisCB: 290,
            xAxis: 160,
            yAxis: 292,
          },
          cbConfigData: {
            name: "",
            description: "",
            motorized: true,
            loadCategory: 0,
            loadPriority: 0,
          },
        },
      ],

      selectedLoadCB: "",
      selectedAssetCB: "",
      selectedEvCB: "",
      selectedLoad: "",
      selectedEv: "",
      selectSubPv: "1",
      addPvShowDialog: false,
      selectedPv: 3,

      pvCount: 0,
      branchName: 3,
      existOrNew: "new",

      noProjectAlertPopUp: false,
      showHideAddPvToBranch: false,

      CBEVList: [
        {
          assetConnected: "EV",
          modbusSlaveId: "31",
          configData: {
            name: "",
            description: "",
            maximum_available_power: 0,
          },
          CBId: "CB_60",
          uiData: {
            status: false,
            CBSelected: false,
            CBConfigured: true,
            xAxisCB: 645,
            yAxisCB: 290,
            xAxis: 600,
            yAxis: 292,
          },
          cbConfigData: {
            name: "",
            description: "",
            motorized: true,
            loadCategory: 0,
            loadPriority: 0,
          },
          communicationData: {},
          assetDetails: [],
        },
      ],

      pvset1Data: [],
      pvset2Data: [],
      pvset3Data: [],
      pvConfig: {
        name: "",
        description: "",
        max_power: "",
        ipaddress: "",
        subnetmask: "",
        modbusSlaveId: "",
        defaultGateway: "",
        status: false,
        assetDetails: [],
      },
      assetSelected: false,
      assets: [],
      assetManufacturer: [],
      assetModelType: [],
      selectedManufacturer: "",
      selectdModelType: "",
      assetDetailsDisplay: {
        assetId: "",
        assetName: "",
        assetType: "",
        manufacturer: "",
        assetInfo: "",
        communicationModel: "",
        imageLink: "",
        assetProperties: [],
      },
      assetDetailsDisplaySample: {
        assetId: "",
        assetName: "",
        assetType: "",
        manufacturer: "",
        assetInfo: "",
        communicationModel: "",
        imageLink: "",
        assetProperties: [],
      },

      //xDer: 100,
      xDer: 8,
      yDer: 190,

      //xDerCB: 200,
      xDerCB: 108,
      yDerCB: 192,

      // xLoad: 90,
      xLoad: 10,
      yLoad: 292,

      // xLoadCB: 135,
      xLoadCB: 55,
      yLoadCB: 290,

      derDiff: 110,
      loadDiff: 110,

      //after 11 loads
      xLoadNew: 65,
      xLoadCBNew: 110,

      // eccwidth: 160,
      // busbarx2: 230,

      eccwidth: 200,
      busbarx2: 200,

      derCount: 0,
      loadCount: 0,
      evCount: 0,

      currentSelectedCBSlaveID: 0,

      ecc: {
        width: 690,
      },
      busbar: {
        x2: 755,
      },

      useCaseValidGenset: false,

      DERStack: {
        utility: 1,
        genset: 1,
        battery: 1,
        pvset1: 1,
        chp: 0,
        pvset2: 0,
        pvset3: 0,
      },

      /* ecc:{
                width: 825
            },
            busbar:{
                x2 :935
            }, */

      ifeIpAddress: "",
      ifeSubnetMask: "",
      ifeGateway: "",
      ifeSettingsUpdated: false,
      ifeCBDetails: false,
      siteType: "",
      totalCount: "",
      updatePvCountOne: true,
      updatePvCountTwo: true,
      updatePvCountThree: true,
      CX: 600,
      CY: 350,
      radius: 46,
      angle: 0,
      positions: [],
      userScope:
        JSON.parse(localStorage.getItem("scope")) !== null
          ? JSON.parse(localStorage.getItem("scope"))
          : userSampleData,
      pvConfiguredCountInCB: 0,
      pvCountInCB: 0,
    };

    this.calculateCoOridinates = this.calculateCoOridinates.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("projectId") !== "null") {
      this.fetchComponentsExisted();
    }
  }

  fetchComponentsExisted() {
    apiSession
      .getComponents(sessionStorage.getItem("projectId"))
      .then(json => {
        if (json.components.length) {
          this.setState({ siteType: json.siteType });
          this.assignComponents(json);
        } else {
          this.props.sldStatusCheck(true);
        }
      })
      .catch(error => {});
  }

  assignComponents(component) {
    let components = component.components;
    components.sort(function (a, b) {
      return a.componentIdx.localeCompare(b.componentIdx);
    });
    let loadCBIndex = 0;
    let loadIndex = 0;
    let newpvset1Data = [];
    let newpvset2Data = [];
    let newpvset3Data = [];
    let CBLoadListUpdated = [];
    components.map(componentsData => {
      let attributes = JSON.parse(componentsData.attributes);

      switch (componentsData.componentType) {
        case "Utility":
          let newUtilityData = Object.assign([], this.state.CBAssetList);
          newUtilityData[0].uiData = JSON.parse(componentsData.uiAttributes);
          newUtilityData[0].uiData.selected = false;
          newUtilityData[0].uiData.CBSelected = false;
          newUtilityData[0].configData = attributes;
          newUtilityData[0].configData.name = componentsData.componentName;
          newUtilityData[0].configData.description = attributes.description;
          newUtilityData[0].configData.utilityCO2 = attributes.utilityCO2;
          newUtilityData[0].configData.maximum_available_power =
            attributes.maximum_available_power;
          newUtilityData[0].assetType = componentsData.componentType;
          this.setState({ CBAssetList: newUtilityData });
          this.setState({ derCount: this.state.derCount + 1 });
          break;

        case "Genset":
          let newGensetData = Object.assign([], this.state.CBAssetList);
          newGensetData[1].uiData = JSON.parse(componentsData.uiAttributes);
          newGensetData[1].uiData.selected = false;
          newGensetData[1].uiData.CBSelected = false;
          newGensetData[1].configData = attributes;
          newGensetData[1].configData.name = componentsData.componentName;
          newGensetData[1].configData.description = attributes.description;
          newGensetData[1].assetType = componentsData.componentType;
          let communicationDataNew = Object.assign(
            {},
            this.state.communicationData
          );
          if (componentsData.communications.length) {
            communicationDataNew.ipaddress =
              componentsData.communications[0].ip;
            communicationDataNew.subnetmask =
              componentsData.communications[0].subnetmask;
            communicationDataNew.defaultGateway =
              componentsData.communications[0].defaultGateway;
            communicationDataNew.modbusSlaveId =
              componentsData.communications[0].modbusSlaveId;
            newGensetData[1].communicationData = communicationDataNew;
          }
          if (componentsData.asset !== undefined) {
            newGensetData[1].assetDetails.push(componentsData.asset);
          }
          this.setState({ CBAssetList: newGensetData });
          this.setState({ derCount: this.state.derCount + 1 });
          break;

        case "BESS":
          let newBatteryData = Object.assign([], this.state.CBAssetList);
          newBatteryData[2].uiData = JSON.parse(componentsData.uiAttributes);
          newBatteryData[2].uiData.selected = false;
          newBatteryData[2].uiData.CBSelected = false;
          newBatteryData[2].configData = attributes;
          newBatteryData[2].configData.name = componentsData.componentName;
          newBatteryData[2].configData.description = attributes.description;
          newBatteryData[2].configData.max_power = attributes.max_power;
          newBatteryData[2].configData.capacity = attributes.capacity;
          newBatteryData[2].configData.soc_max = attributes.soc_max;
          newBatteryData[2].configData.soc_min = attributes.soc_min;
          newBatteryData[2].assetType = componentsData.componentType;
          let newBatteryCom = Object.assign(
            [],
            this.state.batteryCommunicationData
          );
          if (componentsData.communications.length) {
            newBatteryCom.ipaddress = componentsData.communications[0].ip;
            newBatteryCom.subnetmask =
              componentsData.communications[0].subnetmask;
            newBatteryCom.defaultGateway =
              componentsData.communications[0].defaultGateway;
            newBatteryCom.modbusSlaveId =
              componentsData.communications[0].modbusSlaveId;
            this.setState({ batteryCommunicationData: newBatteryCom });
          }
          newBatteryData[2].communicationData = newBatteryCom;
          if (componentsData.asset !== undefined) {
            newBatteryData[2].assetDetails.push(componentsData.asset);
          }

          this.setState({ CBAssetList: newBatteryData });
          this.setState({ derCount: this.state.derCount + 1 });
          break;

        case "Inverter":
          let uiData = JSON.parse(componentsData.uiAttributes);
          if (uiData.pv === 1) {
            let newPvData = Object.assign([], this.state.CBAssetList);
            newPvData[3].uiData = JSON.parse(componentsData.uiAttributes);
            newPvData[3].uiData.selected = false;
            newPvData[3].uiData.CBSelected = false;
            newPvData[3].assetType = componentsData.componentType;

            this.setState({ CBAssetList: newPvData });

            if (this.state.updatePvCountOne) {
              this.setState({ derCount: this.state.derCount + 1 });
              this.setState({ updatePvCountOne: false });
            }

            let assetDetailsSample = [];
            if (componentsData.asset) {
              assetDetailsSample.push(componentsData.asset);
            }
            let pvConfigNew = {
              name: componentsData.componentName,
              description: attributes.description,
              max_power: attributes.max_power,
              ipaddress: componentsData.communications[0]
                ? componentsData.communications[0].ip
                : "",
              subnetmask: componentsData.communications[0]
                ? componentsData.communications[0].subnetmask
                : "",
              modbusSlaveId: componentsData.communications[0]
                ? componentsData.communications[0].modbusSlaveId
                : "",
              defaultGateway: componentsData.communications[0]
                ? componentsData.communications[0].defaultGateway
                : "",
              status: newPvData[3].uiData.configured,
              assetDetails: assetDetailsSample,
            };

            newpvset1Data.push(pvConfigNew);
            this.setState({ pvset1Data: newpvset1Data });
          } else if (uiData.pv === 2) {
            let newPvData = Object.assign([], this.state.CBAssetList);
            newPvData[4].uiData = JSON.parse(componentsData.uiAttributes);
            newPvData[4].uiData.selected = false;
            newPvData[4].uiData.CBSelected = false;
            newPvData[4].assetType = componentsData.componentType;

            this.setState({ CBAssetList: newPvData });
            if (this.state.updatePvCountTwo) {
              this.setState({ derCount: this.state.derCount + 1 });
              this.setState({ updatePvCountTwo: false });
            }
            let assetDetailsSample = [];
            if (componentsData.asset) {
              assetDetailsSample.push(componentsData.asset);
            }
            let pvConfigNew = {
              name: componentsData.componentName,
              description: attributes.description,
              max_power: attributes.max_power,
              ipaddress: componentsData.communications[0]
                ? componentsData.communications[0].ip
                : "",
              subnetmask: componentsData.communications[0]
                ? componentsData.communications[0].subnetmask
                : "",
              modbusSlaveId: componentsData.communications[0]
                ? componentsData.communications[0].modbusSlaveId
                : "",
              defaultGateway: componentsData.communications[0]
                ? componentsData.communications[0].defaultGateway
                : "",
              status: newPvData[4].uiData.configured,
              assetDetails: assetDetailsSample,
            };

            newpvset2Data.push(pvConfigNew);
            this.setState({ pvset2Data: newpvset2Data });
          } else if (uiData.pv === 3) {
            let newPvData = Object.assign([], this.state.CBAssetList);
            newPvData[5].uiData = JSON.parse(componentsData.uiAttributes);
            newPvData[5].uiData.selected = false;
            newPvData[5].uiData.CBSelected = false;
            newPvData[5].assetType = componentsData.componentType;

            this.setState({ CBAssetList: newPvData });
            if (this.state.updatePvCountThree) {
              this.setState({ derCount: this.state.derCount + 1 });
              this.setState({ updatePvCountThree: false });
            }
            let assetDetailsSample = [];
            if (componentsData.asset) {
              assetDetailsSample.push(componentsData.asset);
            }
            let pvConfigNew = {
              name: componentsData.componentName,
              description: attributes.description,
              max_power: attributes.max_power,
              ipaddress: componentsData.communications[0]
                ? componentsData.communications[0].ip
                : "",
              subnetmask: componentsData.communications[0]
                ? componentsData.communications[0].subnetmask
                : "",
              modbusSlaveId: componentsData.communications[0]
                ? componentsData.communications[0].modbusSlaveId
                : "",
              defaultGateway: componentsData.communications[0]
                ? componentsData.communications[0].defaultGateway
                : "",
              status: newPvData[5].uiData.configured,
              assetDetails: assetDetailsSample,
            };

            newpvset3Data.push(pvConfigNew);
            this.setState({ pvset3Data: newpvset3Data });
          }

          break;

        case "CHP":
          let CBAssestListData = Object.assign([], this.state.CBAssetList);

          CBAssestListData[6].uiData = JSON.parse(componentsData.uiAttributes);
          CBAssestListData[6].uiData.selected = false;
          CBAssestListData[6].uiData.CBSelected = false;
          CBAssestListData[6].configData = attributes;
          CBAssestListData[6].configData.name = componentsData.componentName;
          CBAssestListData[6].configData.description = attributes.description;
          CBAssestListData[6].configData.nominal_power =
            attributes.nominal_power;
          CBAssestListData[6].assetType = componentsData.componentType;

          CBAssestListData[6].communicationData = {
            ...componentsData.communications[0],
          };
          if (componentsData.asset !== undefined) {
            CBAssestListData[6].assetDetails.push(componentsData.asset);
          }

          this.setState({ CBAssetList: CBAssestListData });
          this.setState({ derCount: this.state.derCount + 1 });
          break;

        case "Load":
          let newLoadData = Object.assign([], this.state.CBLoadList);
          if (loadIndex === 0) {
            newLoadData[0].uiData = JSON.parse(componentsData.uiAttributes);
            newLoadData[0].uiData.selected = false;
            newLoadData[0].uiData.CBSelected = false;
            newLoadData[0].configData = {};
            newLoadData[0].cbConfigData = {};
            newLoadData[0].configData.name = componentsData.componentName;
            newLoadData[0].configData.description = attributes.description;
            newLoadData[0].configData.maximum_available_power =
              attributes.maximum_available_power;
            newLoadData[0].assetType = componentsData.componentType;
          } else {
            let loads = {};
            loads.uiData = JSON.parse(componentsData.uiAttributes);
            loads.uiData.selected = false;
            loads.uiData.CBSelected = false;
            loads.configData = {};
            loads.cbConfigData = {};
            loads.configData.name = componentsData.componentName;
            loads.configData.description = attributes.description;
            loads.configData.maximum_available_power =
              attributes.maximum_available_power;
            loads.assetType = componentsData.componentType;
            newLoadData.push(loads);
          }
          this.setState({ CBLoadList: newLoadData });
          this.setState({ loadCount: this.state.loadCount + 1 });
          loadIndex = loadIndex + 1;
          CBLoadListUpdated = newLoadData;
          break;

        case "EV_charging_station":
          let newEvData = Object.assign([], this.state.CBEVList);
          newEvData[0].uiData = JSON.parse(componentsData.uiAttributes);
          newEvData[0].uiData.selected = false;
          newEvData[0].uiData.CBSelected = false;
          newEvData[0].configData.name = componentsData.componentName;
          newEvData[0].configData.description = attributes.description;
          newEvData[0].configData.maximum_available_power =
            attributes.maximum_available_power;
          newEvData[0].assetType = componentsData.componentType;
          let newEvCom = Object.assign([], this.state.evCommunicationData);
          if (componentsData.communications[0] !== undefined) {
            newEvCom.ipaddress = componentsData.communications[0].ip;
            newEvCom.subnetmask = componentsData.communications[0].subnetmask;
            newEvCom.defaultGateway =
              componentsData.communications[0].defaultGateway;
            newEvCom.modbusSlaveId =
              componentsData.communications[0].modbusSlaveId;
            this.setState({ evCommunicationData: newEvCom });
          }
          newEvData[0].communicationData = newEvCom;
          if (componentsData.asset !== undefined) {
            newEvData[0].assetDetails.push(componentsData.asset);
          }
          this.setState({ CBEVList: newEvData });
          this.setState({ evCount: this.state.evCount + 1 });
          break;

        case "Circuit Breaker":
          if (componentsData.componentIdx === "CB_10") {
            let newUtilityData = Object.assign([], this.state.CBAssetList);
            newUtilityData[0].cbConfigData.name = componentsData.componentName;
            newUtilityData[0].cbConfigData.description = attributes.description;
            newUtilityData[0].cbConfigData.motorized =
              parseInt(attributes.motorized) === 1 ? true : false;
            newUtilityData[0].cbConfigData.loadCategory = parseInt(
              attributes.loadCategory
            );
            newUtilityData[0].cbConfigData.loadPriority = parseInt(
              attributes.loadPriority
            );
            this.setState({ CBAssetList: newUtilityData });
            if (componentsData.communications[0]) {
              this.setState({
                ifeIpAddress: componentsData.communications[0].ip,
              });
              this.setState({
                ifeSubnetMask: componentsData.communications[0].subnetmask,
              });
              this.setState({
                ifeGateway: componentsData.communications[0].defaultGateway,
              });
            }
          } else if (componentsData.componentIdx === "CB_20") {
            let newGensetData = Object.assign([], this.state.CBAssetList);
            newGensetData[1].cbConfigData.name = componentsData.componentName;
            newGensetData[1].cbConfigData.description = attributes.description;
            newGensetData[1].cbConfigData.motorized =
              parseInt(attributes.motorized) === 1 ? true : false;
            newGensetData[1].cbConfigData.loadCategory = parseInt(
              attributes.loadCategory
            );
            newGensetData[1].cbConfigData.loadPriority = parseInt(
              attributes.loadPriority
            );
            this.setState({ CBAssetList: newGensetData });
          } else if (componentsData.componentIdx === "CB_30") {
            let newBatteryData = Object.assign([], this.state.CBAssetList);
            newBatteryData[2].cbConfigData.name = componentsData.componentName;
            newBatteryData[2].cbConfigData.description = attributes.description;
            newBatteryData[2].cbConfigData.motorized =
              parseInt(attributes.motorized) === 1 ? true : false;
            newBatteryData[2].cbConfigData.loadCategory = parseInt(
              attributes.loadCategory
            );
            newBatteryData[2].cbConfigData.loadPriority = parseInt(
              attributes.loadPriority
            );
            this.setState({ CBAssetList: newBatteryData });
          } else if (componentsData.componentIdx === "CB_80") {
            let CBAssestListData = Object.assign([], this.state.CBAssetList);

            CBAssestListData[6].cbConfigData.name =
              componentsData.componentName;
            CBAssestListData[6].cbConfigData.description =
              attributes.description;
            CBAssestListData[6].cbConfigData.motorized =
              parseInt(attributes.motorized) === 1 ? true : false;
            CBAssestListData[6].cbConfigData.loadCategory = parseInt(
              attributes.loadCategory
            );
            CBAssestListData[6].cbConfigData.loadPriority = parseInt(
              attributes.loadPriority
            );
            this.setState({ CBAssetList: CBAssestListData });
          } else if (componentsData.componentIdx === "CB_40") {
            let newPvData = Object.assign([], this.state.CBAssetList);
            newPvData[3].cbConfigData.name = componentsData.componentName;
            newPvData[3].cbConfigData.description = attributes.description;
            newPvData[3].cbConfigData.motorized =
              parseInt(attributes.motorized) === 1 ? true : false;
            newPvData[3].cbConfigData.loadCategory = parseInt(
              attributes.loadCategory
            );
            newPvData[3].cbConfigData.loadPriority = parseInt(
              attributes.loadPriority
            );
            this.setState({ CBAssetList: newPvData });
          } else if (componentsData.componentIdx === "CB_41") {
            let newPvData = Object.assign([], this.state.CBAssetList);
            newPvData[4].cbConfigData.name = componentsData.componentName;
            newPvData[4].cbConfigData.description = attributes.description;
            newPvData[4].cbConfigData.motorized =
              parseInt(attributes.motorized) === 1 ? true : false;
            newPvData[4].cbConfigData.loadCategory = parseInt(
              attributes.loadCategory
            );
            newPvData[4].cbConfigData.loadPriority = parseInt(
              attributes.loadPriority
            );
            this.setState({ CBAssetList: newPvData });
          } else if (componentsData.componentIdx === "CB_42") {
            let newPvData = Object.assign([], this.state.CBAssetList);
            newPvData[5].cbConfigData.name = componentsData.componentName;
            newPvData[5].cbConfigData.description = attributes.description;
            newPvData[5].cbConfigData.motorized =
              parseInt(attributes.motorized) === 1 ? true : false;
            newPvData[5].cbConfigData.loadCategory = parseInt(
              attributes.loadCategory
            );
            newPvData[5].cbConfigData.loadPriority = parseInt(
              attributes.loadPriority
            );
            this.setState({ CBAssetList: newPvData });
          } else if (componentsData.componentIdx === "CB_60") {
            let newEvData = Object.assign([], this.state.CBEVList);
            newEvData[0].cbConfigData.name = componentsData.componentName;
            newEvData[0].cbConfigData.description = attributes.description;
            newEvData[0].cbConfigData.motorized =
              parseInt(attributes.motorized) === 1 ? true : false;
            newEvData[0].cbConfigData.loadCategory = parseInt(
              attributes.loadCategory
            );
            newEvData[0].cbConfigData.loadPriority = parseInt(
              attributes.loadPriority
            );
            newEvData[0].modbusSlaveId =
              componentsData.communications[0].modbusSlaveId;
            this.setState({ CBEVList: newEvData });
          } else {
            if (componentsData.communications[0]) {
              CBLoadListUpdated[loadCBIndex].modbusSlaveId =
                componentsData.communications[0].modbusSlaveId;
            }
            CBLoadListUpdated[loadCBIndex].cbConfigData.description =
              attributes.description;
            CBLoadListUpdated[loadCBIndex].cbConfigData.name =
              componentsData.componentName;
            CBLoadListUpdated[loadCBIndex].cbConfigData.motorized =
              parseInt(attributes.motorized) === 1 ? true : false;
            CBLoadListUpdated[loadCBIndex].cbConfigData.loadCategory = parseInt(
              attributes.loadCategory
            );
            CBLoadListUpdated[loadCBIndex].cbConfigData.loadPriority = parseInt(
              attributes.loadPriority
            );

            if (componentsData.communications[0]) {
              CBLoadListUpdated[loadCBIndex].modbusSlaveId =
                componentsData.communications[0].modbusSlaveId;
            }

            loadCBIndex = loadCBIndex + 1;
          }

          break;
        default:
          break;
      }

      return null;
    });
    this.setState({ CBLoadList: CBLoadListUpdated });

    let newPvData = _.clone(this.state.CBAssetList);
    let pvStatus1 = true;
    this.state.pvset1Data.map(data => {
      if (pvStatus1 === true && data.status === true) {
        // nothing
      } else {
        pvStatus1 = false;
      }
      return null;
    });
    newPvData[3].uiData.configured = pvStatus1;
    let pvStatus2 = true;
    this.state.pvset2Data.map(data => {
      if (pvStatus2 === true && data.status === true) {
        // nothing
      } else {
        pvStatus2 = false;
      }
      return null;
    });
    newPvData[4].uiData.configured = pvStatus2;

    let pvStatus3 = true;
    this.state.pvset3Data.map(data => {
      if (pvStatus3 === true && data.status === true) {
        pvStatus3 = true;
      } else {
        pvStatus3 = false;
      }
      return null;
    });
    newPvData[5].uiData.configured = pvStatus3;

    this.setState({
      CBAssetList: newPvData,
    });

    this.calculateCoOridinates(false);
    this.setState({ sldLoadCompleted: true });
  }
  getLinePosition = count => {
    let centerX = this.state.CX;
    let centerY = this.state.CY;
    let radius = this.state.radius + 3;
    let totalCount =
      this.state.derCount + this.state.loadCount + this.state.evCount;
    // if (this.state.CBAssetList[3].uiData.status) {
    //   totalCount = totalCount + 1;
    // }
    let angle = 360 / totalCount;
    this.setState({ totalCount: totalCount, angle: angle });
    const degreeToRadian = angleInDeg => angleInDeg * (Math.PI / 180);
    //const normalizeDegree = angle => ((angle % 360) + 360) % 360;
    //const linePosition=normalizeDegree(angle*resourceIndex-90)
    // console.log(Math.cos(degreeToRadian(angle * count)));
    // console.log(Math.sin(degreeToRadian(angle  * count)));

    let x1pos = centerX + radius * Math.cos(degreeToRadian(angle * count - 90));
    let y1pos = centerY + radius * Math.sin(degreeToRadian(angle * count - 90));
    let x2pos = x1pos + 145 * Math.cos(degreeToRadian(angle * count - 90));
    let y2pos = y1pos + 145 * Math.sin(degreeToRadian(angle * count - 90));
    let positions = [x1pos, y1pos, x2pos, y2pos];
    this.setState({ positions: positions });
    return positions;
  };

  calculateCoOridinates = componentPush => {
    if (this.state.siteType === "GRID_CONNECTED") {
      let totalCount =
        this.state.derCount + this.state.loadCount + this.state.evCount;
      let count;

      //* UTILITY

      if (this.state.CBAssetList[0].uiData.status) {
        count = 0;
        const [x1pos, y1pos, x2pos, y2pos] = this.getLinePosition(count);
        let newUtilityData = _.cloneDeep(this.state.CBAssetList);
        newUtilityData[0].uiData.xAxis = 464;
        newUtilityData[0].uiData.yAxis = 64;
        newUtilityData[0].uiData.x1pos = x1pos;
        newUtilityData[0].uiData.y1pos = y1pos;
        newUtilityData[0].uiData.x2pos = x2pos;
        newUtilityData[0].uiData.y2pos = y2pos;
        this.setState({ CBAssetList: newUtilityData });
      }

      //* GENSET

      if (this.state.CBAssetList[1].uiData.status) {
        count = count + 1;

        const [x1pos, y1pos, x2pos, y2pos] = this.getLinePosition(count);
        let newGensetData = _.cloneDeep(this.state.CBAssetList);
        if (totalCount === 7) {
          newGensetData[1].uiData.xAxis = 658;
          newGensetData[1].uiData.yAxis = 168;
        } else if (totalCount === 6) {
          newGensetData[1].uiData.xAxis = 675;
          newGensetData[1].uiData.yAxis = 199;
        } else if (totalCount === 5) {
          newGensetData[1].uiData.xAxis = 690;
          newGensetData[1].uiData.yAxis = 226.5;
        } else if (totalCount === 4) {
          newGensetData[1].uiData.xAxis = 700;
          newGensetData[1].uiData.yAxis = 300;
        } else if (totalCount === 3) {
          newGensetData[1].uiData.xAxis = 672;
          newGensetData[1].uiData.yAxis = 418.5;
        }

        newGensetData[1].uiData.x1pos = x1pos;
        newGensetData[1].uiData.y1pos = y1pos;
        newGensetData[1].uiData.x2pos = x2pos;
        newGensetData[1].uiData.y2pos = y2pos;
        this.setState({ CBAssetList: newGensetData });
      }

      //* PV
      if (this.state.CBAssetList[3].uiData.status) {
        count++;
        const [x1pos, y1pos, x2pos, y2pos] = this.getLinePosition(count);
        let newPvData = _.cloneDeep(this.state.CBAssetList);

        if (totalCount === 7) {
          newPvData[3].uiData.xAxis = 695;
          newPvData[3].uiData.yAxis = 350;
        }
        //* count 6
        else if (totalCount === 6 && newPvData[1].uiData.status) {
          newPvData[3].uiData.xAxis = 675;
          newPvData[3].uiData.yAxis = 409;
        } else if (totalCount === 6 && !newPvData[1].uiData.status) {
          newPvData[3].uiData.xAxis = 675;
          newPvData[3].uiData.yAxis = 199;
        }
        //* count 5
        else if (totalCount === 5 && newPvData[1].uiData.status) {
          newPvData[3].uiData.xAxis = 605;
          newPvData[3].uiData.yAxis = 490;
        } else if (totalCount === 5 && !newPvData[1].uiData.status) {
          newPvData[3].uiData.xAxis = 690;
          newPvData[3].uiData.yAxis = 230;
        }

        //* count 4
        else if (totalCount === 4 && newPvData[1].uiData.status) {
          newPvData[3].uiData.xAxis = 465;
          newPvData[3].uiData.yAxis = 538;
        } else if (totalCount === 4 && !newPvData[1].uiData.status) {
          newPvData[3].uiData.xAxis = 700;
          newPvData[3].uiData.yAxis = 300;
        }

        //* count 3
        else if (totalCount === 3) {
          newPvData[3].uiData.xAxis = 672;
          newPvData[3].uiData.yAxis = 418.5;
        }
        newPvData[3].uiData.x1pos = x1pos;
        newPvData[3].uiData.y1pos = y1pos;
        newPvData[3].uiData.x2pos = x2pos;
        newPvData[3].uiData.y2pos = y2pos;

        this.setState({ CBAssetList: newPvData });
      }

      //* CHP

      if (this.state.CBAssetList[6].uiData.status) {
        count++;
        const [x1pos, y1pos, x2pos, y2pos] = this.getLinePosition(count);
        let newChpData = _.cloneDeep(this.state.CBAssetList);

        if (totalCount === 7) {
          newChpData[6].uiData.xAxis = 560;
          newChpData[6].uiData.yAxis = 518;
        }

        //*count 6
        else if (
          totalCount === 6 &&
          newChpData[1].uiData.status &&
          newChpData[3].uiData.status
        ) {
          newChpData[6].uiData.xAxis = 465;
          newChpData[6].uiData.yAxis = 538;
        } else if (
          totalCount === 6 &&
          (!newChpData[1].uiData.status || !newChpData[3].uiData.status)
        ) {
          newChpData[6].uiData.xAxis = 675;
          newChpData[6].uiData.yAxis = 409;
        }

        //* count 5
        else if (
          totalCount === 5 &&
          newChpData[1].uiData.status &&
          newChpData[3].uiData.status
        ) {
          newChpData[6].uiData.xAxis = 325;
          newChpData[6].uiData.yAxis = 492;
        } else if (
          totalCount === 5 &&
          !newChpData[1].uiData.status &&
          !newChpData[3].uiData.status
        ) {
          newChpData[6].uiData.xAxis = 690;
          newChpData[6].uiData.yAxis = 226.5;
        } else if (
          totalCount === 5 &&
          (!newChpData[1].uiData.status || !newChpData[3].uiData.status)
        ) {
          newChpData[6].uiData.xAxis = 605;
          newChpData[6].uiData.yAxis = 490;
        }

        //* count 4
        else if (
          totalCount === 4 &&
          !newChpData[2].uiData.status &&
          !this.state.CBEVList[0].uiData.status
        ) {
          newChpData[6].uiData.xAxis = 465;
          newChpData[6].uiData.yAxis = 538;
        } else if (
          totalCount === 4 &&
          !newChpData[1].uiData.status &&
          !newChpData[3].uiData.status
        ) {
          newChpData[6].uiData.xAxis = 700;
          newChpData[6].uiData.yAxis = 300;
        }

        //* count 3
        else if (totalCount === 3) {
          newChpData[6].uiData.xAxis = 672;
          newChpData[6].uiData.yAxis = 418.5;
        }

        newChpData[6].uiData.x1pos = x1pos;
        newChpData[6].uiData.y1pos = y1pos;
        newChpData[6].uiData.x2pos = x2pos;
        newChpData[6].uiData.y2pos = y2pos;

        this.setState({ CBAssetList: newChpData });
      }

      //* BATTERY

      if (this.state.CBAssetList[2].uiData.status) {
        count = count + 1;
        const [x1pos, y1pos, x2pos, y2pos] = this.getLinePosition(count);
        let newBatteryData = _.cloneDeep(this.state.CBAssetList);

        if (totalCount === 7) {
          newBatteryData[2].uiData.xAxis = 372;
          newBatteryData[2].uiData.yAxis = 519;
        }
        //* count 6
        else if (totalCount === 6 && !this.state.CBEVList[0].uiData.status) {
          newBatteryData[2].uiData.xAxis = 255;
          newBatteryData[2].uiData.yAxis = 410;
        } else if (totalCount === 6 && this.state.CBEVList[0].uiData.status) {
          newBatteryData[2].uiData.xAxis = 465;
          newBatteryData[2].uiData.yAxis = 539;
        }
        //* count 5
        else if (totalCount === 5 && !this.state.CBEVList[0].uiData.status) {
          newBatteryData[2].uiData.xAxis = 322;
          newBatteryData[2].uiData.yAxis = 492;
        } else if (totalCount === 5 && this.state.CBEVList[0].uiData.status) {
          newBatteryData[2].uiData.xAxis = 605;
          newBatteryData[2].uiData.yAxis = 490;
        }
        //* count 4
        else if (totalCount === 4 && !this.state.CBEVList[0].uiData.status) {
          newBatteryData[2].uiData.xAxis = 465;
          newBatteryData[2].uiData.yAxis = 538;
        } else if (totalCount === 4 && this.state.CBEVList[0].uiData.status) {
          newBatteryData[2].uiData.xAxis = 700;
          newBatteryData[2].uiData.yAxis = 300;
        }

        //* count 3
        else if (totalCount === 3) {
          newBatteryData[2].uiData.xAxis = 672;
          newBatteryData[2].uiData.yAxis = 418.5;
        }

        newBatteryData[2].uiData.x1pos = x1pos;
        newBatteryData[2].uiData.y1pos = y1pos;
        newBatteryData[2].uiData.x2pos = x2pos;
        newBatteryData[2].uiData.y2pos = y2pos;

        this.setState({ CBAssetList: newBatteryData });
      }

      //* EV

      let evData = Object.assign([], this.state.CBEVList);

      if (evData[0].uiData.status) {
        count++;

        const [x1pos, y1pos, x2pos, y2pos] = this.getLinePosition(count);
        console.log(this.getLinePosition(count));

        if (totalCount === 7) {
          evData[0].uiData.xAxis = 290;
          evData[0].uiData.yAxis = 230;
        } else if (totalCount === 6) {
          evData[0].uiData.xAxis = 315;
          evData[0].uiData.yAxis = 290;
        } else if (totalCount === 5) {
          evData[0].uiData.xAxis = 380;
          evData[0].uiData.yAxis = 373;
        } else if (totalCount === 4) {
          evData[0].uiData.xAxis = 520;
          evData[0].uiData.yAxis = 420;
        } else if (totalCount === 3) {
          evData[0].uiData.xAxis = 728;
          evData[0].uiData.yAxis = 300;
        }

        evData[0].uiData.x1pos = x1pos;
        evData[0].uiData.y1pos = y1pos;
        evData[0].uiData.x2pos = x2pos;
        evData[0].uiData.y2pos = y2pos;
      }

      this.setState({ CBEVList: evData });

      let x1Load, y1Load, x2Load, y2Load;
      let newLoadData = [];
      let loadList = Object.assign([], this.state.CBLoadList);
      let cbLoadData = loadList[0];
      if (cbLoadData.uiData.status === true) {
        count++;
        let coOrdinates = this.getLinePosition(count);
        x1Load = coOrdinates[0];
        y1Load = coOrdinates[1];
        x2Load = coOrdinates[2];
        y2Load = coOrdinates[3];

        if (totalCount === 7) {
          cbLoadData.uiData.xAxis = 330;
          cbLoadData.uiData.yAxis = 50;
        } else if (totalCount === 6) {
          cbLoadData.uiData.xAxis = 313;
          cbLoadData.uiData.yAxis = 80;
        } else if (totalCount === 5) {
          cbLoadData.uiData.xAxis = 300;
          cbLoadData.uiData.yAxis = 110;
        } else if (totalCount === 4) {
          cbLoadData.uiData.xAxis = 286;
          cbLoadData.uiData.yAxis = 180;
        } else if (totalCount === 3) {
          cbLoadData.uiData.xAxis = 320;
          cbLoadData.uiData.yAxis = 300;
        } else if (totalCount === 2) {
          cbLoadData.uiData.xAxis = 523;
          cbLoadData.uiData.yAxis = 420;
        }

        cbLoadData.uiData.x1pos = x1Load;
        cbLoadData.uiData.y1pos = y1Load;
        cbLoadData.uiData.x2pos = x2Load;
        cbLoadData.uiData.y2pos = y2Load;
      }
      newLoadData.push(cbLoadData);

      this.setState({ CBLoadList: newLoadData });

      this.setState({ CBLoadList: newLoadData }, () => {
        setTimeout(() => {
          this.captureSLD(this.props);
        }, 200);
      });
    }
    if (this.state.siteType === "ISLANDABLE") {
      let xDer = this.state.xDer;
      let xDerCB = this.state.xDerCB;
      if (this.state.CBAssetList[0].uiData.status) {
        let newUtilityData = this.state.CBAssetList;
        newUtilityData[0].uiData.xAxis = this.state.xDer;
        newUtilityData[0].uiData.xAxisCB = this.state.xDerCB;
        this.setState({ CBAssetList: newUtilityData });
      }

      if (this.state.CBAssetList[1].uiData.status) {
        let newGensetData = this.state.CBAssetList;
        xDer = this.state.derDiff + xDer;
        xDerCB = this.state.derDiff + xDerCB;
        newGensetData[1].uiData.xAxis = xDer;
        newGensetData[1].uiData.xAxisCB = xDerCB;
        this.setState({ CBAssetList: newGensetData });
      }

      if (this.state.CBAssetList[2].uiData.status) {
        let newBatteryData = this.state.CBAssetList;

        xDer = this.state.derDiff + xDer;
        xDerCB = this.state.derDiff + xDerCB;

        newBatteryData[2].uiData.xAxis = xDer;
        newBatteryData[2].uiData.xAxisCB = xDerCB;

        this.setState({ CBAssetList: newBatteryData });
      }
      if (this.state.CBAssetList[3].uiData.status) {
        let newPvData = this.state.CBAssetList;

        xDer = this.state.derDiff + xDer;
        xDerCB = this.state.derDiff + xDerCB;

        newPvData[3].uiData.xAxis = xDer;
        newPvData[3].uiData.xAxisCB = xDerCB;

        this.setState({ CBAssetList: newPvData });
      }
      if (this.state.CBAssetList[4].uiData.status) {
        let newPvData = this.state.CBAssetList;

        xDer = this.state.derDiff + xDer;
        xDerCB = this.state.derDiff + xDerCB;

        newPvData[4].uiData.xAxis = xDer;
        newPvData[4].uiData.xAxisCB = xDerCB;

        this.setState({ CBAssetList: newPvData });
      }
      if (this.state.CBAssetList[5].uiData.status) {
        let newPvData = this.state.CBAssetList;

        xDer = this.state.derDiff + xDer;
        xDerCB = this.state.derDiff + xDerCB;

        newPvData[5].uiData.xAxis = xDer;
        newPvData[5].uiData.xAxisCB = xDerCB;

        this.setState({ CBAssetList: newPvData });
      }
      if (this.state.CBAssetList[6].uiData.status) {
        let newChpData = this.state.CBAssetList;

        xDer = this.state.derDiff + xDer;
        xDerCB = this.state.derDiff + xDerCB;
        newChpData[6].uiData.yAxis = 70;
        newChpData[6].uiData.xAxis = xDer;
        newChpData[6].uiData.xAxisCB = xDerCB;
        this.setState({ CBAssetList: newChpData });
      }

      let xLoad = this.state.xLoad;
      let xLoadCB = this.state.xLoadCB;
      let yLoad = this.state.yLoad;
      let xLoadNew = this.state.xLoadNew;
      let xLoadCBNew = this.state.xLoadCBNew;
      let newLoadData = [];
      const totalLoads = this.state.loadCount + this.state.evCount;

      let loadList = Object.assign([], this.state.CBLoadList);
      loadList.map((cbLoadData, key) => {
        if (key) {
          xLoad = this.state.loadDiff + xLoad;
          xLoadCB = this.state.loadDiff + xLoadCB;
          if (key > 11) yLoad = 377;

          if ((key === 11 && xLoad !== 1110) || xLoad === 1220) {
            xLoad = xLoadNew;
            xLoadCB = xLoadCBNew;
            yLoad = 377;
          }
        }

        cbLoadData.uiData.xAxis = xLoad;
        cbLoadData.uiData.yAxis = yLoad;
        cbLoadData.uiData.xAxisCB = xLoadCB;
        newLoadData.push(cbLoadData);
        return null;
      });
      this.setState({ CBLoadList: newLoadData });

      let evData = Object.assign([], this.state.CBEVList);
      if (evData[0].uiData.status) {
        xLoad = this.state.loadDiff + xLoad;
        xLoadCB = this.state.loadDiff + xLoadCB;
        evData[0].uiData.xAxis = xLoad;
        evData[0].uiData.xAxisCB = xLoadCB;
      }
      this.setState({ CBEVList: evData });

      let ecc = Object.assign({}, this.state.ecc);
      let busbar = Object.assign({}, this.state.busbar);

      if (xDer > xLoad) {
        if (totalLoads >= 11) {
          ecc.width = 1250;
          busbar.x2 = 1255;
        } else {
          ecc.width = xDer + this.state.eccwidth;
          busbar.x2 = xDer + this.state.busbarx2;
        }
      } else {
        if (xLoad - xDer === 10) {
          ecc.width = xLoad + 110;
          busbar.x2 = xLoadCB + 130;
        } else {
          ecc.width = xLoad + 140;
          busbar.x2 = xLoadCB + 100;
          if (totalLoads >= 11) {
            ecc.width = 1250;
            busbar.x2 = 1255;
          }
        }
      }

      this.setState({ ecc: ecc });
      this.setState({ busbar: busbar }, () => {
        setTimeout(() => {
          this.captureSLD(this.props);
        }, 200);
      });
    }

    if (this.state.siteType === "PV_HYBRIDIZATION") {
      let xDer = this.state.xDer;
      let xDerCB = this.state.xDerCB;
      let CBAssestListCor = Object.assign([], this.state.CBAssetList);
      if (this.state.CBAssetList[0].uiData.status) {
        CBAssestListCor[0].uiData.xAxis = this.state.xDer;
        CBAssestListCor[0].uiData.xAxisCB = this.state.xDerCB;
      }

      if (this.state.CBAssetList[1].uiData.status) {
        xDer = this.state.derDiff + xDer;
        xDerCB = this.state.derDiff + xDerCB;
        CBAssestListCor[1].uiData.xAxis = xDer;
        CBAssestListCor[1].uiData.yAxis = 70;
        CBAssestListCor[1].uiData.xAxisCB = xDerCB;
      }
      if (this.state.CBAssetList[3].uiData.status) {
        xDer = this.state.derDiff + xDer;
        xDerCB = this.state.derDiff + xDerCB;
        CBAssestListCor[3].uiData.yAxis = 70;
        CBAssestListCor[3].uiData.xAxis = xDer;
        CBAssestListCor[3].uiData.xAxisCB = xDerCB;
      }
      if (this.state.CBAssetList[6].uiData.status) {
        xDer = this.state.derDiff + xDer;
        xDerCB = this.state.derDiff + xDerCB;
        CBAssestListCor[6].uiData.yAxis = 141;
        CBAssestListCor[6].uiData.xAxis = 740;
        CBAssestListCor[6].uiData.xAxisCB = 840;
      }
      this.setState({ CBAssetList: CBAssestListCor }, () => {
        setTimeout(() => {
          this.captureSLD(this.props);
        }, 200);
      });
    }
  };
  captureSLD = props => {
    var node = document.getElementById("SLD-Image-report");

    domtoimage.toPng(node, { bgcolor: "#FFFFFF" }).then(function (dataUrl) {
      fetch(dataUrl)
        .then(res => res.blob())
        .then(response => {
          let formData = new FormData();
          formData.append("file", response, "SLD.png");
          formData.append("projectId", sessionStorage.getItem("projectId"));
          // Post via axios or other transport method
          apiSession
            .uploadSLDImage(formData)
            .then(json => {
              props.sldStatusCheck(true);
            })
            .catch(error => {
              props.sldStatusCheck(true);
            });
        });
    });
  };

  render() {
    return (
      <div style={{ marginTop: "300px" }}>
        {" "}
        {this.state.siteType === "ISLANDABLE" ? (
          <div
            id="SLD-Image-report"
            className="col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0"
          >
            <svg viewBox="0 0 1300 600">
              <g>
                <svg x="0" y="0" viewBox="0 0 1300 600">
                  <g id="components-sld">
                    <SLDEcc ecc={this.state.ecc} />
                    <SLDBusbar busbar={this.state.busbar} />
                    <SLDUtility
                      utility={this.state.CBAssetList[0].uiData}
                      utilityName={this.state.CBAssetList[0].configData.name}
                      utilityCbName={
                        this.state.CBAssetList[0].cbConfigData.name
                      }
                    />
                    <SLDGenset
                      genset={this.state.CBAssetList[1].uiData}
                      gensetName={this.state.CBAssetList[1].configData.name}
                      gensetCbName={this.state.CBAssetList[1].cbConfigData.name}
                    />
                    <SLDBattery
                      battery={this.state.CBAssetList[2].uiData}
                      batteryName={this.state.CBAssetList[2].configData.name}
                      batteryCbName={
                        this.state.CBAssetList[2].cbConfigData.name
                      }
                    />
                    <SLDPv1
                      pv1={this.state.CBAssetList[3].uiData}
                      pvGroupName={this.state.CBAssetList[3].uiData.groupName}
                      pvCbName={this.state.CBAssetList[3].cbConfigData.name}
                    />
                    <SLDPv2
                      pv2={this.state.CBAssetList[4].uiData}
                      pvGroupName={this.state.CBAssetList[4].uiData.groupName}
                      pvCbName={this.state.CBAssetList[4].cbConfigData.name}
                    />
                    <SLDPv3
                      pv3={this.state.CBAssetList[5].uiData}
                      pvGroupName={this.state.CBAssetList[5].uiData.groupName}
                      pvCbName={this.state.CBAssetList[5].cbConfigData.name}
                    />
                    <SLDCHP
                      chp={this.state.CBAssetList[6].uiData}
                      chpName={this.state.CBAssetList[6].configData.name}
                      chpCbName={this.state.CBAssetList[6].cbConfigData.name}
                    />
                    {this.state.CBLoadList.map((loadData, key) => (
                      <SLDLoad1
                        load1={loadData.uiData}
                        loadName={loadData.configData.name}
                        loadCbName={loadData.cbConfigData.name}
                      />
                    ))}
                    {this.state.CBEVList.map((evData, key) => (
                      <SLDEv
                        ev={evData.uiData}
                        evName={evData.configData.name}
                        evCbName={evData.cbConfigData.name}
                      />
                    ))}
                    ;
                  </g>
                </svg>
              </g>
            </svg>
          </div>
        ) : this.state.siteType === "GRID_CONNECTED" ? (
          <div
            id="SLD-Image-report"
            className="col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0"
          >
            <svg viewBox="0 0 1300 700">
              <g>
                <svg x="0" y="0" viewBox="0 0 1300 700">
                  <g id="components-sld">
                    <SLDEccOnGrid />
                    <SLDUtilityOnGrid
                      utility={this.state.CBAssetList[0].uiData}
                      utilityName={this.state.CBAssetList[0].configData.name}
                    />
                    <SLDGensetOnGrid
                      genset={this.state.CBAssetList[1].uiData}
                      gensetName={this.state.CBAssetList[1].configData.name}
                    />
                    <SLDBatteryOnGrid
                      battery={this.state.CBAssetList[2].uiData}
                      batteryName={this.state.CBAssetList[2].configData.name}
                    />
                    <CHPOnGrid
                      chp={this.state.CBAssetList[6].uiData}
                      chpName={this.state.CBAssetList[6].configData.name}
                    />
                    <SLDPv1OnGrid
                      pv1={this.state.CBAssetList[3].uiData}
                      pvGroupName={this.state.CBAssetList[3].uiData.groupName}
                    />
                    <SLDLoad1OnGrid
                      load1={this.state.CBLoadList[0].uiData}
                      loadName={this.state.CBAssetList[0].configData.name}
                    />
                    <SLDEvOnGrid
                      ev={this.state.CBEVList[0].uiData}
                      evName={this.state.CBEVList[0].configData.name}
                      evCbName={this.state.CBEVList[0].cbConfigData.name}
                    />
                    ;
                  </g>
                </svg>
              </g>
            </svg>
          </div>
        ) : this.state.siteType === "PV_HYBRIDIZATION" ? (
          <div
            id="SLD-Image-report"
            className="col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0"
          >
            <svg viewBox="0 0 1300 600">
              <g>
                <svg x="0" y="0" viewBox="0 0 1300 600">
                  <g id="components-sld">
                    <SLDEccPVH totalDer={this.state.derCount} />
                    <SLDBusbarPVH totalDer={this.state.derCount} />
                    <SLDUtilityPVH
                      utility={this.state.CBAssetList[0].uiData}
                      utilityName={this.state.CBAssetList[0].configData.name}
                      utilityCbName={
                        this.state.CBAssetList[0].cbConfigData.name
                      }
                    />
                    <SLDGensetPVH
                      genset={this.state.CBAssetList[1].uiData}
                      gensetName={this.state.CBAssetList[1].configData.name}
                      gensetCbName={this.state.CBAssetList[1].cbConfigData.name}
                    />

                    <SLDPvPVH
                      pv1={this.state.CBAssetList[3].uiData}
                      pvGroupName={this.state.CBAssetList[3].uiData.groupName}
                      pvCbName={this.state.CBAssetList[3].cbConfigData.name}
                    />
                    <SLDCHPPVH
                      chp={this.state.CBAssetList[6].uiData}
                      chpName={this.state.CBAssetList[6].configData.name}
                      chpCbName={this.state.CBAssetList[6].powerMeterData.name}
                    />

                    <SLDLoadPVH
                      load1={this.state.CBLoadList[0].uiData}
                      loadName={this.state.CBAssetList[0].configData.name}
                    />
                  </g>
                </svg>
              </g>
            </svg>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default DesignAssetSLD;
