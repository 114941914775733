import React, { useContext } from "react";
import embLabels from "../../config/emb-labels-en.json";
import batteryImage from "../../images/BESS-image-SE.png";

import { AssetBlockContext } from "../CustomHooks/ContextApi";
import isTouchDevice from "../SE-Component/Tooltip";

const BatteryAsset = () => {
  const {
    assetDetailsDisplay,
    setAssetSelection,
    configData,
    handleBatteryChange,
    defaultsMinMax,
    updateBatteryConfig,
    checkBatteryRemoveCondition,
  } = useContext(AssetBlockContext);

  const setAssetHandler = () => setAssetSelection(embLabels.BESS);

  const batteryChangeHandler = e => {
    handleBatteryChange(e);
  };

  return (
    <div name="battery" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 se-white-background">
        <div className="row">
          <div className="col-md-8 pb-2 pl-0 ">
            <span>
              <se-icon
                color="primary"
                className="se-icon icon_button se-life-green"
                size="medium"
              >
                battery2_half
              </se-icon>
              <span className="ml-2 bold align-top line-height-30px">
                Battery
              </span>
            </span>
          </div>
          <div className="col-md-4">
            <span className="float-right">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={checkBatteryRemoveCondition}
              >
                Remove
              </button>
            </span>
          </div>
        </div>
      </div>

      <div className="col-md-12 col-sm-12 pt-2 pb-2 se-grey-background se-border">
        <div
          className="row"
          style={assetDetailsDisplay.manufacturer ? {} : { display: "none" }}
        >
          <div className="col-md-3">
            {assetDetailsDisplay.imageLink === null ? (
              assetDetailsDisplay.manufacturer === "SCHNEIDER ELECTRIC" ? (
                <img
                  src={batteryImage}
                  className="asset-image-display"
                  alt={assetDetailsDisplay.manufacturer}
                />
              ) : (
                <se-icon color="primary" size="large">
                  battery2_half
                </se-icon>
              )
            ) : (
              <img
                src={assetDetailsDisplay.imageLink}
                className="asset-image-display"
                alt={assetDetailsDisplay.manufacturer}
              />
            )}
          </div>

          <div className="col-md-9">
            <div className="row">
              <div className="col-md-10 bold ml-2">
                {assetDetailsDisplay.manufacturer}
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 ml-2 text-break">
                {assetDetailsDisplay.communicationModel}
              </div>
              <div className="col-md-2">
                <se-tooltip
                  position="left"
                  action={isTouchDevice() ? "click" : "hover"}
                >
                  <se-icon option="raised" color="secondary" slot="tooltip">
                    information_circle
                  </se-icon>

                  <se-tooltip-content>Battery</se-tooltip-content>
                </se-tooltip>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={assetDetailsDisplay.manufacturer ? { display: "none" } : {}}
        >
          <div className="ml-2 mb-2 asset-selection"> No assets selected</div>
        </div>
        <div className="row">
          <div className=" col-md col-sm col-lg text-center">
            <se-button option="outline" size="small" onClick={setAssetHandler}>
              Asset Selection
            </se-button>
          </div>
        </div>
      </div>

      <div style={assetDetailsDisplay.manufacturer ? {} : { display: "none" }}>
        <div className="col-md-12 pt-1 pb-1 se-white-background align-middle se-border">
          <div className="row">
            <label className="col-md-6 bold mt-2 mb-2 pl-1 se-font-14px">
              Asset Settings
            </label>
            <div className="col-md-6 mt-1 text-right">
              <button
                type="button"
                className="btn btn-secondary btn-sm se-btn-primary"
                onClick={updateBatteryConfig}
              >
                <se-icon size="nano">action_save</se-icon>
                <span>&nbsp;</span>Save
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12 pt-2 pb-2 se-grey-background">
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Name <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              name="name"
              maxLength="50"
              value={configData.configData.name}
              onChange={batteryChangeHandler}
              placeholder="Name"
            />
            <div
              className="invalid-feedback-custom"
              style={!configData.configData.name ? {} : { display: "none" }}
            >
              {embLabels.nameRequired}
            </div>
          </div>
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">Description</label>
            <input
              type="text"
              autoComplete="none"
              className="form-control form-control-sm"
              id="exampleFormControlInput1"
              placeholder="Description"
              name="description"
              onChange={batteryChangeHandler}
              maxLength="100"
              value={configData.configData.description}
            />
          </div>
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Max Power(kW) <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              name="max_power"
              maxLength="4"
              value={configData.configData.max_power}
              placeholder="Max Power(kW)"
              onChange={batteryChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={
                !configData.configData.max_power ? {} : { display: "none" }
              }
            >
              Max Power(kW) is required.
            </div>
            {configData.configData.max_power !== "" &&
            (parseInt(configData.configData.max_power) <
              defaultsMinMax.maxPowerBessMin ||
              parseInt(configData.configData.max_power) >
                defaultsMinMax.maxPowerBessMax) ? (
              <div className="invalid-feedback-custom">
                Max Power should be in range(
                {defaultsMinMax.maxPowerBessMin}-
                {defaultsMinMax.maxPowerBessMax} kW).
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Capacity(kWh) <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              placeholder="Capacity(kWh)"
              maxLength={4}
              name="capacity"
              value={configData.configData.capacity}
              onChange={batteryChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={
                !(parseInt(configData.configData.capacity) >= 0)
                  ? {}
                  : { display: "none" }
              }
            >
              Capacity is required (kWh) .
            </div>
            {configData.configData.capacity !== "" &&
            (parseInt(configData.configData.capacity) <
              defaultsMinMax.capacityBessMin ||
              parseInt(configData.configData.capacity) >
                defaultsMinMax.capacityBessMax) ? (
              <div className="invalid-feedback-custom">
                Capacity should be in range(
                {defaultsMinMax.capacityBessMin}-
                {defaultsMinMax.capacityBessMax} kWh).
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              SOC Max (% of Max power) <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              placeholder="SOC Max (% of Max power)"
              name="soc_max"
              maxLength={4}
              value={configData.configData.soc_max}
              onChange={batteryChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={
                !(parseInt(configData.configData.soc_max) >= 0)
                  ? {}
                  : { display: "none" }
              }
            >
              SOC Max (% of Max power) is required.
            </div>
            {configData.configData.soc_max !== "" &&
            (parseInt(configData.configData.soc_max) <
              defaultsMinMax.socMaxMin ||
              parseInt(configData.configData.soc_max) >
                defaultsMinMax.socMaxMax) ? (
              <div className="invalid-feedback-custom">
                SOC Max should be in range(
                {defaultsMinMax.socMaxMin}-{defaultsMinMax.socMaxMax} % of Max
                power).
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              SOC Min (% of Max power) <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              placeholder="SOC Min (% of Max power)"
              maxLength={4}
              name="soc_min"
              value={configData.configData.soc_min}
              onChange={batteryChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={
                !(parseInt(configData.configData.soc_min) >= 0)
                  ? {}
                  : { display: "none" }
              }
            >
              SOC Min (% of Max power) is required.
            </div>
            {configData.configData.soc_min !== "" &&
            (parseInt(configData.configData.soc_min) <
              defaultsMinMax.socMinMin ||
              parseInt(configData.configData.soc_min) >
                defaultsMinMax.socMinMax) ? (
              <div className="invalid-feedback-custom">
                SOC Min should be in range(
                {defaultsMinMax.socMinMin}-{defaultsMinMax.socMinMax} % of Max
                power).
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BatteryAsset;
