import React from "react";

const CircuitBreaker = props => {
  const {
    data,
    systemInfoDERData,
    componentId,
    enableTextBox,
    communicationState,
    handleChange,
    isValidIp,
    isValidSlaveID,
    cancelUpdate,
    saveCommunications,
  } = props;

  const enableTextHandler = () => enableTextBox(data.componentId);
  const cancelUpdateHandler = () => cancelUpdate(data.componentId);
  const saveCommunicationHandler = () =>
    saveCommunications(data.componentId, data.network);

  return (
    <tr>
      <td width="13%">{data.equipment}</td>
      <td width="10%">{data.network}</td>
      <td width="15%">
        <span
          className="pointer"
          title="Click to edit"
          onClick={enableTextHandler}
          style={componentId[data.componentId] ? { display: "none" } : {}}
        >
          {systemInfoDERData[data.componentId].ipAddress
            ? systemInfoDERData[data.componentId].ipAddress
            : "NA"}
        </span>
        <div
          className="form-group"
          style={componentId[data.componentId] ? {} : { display: "none" }}
        >
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput2"
            name="ipAddress"
            maxLength="15"
            placeholder="Ip address"
            value={communicationState.ipAddress}
            onChange={handleChange}
          />

          <div
            className="invalid-feedback-custom"
            style={
              communicationState.ipAddress === "" ? {} : { display: "none" }
            }
          >
            Ip address is required.
          </div>

          {communicationState.ipAddress !== "" ? (
            <div
              className="invalid-feedback-custom"
              style={
                isValidIp(communicationState.ipAddress)
                  ? { display: "none" }
                  : {}
              }
            >
              Invalid Ip address.
            </div>
          ) : (
            ""
          )}
        </div>
      </td>
      <td width="15%">
        <span
          className="pointer"
          title="Click to edit"
          onClick={enableTextHandler}
          style={componentId[data.componentId] ? { display: "none" } : {}}
        >
          {systemInfoDERData[data.componentId].mask
            ? systemInfoDERData[data.componentId].mask
            : "NA"}
        </span>
        <div
          className="form-group"
          style={componentId[data.componentId] ? {} : { display: "none" }}
        >
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput2"
            name="mask"
            maxLength="15"
            value={communicationState.mask}
            placeholder="mask"
            onChange={handleChange}
          />
          <div
            className="invalid-feedback-custom"
            style={communicationState.mask === "" ? {} : { display: "none" }}
          >
            Mask is required.
          </div>
          {communicationState.mask !== "" ? (
            <div
              className="invalid-feedback-custom"
              style={
                isValidIp(communicationState.mask) ? { display: "none" } : {}
              }
            >
              Invalid mask.
            </div>
          ) : (
            ""
          )}
        </div>
      </td>
      <td width="15%">
        <span
          className="pointer"
          title="Click to edit"
          onClick={enableTextHandler}
          style={componentId[data.componentId] ? { display: "none" } : {}}
        >
          {systemInfoDERData[data.componentId].gatewayAddress
            ? systemInfoDERData[data.componentId].gatewayAddress
            : "NA"}
        </span>
        <div
          className="form-group"
          style={componentId[data.componentId] ? {} : { display: "none" }}
        >
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput2"
            name="gatewayAddress"
            maxLength="15"
            placeholder="Gateway address"
            value={communicationState.gatewayAddress}
            onChange={handleChange}
          />
          <div
            className="invalid-feedback-custom"
            style={
              communicationState.gatewayAddress === ""
                ? {}
                : { display: "none" }
            }
          >
            Gateway address is required.
          </div>
          {communicationState.gatewayAddress !== "" ? (
            <div
              className="invalid-feedback-custom"
              style={
                isValidIp(communicationState.gatewayAddress)
                  ? { display: "none" }
                  : {}
              }
            >
              Invalid Gateway address.
            </div>
          ) : (
            ""
          )}
        </div>
      </td>
      <td width="12%">
        <span
          className="pointer"
          title="Click to edit"
          onClick={enableTextHandler}
          style={componentId[data.componentId] ? { display: "none" } : {}}
        >
          {systemInfoDERData[data.componentId].slaveId
            ? systemInfoDERData[data.componentId].slaveId
            : "NA"}
        </span>

        <div
          className="form-group"
          style={componentId[data.componentId] ? {} : { display: "none" }}
        >
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm text-center "}
            id="exampleFormControlInput2"
            name="slaveId"
            maxLength="3"
            value={communicationState.slaveId}
            placeholder="Slave ID"
            onChange={handleChange}
          />
          <div
            className="invalid-feedback-custom"
            style={communicationState.slaveId === "" ? {} : { display: "none" }}
          >
            Slave ID is required.
          </div>
          {communicationState.slaveId !== "" ? (
            <div
              className="invalid-feedback-custom"
              style={
                isValidSlaveID(communicationState.slaveId)
                  ? { display: "none" }
                  : {}
              }
            >
              Invalid Slave ID.
            </div>
          ) : (
            ""
          )}
        </div>
      </td>
      <td width="10%">
        <span
          className="pointer"
          title="Click to edit"
          onClick={enableTextHandler}
          style={componentId[data.componentId] ? { display: "none" } : {}}
        >
          {systemInfoDERData[data.componentId].comments
            ? systemInfoDERData[data.componentId].comments
            : "NA"}
        </span>

        <div
          className="form-group"
          style={
            componentId[data.componentId] === true ? {} : { display: "none" }
          }
        >
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput2"
            name="comments"
            maxLength="50"
            value={communicationState.comments}
            placeholder="Comments"
            onChange={handleChange}
          />
        </div>
      </td>

      <td width="10%" className="text-center">
        <div className="pointer">
          {componentId[data.componentId] ? (
            <se-icon
              size="medium"
              color="primary"
              title="Click to update"
              disabled={
                communicationState.ipAddress === "" ||
                communicationState.mask === "" ||
                communicationState.gatewayAddress === "" ||
                communicationState.slaveId === ""
                  ? true
                  : false
              }
              onClick={
                communicationState.ipAddress === "" ||
                communicationState.mask === "" ||
                communicationState.gatewayAddress === "" ||
                communicationState.slaveId === ""
                  ? ""
                  : saveCommunicationHandler
              }
            >
              notification_ok_stroke
            </se-icon>
          ) : (
            <div title="Click to edit">
              <se-icon size="medium" disabled="true">
                notification_ok_stroke
              </se-icon>
            </div>
          )}

          {componentId[data.componentId] ? (
            <se-icon
              size="medium"
              title="Click to cancel"
              onClick={cancelUpdateHandler}
            >
              action_delete_cross
            </se-icon>
          ) : (
            ""
          )}
        </div>
      </td>
    </tr>
  );
};

export default CircuitBreaker;
