import React from "react";

import embLabels from "../../config/emb-labels-en.json";
import isTouchDevice from "../SE-Component/Tooltip";

const LicenceKeys = ({
  UserScope,
  gridSetting,
  unlockDeploy,
  siteType,
  architectureType,
  keyExpert,
  keyOperations,
  handleLicenseChange,
  validateExpertKey,
  validateOpertaionsKey,
}) => {
  return (
    <div
      className={`row mt-2 justify-content-center se-white-background deploy-border ${
        unlockDeploy ||
        UserScope.access.unlockBypass === true ||
        !UserScope.access.license
          ? "se-block-disabled"
          : ""
      } ${
        siteType === embLabels.GRID_CONNECTED ||
        siteType === embLabels.PV_HYBRIDIZATION ||
        (siteType === embLabels.ISLANDABLE_GSX &&
          architectureType === embLabels.PV_GENSET_ATS) ||
        (!gridSetting.remoteMonitoringControl &&
          siteType === embLabels.ISLANDABLE)
          ? "col-md-6 mx-auto"
          : ""
      }`}
    >
      <div className="col-md-12 mt-2">
        <span className="se-font-14px-bold ">
          Enter licence keys for unlocking deployment of the solution
        </span>
      </div>

      {(siteType === embLabels.GRID_CONNECTED ||
        (siteType === embLabels.ISLANDABLE &&
          gridSetting.remoteMonitoringControl)) && (
        <div
          className={`${keyExpert.expertStatus ? "se-block-disabled" : ""} ${
            siteType === embLabels.GRID_CONNECTED ? "col-md-12" : "col-md-6"
          }`}
        >
          <div className="form-group">
            <div className="row">
              <label htmlFor="inputEmail3" className="col-sm-12 col-form-label">
                <span className="se-life-green se-font-14px-bold">
                  EcoStruxure Microgrid Advisor{" "}
                </span>
                <span className="se-mandatory">*</span>
                <se-tooltip
                  action={isTouchDevice() ? "click" : "hover"}
                  position="right"
                >
                  <se-icon
                    option="raised"
                    color="secondary"
                    size="small"
                    slot="tooltip"
                  >
                    information_circle
                  </se-icon>
                  <se-tooltip-content>
                    Enter license key to unlock EcoStruxure Microgrid Advisor
                    cloud services and EcoStruxure Microgrid Advisor Controller
                    (Harmony iPC){" "}
                  </se-tooltip-content>
                </se-tooltip>
              </label>
            </div>
            <div className="row">
              <div className="col-sm-9">
                <input
                  type="text"
                  autoComplete="none"
                  className="form-control form-control-md"
                  name="expertKey"
                  placeholder="XXXX-NNN-NNNNNNNN"
                  maxLength="16"
                  value={keyExpert.expertKey}
                  onChange={handleLicenseChange}
                />

                {keyExpert.expertKey === "" &&
                  keyExpert.KeyErrorStatusExpert && (
                    <div className="invalid-feedback-custom">
                      EcoStruxure Microgrid Advisor is required.
                    </div>
                  )}
              </div>
              <div className="col-sm-3">
                <se-button
                  option="flat"
                  disabled={keyExpert.expertKey === "" ? true : false}
                  onClick={validateExpertKey}
                >
                  Submit
                </se-button>
              </div>
            </div>
          </div>
        </div>
      )}

      {(siteType === embLabels.ISLANDABLE ||
        siteType === embLabels.PV_HYBRIDIZATION ||
        (siteType === embLabels.ISLANDABLE_GSX &&
          architectureType === embLabels.PV_GENSET_ATS)) && (
        <div
          className={`${
            keyOperations.operationsStatus ||
            (siteType === embLabels.ISLANDABLE_GSX &&
              architectureType === embLabels.PV_GENSET_ATS)
              ? "se-block-disabled"
              : ""
          } ${
            siteType === embLabels.PV_HYBRIDIZATION ||
            (siteType === embLabels.ISLANDABLE_GSX &&
              architectureType === embLabels.PV_GENSET_ATS) ||
            (siteType === embLabels.ISLANDABLE &&
              !gridSetting.remoteMonitoringControl)
              ? "col-md-12"
              : "col-md-6"
          }`}
        >
          {siteType === embLabels.ISLANDABLE_GSX &&
            architectureType === embLabels.PV_GENSET_ATS && (
              <div className="license__comingSoon mt-2 mx-auto">
                Contact Schneider Electric for implementation.
              </div>
            )}

          <div className="form-group">
            <div className="row">
              <label
                htmlFor="inputEmail3"
                className="col-sm-12 col-form-label "
              >
                <span className="se-life-green se-font-14px-bold">
                  EcoStruxure Microgrid Operation
                </span>
                <span className="se-mandatory">*</span>
                <se-tooltip
                  action={isTouchDevice() ? "click" : "hover"}
                  position="right"
                >
                  <se-icon
                    option="raised"
                    color="secondary"
                    size="small"
                    slot="tooltip"
                  >
                    information_circle
                  </se-icon>
                  <se-tooltip-content>
                    Enter Controller license key to unlock deployment for
                    EcoStruxure Microgrid Operation - Medium Controller (M251
                    PLC) and EcoStruxure Microgrid Operation - Medium HMI
                    (Magelis HMI)
                  </se-tooltip-content>
                </se-tooltip>
              </label>
            </div>
            <div className="row">
              <div className="col-sm-8">
                <input
                  type="text"
                  autoComplete="none"
                  className="form-control form-control-md"
                  name="operationKey"
                  placeholder="XXXX-NNN-NNNNNNNN"
                  maxLength="16"
                  value={keyOperations.operationKey}
                  onChange={handleLicenseChange}
                />
                {keyOperations.operationKey === "" &&
                  keyOperations.KeyErrorStatusOperations && (
                    <div className="invalid-feedback-custom">
                      EcoStruxure Microgrid Operation is required.
                    </div>
                  )}
              </div>
              <div className="col-sm-4">
                <se-button
                  option="flat"
                  disabled={keyOperations.operationKey === "" ? true : false}
                  onClick={validateOpertaionsKey}
                >
                  Submit
                </se-button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LicenceKeys;
