import React, { Component } from "react";
import APISESSION from "../api/ApiSession";
import RawBusinessTypeList from "../config/emb-config.json";
const apiSession = new APISESSION();
const paswordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{15,}$/;

const hostUrl = `${window.location.protocol}//${window.location.host}`;
const privacyPolicy =
  "https://www.se.com/ww/en/about-us/legal/data-privacy.jsp";
class UserRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onLoadingStatus: false,
      registrationLoading: false,
      tokenExpireErrorMessage: false,
      userId: "",
      email: "",
      firstName: "",
      lastName: "",
      employer: "",
      phoneNumber: "",
      location: "",
      password: "",
      businessType: "",
      registrationError: false,
      registrationSuccess: false,
      userAlreadyRegistered: false,
      agreeStatus: false,
      passwordCheck: false,
      showPassword: false,
      confirmPassword: "",
      type: "",
      message: "",
    };
  }
  componentDidMount() {
    this.setState({ onLoadingStatus: true });
    let querySearch = this.props.location.search;
    let query = querySearch.split("?userId=");
    let token = query[1];
    this.setState({ userId: token });
    this.getTokenDetials(token);
  }
  getTokenDetials(token) {
    apiSession
      .getRegistrationDetails(token)
      .then(response => {
        this.setState({ email: response });
        this.setState({ onLoadingStatus: false });
      })
      .catch(error => {
        this.setState({ tokenExpireErrorMessage: true });
        this.setState({ email: "" });
        this.setState({ onLoadingStatus: false });
      });
  }
  handlePasswordChange = e => {
    let passwordStr = e.target.value.replace(/\s/g, "");
    if (paswordRegex.test(e.target.value) === true) {
      this.setState({ passwordCheck: true });
    } else {
      this.setState({ passwordCheck: false });
    }
    this.setState({
      [e.target.name]: e.target.value.replace(/\s/g, ""),
    });
    passwordStr = passwordStr.toLowerCase();
    let firstNameStr = this.state.firstName.trim().toLowerCase();
    let lastNameStr = this.state.lastName.trim().toLowerCase();
    if (firstNameStr.includes(passwordStr)) {
      this.setState({ passwordCheck: false });
    }

    if (lastNameStr.includes(passwordStr)) {
      this.setState({ passwordCheck: false });
    }
  };
  handleChangeConfirmPassword = e => {
    this.setState({
      [e.target.name]: e.target.value.replace(/ +(?= )/g, ""),
    });
  };
  handleChange = e => {
    let phoneNumberRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/;
    switch (e.target.name) {
      case "phoneNumber":
        if (e.target.value.length >= 2) {
          if (phoneNumberRegex.test(e.target.value)) {
            this.setState({
              [e.target.name]: e.target.value,
            });
          }
        } else {
          this.setState({
            [e.target.name]: e.target.value,
          });
        }

        break;
      default:
        if (this.isValidName(e.target.value)) {
          this.setState({
            [e.target.name]: e.target.value.replace(/ +(?= )/g, ""),
          });
        }
    }
  };
  isValidName = value => (/^[a-zA-Z0-9_-\s]*$/.test(value) ? true : false);
  isNumber = value => (/^[0-9]+$/.test(value) ? true : false);
  reset() {
    this.setState({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      employer: "",
      businessType: "",
      location: "",
      password: "",
    });
  }
  handleCheckBoxChange = e => {
    this.setState({ agreeStatus: e.target.checked });
  };
  saveRegistration() {
    if (
      this.state.firstName !== "" &&
      this.state.passwordCheck !== false &&
      this.state.businessType !== "" &&
      this.state.employer !== "" &&
      this.state.agreeStatus !== false &&
      this.state.password === this.state.confirmPassword
    ) {
      this.setState({ onLoadingStatus: true });
      let data = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        phoneNumber: this.state.phoneNumber,
        employer: this.state.employer,
        businessType: this.state.businessType,
        location: this.state.location,
        password: this.state.password,
      };
      let token = this.state.userId;
      apiSession
        .saveRegistration(data, token)
        .then(response => {
          this.setState({ registrationSuccess: true });
          this.setState({ email: "" });
          this.reset();
          this.setState({ registrationError: false });
          this.setState({ userAlreadyRegistered: false });
          this.setState({ onLoadingStatus: false });
        })
        .catch(error => {
          if (error.response.status === 302) {
            this.setState({ userAlreadyRegistered: true });
          } else {
            this.setState({ registrationError: true });
          }
          this.setState({ email: "" });
          this.reset();
          this.setState({ registrationSuccess: false });
          this.setState({ onLoadingStatus: false });
        });
    } else {
      this.callSnackbar("error", "please fill the mandatory fields");
    }
  }
  showPassword() {
    this.setState({ displayPassword: !this.state.displayPassword });
  }
  callSnackbar = (errorType, Body) => {
    this.setState({
      type: errorType,
      message: Body,
    });
    document.getElementById("snackbar-register").open = true;
  };

  disableEvent = e => {
    e.preventDefault();
  };
  isTouchDevice = () =>
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
      ? true
      : false;

  render() {
    return (
      <se-container
        option="centered"
        style={this.state.userId !== "" ? {} : { display: "none" }}
      >
        <se-loading loading={this.state.onLoadingStatus} />
        <div className="container mb-1">
          <div
            className="row justify-content-md-center"
            style={
              this.state.tokenExpireErrorMessage === true &&
              this.state.email === ""
                ? {}
                : { display: "none" }
            }
          >
            <div className="col-md-6 se-white-background pr-5 pl-5 mt-5 text-center error-msg">
              <se-block-content>
                <h4>
                  <se-icon size="small">notification_critical_stroke</se-icon>{" "}
                  Registration Link expired. Please contact administrator{" "}
                </h4>
              </se-block-content>
            </div>
          </div>

          <div
            className="row justify-content-md-center"
            style={
              this.state.registrationError === true ? {} : { display: "none" }
            }
          >
            <div className="col-md-6 se-white-background pr-5 pl-5 mt-5 text-center error-msg">
              <se-block-content>
                <h5>
                  <se-icon size="small">notification_critical_stroke</se-icon>{" "}
                  Error in Registration. Contact Administrator{" "}
                </h5>
              </se-block-content>
            </div>
          </div>

          <div
            className="row justify-content-md-center"
            style={
              this.state.userAlreadyRegistered === true
                ? {}
                : { display: "none" }
            }
          >
            <div className="col-md-6 se-white-background pr-5 pl-5 mt-5 text-center succedd-msg">
              <se-block-content>
                <h4>
                  <se-icon color="primary" size="small">
                    notification_critical_stroke
                  </se-icon>{" "}
                  Registration Successfull. Login with your Ecostruxure
                  credentials.{" "}
                </h4>
                <se-divider></se-divider>
                <a href={hostUrl} title="Click here to login">
                  <h5>Click here to login </h5>
                </a>
              </se-block-content>
            </div>
          </div>

          <div
            className="row justify-content-md-center"
            style={
              this.state.registrationSuccess === true ? {} : { display: "none" }
            }
          >
            <div className="col-md-6 se-white-background pr-5 pl-5 mt-5 text-center succedd-msg">
              <se-block-content>
                <h4>
                  <se-icon color="primary" size="small">
                    notification_critical_stroke
                  </se-icon>{" "}
                  Registration Successfull. Login details sent via Email.{" "}
                </h4>
                <se-divider></se-divider>
                <a href={hostUrl} title="Click here to login">
                  <h5>Click here to login </h5>
                </a>
              </se-block-content>
            </div>
          </div>
          <div
            className="row justify-content-md-center"
            style={
              this.state.tokenExpireErrorMessage !== true &&
              this.state.email !== ""
                ? {}
                : { display: "none" }
            }
          >
            {/* style={this.state.tokenExpireErrorMessage !== true && this.state.email !== "" ? {} : { display: 'none' }} */}
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 se-white-background pr-5 pl-5 pb-2 pt-2">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h2 className=" se-life-green bold">Register</h2>
                  </div>
                  <div className="col-md-12 text-center">
                    <h4 className="se-life-green bold">
                      Ecostruxure Microgrid Build
                    </h4>
                  </div>
                  <div className="col-md-12 text-center mt-2">
                    <span>
                      Create your account in order to connect to
                      Schneider-Electric Ecostruxure Microgrid Build
                      configuration tool, in order to collaborate in microgrid
                      projects.
                    </span>
                  </div>
                </div>
                {/* <span className="ml-2 se-font-14px-bold se-life-green">User Registration</span> */}
                {/* <div className="dropdown-divider"></div> */}
                <div className="row mt-1 justify-content-md-center">
                  <div className="col-md-8">
                    <div className="form-group row">
                      {/* <label htmlFor="EmailAddress" className="col-sm-3 col-form-label text-right">Email Address</label> */}
                      <div className="col-md-12 input-group">
                        <input
                          type="text"
                          autoComplete="none"
                          disabled
                          className="form-control"
                          id="EmailAddress"
                          value={this.state.email}
                        />
                        <se-tooltip
                          action={this.isTouchDevice() ? "click" : "hover"}
                          position="right"
                        >
                          <se-icon
                            option="raised"
                            color="secondary"
                            size="medium"
                            slot="tooltip"
                          >
                            information_circle
                          </se-icon>
                          <se-tooltip-content>
                            Email ID is your User ID
                          </se-tooltip-content>
                        </se-tooltip>
                      </div>
                    </div>

                    <div className="form-group row">
                      {/* <label htmlFor="firstName" className="col-sm-3 col-form-label text-right">First Name <span className="se-mandatory">*</span></label> */}
                      <div className="col-md-12 ">
                        <input
                          type="text"
                          autoComplete="none"
                          className={
                            "form-control " +
                            (this.state.firstName.trim() === ""
                              ? "is-mandatory"
                              : "")
                          }
                          id="firstName"
                          name="firstName"
                          value={this.state.firstName}
                          maxLength="50"
                          placeholder="First Name"
                          onChange={e => this.handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      {/* <label htmlFor="lastName" className="col-sm-3 col-form-label text-right">Last Name <span className="se-mandatory">*</span></label> */}
                      <div className="col-md-12">
                        <input
                          type="text"
                          autoComplete="none"
                          className={"form-control"}
                          id="lastName"
                          name="lastName"
                          value={this.state.lastName}
                          maxLength="50"
                          placeholder="Last Name"
                          onChange={e => this.handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-12 input-group">
                        <input
                          type={
                            this.state.displayPassword === true
                              ? "text"
                              : "password"
                          }
                          autoComplete="new-password"
                          className={
                            "form-control border-right-0 " +
                            (this.state.password.trim() === ""
                              ? "is-mandatory"
                              : this.state.passwordCheck === true
                              ? "is-success"
                              : "")
                          }
                          id="password"
                          name="password"
                          value={this.state.password}
                          maxLength="50"
                          placeholder="Password"
                          onChange={e => this.handlePasswordChange(e)}
                          onPaste={e => this.disableEvent(e)}
                          onCopy={e => this.disableEvent(e)}
                          onCut={e => this.disableEvent(e)}
                        />
                        <span
                          className="input-group-append border-password pointer"
                          onClick={this.showPassword.bind(this)}
                        >
                          <div
                            className={
                              "input-group-text bg-transparent " +
                              (this.state.password.trim() === ""
                                ? "is-mandatory-password"
                                : this.state.passwordCheck === true
                                ? "is-success-password"
                                : "")
                            }
                          >
                            {this.state.displayPassword === true ? (
                              <se-icon size="small">eye_dont_show</se-icon>
                            ) : (
                              <se-icon size="small">body_eye</se-icon>
                            )}
                          </div>
                        </span>

                        <div
                          className="invalid-feedback-custom "
                          style={
                            this.state.passwordCheck === false &&
                            this.state.password !== ""
                              ? {}
                              : { display: "none" }
                          }
                        >
                          Password must have 15 or more characters including at
                          least one number , one upper case and lower case
                          character and one special symbol (#?!@$%^&*-), Must
                          not include first name or last name
                        </div>
                      </div>
                    </div>

                    <div className="form-group row ">
                      <div className="col-md-12">
                        <input
                          type="password"
                          autoComplete="new-password"
                          className={
                            "form-control " +
                            (this.state.confirmPassword === ""
                              ? "is-mandatory"
                              : "")
                          }
                          id="confirmpassword"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          onChange={e => this.handleChangeConfirmPassword(e)}
                          value={this.state.confirmPassword}
                          maxLength="50"
                          onPaste={e => this.disableEvent(e)}
                          onCopy={e => this.disableEvent(e)}
                          onCut={e => this.disableEvent(e)}
                        />
                        <div
                          className="invalid-feedback-custom "
                          style={
                            this.state.confirmPassword.length >= 15 &&
                            this.state.password !== this.state.confirmPassword
                              ? {}
                              : { display: "none" }
                          }
                        >
                          Confirm password is not matching with Password
                        </div>
                      </div>
                    </div>

                    <div className="form-group row">
                      {/* <label htmlFor="employer" className="col-sm-3 col-form-label text-right">Employer <span className="se-mandatory">*</span></label> */}
                      <div className="col-md-12">
                        <input
                          type="text"
                          autoComplete="none"
                          className={
                            "form-control " +
                            (this.state.location.trim() === ""
                              ? "is-mandatory"
                              : "")
                          }
                          id="location"
                          name="location"
                          value={this.state.location}
                          maxLength="50"
                          placeholder="Location"
                          onChange={e => this.handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      {/* <label htmlFor="phoneNumber" className="col-sm-3 col-form-label text-right">Phone Number <span className="se-mandatory">*</span></label> */}
                      <div className="col-md-12">
                        <input
                          type="text"
                          autoComplete="none"
                          className={"form-control"}
                          id="phoneNumber"
                          name="phoneNumber"
                          value={this.state.phoneNumber}
                          maxLength="14"
                          placeholder="Phone Number"
                          onChange={e => this.handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      {/* <label htmlFor="employer" className="col-sm-3 col-form-label text-right">Employer <span className="se-mandatory">*</span></label> */}
                      <div className="col-md-12">
                        <input
                          type="text"
                          autoComplete="none"
                          className={
                            "form-control " +
                            (this.state.employer.trim() === ""
                              ? "is-mandatory"
                              : "")
                          }
                          id="employer"
                          name="employer"
                          value={this.state.employer}
                          maxLength="50"
                          placeholder="Company Name"
                          onChange={e => this.handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-md-12">
                        <select
                          className={
                            "form-control " +
                            (this.state.businessType === ""
                              ? "is-mandatory"
                              : "")
                          }
                          name="businessType"
                          value={this.state.businessType}
                          onChange={e => this.handleChange(e)}
                        >
                          <option value="">My Business Type</option>
                          {RawBusinessTypeList.businessTypeList.map(
                            (businessData, index) => (
                              <option value={businessData}>
                                {businessData}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row justify-content-md-center">
                  <div className="col-md-1">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        name="agreeStatus"
                        id="customControlValidationE1"
                        onChange={e => this.handleCheckBoxChange(e)}
                        checked={this.state.agreeStatus}
                      />
                      <label
                        className="custom-control-label line-height-25"
                        htmlFor="customControlValidationE1"
                      ></label>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <span className="se-font-12px">
                      We process account registration information and connection
                      logs for authentication and application access
                      management...
                      <a
                        href={privacyPolicy}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Privacy Policy"
                      >
                        Privacy Policy
                      </a>{" "}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row  justify-content-md-center">
                <div className="col-md-7 text-center pl-1 pr-1">
                  {/* <se-button option="outline" onClick={this.reset.bind(this)} >Reset</se-button> */}

                  <se-button
                    option="login"
                    color="primary"
                    disabled={
                      this.state.firstName !== "" &&
                      this.state.passwordCheck !== false &&
                      this.state.businessType !== "" &&
                      this.state.employer !== "" &&
                      this.state.agreeStatus !== false &&
                      this.state.password === this.state.confirmPassword
                        ? false
                        : true
                    }
                    onClick={this.saveRegistration.bind(this)}
                  >
                    Register
                  </se-button>
                </div>

                <div className="col-md-7 text-center pl-1 pr-1">
                  <a href={hostUrl} className="bold" title="Return To Login">
                    Return to login
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <se-snackbar
          id="snackbar-register"
          type={this.state.type}
          icon="information_stroke"
          message={this.state.message}
          duration="3000"
        ></se-snackbar>
      </se-container>
    );
  }
}

export default UserRegistration;
