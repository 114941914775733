import React from "react";

const Rosette = () => {
  return (
    <svg>
      <g id="ECC">
        <svg x="553" y="294">
          <g
            id="ECC"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="ECC" transform="translate(-532.000000, -514.000000)">
              <g
                id="SLD/Infos/Microgrid_Background"
                transform="translate(532.000000, 514.000000)"
              >
                <circle
                  id="Oval"
                  strokeWidth="3"
                  fill="#FFFFFF"
                  cx="48"
                  cy="56"
                  r="46.5"
                  stroke="#333333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></circle>
                <text
                  id="segment_energy_infra"
                  fontFamily="se-icon"
                  fontSize="56"
                  fontWeight="normal"
                  fill="#333333"
                >
                  <tspan x="20" y="76">
                    segment_electrical_energy
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </svg>
      </g>
    </svg>
  );
};

export default Rosette;
