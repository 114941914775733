import React, { useEffect, useReducer, useState } from "react";

import TableHeading from "./SystemPages/TableHeading";
import CircuitBreakerTable from "./SystemPages/CircuitBreaker";
import DERtable from "./SystemPages/DERTable";
import EVTable from "./SystemPages/EVTable";
import PowerMeterTable from "./SystemPages/PowerMeterTable";
import SystemDeviceTable from "./SystemPages/SystemDeviceTable";
import SystemSupervisionTable from "./SystemPages/SystemSupervisionTable";

import embLabels from "../config/emb-labels-en.json";

//api call
import ApiSession from "../api/ApiSession";
const apiSession = new ApiSession();

// ACTIONS

export const ACTIONS = {
  FETCH_SUCCESS: "FETCH_SUCCESS",
  ENABLE_TEXTBOX: "ENABLE_TEXTBOX",
  ENABLE_TEXTBOX_SYSTEM: "ENABLE_TEXTBOX_SYSTEM",
  RESET_COMMUNICATION: "RESET_COMMUNICATION",
};

// Reducer functions

const initialSystemState = {
  systemInfoDER: [],
  systemCommunication: [],
};

const systemReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_SUCCESS:
      return {
        systemInfoDER: action.payload.systemInfoDER,
        systemCommunication: action.payload.systemCommunication,
      };

    default:
      return state;
  }
};

const initialCommunicationState = {
  ipAddress: "",
  mask: "",
  gatewayAddress: "",
  slaveId: "",
  comments: "",
};
const isValidName = value => (/^[a-zA-Z0-9_-\s]*$/.test(value) ? true : false);

const communicationReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ENABLE_TEXTBOX:
      return {
        ...state,
        ipAddress: action.payload.ipAddress,
        mask: action.payload.mask,
        gatewayAddress: action.payload.gatewayAddress,
        slaveId: action.payload.slaveId,
        comments: action.payload.comments,
      };
    case ACTIONS.ENABLE_TEXTBOX_SYSTEM:
      return {
        ...state,
        ipAddress: action.payload.ipAddress,
        mask: action.payload.mask,
        gatewayAddress: action.payload.gatewayAddress,
        slaveId: action.payload.slaveId,
        comments: action.payload.comments,
      };
    case "ipAddress":
      return {
        ...state,
        ipAddress: action.payload.ipAddress,
      };

    case "mask":
      return {
        ...state,
        mask: action.payload.mask,
      };

    case "gatewayAddress":
      return {
        ...state,
        gatewayAddress: action.payload.gatewayAddress,
      };

    case "slaveId":
      return {
        ...state,
        slaveId: action.payload.slaveId,
      };
    case "comments":
      if (isValidName(action.payload.comments)) {
        return {
          ...state,
          comments: action.payload.comments,
        };
      }
      break;
    case ACTIONS.RESET_COMMUNICATION:
      return initialCommunicationState;
    default:
      return state;
  }
};

// System page component
const SystemPage = props => {
  // usestate
  // setting userScope
  let userSampleData = {
    name: "",
    role: "",
    access: "",
  };

  let userScope =
    JSON.parse(localStorage.getItem("scope")) !== null
      ? JSON.parse(localStorage.getItem("scope"))
      : userSampleData;

  const [UserScope] = useState(userScope);
  const [loading, setLoading] = useState(false);
  const [siteCreated, setSiteCreated] = useState(false);
  const [siteType, setSiteType] = useState("");
  const [clientType, setClientType] = useState("");
  const [architectureType, setArchitectureType] = useState("");
  const [modbusSlaveIdArray, setModbusSlaveIdArray] = useState([]);
  const [systemInfoDERData, setSystemInfoDerData] = useState({});
  const [systemCommunicationData, setSystemCommunicationData] = useState({});
  const [componentId, setComponentId] = useState({});
  const [snackBar, setSnackBar] = useState({ type: "", message: "" });

  // usereducer
  const [systemState, systemDispatch] = useReducer(
    systemReducer,
    initialSystemState
  );

  const [communicationState, communicationDispatch] = useReducer(
    communicationReducer,
    initialCommunicationState
  );

  // useEffect

  useEffect(() => {
    if (sessionStorage.getItem("projectId") === "null") {
      let { history } = props;
      history.push({
        pathname: "/information",
      });
    } else {
      setLoading(true);
      apiSession
        .getSystemInfo(sessionStorage.getItem("projectId"))
        .then(response => {
          let derComInfo = {};
          let systemComInfo = {};
          let modbusSlaveArray = [];
          setSiteType(response.siteType);
          setClientType(response.clientType);
          setArchitectureType(response.architecture);
          setSiteCreated(response.siteCreated);

          response.systemComponents.map(infoDER => {
            setComponentId(prevState => ({
              ...prevState,
              [infoDER.componentId]: false,
            }));
            derComInfo[infoDER.componentId] = infoDER;
            if (infoDER.network === "Circuit Breaker") {
              modbusSlaveArray.push(infoDER.slaveId);
            }
            return null;
          });

          response.systemSettings.map(sysSetting => {
            setComponentId(prevState => ({
              ...prevState,
              [sysSetting.id]: false,
            }));
            systemComInfo[sysSetting.id] = sysSetting;
            return null;
          });

          setModbusSlaveIdArray(modbusSlaveArray);
          setSystemInfoDerData(derComInfo);
          setSystemCommunicationData(systemComInfo);
          setLoading(false);

          // dispatch function

          systemDispatch({
            type: ACTIONS.FETCH_SUCCESS,
            payload: {
              systemInfoDER: response.systemComponents,
              systemCommunication: response.systemSettings,
            },
          });

          // for system communication validation
          localStorage.setItem("communicationValidated", response.systemsPage);
        })
        .catch(error => {
          setLoading(false);
        });
    }
  }, [props]);

  // functions for  info table start //

  const enableTextBox = value => {
    systemState.systemInfoDER.forEach(infoDER =>
      setComponentId(prevState => ({
        ...prevState,
        [infoDER.componentId]: false,
      }))
    );
    systemState.systemCommunication.forEach(systemComInfo =>
      setComponentId(prevState => ({ ...prevState, [systemComInfo.id]: false }))
    );
    setComponentId(prevState => ({ ...prevState, [value]: true }));

    // dispacth function

    communicationDispatch({
      type: ACTIONS.ENABLE_TEXTBOX,
      payload: {
        ipAddress: systemInfoDERData[value].ipAddress,
        mask: systemInfoDERData[value].mask,
        gatewayAddress: systemInfoDERData[value].gatewayAddress,
        slaveId: systemInfoDERData[value].slaveId,
        comments:
          systemInfoDERData[value].comments === null
            ? ""
            : systemInfoDERData[value].comments,
      },
    });
  };

  const handleChange = e => {
    communicationDispatch({
      type: e.target.name,
      payload: { [e.target.name]: e.target.value },
    });
  };

  const cancelUpdate = componentId => {
    setComponentId(prevState => ({ ...prevState, [componentId]: false }));
    resetCommunicationSettings();
  };

  const resetCommunicationSettings = () =>
    communicationDispatch({ type: ACTIONS.RESET_COMMUNICATION });

  const checkForModbusDuplicate = (ip, slaveID, componentId) => {
    if (
      systemInfoDERData[componentId].ipAddress !==
        communicationState.ipAddress ||
      systemInfoDERData[componentId].slaveId !== communicationState.slaveId
    ) {
      const EXISTINGIP = systemState.systemInfoDER
        .filter(DERData => {
          if (DERData.network === "Circuit Breaker") {
            return DERData.slaveId === slaveID;
          }
          return null;
        })
        .map(data => data.ipAddress);

      return EXISTINGIP.indexOf(ip) > -1 ? true : false;
    }
  };

  const checkForModbusDuplicateDer = (ip, slaveID, componentId) => {
    if (
      systemInfoDERData[componentId].ipAddress !==
        communicationState.ipAddress ||
      systemInfoDERData[componentId].slaveId !== communicationState.slaveId
    ) {
      const EXISTINGIP = systemState.systemInfoDER
        .filter(DERData => {
          if (DERData.network === "Inverter") {
            return DERData.slaveId === slaveID;
          }
          return null;
        })
        .map(data => data.ipAddress);

      return EXISTINGIP.indexOf(ip) > -1 ? true : false;
    }
  };

  const checkForIpInIfe = (ip, componentId) => {
    if (
      systemInfoDERData[componentId].ipAddress !== communicationState.ipAddress
    ) {
      let count = 0;
      const cbTypes = ["Circuit Breaker"];
      const systemInfoDERNew = [...systemState.systemInfoDER];
      const CBIP = systemInfoDERNew
        .filter(data => cbTypes.includes(data.network) && data.ipAddress)
        .map(data => data.ipAddress);

      CBIP.forEach(ipaddress => ipaddress === ip && count++);

      const returnValue = count > 6 ? true : false;
      return returnValue;
    }
  };

  const saveCommunicationsDer = (componentId, type) => {
    let filteredIpAddress = [];
    let systemInfoDERDataNew = Object.assign([], systemState.systemInfoDER);

    if (isValidSlaveID(communicationState.slaveId)) {
      if (
        communicationState.slaveId <= 255 &&
        parseInt(communicationState.slaveId) !== 0
      ) {
        if (
          systemInfoDERData[componentId].slaveId !== communicationState.slaveId
        ) {
          filteredIpAddress = systemInfoDERDataNew.filter(
            DERData =>
              DERData.slaveId === communicationState.slaveId &&
              DERData.componentType === "Serial"
          );
          if (filteredIpAddress.length) {
            callSnackbar("error", "Entered Duplicate Slave ID");
          } else {
            saveSystemInfoDer(componentId, type);
          }
        } else {
          saveSystemInfoDer(componentId, type);
        }
      } else {
        callSnackbar(
          "error",
          "Modbus Slave Id should be always between 1 - 255"
        );
      }
    } else {
      callSnackbar("error", "Invalid field entires");
    }
  };

  const saveCommunications = (componentId, type) => {
    if (
      isValidIp(communicationState.ipAddress) &&
      isValidIp(communicationState.mask) &&
      isValidIp(communicationState.gatewayAddress) &&
      isValidSlaveID(communicationState.slaveId)
    ) {
      let filteredIpAddress = [];
      if (
        systemInfoDERData[componentId].ipAddress !==
        communicationState.ipAddress
      ) {
        const systemInfoDerDataNew = [...systemState.systemInfoDER];
        filteredIpAddress = systemInfoDerDataNew.filter(DERData => {
          if (type === "Circuit Breaker") {
            if (DERData.network !== "Circuit Breaker") {
              return DERData.ipAddress === communicationState.ipAddress;
            }
            return null;
          }
          return null;
          // else {
          //   return DERData.ipAddress === communicationState.ipAddress;
          // }
        });
      }
      if (filteredIpAddress.length) {
        callSnackbar("error", "Entered duplicate Ipaddress");
      } else if (type === "Circuit Breaker") {
        if (
          checkForModbusDuplicate(
            communicationState.ipAddress,
            communicationState.slaveId,
            componentId
          )
        ) {
          callSnackbar(
            "error",
            "Duplicate Modbus Slave ID allowed only if their IPaddress are unique"
          );
        } else if (checkForIpInIfe(communicationState.ipAddress, componentId)) {
          callSnackbar("error", "An IFE can have maximum 7 Circuit Breakers");
        } else if (
          systemInfoDERData[componentId].slaveId !== communicationState.slaveId
        ) {
          if (
            communicationState.slaveId <= 255 &&
            parseInt(communicationState.slaveId) !== 0
          ) {
            const modbusSlaveIdArrayNew = [...modbusSlaveIdArray];
            modbusSlaveIdArrayNew.push(communicationState.slaveId);
            setModbusSlaveIdArray(modbusSlaveIdArrayNew);
            saveSystemInfoDer(componentId, type);
          } else {
            callSnackbar(
              "error",
              "Modbus Slave Id should be always between 1 - 255"
            );
          }
        } else {
          saveSystemInfoDer(componentId, type);
        }
      } else if (type !== "Circuit Breaker") {
        if (
          checkForModbusDuplicateDer(
            communicationState.ipAddress,
            communicationState.slaveId,
            componentId
          )
        ) {
          callSnackbar(
            "error",
            "Duplicate IPaddress allowed only if their Modbus Slave ID  are unique"
          );
        } else if (
          systemInfoDERData[componentId].slaveId !== communicationState.slaveId
        ) {
          if (
            communicationState.slaveId <= 255 &&
            parseInt(communicationState.slaveId) !== 0
          ) {
            const modbusSlaveIdArrayNew = [...modbusSlaveIdArray];
            modbusSlaveIdArrayNew.push(communicationState.slaveId);
            setModbusSlaveIdArray(modbusSlaveIdArrayNew);
            saveSystemInfoDer(componentId, type);
          } else {
            callSnackbar(
              "error",
              "Modbus Slave Id should be always between 1 - 255"
            );
          }
        } else {
          saveSystemInfoDer(componentId, type);
        }
      } else {
        saveSystemInfoDer(componentId, type);
      }
    } else {
      callSnackbar("error", "Invalid field entires");
    }
  };

  const savePowerMeter = (componentId, type, equipment) => {
    if (
      clientType === embLabels.GREENSTRUXURE_PROJECT &&
      architectureType === embLabels.PV_GENSET_ATS &&
      equipment === "PM_PV_Array"
    ) {
      if (
        isValidIp(communicationState.ipAddress) &&
        isValidIp(communicationState.mask) &&
        isValidIp(communicationState.gatewayAddress) &&
        isValidSlaveID(communicationState.slaveId)
      ) {
        if (
          systemInfoDERData[componentId].slaveId !== communicationState.slaveId
        ) {
          if (
            communicationState.slaveId <= 255 &&
            parseInt(communicationState.slaveId) !== 0
          ) {
            const powerMeterSlaveId = systemState.systemInfoDER
              .filter(infoDER => infoDER.network === "Power Meter")
              .map(power => power.slaveId);
            if (powerMeterSlaveId.includes(communicationState.slaveId)) {
              callSnackbar("error", "Entered Duplicate Slave ID");
            } else {
              saveSystemInfoDer(componentId, type);
            }
          } else {
            callSnackbar(
              "error",
              "Modbus Slave Id should be always between 1 - 255"
            );
          }
        } else {
          saveSystemInfoDer(componentId, type);
        }
      } else {
        callSnackbar("error", "Invalid field entires");
      }
    } else {
      if (isValidSlaveID(communicationState.slaveId)) {
        if (
          systemInfoDERData[componentId].slaveId !== communicationState.slaveId
        ) {
          if (parseInt(communicationState.slaveId) !== 0) {
            const powerMeterSlaveId = systemState.systemInfoDER
              .filter(infoDER => infoDER.network === "Power Meter")
              .map(power => power.slaveId);
            if (powerMeterSlaveId.includes(communicationState.slaveId)) {
              callSnackbar("error", "Entered Duplicate Slave ID");
            } else {
              saveSystemInfoDer(componentId, type);
            }
          } else {
            callSnackbar(
              "error",
              "Modbus Slave Id should be always between 1 - 255"
            );
          }
        } else {
          saveSystemInfoDer(componentId, type);
        }
      } else {
        callSnackbar("error", "Invalid Slave Id");
      }
    }
  };

  const saveSystemInfoDer = (componentId, type) => {
    setLoading(true);
    localStorage.setItem("communicationValidated", true);
    let jsonBody = [];
    let innerJson = {
      componentId,
      ipAddress: communicationState.ipAddress,
      mask: communicationState.mask,
      gatewayAddress: communicationState.gatewayAddress,
      slaveId: parseInt(communicationState.slaveId),
      comments: communicationState.comments,
      systemsPage: JSON.parse(localStorage.getItem("communicationValidated")),
    };
    jsonBody.push(innerJson);

    apiSession
      .updateSystemInfo(jsonBody)
      .then(response => {
        const copyDerData = Object.assign({}, systemInfoDERData);
        copyDerData[componentId].ipAddress = communicationState.ipAddress;
        copyDerData[componentId].mask = communicationState.mask;
        copyDerData[componentId].gatewayAddress =
          communicationState.gatewayAddress;
        copyDerData[componentId].slaveId = communicationState.slaveId;
        copyDerData[componentId].comments = communicationState.comments;

        setSystemInfoDerData(copyDerData);
        setComponentId(prevState => ({ ...prevState, [componentId]: false }));
        let modbusSlaveArray = [];
        systemState.systemInfoDER.map(infoDER => {
          if (infoDER.network === "Circuit Breaker") {
            modbusSlaveArray.push(infoDER.slaveId);
          }
          return null;
        });
        setModbusSlaveIdArray(modbusSlaveArray);
        resetCommunicationSettings();
        callSnackbar(
          "success",
          `${type} commuincation settings updated successfully`
        );
        setLoading(false);
      })
      .catch(error => {
        callSnackbar("error", "error in updation");
        setLoading(false);
      });
  };

  // functions for  info table end //

  // functions for  system table start //

  const enableTextBoxSystem = value => {
    systemState.systemInfoDER.forEach(infoDER =>
      setComponentId(prevState => ({
        ...prevState,
        [infoDER.componentId]: false,
      }))
    );
    systemState.systemCommunication.forEach(sysInfo =>
      setComponentId(prevState => ({ ...prevState, [sysInfo.id]: false }))
    );
    setComponentId(prevState => ({ ...prevState, [value]: true }));

    // dispacth function

    communicationDispatch({
      type: ACTIONS.ENABLE_TEXTBOX_SYSTEM,
      payload: {
        ipAddress: systemCommunicationData[value].ipAddress,
        mask: systemCommunicationData[value].mask,
        gatewayAddress: systemCommunicationData[value].gateway,
        slaveId: systemCommunicationData[value].modbusSlave,
        comments:
          systemCommunicationData[value].comment === null
            ? ""
            : systemCommunicationData[value].comment,
      },
    });
  };

  const saveSystemCommunication = rowId => {
    localStorage.setItem("communicationValidated", true);
    let jsonBody = {
      id: rowId,
      ipAddress: communicationState.ipAddress,
      mask: communicationState.mask,
      gateway: communicationState.gatewayAddress,
      comment: communicationState.comments,
      systemsPage: JSON.parse(localStorage.getItem("communicationValidated")),
    };

    setLoading(true);
    apiSession
      .updateSystemSettings(jsonBody)
      .then(response => {
        callSnackbar(
          "success",
          "System communication settings updated successfully"
        );
        setLoading(false);

        let copySysComData = Object.assign({}, systemCommunicationData);
        copySysComData[rowId].ipAddress = communicationState.ipAddress;
        copySysComData[rowId].mask = communicationState.mask;
        copySysComData[rowId].gateway = communicationState.gatewayAddress;
        copySysComData[rowId].comment = communicationState.comments;

        setSystemCommunicationData(copySysComData);

        setComponentId(prevState => ({ ...prevState, [rowId]: false }));
        resetCommunicationSettings();
      })
      .catch(() => {
        callSnackbar("error", "Error in system communication setting updation");
        setLoading(false);
      });
  };

  // functions for  system table end //

  //---  validation functions start ---- //

  const validateSystemsPage = systemsPage => {
    let jsonBody = {
      projectId: sessionStorage.getItem("projectId"),
      systemsPage,
    };

    setLoading(true);
    apiSession
      .validateSystemSettings(jsonBody)
      .then(() => {
        setLoading(false);
        document.getElementById("dialog-communication-validation").open = true;
      })
      .catch(error => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            callSnackbar(`error`, `${error.response.data.message}`);
          }
        } else {
          callSnackbar("error", "Duplicate values found");
        }
        localStorage.setItem("communicationValidated", true);
        setLoading(false);
      });
  };

  const onValidateCommunicationSetting = () => {
    const cbTypes = ["Circuit Breaker"];
    const derTypes = [
      "Utility",
      "Genset",
      "BESS",
      "Inverter",
      "CHP",
      "EV_charging_station",
    ];
    const systemTypes = [
      "DER Maintenance 1",
      "DER Maintenance 2",
      "DER Maintenance 3",
      "PLC - ETH2",
    ];

    const CBIP = systemState.systemInfoDER
      .filter(data => cbTypes.includes(data.network) && data.ipAddress)
      .map(data => ({
        firstOctet: data.ipAddress.split(".")[0],
        secondOctet: data.ipAddress.split(".")[1],
        thirdOctet: data.ipAddress.split(".")[2],
        fourthOctet: data.ipAddress.split(".")[3],
      }));

    const CBMASK = systemState.systemInfoDER
      .filter(data => cbTypes.includes(data.network) && data.mask)
      .map(data => ({
        firstOctet: data.mask.split(".")[0],
        secondOctet: data.mask.split(".")[1],
        thirdOctet: data.mask.split(".")[2],
        fourthOctet: data.mask.split(".")[3],
      }));

    const DERIP = systemState.systemInfoDER
      .filter(data => derTypes.includes(data.network) && data.ipAddress)
      .map(data => ({
        firstOctet: data.ipAddress.split(".")[0],
        secondOctet: data.ipAddress.split(".")[1],
        thirdOctet: data.ipAddress.split(".")[2],
        fourthOctet: data.ipAddress.split(".")[3],
      }));

    const DERMASK = systemState.systemInfoDER
      .filter(data => derTypes.includes(data.network) && data.mask)
      .map(data => ({
        firstOctet: data.mask.split(".")[0],
        secondOctet: data.mask.split(".")[1],
        thirdOctet: data.mask.split(".")[2],
        fourthOctet: data.mask.split(".")[3],
      }));

    const SYSTEMIP = systemState.systemCommunication
      .filter(data => systemTypes.includes(data.name) && data.ipAddress)
      .map(data => ({
        firstOctet: data.ipAddress.split(".")[0],
        secondOctet: data.ipAddress.split(".")[1],
        thirdOctet: data.ipAddress.split(".")[2],
        fourthOctet: data.ipAddress.split(".")[3],
      }));

    const SYSTEMMASK = systemState.systemCommunication
      .filter(data => systemTypes.includes(data.name) && data.mask)
      .map(data => ({
        firstOctet: data.mask.split(".")[0],
        secondOctet: data.mask.split(".")[1],
        thirdOctet: data.mask.split(".")[2],
        fourthOctet: data.mask.split(".")[3],
      }));

    const ALLIP = [...CBIP, ...DERIP, ...SYSTEMIP];
    const ALLMASK = [...CBMASK, ...DERMASK, ...SYSTEMMASK];

    const ALL_THIRDOCTET_IPSAME = ALLIP.every(
      value => value["thirdOctet"] === ALLIP[0]["thirdOctet"]
    );

    //devices are in same IP range
    const SAME_IPRANGE = ALLMASK.every(
      value =>
        value["firstOctet"] === "255" &&
        value["secondOctet"] === "255" &&
        value["thirdOctet"] === "255" &&
        value["fourthOctet"] === "0"
    );

    //devices are in diff IP range
    const DIFF_IPRANGE = ALLMASK.every(
      value =>
        value["firstOctet"] === "255" &&
        value["secondOctet"] === "255" &&
        value["thirdOctet"] === "0" &&
        value["fourthOctet"] === "0"
    );

    if (ALL_THIRDOCTET_IPSAME) {
      if (SAME_IPRANGE) {
        localStorage.setItem("communicationValidated", false);
        validateSystemsPage(false);
      } else {
        localStorage.setItem("communicationValidated", true);
        callSnackbar("error", 'Update mask values to "255.255.255.0"');
      }
    } else {
      if (DIFF_IPRANGE) {
        localStorage.setItem("communicationValidated", false);
        validateSystemsPage(false);
      } else {
        localStorage.setItem("communicationValidated", true);
        callSnackbar("error", 'Update mask values to "255.255.0.0"');
      }
    }
  };

  const onButtonClickValidated = () => {
    let { history } = props;
    history.push({
      pathname: "/bom",
    });
  };

  const isValidIp = value =>
    /^(?:(?:^|\.)(?:2(?:5[0-5]|[0-4]\d)|1?\d?\d)){4}$/.test(value)
      ? true
      : false;
  const isValidSlaveID = value =>
    /^(25[0-5]*|2[0-4][0-9]*|1[0-9][0-9]*|[1-9]?[0-9]?)$/.test(value)
      ? true
      : false;

  // ---  validation functions end ---- //

  //---  snackbar functions start ---- //

  const callSnackbar = (type, message) => {
    setSnackBar({ type, message });
    document.getElementById("custom-systempage").open = true;
  };

  // ---  snackbar functions end ---- //

  // JSX CODE

  return (
    <>
      {siteType ? (
        <div
          className={
            siteCreated
              ? "se-block-disabled"
              : !UserScope.access.saveSystemInfo
              ? "se-block-disabled"
              : ""
          }
        >
          {siteType ? (
            <se-container option="centered">
              <se-loading loading={loading}></se-loading>
              <div className="row">
                {UserScope.access.saveSystemInfo !== true && (
                  <div className="col-sm-12 text-center mt-1">
                    <span className="se-error-message-note text-center">
                      {`Information: ${UserScope.role} is not authorized to perform action on this page`}
                    </span>
                  </div>
                )}

                {UserScope.access.saveSystemInfo && siteCreated && (
                  <div className="col-sm-12 text-center mt-1">
                    <span className="se-error-message-note text-center">
                      {`Site already created for the project, no more modifications allowed`}
                    </span>
                  </div>
                )}

                <div className="col-md-12 mt-1">
                  {/* CIRCUIT BREAKER TABLE START */}
                  {siteType === "ISLANDABLE" && (
                    <div className="row">
                      <div className="col-md-12">
                        <span className="se-font-14px-bold se-life-green">
                          {" "}
                          Circuit breaker Communication settings (Device
                          Network)
                        </span>
                        <TableHeading />
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <tbody>
                              {systemState.systemInfoDER.map(
                                data =>
                                  data.network === "Circuit Breaker" && (
                                    <CircuitBreakerTable
                                      key={data.componentId}
                                      data={data}
                                      systemInfoDERData={systemInfoDERData}
                                      componentId={componentId}
                                      enableTextBox={enableTextBox}
                                      communicationState={communicationState}
                                      handleChange={handleChange}
                                      isValidIp={isValidIp}
                                      isValidSlaveID={isValidSlaveID}
                                      cancelUpdate={cancelUpdate}
                                      saveCommunications={saveCommunications}
                                    />
                                  )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* CIRCUIT BREAKER TABLE END */}

                  {/* POWER METER TABLE START */}

                  {([
                    embLabels.PV_HYBRIDIZATION,
                    embLabels.GRID_CONNECTED,
                  ].includes(siteType) ||
                    (siteType === embLabels.ISLANDABLE_GSX &&
                      architectureType === embLabels.PV_GENSET_ATS)) && (
                    <div className="row">
                      <div className="col-md-12">
                        <span className="se-font-14px-bold se-life-green">
                          Power Meter Communication settings
                        </span>
                        <TableHeading />
                        <div className="table-responsive">
                          <table className="table table-bordered">
                            <tbody>
                              {systemState.systemInfoDER.map(
                                data =>
                                  data.network === "Power Meter" && (
                                    <PowerMeterTable
                                      data={data}
                                      siteType={siteType}
                                      architectureType={architectureType}
                                      key={data.componentId}
                                      systemInfoDERData={systemInfoDERData}
                                      componentId={componentId}
                                      enableTextBox={enableTextBox}
                                      communicationState={communicationState}
                                      handleChange={handleChange}
                                      isValidSlaveID={isValidSlaveID}
                                      isValidIp={isValidIp}
                                      cancelUpdate={cancelUpdate}
                                      savePowerMeter={savePowerMeter}
                                    />
                                  )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* POWER METER TABLE START */}

                  {/* DER TABLE START */}

                  <div className="row">
                    <div className="col-md-12">
                      <span className="se-font-14px-bold se-life-green">
                        DER Communication settings (Device Network)
                      </span>

                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <tbody>
                            {systemState.systemInfoDER.map(
                              data =>
                                (data.network === "Utility" ||
                                  data.network === "Genset" ||
                                  data.network === "BESS" ||
                                  data.network === "Inverter" ||
                                  data.network === "CHP") && (
                                  <DERtable
                                    data={data}
                                    key={data.componentId}
                                    systemInfoDERData={systemInfoDERData}
                                    componentId={componentId}
                                    enableTextBox={enableTextBox}
                                    communicationState={communicationState}
                                    handleChange={handleChange}
                                    isValidIp={isValidIp}
                                    isValidSlaveID={isValidSlaveID}
                                    cancelUpdate={cancelUpdate}
                                    saveCommunications={saveCommunications}
                                    saveCommunicationsDer={
                                      saveCommunicationsDer
                                    }
                                  />
                                )
                            )}
                            {systemState.systemInfoDER.map(
                              data =>
                                // (data.network === "EV_charging_station" || data.network === "Load")
                                data.network === "EV_charging_station" && (
                                  <EVTable
                                    data={data}
                                    key={data.componentId}
                                    systemInfoDERData={systemInfoDERData}
                                    componentId={componentId}
                                    enableTextBox={enableTextBox}
                                    communicationState={communicationState}
                                    handleChange={handleChange}
                                    isValidIp={isValidIp}
                                    isValidSlaveID={isValidSlaveID}
                                    cancelUpdate={cancelUpdate}
                                    saveCommunications={saveCommunications}
                                  />
                                )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* DER TABLE END */}

                  {/* System communication settings (Device Network) start */}
                  <div className="row">
                    <div className="col-md-12">
                      <span className="se-font-14px-bold se-life-green">
                        System communication settings (Device Network)
                      </span>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <tbody>
                            {systemState.systemCommunication.map(
                              data =>
                                (data.name === "DER Maintenance 1" ||
                                  data.name === "DER Maintenance 2" ||
                                  data.name === "DER Maintenance 3" ||
                                  data.name === "PLC - ETH2") && (
                                  <SystemDeviceTable
                                    key={data.id}
                                    data={data}
                                    componentId={componentId}
                                    systemCommunicationData={
                                      systemCommunicationData
                                    }
                                    enableTextBoxSystem={enableTextBoxSystem}
                                    communicationState={communicationState}
                                    handleChange={handleChange}
                                    isValidIp={isValidIp}
                                    cancelUpdate={cancelUpdate}
                                    saveSystemCommunication={
                                      saveSystemCommunication
                                    }
                                  />
                                )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* System communication settings (Device Network) end */}

                  {/* System communication settings (Supervision Network) start*/}
                  <div className="row">
                    <div className="col-md-12">
                      <span className="se-font-14px-bold se-life-green">
                        System communication settings (Supervision Network)
                      </span>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <tbody>
                            {systemState.systemCommunication.map(
                              data =>
                                (data.name === "Supervision Maintenance 1" ||
                                  data.name === "Supervision Maintenance 2" ||
                                  data.name === "Supervision Maintenance 3" ||
                                  data.name === "PLC - ETH1" ||
                                  data.name === "IPC - Port B (local)" ||
                                  data.name === "Magelis") && (
                                  <SystemSupervisionTable
                                    key={data.id}
                                    data={data}
                                    systemCommunicationData={
                                      systemCommunicationData
                                    }
                                    componentId={componentId}
                                    enableTextBoxSystem={enableTextBoxSystem}
                                    communicationState={communicationState}
                                    handleChange={handleChange}
                                    cancelUpdate={cancelUpdate}
                                    saveSystemCommunication={
                                      saveSystemCommunication
                                    }
                                  />
                                )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* System communication settings (Supervision Network) end*/}
                </div>
              </div>

              {/* on button clicked ,onValidateCommunicationSetting*/}

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                  <se-snackBar
                    id="custom-systempage"
                    type={snackBar.type}
                    message={snackBar.message}
                    icon="information_stroke"
                    duration="3000"
                  ></se-snackBar>
                  <se-button
                    option="flat"
                    color="primary"
                    padding="large"
                    onClick={onValidateCommunicationSetting}
                  >
                    Validate
                  </se-button>
                </div>
              </div>
              <se-dialog
                id="dialog-communication-validation"
                can-backdrop="false"
                size="small"
              >
                <se-dialog-header>
                  Communication validation status
                </se-dialog-header>
                <se-dialog-content
                  className="se-header-font-size-16px"
                  icon="information_circle"
                  color="primary"
                >
                  Communication is validated   
                </se-dialog-content>
                <se-dialog-footer>
                  <se-button onClick={onButtonClickValidated}>OK</se-button>
                </se-dialog-footer>
              </se-dialog>
            </se-container>
          ) : (
            <se-loading loading={loading}></se-loading>
          )}
        </div>
      ) : (
        <se-loading loading={loading}></se-loading>
      )}
    </>
  );
};

export default SystemPage;
