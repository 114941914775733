import React, { useState } from "react";

const ContactEmail = ({
  UserScope,
  contactList,
  allRegions,
  loading,
  newAddContact,
  updateContact,
  deleteContactFromList,
}) => {
  const [contactDetails, setContactDetails] = useState({
    contactId: "",
    newContactEmail: "",
    contactEmail: "",
    contactDeleteId: "",
    newEmailRegion: "AFRICA",
    editEmailRegion: "",
    deleteContactConfirmPopUpStatus: false,
  });

  const addContactDisplay = () =>
    setContactDetails(prevState => ({ ...prevState, contactId: "0" }));

  const handleChangeContactEmail = e => {
    const { name, value } = e.target;
    setContactDetails(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addNewContact = () => {
    newAddContact(contactDetails);
    newCancelContact();
  };

  const newCancelContact = () => {
    setContactDetails(prevState => ({
      ...prevState,
      contactId: "",
      newContactEmail: "",
    }));
  };

  const editContact = contactData => {
    setContactDetails(prevState => ({
      ...prevState,
      contactId: contactData.id,
      contactEmail: contactData.email,
      editEmailRegion: "",
    }));
  };

  const cancelEditContact = () => {
    setContactDetails(prevState => ({
      ...prevState,
      contactId: "",
      contactEmail: "",
      editEmailRegion: "",
      newContactEmail: "",
    }));
  };

  const updateEditedContact = region => {
    updateContact(contactDetails, region);
    cancelEditContact();
  };

  const deleteContactPopUp = contactId =>
    setContactDetails(prevState => ({
      ...prevState,
      contactDeleteId: contactId,
      deleteContactConfirmPopUpStatus: true,
    }));

  const closeDeleteContactPopUp = () =>
    setContactDetails(prevState => ({
      ...prevState,
      contactDeleteId: "",
      deleteContactConfirmPopUpStatus: false,
    }));

  const removeContactConfirm = () => {
    if (contactDetails.contactDeleteId) {
      deleteContactFromList(contactDetails.contactDeleteId);
      closeDeleteContactPopUp();
    } else {
      closeDeleteContactPopUp();
    }
  };

  const isValidEmail = value => {
    var patternCheckRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim;
    return patternCheckRegex.test(value) ? true : false;
  };

  return (
    <div className="col-md-12 mt-2 pb-2">
      <div className="row">
        <div className="col-md-12 mt-2">
          <se-divider></se-divider>
        </div>
      </div>

      <div className="row">
        <div className="col-md-10 mt-2">
          <span className="se-life-green se-font-14px-bold ">
            Contact Email
          </span>
        </div>
        <div className="col-md-1">
          {UserScope.access.saveContact &&
            contactDetails.contactId === "" &&
            contactList.length < 10 && (
              <se-button
                option="raised"
                size="small"
                color="primary"
                icon="action_add_flat"
                onClick={addContactDisplay}
              >
                Add Contact
              </se-button>
            )}
        </div>
      </div>

      {contactDetails.contactId === "0" && (
        <div className="row">
          <div className="col-md-7">
            <div className="form-group row">
              <label
                htmlFor="inputEmail3"
                className="col-sm-2 col-form-label text-right"
              >
                Email<span className="se-mandatory">*</span>
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  autoComplete="none"
                  className="form-control form-control-md"
                  name="newContactEmail"
                  placeholder="Email"
                  value={contactDetails.newContactEmail}
                  maxlength="50"
                  onChange={handleChangeContactEmail}
                />
                <div
                  className="invalid-feedback-custom"
                  style={
                    contactDetails.newContactEmail.trim() === ""
                      ? {}
                      : { display: "none" }
                  }
                >
                  Email is required.
                </div>
                <div
                  className="invalid-feedback-custom"
                  style={
                    contactDetails.newContactEmail.trim() !== "" &&
                    isValidEmail(contactDetails.newContactEmail) === false
                      ? {}
                      : { display: "none" }
                  }
                >
                  Email is not valid.
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="form-group row">
                <div className="col-sm-12">
                  <select
                    className="custom-select"
                    name="newEmailRegion"
                    value={contactDetails.newEmailRegion}
                    onChange={handleChangeContactEmail}
                  >
                    {allRegions.map(regions => (
                      <option key={regions.id}>{regions.regions}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-2 my-auto  contact--email__cta">
            <span className="pointer">
              <se-icon
                size="medium"
                color="primary"
                title="Click to Save"
                disabled={
                  contactDetails.newContactEmail.trim() === "" ||
                  isValidEmail(contactDetails.newContactEmail) === false
                    ? true
                    : false
                }
                onClick={
                  contactDetails.newContactEmail.trim() === "" ||
                  isValidEmail(contactDetails.newContactEmail) === false
                    ? undefined
                    : addNewContact
                }
              >
                notification_ok_stroke
              </se-icon>
            </span>
            <span>&nbsp;</span>
            <span className="pointer">
              {" "}
              <se-icon
                size="medium"
                title="Click to cancel"
                onClick={newCancelContact}
              >
                action_delete_cross
              </se-icon>
            </span>
          </div>
        </div>
      )}

      <div className="row mt-1 pb-2">
        {contactList.length !== 0 && (
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-bordered mb-2">
                <thead>
                  <tr>
                    <th width="20%">Contact Email</th>
                    <th width="10%">Region</th>
                    <th width="10%" className="text-center">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {contactList.map(contactData => (
                    <tr key={contactData.id}>
                      <td width="20%">
                        {contactData.id !== contactDetails.contactId ? (
                          <span>{contactData.email}</span>
                        ) : (
                          <div className="form-group">
                            <input
                              type="text"
                              autoComplete="none"
                              className={"form-control form-control-sm "}
                              id="exampleFormControlInput1"
                              name="contactEmail"
                              maxLength="50"
                              value={contactDetails.contactEmail}
                              placeholder="Email"
                              onChange={handleChangeContactEmail}
                            />
                            <div
                              className="invalid-feedback-custom"
                              style={
                                !contactDetails.contactEmail
                                  ? {}
                                  : { display: "none" }
                              }
                            >
                              Email is required.
                            </div>
                            <div
                              className="invalid-feedback-custom"
                              style={
                                contactDetails.contactEmail.trim() !== "" &&
                                isValidEmail(contactDetails.contactEmail) ===
                                  false
                                  ? {}
                                  : { display: "none" }
                              }
                            >
                              Email is not valid.
                            </div>
                          </div>
                        )}
                      </td>

                      <td width="10%">
                        {contactData.id !== contactDetails.contactId ? (
                          <span>{contactData.region}</span>
                        ) : (
                          <div className="form-group row">
                            <div className="col-sm-12">
                              <select
                                className="contact--email__change"
                                name="editEmailRegion"
                                value={
                                  contactDetails.editEmailRegion === ""
                                    ? contactData.region
                                    : contactDetails.editEmailRegion
                                }
                                onChange={handleChangeContactEmail}
                              >
                                {allRegions.map(regions => (
                                  <option key={regions.id}>
                                    {regions.regions}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                      </td>

                      {contactData.id !== contactDetails.contactId ? (
                        <td width="10%" className="align-middle text-center">
                          {UserScope.access.saveContact === true ? (
                            <span className="pointer">
                              <se-icon
                                size="small"
                                title="Edit"
                                color="primary"
                                onClick={() => editContact(contactData)}
                              >
                                action_editor
                              </se-icon>
                            </span>
                          ) : (
                            ""
                          )}
                          <span>&nbsp; &nbsp;</span>
                          {UserScope.access.saveContact === true ? (
                            <span className="pointer">
                              <se-icon
                                size="small"
                                title="Delete"
                                color="primary"
                                onClick={() =>
                                  deleteContactPopUp(contactData.id)
                                }
                              >
                                action_delete
                              </se-icon>
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                      ) : (
                        <td width="10%" className="align-middle text-center">
                          <span className="pointer">
                            <se-icon
                              size="medium"
                              color="primary"
                              title="Click to update"
                              disabled={
                                contactDetails.contactEmail.trim() === "" ||
                                isValidEmail(contactDetails.contactEmail) ===
                                  false
                                  ? true
                                  : false
                              }
                              onClick={
                                contactDetails.contactEmail.trim() === "" ||
                                isValidEmail(contactDetails.contactEmail) ===
                                  false
                                  ? undefined
                                  : () =>
                                      updateEditedContact(contactData.region)
                              }
                            >
                              notification_ok_stroke
                            </se-icon>
                          </span>
                          <span>&nbsp;</span>
                          <span className="pointer">
                            {" "}
                            <se-icon
                              size="medium"
                              title="Click to cancel"
                              onClick={cancelEditContact}
                            >
                              action_delete_cross
                            </se-icon>
                          </span>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {contactList.length === 0 && loading === false && (
          <div className="col-md-12 text-center">
            <se-divider></se-divider>
            No Contacts
          </div>
        )}
      </div>
      <se-dialog
        id="dialog-complex-delete-confirm"
        open={contactDetails.deleteContactConfirmPopUpStatus}
        can-backdrop="false"
      >
        <se-dialog-header>Confirmation</se-dialog-header>
        <se-dialog-content>
          <span className="se-font-14px">
            Do you confirm to delete this contact ?
          </span>
        </se-dialog-content>
        <se-dialog-footer>
          <se-button onClick={closeDeleteContactPopUp}>Cancel</se-button>
          <se-button onClick={removeContactConfirm}>OK</se-button>
        </se-dialog-footer>
      </se-dialog>
    </div>
  );
};

export default ContactEmail;
