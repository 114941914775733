import React, { useState } from "react";

import embLabels from "../../config/emb-labels-en.json";

const AdminRoles = ({ closeAddSubrole }) => {
  const [selectedSubRole, setSelectedSubRole] = useState("");
  const [subRolesList] = useState([
    embLabels.SCHNEIDER_BUSINESS,
    embLabels.TEE,
    embLabels.EXECUTION_ENGINEER,
    embLabels.PANEL_BUILDER,
    embLabels.SPECIFIER,
    embLabels.GREENSTRUXURE,
  ]);

  const handleSubRoleChange = e => setSelectedSubRole(e.target.value);
  return (
    <se-dialog
      id="dialog-complex"
      size="small"
      open={true}
      can-backdrop="false"
    >
      <se-dialog-header>Subrole</se-dialog-header>

      <se-dialog-content className="se-dialog-content">
        <se-loading loading={false}></se-loading>
        <se-loading loading={false}></se-loading>

        <div className="row">
          <div className="col-md-12">
            <div className="admin__subrole--comingSoon">
              Contact Schneider Electric for implementation.
            </div>

            <div className="form-group ">
              <div className="col-sm-12">
                <select
                  className="form-control form-control-sm "
                  name="subRole"
                  id="subRoleSelection"
                  value={selectedSubRole}
                  onChange={handleSubRoleChange}
                >
                  <option value="0" hidden selected>
                    Select the role
                  </option>
                  {subRolesList.map(role => (
                    <option key={role} value={role}>
                      {role}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </se-dialog-content>
      <se-dialog-footer>
        <se-button option="outline" onClick={closeAddSubrole}>
          Cancel
        </se-button>

        <se-button
          option="flat"
          // disabled={selectedSubRole === "" ? true : false}
          disabled={true}
          //onClick={addUserToProjectList}
        >
          Add subrole
        </se-button>
      </se-dialog-footer>
    </se-dialog>
  );
};

export default AdminRoles;
