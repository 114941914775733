import React, { useState, useRef, useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";

import ProjectBackgroundImage from "../images/EMB.png";

import Contact from "../pages/contact";
import ChangePassword from "../components/component/ChangePassword";
import GenerateReport from "../components/component/GenerateReport";
import AdminRoles from "../components/component/AdminRoles";

import useMountEffect from "../pages/CustomHooks/useMountEffect";
import userScope from "../pages/CustomHooks/userScope";
import configLabel from "../config/emb-labels-en.json";

import APISESSION from "../api/ApiSession";

import embLabels from "../config/emb-labels-en.json";

const apiSession = new APISESSION();
const src = "url(" + ProjectBackgroundImage + ")";
const privacyPolicy = `https://www.se.com/ww/en/about-us/legal/data-privacy.jsp`;

const FileDownload = require("js-file-download");

const ShellLayout = props => {
  const [UserScope] = userScope();
  const [status, setStatus] = useState({
    reportReadyStatus: false,
    navStatus: true,
    enableAssetPage: false,
    enableProgramPage: false,
    enableSystemPage: false,
    enableBOMPage: false,
    enableDeployPage: false,
  });
  const [popUpStatus, setPopUpStatus] = useState({
    changePasswordPopUpStatus: false,
    successChangePassword: false,
    modifiedDataAlertPopupStatus: false,
    openReportPopUp: false,
    loadingPopupStatus: false,
    adminRolePopStatus: false,
  });
  const [modifiedStatus, setModifiedStatus] = useState({
    modifiedDataAlertAssetPopupStatus: false,
    modifiedDERName: "",
  });
  const [alertStatus, setAlertStatus] = useState({
    alertMessagePopupStatus: false,
    alertMessage: "",
  });
  const [reportStatus, setReportStatus] = useState({
    selectAll: false,
    information: false,
    bom: false,
    deploy: false,
    sld: false,
    system: false,
    program: false,
    showSeletOption: false,
  });
  const [snackBar, setSnackBar] = useState({ type: "", message: "" });

  const projectNavigationRef = useRef(null);
  const userNavigationRef = useRef(null);
  const libraryNavigationRef = useRef(null);
  const globalNavigationRef = useRef(null);
  const analyticsNavigationRef = useRef(null);
  const contactNavigationRef = useRef(null);

  useMountEffect(() => {
    localStorage.setItem("deploymentReady", true);
    localStorage.setItem("programPage", false);
    setStatus(prevState => ({ ...prevState, reportReadyStatus: false }));

    projectNavigationRef.current.addEventListener("didClick", e =>
      navigateProject(e)
    );

    if (UserScope.access.getUsers === true) {
      userNavigationRef.current.addEventListener("didClick", e =>
        navigateUser(e)
      );
    }

    if (UserScope.access.saveAssets === true) {
      libraryNavigationRef.current.addEventListener("didClick", e =>
        navigateLibrary(e)
      );
    }

    if (UserScope.access.globalParams === true) {
      globalNavigationRef.current.addEventListener("didClick", e =>
        navigateGlobals(e)
      );
    }

    if (UserScope.access.audit === true) {
      analyticsNavigationRef.current.addEventListener("didClick", e =>
        navigateAnalytics(e)
      );
    }

    if (UserScope.access.globalParams === true) {
      contactNavigationRef.current.addEventListener("didClick", e =>
        navigateContactUs(e)
      );
    }
  }, []);

  useEffect(() => {
    if (
      props.children.props.history.location.pathname === "/project" ||
      props.children.props.history.location.pathname === "/userdetails"
    ) {
      setStatus(prevState => ({ ...prevState, navStatus: false }));
    } else {
      setStatus(prevState => ({ ...prevState, navStatus: true }));

      resetEnable();
      if (props.children.props.history.location.pathname === "/information") {
        // nothing
      } else if (props.children.props.history.location.pathname === "/assets") {
        setStatus(prevState => ({ ...prevState, enableAssetPage: true }));
      } else if (
        props.children.props.history.location.pathname === "/program"
      ) {
        setStatus(prevState => ({ ...prevState, enableProgramPage: true }));
      } else if (props.children.props.history.location.pathname === "/system") {
        setStatus(prevState => ({ ...prevState, enableSystemPage: true }));
      } else if (props.children.props.history.location.pathname === "/bom") {
        setStatus(prevState => ({ ...prevState, enableBOMPage: true }));
      } else if (props.children.props.history.location.pathname === "/deploy") {
        setStatus(prevState => ({ ...prevState, enableDeployPage: true }));
      }
    }
  }, [props.children.props.history.location.pathname]);

  const navigateProject = () => {
    localStorage.setItem("selectedtab", "projects");
    localStorage.setItem("pname", null);
    document.getElementById("app2-sidemenu").toggle();
    let {
      children: {
        props: { history },
      },
    } = props;

    history.push({
      pathname: "/project",
    });
  };

  const navigateUser = () => {
    localStorage.setItem("selectedtab", "user");
    localStorage.setItem("pname", null);
    document.getElementById("app2-sidemenu").toggle();
    let {
      children: {
        props: { history },
      },
    } = props;

    history.push({
      pathname: "/project",
    });
  };

  const navigateLibrary = () => {
    localStorage.setItem("selectedtab", "asset");
    localStorage.setItem("pname", null);
    document.getElementById("app2-sidemenu").toggle();

    let {
      children: {
        props: { history },
      },
    } = props;

    history.push({
      pathname: "/project",
    });
  };

  const navigateGlobals = () => {
    localStorage.setItem("selectedtab", "globalparam");
    localStorage.setItem("pname", null);
    document.getElementById("app2-sidemenu").toggle();
    let {
      children: {
        props: { history },
      },
    } = props;

    history.push({
      pathname: "/project",
    });
  };

  const navigateAnalytics = () => {
    localStorage.setItem("selectedtab", "analytics");
    localStorage.setItem("pname", null);
    document.getElementById("app2-sidemenu").toggle();
    let {
      children: {
        props: { history },
      },
    } = props;

    history.push({
      pathname: "/project",
    });
  };

  const navigateContactUs = () => {};

  const redirectToProfile = () => {
    let {
      children: {
        props: { history },
      },
    } = props;

    history.push({
      pathname: "/userdetails",
    });
  };

  const onChangePassword = () =>
    setPopUpStatus(prevState => ({
      ...prevState,
      changePasswordPopUpStatus: true,
    }));

  const closeChangePasswordPopUp = () =>
    setPopUpStatus(prevState => ({
      ...prevState,
      changePasswordPopUpStatus: false,
    }));

  const onButtonLogout = () => {
    let {
      children: {
        props: { history },
      },
    } = props;
    localStorage.clear();
    apiSession
      .logout()
      .then(response => {
        history.push({
          pathname: "/logout",
        });
      })
      .catch(error => {
        history.push({
          pathname: "/logout",
        });
      });
    history.push({
      pathname: "/logout",
    });
  };

  const navigateToInformation = () => {
    let blockStatus = localStorage.getItem("assetEditStatus").split(",");
    if (blockStatus[1] === "true") {
      blockStatusDisplay(true, blockStatus[0]);
      localStorage.setItem("page", "information");
    } else if (
      status.enableProgramPage === true &&
      localStorage.getItem("programDataModified") === "true"
    ) {
      setPopUpStatus(prevState => ({
        ...prevState,
        modifiedDataAlertPopupStatus: true,
      }));
      localStorage.setItem("page", "information");
    } else {
      if (localStorage.getItem("processLoading") === "true") {
      } else {
        let {
          children: {
            props: { history },
          },
        } = props;
        history.push({
          pathname: "/information",
        });
      }
    }
  };

  const navigateToAsset = () => {
    if (
      status.enableProgramPage === true &&
      localStorage.getItem("programDataModified") === "true"
    ) {
      setPopUpStatus(prevState => ({
        ...prevState,
        modifiedDataAlertPopupStatus: true,
      }));
      localStorage.setItem("page", "assets");
    } else {
      let {
        children: {
          props: { history },
        },
      } = props;
      history.push({
        pathname: "/assets",
      });
    }
  };

  const navigateToProgram = () => {
    if (localStorage.getItem("deploymentReady") === "true") {
      let blockStatus = localStorage.getItem("assetEditStatus").split(",");
      if (blockStatus[1] === "true") {
        blockStatusDisplay(true, blockStatus[0]);
        localStorage.setItem("page", "program");
      } else {
        let {
          children: {
            props: { history },
          },
        } = props;
        history.push({
          pathname: "/program",
        });
      }
    } else {
      displayAlert("Please configure the asset/assets to proceed further.");
    }
  };

  const navigateToSystem = () => {
    if (localStorage.getItem("deploymentReady") === "true") {
      let blockStatus = localStorage.getItem("assetEditStatus").split(",");
      if (blockStatus[1] === "true") {
        blockStatusDisplay(true, blockStatus[0]);
        localStorage.setItem("page", "system");
      } else if (
        status.enableProgramPage === true &&
        localStorage.getItem("programDataModified") === "true"
      ) {
        setPopUpStatus(prevState => ({
          ...prevState,
          modifiedDataAlertPopupStatus: true,
        }));
        localStorage.setItem("page", "system");
      } else {
        let {
          children: {
            props: { history },
          },
        } = props;
        history.push({
          pathname: "/system",
        });
      }
    } else {
      displayAlert("Please configure the asset/assets to proceed further");
    }
  };

  const navigateToBOM = () => {
    if (localStorage.getItem("deploymentReady") === "true") {
      let blockStatus = localStorage.getItem("assetEditStatus").split(",");
      if (blockStatus[1] === "true") {
        blockStatusDisplay(true, blockStatus[0]);
        localStorage.setItem("page", "bom");
      } else if (
        status.enableProgramPage === true &&
        localStorage.getItem("programDataModified") === "true"
      ) {
        setPopUpStatus(prevState => ({
          ...prevState,
          modifiedDataAlertPopupStatus: true,
        }));
        localStorage.setItem("page", "bom");
      } else {
        let {
          children: {
            props: { history },
          },
        } = props;
        history.push({
          pathname: "/bom",
        });
      }
    } else {
      displayAlert("Please configure the asset/assets to proceed further.");
    }
  };

  const navigateToDeploy = () => {
    if (localStorage.getItem("deploymentReady") === "true") {
      let blockStatus = localStorage.getItem("assetEditStatus").split(",");
      if (blockStatus[1] === "true") {
        blockStatusDisplay(true, blockStatus[0]);
        localStorage.setItem("page", "deploy");
      } else if (
        status.enableProgramPage === true &&
        localStorage.getItem("programDataModified") === "true"
      ) {
        setPopUpStatus(prevState => ({
          ...prevState,
          modifiedDataAlertPopupStatus: true,
        }));
        localStorage.setItem("page", "deploy");
      } else {
        if (localStorage.getItem("communicationValidated") === "true") {
          displayAlert(
            "Please validate communication settings to proceed further."
          );
        } else {
          let {
            children: {
              props: { history },
            },
          } = props;
          history.push({
            pathname: "/deploy",
          });
        }
      }
    } else {
      displayAlert("Please configure the asset/assets to proceed further.");
    }
  };

  const resetEnable = () => {
    setStatus(prevState => ({
      ...prevState,
      enableAssetPage: false,
      enableProgramPage: false,
      enableSystemPage: false,
      enableBOMPage: false,
      enableDeployPage: false,
    }));
  };

  const blockStatusDisplay = (status, message) => {
    setModifiedStatus(prevState => ({
      ...prevState,
      modifiedDataAlertAssetPopupStatus: status,
      modifiedDERName: message,
    }));
  };

  const allowToSaveModifiedDataAsset = () => {
    setModifiedStatus(prevState => ({
      ...prevState,
      modifiedDataAlertAssetPopupStatus: false,
    }));
  };

  const closeDataModifiedAlertPopupAsset = () => {
    setModifiedStatus(prevState => ({
      ...prevState,
      modifiedDataAlertAssetPopupStatus: false,
    }));
    localStorage.removeItem("derHistory");
    localStorage.removeItem("pvHistory");
    localStorage.removeItem("cbEvHistory");
    localStorage.removeItem("cbLoadHistory");
    localStorage.removeItem("cbAssetHistory");
    localStorage.removeItem("loadHistory");
    localStorage.removeItem("derStatus");
    localStorage.setItem("assetEditStatus", "");

    let {
      children: {
        props: { history },
      },
    } = props;
    let pathNameDynamic = localStorage.getItem("page");
    history.push({
      pathname: pathNameDynamic,
    });
  };

  const allowToDeleteModifiedData = () => {
    localStorage.setItem("toBeSavedAsDraft", true);
    setPopUpStatus(prevState => ({
      ...prevState,
      modifiedDataAlertPopupStatus: false,
    }));

    setTimeout(() => {
      saveDraftData();
    }, 200);
    let {
      children: {
        props: { history },
      },
    } = props;
    let pathNameDynamic = localStorage.getItem("page");
    history.push({
      pathname: pathNameDynamic,
    });
  };

  const saveDraftData = () => {
    if (UserScope.access.saveProgram === true) {
      let jsonData = localStorage.getItem("draftSave");
      if (jsonData !== null) {
        apiSession
          .saveDraftProgram(JSON.parse(jsonData))
          .then(response => {
            localStorage.setItem("draftSave", null);
          })
          .catch(error => {
            localStorage.setItem("draftSave", null);
          });
      }
    } else {
      localStorage.setItem("draftSave", null);
    }
  };

  const closeDataModifiedAlertPopup = () => {
    setPopUpStatus(prevState => ({
      ...prevState,
      modifiedDataAlertPopupStatus: false,
    }));
    let {
      children: {
        props: { history },
      },
    } = props;
    let pathNameDynamic = localStorage.getItem("page");
    history.push({
      pathname: pathNameDynamic,
    });
  };

  const displayAlert = message => {
    setAlertStatus(prevState => ({
      ...prevState,
      alertMessage: message,
      alertMessagePopupStatus: true,
    }));
  };

  const closeAlertPopup = () => {
    setAlertStatus(prevState => ({
      ...prevState,
      alertMessage: "",
      alertMessagePopupStatus: false,
    }));

    //*for communication validation

    if (
      alertStatus.alertMessage ===
      "Please validate communication settings to proceed further."
    ) {
      let {
        children: {
          props: { history },
        },
      } = props;
      history.push({
        pathname: "/system",
      });
    }
  };

  const openGenerateReportConfiguration = () => {
    if (sessionStorage.getItem("projectId") !== "null") {
      setPopUpStatus(prevState => ({ ...prevState, openReportPopUp: true }));

      setReportStatus(prevState => ({
        ...prevState,
        selectAll: false,
        information: false,
        bom: false,
        deploy: false,
        sld: false,
        system: false,
        program: false,
      }));
    } else {
      displayAlert("Project is not created.");
    }
  };

  const closeOpenReportPopUp = () => {
    setPopUpStatus(prevState => ({ ...prevState, openReportPopUp: false }));
    setReportStatus(prevState => ({
      ...prevState,
      selectAll: false,
      information: false,
      bom: false,
      deploy: false,
      sld: false,
      system: false,
      program: false,
      showSeletOption: false,
    }));
    setStatus(prevState => ({ ...prevState, reportReadyStatus: false }));
  };

  const handleCheckBoxChange = e => {
    const { name, checked } = e.target;

    setReportStatus(prevState => ({
      ...prevState,
      [name]: checked,
    }));
  };
  const handleCheckBoxChangeAll = e => {
    const { checked } = e.target;
    setReportStatus(prevState => ({
      ...prevState,
      selectAll: checked,
      information: checked,
      bom: checked,
      deploy: checked,
      sld: checked,
      system: checked,
      program: checked,
    }));
  };

  const uploadReport = () => {
    if (
      reportStatus.information === true ||
      reportStatus.sld === true ||
      reportStatus.program === true ||
      reportStatus.bom === true ||
      reportStatus.system === true ||
      reportStatus.deploy === true
    ) {
      setStatus(prevState => ({ ...prevState, reportReadyStatus: true }));
      setPopUpStatus(prevState => ({
        ...prevState,
        loadingPopupStatus: true,
      }));
    } else {
      setReportStatus(prevState => ({
        ...prevState,
        showSeletOption: true,
      }));
    }
  };

  const uploadSLDStatus = data => {
    generateReport();
  };

  const generateReport = () => {
    if (
      reportStatus.information === true ||
      reportStatus.sld === true ||
      reportStatus.program === true ||
      reportStatus.bom === true ||
      reportStatus.system === true ||
      reportStatus.deploy === true
    ) {
      if (sessionStorage.getItem("projectId")) {
        apiSession
          .generateReport({
            projectId: sessionStorage.getItem("projectId"),
            information: reportStatus.information,
            sld: reportStatus.sld,
            program: reportStatus.program,
            bom: reportStatus.bom,
            system: reportStatus.system,
            deploy: reportStatus.deploy,
          })
          .then(response => {
            setPopUpStatus(prevState => ({
              ...prevState,
              loadingPopupStatus: false,
            }));
            closeOpenReportPopUp();
            FileDownload(
              response,
              "report.docx",
              "application/vnd.openxmlformats-officedocument.wordprocessing"
            );
            callSnackbar("success", "Report Downloaded successfully");
            setStatus(prevState => ({
              ...prevState,
              reportReadyStatus: false,
            }));
            setReportStatus(prevState => ({
              ...prevState,
              showSeletOption: false,
            }));
          })
          .catch(error => {
            setPopUpStatus(prevState => ({
              ...prevState,
              loadingPopupStatus: false,
            }));
            setStatus(prevState => ({
              ...prevState,
              reportReadyStatus: false,
            }));
            closeOpenReportPopUp();
            callSnackbar("error", "Error in report download");
            setReportStatus(prevState => ({
              ...prevState,
              showSeletOption: false,
            }));
          });
      }
    }
  };

  const changePasswordConfirmation = (
    passwordCheck,
    newPassword,
    confirmPassword
  ) => {
    if (
      passwordCheck === true &&
      confirmPassword.toLowerCase() === newPassword.toLowerCase()
    ) {
      let jsonBody = {
        oldPassword: "",
        newPassword: newPassword,
      };
      apiSession
        .changePassword(jsonBody)
        .then(() => {
          setPopUpStatus(prevState => ({
            ...prevState,
            changePasswordPopUpStatus: false,
            successChangePassword: true,
          }));
        })
        .catch(error => {
          if (error.response !== undefined) {
            if (error.response.status === 403) {
              callSnackbar("error", error.response.data.message);
            } else if (error.response.status === 400) {
              callSnackbar("error", error.response.data.message);
            } else if (error.response.status === 500) {
              callSnackbar("error", error.response.data.message);
            } else {
              callSnackbar("error", error.response.data.message);
            }
          } else {
            callSnackbar("error", configLabel.layout.NetworkError);
          }
        });
    } else {
      callSnackbar("error", configLabel.layout.passwordPolicyError);
    }
  };

  const redirectTologin = () => {
    let {
      children: {
        props: { history },
      },
    } = props;
    setPopUpStatus(prevState => ({
      ...prevState,
      successChangePassword: false,
    }));

    history.push({
      pathname: "/",
    });
  };

  const adminRoleClickHandler = () => {
    setPopUpStatus(prevState => ({
      ...prevState,
      adminRolePopStatus: true,
    }));

    // apiSession.getScope()
    // .then(response => {
    //   let scopeList = {};
    //   let scopeListAccess = {};
    //   scopeList.role = response.role;
    //   scopeList.name = response.name;
    //   response.scope.map((scopeData) => {
    //     scopeListAccess[scopeData] = true;
    //     return null;
    //   });
    //   localStorage.setItem("pname", null);
    //   scopeList.access = scopeListAccess;
    //   localStorage.setItem("scope", JSON.stringify(scopeList));
    //   history.push({
    //     pathname: '/project'
    //   });
    // })
    // .catch(error => {
    //   if (error.response !== undefined) {
    //     if (error.response.status === 404) {
    //       history.push({
    //         pathname: '/nonregistered'
    //       });

    //     } else {
    //       history.push({
    //         pathname: '/'
    //       });

    //     }
    //   }
    //   else {
    //     history.push({
    //       pathname: '/'
    //     });
    //   }

    // })
  };

  const closeAddSubrole = () =>
    setPopUpStatus(prevState => ({
      ...prevState,
      adminRolePopStatus: false,
    }));

  const callSnackbar = (type, message) => {
    setSnackBar({ type, message });
  };

  let pname = localStorage.getItem("pname");

  return (
    <se-container position="absolute" direction="column">
      <se-header app-title="Microgrid Build">
        <se-sidemenu
          id="app2-sidemenu"
          style={UserScope.name !== "" ? {} : { display: "none" }}
        >
          <se-sidemenu-item
            item="Projects"
            ref={projectNavigationRef}
          ></se-sidemenu-item>

          {UserScope.access.getUsers === true && (
            <se-sidemenu-item
              item="User Management"
              ref={userNavigationRef}
            ></se-sidemenu-item>
          )}

          {UserScope.access.saveAssets === true && (
            <se-sidemenu-item
              item="Library Management"
              ref={libraryNavigationRef}
            ></se-sidemenu-item>
          )}

          {UserScope.access.globalParams === true && (
            <se-sidemenu-item
              item="Global Parameters"
              ref={globalNavigationRef}
            ></se-sidemenu-item>
          )}

          {UserScope.access.audit === true && (
            <se-sidemenu-item
              item="Analytics"
              ref={analyticsNavigationRef}
            ></se-sidemenu-item>
          )}

          <se-sidemenu-item item="About">
            <se-about
              app-title="Microgrid Build"
              version={process.env.REACT_APP_VERSION}
              image-url={src}
            ></se-about>
          </se-sidemenu-item>
          <se-sidemenu-item item="Legal">
            <div className="col-md-12">
              <div className="border mt-2 p-2 legal-border">
                <h4>Schneider Data Privacy Policy</h4>
                <a
                  href={privacyPolicy}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Legal Policy"
                >
                  <h5>{privacyPolicy}</h5>
                </a>
              </div>
            </div>
          </se-sidemenu-item>
          <se-sidemenu-item item="Contact" ref={contactNavigationRef}>
            <Contact />
          </se-sidemenu-item>
        </se-sidemenu>

        <div
          slot="end"
          style={UserScope.name !== "" ? {} : { display: "none" }}
        >
          <se-dropdown alignment="right">
            <se-button
              slot="trigger"
              option="inherit"
              icon="user_standard"
              icon-color="primary"
            >
              {UserScope.name}
            </se-button>
            <se-list option="dropdown">
              <se-list-item
                item={"Role : " + UserScope.role}
                onClick={
                  UserScope.role === embLabels.ADMINs
                    ? adminRoleClickHandler
                    : undefined
                }
              ></se-list-item>
              <se-divider></se-divider>
              <se-list-item
                onClick={redirectToProfile}
                item="Profile"
              ></se-list-item>
              <se-divider></se-divider>
              <se-list-item
                onClick={onChangePassword}
                item="Change password"
              ></se-list-item>
              <se-divider></se-divider>

              <se-list-item
                onClick={onButtonLogout}
                item="Logout"
              ></se-list-item>
            </se-list>
          </se-dropdown>
        </div>
      </se-header>

      <se-tabbar color="primary">
        <nav style={status.navStatus ? {} : { display: "none" }}>
          <span className="ml-3" onClick={navigateToInformation}>
            <span className="se-subHeader-16px text-capitalize se-custom-nav-header">
              <span className="ml-1"> INFORMATION </span>
            </span>
          </span>

          <span className="mr-0 no-events">
            {" "}
            Design{" "}
            <se-icon className="icon_button icon_28 mr-1">arrow2_right</se-icon>
          </span>

          <span className="ml-0" onClick={navigateToAsset}>
            <span className="se-subHeader text-capitalize">
              <se-icon className="icon_button icon_20 " size="nano">
                circuit_breaker
              </se-icon>
              <span className="ml-1"> Configure DERs </span>
            </span>
          </span>

          {status.enableProgramPage === true ? (
            <NavLink to="/program" activeClassName="active" className="ml-0">
              <span className="se-subHeader text-capitalize">
                <se-icon className="icon_button icon_20 " size="nano">
                  sensors
                </se-icon>
                <span className="ml-1"> Configure Use Cases </span>
              </span>
            </NavLink>
          ) : (
            <span className="ml-0" onClick={navigateToProgram}>
              <span className="se-subHeader text-capitalize">
                <se-icon className="icon_button icon_20 " size="nano">
                  sensors
                </se-icon>
                <span className="ml-1"> Configure Use Cases </span>
              </span>
            </span>
          )}

          {status.enableSystemPage === true ? (
            <NavLink to="/system" activeClassName="active" className="ml-0">
              <span className="se-subHeader text-capitalize">
                <se-icon className="icon_button icon_20 " size="nano">
                  connection_ethernet
                </se-icon>
                <span className="ml-1"> System settings </span>
              </span>
            </NavLink>
          ) : (
            <span className="ml-0" onClick={navigateToSystem}>
              <span className="se-subHeader text-capitalize">
                <se-icon className="icon_button icon_20 " size="nano">
                  connection_ethernet
                </se-icon>
                <span className="ml-1"> System settings </span>
              </span>
            </span>
          )}

          {status.enableBOMPage === true ? (
            <NavLink
              to="/bom"
              exact={true}
              activeClassName="active"
              className="ml-0"
            >
              B.O.M
            </NavLink>
          ) : (
            <span className="ml-0" onClick={navigateToBOM}>
              <span className="se-subHeader-14px text-capitalize se-custom-nav-header">
                <span className="ml-1"> B.O.M </span>
              </span>
            </span>
          )}

          {status.enableDeployPage === true ? (
            <NavLink
              to="/deploy"
              exact={true}
              activeClassName="active"
              className="ml-0"
            >
              DEPLOY
            </NavLink>
          ) : (
            <span className="ml-0" onClick={navigateToDeploy}>
              <span className="se-subHeader-14px text-capitalize se-custom-nav-header">
                <span className="ml-1"> DEPLOY </span>
              </span>
            </span>
          )}

          <span className="ml-0" onClick={openGenerateReportConfiguration}>
            <span className="se-subHeader-14px text-capitalize se-custom-nav-header">
              <span className="ml-1"> GENERATE REPORT </span>
            </span>
          </span>
        </nav>
        <nav
          slot="edge"
          overflow="compact"
          style={
            pname === "null" || pname === null
              ? { display: "none" }
              : status.navStatus
              ? {}
              : { display: "none" }
          }
          className="pr-1 no-events se-subHeader-14px se-green-background se-custom-nav-header"
        >
          <span className="truncate-projectName-header " title={pname}>
            {" "}
            Project : {pname}
          </span>
        </nav>
      </se-tabbar>
      {props.children}

      {popUpStatus.changePasswordPopUpStatus && (
        <ChangePassword
          closeChangePasswordPopUp={closeChangePasswordPopUp}
          changePasswordConfirmation={changePasswordConfirmation}
        />
      )}

      {popUpStatus.openReportPopUp && (
        <GenerateReport
          status={status}
          reportStatus={reportStatus}
          closeOpenReportPopUp={closeOpenReportPopUp}
          handleCheckBoxChange={handleCheckBoxChange}
          handleCheckBoxChangeAll={handleCheckBoxChangeAll}
          uploadReport={uploadReport}
          uploadSLDStatus={uploadSLDStatus}
        />
      )}

      <se-dialog
        id="AlertDialog"
        open={modifiedStatus.modifiedDataAlertAssetPopupStatus}
        can-backdrop="false"
      >
        <se-dialog-header>Alert</se-dialog-header>
        <se-dialog-content
          icon="information_circle"
          color="primary"
          className="se-header-font-size-16px se-life-green"
        >
          Modified data is not saved in {modifiedStatus.modifiedDERName}. Do you
          want to save ? if No, Modified data will be lost.
        </se-dialog-content>
        <se-dialog-footer>
          <se-button onClick={allowToSaveModifiedDataAsset}>Yes</se-button>
          <se-button onClick={closeDataModifiedAlertPopupAsset}>No</se-button>
        </se-dialog-footer>
      </se-dialog>

      <se-dialog
        id="AlertDialog"
        open={popUpStatus.modifiedDataAlertPopupStatus}
        can-backdrop="false"
      >
        <se-dialog-header>Alert</se-dialog-header>
        <se-dialog-content
          icon="information_circle"
          color="primary"
          className="se-header-font-size-16px se-life-green"
        >
          Modified data is not saved. Do you want to save data? if yes, Modified
          data will be saved as draft.
        </se-dialog-content>
        <se-dialog-footer>
          <se-button onClick={allowToDeleteModifiedData}>Yes</se-button>
          <se-button onClick={closeDataModifiedAlertPopup}>No</se-button>
        </se-dialog-footer>
      </se-dialog>

      <se-dialog
        id="AlertDialog"
        open={alertStatus.alertMessagePopupStatus}
        can-backdrop="false"
      >
        <se-dialog-header>Alert</se-dialog-header>
        <se-dialog-content
          icon="information_circle"
          color="primary"
          className="se-header-font-size-16px se-life-green"
        >
          {alertStatus.alertMessage}
        </se-dialog-content>
        <se-dialog-footer>
          <se-button onClick={closeAlertPopup}>OK</se-button>
        </se-dialog-footer>
      </se-dialog>

      <se-dialog
        id="AlertDialog"
        open={popUpStatus.loadingPopupStatus}
        can-backdrop="false"
      >
        <se-dialog-content
          color="primary"
          className="se-header-font-size-16px se-life-green"
        >
          Please Wait Report is generating...
        </se-dialog-content>
      </se-dialog>

      <se-dialog
        id="dialog-asset-select-alert"
        size="small"
        can-backdrop="false"
        open={popUpStatus.successChangePassword}
      >
        <se-dialog-content icon="information_stroke" icon-color="primary">
          Password changed Successfully, Please login with new password.
        </se-dialog-content>
        <se-dialog-footer>
          <se-button onClick={redirectTologin}>OK</se-button>
        </se-dialog-footer>
      </se-dialog>

      {popUpStatus.adminRolePopStatus && (
        <AdminRoles closeAddSubrole={closeAddSubrole} />
      )}

      <se-snackbar
        id="snackbar-shell"
        type={snackBar.snackbarType}
        icon="information_stroke"
        message={snackBar.snackbarMessage}
        duration="3000"
      ></se-snackbar>
    </se-container>
  );
};

export default withRouter(ShellLayout);
