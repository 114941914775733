import React, { useContext } from "react";
import { AssetBlockContext } from "../CustomHooks/ContextApi";
import embLabels from "../../config/emb-labels-en.json";

const CBConfigDetails = () => {
  const {
    updateCbConfigData,
    configData,
    handleCBConfigChange,
    assetEditStatusUpdateAssetCB,
    selectedValues,
  } = useContext(AssetBlockContext);

  const cbConfigChangeHandler = e => {
    handleCBConfigChange(e);
    assetEditStatusUpdateAssetCB(e, true, selectedValues.selectedAssetCB);
  };
  return (
    <>
      <div className="col-md-12 mt-2 se-white-background align-middle">
        <div className="row">
          <label className="col-md-6 bold mt-2 mb-2 se-font-14px">
            Asset Settings
          </label>

          <div className="col-md-6 mt-1 text-right">
            <button
              type="button"
              className="btn btn-secondary btn-sm se-btn-primary"
              onClick={updateCbConfigData}
            >
              <se-icon size="nano">action_save</se-icon>
              <span>&nbsp;</span>Save
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-12 se-grey-background">
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">
            Name <span className="se-mandatory">*</span>
          </label>

          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput1"
            placeholder="Name"
            name="name"
            maxLength="50"
            value={configData.cbConfigData.name}
            onChange={cbConfigChangeHandler}
          />
          <div
            className={`invalid-feedback-custom ${
              !configData.cbConfigData.name ? "" : "hidden"
            }`}
          >
            {embLabels.nameRequired}
          </div>
        </div>
        <div className="form-group mb-1">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input asCompatible"
              name="motorized"
              id="customControlValidation4"
              value={configData.cbConfigData.motorized}
              checked={configData.cbConfigData.motorized}
              onChange={cbConfigChangeHandler}
            />
            <label
              className="custom-control-label line-height-25"
              htmlFor="customControlValidation4"
            >
              Motorized
            </label>
          </div>
        </div>
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">Description</label>
          <input
            type="text"
            autoComplete="none"
            className="form-control form-control-sm"
            id="exampleFormControlInput1"
            placeholder="Description"
            name="description"
            maxLength="100"
            value={configData.cbConfigData.description}
            onChange={cbConfigChangeHandler}
          />
        </div>
      </div>
    </>
  );
};

export default CBConfigDetails;
