import React, { Component } from "react";

class SLDPv1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOverData: false,
    };
  }
  onCBMouseOver() {
    this.setState({ mouseOverData: !this.state.mouseOverData });
  }
  padNumberFormat(number) {
    return (number < 10 ? "0" : "") + number;
  }
  render() {
    const {
      pv1,
      onPv1Select,
      onPv1CBSelect,
      pvGroupName,
      pvCbName,
    } = this.props;
    return [
      <svg className="pointer" style={pv1.status ? {} : { display: "none" }}>
        <g id="pv1">
          <svg
            x={pv1.xAxis}
            y={pv1.yAxis}
            width="195px"
            height="130px"
            viewBox="0 0 10 149"
            onClick={onPv1Select}
          >
            <title>{pvGroupName}</title>
            <g
              id="Design"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Design/Assets-_-00"
                transform="translate(-1036.000000, -372.000000)"
              >
                <g
                  id="SLD/PV/Uniline/Multiple_Done"
                  transform="translate(1036.000000, 372.000000)"
                >
                  <circle
                    id="Oval"
                    stroke="#42B4E6"
                    stroke-width="3"
                    cx="48"
                    cy="56"
                    r="55"
                    style={pv1.selected ? {} : { display: "none" }}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    fill="rgba(0, 135, 205, 0.2)"
                  ></circle>

                  <circle
                    id="Oval"
                    stroke={pv1.configured ? "#333333" : "#E47F00"}
                    stroke-width="3"
                    fill="#FFFFFF"
                    fill-rule="evenodd"
                    cx="57"
                    cy="59"
                    r="43.5"
                  ></circle>

                  <line
                    x1="48.5"
                    y1="102.287109"
                    x2="48.5"
                    y2="144"
                    id="Line-2"
                    stroke={pv1.configured ? "#333333" : "#E47F00"}
                    stroke-width="3"
                    stroke-linejoin="round"
                  ></line>
                  <circle
                    id="Oval"
                    stroke={pv1.configured ? "#333333" : "#E47F00"}
                    stroke-width="3"
                    fill="#FFFFFF"
                    fill-rule="evenodd"
                    cx="48"
                    cy="55"
                    r="46.5"
                  ></circle>

                  <circle
                    id="Oval"
                    stroke={pv1.configured ? "#333333" : "#E47F00"}
                    stroke-width="3"
                    fill="#FFFFFF"
                    fill-rule="evenodd"
                    cx="48"
                    cy="55"
                    r="46.5"
                  ></circle>

                  <g
                    id="999🔒Atoms/Notifications/Done"
                    transform="translate(66.000000, 2.000000)"
                  >
                    <g
                      id="999🔒Atoms/Background/Round"
                      stroke-width="1"
                      fill-rule="evenodd"
                      fill={pv1.configured ? "#333333" : "#E47F00"}
                      stroke="#FFFFFF"
                    >
                      <circle
                        id="Oval"
                        stroke-width="2.66666667"
                        cx="16"
                        cy="16"
                        r="18"
                      ></circle>
                    </g>

                    <text
                      id="3"
                      font-family="Nunito-Regular, Nunito"
                      font-size="24"
                      font-weight="normal"
                      fill="#FFFFFF"
                    >
                      <tspan x="2" y="24">
                        {this.padNumberFormat(pv1.count)}
                      </tspan>
                    </text>
                  </g>
                  <text
                    id="solar_panel1"
                    font-family="se-icon"
                    font-size="56"
                    font-weight="normal"
                    fill="#333333"
                  >
                    <tspan x="20" y="69">
                      solar_panel1
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </svg>
        </g>
        <g id="CB4">
          <svg
            width="75px"
            height="100px"
            viewBox="0 0 10 149"
            x={pv1.xAxisCB}
            y={pv1.yAxisCB}
            onClick={onPv1CBSelect}
          >
            <title>{pvCbName}</title>
            <g
              id="Circuit Breaker"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Circuit Breaker 1"
                transform="translate(-651.000000, -512.000000)"
              >
                <g
                  id="Circuit Breaker - closed"
                  transform="translate(604.000000, 514.000000)"
                  onMouseOver={this.onCBMouseOver.bind(this)}
                  onMouseOut={this.onCBMouseOver.bind(this)}
                >
                  <g
                    id="Group-15"
                    stroke-width="4"
                    fill-rule="evenodd"
                    fill={
                      pv1.CBSelected
                        ? "rgba(65, 181, 230, 0.35)"
                        : this.state.mouseOverData
                        ? "rgba(65, 181, 230, 0.35)"
                        : "none"
                    }
                    stroke={pv1.CBSelected ? "#42B4E6" : "none"}
                  >
                    <rect
                      id="Rectangle-Copy-20"
                      x="0"
                      y="12"
                      width="96"
                      height="122"
                    ></rect>
                    <g
                      id="Group-2"
                      transform="translate(48.000000, 0.000000)"
                      stroke={pv1.CBConfigured ? "#333333" : "#E47F00"}
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="4"
                    >
                      <line
                        x1="0.5"
                        y1="0"
                        x2="0.5"
                        y2="47.8298611"
                        id="Line-2"
                      ></line>
                    </g>
                  </g>
                  <line
                    x1="48.5"
                    y1="97.1701389"
                    x2="48.501111"
                    y2="145"
                    id="Line-2-Copy-15"
                    stroke={pv1.CBConfigured ? "#333333" : "#E47F00"}
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></line>
                  <path
                    d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                    id="Path-11"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke={pv1.CBConfigured ? "#333333" : "#E47F00"}
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </g>
      </svg>,
    ];
  }
}

export default SLDPv1;
