import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import ShellLayout from "./layouts/ShellLayout";
import DefaultLayout from "./layouts/default";

import Login from "./pages/login";
import Information from "./pages/InformationHook";
import DesignAsset from "./pages/DesignAssetHook";
import DesignProgram from "./pages/DesignProgramHook";
import Deploy from "./pages/DeployHook";
import NoPage from "./pages/404";
import ProjectHome from "./pages/project";
import AuthCallback from "./auth/AuthCallback";
import UserRegistration from "./pages/userRegistration";
import auth from "./auth/Auth";
import Logout from "./pages/logout";
import SystemPage from "./pages/SystemPageHook";
import RegistrationLayout from "./layouts/registrationLayout";
import cancelInvitation from "./pages/cancel";
import Invitation from "./pages/invitation";
import NonRegistered from "./pages/nonregistered";
import UserProfile from "./pages/userProfile";
import BOM from "./pages/bom";
const authLogin = new auth();

const AppRoute = ({
  component: Component,
  layout: Layout,
  auth: Auth,
  ...rest
}) =>
  Auth === "yes" ? (
    authLogin.isAuthenticated() === true ? (
      <Route
        {...rest}
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    ) : (
      <Route
        {...rest}
        render={props => (
          <Layout>
            <Login {...props} />
          </Layout>
        )}
      />
    )
  ) : (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );

class App extends Component {
  render() {
    return (
      <se-app>
        <BrowserRouter>
          <Switch>
            <AppRoute
              exact
              path="/"
              layout={ShellLayout}
              component={Login}
              auth={"no"}
            />
            <AppRoute
              path="/callback"
              layout={DefaultLayout}
              component={AuthCallback}
              auth={"no"}
            />
            <AppRoute
              path="/deploy"
              layout={ShellLayout}
              component={Deploy}
              auth={"yes"}
            />

            <AppRoute
              path="/information"
              layout={ShellLayout}
              component={Information}
              auth={"yes"}
            />

            <AppRoute
              path="/assets"
              layout={ShellLayout}
              component={DesignAsset}
              auth={"yes"}
            />

            <AppRoute
              path="/program"
              layout={ShellLayout}
              component={DesignProgram}
              auth={"yes"}
            />
            <AppRoute
              path="/system"
              layout={ShellLayout}
              component={SystemPage}
              auth={"yes"}
            />
            <AppRoute
              path="/project"
              layout={ShellLayout}
              component={ProjectHome}
              auth={"yes"}
            />
            <AppRoute
              path="/logout"
              layout={ShellLayout}
              component={Logout}
              auth={"no"}
            />
            <AppRoute
              path="/registration"
              layout={RegistrationLayout}
              component={UserRegistration}
              auth={"no"}
            />
            <AppRoute
              path="/invitation"
              layout={RegistrationLayout}
              component={Invitation}
              auth={"no"}
            />
            <AppRoute
              path="/cancel"
              layout={RegistrationLayout}
              component={cancelInvitation}
              auth={"no"}
            />
            <AppRoute
              path="/nonregistered"
              layout={RegistrationLayout}
              component={NonRegistered}
              auth={"no"}
            />
            <AppRoute
              path="/userdetails"
              layout={ShellLayout}
              component={UserProfile}
              auth={"yes"}
            />
            <AppRoute
              path="/bom"
              layout={ShellLayout}
              component={BOM}
              auth={"yes"}
            />

            <AppRoute
              path="/*"
              layout={DefaultLayout}
              component={NoPage}
              auth={"no"}
            />
          </Switch>
        </BrowserRouter>
      </se-app>
    );
  }
}

export default App;
