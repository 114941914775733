import React from "react";

const DisabledUseCase = ({ gridSettings }) => {
  return (
    <div className="row se-white-background pb-2">
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-2 ">
        <span className="se-font-14px-bold se-life-green ">
          Disabled Usecases
        </span>
        <div className="row ">
          {!gridSettings.demandCharge && (
            <div className="col-md-2 border se-program-usecase-card ml-2 overlay textEllipsis ">
              <span className="ml-2 ">Demand Charge</span>
            </div>
          )}
          {!gridSettings.selfConsumption && (
            <div className="col-md-2 border se-program-usecase-card ml-2 overlay textEllipsis ">
              <span className="ml-2 ">Self Consumption</span>
            </div>
          )}
          {(!gridSettings.noExport || !gridSettings.exportOptimization) && (
            <div className="col-md-2 border se-program-usecase-card ml-2 overlay textEllipsis ">
              <span className="ml-2 ">Export Management</span>
            </div>
          )}
          {!gridSettings.tariffManagement && (
            <div className="col-md-2 border se-program-usecase-card ml-2 overlay textEllipsis ">
              <span className="ml-2 ">Tariff Management </span>
            </div>
          )}

          {!gridSettings.remoteMonitoringControl && (
            <div className="col-md-2 border se-program-usecase-card ml-2 overlay textEllipsis ">
              <span className="ml-2  ">
                Remote energy monitoring and forecast
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DisabledUseCase;
