import React from "react";

const EVTable = props => {
  const {
    data,
    systemInfoDERData,
    componentId,
    enableTextBox,
    communicationState,
    handleChange,
    isValidIp,
    isValidSlaveID,
    cancelUpdate,
    saveCommunications,
  } = props;

  const enableTextHandler = () => enableTextBox(data.componentId);
  const cancelUpdateHandler = () => cancelUpdate(data.componentId);
  const saveCommunicationHandler = () =>
    saveCommunications(data.componentId, data.network);

  return (
    <tr>
      <td width="13%">{data.equipment}</td>
      <td width="10%">{data.network}</td>
      <td width="15%">
        <span
          className="pointer"
          title={
            systemInfoDERData[data.componentId].ipAddress && "Click to edit"
          }
          onClick={
            systemInfoDERData[data.componentId].ipAddress && enableTextHandler
          }
          style={componentId[data.componentId] ? { display: "none" } : {}}
        >
          {systemInfoDERData[data.componentId].ipAddress
            ? systemInfoDERData[data.componentId].ipAddress
            : "NA"}
        </span>
        <div
          className="form-group"
          style={componentId[data.componentId] ? {} : { display: "none" }}
        >
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput2"
            name="ipAddress"
            maxLength="15"
            value={communicationState.ipAddress}
            placeholder="Ip address"
            onChange={handleChange}
          />
          <div
            className="invalid-feedback-custom"
            style={
              communicationState.ipAddress === "" ? {} : { display: "none" }
            }
          >
            Ip address is required.
          </div>
          {communicationState.ipAddress !== "" ? (
            <div
              className="invalid-feedback-custom"
              style={
                isValidIp(communicationState.ipAddress) === false
                  ? {}
                  : { display: "none" }
              }
            >
              Invalid Ip address.
            </div>
          ) : (
            ""
          )}
        </div>
      </td>
      <td width="15%">
        <span
          className="pointer"
          title={systemInfoDERData[data.componentId].mask && "Click to edit"}
          onClick={
            systemInfoDERData[data.componentId].ipAddress && enableTextHandler
          }
          style={componentId[data.componentId] ? { display: "none" } : {}}
        >
          {systemInfoDERData[data.componentId].mask
            ? systemInfoDERData[data.componentId].mask
            : "NA"}
        </span>
        <div
          className="form-group"
          style={componentId[data.componentId] ? {} : { display: "none" }}
        >
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput2"
            name="mask"
            maxLength="15"
            value={communicationState.mask}
            placeholder="mask"
            onChange={handleChange}
          />
          <div
            className="invalid-feedback-custom"
            style={communicationState.mask === "" ? {} : { display: "none" }}
          >
            Mask is required.
          </div>
          {communicationState.mask !== "" ? (
            <div
              className="invalid-feedback-custom"
              style={
                isValidIp(communicationState.mask) === false
                  ? {}
                  : { display: "none" }
              }
            >
              Invalid mask.
            </div>
          ) : (
            ""
          )}
        </div>
      </td>
      <td width="15%">
        <span
          className="pointer"
          title={
            systemInfoDERData[data.componentId].gatewayAddress &&
            "Click to edit"
          }
          onClick={
            systemInfoDERData[data.componentId].ipAddress && enableTextHandler
          }
          style={componentId[data.componentId] ? { display: "none" } : {}}
        >
          {systemInfoDERData[data.componentId].gatewayAddress
            ? systemInfoDERData[data.componentId].gatewayAddress
            : "NA"}
        </span>
        <div
          className="form-group"
          style={componentId[data.componentId] ? {} : { display: "none" }}
        >
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput2"
            name="gatewayAddress"
            maxLength="15"
            value={communicationState.gatewayAddress}
            placeholder="Gateway address"
            onChange={handleChange}
          />
          <div
            className="invalid-feedback-custom"
            style={
              communicationState.gatewayAddress === ""
                ? {}
                : { display: "none" }
            }
          >
            Gateway address is required.
          </div>
          {communicationState.gatewayAddress !== "" ? (
            <div
              className="invalid-feedback-custom"
              style={
                isValidIp(communicationState.gatewayAddress) === false
                  ? {}
                  : { display: "none" }
              }
            >
              Invalid Gateway address.
            </div>
          ) : (
            ""
          )}
        </div>
      </td>
      <td width="12%">
        <span
          className="pointer"
          title={systemInfoDERData[data.componentId].slaveId && "Click to edit"}
          onClick={
            systemInfoDERData[data.componentId].ipAddress && enableTextHandler
          }
          style={componentId[data.componentId] ? { display: "none" } : {}}
        >
          {systemInfoDERData[data.componentId].slaveId
            ? systemInfoDERData[data.componentId].slaveId
            : "NA"}
        </span>

        <div
          className="form-group"
          style={componentId[data.componentId] ? {} : { display: "none" }}
        >
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput2"
            disabled="true"
            name="slaveId"
            maxLength="3"
            value={communicationState.slaveId}
            placeholder="Slave ID"
            onChange={handleChange}
          />
          <div
            className="invalid-feedback-custom"
            style={communicationState.slaveId === "" ? {} : { display: "none" }}
          >
            Slave ID is required.
          </div>
          {communicationState.slaveId !== "" ? (
            <div
              className="invalid-feedback-custom"
              style={
                isValidSlaveID(communicationState.slaveId) === false
                  ? {}
                  : { display: "none" }
              }
            >
              Invalid Slave ID.
            </div>
          ) : (
            ""
          )}
        </div>
      </td>
      <td width="10%">
        <span
          className="pointer"
          title={
            systemInfoDERData[data.componentId].comments && "Click to edit"
          }
          onClick={
            systemInfoDERData[data.componentId].ipAddress && enableTextHandler
          }
          style={componentId[data.componentId] ? { display: "none" } : {}}
        >
          {systemInfoDERData[data.componentId].comments
            ? systemInfoDERData[data.componentId].comments
            : "NA"}
        </span>

        <div
          className="form-group"
          style={componentId[data.componentId] ? {} : { display: "none" }}
        >
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput2"
            name="comments"
            maxLength="50"
            value={communicationState.comments}
            placeholder="Comments"
            onChange={handleChange}
          />
        </div>
      </td>
      <td width="10%" className="text-center">
        <div className="pointer">
          {componentId[data.componentId] ? (
            <se-icon
              size="medium"
              color="primary"
              title="Click to update"
              disabled={
                communicationState.ipAddress === "" ||
                communicationState.mask === "" ||
                communicationState.gatewayAddress === "" ||
                communicationState.slaveId === ""
                  ? true
                  : false
              }
              onClick={
                communicationState.ipAddress === "" ||
                communicationState.mask === "" ||
                communicationState.gatewayAddress === "" ||
                communicationState.slaveId === ""
                  ? ""
                  : saveCommunicationHandler
              }
            >
              notification_ok_stroke
            </se-icon>
          ) : (
            <div>
              <se-icon size="medium" disabled="true">
                notification_ok_stroke
              </se-icon>
            </div>
          )}

          {componentId[data.componentId] ? (
            <se-icon
              size="medium"
              title="Click to cancel"
              onClick={cancelUpdateHandler}
            >
              action_delete_cross
            </se-icon>
          ) : (
            ""
          )}
        </div>
      </td>
    </tr>
  );
};

export default EVTable;
