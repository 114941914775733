import React from "react";

const PlcProtection = ({
  UserScope,
  protectionKeyList,
  keyIds,
  enableTextBox,
  disableTextBox,
  handleChange,
  savePlcProtectionKey,
}) => {
  const isTouchDevice = () =>
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
      ? true
      : false;

  return (
    <div className="col-md-12 mt-2">
      <div className="row">
        <div className="col-md-12 mt-2">
          <span className="se-life-green se-font-14px-bold ">
            Global Parameters PLC Protection Key
          </span>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <tbody>
            {protectionKeyList.map(
              (globalData, index) =>
                index === 0 && (
                  <tr key={globalData.id}>
                    <td width="20%">
                      {keyIds[globalData.id] ? (
                        <label
                          htmlFor="inputEmail3"
                          className="col-sm-12 col-form-label se-life-green se-font-14px-bold"
                        >
                          PLC Protection Key{" "}
                          <span className="se-mandatory">*</span>
                          <se-tooltip
                            action={isTouchDevice() ? "click" : "hover"}
                            position="right"
                          >
                            <se-icon
                              option="raised"
                              color="secondary"
                              size="small"
                              slot="tooltip"
                            >
                              information_circle
                            </se-icon>
                            <se-tooltip-content>
                              Hexadecimal value and max 8 digits
                            </se-tooltip-content>
                          </se-tooltip>
                        </label>
                      ) : (
                        <span
                          className="pointer line-height-30px"
                          title="Click to edit"
                          onClick={() => enableTextBox(globalData.id)}
                          style={
                            keyIds[globalData.id] ? { display: "none" } : {}
                          }
                        >
                          PLC Protection Key
                        </span>
                      )}
                    </td>
                    <td width="40%">
                      {keyIds[globalData.id] ? (
                        <div>
                          <input
                            type="text"
                            autoComplete="none"
                            className="form-control form-control-md"
                            name="protectionkey"
                            placeholder="XXXX-NNN-NNNNNNNN"
                            value={globalData.value}
                            maxlength="8"
                            onChange={e => handleChange(e, index)}
                          />

                          {globalData.value === "" && (
                            <div className="invalid-feedback-custom">
                              Key is mandatory
                            </div>
                          )}
                        </div>
                      ) : (
                        <span
                          className="pointer line-height-30px"
                          title="Click to edit"
                          onClick={() => enableTextBox(globalData.id)}
                          style={
                            keyIds[globalData.id] ? { display: "none" } : {}
                          }
                        >
                          {globalData.value}
                        </span>
                      )}
                    </td>
                    <td width="10%" className="text-center">
                      <div className="pointer">
                        {keyIds[globalData.id] &&
                        UserScope.access.globalParams ? (
                          <se-icon
                            size="medium"
                            color="primary"
                            title="Click to update"
                            onClick={() => savePlcProtectionKey(index)}
                          >
                            notification_ok_stroke
                          </se-icon>
                        ) : (
                          <div title="Click to edit">
                            <se-icon
                              size="medium"
                              disabled="true"
                              onClick={() => enableTextBox(globalData.id)}
                            >
                              notification_ok_stroke
                            </se-icon>
                          </div>
                        )}

                        {keyIds[globalData.id] && (
                          <se-icon
                            size="medium"
                            title="Click to cancel"
                            onClick={() => disableTextBox(globalData.id)}
                          >
                            action_delete_cross
                          </se-icon>
                        )}
                      </div>
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlcProtection;
