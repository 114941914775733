import React, { useContext } from "react";

import isTouchDevice from "../SE-Component/Tooltip";
import embLabels from "../../config/emb-labels-en.json";
import { InformationPageContext } from "../CustomHooks/ContextApi";

const GenericSiteType = () => {
  const {
    projectType,
    informationToolTIP,
    siteType,
    handleSiteTypeChange,
    handlePackageMGSiteTypeChange,
  } = useContext(InformationPageContext);
  return (
    <>
      {projectType && (
        <div className="row">
          <div className="col-md-12 mt-1">
            <span className="labelCircle mr-2 ">2</span>{" "}
            {projectType === embLabels.STANDARD_MICROGRID ? (
              <span>Select your Microgrid type</span>
            ) : (
              <span>Select your pre-configured site</span>
            )}
            <se-tooltip
              action={isTouchDevice() ? "click" : "hover"}
              position="right"
            >
              <se-icon
                option="raised"
                color="secondary"
                size="small"
                slot="tooltip"
              >
                information_circle
              </se-icon>
              <se-tooltip-content>
                {projectType === embLabels.STANDARD_MICROGRID
                  ? "Select your Microgrid type"
                  : "Select your pre-configured site"}
              </se-tooltip-content>
            </se-tooltip>
          </div>
        </div>
      )}

      {projectType === embLabels.STANDARD_MICROGRID && (
        <div className="row is-flex mt-2">
          <div className="col-md-4">
            <div
              className={`col-md-12 ${
                siteType === embLabels.GRID_CONNECTED
                  ? "border-green-radius-5px"
                  : "border-radius-5px"
              }`}
            >
              <div className="row align-items-center">
                <div className="col-md-2">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="customRadio1"
                        name="siteType"
                        className="custom-control-input islandableRadio asCompatible"
                        value="GRID_CONNECTED"
                        checked={
                          siteType === embLabels.GRID_CONNECTED ? true : false
                        }
                        onChange={handleSiteTypeChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadio1"
                      >
                        &zwnj;
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-10 p-2">
                  <div className="row">
                    <div className="col-md-12 mt-1">
                      <span className="bold">Always grid connected site</span>
                    </div>
                    <div className="col-md-12 mt-1">
                      <span>
                        "{informationToolTIP.gridConnectedTooltipData}"
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className={`col-md-12 ${
                siteType === embLabels.ISLANDABLE
                  ? "border-green-radius-5px"
                  : "border-radius-5px"
              }`}
            >
              <div className="row align-items-center">
                <div className="col-md-2">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="customRadio2"
                        name="siteType"
                        className="custom-control-input islandableRadio asCompatible"
                        value="ISLANDABLE"
                        checked={
                          siteType === embLabels.ISLANDABLE ? true : false
                        }
                        onChange={handleSiteTypeChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadio2"
                      >
                        &zwnj;
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-10 p-2">
                  <div className="row">
                    <div className="col-md-12 mt-1">
                      <span className="bold">Islandable site</span>
                    </div>
                    <div className="col-md-12 mt-1">
                      <span>"{informationToolTIP.islandableTooltipData}"</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div
              className={`col-md-12 ${
                siteType === embLabels.OFF_GRID
                  ? "border-green-radius-5px"
                  : "border-radius-5px"
              }`}
            >
              <div className="row align-items-center">
                <div className="col-md-2">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="customRadio3"
                        name="siteType"
                        className="custom-control-input islandableRadio asCompatible"
                        value="OFF_GRID"
                        checked={siteType === embLabels.OFF_GRID ? true : false}
                        onChange={handleSiteTypeChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadio3"
                      >
                        &zwnj;
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-10 p-2">
                  <div className={`row `}>
                    <div className="col-md-12 mt-1">
                      <span className="bold">Always off grid site</span>
                    </div>
                    <div className="col-md-12 mt-1">
                      <span>"{informationToolTIP.offGridTooltipData}"</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {projectType === embLabels.PACKAGED_MICROGRID && (
        <div className="row mt-2">
          <div className="col-md-4">
            <div
              className={`col-md-12 ${
                siteType === embLabels.PV_HYBRIDIZATION
                  ? "border-green-radius-5px"
                  : "border-radius-5px"
              }`}
            >
              <div className="row align-items-center">
                <div className="col-md-2">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="customRadio1"
                        name="siteType"
                        className="custom-control-input islandableRadio asCompatible"
                        value="PV_HYBRIDIZATION"
                        checked={
                          siteType === embLabels.PV_HYBRIDIZATION ? true : false
                        }
                        onChange={handlePackageMGSiteTypeChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadio1"
                      >
                        &zwnj;
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-10 p-2">
                  <div className="row">
                    <div className="col-md-12 mt-1">
                      <span className="bold">Genset/PV hybridization</span>
                    </div>
                    <div className="col-md-12 mt-1">
                      <span>"{informationToolTIP.pvHybridizationToolTip}"</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GenericSiteType;
