import React from "react";

const CHPOnGrid = ({ chp, chpName, onChpSelect }) => {
  return (
    <svg className="pointer" style={chp.status ? {} : { display: "none" }}>
      <g id="chp">
        <svg
          x={chp.xAxis}
          y={chp.yAxis}
          width="195px"
          height="130px"
          viewBox="0 0 10 149"
          onClick={onChpSelect}
        >
          <title>{chpName}</title>
          <g id="Design" fill="none" fill-rule="evenodd">
            <g
              id="Design/Assets-_-00"
              transform="translate(-892.000000, -372.000000)"
            >
              <g
                id="SLD/Production/Uniline/Tobedefine"
                transform="translate(892.000000, 372.000000)"
              >
                <g id="Group-7">
                  <rect
                    id="Rectangle"
                    x="0"
                    y="0"
                    width="200"
                    height="200"
                  ></rect>

                  <circle
                    id="Oval"
                    stroke="#42B4E6"
                    stroke-width="3"
                    cx="48"
                    cy="56"
                    r="55"
                    style={chp.selected ? {} : { display: "none" }}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    fill="rgba(0, 135, 205, 0.2)"
                  ></circle>
                  <circle
                    id="Oval"
                    stroke-width="3"
                    fill="#FFFFFF"
                    cx="48"
                    cy="55"
                    r="46.5"
                    stroke={chp.configured ? "#333" : "#E47F00"}
                  ></circle>
                  <text
                    id="segment_energy_infra"
                    font-family="se-icon"
                    stroke={chp.configured ? "#333" : "#333"}
                    font-size="52"
                    font-weight="normal"
                    fill={chp.configured ? "#333" : "#333"}
                  >
                    <tspan x="22" y="76">
                      system_heat
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <line
          x1={chp.x1pos}
          y1={chp.y1pos}
          x2={chp.x2pos}
          y2={chp.y2pos}
          id="Line-2"
          stroke-width="4"
          stroke-dasharray="6 8"
          stroke-linecap="round"
          stroke={chp.configured ? "#333333" : "#E47F00"}
        ></line>
      </g>
    </svg>
  );
};

export default CHPOnGrid;
