import React, { useContext } from "react";

import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";
import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";

const Utility = () => {
  const {
    CBAssetList: [{ uiData, configData, powerMeterData }],
    UserScope,
    assetDetailsDisplay,
    CBAssetListDispatch,
    assetDetailsReset,
    callSnackbar,
    selectedValuesHandler,
    assetConfigDataHandler,
    setAssetDetailsDisplay,
    pmConfigDataHandler,
  } = useContext(DesignAssetContext);

  const utilitySelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "assetUtilityDetailsBlock";
      const selectedAssetValue = "selectedAsset";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_SELECT,
        payload: {
          index: 0,
          assetDetailsDisplay,
          setAssetDetailsDisplay,
        },
      });
      selectedValuesHandler(0, selectedAssetValue, assetBlock);
      assetConfigDataHandler(0);
    } else {
      callSnackbar(
        "error",
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const utilityPMSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "cbAssetDetailsBlock";
      const selectedAssetValue = "selectedAssetPM";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_PM_SELECT,
        payload: {
          index: 0,
        },
      });
      selectedValuesHandler(0, selectedAssetValue, assetBlock);
      pmConfigDataHandler(0);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };
  return (
    <svg className="pointer">
      <g id="utility">
        <svg
          x={uiData.xAxis}
          y={uiData.yAxis}
          width="220px"
          height="130px"
          viewBox="0 0 60 149"
          onClick={utilitySelectHandler}
        >
          <title>{configData.name}</title>
          <g
            id="Design"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Design/Assets-_-00"
              transform="translate(-604.000000, -372.000000)"
            >
              <g
                id="SLD/Production/Uniline/Undefined"
                transform="translate(604.000000, 372.000000)"
              >
                <g id="SLD/Production/Uniline/Tobedefine">
                  <g id="Group-7">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="0"
                      width="96"
                      height="144"
                    ></rect>
                    <line
                      x1="48.5"
                      y1="96"
                      x2="48.5"
                      y2="149"
                      id="Line-2"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      stroke={uiData.configured ? "#333" : "#E47F00"}
                    ></line>

                    <circle
                      id="Oval"
                      style={uiData.selected ? {} : { display: "none" }}
                      stroke="#42B4E6"
                      strokeWidth="3"
                      cx="48"
                      cy="56"
                      r="55"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="rgba(0, 135, 205, 0.2)"
                    ></circle>
                    <circle
                      id="Oval"
                      strokeWidth="3"
                      fill="#FFFFFF"
                      cx="48"
                      cy="56"
                      r="46.5"
                      stroke={uiData.configured ? "#333" : "#E47F00"}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></circle>

                    <text
                      id="segment_energy_infra"
                      fontFamily="se-icon"
                      fontSize="56"
                      fontWeight="normal"
                      fill="#333333"
                    >
                      <tspan x="20" y="76">
                        segment_energy_infrastructure
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </g>
      <g id="power meter">
        <svg
          x="120"
          y="30"
          width="300px"
          height="140px"
          viewBox="0 0 100 160"
          onClick={utilityPMSelectHandler}
        >
          <title>{powerMeterData.name}</title>
          <line
            x1="30"
            y1="127"
            x2="80"
            y2="127"
            stroke="#42B4E6"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            id="Pm outer"
            stroke="#42B4E6"
            strokeWidth="3"
            cx="100"
            cy="127"
            r="26"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="rgba(0, 135, 205, 0.2)"
            style={uiData.pmSelected ? {} : { display: "none" }}
          ></circle>
          <circle
            id="pm inner"
            stroke="#42B4E6"
            strokeWidth="3"
            cx="100"
            cy="127"
            r="18.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#FFFFFF"
          ></circle>
          <text
            id="PM"
            fontSize="16"
            fontWeight="normal"
            stroke="#42B4E6"
            fontFamily="Nunito-Regular, Nunito"
            fill="#42B4E6"
          >
            <tspan x="88" y="132">
              PM
            </tspan>
          </text>
        </svg>
      </g>
    </svg>
  );
};

export default Utility;
