import React, { useContext } from "react";

import Accordion from "../../components/Accordions/Accordion";
import LoadShedding from "./LoadShedding";
import BessManagement from "./BessManagement";
import TransitionManagement from "./TransitionManagement";
import GensetOptimization from "./GensetOptimization";
import BessChargingLimitation from "./BessChargingLimitation";
import BESSHibernating from "./BESSHibernating";

import { DesignProgramContext } from "../CustomHooks/ContextApi";

import embLabels from "../../config/emb-labels-en.json";

const OffGridManagement = () => {
  const {
    siteType,
    configuredStatus,
    batteryDetails,
    gensetDetails,
    gridSettings,
  } = useContext(DesignProgramContext);
  return (
    <div className="row se-white-background pb-2">
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-2 ">
        <span className="se-font-14px-bold se-life-green ">
          off grid management
        </span>

        <div className="row ">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            {siteType === embLabels.ISLANDABLE ||
            siteType === embLabels.PV_HYBRIDIZATION ||
            siteType === embLabels.ISLANDABLE_GSX ? (
              <Accordion allowMultipleOpen>
                <div
                  className="col-md-12"
                  label="Load Shedding"
                  status={configuredStatus.lsConfiguredStatus}
                  displayStatus={siteType === embLabels.ISLANDABLE ? "1" : "0"}
                  icon="notification_error_full_stroke"
                >
                  <se-divider></se-divider>
                  <LoadShedding />
                </div>

                {batteryDetails.batteryBufferingStatus ? (
                  <div
                    className="col-md-12"
                    label="BESS Management"
                    status={configuredStatus.configuredBlocks}
                    displayStatus="0"
                    icon="notification_error_full_stroke"
                  >
                    <BessManagement />
                  </div>
                ) : (
                  <div
                    className="col-md-12"
                    label="dummy bess management"
                    status="1"
                    displayStatus="0"
                    icon="notification_error_full_stroke"
                  ></div>
                )}

                <div
                  className="col-md-12"
                  label="Transition Management"
                  status={configuredStatus.tmConfiguredStatus}
                  displayStatus={siteType === embLabels.ISLANDABLE ? "1" : "0"}
                  icon="notification_error_full_stroke"
                >
                  <TransitionManagement />
                </div>

                {gensetDetails.gensetAvailabilityStatus ? (
                  <div
                    className="col-md-12"
                    label="Genset optimization & backfeed protection"
                    status={configuredStatus.gofpConfiguredStatus}
                    displayStatus="1"
                    icon="notification_error_full_stroke"
                  >
                    <GensetOptimization />
                  </div>
                ) : (
                  <div
                    className="col-md-12"
                    label="dummy Genset optimization & backfeed protection"
                    status="1"
                    displayStatus="0"
                    icon="notification_error_full_stroke"
                  ></div>
                )}

                {batteryDetails.batteryBufferingStatus ? (
                  <div
                    className="col-md-12"
                    label="BESS charging limitation"
                    status={configuredStatus.bclConfiguredStatus}
                    displayStatus={
                      siteType === embLabels.ISLANDABLE ? "1" : "0"
                    }
                    icon="notification_error_full_stroke"
                  >
                    <BessChargingLimitation />
                  </div>
                ) : (
                  <div
                    className="col-md-12"
                    label="dummy BESS charging limitation"
                    status="1"
                    displayStatus="0"
                    icon="notification_error_full_stroke"
                  ></div>
                )}

                {gridSettings.mode === "FUEL_ERASER" ||
                gridSettings.mode === "NO_FUEL" ? (
                  <div
                    className="col-md-12"
                    label="BESS hibernating"
                    status={configuredStatus.bhConfiguredStatus}
                    displayStatus={
                      siteType === embLabels.ISLANDABLE ? "1" : "0"
                    }
                    icon="notification_error_full_stroke"
                  >
                    <BESSHibernating />
                  </div>
                ) : (
                  <div
                    className="col-md-12"
                    label="dummy"
                    status="1"
                    displayStatus="0"
                    icon="notification_error_full_stroke"
                  ></div>
                )}

                <div
                  className="col-md-12"
                  label="dummy offgrid"
                  status={1}
                  displayStatus="0"
                  icon="notification_error_full_stroke"
                ></div>
              </Accordion>
            ) : siteType === embLabels.GRID_CONNECTED ? (
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="row">
                  <label className="col-md-7 col-form-label pl-5">
                    No off-grid management usecase
                  </label>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OffGridManagement;
