import React, { Component } from "react";

class SLDLoad1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOverData: false,
      XaxisLoad: "",
      YaxisLoad: "",
      x1: "",
      x2: "",
      y1: "",
      y2: "",
    };
  }
  onCBMouseOver() {
    this.setState({ mouseOverData: !this.state.mouseOverData });
  }

  componentDidMount() {
    switch (this.props.count) {
      case 1:
        this.setState({
          XaxisLoad: "288",
          YaxisLoad: "185",
          x1: "525",
          y1: "350",
          x2: "405",
          y2: "350",
        });
        break;
      case 2:
        this.setState({
          XaxisLoad: "320",
          YaxisLoad: "20",
          x1: "545",
          y1: "300",
          x2: "425",
          y2: "210",
        });
        break;
      case 3:
        this.setState({
          XaxisLoad: "320",
          YaxisLoad: "380",
          x1: "540",
          y1: "395",
          x2: "435",
          y2: "530",
        });
        break;
      case 4:
        this.setState({
          XaxisLoad: "420",
          YaxisLoad: "-30",
          x1: "570",
          y1: "280",
          x2: "510",
          y2: "170",
        });
        break;
      case 5:
        this.setState({
          XaxisLoad: "420",
          YaxisLoad: "420",
          x1: "570",
          y1: "415",
          x2: "500",
          y2: "550",
        });
        break;
      case 6:
        this.setState({
          XaxisLoad: "265",
          YaxisLoad: "97",
          x1: "530",
          y1: "325",
          x2: "385",
          y2: "270",
        });
        break;
      case 7:
        this.setState({
          XaxisLoad: "265",
          YaxisLoad: "280",
          x1: "530",
          y1: "380",
          x2: "380",
          y2: "430",
        });
        break;

      default:
        break;
    }
  }

  render() {
    const { load1, onLoad1Select, loadName } = this.props;
    return [
      <svg className="pointer">
        <g id="load1">
          <svg
            x={load1.xAxis}
            y={load1.yAxis}
            width="149px"
            height="475px"
            viewBox="0 0 90 550"
            onClick={onLoad1Select}
          >
            <title>{loadName}</title>
            <g
              id="Design"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Design/Assets-_-00"
                transform="translate(-604.000000, -372.000000)"
              >
                <g
                  id="SLD/Production/Uniline/Undefined"
                  transform="translate(604.000000, 372.000000)"
                >
                  <g id="SLD/Production/Uniline/Tobedefine">
                    <g id="Group-7">
                      <rect
                        id="Rectangle"
                        x="0"
                        y="95"
                        width="96"
                        height="148"
                      ></rect>

                      <circle
                        id="Oval"
                        stroke="#42B4E6"
                        stroke-width="3"
                        cx="48"
                        cy="193.5"
                        r="55"
                        style={load1.selected ? {} : { display: "none" }}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        fill="#EEEEEE"
                      ></circle>

                      <circle
                        id="Oval"
                        stroke-width="3"
                        fill="#FFFFFF"
                        cx="48"
                        cy="193.5"
                        r="46.5"
                        stroke={load1.configured ? "#333333" : "#E47F00"}
                      ></circle>

                      <text
                        id="segment_energy_infra"
                        font-family="se-icon"
                        font-size="56"
                        font-weight="normal"
                        fill="#333333"
                      >
                        <tspan x="20" y="210">
                          segment_building_small
                        </tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <line
            x1={load1.x1pos}
            y1={load1.y1pos}
            x2={load1.x2pos}
            y2={load1.y2pos}
            id="Line-2"
            stroke-width="4"
            stroke-dasharray="6 8"
            stroke-linecap="round"
            stroke={load1.configured ? "#333333" : "#E47F00"}
          ></line>
        </g>
      </svg>,
    ];
  }
}

export default SLDLoad1;
