import React, { useContext } from "react";
import embLabels from "../../config/emb-labels-en.json";

import { AssetBlockContext } from "../CustomHooks/ContextApi";

const GensetAssetPvHyb = () => {
  const {
    siteType,
    architectureType,
    gsxExternalId,
    configData,
    handleGensetChange,
    defaultsMinMax,
    updateGensetConfig,
  } = useContext(AssetBlockContext);

  const gensetChangeHandler = e => {
    handleGensetChange(e);
  };
  return (
    <div name="genset" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 pl-4 se-white-background">
        <div className="row">
          <div className="col-md-8 pb-2 pl-0 ">
            <span>
              <se-icon
                color="primary"
                className="se-icon icon_button se-life-green"
                size="medium"
              >
                {" "}
                genset_01{" "}
              </se-icon>
              <span className="ml-2 bold align-top line-height-30px">
                Genset
              </span>
            </span>
          </div>
        </div>
      </div>

      <div>
        <div className="col-md-12 se-white-background align-middle se-border">
          <div className="row">
            <label className=" col-md-6 bold mt-2 mb-2 pl-1 se-font-14px">
              Asset Settings
            </label>

            <div className="col-md-6 mt-1 text-right">
              <button
                type="button"
                className="btn btn-secondary btn-sm se-btn-primary"
                onClick={updateGensetConfig}
              >
                <se-icon size="nano">action_save</se-icon>
                <span>&nbsp;</span>Save
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12 pt-2 pb-2 se-grey-background">
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Name <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              name="name"
              maxLength="50"
              placeholder="Name"
              value={configData.configData.name}
              onChange={gensetChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={!configData.configData.name ? {} : { display: "none" }}
            >
              {embLabels.nameRequired}
            </div>
          </div>

          {[embLabels.ISLANDABLE_GSX].includes(siteType) &&
            architectureType === embLabels.PV_GENSET_ATS && (
              <div className="form-group mb-1">
                <label htmlFor="exampleFormControlInput1">External ID</label>
                <input
                  type="text"
                  autoComplete="none"
                  className="form-control form-control-sm"
                  id="exampleFormControlInput1"
                  placeholder="External ID"
                  onChange={gensetChangeHandler}
                  name="externalId"
                  maxLength="64"
                  value={gsxExternalId.gensetExternalId}
                />
              </div>
            )}
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">Description</label>
            <input
              type="text"
              autoComplete="none"
              className="form-control form-control-sm"
              id="exampleFormControlInput1"
              name="description"
              maxLength="100"
              placeholder="Description"
              value={configData.configData.description}
              onChange={gensetChangeHandler}
            />
          </div>

          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Max Power(kW) <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              name="max_power"
              maxLength="4"
              placeholder="Max Power(kW)"
              value={configData.configData.max_power}
              onChange={gensetChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={
                !configData.configData.max_power ? {} : { display: "none" }
              }
            >
              Max Power(kW) is required.
            </div>
            {configData.configData.max_power !== "" &&
            (parseInt(configData.configData.max_power) <
              defaultsMinMax.maxPowerGensetMin ||
              parseInt(configData.configData.max_power) >
                defaultsMinMax.maxPowerGensetMax) ? (
              <div className="invalid-feedback-custom">
                Max Power should be in range(
                {defaultsMinMax.maxPowerGensetMin}-
                {defaultsMinMax.maxPowerGensetMax} kW).
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput2">
              Genset CO2 Rate (kg/MWh) <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput2"
              name="gensetCO2"
              maxLength="4"
              placeholder="Genset CO2 Rate"
              value={configData.configData.gensetCO2}
              onChange={gensetChangeHandler}
            />
            {configData.configData.gensetCO2 === "" ? (
              <div className="invalid-feedback-custom">
                Genset CO2(kg/MWh) is required.
              </div>
            ) : (
              ""
            )}

            {configData.configData.gensetCO2 !== "" &&
            (parseInt(configData.configData.gensetCO2) <
              defaultsMinMax.gensetCO2Min ||
              parseInt(configData.configData.gensetCO2) >
                defaultsMinMax.gensetCO2Max) ? (
              <div className="invalid-feedback-custom">
                Genset CO2 should be in range(
                {defaultsMinMax.gensetCO2Min}-{defaultsMinMax.gensetCO2Max}{" "}
                kg/MWh).
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GensetAssetPvHyb;
