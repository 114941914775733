import React, { useContext } from "react";

import isTouchDevice from "../SE-Component/Tooltip";
import { InformationPageContext } from "../CustomHooks/ContextApi";

const SelectUseCaseGSX = () => {
  const { informationToolTIP, gridSettings, handleOnGridFeatureChangeGSX } =
    useContext(InformationPageContext);
  return (
    <div className="row mt-2">
      <div className="col-md-12">
        <div className="col-md-12 border-radius-5px ">
          <div className="row">
            <div className={"col-md-6 "}>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-1">
                  <span>On grid optimization features </span>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input asCompatible"
                      name="noExport"
                      id="customControlValidation5"
                      value={gridSettings.noExport}
                      checked={gridSettings.noExport}
                      onChange={handleOnGridFeatureChangeGSX}
                    />
                    <label
                      className="custom-control-label line-height-25"
                      htmlFor="customControlValidation5"
                    >
                      Export real time optimization
                    </label>
                    <se-tooltip
                      action={isTouchDevice() ? "click" : "hover"}
                      position="right"
                    >
                      <se-icon
                        option="raised"
                        color="secondary"
                        size="small"
                        slot="tooltip"
                      >
                        information_circle
                      </se-icon>
                      <se-tooltip-content>
                        {informationToolTIP.exportRealTimeTooltipData}
                      </se-tooltip-content>
                    </se-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectUseCaseGSX;
