import "babel-polyfill";
//import 'react-app-polyfill/ie11';
import { applyPolyfills, defineCustomElements } from "@se/web-ui/loader";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import "./map-custom.css";

applyPolyfills().then(() => {
  defineCustomElements(window);
});
/* estlint no-restricted-globals:0 */
let state = {};
window.setState = changes => {
  state = Object.assign({}, state, changes);
  ReactDOM.render(<App {...state} />, document.getElementById("root"));
};

let iniialState = {
  location: window.location.pathname.replace(/"\/?|\/$/g, ""),
};
window.setState(iniialState);
