import React from "react";

const SystemSupervisionTable = props => {
  const {
    data,
    systemCommunicationData,
    enableTextBoxSystem,
    componentId,
    communicationState,
    handleChange,
    cancelUpdate,
    saveSystemCommunication,
  } = props;
  const enableTextBoxSystemHandler = () => enableTextBoxSystem(data.id);
  const saveSystemCommunicationHandler = () => saveSystemCommunication(data.id);
  const cancelUpdateHandler = () => cancelUpdate(data.id);

  return (
    <tr>
      <td width="13%">{data.name}</td>
      <td width="10%">{data.assetType}</td>
      <td width="15%">
        {systemCommunicationData[data.id].ipAddress
          ? systemCommunicationData[data.id].ipAddress
          : "NA"}
      </td>
      <td width="15%">
        {systemCommunicationData[data.id].mask
          ? systemCommunicationData[data.id].mask
          : "NA"}
      </td>
      <td width="15%">
        {systemCommunicationData[data.id].gateway
          ? systemCommunicationData[data.id].gateway
          : "NA"}
      </td>
      <td width="12%">
        {systemCommunicationData[data.id].modbusSlave
          ? systemCommunicationData[data.id].modbusSlave
          : "NA"}
      </td>
      <td width="10%">
        <span
          className="pointer"
          title="Click to edit"
          onClick={enableTextBoxSystemHandler}
          style={componentId[data.id] ? { display: "none" } : {}}
        >
          {systemCommunicationData[data.id].comment
            ? systemCommunicationData[data.id].comment
            : "NA"}
        </span>

        <div
          className="form-group"
          style={componentId[data.id] ? {} : { display: "none" }}
        >
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput2"
            name="comments"
            maxLength="50"
            value={communicationState.comments}
            placeholder="Comments"
            onChange={handleChange}
          />
        </div>
      </td>
      <td width="10%" className="text-center">
        <div className="pointer">
          {componentId[data.id] ? (
            <se-icon
              size="medium"
              color="primary"
              title="Click to update"
              disabled={communicationState.comment === "" ? true : false}
              onClick={
                communicationState.comment === ""
                  ? ""
                  : saveSystemCommunicationHandler
              }
            >
              notification_ok_stroke
            </se-icon>
          ) : (
            <div>
              <se-icon size="medium" disabled="true">
                notification_ok_stroke
              </se-icon>
            </div>
          )}

          {componentId[data.id] && (
            <se-icon
              size="medium"
              title="Click to cancel"
              onClick={cancelUpdateHandler}
            >
              action_delete_cross
            </se-icon>
          )}
        </div>
      </td>
    </tr>
  );
};

export default SystemSupervisionTable;
