import React, { useContext } from "react";

import isTouchDevice from "../SE-Component/Tooltip";
import embLabels from "../../config/emb-labels-en.json";
import { InformationPageContext } from "../CustomHooks/ContextApi";

const RemoteMonitoring = () => {
  const {
    siteType,
    informationToolTIP,
    disabledFeatures,
    gridSettings,
    handleRemoteMonitoringChange,
  } = useContext(InformationPageContext);
  return (
    <>
      <div className="row mt-1">
        <div className="col-md-12 mt-1">
          <span className="labelCircle mr-2 ">3</span>{" "}
          <span>Select Use Cases</span>
          <se-tooltip
            action={isTouchDevice() ? "click" : "hover"}
            position="right"
          >
            <se-icon
              option="raised"
              color="secondary"
              size="small"
              slot="tooltip"
            >
              information_circle
            </se-icon>
            <se-tooltip-content>
              Select grid-tied functions and/or offgrid functions depending on
              your site selection
            </se-tooltip-content>
          </se-tooltip>
        </div>
      </div>
      <div className="row mt-2">
        <div
          className={
            siteType === embLabels.ISLANDABLE ||
            siteType === embLabels.PV_HYBRIDIZATION ||
            siteType === embLabels.ISLANDABLE_GSX ||
            (siteType === embLabels.GRID_CONNECTED &&
              disabledFeatures.disableCloud === false)
              ? "col-md-12"
              : "col-md-12  se-block-disabled"
          }
        >
          <div className="col-md-12 border-radius-5px mt-2">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-2 ">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input asCompatible"
                    name="remoteMonitoringControl"
                    id="customControlValidation6"
                    value={gridSettings.remoteMonitoringControl}
                    checked={gridSettings.remoteMonitoringControl}
                    onChange={handleRemoteMonitoringChange}
                  />
                  <label
                    className="custom-control-label line-height-25"
                    htmlFor="customControlValidation6"
                  >
                    Remote energy monitoring and forecast
                  </label>
                  <se-tooltip
                    action={isTouchDevice() ? "click" : "hover"}
                    position="right"
                  >
                    <se-icon
                      option="raised"
                      color="secondary"
                      size="small"
                      slot="tooltip"
                    >
                      information_circle
                    </se-icon>
                    <se-tooltip-content>
                      {informationToolTIP.remoteMonitoringTooltipData}
                    </se-tooltip-content>
                  </se-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RemoteMonitoring;
