import React from "react";

const SLDEcc = () => {
  return (
    <svg>
      <g id="ECC1">
        <svg>
          <g
            id="ECC1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="ECC1" transform="translate(-532.000000, -514.000000)">
              <g
                id="SLD/Infos/Microgrid_Background"
                transform="translate(532.000000, 514.000000)"
              >
                <rect
                  id="Rectangle-Copy-24"
                  stroke="#333333"
                  strokeWidth="3"
                  x="98"
                  y="170"
                  width="375"
                  height="255.3"
                  rx="16"
                />
                <text
                  id="AAA"
                  fontFamily="Nunito-Regular, Nunito"
                  fontSize="18"
                  fontWeight="normal"
                  fill="#333333"
                >
                  <tspan x="280" y="415">
                    Existing switchboard
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </svg>
      </g>
      <g id="ECC2">
        <svg>
          <g
            id="ECC2"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="ECC2" transform="translate(-532.000000, -514.000000)">
              <g
                id="SLD/Infos/Microgrid_Background"
                transform="translate(532.000000, 514.000000)"
              >
                <rect
                  id="Rectangle-Copy-24"
                  stroke="#333333"
                  strokeWidth="3"
                  x="600"
                  y="332"
                  width="310"
                  height="200.3"
                  rx="16"
                />
                <text
                  id="AAA"
                  fontFamily="Nunito-Regular, Nunito"
                  fontSize="18"
                  fontWeight="normal"
                  fill="#333333"
                >
                  <tspan x="755" y="520">
                    New switchboard
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </svg>
      </g>
    </svg>
  );
};

export default SLDEcc;
