import React, { Component } from "react";

class SLDUtility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOverData: false,
    };
  }
  onCBMouseOver() {
    this.setState({ mouseOverData: !this.state.mouseOverData });
  }

  render() {
    const { utility, onUtilitySelect, utilityName } = this.props;
    return [
      <svg className="pointer">
        <g id="utility">
          <svg
            x={utility.xAxis}
            y={utility.yAxis}
            // x="464"
            // y="70"
            width="195px"
            height="130px"
            viewBox="0 0 10 149"
            onClick={onUtilitySelect}
          >
            <title>{utilityName}</title>
            <g
              id="Design"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Design/Assets-_-00"
                transform="translate(-604.000000, -372.000000)"
              >
                <g
                  id="SLD/Production/Uniline/Undefined"
                  transform="translate(604.000000, 372.000000)"
                >
                  <g id="SLD/Production/Uniline/Tobedefine">
                    <g id="Group-7">
                      <rect
                        id="Rectangle"
                        x="0"
                        y="0"
                        width="96"
                        height="144"
                      ></rect>
                      {/* <line
                        x1="48.5"
                        y1="96"
                        x2="48.5"
                        y2="210"
                        id="Line-2"
                        stroke-width="4"
                        stroke-dasharray="5 2"
                        stroke={utility.configured ? "#333333" : "#E47F00"}
                        stroke-linejoin="round"
                      ></line> */}
                      <circle
                        id="Oval"
                        style={utility.selected ? {} : { display: "none" }}
                        stroke="#42B4E6"
                        stroke-width="3"
                        cx="48"
                        cy="56"
                        r="55"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        fill="rgba(0, 135, 205, 0.2)"
                      ></circle>
                      <circle
                        id="Oval"
                        stroke-width="3"
                        fill="#FFFFFF"
                        cx="48"
                        cy="56"
                        r="46.5"
                        stroke={utility.configured ? "#333333" : "#E47F00"}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></circle>
                      <text
                        id="segment_energy_infra"
                        font-family="se-icon"
                        font-size="56"
                        font-weight="normal"
                        fill="#333333"
                      >
                        <tspan x="20" y="76">
                          segment_energy_infrastructure
                        </tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <line
            x1={utility.x1pos}
            y1={utility.y1pos}
            x2={utility.x2pos}
            y2={utility.y2pos}
            id="Line-2"
            stroke-width="4"
            stroke-dasharray="6 8"
            stroke-linecap="round"
            stroke={utility.configured ? "#333333" : "#E47F00"}
          ></line>
        </g>
      </svg>,
    ];
  }
}

export default SLDUtility;
