import React, { useContext } from "react";

import LocationFinder from "mg-ui-location";
import ProjectUsers from "./ProjectUsers";
import { InformationPageContext } from "../CustomHooks/ContextApi";

import embLabels from "../../config/emb-labels-en.json";

const LANGUAGE = "en";
const CUSTOM_LABELS = LocationFinder.getLabelsForLanguage(LANGUAGE);

const SiteInformation = () => {
  const {
    UserScope,
    siteType,
    siteInformation,
    locationData,
    mandatoryMsgDisplay,
    renderLocationComponent,
    handlSiteInformationChange,
    locationCallback,
    disableValidateProject,
    onBackButtonClick,
    onValidateClick,
  } = useContext(InformationPageContext);
  return (
    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background">
      <div className="row">
        <div
          className={`col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-3 ${
            UserScope.access.saveProjects ? "" : "se-block-disabled "
          }`}
        >
          <se-icon color="primary">building_flat</se-icon>
          <span className="ml-2 se-font-14px-bold se-life-green">
            Site Information
          </span>
          <div className="dropdown-divider"></div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <div className="form-group row">
                <label
                  htmlFor="inputEmail3"
                  className="col-sm-2 col-form-label text-right"
                >
                  Local User Interface <span className="se-mandatory">*</span>
                </label>
                <div className="col-sm-10">
                  <select
                    className="custom-select"
                    name="userInterface"
                    value={siteInformation.userInterface}
                    onChange={handlSiteInformationChange}
                  >
                    {siteType !== embLabels.GRID_CONNECTED && (
                      <option value="12">12" HMI Screen</option>
                    )}
                    <option value="none">None</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputEmail10"
                  className="col-sm-4 col-form-label text-right"
                >
                  Grid nominal frequency (Hz){" "}
                  <span className="se-mandatory">*</span>
                </label>
                <div className="col-sm-8">
                  <select
                    className="custom-select"
                    name="gridNominalFrequency"
                    value={siteInformation.gridNominalFrequency}
                    onChange={handlSiteInformationChange}
                  >
                    <option value="50">50 Hz</option>
                    <option value="60">60 Hz</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputEmail11"
                  className="col-sm-3 col-form-label text-right"
                >
                  Grid nominal voltage (V)
                  <span className="se-mandatory">*</span>
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    autoComplete="none"
                    className="form-control form-control-md"
                    name="gridNominalVoltage"
                    placeholder="Grid nominal voltage (V)"
                    maxLength="3"
                    value={siteInformation.gridNominalVoltage}
                    onChange={handlSiteInformationChange}
                  />
                  <div
                    className="invalid-feedback-custom"
                    style={
                      siteInformation.gridNominalVoltage !== ""
                        ? { display: "none" }
                        : {}
                    }
                  >
                    Grid nominal voltage(V) is required.
                  </div>
                  {siteInformation.gridNominalVoltage !== "" &&
                    (parseInt(siteInformation.gridNominalVoltage) < 100 ||
                      parseInt(siteInformation.gridNominalVoltage) > 700) && (
                      <div className="invalid-feedback-custom">
                        Grid nominal voltage should be in range(100-700 V).
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <span className="se-font-14px-bold">Address</span>
            </div>
          </div>

          {renderLocationComponent && (
            <LocationFinder
              locationData={locationData}
              locationDataCallback={locationCallback}
              mandatoryMsgDisplay={mandatoryMsgDisplay}
              customLabels={CUSTOM_LABELS}
            />
          )}
        </div>
      </div>
      <ProjectUsers />

      {/* //*validation */}

      <div className="row mt-3">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12  text-right">
          <se-button option="outline" onClick={onBackButtonClick}>
            Cancel
          </se-button>

          <se-button
            option="flat"
            color="primary"
            disabled={UserScope.access.saveProjects ? false : true}
            onClick={!disableValidateProject ? onValidateClick : undefined}
          >
            Validate
          </se-button>
        </div>
      </div>
    </div>
  );
};

export default SiteInformation;
