import React, { useContext } from "react";
import sld from "../../images/sld/Rectangle.svg";
import Rosette from "../../components/GRIDCONNECTED/Rosette";
import Utility from "../../components/GRIDCONNECTED/Utility";
import Genset from "../../components/GRIDCONNECTED/Genset";
import PV from "../../components/GRIDCONNECTED/PV";
import CHP from "../../components/GRIDCONNECTED/CHP";
import Battery from "../../components/GRIDCONNECTED/Battery";
import EV from "../../components/GRIDCONNECTED/EV";
import Load from "../../components/GRIDCONNECTED/Load";

import { DesignAssetContext } from "../CustomHooks/ContextApi";

const GridConnectedRosette = () => {
  const { CBAssetList, CBLoadList, CBEVList, sldLoadCompleted } =
    useContext(DesignAssetContext);
  return (
    <div
      id="SLD-Image"
      className="col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0"
    >
      <svg viewBox="0 0 1300 800">
        <g>
          <svg x="0" y="0" viewBox="0 0 1300 800">
            <g id="grid">
              <svg>
                <image href={sld} x="0" y="0" />
              </svg>
            </g>

            <g
              id="components-sld"
              style={sldLoadCompleted ? {} : { display: "none" }}
            >
              <Rosette />
              <Utility />
              {CBAssetList[1].uiData.status && <Genset />}
              {CBAssetList[2].uiData.status && <Battery />}
              {CBAssetList[3].uiData.status && <PV />}
              {CBAssetList[6].uiData.status && <CHP />}

              {CBLoadList.map((loadData, key) => (
                <Load
                  key={loadData.uiData.xAxis}
                  load={loadData.uiData}
                  loadName={loadData.configData.name}
                  index={key}
                />
              ))}
              {CBEVList.map(
                (cbData, key) =>
                  cbData.uiData.status && (
                    <EV
                      key={cbData.configData.name}
                      index={key}
                      status={cbData.uiData.status}
                    />
                  )
              )}
            </g>
          </svg>
        </g>
      </svg>
    </div>
  );
};

export default GridConnectedRosette;
