import React from "react";

const Linebar = () => {
  return (
    <svg>
      <g id="linebar">
        <g id="lines">
          <line
            x1="310"
            y1="260"
            x2="696"
            y2="260"
            stroke="#333333"
            strokeWidth="3"
          />
          <line
            x1="696"
            y1="258.5"
            x2="695"
            y2="430"
            stroke="#333333"
            strokeWidth="3"
          />
          <line
            x1="250.5"
            y1="170"
            x2="250.5"
            y2="200"
            stroke="#333333"
            strokeWidth="3"
          />
          <line
            x1="372"
            y1="170"
            x2="372"
            y2="200"
            stroke="#333333"
            strokeWidth="3"
          />
          <line
            x1="251"
            y1="200"
            x2="310"
            y2="230"
            stroke="#333333"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <line
            x1="310"
            y1="230"
            x2="310"
            y2="272"
            stroke="#333333"
            strokeWidth="3"
          />
          <rect
            id="Rectangle-Copy-24"
            stroke="#333333"
            fill="none"
            strokeWidth="3"
            x="230"
            y="188"
            width="160"
            height="60"
            rx="20"
          />

          <svg
            width="75px"
            height="90px"
            viewBox="0 0 10 149"
            x="269.5"
            y="270"
          >
            <g id="curved line">
              <line
                y2="25.5"
                x2="10"
                y1="2.5"
                x1="10"
                strokeWidth="4"
                stroke="#000"
                fill="none"
              />
              <path
                d="m10.5,54.0922l1,30"
                strokeWidth="4"
                stroke="#000"
                fill="none"
              />
              <path
                d="m10.11208,24.50709c15.60284,21.98582 0.70922,31.20567 0.70922,31.20567"
                strokeWidth="4"
                stroke="#000"
                fill="none"
              />
              <line
                y2="105"
                x2="11.5"
                y1="84"
                x1="11.5"
                strokeWidth="4"
                stroke="#000"
                fill="none"
              />
            </g>
          </svg>
        </g>
      </g>
    </svg>
  );
};

export default Linebar;
