import React, { Component } from "react";

class SLDBattery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOverData: false,
    };
  }
  onCBMouseOver() {
    this.setState({ mouseOverData: !this.state.mouseOverData });
  }

  render() {
    const { battery, onBatterySelect, batteryName } = this.props;
    return [
      <svg
        className="pointer"
        style={battery.status ? {} : { display: "none" }}
      >
        <g id="battery">
          <svg
            x={battery.xAxis}
            y={battery.yAxis}
            // x="700" //690 // 675
            // y="300" // 245 // 200
            width="195px"
            height="130px"
            viewBox="0 0 10 149"
            onClick={onBatterySelect}
          >
            <title>{batteryName}</title>
            <g id="Design" fill="none" fill-rule="evenodd">
              <g
                id="Design/Assets-_-00"
                transform="translate(-892.000000, -372.000000)"
              >
                <g
                  id="SLD/Production/Uniline/Tobedefine"
                  transform="translate(892.000000, 372.000000)"
                >
                  <g id="Group-7">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="0"
                      width="200"
                      height="200"
                    ></rect>

                    <circle
                      id="Oval"
                      stroke="#42B4E6"
                      stroke-width="3"
                      cx="48"
                      cy="56"
                      r="55"
                      style={battery.selected ? {} : { display: "none" }}
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      fill="rgba(0, 135, 205, 0.2)"
                    ></circle>
                    <circle
                      id="Oval"
                      stroke-width="3"
                      fill="#FFFFFF"
                      cx="48"
                      cy="55"
                      r="46.5"
                      stroke={battery.configured ? "#333" : "#E47F00"}
                    ></circle>
                    <text
                      id="segment_energy_infra"
                      font-family="se-icon"
                      stroke={battery.configured ? "#333" : "#333"}
                      font-size="52"
                      font-weight="normal"
                      fill={battery.configured ? "#333" : "#333"}
                    >
                      <tspan x="22" y="76">
                        battery2_general
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <line
            x1={battery.x1pos}
            y1={battery.y1pos}
            x2={battery.x2pos}
            y2={battery.y2pos}
            id="Line-2"
            stroke-width="4"
            stroke-dasharray="6 8"
            stroke-linecap="round"
            stroke={battery.configured ? "#333333" : "#E47F00"}
          ></line>
        </g>
      </svg>,
    ];
  }
}

export default SLDBattery;
