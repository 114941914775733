import React, { Component } from "react";
import APISESSION from "../api/ApiSession";
import ReactAutocomplete from "react-autocomplete";

const apiSession = new APISESSION();

class Analytics extends Component {
  constructor(props) {
    super(props);
    let userSampleData = {
      name: "",
      role: "",
      access: {},
    };
    this.state = {
      userScope:
        JSON.parse(localStorage.getItem("scope")) !== null
          ? JSON.parse(localStorage.getItem("scope"))
          : userSampleData,
      type: "",
      message: "",
      loading: false,
      menuStyle: {
        borderRadius: "3px",
        boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
        background: "#ffffff",
        padding: "2px 0",
        fontSize: "14px",
        position: "fixed",
        overflow: "auto",
        maxHeight: "50%", // TODO: don't cheat, let it flow to the bottom
        zIndex: "1000",
      },
      activeSearchText: "",
      users: [],
      usersSearch: [],
      selectedUserId: "",
      auditDetails: {},
      clearCountConfirmPopUpStatus: false,
      selectedProjectId: "",
      analyticsData: {},
      resetProjects: [],
    };
  }
  componentDidMount() {
    this.listUsers();
    this.getAnalytics();
  }

  getAnalytics() {
    this.setState({ loading: true });
    apiSession
      .getAnalytics()
      .then(response => {
        this.setState({ loading: false });
        this.setState({
          analyticsData: response,
          resetProjects: response.plcreset,
        });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  }

  listUsers() {
    this.setState({ loading: true });
    apiSession
      .getUsers()
      .then(response => {
        let activeUsers = [];
        response.map(user => {
          if (user.status === "A") {
            let userData = {
              label: user.name + "-" + user.email,
              value: user.userId,
            };
            activeUsers.push(userData);
          }
          return null;
        });
        this.setState({
          users: activeUsers,
        });

        this.setState({
          usersSearch: activeUsers,
        });

        this.setState({ loading: false });
      })
      .catch(error => {
        this.setState({ loading: false });
        console.log(error);
      });
  }

  onChangeActiveSearch = e => {
    let eventValue = e.target.value.replace(/\s+/g, " ");
    this.setState({ activeSearchText: eventValue });
    if (eventValue.length >= 4) {
      this.setState({ usersSearch: this.state.users }, () => {
        const filteredUsers = this.state.usersSearch.filter(user => {
          return (
            user.label.toLowerCase().indexOf(eventValue.toLowerCase()) !== -1
          );
        });
        this.setState({ usersSearch: filteredUsers });
      });
    } else if (eventValue === "") {
      this.setState({ usersSearch: this.state.users });
    } else {
      this.setState({ usersSearch: this.state.users });
    }
  };

  onSelectActiveSearch(value) {
    this.setState({ loading: true });
    this.setState({ activeSearchText: value });
    let searchResultIn = this.state.usersSearch.find(
      searchDataObj => searchDataObj.label === value
    );

    this.getAuditDetails(searchResultIn.value);
  }

  resetUserSearch(e) {
    this.setState({ activeSearchText: "" });
    this.setState({ usersSearch: [] });
  }

  getAuditDetails(userId) {
    this.setState({ loading: true });
    apiSession
      .getAuditByUser(userId)
      .then(response => {
        this.setState({ auditDetails: response });
        this.resetUserSearch();
        this.setState({ loading: false });
        this.setState({ selectedUserId: userId });
      })
      .catch(error => {
        this.resetUserSearch();
        this.setState({ loading: false });
        this.callSnackbar("error", "Error in loading user history");
        this.setState({ selectedUserId: "" });
      });
  }

  clearPLCByProject(projectId) {
    this.setState({ loading: true });
    apiSession
      .resetPLC({ projectId: projectId })
      .then(response => {
        this.setState({ selectedProjectId: "" });
        this.getAnalytics();
        if (this.state.selectedUserId) {
          this.getAuditDetails(this.state.selectedUserId);
        }

        this.callSnackbar("success", "Cleared project PLC count successfully");
      })
      .catch(error => {
        this.setState({ loading: false });
        this.setState({ selectedProjectId: "" });
        this.callSnackbar("error", "Error in clear PLC count");
      });
  }

  clearPLCAll() {
    let projectId = 0;
    this.setState({ loading: true });
    apiSession
      .resetPLC({ projectId: projectId })
      .then(response => {
        this.getAnalytics();
        if (this.state.selectedUserId) {
          this.getAuditDetails(this.state.selectedUserId);
        }
        this.callSnackbar("success", "Cleared PLC count successfully");
      })
      .catch(error => {
        this.setState({ loading: false });
        this.callSnackbar("error", "Error in clear PLC count");
      });
  }

  closeClearCountPopUp() {
    this.setState({ clearCountConfirmPopUpStatus: false });
    this.setState({ selectedProjectId: "" });
  }

  openClearCountPopUpByProject(projectId) {
    this.setState({ clearCountConfirmPopUpStatus: true });
    this.setState({ selectedProjectId: projectId });
  }

  openClearCountPopUp() {
    this.setState({ clearCountConfirmPopUpStatus: true });
  }

  clearCountConfirm() {
    this.setState({ clearCountConfirmPopUpStatus: false });
    if (this.state.selectedProjectId === "") {
      this.clearPLCAll();
    } else {
      this.clearPLCByProject(this.state.selectedProjectId);
    }
  }

  callSnackbar = (errorType, Body) => {
    this.setState({
      type: errorType,
      message: Body,
    });
    document.getElementById("snackbar-custom").open = true;
  };

  render() {
    return (
      <div className="mt-2 col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background pb-2 analytics-container-min-height">
        <se-loading loading={this.state.loading}></se-loading>

        <div className="col-md-12 mt-2">
          <div className="row pt-2">
            <div className="col-md-12">
              <span className="se-life-green se-font-14px-bold ">
                Current Status
              </span>
              <table className="table table-bordered mt-2">
                <tbody>
                  <tr>
                    <td>Active Users</td>
                    <td>{this.state.analyticsData.activeUsers}</td>
                    <td>Logged-In Users</td>
                    <td>{this.state.analyticsData.loggedInCount}</td>
                  </tr>

                  <tr>
                    <td>Invited Users</td>
                    <td>{this.state.analyticsData.invitedUsers}</td>
                    <td>Deleted Users</td>
                    <td>{this.state.analyticsData.deletedUsers}</td>
                  </tr>

                  <tr>
                    <td>Invitation cancelled Users</td>
                    <td>{this.state.analyticsData.cancelUsers}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row pt-2">
            <div className="col-md-12">
              <span className="se-life-green se-font-14px-bold ">
                Reset PLC count:{" "}
              </span>
              {this.state.resetProjects.length !== 0 ? (
                <span className="float-right">
                  <se-button
                    option="raised"
                    size="small"
                    color="primary"
                    icon="data_refresh_stroke"
                    onClick={e => this.openClearCountPopUp()}
                  >
                    Clear All Count
                  </se-button>
                </span>
              ) : (
                <span> No projects to reset PLC count</span>
              )}
            </div>
            {this.state.resetProjects.length !== 0 ? (
              <div className="col-md-12 analytics-table-height">
                <table className="table table-bordered mt-2">
                  <tbody>
                    {this.state.resetProjects.map(plcdata => (
                      <tr>
                        <td>
                          <b>Project Name</b>
                        </td>
                        <td>{plcdata.projectName}</td>
                        <td>
                          <b>Last Modification</b>
                        </td>
                        <td>{plcdata.lastModificationDate}</td>
                        <td>
                          <se-button
                            icon="data_refresh_stroke"
                            title="Clear count"
                            size="nano"
                            onClick={e =>
                              this.openClearCountPopUpByProject(
                                plcdata.projectId
                              )
                            }
                            icon-only
                          ></se-button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <se-divider></se-divider>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-2 mt-2">
              <span className="se-life-green se-font-14px-bold ">
                Search By Users :
              </span>
            </div>
            <div className="col-md-8 mt-2 my-auto">
              <ReactAutocomplete
                items={this.state.usersSearch}
                // shouldItemRender={(item, value) => item.label.toLowerCase().indexOf(value.toLowerCase()) > -1}
                getItemValue={item => item.label}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.id}
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                  >
                    {item.label}
                  </div>
                )}
                value={this.state.activeSearchText}
                menuStyle={this.state.menuStyle}
                wrapperProps={{
                  className: "col-sm-12",
                }}
                inputProps={{
                  className: "form-control",
                }}
                onChange={e => this.onChangeActiveSearch(e)}
                onSelect={value => this.onSelectActiveSearch(value)}
              />
            </div>
            {this.state.activeSearchText !== "" ? (
              <div className="col-sm-2 pl-0 my-auto">
                <span
                  className="pointer"
                  onClick={e => this.resetUserSearch(e)}
                >
                  <se-icon size="medium" color="primary">
                    action_delete_cross
                  </se-icon>
                </span>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="row mt-2">
            <div className="col-md-12">
              <se-divider></se-divider>
            </div>
            {this.state.selectedUserId === "" ? (
              <div className="col-md-12 mt-2 text-center se-life-green se-font-14px-bold">
                Select User to get Details
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {this.state.selectedUserId !== "" ? (
          <div className="col-md-12 mt-2">
            {/* <div className="row">
                        <div className="col-md-12 mt-2">
                            <span className="se-life-green se-font-14px-bold ">User Login Details:</span>
                        </div>
                    </div> */}
            <div className="row">
              <div className="col-md-6">
                <span className="se-life-green se-font-14px-bold ">
                  User Details
                </span>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>Name</td>
                      <td>
                        {this.state.auditDetails.firstName +
                          " " +
                          this.state.auditDetails.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td>Role</td>
                      <td>{this.state.auditDetails.role}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>{this.state.auditDetails.email}</td>
                    </tr>
                    <tr>
                      <td>Employer</td>
                      <td>{this.state.auditDetails.employer}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-6">
                <span className="se-life-green se-font-14px-bold ">
                  Login Details
                </span>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>First login</td>
                      <td>{this.state.auditDetails.firstLogin}</td>
                    </tr>
                    <tr>
                      <td>Last Login</td>
                      <td>{this.state.auditDetails.lastLogin}</td>
                    </tr>
                    <tr>
                      <td>Invited On</td>
                      <td>{this.state.auditDetails.invitedOn}</td>
                    </tr>
                    <tr>
                      <td>Invited By</td>
                      <td>
                        {this.state.auditDetails.invitedBy.firstName +
                          " " +
                          this.state.auditDetails.invitedBy.lastName}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 no-events">
                <span className="se-life-green se-font-14px-bold ">
                  Connected Projects :
                </span>
                {this.state.auditDetails.connectedProjects.map(project => (
                  <se-chip
                    value={project.projectName}
                    color="standard"
                    size="nano"
                  ></se-chip>
                ))}
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12 analytics-table-height">
                <span className="se-life-green se-font-14px-bold ">
                  Role History :
                </span>
                <table className="table table-bordered">
                  <tbody>
                    {this.state.auditDetails.roleHistory.map(roleHistory => (
                      <tr>
                        <td>
                          <b>From Role</b>
                        </td>
                        <td>{roleHistory.fromRole}</td>
                        <td>
                          <b>To Role</b>
                        </td>
                        <td>{roleHistory.toRole}</td>
                        <td>
                          <b>Changed On</b>
                        </td>
                        <td>{roleHistory.changedOn}</td>
                        <td>
                          <b>Changed By</b>
                        </td>
                        <td>
                          {roleHistory.changedBy.firstName +
                            " " +
                            roleHistory.changedBy.lastName}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <span className="se-life-green se-font-14px-bold ">
                  PLC package downloaded projects
                </span>
              </div>
              <div className="col-md-12 analytics-table-height">
                {/* {
                                    _.isEmpty(this.state.auditDetails.plcreset) === true ?
                                        "" :
                                        <span className="float-right">
                                            <se-button option="raised" size="small" color="primary" icon="data_refresh_stroke"
                                                onClick={(e) => this.openClearCountPopUp()} >Clear All Count</se-button>
                                        </span>
                                } */}
                <table className="table table-bordered">
                  <tbody>
                    {this.state.auditDetails.downloadCounter.PLC.details.map(
                      projects => (
                        <tr>
                          <td>
                            <b>Project Name</b>
                          </td>
                          <td>{projects.project.projectName}</td>
                          <td>
                            <b>Modification Date</b>
                          </td>
                          <td>{projects.project.lastModificationDate}</td>
                          <td>
                            <b>created On </b>
                          </td>
                          <td>{projects.project.createdOn}</td>
                          <td>
                            <b>downloaded On</b>
                          </td>
                          <td>{projects.downloadedOn}</td>
                          <td>
                            <b>Version</b>
                          </td>
                          <td>{projects.version}</td>
                          {/* <td>
                                                { this.state.auditDetails.plcreset[projects.project.projectId] !== undefined ?
                                                <se-button icon="data_refresh_stroke" title="Clear count" onClick={(e) => this.openClearCountPopUpByProject(projects.project.projectId)} icon-only></se-button>
                                                 : ""

                                                }
                                                </td> */}
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <span className="se-life-green se-font-14px-bold ">
                  IPC package downloaded projects
                </span>
              </div>

              <div className="col-md-12 analytics-table-height">
                <table className="table table-bordered">
                  <tbody>
                    {this.state.auditDetails.downloadCounter.IPC.details.map(
                      projects => (
                        <tr>
                          <td>
                            <b>Project Name</b>
                          </td>
                          <td>{projects.project.projectName}</td>
                          <td>
                            <b>Modification Date</b>
                          </td>
                          <td>{projects.project.lastModificationDate}</td>
                          <td>
                            <b>created On </b>
                          </td>
                          <td>{projects.project.createdOn}</td>
                          <td>
                            <b>downloaded On</b>
                          </td>
                          <td>{projects.downloadedOn}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <span className="se-life-green se-font-14px-bold ">
                  HMI package downloaded projects{" "}
                </span>
              </div>
              <div className="col-md-12 analytics-table-height">
                <table className="table table-bordered">
                  <tbody>
                    {this.state.auditDetails.downloadCounter.HMI.details.map(
                      projects => (
                        <tr>
                          <td>
                            <b>Project Name</b>
                          </td>
                          <td>{projects.project.projectName}</td>
                          <td>
                            <b>Modification Date</b>
                          </td>
                          <td>{projects.project.lastModificationDate}</td>
                          <td>
                            <b>created On </b>
                          </td>
                          <td>{projects.project.createdOn}</td>
                          <td>
                            <b>downloaded On</b>
                          </td>
                          <td>{projects.downloadedOn}</td>
                          <td>
                            <b>Version</b>
                          </td>
                          <td>{projects.version}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12 ">
                <span className="se-life-green se-font-14px-bold ">
                  Visited Project :
                </span>
                <div className="col-md-12 analytics-table-height">
                  <table className="table table-bordered">
                    <tbody>
                      {this.state.auditDetails.visitedProjects.map(projects => (
                        <tr>
                          <td>
                            <b>Project Name</b>
                          </td>
                          <td>{projects.projectName}</td>
                          <td>
                            <b>Modification Date</b>
                          </td>
                          <td>{projects.lastModificationDate}</td>
                          <td>
                            <b>created On </b>
                          </td>
                          <td>{projects.createdOn}</td>
                          <td>
                            <b>Visited On </b>
                          </td>
                          <td>{projects.visitedOn}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <span className="se-life-green se-font-14px-bold ">
                  Modified Project :
                </span>
                <div className="col-md-12 analytics-table-height">
                  <table className="table table-bordered">
                    <tbody>
                      {this.state.auditDetails.modifiedProjects.map(
                        projects => (
                          <tr>
                            <td>
                              <b>Project Name</b>
                            </td>
                            <td>{projects.projectName}</td>
                            <td>
                              <b>Modification Date</b>
                            </td>
                            <td>{projects.lastModificationDate}</td>
                            <td>
                              <b>created On </b>
                            </td>
                            <td>{projects.createdOn}</td>
                            <td>
                              <b>created By </b>
                            </td>
                            <td>
                              {projects.createdBy.firstName}&nbsp;
                              {projects.createdBy.lastName}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <se-dialog
          id="dialog-complex-delete-confirm"
          open={this.state.clearCountConfirmPopUpStatus}
          can-backdrop="false"
        >
          <se-dialog-header>Confirmation</se-dialog-header>
          <se-dialog-content>
            <span className="se-font-14px">
              Do you confirm to clear count ?
            </span>
          </se-dialog-content>
          <se-dialog-footer>
            <se-button onClick={this.closeClearCountPopUp.bind(this)}>
              Cancel
            </se-button>
            <se-button onClick={this.clearCountConfirm.bind(this)}>
              OK
            </se-button>
          </se-dialog-footer>
        </se-dialog>

        <se-snackbar
          id="snackbar-custom"
          type={this.state.type}
          icon="information_stroke"
          message={this.state.message}
          duration="3000"
        ></se-snackbar>
      </div>
    );
  }
}
export default Analytics;
