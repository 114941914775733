import React from "react";

const sldBusBar = ({ totalDer }) => (
  <svg>
    <g id="busbar">
      <line
        x1="426"
        y1="360"
        x2={totalDer === 4 ? "930" : "815"}
        y2="360"
        stroke="#333333"
        stroke-width="3"
      />
    </g>
  </svg>
);

export default sldBusBar;
