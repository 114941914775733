import React from "react";

const PlcPackage = ({
  UserScope,
  plcTagNew,
  protectionKeyList,
  selectedFilePLC,
  loading,
  fileVersion,
  fileChangedHandlerPLC,
  uploadPlcFile,
  updatePLCVersion,
  versionUpdatePLC,
}) => {
  const isTouchDevice = () =>
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
      ? true
      : false;

  return (
    <div className="col-md-12 mt-2">
      <div className="row">
        <div className="col-md-12 mt-2">
          <span className="se-life-green se-font-14px-bold ">
            M251 PLC Package
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mt-2">
          <se-divider></se-divider>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-md-8">
          <div className="form-group row">
            <label htmlFor="inputEmail4" className="col-md-4 col-form-label">
              M251 PLC Zip File <span className="se-mandatory">*</span>
              <se-tooltip
                action={isTouchDevice() ? "click" : "hover"}
                position="right"
              >
                <se-icon
                  option="raised"
                  color="secondary"
                  size="small"
                  slot="tooltip"
                >
                  information_circle
                </se-icon>
                <se-tooltip-content>
                  PLC package always zip file
                </se-tooltip-content>
              </se-tooltip>
            </label>
            <div className="col-md-6">
              <input
                type="file"
                accept="application/zip"
                onChange={fileChangedHandlerPLC}
                className="form-control-file form-control-sm fileNameDisplay"
                name="file"
              />
            </div>
          </div>
        </div>

        {UserScope.access.uploadFile && (
          <div className="col-md-2">
            <se-button
              option="flat"
              color="primary"
              disabled={selectedFilePLC === null || loading ? true : false}
              onClick={selectedFilePLC !== null ? uploadPlcFile : undefined}
            >
              PLC Upload
            </se-button>
          </div>
        )}

        <div className="col-md-2">
          <div className="form-group row">
            <label htmlFor="inputEmail4" className="col-md-12 col-form-label">
              Last uploaded: &nbsp;{plcTagNew}
            </label>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mt-2">
          <se-divider></se-divider>
        </div>
      </div>
      <div className="row mt-1">
        {protectionKeyList.length > 0 && (
          <div className="col-md-8">
            <div className="form-group row">
              <label className="col-md-3 my-auto col-form-label">
                EcoStruxure Machine Expert version
              </label>
              <div className="col-md-6 my-auto">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="plcFileVersion"
                  value={fileVersion.plcFileVersion}
                  maxLength="25"
                  onChange={updatePLCVersion}
                />
              </div>
              <div className="col-md-3 my-auto pointer">
                <se-icon
                  size="medium"
                  color="primary"
                  title="Click to update"
                  onClick={
                    fileVersion.plcFileVersion !== ""
                      ? versionUpdatePLC
                      : undefined
                  }
                  disabled={fileVersion.plcFileVersion === "" ? true : false}
                >
                  notification_ok_stroke
                </se-icon>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PlcPackage;
