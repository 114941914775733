import React, { Component } from 'react';
import Auth from './Auth';
import ApiSession from '../api/ApiSession';

const auth = new Auth();

class AuthCallback extends Component {
  
  componentDidMount() {
    let { history } = this.props;
    auth.handleAuthentication(this.props)
      .then(() => {
        let apiSession = new ApiSession();
        apiSession.getScope()
          .then(response => {
            let scopeList = {};
            let scopeListAccess = {};
            scopeList.role = response.role;
            scopeList.name = response.name;
            response.scope.map((scopeData) => {
              scopeListAccess[scopeData] = true;
              return null;
            });
            localStorage.setItem("pname", null);
            scopeList.access = scopeListAccess;
            localStorage.setItem("scope", JSON.stringify(scopeList));
            history.push({
              pathname: '/project'
            });
          })
          .catch(error => {
            if (error.response !== undefined) {
              if (error.response.status === 404) {
                history.push({
                  pathname: '/nonregistered'
                });

              } else {
                history.push({
                  pathname: '/'
                });

              }
            }
            else {
              history.push({
                pathname: '/'
              });
            }

          })

      })
      .catch(() => {
        history.push({
          pathname: '/'
        });
      })
  }
  render() {
    return (
      <se-loading loading="true" />
    )
  }
}

export default AuthCallback;