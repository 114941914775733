import React, { useContext } from "react";

import isTouchDevice from "../SE-Component/Tooltip";
import embLabels from "../../config/emb-labels-en.json";
import { InformationPageContext } from "../CustomHooks/ContextApi";

const MicrogridType = () => {
  const { projectType, informationToolTIP, handleProjectTypeChange } =
    useContext(InformationPageContext);
  return (
    <>
      <div className="row">
        <div className="col-md-12 mt-1">
          <span className="labelCircle mr-2 ">1</span>{" "}
          <span>Select your project type</span>
          <se-tooltip
            action={isTouchDevice() ? "click" : "hover"}
            position="right"
          >
            <se-icon
              option="raised"
              color="secondary"
              size="small"
              slot="tooltip"
            >
              information_circle
            </se-icon>
            <se-tooltip-content>Select your project type</se-tooltip-content>
          </se-tooltip>
        </div>
      </div>
      <div className="row is-flex mt-2">
        <div className="col-md-4">
          <div
            className={`col-md-12 ${
              projectType === embLabels.STANDARD_MICROGRID
                ? "border-green-radius-5px"
                : "border-radius-5px"
            }`}
          >
            <div className="row align-items-center">
              <div className="col-md-2">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadio1"
                      name="projectType"
                      className="custom-control-input islandableRadio asCompatible"
                      value="STANDARD_MICROGRID"
                      checked={
                        projectType === embLabels.STANDARD_MICROGRID
                          ? true
                          : false
                      }
                      onChange={handleProjectTypeChange}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadio1"
                    >
                      &zwnj;
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-10 p-2">
                <div className="row">
                  <div className="col-md-12 mt-1">
                    <span className="bold">Design your own Microgrid</span>
                  </div>
                  <div className="col-md-12 mt-1">
                    <span>"{informationToolTIP.standardToolTipData}"</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div
            className={`col-md-12 ${
              projectType === embLabels.PACKAGED_MICROGRID
                ? "border-green-radius-5px"
                : "border-radius-5px"
            }`}
          >
            <div className="row align-items-center">
              <div className="col-md-2 mt-3">
                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      id="customRadio2"
                      name="projectType"
                      className="custom-control-input islandableRadio asCompatible"
                      value="PACKAGED_MICROGRID"
                      checked={
                        projectType === embLabels.PACKAGED_MICROGRID
                          ? true
                          : false
                      }
                      onChange={handleProjectTypeChange}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customRadio2"
                    >
                      &zwnj;
                    </label>
                  </div>
                </div>
              </div>

              <div className="col-md-10 p-2">
                <div className="row">
                  <div className="col-md-12 mt-1">
                    <span className="bold">Use pre-configured solutions</span>
                  </div>
                  <div className="col-md-12 mt-1">
                    <span>"{informationToolTIP.packagedToolTipData}"</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MicrogridType;
