import React from "react";

const HmiPackage = ({
  UserScope,
  selectedFileHMI,
  hmiTagNew,
  protectionKeyList,
  fileVersion,
  fileChangedHandlerHMI,
  uploadHmiFile,
  updateHmiVersion,
  versionUpdateHMI,
}) => {
  const isTouchDevice = () =>
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
      ? true
      : false;

  return (
    <div className="col-md-12 mt-2">
      <div className="row">
        <div className="col-md-12 mt-2">
          <span className="se-life-green se-font-14px-bold ">
            Vijeo HMI Package
          </span>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mt-2">
          <se-divider></se-divider>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-md-8">
          <div className="form-group row">
            <label htmlFor="inputEmail4" className="col-md-4 col-form-label">
              Vijeo HMI Zip File <span className="se-mandatory">*</span>
              <se-tooltip
                action={isTouchDevice() ? "click" : "hover"}
                position="right"
              >
                <se-icon
                  option="raised"
                  color="secondary"
                  size="small"
                  slot="tooltip"
                >
                  information_circle
                </se-icon>
                <se-tooltip-content>
                  Vijeo HMI package always zip file
                </se-tooltip-content>
              </se-tooltip>
            </label>
            <div className="col-md-6">
              <input
                type="file"
                onChange={fileChangedHandlerHMI}
                className="form-control-file form-control-sm fileNameDisplay"
                name="file"
              />
            </div>
          </div>
        </div>

        {UserScope.access.uploadFile && (
          <div className="col-md-2">
            <se-button
              option="flat"
              color="primary"
              disabled={selectedFileHMI === null ? true : false}
              onClick={selectedFileHMI !== null ? uploadHmiFile : undefined}
            >
              HMI Upload
            </se-button>
          </div>
        )}

        <div className="col-md-2">
          <div className="form-group row">
            <label htmlFor="inputEmail4" className="col-md-12 col-form-label">
              Last uploaded: &nbsp;{hmiTagNew}
            </label>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-2">
          <se-divider></se-divider>
        </div>
      </div>
      <div className="row mt-1">
        {protectionKeyList.length > 0 && (
          <div className="col-md-8">
            <div className="form-group row">
              <label className="col-md-3 my-auto col-form-label">
                Vijeo SCADA version
              </label>
              <div className="col-md-6 my-auto">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="hmiFileVersion"
                  value={fileVersion.hmiFileVersion}
                  maxLength="25"
                  onChange={updateHmiVersion}
                />
              </div>
              <div className="col-md-3 my-auto pointer">
                <se-icon
                  size="medium"
                  color="primary"
                  title="Click to update"
                  onClick={
                    fileVersion.hmiFileVersion !== ""
                      ? versionUpdateHMI
                      : undefined
                  }
                  disabled={fileVersion.hmiFileVersion === "" ? true : false}
                >
                  notification_ok_stroke
                </se-icon>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HmiPackage;
