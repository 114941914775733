import React from "react";
import isTouchDevice from "../SE-Component/Tooltip";

const OperationUser = ({
  siteGroup,
  UserScope,
  siteUsers,
  handleChangeSiteGroup,
  addUserSite,
  removeSiteUserConfirmationPrompt,
}) => {
  return (
    <>
      <div className="row se-white-background">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-2">
          <div className="form-group row mb-2">
            <label
              htmlFor="sitegroup"
              className="col-sm-2 col-form-label col-form-label-sm se-font-14px"
            >
              <span className="se-font-14px-bold se-life-green">
                Site Group{" "}
              </span>
              <span className="se-mandatory">*</span>
              <se-tooltip
                action={isTouchDevice() ? "click" : "hover"}
                position="right"
              >
                <se-icon option="raised" color="secondary" slot="tooltip">
                  information_circle
                </se-icon>
                <se-tooltip-content>
                  Connect your site to Site Group in EMA.
                </se-tooltip-content>
              </se-tooltip>
            </label>
            <div className="col-sm-4">
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-sm"
                name="siteGroup"
                placeholder="Site Group "
                maxLength="50"
                value={siteGroup}
                onChange={handleChangeSiteGroup}
              />
              {siteGroup === "" && (
                <div className="invalid-feedback-custom">
                  Site Group is required.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-12">
          <span className="se-font-14px-bold se-life-green ">
            Operation - User Role
          </span>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th> Email Address</th>
                <th> Status</th>
                <th> Invited on</th>
                <th width="10%" className="pt-0 pb-0 text-center">
                  <se-button
                    option="raised"
                    size="small"
                    color="primary"
                    icon="action_add_flat"
                    disabled={
                      UserScope.access.saveProgram === true ? false : true
                    }
                    onClick={addUserSite}
                  >
                    Invite user
                  </se-button>
                </th>
              </tr>
            </thead>

            <tbody>
              {siteUsers.map(userData => (
                <tr key={userData.id}>
                  <td>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left pl-0 textEllipsis">
                      <span title={userData.emailId}> {userData.emailId} </span>
                    </div>
                  </td>
                  <td>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left pl-0 textEllipsis ">
                      <span>{userData.status}</span>
                    </div>
                  </td>
                  <td>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-left pl-0 textEllipsis">
                      <span title={userData.invitedOn}>
                        {" "}
                        {userData.invitedOn}{" "}
                      </span>
                    </div>
                  </td>
                  <td width="10%">
                    {userData.status ===
                    "EMA web access invited successfully" ? (
                      <span className="pointer">
                        <se-icon
                          size="small"
                          title="Delete"
                          color="primary"
                          onClick={() =>
                            removeSiteUserConfirmationPrompt(userData.id)
                          }
                        >
                          action_delete
                        </se-icon>
                      </span>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default OperationUser;
