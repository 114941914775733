import React, { Component } from "react";

class SLDEv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOverData: false,
    };
  }
  onCBMouseOver() {
    this.setState({ mouseOverData: !this.state.mouseOverData });
  }

  render() {
    const { ev, onEvSelect, evName } = this.props;
    return [
      <svg className="pointer" style={ev.status ? {} : { display: "none" }}>
        <g id="ev">
          <svg
            x={ev.xAxis}
            y={ev.yAxis}
            width="149px"
            height="475px"
            viewBox="0 0 90 550"
            onClick={onEvSelect}
          >
            <title>{evName}</title>
            <g
              id="Design"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Design/Assets-_-00"
                transform="translate(-604.000000, -372.000000)"
              >
                <g
                  id="SLD/Production/Uniline/Undefined"
                  transform="translate(604.000000, 372.000000)"
                >
                  <g id="SLD/Production/Uniline/Tobedefine">
                    <g id="Group-7">
                      <rect
                        id="Rectangle"
                        x="0"
                        y="95"
                        width="96"
                        height="148"
                      ></rect>

                      <circle
                        id="Oval"
                        stroke="#42B4E6"
                        stroke-width="3"
                        cx="48"
                        cy="193.5"
                        r="55"
                        style={ev.selected ? {} : { display: "none" }}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        fill="rgba(0, 135, 205, 0.2)"
                      ></circle>

                      <circle
                        id="Oval"
                        stroke-width="3"
                        fill="#FFFFFF"
                        cx="48"
                        cy="193.5"
                        r="46.5"
                        stroke={ev.configured ? "#333333" : "#E47F00"}
                      ></circle>

                      <text
                        id="segment_energy_infra"
                        font-family="se-icon"
                        font-size="56"
                        font-weight="normal"
                        fill="#333333"
                      >
                        <tspan x="20" y="210">
                          electric_vehicle_terminal
                        </tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
          <line
            x1={ev.x1pos}
            y1={ev.y1pos}
            x2={ev.x2pos}
            y2={ev.y2pos}
            id="Line-2"
            stroke-width="4"
            stroke-dasharray="6 8"
            stroke-linecap="round"
            stroke={ev.configured ? "#333333" : "#E47F00"}
          ></line>
        </g>
      </svg>,
    ];
  }
}

export default SLDEv;
