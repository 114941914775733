import ACTIONS from "../Design_Asset_Actions.json";

const initialCBLoadList = [
  {
    modbusSlaveId: "21",
    configData: {
      name: "",
      description: "",
      maximum_available_power: 0,
    },
    uiData: {
      status: false,
      configured: false,
      CBSelected: false,
      selected: false,
      CBConfigured: true,
      xAxisCB: 205,
      yAxisCB: 290,
      xAxis: 160,
      yAxis: 292,
      detailsBlock: false,
    },
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
    },
  },
];

const CBLoadListReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ASSIGN_DEFAULT_VALUES:
      return assignDefaultValuesCBLoadList(state, action.payload.defaultValues);
    case ACTIONS.ASSIGN_USECASE_LOAD:
      return assignUseCaseLoad(state);
    case ACTIONS.UPDATE_CBLOADLIST_COORDINATES:
      return updateCbLoadListCoordinates(
        state,
        action.payload.coordinates,
        action.payload.count
      );
    case ACTIONS.UPDATE_CBLOADLIST_COORDINATES_GRIDCONNECTED:
      return updateCbLoadListCoordinatesGridConnected(
        state,
        action.payload.index,
        action.payload.positions,
        action.payload.petal
      );
    case ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVHYBRIDIZATION:
      return updateCbLoadListCoordinatesPvHybridization(state);
    case ACTIONS.UPDATE_CBLOADLIST_COORDINATES_PVGENSET_ATS:
      return updateCbLoadListCoordinatesPvGensetAts(state);
    case ACTIONS.UPDATE_LOAD:
      return updateLoad(
        state,
        action.payload.componentsData,
        action.payload.loadIndex
      );
    case ACTIONS.UPDATE_LOAD_CB:
      return updateLoadCb(
        state,
        action.payload.componentsData,
        action.payload.loadCBIndex
      );
    case ACTIONS.CLOSEDETAILS_LOAD:
      return closeDetailsLoad(state);
    case ACTIONS.LOAD_SELECT:
      return loadSelect(state, action.payload.index);
    case ACTIONS.LOAD_CB_SELECT:
      return loadCbSelect(state, action.payload.index);
    case ACTIONS.UPDATE_LOAD_CONFIG:
      return updateLoadConfig(
        state,
        action.payload.selectedLoad,
        action.payload.configData
      );
    case ACTIONS.DUPLICATE_LOAD_UPDATE:
      return duplicateLoadUpdate(state, action.payload.selectedLoad);
    case ACTIONS.UPDATE_LOAD_CB_CONFIG:
      return updateLoadCbConfig(
        state,
        action.payload.selectedLoadCB,
        action.payload.cbConfigData
      );
    case ACTIONS.ADD_LOAD:
      return addLoad(
        state,
        action.payload.defaultPreData,
        action.payload.count
      );
    case ACTIONS.REMOVE_LOAD:
      return removeLoad(state, action.payload.selectedLoad);
    default:
      return state;
  }
};

const assignDefaultValuesCBLoadList = (CBLoadList, defaultValues) => {
  const loadList = [...CBLoadList];
  const circuitBreakersModbusSlaveIdList = defaultValues["Circuit Breaker"];
  loadList[0].configData.name = `${defaultValues.Load.name} 1`;
  loadList[0].configData.maximum_available_power = 0;
  loadList[0].cbConfigData.name = `${defaultValues["Circuit Breaker"].name}_${defaultValues.Load.name} 1`;
  loadList[0].uiData.CBSelected = false;
  loadList[0].cbConfigData.motorized = true;
  loadList[0].modbusSlaveId =
    circuitBreakersModbusSlaveIdList["modbusId_CB_50"];
  //return new state
  return loadList;
};
const assignUseCaseLoad = CBLoadList => {
  const loadList = [...CBLoadList];

  loadList[0].uiData.status = true;
  loadList[0].uiData.configured = false;
  loadList[0].uiData.CBConfigured = true;

  //return new state
  return loadList;
};

const updateCbLoadListCoordinates = (CBLoadList, coordinates, count) => {
  let xLoad = coordinates.xLoad;
  let xLoadCB = coordinates.xLoadCB;
  let xLoadNew = coordinates.xLoadNew;
  let xLoadCBNew = coordinates.xLoadCBNew;
  let yLoad = 292;
  //  const totalLoads = count.loadCount + count.evCount;
  let newLoadData = [];
  let loadList = [...CBLoadList];

  loadList.map((cbLoadData, key) => {
    if (key) {
      xLoad = coordinates.loadDiff + xLoad;
      xLoadCB = coordinates.loadDiff + xLoadCB;
      if (key > 11) yLoad = 377;

      if ((key === 11 && xLoad !== 1110) || xLoad === 1220) {
        xLoad = xLoadNew;
        xLoadCB = xLoadCBNew;
        yLoad = 377;
      }
    }

    cbLoadData.uiData.xAxis = xLoad;
    cbLoadData.uiData.yAxis = yLoad;
    cbLoadData.uiData.xAxisCB = xLoadCB;
    cbLoadData.uiData.yAxisCB = 290;
    newLoadData.push(cbLoadData);
    return null;
  });

  return newLoadData;
};

const updateCbLoadListCoordinatesGridConnected = (
  CBLoadList,
  index,
  positions,
  petal
) => {
  let loadList = [...CBLoadList];
  const [x1pos, y1pos, x2pos, y2pos] = positions;
  const [xAxis, yAxis] = petal;

  loadList[index].uiData.xAxis = xAxis;
  loadList[index].uiData.yAxis = yAxis;
  loadList[index].uiData.x1pos = x1pos;
  loadList[index].uiData.y1pos = y1pos;
  loadList[index].uiData.x2pos = x2pos;
  loadList[index].uiData.y2pos = y2pos;

  return loadList;
};

const updateCbLoadListCoordinatesPvHybridization = CBLoadList => {
  let loadList = [...CBLoadList];

  loadList[0].uiData.xAxis = "398";
  loadList[0].uiData.yAxis = "366";
  loadList[0].uiData.xAxisCB = "442.5";
  loadList[0].uiData.yAxisCB = "360";

  return loadList;
};

const updateCbLoadListCoordinatesPvGensetAts = CBLoadList => {
  let loadList = [...CBLoadList];

  loadList[0].uiData.xAxis = "139";
  loadList[0].uiData.yAxis = "345";
  loadList[0].uiData.xAxisCB = "183.5";
  loadList[0].uiData.yAxisCB = "336";

  return loadList;
};

const updateLoad = (CBLoadList, componentsData, loadIndex) => {
  const attributes = JSON.parse(componentsData.attributes);
  let newLoadData = [...CBLoadList];
  if (loadIndex === 0) {
    newLoadData[0].uiData = JSON.parse(componentsData.uiAttributes);
    newLoadData[0].uiData.selected = false;
    newLoadData[0].uiData.CBSelected = false;
    newLoadData[0].configData = {};
    newLoadData[0].cbConfigData = {};
    newLoadData[0].configData.name = componentsData.componentName;
    newLoadData[0].configData.description = attributes.description;
    newLoadData[0].configData.maximum_available_power =
      attributes.maximum_available_power;
    newLoadData[0].assetType = componentsData.componentType;
  } else {
    let loads = {};
    loads.uiData = JSON.parse(componentsData.uiAttributes);
    loads.uiData.selected = false;
    loads.uiData.CBSelected = false;
    loads.configData = {};
    loads.cbConfigData = {};
    loads.configData.name = componentsData.componentName;
    loads.configData.description = attributes.description;
    loads.configData.maximum_available_power =
      attributes.maximum_available_power;
    loads.assetType = componentsData.componentType;
    newLoadData.push(loads);
  }
  return newLoadData;
};

const updateLoadCb = (CBLoadList, componentsData, loadCBIndex) => {
  const CBLoadListUpdated = [...CBLoadList];
  const attributes = JSON.parse(componentsData.attributes);
  if (componentsData.communications[0]) {
    CBLoadListUpdated[loadCBIndex].modbusSlaveId =
      componentsData.communications[0].modbusSlaveId;
  }
  CBLoadListUpdated[loadCBIndex].cbConfigData.description =
    attributes.description;
  CBLoadListUpdated[loadCBIndex].cbConfigData.name =
    componentsData.componentName;
  CBLoadListUpdated[loadCBIndex].cbConfigData.motorized =
    parseInt(attributes.motorized) === 1 ? true : false;
  CBLoadListUpdated[loadCBIndex].cbConfigData.loadCategory = parseInt(
    attributes.loadCategory
  );
  CBLoadListUpdated[loadCBIndex].cbConfigData.loadPriority = parseInt(
    attributes.loadPriority
  );

  return CBLoadListUpdated;
};

const closeDetailsLoad = CBLoadList => {
  const newCBLoadList = [...CBLoadList];
  newCBLoadList.forEach(load => {
    load.uiData.selected = false;
    load.uiData.CBSelected = false;
    load.uiData.detailsBlock = false;
  });
  return newCBLoadList;
};

const loadSelect = (CBLoadList, index) => {
  const newCBLoadList = [...CBLoadList];
  newCBLoadList[index].uiData.selected = true;
  return newCBLoadList;
};

const loadCbSelect = (CBLoadList, index) => {
  const newCBLoadList = [...CBLoadList];
  newCBLoadList[index].uiData.CBSelected = true;
  return newCBLoadList;
};

const updateLoadConfig = (CBLoadList, selectedLoad, configData) => {
  const cbDataList = [...CBLoadList];
  cbDataList[selectedLoad].configData = configData;
  cbDataList[selectedLoad].uiData.configured = true;

  return CBLoadList;
};
const duplicateLoadUpdate = (CBLoadList, selectedLoad) => {
  const cbDataList = [...CBLoadList];
  cbDataList[selectedLoad].uiData.configured = false;

  return CBLoadList;
};

const updateLoadCbConfig = (CBLoadList, selectedLoadCB, cbConfigData) => {
  const cbDataList = [...CBLoadList];
  cbDataList[selectedLoadCB].cbConfigData = cbConfigData;
  cbDataList[selectedLoadCB].uiData.CBConfigured = true;

  return CBLoadList;
};

const addLoad = (CBLoadList, defaultPreData, count) => {
  const newLoadData = [...CBLoadList];
  const totalLoads = count.loadCount + count.derCount + count.evCount;

  let newLoad = {
    assetConnected: "Load",
    modbusSlaveId: "21",
    configData: {
      name: "",
      description: "",
      maximum_available_power: 0,
    },
    uiData: {
      status: true,
      CBSelected: false,
      configured: false,
      CBConfigured: true,
      yAxisCB: 290,
      yAxis: 292,
    },
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
    },
  };
  newLoadData.push(newLoad);

  let cBMBData = defaultPreData["Circuit Breaker"];
  let cbIndex = 50;
  let loadIndex = 1;

  newLoadData.map((loadData, key) => {
    newLoadData[key].modbusSlaveId =
      totalLoads % 7 === 0 ? "255" : cBMBData["modbusId_CB_" + cbIndex];
    if (newLoadData[key].configData.name === "") {
      newLoadData[
        key
      ].configData.name = `${defaultPreData.Load.name} ${loadIndex}`;
    }
    if (newLoadData[key].cbConfigData.name === "") {
      newLoadData[
        key
      ].cbConfigData.name = `${defaultPreData["Circuit Breaker"].name}_${defaultPreData.Load.name} ${loadIndex}`;
    }
    cbIndex = cbIndex + 1;
    if (cbIndex === 60) {
      cbIndex = 61;
    }
    if (totalLoads % 7 === 0) {
      cbIndex = cbIndex + 1;
    }
    loadIndex = loadIndex + 1;
    return null;
  });
  let index = newLoadData.length - 1;
  newLoadData[index].uiData.status = true;
  newLoadData[index].uiData.configured = false;
  newLoadData[index].uiData.CBConfigured = true;

  return newLoadData;
};

const removeLoad = (CBLoadList, selectedLoad) => {
  const removeLoadData = [...CBLoadList];

  const newLoadData = removeLoadData.filter(
    (load, index) => index !== selectedLoad
  );

  // let modbusSlaveID = 20;
  // let count = 0;

  // newLoadData.forEach((loadData, key) => {
  //   count++;
  //   switch (true) {
  //     case count > 0 && count <= 10:
  //       modbusSlaveID = modbusSlaveID + 1;
  //       break;

  //     case count === 11:
  //       modbusSlaveID = 255;
  //       break;

  //     case count > 11 && count <= 16:
  //       modbusSlaveID = modbusSlaveID === 255 ? 1 : modbusSlaveID;
  //       modbusSlaveID = modbusSlaveID + 1;
  //       break;

  //     case count > 16 && count <= 22:
  //       modbusSlaveID = modbusSlaveID === 6 ? 20 : modbusSlaveID;
  //       modbusSlaveID = modbusSlaveID + 1;
  //       break;
  //     default:
  //       break;
  //   }
  //   newLoadData[key].modbusSlaveId = modbusSlaveID;
  // });
  return newLoadData;
};

export { initialCBLoadList, CBLoadListReducer };
