import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

const PV = () => {
  const {
    CBAssetList: [, , , { uiData }],
    pv1SelectHandler,
    padNumberFormat,
    PVDetails,
  } = useContext(DesignAssetContext);

  return (
    <svg className="pointer">
      <g id="pv1">
        <svg
          x={uiData.xAxis}
          y={uiData.yAxis}
          width="195px"
          height="130px"
          viewBox="0 0 10 149"
          onClick={pv1SelectHandler}
        >
          <title>
            {PVDetails.pvset1Data[0] ? PVDetails.pvset1Data[0].groupName : ""}
          </title>
          <g
            id="Design"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="Design/Assets-_-00"
              transform="translate(-1036.000000, -372.000000)"
            >
              <g
                id="SLD/PV/Uniline/Multiple_Done"
                transform="translate(1036.000000, 372.000000)"
              >
                <circle
                  id="Oval"
                  stroke="#42B4E6"
                  strokeWidth="3"
                  cx="48"
                  cy="56"
                  r="55"
                  style={uiData.selected ? {} : { display: "none" }}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="rgba(0, 135, 205, 0.2)"
                ></circle>

                <circle
                  id="Oval"
                  stroke={uiData.configured ? "#333333" : "#E47F00"}
                  strokeWidth="3"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  cx="57"
                  cy="59"
                  r="43.5"
                ></circle>
                <circle
                  id="Oval"
                  stroke={uiData.configured ? "#333333" : "#E47F00"}
                  strokeWidth="3"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  cx="48"
                  cy="55"
                  r="46.5"
                ></circle>
                <circle
                  id="Oval"
                  stroke={uiData.configured ? "#333333" : "#E47F00"}
                  strokeWidth="3"
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  cx="48"
                  cy="55"
                  r="46.5"
                ></circle>
                <g
                  id="999🔒Atoms/Notifications/Done"
                  transform="translate(66.000000, 2.000000)"
                >
                  <g
                    id="999🔒Atoms/Background/Round"
                    strokeWidth="1"
                    fillRule="evenodd"
                    fill={uiData.configured ? "#333333" : "#E47F00"}
                    stroke="#FFFFFF"
                  >
                    <circle
                      id="Oval"
                      strokeWidth="2.66666667"
                      cx="16"
                      cy="16"
                      r="18"
                    ></circle>
                  </g>

                  <text
                    id="3"
                    fontFamily="Nunito-Regular, Nunito"
                    fontSize="24"
                    fontWeight="normal"
                    fill="#FFFFFF"
                  >
                    <tspan x="2" y="24">
                      {padNumberFormat(uiData.count)}
                    </tspan>
                  </text>
                </g>
                <text
                  id="solar_panel1"
                  fontFamily="se-icon"
                  fontSize="56"
                  fontWeight="normal"
                  fill="#333333"
                >
                  <tspan x="20" y="69">
                    solar_panel1
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </svg>
        <line
          x1={uiData.x1pos}
          y1={uiData.y1pos}
          x2={uiData.x2pos}
          y2={uiData.y2pos}
          id="Line-2"
          strokeWidth="4"
          strokeDasharray="6 8"
          strokeLinecap="round"
          stroke={uiData.configured ? "#333333" : "#E47F00"}
          // strokeLinejoin="round"
        ></line>
      </g>
    </svg>
  );
};

export default PV;
