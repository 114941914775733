import React from "react";

import embLabels from "../config/emb-labels-en.json";
import ACTIONS from "../pages/DesignAssets/Design_Asset_Actions.json";

const AddPvModals = ({
  CBAssetList,
  siteType,
  clientType,
  architectureType,
  PVDetails,
  onPvDetailsDispatch,
  onAddPVSetWithCB,
}) => {
  return (
    <se-dialog
      id="dialog-complex-add-Pv"
      size="small"
      open={PVDetails.addPvShowDialog}
      can-backdrop="false"
    >
      <se-dialog-header>
        <span className="se-dialog-custom-header">Add PhotoVoltaic DER</span>
      </se-dialog-header>
      <se-dialog-content>
        <div className="container">
          <div className="row">
            <div className="col-md col-lg col-sm m-0 mb-2 ml-2">
              <div className="oval">
                <span className="text-badge">1</span>
              </div>
              <span className="label-text ml-2">Circuit Breaker Choice</span>
            </div>
          </div>

          <div className="row">
            <div className="col-md col-lg col-sm m-0 mb-2">
              <se-form-field
                label="Do you want to add your photovoltaic DER on :"
                required="true"
                option="stacked"
              >
                <div
                  className="custom-control custom-radio inline"
                  style={
                    !CBAssetList[3].uiData.status &&
                    !CBAssetList[4].uiData.status &&
                    !CBAssetList[5].uiData.status
                      ? { display: "none" }
                      : {}
                  }
                >
                  <input
                    type="radio"
                    id="customRadio1"
                    name="newOrExist"
                    className="custom-control-input asCompatible"
                    value="exist"
                    checked={PVDetails.existOrNew === "exist"}
                    onChange={e =>
                      onPvDetailsDispatch({
                        type: ACTIONS.EXIST_NEW_BRANCH_SELECT,
                        payload: {
                          data: e.target,
                        },
                      })
                    }
                  />
                  <label
                    className="custom-control-label padding-top-4"
                    htmlFor="customRadio1"
                  >
                    Existing Circuit Breaker Branch
                  </label>
                </div>
                <div
                  className="custom-control custom-radio"
                  style={
                    (CBAssetList[3].uiData.status &&
                      (siteType === embLabels.GRID_CONNECTED ||
                        siteType === embLabels.PV_HYBRIDIZATION)) ||
                    (clientType === embLabels.GREENSTRUXURE_PROJECT &&
                      siteType === embLabels.ISLANDABLE_GSX &&
                      architectureType === embLabels.PV_GENSET_ATS)
                      ? { display: "none" }
                      : {}
                  }
                >
                  <input
                    type="radio"
                    id="customRadio2"
                    name="newOrExist"
                    className="custom-control-input asCompatible"
                    value="new"
                    checked={PVDetails.existOrNew === "new"}
                    onChange={e =>
                      onPvDetailsDispatch({
                        type: ACTIONS.EXIST_NEW_BRANCH_SELECT,
                        payload: {
                          data: e.target,
                        },
                      })
                    }
                  />
                  <label
                    className="custom-control-label padding-top-4"
                    htmlFor="customRadio2"
                  >
                    New Circuit Breaker Branch
                  </label>
                </div>
              </se-form-field>
            </div>
          </div>

          <div className="row">
            <div
              className="col-md col-lg col-sm m-0 ml-2 mb-2"
              style={
                siteType === embLabels.GRID_CONNECTED ||
                siteType === embLabels.PV_HYBRIDIZATION ||
                (!CBAssetList[3].uiData.status &&
                  !CBAssetList[4].uiData.status &&
                  !CBAssetList[5].uiData.status) ||
                (clientType === embLabels.GREENSTRUXURE_PROJECT &&
                  siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS)
                  ? { display: "none" }
                  : {}
              }
            >
              <select
                className="form-control form-control-sm"
                name="branchName"
                style={
                  PVDetails.existOrNew === "exist" ? {} : { display: "none" }
                }
                value={PVDetails.branchName}
                onChange={e =>
                  onPvDetailsDispatch({
                    type: ACTIONS.EXISTING_BRANCH_CHANGE,
                    payload: {
                      data: e.target,
                    },
                  })
                }
              >
                {CBAssetList[3].uiData.status ? (
                  <option value="3">Circuit Breaker Branch #1 </option>
                ) : (
                  []
                )}
                {CBAssetList[4].uiData.status ? (
                  <option value="4">Circuit Breaker Branch #2 </option>
                ) : (
                  []
                )}
                {CBAssetList[5].uiData.status ? (
                  <option value="5">Circuit Breaker Branch #3 </option>
                ) : (
                  []
                )}
              </select>
            </div>
          </div>

          <div className="row">
            <div className="col-md col-lg col-sm m-0 ml-2 mb-2">
              <div className="oval">
                <span className="text-badge">2</span>
              </div>
              <span className="label-text ml-2">PhotoVoltaic DER Quantity</span>
            </div>
          </div>

          <div className="row">
            <div className="col-md col-lg col-sm m-0 ml-2 mb-2">
              <span>How many photovoltaic DER do you want to add ?</span>
            </div>
          </div>

          <div className="row justify-content-md-center">
            <div className="col col-lg-2 mr-0 pr-0 pointer">
              <se-icon
                size="medium"
                onClick={() =>
                  onPvDetailsDispatch({
                    type: ACTIONS.REDUCE_PV_BY_ONE,
                  })
                }
              >
                action_remove_flat
              </se-icon>
            </div>
            <div className="col-md-auto pl-0 pr-0">
              <input
                type="text"
                autoComplete="off"
                className="form-control form-control-sm"
                id="inlineFormInput"
                placeholder="3"
                disabled
                value={PVDetails.pvCount}
              />
            </div>
            <div className="col col-lg-2 ml-0 pl-1 pointer">
              <se-icon
                size="medium"
                onClick={() =>
                  onPvDetailsDispatch({
                    type: ACTIONS.ADD_PV_BY_ONE,
                    payload: {
                      CBAssetList: CBAssetList,
                      siteType,
                      architectureType,
                    },
                  })
                }
              >
                action_add_flat
              </se-icon>
            </div>
          </div>

          <div className="row ">
            <div
              className="col-md-12"
              style={PVDetails.showHideAddPvToBranch ? {} : { display: "none" }}
            >
              <div className="row">
                <div className="col-md-1">
                  <se-icon size="small" color="error">
                    information_circle
                  </se-icon>
                </div>
                <div className="col-md-11">
                  {siteType === embLabels.ISLANDABLE_GSX &&
                  architectureType === embLabels.PV_GENSET_ATS ? (
                    <span className="error">
                      Max 15 photovoltaic can be added
                    </span>
                  ) : (
                    <span className="error">
                      Max 15 photovoltaic can be added
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-1">
                  <se-icon size="small" color="secondary">
                    information_circle
                  </se-icon>
                </div>
                <div className="col-md-11 mt-1">
                  {siteType === embLabels.GRID_CONNECTED ||
                  siteType === embLabels.PV_HYBRIDIZATION ? (
                    <span>You can add max 15 photovoltaic</span>
                  ) : siteType === embLabels.ISLANDABLE_GSX &&
                    architectureType === embLabels.PV_GENSET_ATS ? (
                    <span>You can add max 15 photovoltaic</span>
                  ) : (
                    <span>
                      You can add max 3 circuit breakers and 15 photovoltaic in
                      total
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </se-dialog-content>
      <se-dialog-footer>
        <se-button
          onClick={() => onPvDetailsDispatch({ type: ACTIONS.CLOSE_PV_MODAL })}
        >
          Cancel
        </se-button>
        <se-button
          onClick={onAddPVSetWithCB}
          disabled={PVDetails.pvCount ? false : true}
        >
          Add
        </se-button>
      </se-dialog-footer>
    </se-dialog>
  );
};

export default AddPvModals;
