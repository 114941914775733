import React, { Component, createRef } from "react";
import APISESSION from "../api/ApiSession";

const apiSession = new APISESSION();

class Contact extends Component {
  constructor(props) {
    super(props);
    let userSampleData = {
      name: "",
      role: "",
      access: {},
    };
    this.state = {
      body: "",
      subject: "",
      mandatoryMsgDisplaySubject: false,
      mandatoryMsgDisplayBody: false,
      userScope:
        JSON.parse(localStorage.getItem("scope")) !== null
          ? JSON.parse(localStorage.getItem("scope"))
          : userSampleData,
      snackbarType: "",
      snackbarMessage: "",
      loading: false,
      disableSend: false,

      newEmailRegion: "",
      selectedAtachments: [],
      allRegions: [],
    };

    this.clearInputFileRef = createRef();
  }

  componentDidMount() {
    localStorage.setItem("callGetRegion", true);
  }

  getRegions = () => {
    if (localStorage.getItem("callGetRegion") === "true") {
      localStorage.setItem("callGetRegion", false);
      this.getLatestRegions();
    }
  };

  getLatestRegions = () => {
    this.setState({ loading: true });
    apiSession
      .getRegions()
      .then(allRegions => {
        let newEmailRegion = "";
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timeRegion = timeZone.split("/")[0].toUpperCase();
        if (allRegions.includes(timeRegion)) {
          newEmailRegion = timeRegion;
        } else {
          if (allRegions.length) {
            newEmailRegion = allRegions[0];
          }
        }
        this.setState({ allRegions, newEmailRegion, loading: false });
      })
      .catch(() => {});
  };

  callSnackbar = (errorType, Body) => {
    this.setState({
      snackbarType: errorType,
      snackbarMessage: Body,
    });
    document.getElementById("snackbar-global").open = true;
  };
  handleEmailRegionChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    switch (e.target.name) {
      case "subject":
        this.setState({ mandatoryMsgDisplaySubject: true });
        break;
      case "body":
        this.setState({ mandatoryMsgDisplayBody: true });
        break;
      default:
        break;
    }
  }

  fileChangedHandle = e => {
    if (e.target.files.length) {
      const maxSize = Array.from(e.target.files).reduce(
        (acc, cur) => acc + cur.size,
        0
      );
      if (maxSize > 5120000) {
        this.callSnackbar("error", "File should be less than 5MB");
        e.target.value = null;
      } else {
        let selectedAtachments = [];
        for (let file of e.target.files) {
          selectedAtachments.push(file);
        }
        this.setState({
          selectedAtachments,
        });

        for (const file of e.target.files) {
          let reader = new FileReader();
          reader.onloadend = () => {};
          reader.readAsDataURL(file);
        }
      }
    }
  };
  sendQuery(e) {
    this.setState({ disableSend: true });
    if (
      this.state.subject.toString().trim() === "" ||
      this.state.body.toString().trim() === "" ||
      this.state.body.toString().length >= 500
    ) {
      this.setState({ disableSend: false });
      this.callSnackbar(
        "error",
        "Please fill the mandatory fields with conditions"
      );
    } else {
      this.setState({ loading: true });
      this.setState({ disableSend: true });
      this.sendConactDetails();
    }
  }

  sendConactDetails() {
    const fd = new FormData();
    fd.append("region", this.state.newEmailRegion);
    fd.append("subject", this.state.subject);
    fd.append("body", this.state.body);
    for (let file of this.state.selectedAtachments) {
      fd.append(`file`, file);
    }

    apiSession
      .sendContactAPI(fd)
      .then(response => {
        this.callSnackbar("success", "Query sent successfully");
        this.setState({ loading: false });
        this.setState({ disableSend: false });
        this.resetQuery();
      })
      .catch(error => {
        this.setState({ loading: false });
        this.setState({ disableSend: false });
        this.resetQuery();
        this.callSnackbar("error", "Error in sending query");
      });
  }

  resetQuery() {
    this.clearInputFileRef.current.value = null;
    this.setState({
      body: "",
      subject: "",
      mandatoryMsgDisplaySubject: false,
      mandatoryMsgDisplayBody: false,
      disableSend: false,
      selectedAtachments: [],
    });
  }

  render() {
    return (
      <div
        className="container se-grey-background se-contactus-page-height "
        onMouseMove={this.getRegions}
      >
        {/* </div>
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12  se-grey-background se-contactus-page-height"> */}
        <div className="row justify-content-md-center">
          {/* //justify-content-md-center */}
          <div className="col-md-7 se-contactus-border se-white-background se-contactus-margin-top-125">
            <se-loading loading={this.state.loading}></se-loading>
            <div className="row">
              <div className="col-md-12 se-contactus-header">
                <div className="col-md-12 pt-1">
                  <span className="se-contactus-heading">Contact Us </span>
                </div>
                <div className="col-md-12">
                  <span>Please fill form to drop us a mail </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 ">
                <div className="form-group mt-2">
                  <label className="col-sm-3 col-form-label">Region:</label>
                  <select
                    className="contact--email__change"
                    name="newEmailRegion"
                    value={this.state.newEmailRegion}
                    onChange={this.handleEmailRegionChange}
                  >
                    {this.state.allRegions.map(region => (
                      <option key={region}>{region}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  >
                    Subject<span className="se-mandatory">*</span>
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      autoComplete="off"
                      className="form-control form-control-sm"
                      name="subject"
                      placeholder="Subject"
                      value={this.state.subject}
                      maxLength="100"
                      onChange={e => this.handleChange(e)}
                    />
                    <div
                      className="invalid-feedback-custom"
                      style={
                        !this.state.subject &&
                        this.state.mandatoryMsgDisplaySubject
                          ? {}
                          : { display: "none" }
                      }
                    >
                      Subject is required.
                    </div>
                  </div>
                </div>

                <div className="form-group ">
                  <label
                    htmlFor="inputEmail3"
                    className="col-sm-3 col-form-label"
                  >
                    Message<span className="se-mandatory">*</span>
                  </label>
                  <div className="col-sm-12">
                    <textarea
                      className="form-control form-control-sm"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      value={this.state.body}
                      name="body"
                      placeholder="Enter the Query"
                      onChange={e => this.handleChange(e)}
                    ></textarea>

                    <div
                      className="invalid-feedback-custom"
                      style={
                        !this.state.body && this.state.mandatoryMsgDisplayBody
                          ? {}
                          : { display: "none" }
                      }
                    >
                      Message is required.
                    </div>

                    <div
                      className="invalid-feedback-custom"
                      style={
                        this.state.body.toString().length >= 500 &&
                        this.state.mandatoryMsgDisplayBody
                          ? {}
                          : { display: "none" }
                      }
                    >
                      Message letters should not exceed more than 500
                      Characters.
                    </div>
                  </div>
                </div>
                <div className="form-group ">
                  <label className="col-sm-3 col-form-label">Attach:</label>
                  {/* <form encType='multipart/form-data'></form> */}
                  <input
                    type="file"
                    onChange={this.fileChangedHandle}
                    className="form-control-file form-control-sm fileNameDisplay col-md-6"
                    name="files"
                    ref={this.clearInputFileRef}
                  />
                </div>

                <div className="form-group ">
                  <div className="col-sm-12 pt-0">
                    <span className="se-life-green">Regards,</span>
                    <br />
                    <span className="se-life-green">
                      {this.state.userScope.name}
                    </span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12  text-right">
                    <se-button
                      option="outline"
                      onClick={this.resetQuery.bind(this)}
                    >
                      Reset
                    </se-button>

                    <se-button
                      option="flat"
                      color="primary"
                      disabled={
                        this.state.subject.toString().trim() === "" ||
                        this.state.body.toString().trim() === "" ||
                        this.state.body.toString().length >= 500 ||
                        this.state.disableSend === true
                          ? true
                          : false
                      }
                      onClick={
                        this.state.subject.toString().trim() === "" ||
                        this.state.body.toString().trim() === "" ||
                        this.state.body.toString().length >= 500
                          ? undefined
                          : this.sendQuery.bind(this)
                      }
                    >
                      Send
                    </se-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <se-snackbar
          id="snackbar-global"
          type={this.state.snackbarType}
          icon="information_stroke"
          message={this.state.snackbarMessage}
          duration="3000"
        ></se-snackbar>
      </div>
    );
  }
}
export default Contact;
