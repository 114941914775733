import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

const SLDEcc = () => {
  const {
    count: { derCount },
  } = useContext(DesignAssetContext);
  return (
    <svg>
      {" "}
      <g id="ECC">
        {" "}
        <svg>
          {" "}
          <g
            id="ECC"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            {" "}
            <g id="ECC" transform="translate(-532.000000, -514.000000)">
              {" "}
              <g
                id="SLD/Infos/Microgrid_Background"
                transform="translate(532.000000, 514.000000)"
              >
                {" "}
                <rect
                  id="Rectangle-Copy-24"
                  stroke="#333333"
                  strokeWidth="3"
                  x="360"
                  y="269"
                  width={derCount === 4 ? "635" : "530"}
                  height="180"
                  rx="16"
                />{" "}
                <text
                  id="AAA"
                  fontFamily="Nunito-Regular, Nunito"
                  fontSize="18"
                  fontWeight="normal"
                  fill="#333333"
                >
                  {" "}
                  <tspan x="392" y="310">
                    Low Voltage{" "}
                  </tspan>
                  /{" "}
                  <tspan x="392" y="330">
                    Switchboard
                  </tspan>{" "}
                </text>{" "}
              </g>{" "}
            </g>{" "}
          </g>{" "}
        </svg>{" "}
      </g>{" "}
    </svg>
  );
};

export default SLDEcc;
