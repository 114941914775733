import React, { Component } from "react";

class SLDBusbar extends Component {
  render() {
    const { busbar } = this.props;
    return [
      <svg>
        <g id="busbar">
          <line
            x1="60"
            y1="290"
            x2={busbar.x2}
            y2="290"
            stroke="#333333"
            stroke-width="3"
          />
        </g>
      </svg>,
    ];
  }
}

export default SLDBusbar;
