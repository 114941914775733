import React, { useState } from "react";
import PropTypes from "prop-types";

import AccordionSection from "./AccordionSection";
import "./Accordion.scss";

const Accordion = props => {
  const [openSections, setOpenSections] = useState({});

  const onClick = label => {
    const { allowMultipleOpen } = props;
    const isOpen = !!openSections[label];

    if (allowMultipleOpen) {
      setOpenSections(prevState => ({
        ...prevState,
        [label]: !isOpen,
      }));
    } else {
      setOpenSections({ [label]: !isOpen });
    }
  };

  return (
    <div className="col-md-12 p-0">
      {props.children.map(child => (
        <AccordionSection
          key={child.props.label}
          isOpen={!!openSections[child.props.label]}
          label={child.props.label}
          icon={child.props.icon}
          displayStatus={child.props.displayStatus}
          status={child.props.status}
          onClick={onClick}
          innerClick={child.props.enableTemplatePopUp}
        >
          {child.props.children}
        </AccordionSection>
      ))}
    </div>
  );
};

export default Accordion;

Accordion.propTypes = {
  allowMultipleOpen: PropTypes.bool,
  children: PropTypes.instanceOf(Object),
};
