import React, { useContext } from "react";

import MicrogridType from "./MicrogridType";
import GenericSiteType from "./GenericSiteType";
import GSXSiteType from "./GSXSiteType";
import ArchitectureType from "./ArchitectureType";
import SelectUseCase from "./SelectUseCase";
import SelectUseCaseGSX from "./SelectUseCaseGSX";
import RemoteMonitoring from "./RemoteMonitoring";

import { InformationPageContext } from "../CustomHooks/ContextApi";

import embLabels from "../../config/emb-labels-en.json";

const ProjectType = () => {
  const { UserScope, siteType, architectureType, findProjectTypeHandler } =
    useContext(InformationPageContext);
  return (
    <div
      className={` col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background ${
        UserScope.access.saveProjects ? "" : "se-block-disabled"
      }`}
    >
      <se-icon color="primary">product_selector</se-icon>
      <span className="ml-2 se-font-14px-bold se-life-green">
        Topology & Use Cases
      </span>
      <div className="dropdown-divider"></div>

      {findProjectTypeHandler() === embLabels.GENERIC_PROJECT && (
        <>
          <MicrogridType />
          <GenericSiteType />
          {siteType && <RemoteMonitoring />}
          {siteType && <SelectUseCase />}
        </>
      )}

      {findProjectTypeHandler() === embLabels.GREENSTRUXURE_PROJECT && (
        <>
          <GSXSiteType />
          <ArchitectureType />
          {architectureType && <RemoteMonitoring />}
          {architectureType && <SelectUseCaseGSX />}
        </>
      )}
    </div>
  );
};

export default ProjectType;
