import React, { useContext } from "react";
import { DesignProgramContext } from "../CustomHooks/ContextApi";
import isTouchDevice from "../SE-Component/Tooltip";

import embLabels from "../../config/emb-labels-en.json";

const GensetOptimization = () => {
  const {
    siteType,
    architectureType,
    gensetDetails,
    gensetLoad,
    maxGensetLoad,
    batteryDetails,
    pvCurtailment,
    gensetLSIEnable,
    handleRadioButtonChange,
    handleGensetOptimizationConfirm,
    configuredStatusModified,
    handleChange,
    handleCheckBoxChange,
    handlepPVOpeningPopup,
  } = useContext(DesignProgramContext);

  const gensetBackfeedProtectionStatusChangeHandlerYes = () => {
    handleGensetOptimizationConfirm();
    configuredStatusModified("gofpConfiguredStatus");
  };

  const gensetBackfeedProtectionStatusChangeHandlerNo = e => {
    handleRadioButtonChange(e);
    configuredStatusModified("gofpConfiguredStatus");
  };

  const gensetLoadChangeHandler = e => {
    handleChange(e);
    configuredStatusModified("gofpConfiguredStatus");
  };

  const optimizationFunctionChangeHandler = e => {
    handleCheckBoxChange(e);
    configuredStatusModified("gofpConfiguredStatus");
  };

  const gensetLSIChangeHandler = e => {
    handlepPVOpeningPopup(e);
    configuredStatusModified("gofpConfiguredStatus");
  };

  return (
    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-1">
      <div className="dropdown-divider"></div>
      <div className="row">
        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-2 bold">
          Genset optimization & backfeed protection
        </div>
        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 mb-2 ">
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="customRadioG1"
              name="gensetBackfeedProtectionStatus"
              className="custom-control-input islandableRadio asCompatible"
              value="Y"
              checked={gensetDetails.gensetBackfeedProtectionStatus === "Y"}
              onChange={gensetBackfeedProtectionStatusChangeHandlerYes}
            />
            <label
              className="custom-control-label line-height-25px"
              htmlFor="customRadioG1"
            >
              Yes
            </label>
          </div>
          <div className="custom-control custom-radio custom-control-inline">
            <input
              type="radio"
              id="customRadioG2"
              name="gensetBackfeedProtectionStatus"
              className="custom-control-input islandableRadio asCompatible"
              value="N"
              checked={gensetDetails.gensetBackfeedProtectionStatus === "N"}
              onChange={gensetBackfeedProtectionStatusChangeHandlerNo}
            />
            <label
              className="custom-control-label line-height-25px"
              htmlFor="customRadioG2"
            >
              No
            </label>
          </div>
        </div>
      </div>

      {gensetDetails.gensetBackfeedProtectionStatus === "Y" && (
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
            <div className="form-group row">
              <label
                htmlFor="inputEmail11"
                className="col-sm-3 col-form-label line-height-25"
              >
                Targeted Min Genset load (kW)
                <span className="se-mandatory">*</span>
                <se-tooltip
                  action={isTouchDevice() ? "click" : "hover"}
                  position="right"
                >
                  <se-icon option="raised" color="secondary" slot="tooltip">
                    information_circle
                  </se-icon>
                  <se-tooltip-content>
                    For the optimum use Genset make sure that the loading value
                    of your genset, i.e targeted minimum loading(kw) value is
                    10-30% of Maximum Genset loading value, to be confirmed by
                    the genset manufacturer
                  </se-tooltip-content>
                </se-tooltip>
              </label>

              <div className="col-sm-5">
                <input
                  type="text"
                  autoComplete="none"
                  className="form-control form-control-md"
                  name="gensetLoad"
                  placeholder="Targeted Min Genset load (kW)"
                  maxLength="6"
                  value={gensetLoad}
                  onChange={gensetLoadChangeHandler}
                />

                {gensetLoad.toString() === "" ? (
                  <div className="invalid-feedback-custom">
                    Targeted Min Genset load (kW) is required.
                  </div>
                ) : (parseFloat(gensetLoad) / parseFloat(maxGensetLoad)) * 100 >
                    100.0 ||
                  (parseFloat(gensetLoad) / parseFloat(maxGensetLoad)) * 100 <
                    10.0 ? (
                  <div className="invalid-feedback-custom">
                    Targeted Min Genset load should be in range of 10% to 100%
                    of Max Power(kW) .
                  </div>
                ) : (
                  ""
                )}
              </div>
              <label className="col-sm-4 col-form-label line-height-25">
                <span>
                  ({" "}
                  {(parseFloat(gensetLoad) / parseFloat(maxGensetLoad)) * 100 >=
                  0
                    ? Math.round(
                        (parseFloat(gensetLoad) / parseFloat(maxGensetLoad)) *
                          100
                      )
                    : "0"}
                  % of max power {maxGensetLoad} kW )
                </span>
              </label>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
            <span className="bold">
              Select optimization and protection functions
            </span>
          </div>

          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input asCompatible"
                name="batteryBuffering"
                id="customControlValidation4"
                checked={batteryDetails.batteryBuffering}
                disabled={batteryDetails.batteryBufferingStatus ? false : true}
                onChange={optimizationFunctionChangeHandler}
              />
              <label
                className="custom-control-label line-height-25"
                htmlFor="customControlValidation4"
              >
                Battery buffering
              </label>
              <se-tooltip
                action={
                  [embLabels.ISLANDABLE, embLabels.GRID_CONNECTED].includes(
                    siteType
                  )
                    ? isTouchDevice()
                      ? "click"
                      : "hover"
                    : ""
                }
                position="right"
              >
                <se-icon
                  option="raised"
                  color="secondary"
                  slot="tooltip"
                  disabled={
                    siteType === embLabels.PV_HYBRIDIZATION ||
                    (siteType === embLabels.ISLANDABLE_GSX &&
                      architectureType === embLabels.PV_GENSET_ATS)
                      ? true
                      : false
                  }
                >
                  information_circle
                </se-icon>
                <se-tooltip-content>
                  Charge the battery with excess of PV production
                </se-tooltip-content>
              </se-tooltip>
            </div>
          </div>

          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input asCompatible"
                name="pvCurtailment"
                id="customControlValidation2"
                value={pvCurtailment}
                checked={pvCurtailment}
                onChange={optimizationFunctionChangeHandler}
              />
              <label
                className="custom-control-label line-height-25"
                htmlFor="customControlValidation2"
              >
                PV curtailment
              </label>
              <se-tooltip
                action={isTouchDevice() ? "click" : "hover"}
                position="right"
              >
                <se-icon option="raised" color="secondary" slot="tooltip">
                  information_circle
                </se-icon>
                <se-tooltip-content>
                  Curtails PV when excess of PV production and no battery or
                  battery full
                </se-tooltip-content>
              </se-tooltip>
            </div>
          </div>

          <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input asCompatible"
                name="gensetLSIEnable"
                id="customControlValidation3"
                value={gensetLSIEnable}
                checked={gensetLSIEnable}
                onChange={gensetLSIChangeHandler}
              />
              <label
                className="custom-control-label line-height-25"
                htmlFor="customControlValidation3"
              >
                PV breakers opening
              </label>
              <se-tooltip
                action={isTouchDevice() ? "click" : "hover"}
                position="right"
              >
                <se-icon option="raised" color="secondary" slot="tooltip">
                  information_circle
                </se-icon>
                <se-tooltip-content>
                  Opens the PV breakers when excess of PV and PV curtailment not
                  working. This is a last chance mechanism
                </se-tooltip-content>
              </se-tooltip>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GensetOptimization;
