import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

const Busbar = ({ secondBusbar }) => {
  const {
    coordinates: { busbarCordinates },
  } = useContext(DesignAssetContext);
  return (
    <svg>
      <g id="busbar">
        <line
          x1={busbarCordinates.x11}
          y1={busbarCordinates.y11}
          x2={busbarCordinates.x21}
          y2={busbarCordinates.y21}
          stroke="#333333"
          strokeWidth="3"
        />
        {secondBusbar && (
          <line
            x1={busbarCordinates.x12}
            y1={busbarCordinates.y12}
            x2={busbarCordinates.x22}
            y2={busbarCordinates.y22}
            stroke="#333333"
            strokeWidth="3"
          />
        )}
      </g>
    </svg>
  );
};

export default Busbar;
