import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";

import embLabels from "../../config/emb-labels-en.json";

const Genset = () => {
  const {
    CBAssetList: [, { uiData, configData, powerMeterData }],
    UserScope,
    CBAssetListDispatch,
    assetDetailsReset,
    callSnackbar,
    selectedValuesHandler,
    assetConfigDataHandler,
    selectedAssetHandler,
    pmConfigDataHandler,
  } = useContext(DesignAssetContext);

  const gensetSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "assetGensetDetailsBlock";
      const selectedAssetValue = "selectedAsset";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_SELECT,
        payload: {
          index: 1,
        },
      });
      selectedValuesHandler(1, selectedAssetValue, assetBlock);
      selectedAssetHandler(embLabels.ASSETS, 1);
      assetConfigDataHandler(1);
    } else {
      callSnackbar(
        "error",
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  const gensetPMSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "cbAssetDetailsBlock";
      const selectedAssetValue = "selectedAssetPM";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_PM_SELECT,
        payload: {
          index: 1,
        },
      });
      selectedValuesHandler(1, selectedAssetValue, assetBlock);
      pmConfigDataHandler(1);
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  return (
    <svg className="pointer">
      <g id="genset">
        <svg
          x={uiData.xAxis}
          y={uiData.yAxis}
          width="220px"
          height="130px"
          viewBox="0 0 60 149"
          onClick={gensetSelectHandler}
        >
          <title>{configData.name}</title>

          <g id="Design" fill="none">
            <g
              id="Design/Assets-_-00"
              transform="translate(-748.000000, -372.000000)"
            >
              <g
                id="SLD/Genset/Uniline/OK"
                transform="translate(748.000000, 372.000000)"
              >
                <rect id="Rectangle" x="0" y="0" width="96" height="144"></rect>
                <line
                  x1="48.5"
                  y1="96"
                  x2="48.5"
                  y2="149"
                  id="Line-2"
                  stroke={uiData.configured ? "#333" : "#E47F00"}
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></line>
                <circle
                  id="Oval"
                  stroke="#42B4E6"
                  strokeWidth="3"
                  cx="48"
                  cy="56"
                  r="55"
                  style={uiData.selected ? {} : { display: "none" }}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="rgba(0, 135, 205, 0.2)"
                ></circle>
                <circle
                  id="Oval"
                  strokeWidth="3"
                  stroke={uiData.configured ? "#333" : "#E47F00"}
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  cx="48"
                  cy="56"
                  r="46.5"
                ></circle>
                <text
                  id="segment_energy_infra"
                  fontFamily="se-icon"
                  fontSize="50"
                  stroke="#333"
                  fontWeight="normal"
                  fill="#0F0F0F"
                >
                  <tspan x="25" y="74">
                    genset_01
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </svg>
      </g>
      <g id="power meter">
        <svg
          x="242"
          y="30"
          width="300px"
          height="140px"
          viewBox="0 0 100 160"
          onClick={gensetPMSelectHandler}
        >
          <title>{powerMeterData.name}</title>

          <line
            x1="29"
            y1="127"
            x2="80"
            y2="127"
            stroke="#42B4E6"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle
            id="Pm outer"
            stroke="#42B4E6"
            strokeWidth="3"
            cx="100"
            cy="127"
            r="26"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="rgba(0, 135, 205, 0.2)"
            style={uiData.pmSelected ? {} : { display: "none" }}
          ></circle>
          <circle
            id="pm inner"
            stroke="#42B4E6"
            strokeWidth="3"
            cx="100"
            cy="127"
            r="18.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="#FFFFFF"
          ></circle>
          <text
            id="PM"
            fontSize="16"
            fontWeight="normal"
            stroke="#42B4E6"
            fontFamily="Nunito-Regular, Nunito"
            fill="#42B4E6"
          >
            <tspan x="88" y="132">
              PM
            </tspan>
          </text>
        </svg>
      </g>
    </svg>
  );
};

export default Genset;
