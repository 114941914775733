import React, { useState } from "react";

const paswordRegex =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

const ChangePassword = ({
  closeChangePasswordPopUp,
  changePasswordConfirmation,
}) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [displayPassword, setDisplayPassword] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState(false);

  const handleChange = e => {
    setOldPassword(e.target.value);
    const { name, value } = e.target;
    switch (name) {
      case "oldPassword":
        setOldPassword(value.replace(/ +(?= )/g, ""));
        break;
      case "confirmPassword":
        setConfirmPassword(value.replace(/ +(?= )/g, ""));
        break;
      default:
        break;
    }
  };

  const disableEvent = e => {
    e.preventDefault();
  };

  const handlePasswordChange = e => {
    const { value } = e.target;
    if (paswordRegex.test(value) === true) {
      setPasswordCheck(true);
    } else {
      setPasswordCheck(false);
    }
    setNewPassword(value.replace(/\s/g, ""));
  };

  const showPassword = () => setDisplayPassword(!displayPassword);

  const closeChangePassword = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    closeChangePasswordPopUp();
  };

  const changePassword = () => {
    changePasswordConfirmation(passwordCheck, newPassword, confirmPassword);
  };

  return (
    <se-dialog
      id="dialog-complex"
      size="large"
      open={true}
      can-backdrop="false"
    >
      <se-dialog-header>Change Password</se-dialog-header>
      <se-dialog-content className="se-dialog-content">
        <div className="container">
          <div className="col-md-12">
            <div className="row justify-content-md-center">
              <div className="col-md-8">
                <div className="form-group row mb-1">
                  <div className="col-sm-8">
                    <input
                      type="hidden"
                      autoComplete="new-password"
                      className="form-control"
                      name="oldPassword"
                      placeholder="Old Password"
                      maxLength="50"
                      value={oldPassword}
                      onChange={handleChange}
                      onPaste={disableEvent}
                      onCopy={disableEvent}
                      onCut={disableEvent}
                    />
                  </div>
                </div>

                <div className="form-group row mb-2">
                  <label
                    htmlFor="newPassword"
                    className="col-sm-4 col-form-label"
                  >
                    New password<span className="se-mandatory">*</span>
                  </label>
                  <div className="col-sm-8 input-group">
                    <input
                      type={displayPassword ? "text" : "password"}
                      autoComplete="new-password"
                      className={
                        "form-control border-right-0 " +
                        (newPassword.length >= 1 && passwordCheck === false
                          ? "is-mandatory"
                          : passwordCheck === true
                          ? "is-success"
                          : "")
                      }
                      name="newPassword"
                      placeholder="New Password "
                      maxLength="50"
                      value={newPassword}
                      onChange={handlePasswordChange}
                      onPaste={disableEvent}
                      onCopy={disableEvent}
                      onCut={disableEvent}
                    />
                    <span
                      className="input-group-append border-password pointer"
                      onClick={showPassword}
                    >
                      <div
                        className={
                          "input-group-text bg-transparent " +
                          (newPassword.length >= 1 && passwordCheck === false
                            ? "is-mandatory-password"
                            : passwordCheck === true
                            ? "is-success-password"
                            : "")
                        }
                      >
                        {displayPassword === true ? (
                          <se-icon size="small">eye_dont_show</se-icon>
                        ) : (
                          <se-icon size="small">body_eye</se-icon>
                        )}
                      </div>
                    </span>
                    <div
                      className="invalid-feedback-custom "
                      style={
                        passwordCheck === false && newPassword !== ""
                          ? {}
                          : { display: "none" }
                      }
                    >
                      Password must have 8 or more characters including at least
                      one number , one upper case and lower case character and
                      one special symbol (#?!@$%^&*-), Must not include first
                      name or last name
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-1">
                  <label
                    htmlFor="confirmPassword"
                    className="col-sm-4 col-form-label"
                  >
                    Confirm password<span className="se-mandatory">*</span>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="password"
                      autoComplete="new-password"
                      className="form-control "
                      name="confirmPassword"
                      placeholder="Confirm Password "
                      onChange={handleChange}
                      value={confirmPassword}
                      maxLength="50"
                      onPaste={disableEvent}
                      onCopy={disableEvent}
                      onCut={disableEvent}
                    />
                    <div
                      className="invalid-feedback-custom "
                      style={
                        confirmPassword.length >= 8 &&
                        newPassword !== confirmPassword
                          ? {}
                          : { display: "none" }
                      }
                    >
                      Confirm password is not matching with New password
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </se-dialog-content>
      <se-dialog-footer>
        <se-button onClick={closeChangePassword}>Cancel</se-button>

        <se-button
          disabled={
            passwordCheck === true && confirmPassword === newPassword
              ? false
              : true
          }
          color="primary"
          onClick={changePassword}
        >
          Submit
        </se-button>
      </se-dialog-footer>
    </se-dialog>
  );
};

export default ChangePassword;
