import React, { Component } from "react";

class SLDGenset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOverData: false,
    };
  }
  onCBMouseOver() {
    this.setState({ mouseOverData: !this.state.mouseOverData });
  }

  render() {
    const { genset, onGensetSelect, gensetName } = this.props;
    return [
      <svg style={genset.status ? {} : { display: "none" }} className="pointer">
        <g id="genset">
          <svg
            x={genset.xAxis}
            y={genset.yAxis}
            width="195px"
            height="130px"
            viewBox="0 0 10 149"
            onClick={onGensetSelect}
          >
            <title className="se-custom-tooltip">{gensetName}</title>
            <g id="Design" fill="none">
              <g
                id="Design/Assets-_-00"
                transform="translate(-748.000000, -372.000000)"
              >
                <g
                  id="SLD/Genset/Uniline/OK"
                  transform="translate(748.000000, 372.000000)"
                >
                  <circle
                    id="Oval"
                    stroke="#42B4E6"
                    stroke-width="3"
                    cx="48"
                    cy="56"
                    r="55"
                    style={genset.selected ? {} : { display: "none" }}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    fill="rgba(0, 135, 205, 0.2)"
                  ></circle>
                  <circle
                    id="Oval"
                    stroke-width="3"
                    stroke={genset.configured ? "#333333" : "#E47F00"}
                    fill="#FFFFFF"
                    fill-rule="evenodd"
                    cx="48"
                    cy="56"
                    r="46.5"
                  ></circle>
                  <text
                    id="segment_energy_infra"
                    font-family="se-icon"
                    font-size="50"
                    stroke="#333333"
                    font-weight="normal"
                    fill="#0F0F0F"
                  >
                    <tspan x="25" y="74">
                      genset_01
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </svg>
          <line
            x1={genset.x1pos}
            y1={genset.y1pos}
            x2={genset.x2pos}
            y2={genset.y2pos}
            id="Line-2"
            stroke-width="4"
            stroke-dasharray="6 8"
            stroke-linecap="round"
            stroke={genset.configured ? "#333333" : "#E47F00"}
          ></line>
        </g>
      </svg>,
    ];
  }
}

export default SLDGenset;
