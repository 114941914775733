import React, { useContext } from "react";
import { AssetBlockContext } from "../CustomHooks/ContextApi";

import cbImage from "../../images/CB.png";
import embLabels from "../../config/emb-labels-en.json";

import isTouchDevice from "../SE-Component/Tooltip";

const LoadCB = () => {
  const {
    updateLoadCbConfigData,
    configData,
    handleCBConfigChangeLoad,
    assetEditStatusUpdateLoadCb,
    selectedValues,
  } = useContext(AssetBlockContext);

  const loadCbConfigChangeHandler = e => {
    handleCBConfigChangeLoad(e);
    assetEditStatusUpdateLoadCb(e, true, selectedValues.selectedLoadCB);
  };

  return (
    <div name="CB" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 pb-2 se-white-background">
        <div className="row">
          <div className="col-md-8">
            <img
              src={cbImage}
              className="asset-image-icon"
              alt="circuit breaker"
            />
            <span className="align-top line-height-30px">Circuit Breaker</span>
          </div>
          <div className="col-md-4">
            <span className="float-right">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                disabled
              >
                Remove
              </button>
            </span>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-sm-12 pt-2 se-grey-background se-border">
        <div className="row">
          <div className="col-md-3">
            <img
              src={cbImage}
              className="asset-image-display"
              alt="circuit breaker"
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12 bold">Schneider Electric</div>
            </div>
            <div className="row">
              <div className="col-md-9">Circuit Breaker</div>
              <div className="col-md-3 text-right">
                <se-tooltip
                  position="left"
                  action={isTouchDevice() ? "click" : "hover"}
                >
                  <se-icon option="raised" color="secondary" slot="tooltip">
                    information_circle
                  </se-icon>

                  <se-tooltip-content>
                    Powerpact(UL)/ Compact NSX(IEC)
                  </se-tooltip-content>
                </se-tooltip>
              </div>
              <div className="col-md-12">PowerPact NSX</div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-md-12 mt-2 se-white-background align-middle">
        <div className="row">
          <label className=" col-md-6 bold se-font-14px">Asset Settings</label>

          <div className="col-md-6 mt-1 text-right">
            <button
              type="button"
              className="btn btn-secondary btn-sm se-btn-primary"
              onClick={updateLoadCbConfigData}
            >
              <se-icon size="nano">action_save</se-icon>
              <span>&nbsp;</span>Save
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-12 se-grey-background">
        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">
            Name <span className="se-mandatory">*</span>
          </label>
          <input
            type="text"
            autoComplete="none"
            className={"form-control form-control-sm "}
            id="exampleFormControlInput1"
            placeholder="Name"
            name="name"
            value={configData.cbConfigData.name}
            onChange={loadCbConfigChangeHandler}
          />

          <div
            className="invalid-feedback-custom"
            style={!configData.cbConfigData.name ? {} : { display: "none" }}
          >
            {embLabels.nameRequired}
          </div>
        </div>

        <div className="form-group mb-1">
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input asCompatible"
              name="motorized"
              id="customControlValidation4"
              onChange={loadCbConfigChangeHandler}
              value={configData.cbConfigData.motorized}
              checked={configData.cbConfigData.motorized}
            />
            <label
              className="custom-control-label line-height-25"
              htmlFor="customControlValidation4"
            >
              Motorized
            </label>
            {configData.cbConfigData.motorized}
            <se-tooltip
              action={isTouchDevice() ? "click" : "hover"}
              position="bottom"
            >
              <se-icon
                option="raised"
                color="secondary"
                size="small"
                slot="tooltip"
              >
                information_circle
              </se-icon>
              <se-tooltip-content>
                If the circuit breaker is not motorized, the load won't be part
                of load shedding.
              </se-tooltip-content>
            </se-tooltip>
          </div>
        </div>

        <div className="form-group mb-1">
          <label htmlFor="exampleFormControlInput1">Description</label>
          <input
            type="text"
            autoComplete="none"
            className="form-control form-control-sm"
            id="exampleFormControlInput1"
            placeholder="Description"
            name="description"
            value={configData.cbConfigData.description}
            onChange={loadCbConfigChangeHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default LoadCB;
