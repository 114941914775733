import React, { Component } from 'react';
import APISESSION from '../api/ApiSession';
const apiSession = new APISESSION();


const hostUrl = `${window.location.protocol}//${window.location.host}`
class Invitation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId : '',
            onLoadingStatus : true,
            acceptInvitationSuccess:false,
            acceptInvitationFailure : false
        }
    }
    componentDidMount() {
        this.setState({ onLoadingStatus: true });
        let querySearch = this.props.location.search;
        let query = querySearch.split("?userId=");
        this.setState({userId : query[1]});
        this.acceptInvitation(query[1]);
    }
    
    
    
    acceptInvitation(token){
        apiSession.acceptInvitation(token).then(response=>{
            this.setState({acceptInvitationSuccess : true});
            this.setState({acceptInvitationFailure : false});
            this.setState({ onLoadingStatus: false });
        }).catch(error=>{
            console.log(error);
            this.setState({acceptInvitationSuccess : false});
            this.setState({acceptInvitationFailure : true});
            this.setState({ onLoadingStatus: false });

        })


    }

    render() {
        return (
            <se-container option="centered" style={this.state.userId !== "" ? {} : { display: 'none' }}>
                <se-loading loading={this.state.onLoadingStatus} />
                <div className="container mb-1" >

                    <div className="row" style={ this.state.acceptInvitationFailure === true? {} : { display: 'none' }}>
                        <div className="col-md-12 col-sm-12 col-lg-12 se-white-background pr-5 pl-5 mt-5 text-center error-msg">
                            <se-block-content>
                                <h4><se-icon size="small">notification_critical_stroke</se-icon> Invitation Link expired or Error in Invitation. Please contact administrator </h4>
                                
                            </se-block-content>
                        </div>


                    </div>

                    

                    <div className="row" style={this.state.acceptInvitationSuccess === true? {} : { display: 'none' }}>
                        <div className="col-md-12 col-sm-12 col-lg-12 se-white-background pr-5 pl-5 mt-5 text-center succedd-msg">
                            <se-block-content>
                                <h4><se-icon color="primary" size="small">notification_critical_stroke</se-icon> Invitation accepted successfull.</h4>
                                <se-divider></se-divider>
                                <a href={hostUrl} title="Click here to login"><h5>Click here to login </h5></a>
                            </se-block-content>
                        </div>


                    </div>
                    
                </div>
            </se-container>

        )
    }
}

export default Invitation;