import React from "react";

const ViewAsset = ({ viewAssets, viewAssetPopupStatus, closeViewPopup }) => (
  <se-dialog
    id="dialog-complex-view"
    size="large"
    open={viewAssetPopupStatus}
    can-backdrop="false"
  >
    <se-dialog-header>View Asset</se-dialog-header>

    <se-dialog-content className="se-dialog-content">
      <div className="container ">
        <div className="col-md-12">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="table-responsive">
                <table className="table table-bordered mb-0">
                  <tbody>
                    <tr>
                      <td>Asset Type </td>
                      <td>{viewAssets.type.type}</td>
                    </tr>
                    <tr>
                      <td>Asset Manufacturer </td>
                      <td>{viewAssets.manufacturer}</td>
                    </tr>
                    <tr>
                      <td>Asset Model </td>
                      <td>{viewAssets.communicationModel}</td>
                    </tr>
                    <tr>
                      <td>Version </td>
                      <td>{viewAssets.version}</td>
                    </tr>
                    <tr>
                      <td>Category</td>
                      <td>{viewAssets.assetInfo}</td>
                    </tr>
                    <tr>
                      <td>Asset Image</td>
                      <td>
                        {viewAssets.imageLink === null ? (
                          " No Image"
                        ) : (
                          <img
                            src={viewAssets.imageLink}
                            class="img-thumbnail asset-add-image"
                            alt="Asset icon"
                          />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Asset status</td>
                      <td>
                        {viewAssets.validated === true ? "Active" : "Inactive"}
                      </td>
                    </tr>

                    <tr>
                      <td>Availability</td>
                      <td>{viewAssets.availability}</td>
                    </tr>

                    <tr>
                      <td>Comments</td>
                      <td>{viewAssets.comments}</td>
                    </tr>

                    <tr>
                      <td>Created date</td>
                      <td>{viewAssets.creationDate}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </se-dialog-content>
    <se-dialog-footer>
      <se-button onClick={closeViewPopup}>Cancel</se-button>
    </se-dialog-footer>
  </se-dialog>
);

export default ViewAsset;
