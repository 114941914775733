import React, { useState } from "react";
import DemandCharge from "mg-ui-dc";
import _ from "lodash";
import DcAlertLabels from "../../config/DEMAND_CHARGE_ALERT_LIST.json";

const styleOne = {
  width: "1050px",
  margin: "1em auto",
  padding: "0.5em 1.5em",
  border: "solid 1px lightgray",
  marginBottom: "30px",
};

const styleTwo = {
  width: "1300px",
  margin: "1em auto",
  padding: "0.5em 0.5em",
  border: "solid 1px lightgray",
  marginBottom: "30px",
};

const DcTemplate = ({
  UserScope,
  templatesList,
  loading,
  callSnackbar,
  saveTemplate,
  saveEditedTemplate,
  deleteTemplateFromList,
}) => {
  const [templateSelected, setTemplateSelected] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({
    templateId: "",
    templateName: "",
    templateDeleteId: "",
    deleteTemplateConfirmPopUpStatus: false,
    editedTemplateId: "",
    editedTemplateName: "",
  });
  const [demandChargeOutput, setDemandChargeOutput] = useState({
    periodsList: [],
    demandChargeUseCaseSelection: "",
    penaltyCoefficient: 0.11,
    penaltyCalculationFormula: "FRENCH",
    dcUsedPower: "instantaneous",
    dcAverageDuration: 0,
    demandChargeMaxPower: {
      yearEnable: true,
      yearPeakCost: "",
    },
    demandChargeYearly: {
      ratchetYearlyPercentage: 100,
    },
  });

  const addTempalteDisplay = () =>
    setTemplateDetails(prevState => ({ ...prevState, templateId: "0" }));

  const demandChargeDataCallback = data => {
    let newDemandCharge = {};
    newDemandCharge = data;
    setDemandChargeOutput(newDemandCharge);
    setTemplateSelected(false);
  };

  const templateNameHandler = e => {
    const { name, value } = e.target;
    setTemplateDetails(prevState => ({ ...prevState, [name]: value }));
  };

  const cancelSaveTemplate = () => {
    setDemandChargeOutput({
      periodsList: [],
      demandChargeUseCaseSelection: "",
      penaltyCoefficient: 0.11,
      penaltyCalculationFormula: "FRENCH",
      dcUsedPower: "instantaneous",
      dcAverageDuration: 0,
      demandChargeMaxPower: {
        yearEnable: true,
        yearPeakCost: "",
      },
      demandChargeYearly: {
        ratchetYearlyPercentage: 100,
      },
    });

    setTemplateDetails(prevState => ({
      ...prevState,
      templateId: "",
      templateName: "",
    }));
  };

  const saveNewTemplateHandler = () => {
    let result = null;
    let dcData = _.cloneDeep(demandChargeOutput);

    result = DemandCharge.validateDemandCharge(dcData);
    if (result !== null) {
      callSnackbar("error", DcAlertLabels[result]);
    } else {
      if (templateDetails.templateId === templateDetails.editedTemplateId) {
        const demandCharge = {
          id: templateDetails.templateId,
          title: templateDetails.templateName,
          demandCharge: JSON.stringify(demandChargeOutput),
        };
        saveEditedTemplate(demandCharge);
        cancelSaveTemplate();
      } else {
        const demandCharge = {
          title: templateDetails.templateName,
          demandCharge: JSON.stringify(demandChargeOutput),
        };
        saveTemplate(demandCharge);
        cancelSaveTemplate();
      }
    }
  };
  const deleteTemplatePopUp = templateId =>
    setTemplateDetails(prevState => ({
      ...prevState,
      templateDeleteId: templateId,
      deleteTemplateConfirmPopUpStatus: true,
    }));

  const closeDeleteTemplatePopUp = () =>
    setTemplateDetails(prevState => ({
      ...prevState,
      templateDeleteId: "",
      deleteTemplateConfirmPopUpStatus: false,
    }));

  const removeTemplateConfirm = () => {
    if (templateDetails.templateDeleteId) {
      deleteTemplateFromList(templateDetails.templateDeleteId);
      closeDeleteTemplatePopUp();
    } else {
      closeDeleteTemplatePopUp();
    }
  };

  const editTemplate = template => {
    let newDemandCharge = {};
    newDemandCharge = JSON.parse(template.demandCharge);
    setTemplateSelected(true);
    setDemandChargeOutput(newDemandCharge);
    setTemplateDetails(prevState => ({
      ...prevState,
      templateId: template.id,
      templateName: template.title,
      editedTemplateId: template.id,
      editedTemplateName: template.title,
    }));
  };

  const getTemplateNameClass = () => {
    let usedTemplateNames = templatesList.map(template => template.title);
    if (templateDetails.templateId === templateDetails.editedTemplateId) {
      usedTemplateNames = usedTemplateNames.filter(
        template => template !== templateDetails.editedTemplateName
      );
    }
    let trueFalse =
      templateDetails.templateName.trim() !== "" &&
      usedTemplateNames.includes(templateDetails.templateName);
    return trueFalse;
  };

  const templateSaveDisabled = () => {
    let usedTemplateNames = templatesList.map(template => template.title);
    if (templateDetails.templateId === templateDetails.editedTemplateId) {
      usedTemplateNames = usedTemplateNames.filter(
        template => template !== templateDetails.editedTemplateName
      );
    }
    let trueFalse =
      templateDetails.templateName.trim() === "" ||
      usedTemplateNames.includes(templateDetails.templateName);
    return trueFalse;
  };

  return (
    <div className="col-md-12 mt-2 pb-2">
      <div className="row">
        <div className="col-md-12 mt-2">
          <se-divider></se-divider>
        </div>
      </div>

      <div className="row">
        <div className="col-md-10 mt-2">
          <span className="se-life-green se-font-14px-bold ">Template</span>
        </div>
        <div className="col-md-1">
          {UserScope.access.saveContact &&
            templateDetails.templateId === "" &&
            templatesList.length < 10 && (
              <se-button
                option="raised"
                size="small"
                color="primary"
                icon="action_add_flat"
                onClick={addTempalteDisplay}
              >
                Add Template
              </se-button>
            )}
        </div>
      </div>

      {templateDetails.templateId !== "" && (
        <div className="row">
          <div
            style={
              demandChargeOutput.periodsList.length > 5 ? styleTwo : styleOne
            }
          >
            <DemandCharge
              demandChargeData={demandChargeOutput}
              demandChargeDataCallback={demandChargeDataCallback}
              demandChargeUseCase={true}
              timeOfUseCase={true}
              currency={"US$"}
              country={localStorage.getItem("userCountry")}
              templateSel={templateSelected}
            />
          </div>
        </div>
      )}

      {templateDetails.templateId !== "" && (
        <div className="row">
          <div className="col-md-7">
            <div className="form-group row">
              <label
                htmlFor="inputEmail3"
                className="col-sm-2 col-form-label text-right"
              >
                Template Name<span className="se-mandatory">*</span>
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  autoComplete="none"
                  className="form-control form-control-md"
                  name="templateName"
                  placeholder="Name"
                  value={templateDetails.templateName}
                  maxlength="50"
                  onChange={templateNameHandler}
                />
                <div
                  className="invalid-feedback-custom"
                  style={
                    templateDetails.templateName.trim() === ""
                      ? {}
                      : { display: "none" }
                  }
                >
                  Template Name is required.
                </div>
                <div
                  className="invalid-feedback-custom"
                  style={getTemplateNameClass() ? {} : { display: "none" }}
                >
                  {templateDetails.templateName} exsits, provide an unique name
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-2 my-auto  contact--email__cta">
            <span className="pointer">
              <se-icon
                size="medium"
                color="primary"
                title="Click to Save"
                disabled={templateSaveDisabled() ? true : false}
                onClick={
                  templateSaveDisabled() ? undefined : saveNewTemplateHandler
                }
              >
                notification_ok_stroke
              </se-icon>
            </span>
            <span>&nbsp;</span>
            <span className="pointer">
              {" "}
              <se-icon
                size="medium"
                title="Click to cancel"
                onClick={cancelSaveTemplate}
              >
                action_delete_cross
              </se-icon>
            </span>
          </div>
        </div>
      )}

      <div className="row mt-1 pb-2">
        {templatesList.length !== 0 && (
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-bordered mb-2">
                <thead>
                  <tr>
                    <th width="20%">Template</th>
                    <th width="10%">Created by</th>
                    <th width="10%">Last modified by</th>
                    <th width="10%" className="text-center">
                      Action
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {templatesList.map(template => (
                    <tr key={template.id}>
                      <td width="20%">
                        <span>{template.title}</span>
                      </td>
                      <td width="10%">
                        <span>{template.createdBy}</span>
                      </td>
                      <td width="10%">
                        <span>{template.lastModifiedBy}</span>
                      </td>

                      <td width="10%" className="align-middle text-center">
                        {UserScope.access.saveContact === true ? (
                          <span className="pointer">
                            <se-icon
                              size="small"
                              title="Edit"
                              color="primary"
                              onClick={() => editTemplate(template)}
                            >
                              action_editor
                            </se-icon>
                          </span>
                        ) : (
                          ""
                        )}
                        <span>&nbsp; &nbsp;</span>
                        {UserScope.access.saveContact === true ? (
                          <span className="pointer">
                            <se-icon
                              size="small"
                              title="Delete"
                              color="primary"
                              onClick={() => deleteTemplatePopUp(template.id)}
                            >
                              action_delete
                            </se-icon>
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {templatesList.length === 0 && loading === false && (
          <div className="col-md-12 text-center">
            <se-divider></se-divider>
            No templates
          </div>
        )}
      </div>
      <se-dialog
        id="dialog-complex-delete-confirm"
        open={templateDetails.deleteTemplateConfirmPopUpStatus}
        can-backdrop="false"
      >
        <se-dialog-header>Confirmation</se-dialog-header>
        <se-dialog-content>
          <span className="se-font-14px">
            Do you confirm to delete this template ?
          </span>
        </se-dialog-content>
        <se-dialog-footer>
          <se-button onClick={closeDeleteTemplatePopUp}>Cancel</se-button>
          <se-button onClick={removeTemplateConfirm}>OK</se-button>
        </se-dialog-footer>
      </se-dialog>
    </div>
  );
};

export default DcTemplate;
