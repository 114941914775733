import React, { useContext } from "react";
import embLabels from "../../config/emb-labels-en.json";

import { AssetBlockContext } from "../CustomHooks/ContextApi";
import isTouchDevice from "../SE-Component/Tooltip";

const CHPAsset = () => {
  const {
    assetDetailsDisplay,
    setAssetSelection,
    configData,
    handleChpChange,
    defaultsMinMax,
    updateChpConfig,
    removeCHP,
  } = useContext(AssetBlockContext);

  const setAssetHandler = () => setAssetSelection(embLabels.CHP);

  const chpChangeHandler = e => {
    handleChpChange(e);
  };

  return (
    <div name="chp" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 se-white-background">
        <div className="row">
          <div className="col-md-8 pb-2 pl-0 ">
            <span>
              <se-icon
                color="primary"
                className="se-icon icon_button se-life-green"
                size="medium"
              >
                {" "}
                system_heat{" "}
              </se-icon>
              <span className="ml-2 bold align-top line-height-30px">CHP</span>
            </span>
          </div>
          <div className="col-md-4">
            <span className="float-right">
              <button
                type="button"
                className={`btn btn-secondary btn-sm`}
                onClick={removeCHP}
              >
                Remove
              </button>
            </span>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-sm-12 pt-2 pb-1 se-grey-background se-border">
        <div
          className="row"
          style={assetDetailsDisplay.manufacturer ? {} : { display: "none" }}
        >
          <div className="col-md-3">
            {assetDetailsDisplay.imageLink === null ? (
              <se-icon color="primary" size="large">
                system_heat
              </se-icon>
            ) : (
              <img
                src={assetDetailsDisplay.imageLink}
                className="asset-image-display"
                alt={assetDetailsDisplay.manufacturer}
              />
            )}
          </div>

          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12 bold ml-0">
                {assetDetailsDisplay.manufacturer}
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 text-break">
                {assetDetailsDisplay.communicationModel}
              </div>
              <div className="col-md-4 text-right">
                <se-tooltip
                  position="left"
                  action={isTouchDevice() ? "click" : "hover"}
                >
                  <se-icon option="raised" color="secondary" slot="tooltip">
                    information_circle
                  </se-icon>

                  <se-tooltip-content>CHP</se-tooltip-content>
                </se-tooltip>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={assetDetailsDisplay.manufacturer ? { display: "none" } : {}}
        >
          <div className="ml-2 mb-2 asset-selection"> No assets selected</div>
        </div>
        <div className="row">
          <div className=" col-md col-sm col-lg text-center">
            <se-button option="outline" size="small" onClick={setAssetHandler}>
              Asset Selection
            </se-button>
          </div>
        </div>
      </div>
      <div style={assetDetailsDisplay.manufacturer ? {} : { display: "none" }}>
        <div className="col-md-12 se-white-background align-middle se-border">
          <div className="row">
            <label className=" col-md-6 bold mt-2 mb-2 pl-1 se-font-14px">
              Asset Settings
            </label>
            <div className="col-md-6 mt-1 text-right">
              <button
                type="button"
                className="btn btn-secondary btn-sm se-btn-primary"
                onClick={updateChpConfig}
              >
                <se-icon size="nano">action_save</se-icon>
                <span>&nbsp;</span>Save
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12 pb-2 pt-2 se-grey-background">
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Name <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              name="name"
              maxLength="50"
              value={configData.configData.name}
              placeholder="Name"
              onChange={chpChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={!configData.configData.name ? {} : { display: "none" }}
            >
              {embLabels.nameRequired}
            </div>
          </div>
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">Description</label>
            <input
              type="text"
              autoComplete="none"
              className="form-control form-control-sm"
              id="exampleFormControlInput1"
              name="description"
              maxLength="100"
              value={configData.configData.description}
              placeholder="Description"
              onChange={chpChangeHandler}
            />
          </div>

          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Nominal Power(kW) <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              name="nominal_power"
              maxLength="4"
              value={configData.configData.nominal_power}
              placeholder="Nominal Power(kW)"
              onChange={chpChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={
                !configData.configData.nominal_power ? {} : { display: "none" }
              }
            >
              Nominal Power(kW) is required.
            </div>

            {((configData.configData.nominal_power !== "" &&
              parseInt(configData.configData.nominal_power) <
                defaultsMinMax.chpNominalPowerMin) ||
              parseInt(configData.configData.nominal_power) >
                defaultsMinMax.chpNominalPowerMax) && (
              <div className="invalid-feedback-custom">
                Nominal Power should be in range(
                {defaultsMinMax.chpNominalPowerMin}-
                {defaultsMinMax.chpNominalPowerMax} kW).
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CHPAsset;
