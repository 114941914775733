import React from "react";
import embLabels from "../../config/emb-labels-en.json";

const AddEquipment = props => {
  const {
    siteType,
    clientType,
    architectureType,
    onAddGensetVerify,
    onAddBattery,
    onAddPv,
    onAddCHPVerify,
    onAddLoad,
    onAddEv,
    onAddTransferSwitch,
  } = props;

  const checkDerDisabled = () => {
    const isDisabled =
      clientType === embLabels.GENERIC_PROJECT
        ? siteType === embLabels.PV_HYBRIDIZATION
          ? true
          : false
        : clientType === embLabels.GREENSTRUXURE_PROJECT
        ? siteType === embLabels.ISLANDABLE_GSX
          ? architectureType === embLabels.PV_GENSET_ATS
            ? true
            : false
          : false
        : false;
    return isDisabled;
  };

  return (
    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 se-white-background">
      <div className="row border se-white-background se-block-header">
        <div>
          <span className="se-life-green bold">ADD EQUIPMENT</span>
        </div>
        <div className=" col-md-12 dropdown-divider"></div>
        <div className="col-md-12 px-0 mb-2">
          <span className="inline se-font-14px ">DERs</span>
          <hr className="se-text-hr-75" />
        </div>

        <div
          className={`col-md-12 border se-small-card pl-1 ${
            checkDerDisabled() ? "der-disabled" : ""
          }`}
          onClick={checkDerDisabled() ? undefined : onAddBattery}
        >
          <se-icon
            color="primary"
            className="se-icon icon_button se-life-green"
          >
            battery2_half
          </se-icon>
          <span className="ml-2 se-font-14px-bold">Battery</span>
        </div>
        <div className="col-md-12 border se-small-card pl-1" onClick={onAddPv}>
          <se-icon
            color="primary"
            className="se-icon icon_button  se-life-green"
          >
            solar_panel2
          </se-icon>
          <span className="ml-2 se-font-14px-bold">PhotoVoltaic</span>
        </div>

        <div
          className={`col-md-12 border se-small-card pl-1 ${
            checkDerDisabled() ? "der-disabled" : ""
          }`}
          onClick={checkDerDisabled() ? undefined : onAddGensetVerify}
        >
          <se-icon
            color="primary"
            className="se-icon icon_button se-life-green"
          >
            genset_01
          </se-icon>
          <span className="ml-2 se-font-14px-bold">Genset</span>
        </div>

        <div
          className={`col-md-12 border se-small-card pl-1
          ${
            [
              embLabels.PV_HYBRIDIZATION,
              embLabels.ISLANDABLE,
              embLabels.GRID_CONNECTED,
              embLabels.ISLANDABLE_GSX,
            ].includes(siteType) && "der-disabled"
          }`}
          onClick={
            [
              embLabels.PV_HYBRIDIZATION,
              embLabels.ISLANDABLE,
              embLabels.GRID_CONNECTED,
              embLabels.ISLANDABLE_GSX,
            ].includes(siteType)
              ? undefined
              : onAddCHPVerify
          }
        >
          <se-icon
            color="primary"
            className="se-icon icon_button se-life-green"
          >
            system_heat
          </se-icon>
          <span className="ml-2 se-font-14px-bold">CHP</span>
        </div>

        <div className="col-md-12 px-0 mb-2">
          <span className="inline se-font-14px">Loads</span>
          <hr className="se-text-hr-75" />
        </div>
        <div
          className={`col-md-12 border se-small-card pl-1 ${
            checkDerDisabled() ? "der-disabled" : ""
          }`}
          onClick={checkDerDisabled() ? undefined : onAddEv}
        >
          <se-icon
            color="primary"
            className="se-icon icon_button se-life-green"
          >
            electric_vehicle_terminal
          </se-icon>
          <span className="ml-2 se-font-14px-bold">EV cluster</span>
        </div>

        <div
          className={`col-md-12 border se-small-card pl-1 ${
            checkDerDisabled() ? "der-disabled" : ""
          }`}
          onClick={checkDerDisabled() ? undefined : onAddLoad}
        >
          <se-icon
            color="primary"
            className="se-icon icon_button se-life-green"
          >
            segment_building_small
          </se-icon>
          <span className="ml-2 se-font-14px-bold">Loads</span>
        </div>
        {[
          embLabels.ISLANDABLE,
          embLabels.PV_HYBRIDIZATION,
          embLabels.ISLANDABLE_GSX,
        ].includes(siteType) && (
          <div className="col-md-12 px-0 mb-2">
            <span className="inline se-font-14px">Electrical Assets</span>
            <hr className="se-text-hr-50" />
          </div>
        )}

        {[
          embLabels.ISLANDABLE,
          embLabels.PV_HYBRIDIZATION,
          embLabels.ISLANDABLE_GSX,
        ].includes(siteType) && (
          <div
            className={`col-md-12 border se-small-card pl-1 ${
              checkDerDisabled() ? "der-disabled" : ""
            }`}
            onClick={checkDerDisabled() ? undefined : onAddTransferSwitch}
          >
            <se-icon
              color="primary"
              className="se-icon icon_button se-life-green"
            >
              toggle_switch
            </se-icon>
            <span className="ml-2 se-font-14px-bold">Transfer Switch </span>
            <span fontSize="4px" className="float-right">
              Coming Soon
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddEquipment;
