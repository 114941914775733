import React from "react";
import embLabels from "../config/emb-labels-en.json";

import pvCL60SE from "../images/CL60_SE.jpg";
import pvCL36SE from "../images/CL36_SE.jpg";
import pvCL125SE from "../images/CL125_SE.jpg";

import isTouchDevice from "../pages/SE-Component/Tooltip";

const AssetSelectionPV = ({
  siteType,
  architectureType,
  assetDetailsDisplay,
  PVDetails,
  onsetAssetSelection,
  onHandlePvChange,
  assetEditStatusUpdatePv,
  defaultsMinMax,
  onClosePvDetailsBlock,
  onUpdatePvConfig,
}) => {
  const setAssetHandler = () => {
    onsetAssetSelection(embLabels.Pv_inverter_CallAsset);
  };

  const handlePvChangeHandler = e => {
    onHandlePvChange(e);
    assetEditStatusUpdatePv(
      e,
      true,
      PVDetails.selectedPv,
      PVDetails.selectSubPv
    );
  };

  return (
    <se-dialog
      id="dialog-complex"
      size="large"
      open={PVDetails.displayPvContent}
      can-backdrop="false"
    >
      <se-dialog-header> Configure PV inverters</se-dialog-header>

      <se-dialog-content className="se-dialog-content">
        <div className="col-md-12 col-sm-12 pt-2 se-grey-background se-border">
          {assetDetailsDisplay.manufacturer ? (
            <div className="row">
              {assetDetailsDisplay.imageLink === null ? (
                <div className="col-md-2">
                  {assetDetailsDisplay.manufacturer === "SCHNEIDER ELECTRIC" ? (
                    assetDetailsDisplay.communicationModel === "CONEXT CL36" ? (
                      <img
                        src={pvCL36SE}
                        className="asset-image-display"
                        alt={assetDetailsDisplay.communicationModel}
                      />
                    ) : assetDetailsDisplay.communicationModel ===
                      "CONEXT CL60" ? (
                      <img
                        src={pvCL60SE}
                        className="asset-image-display"
                        alt={assetDetailsDisplay.communicationModel}
                      />
                    ) : assetDetailsDisplay.communicationModel ===
                      "CONEXT CL125" ? (
                      <img
                        src={pvCL125SE}
                        className="asset-image-display"
                        alt={assetDetailsDisplay.communicationModel}
                      />
                    ) : assetDetailsDisplay.communicationModel ===
                      "CONEXT CL25" ? (
                      <img
                        src={pvCL60SE}
                        className="asset-image-display"
                        alt={assetDetailsDisplay.communicationModel}
                      />
                    ) : (
                      <se-icon color="primary" size="medium">
                        solar_panel1
                      </se-icon>
                    )
                  ) : (
                    <se-icon color="primary" size="medium">
                      solar_panel1
                    </se-icon>
                  )}
                </div>
              ) : (
                <div className="col-md-2">
                  <img
                    src={assetDetailsDisplay.imageLink}
                    className="asset-image-display"
                    alt={assetDetailsDisplay.communicationModel}
                  />
                </div>
              )}

              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-11 bold ml-2">
                    {assetDetailsDisplay.manufacturer}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-8 ml-2 text-break">
                    {assetDetailsDisplay.communicationModel}
                  </div>
                  <div className="col-md-2 ">
                    <se-tooltip
                      position="left"
                      action={isTouchDevice() ? "click" : "hover"}
                    >
                      <se-icon option="raised" color="secondary" slot="tooltip">
                        information_circle
                      </se-icon>

                      <se-tooltip-content> PV inverters</se-tooltip-content>
                    </se-tooltip>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="row">
                  <div className=" col-md col-sm col-lg text-center">
                    <se-button
                      option="outline"
                      size="small"
                      onClick={setAssetHandler}
                    >
                      Asset Selection
                    </se-button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className=" col-md col-sm col-lg text-center">
                <div className="ml-2 mb-2 asset-selection text-center">
                  {" "}
                  No assets selected
                </div>
                <se-button
                  option="outline"
                  size="small"
                  onClick={setAssetHandler}
                >
                  Asset Selection
                </se-button>
              </div>
            </div>
          )}
        </div>

        {assetDetailsDisplay.manufacturer && (
          <div>
            <div className="col-md-12 mt-2 se-white-background align-middle">
              <div className="row">
                <label className=" col-md-6 bold mt-1 se-font-14px">
                  Asset Settings
                </label>
              </div>
            </div>
            <div className="col-md-12 pb-2 pt-2 se-grey-background">
              <div className="form-group mb-1">
                <label htmlFor="exampleFormControlInput1">
                  Name <span className="se-mandatory">*</span>
                </label>
                <input
                  type="text"
                  autoComplete="none"
                  className={"form-control form-control-sm "}
                  id="exampleFormControlInput1"
                  name="name"
                  maxLength="50"
                  placeholder="Name"
                  value={PVDetails.pvConfig.name}
                  onChange={handlePvChangeHandler}
                />
                <div
                  className="invalid-feedback-custom"
                  style={!PVDetails.pvConfig.name ? {} : { display: "none" }}
                >
                  {embLabels.nameRequired}
                </div>
              </div>

              {[embLabels.ISLANDABLE_GSX].includes(siteType) &&
                architectureType === embLabels.PV_GENSET_ATS && (
                  <div className="form-group mb-1">
                    <label htmlFor="exampleFormControlInput1">
                      External ID
                    </label>
                    <input
                      type="text"
                      autoComplete="none"
                      className="form-control form-control-sm"
                      id="exampleFormControlInput1"
                      placeholder="External ID"
                      name="pvGsxExternalId"
                      maxLength="64"
                      onChange={handlePvChangeHandler}
                      value={PVDetails.pvConfig.pvGsxExternalId}
                    />
                  </div>
                )}

              <div className="form-group mb-1">
                <label htmlFor="exampleFormControlInput1">Description</label>
                <input
                  type="text"
                  autoComplete="none"
                  className="form-control form-control-sm"
                  id="exampleFormControlInput1"
                  placeholder="Description"
                  name="description"
                  maxLength="100"
                  value={PVDetails.pvConfig.description}
                  onChange={handlePvChangeHandler}
                />
              </div>
              <div className="form-group mb-1">
                <label htmlFor="exampleFormControlInput1">
                  Inverter Max Power(kW) <span className="se-mandatory">*</span>
                </label>
                <input
                  type="text"
                  autoComplete="none"
                  className={"form-control form-control-sm "}
                  id="exampleFormControlInput1"
                  name="max_power"
                  maxLength="4"
                  placeholder="Max Power(kW)"
                  value={PVDetails.pvConfig.max_power}
                  onChange={handlePvChangeHandler}
                />
                <div
                  className="invalid-feedback-custom"
                  style={
                    !PVDetails.pvConfig.max_power ? {} : { display: "none" }
                  }
                >
                  Max Power(kW) is required.
                </div>
                {PVDetails.pvConfig.max_power !== "" &&
                (parseInt(PVDetails.pvConfig.max_power) <
                  defaultsMinMax.maxPowerPvMin ||
                  parseInt(PVDetails.pvConfig.max_power) >
                    defaultsMinMax.maxPowerPvMax) ? (
                  <div className="invalid-feedback-custom">
                    Max Power should be in range(
                    {defaultsMinMax.maxPowerPvMin}-
                    {defaultsMinMax.maxPowerPvMax} kW).
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}
      </se-dialog-content>
      <se-dialog-footer>
        <se-button onClick={onClosePvDetailsBlock}>Close</se-button>

        {assetDetailsDisplay.manufacturer && (
          <se-button onClick={onUpdatePvConfig}>Save</se-button>
        )}
      </se-dialog-footer>
    </se-dialog>
  );
};

export default AssetSelectionPV;
