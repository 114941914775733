import React, { useContext } from "react";

import Accordion from "../../components/Accordions/Accordion";

import DemandCharge from "mg-ui-dc";

import { DesignProgramContext } from "../CustomHooks/ContextApi";

import embLabels from "../../config/emb-labels-en.json";
import isTouchDevice from "../SE-Component/Tooltip";

const OnGridOptimization = () => {
  const {
    gridSettings,
    configuredStatus,
    demandChargeOutput,
    siteType,
    maxExportPower,
    batteryDetails,
    pvCurtailmentExport,
    utilityLSIEnable,
    templateSelected,
    architectureType,
    demandChargeDataCallback,
    exportManagementChange,
    configuredStatusModified,
    handleChange,
    handleCheckBoxChange,
    handlepPVOpeningPopup,
    enableTemplatePopUp,
  } = useContext(DesignProgramContext);

  const exportManagementChangeHandler = () => {
    exportManagementChange();
    configuredStatusModified("emConfiguredStatus");
  };

  const maxExportPowerChangeHandler = e => {
    handleChange(e);
    configuredStatusModified("emConfiguredStatus");
  };

  const exportFunctionChangeHandler = e => {
    handleCheckBoxChange(e);
    configuredStatusModified("emConfiguredStatus");
  };

  const utilityLSIChangeHandler = e => {
    configuredStatusModified("emConfiguredStatus");
    handlepPVOpeningPopup(e);
  };

  return (
    <div className="row se-white-background pb-2">
      <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-2 ">
        <span className="se-font-14px-bold se-life-green ">
          on-grid optimization
        </span>
        <div className="row ">
          {gridSettings.noExport === false &&
          gridSettings.exportOptimization === false &&
          gridSettings.tariffManagement === false &&
          gridSettings.selfConsumption === false &&
          gridSettings.demandCharge === false ? (
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <div className="row">
                <label className="col-md-7 col-form-label pl-5">
                  No on-grid optimization usecase
                </label>
              </div>
            </div>
          ) : (
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Accordion allowMultipleOpen>
                <div
                  className="col-md-12"
                  label="Demand charge and Time of Use tariff "
                  status={configuredStatus.dcConfiguredStatus}
                  displayStatus={
                    gridSettings.demandCharge === true ||
                    gridSettings.tariffManagement === true
                      ? "1"
                      : "0"
                  }
                  icon="notification_error_full_stroke"
                  enableTemplatePopUp={enableTemplatePopUp}
                >
                  <div className="col-md-12 ">
                    <se-divider></se-divider>
                    <DemandCharge
                      demandChargeData={demandChargeOutput}
                      demandChargeDataCallback={demandChargeDataCallback}
                      demandChargeUseCase={gridSettings.demandCharge}
                      timeOfUseCase={gridSettings.tariffManagement}
                      currency={localStorage.getItem("currency")}
                      country={localStorage.getItem("userCountry")}
                      templateSel={templateSelected}
                    />
                  </div>
                </div>

                {gridSettings.noExport === true ||
                gridSettings.exportOptimization === true ? (
                  <div
                    className="col-md-12"
                    label="Export management"
                    status={configuredStatus.emConfiguredStatus}
                    displayStatus="1"
                    icon="notification_error_full_stroke"
                  >
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-1">
                      <div className="dropdown-divider"></div>

                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                          <span className="bold">
                            Selected export management type:
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-2 ">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="customRadioEx2"
                              name="noExport"
                              className="custom-control-input islandableRadio  asCompatible"
                              checked={gridSettings.noExport}
                              onClick={exportManagementChangeHandler}
                              disabled={
                                gridSettings.noExport === true ? false : true
                              }
                            />
                            <label
                              className="custom-control-label line-height-25px"
                              htmlFor="customRadioEx2"
                            >
                              Export management real time
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-5 col-md-5 col-lg-5 col-xl-5 mb-2 ">
                          <div className="custom-control custom-radio">
                            <input
                              type="radio"
                              id="customRadioEx1"
                              name="exportOptimization"
                              className="custom-control-input islandableRadio asCompatible"
                              checked={gridSettings.exportOptimization}
                              onClick={exportManagementChangeHandler}
                              style={
                                siteType === embLabels.PV_HYBRIDIZATION ||
                                (siteType === embLabels.ISLANDABLE_GSX &&
                                  architectureType === embLabels.PV_GENSET_ATS)
                                  ? { cursor: "not-allowed" }
                                  : { cursor: "pointer" }
                              }
                              disabled={
                                siteType === embLabels.PV_HYBRIDIZATION ||
                                (siteType === embLabels.ISLANDABLE_GSX &&
                                  architectureType ===
                                    embLabels.PV_GENSET_ATS) ||
                                gridSettings.exportOptimization === false
                                  ? true
                                  : false
                              }
                            />
                            <label
                              className="custom-control-label line-height-25px"
                              htmlFor="customRadioEx1"
                            >
                              Export management predictive optimization
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                          <div className="form-group row">
                            <label
                              htmlFor="inputEmail11"
                              className="col-sm-3 col-form-label"
                            >
                              Targeted Max Export power (kW)
                              <span className="se-mandatory">*</span>
                              <se-tooltip
                                action={isTouchDevice() ? "click" : "hover"}
                                position="right"
                              >
                                <se-icon
                                  option="raised"
                                  color="secondary"
                                  slot="tooltip"
                                >
                                  information_circle
                                </se-icon>
                                <se-tooltip-content>
                                  If the value is negative, the system will
                                  allow to export energy from on-site generation
                                  to the grid. <br />
                                  If the value is positive, the system will
                                  ensure a minimum energy import from the grid.
                                  <br /> If the value is equals to 0 ,the system
                                  will forbid any export.
                                </se-tooltip-content>
                              </se-tooltip>
                            </label>
                            <div className="col-sm-5">
                              <input
                                type="text"
                                autoComplete="none"
                                className="form-control form-control-md"
                                name="maxExportPower"
                                placeholder="Targeted Max Export power (kW)"
                                maxLength="4"
                                value={maxExportPower}
                                onChange={maxExportPowerChangeHandler}
                              />
                              {maxExportPower.toString() === "" ? (
                                <div className="invalid-feedback-custom">
                                  Targeted Max Export power (kW) is required.
                                </div>
                              ) : parseInt(maxExportPower) <= 999 &&
                                parseInt(maxExportPower) >= -999 ? (
                                ""
                              ) : (
                                <div className="invalid-feedback-custom">
                                  Targeted Max Export power should be in range
                                  (-999 &lt; Max Export power &gt; 999)
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                          <span className="bold">
                            Select export management functions
                          </span>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input asCompatible"
                              name="batteryBufferingExport"
                              id="customControlValidation4"
                              checked={
                                siteType === embLabels.PV_HYBRIDIZATION ||
                                (siteType === embLabels.ISLANDABLE_GSX &&
                                  architectureType === embLabels.PV_GENSET_ATS)
                                  ? false
                                  : batteryDetails.batteryBufferingExport
                              }
                              disabled={
                                siteType === embLabels.PV_HYBRIDIZATION
                                  ? true
                                  : batteryDetails.batteryBufferingStatus ===
                                      true &&
                                    (gridSettings.noExport === true ||
                                      gridSettings.exportOptimization === true)
                                  ? false
                                  : true
                              }
                              style={
                                siteType === embLabels.PV_HYBRIDIZATION ||
                                (siteType === embLabels.ISLANDABLE_GSX &&
                                  architectureType === embLabels.PV_GENSET_ATS)
                                  ? { cursor: "not-allowed" }
                                  : { cursor: "pointer" }
                              }
                              onChange={exportFunctionChangeHandler}
                            />
                            <label
                              className="custom-control-label line-height-25"
                              htmlFor="customControlValidation4"
                            >
                              Battery buffering
                            </label>
                            <se-tooltip
                              action={
                                [
                                  embLabels.ISLANDABLE,
                                  embLabels.GRID_CONNECTED,
                                ].includes(siteType)
                                  ? isTouchDevice()
                                    ? "click"
                                    : "hover"
                                  : ""
                              }
                              position="right"
                            >
                              <se-icon
                                option="raised"
                                color="secondary"
                                slot="tooltip"
                                disabled={
                                  siteType === embLabels.PV_HYBRIDIZATION ||
                                  (siteType === embLabels.ISLANDABLE_GSX &&
                                    architectureType ===
                                      embLabels.PV_GENSET_ATS)
                                    ? true
                                    : false
                                }
                              >
                                information_circle
                              </se-icon>
                              <se-tooltip-content>
                                Charge the battery with excess of PV production
                              </se-tooltip-content>
                            </se-tooltip>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input asCompatible"
                              name="pvCurtailmentExport"
                              id="customControlValidation2"
                              value={pvCurtailmentExport}
                              checked={pvCurtailmentExport}
                              disabled={
                                gridSettings.noExport === true ||
                                gridSettings.exportOptimization === true
                                  ? false
                                  : true
                              }
                              onChange={exportFunctionChangeHandler}
                            />
                            <label
                              className="custom-control-label line-height-25"
                              htmlFor="customControlValidation2"
                            >
                              PV curtailment
                            </label>
                            <se-tooltip
                              action={isTouchDevice() ? "click" : "hover"}
                              position="right"
                            >
                              <se-icon
                                option="raised"
                                color="secondary"
                                slot="tooltip"
                              >
                                information_circle
                              </se-icon>
                              <se-tooltip-content>
                                Curtails PV when excess of PV production and no
                                battery or battery full
                              </se-tooltip-content>
                            </se-tooltip>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8 ">
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              className="custom-control-input asCompatible"
                              name="utilityLSIEnable"
                              id="customControlValidationE3"
                              checked={utilityLSIEnable}
                              disabled={
                                gridSettings.noExport === true ||
                                gridSettings.exportOptimization === true
                                  ? false
                                  : true
                              }
                              onChange={utilityLSIChangeHandler}
                            />
                            <label
                              className="custom-control-label line-height-25"
                              htmlFor="customControlValidationE3"
                            >
                              PV breakers opening
                            </label>
                            <se-tooltip
                              action={isTouchDevice() ? "click" : "hover"}
                              position="right"
                            >
                              <se-icon
                                option="raised"
                                color="secondary"
                                slot="tooltip"
                              >
                                information_circle
                              </se-icon>
                              <se-tooltip-content>
                                Opens the PV breakers when excess of PV and PV
                                curtailment not working. This is a last chance
                                mechanism
                              </se-tooltip-content>
                            </se-tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="col-md-12"
                    label="dummy Export management"
                    status="1"
                    displayStatus="0"
                    icon="notification_error_full_stroke"
                  ></div>
                )}

                {gridSettings.selfConsumption === true ? (
                  <div
                    className="col-md-12"
                    label="Self Consumption"
                    status={1}
                    displayStatus="1"
                    icon="notification_error_full_stroke"
                  >
                    <div className="row">
                      <div className="col-md-12 text-center ">
                        <span className="se-font-14px-bold se-life-green">
                          No parameters for this function
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="col-md-12"
                    label="dummy Self Consumption"
                    status="1"
                    displayStatus="0"
                    icon="notification_error_full_stroke"
                  ></div>
                )}

                <div
                  className="col-md-12"
                  label="dummy on grid"
                  status={1}
                  displayStatus="0"
                  icon="notification_error_full_stroke"
                ></div>
              </Accordion>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnGridOptimization;
