import React, { useCallback, useRef, useState } from "react";

import { SeCheckbox } from "@se/web-ui-react";

import FileDownload from "js-file-download";
// const FileDownload = require("js-file-download");

import Services from "./DeployPages/Services";
import EmoController from "./DeployPages/EmoController";
import LicenceKeys from "./DeployPages/LicenceKeys";

import useMountEffect from "./CustomHooks/useMountEffect";
import userScope from "./CustomHooks/userScope";
import Snackbar from "./SE-Component/Snackbar";
import AlertIcon from "./SE-Component/AlertIcon";

//*api call
import ApiSession from "../api/ApiSession";
const apiSession = new ApiSession();

//* RegEx
const alphanumericReg = /^([a-zA-Z0-9]+)$/;
const serialNoRegex = /^[a-zA-Z0-9]{8}[0-9]{5}$/;
const alphanumericRegWithHyphen = /^([a-zA-Z0-9,-]+)$/;

const Deploy = props => {
  const [UserScope] = userScope();
  const [loading, setLoading] = useState(false);
  const [unlockDeploy, setUnlockDeploy] = useState(false);
  const [emaSiteStatus, setEmaSiteStatus] = useState(false);
  const [resetPlcPopUpStatus, setResetPlcPopUpStatus] = useState(false);

  const [siteType, setSiteType] = useState("");
  const [clientType, setClientType] = useState("");
  const [architectureType, setArchitectureType] = useState("");
  const [emoSerialNumber, setEmoSerialNumber] = useState(null);

  const [projectName] = useState(localStorage.getItem("pname"));

  const [keyExpert, setKeyExpert] = useState({
    expertKey: "",
    expertStatus: false,
    KeyErrorStatusExpert: false,
    expertKeyInvalid: false,
    expertExpired: false,
  });
  const [keyOperations, setKeyOperations] = useState({
    operationKey: "",
    operationsStatus: false,
    KeyErrorStatusOperations: false,
    operationKeyInvalid: false,
    operationExpired: false,
  });
  const [gridSetting, setGridSetting] = useState({
    userInterface: true,
    remoteMonitoringControl: true,
  });
  const [pagesValidated, setPagesValidated] = useState({
    programPage: true,
    systemsPage: true,
  });
  const [versions, setVersions] = useState({
    plcVersion: "",
    hmiVersion: "",
  });
  const [lockedStatus, setLockedStatus] = useState({
    unlockDeploy: false,
    plcLocked: false,
  });
  const [emoController, setEmoController] = useState({
    plcKeyPopUp: false,
    serialNo: "",
    KeyErrorStatusSerialNo: false,
  });
  const [snackBar, setSnackBar] = useState({ type: "", message: "" });
  const [alert, setAlert] = useState({
    message: "",
    alertPopUpStatus: false,
    title: "",
    isRoute: false,
    routePath: "",
  });
  const [confirm, setConfirm] = useState({
    deleteSiteAlertPopUp: false,
  });

  const snackBarRef = useRef(null);

  useMountEffect(() => {
    if (sessionStorage.getItem("projectId") === "null") {
      setAlertMessage(
        `Deploy Page`,
        true,
        `information`,
        `Please create project and then visit deploy page.`
      );
    } else {
      if (UserScope.access.deploymentStatus) {
        setLoading(true);
        getDeploymentStatus();
      }
      getEMASite(false);
    }

    // if (hostUrl === IntegrationURL) {
    //   this.setState({ disableCloudConfiguration: true });
    // }
  }, []);

  const getDeploymentStatus = () => {
    apiSession
      .getDeploymentStatus(sessionStorage.getItem("projectId"))
      .then(response => {
        let licenses = [];
        licenses = response.licenses;

        setKeyExpert(prevState => ({
          ...prevState,
          expertStatus: false,
          expertExpired: false,
        }));
        setKeyOperations(prevState => ({
          ...prevState,
          operationsStatus: false,
          operationExpired: false,
        }));

        if (licenses.length) {
          if (licenses.indexOf("EXPERT") > -1) {
            setKeyExpert(prevState => ({ ...prevState, expertStatus: true }));
          }
          if (licenses.indexOf("OPERATIONS") > -1) {
            setKeyOperations(prevState => ({
              ...prevState,
              operationsStatus: true,
            }));
          }
          if (licenses.indexOf("EXPERT_EXPIRED") > -1) {
            setKeyExpert(prevState => ({
              ...prevState,
              expertExpired: true,
            }));
          }
          if (licenses.indexOf("OPERATIONS_EXPIRED") > -1) {
            setKeyOperations(prevState => ({
              ...prevState,
              operationExpired: true,
            }));
          }
        }

        setUnlockDeploy(response.deploymentStatus);
        setPagesValidated(prevState => ({
          ...prevState,
          programPage: response.programPage,
          systemsPage: response.systemsPage,
        }));

        setVersions({
          ...versions,
          plcVersion: response.plcVersion,
          hmiVersion: response.hmiVersion,
        });

        const gridSettings = { ...gridSetting };
        gridSettings.userInterface = response.hmiSelected;
        gridSettings.remoteMonitoringControl = response.remoteMonitoring;
        setGridSetting(gridSettings);
        setLockedStatus(prevState => ({
          ...prevState,
          unlockDeploy: response.deploymentStatus,
          plcLocked: response.plcLocked,
        }));
        setSiteType(response.siteType);
        setClientType(response.clientType);
        setArchitectureType(response.architecture);
        setEmoSerialNumber(response.serialNumber);
        if (response.serialNumber) {
          setEmoController(prevState => ({
            ...prevState,
            serialNo: response.serialNumber,
          }));
        }
        setLoading(false);
      })
      .catch(() => {
        setPagesValidated(prevState => ({
          ...prevState,
          programPage: true,
          systemsPage: true,
        }));
        setKeyExpert(prevState => ({
          ...prevState,
          expertStatus: false,
          expertExpired: false,
        }));
        setKeyOperations(prevState => ({
          ...prevState,
          operationsStatus: false,
          operationExpired: false,
        }));
        setLockedStatus(prevState => ({
          ...prevState,
          unlockDeploy: false,
          plcLocked: false,
        }));
        setLoading(false);
      });
  };

  //* Cloud servie

  const getEMASite = update => {
    apiSession
      .getEMASite(sessionStorage.getItem("projectId"))
      .then(response => {
        if (update === true) {
          if (response.reConfigured === "true") {
            setAlertMessage(
              `Alert`,
              false,
              ``,
              `Download EMA controller configuration as EMA website has been updated.`
            );
          }
        }

        setEmaSiteStatus(true);
      })
      .catch(() => {
        setEmaSiteStatus(false);
      });
  };

  const createSiteInEMA = () => {
    if (
      pagesValidated.programPage === false &&
      pagesValidated.systemsPage === false
    ) {
      setLoading(true);

      apiSession
        .createEMAsite(sessionStorage.getItem("projectId"))
        .then(() => {
          callSnackbar(`success`, `Site created successfully`);
          setLoading(false);
          getEMASite(true);
        })
        .catch(error => {
          if (error.response !== undefined) {
            if (error.response.status === 403) {
              callSnackbar(
                `error`,
                `Unauthorised to perform this action, Contact administrator.`
              );
            } else if (error.response.status === 400) {
              callSnackbar(
                `error`,
                `${
                  error.response.data === null
                    ? "Error in site creation"
                    : error.response.data.message
                }`
              );
            } else {
              callSnackbar(
                `error`,
                `${
                  error.response.data === null
                    ? "Error in site creation"
                    : error.response.data.message
                }`
              );
            }
          }
          setLoading(false);
          getEMASite();
        });
    } else {
      if (pagesValidated.programPage) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `Please validate use case to proceed further in Configure use cases.`
        );
      } else if (pagesValidated.systemsPage) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `Please validate communication settings to proceed further.`
        );
      }
    }
  };

  const deleteSiteInEMA = () =>
    setConfirm(prevState => ({ ...prevState, deleteSiteAlertPopUp: true }));
  const cancelDeleteSite = () =>
    setConfirm(prevState => ({ ...prevState, deleteSiteAlertPopUp: false }));

  const confirmDeleteSite = () => {
    if (UserScope.access.deleteSite) {
      setConfirm(prevState => ({ ...prevState, deleteSiteAlertPopUp: false }));
      setLoading(true);

      apiSession
        .deleteEMASite(sessionStorage.getItem("projectId"))
        .then(() => {
          setLoading(false);

          callSnackbar("success", "Cloud service deleted successfully");
          getEMASite(false);
        })
        .catch(error => {
          setLoading(false);

          getEMASite(false);
          if (error.response !== undefined) {
            if (error.response.status === 400) {
              callSnackbar("error", error.response.data.message);
            }
          } else {
            callSnackbar("error", "Error in deletion of Cloud service");
          }
        });
    } else {
      setConfirm(prevState => ({ ...prevState, deleteSiteAlertPopUp: false }));
      callSnackbar(
        "error",
        "User doesn't have permission to delete the cloud service"
      );
      getEMASite(false);
    }
  };

  //* IPC

  const downloadIpcFile = () => {
    if (!emaSiteStatus) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `Configure cloud services before downloading EMA controller configuration.`
      );
    } else {
      if (!pagesValidated.programPage) {
        setLoading(true);
        apiSession
          .downloadIPC(sessionStorage.getItem("projectId"))
          .then(response => {
            FileDownload(response, `${projectName}_EMAController.zip`);
            callSnackbar("success", "Downloaded successfully");
            setLoading(false);
          })
          .catch(error => {
            if (error.response !== undefined) {
              var enc = new TextDecoder("utf-8");
              var arr = new Uint8Array(error.response.data);
              let resultBuf = JSON.parse(enc.decode(arr));
              callSnackbar(
                "error",
                resultBuf.message !== undefined
                  ? resultBuf.message
                  : "Error in IPC Download"
              );
            } else {
              callSnackbar("error", "Error in IPC Download");
            }
            setLoading(false);
          });
      } else {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `Please validate use case to proceed further in Configure use cases.`
        );
      }
    }
  };

  //* PLC

  const handleSerialNoPopUp = () => {
    if (lockedStatus.plcLocked) {
      setAlertMessage(
        `Alert`,
        false,
        ``,
        `Please contact admin for unlocking the deployment.`
      );
    } else {
      if (!pagesValidated.programPage && !pagesValidated.systemsPage) {
        setEmoController(prevState => ({
          ...prevState,
          plcKeyPopUp: true,
          serialNo: emoSerialNumber ? emoSerialNumber : "",
          KeyErrorStatusSerialNo: false,
        }));
      } else {
        if (pagesValidated.programPage) {
          setAlertMessage(
            `Alert`,
            false,
            ``,
            `Please validate use case to proceed further in Configure use cases.`
          );
        } else if (pagesValidated.systemsPage) {
          setAlertMessage(
            `Alert`,
            false,
            ``,
            `Please validate communication settings to proceed further.`
          );
        }
      }
    }
  };

  const handleSerialNoChange = e => {
    const { value } = e.target;
    setEmoController(prevState => ({
      ...prevState,
      KeyErrorStatusSerialNo: true,
    }));
    if (value !== "") {
      if (alphanumericReg.test(value)) {
        setEmoController(prevState => ({
          ...prevState,
          serialNo: value,
        }));
      }
    } else {
      setEmoController(prevState => ({
        ...prevState,
        serialNo: "",
      }));
    }
  };

  const handleSerialNoPopUpClose = () => {
    setEmoController(prevState => ({
      ...prevState,
      plcKeyPopUp: false,
      serialNo: "",
      KeyErrorStatusSerialNo: false,
    }));
  };

  const handleSerialNoSave = () => {
    if (
      emoController.serialNo.length === 13 &&
      emoController.serialNo !== "" &&
      serialNoRegex.test(emoController.serialNo)
    ) {
      setEmoController(prevState => ({
        ...prevState,
        plcKeyPopUp: false,
      }));
      generateProgram();
    }
  };

  const generateProgram = () => {
    setLoading(true);

    apiSession
      .generatePLCProgram({
        projectId: sessionStorage.getItem("projectId")
          ? sessionStorage.getItem("projectId")
          : 29,
        serialNumber: emoController.serialNo,
      })
      .then(() => {
        downloadPlc();
      })
      .catch(error => {
        callSnackbar("error", error.response.data);
        if (error.response !== undefined) {
          if (error.response.status === 412) {
            callSnackbar("error", error.response.data.message);
          } else if (error.response.status === 403) {
            callSnackbar(
              "error",
              "Unauthorised to perform this action, Contact administrator."
            );
          } else {
            callSnackbar("error", "Failed to download");
          }
        } else {
          callSnackbar("error", "Error calling download PLC");
        }
        setLoading(false);
      });
  };

  const downloadPlc = () => {
    let projectId = sessionStorage.getItem("projectId")
      ? sessionStorage.getItem("projectId")
      : 29;
    let serialNumber = emoController.serialNo;
    apiSession
      .downloadPLCProgram(projectId, serialNumber)
      .then(response => {
        FileDownload(response, `${projectName}_EMOM.zip`);
        callSnackbar("success", "Downloaded successfully");
        setLoading(false);
        getDeploymentStatus();
      })
      .catch(error => {
        setLoading(false);

        if (error.response !== undefined) {
          if (error.response.status === 423) {
            callSnackbar("error", error.response.data.message);
          }
        } else {
          callSnackbar("error", "Failed to download");
        }
      });
  };

  //* HMI

  const downloadHMI = () => {
    if (!pagesValidated.programPage && !pagesValidated.systemsPage) {
      setLoading(true);

      apiSession
        .downloadHMI(sessionStorage.getItem("projectId"))
        .then(response => {
          FileDownload(response, `${projectName}_HMI.7z`);
          setLoading(false);
          callSnackbar("success", "Downloaded successfully");
        })
        .catch(error => {
          setLoading(false);
          if (error.response !== undefined) {
            if (error.response.status === 403) {
              callSnackbar(
                "error",
                "Unauthorised to perform this action, Contact administrator"
              );
            } else {
              callSnackbar("error", "Failed to download");
            }
          } else {
            callSnackbar("error", "Failed to download");
          }
        });
    } else {
      if (pagesValidated.programPage) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `Please validate use case to proceed further in Configure use cases.`
        );
      } else if (pagesValidated.systemsPage) {
        setAlertMessage(
          `Alert`,
          false,
          ``,
          `Please validate communication settings to proceed further.`
        );
      }
    }
  };

  //* Licence

  const handleLicenseChange = e => {
    const { name, value } = e.target;
    if (name === "expertKey") {
      setKeyExpert(prevState => ({
        ...prevState,
        KeyErrorStatusExpert: true,
      }));
    } else {
      setKeyOperations(prevState => ({
        ...prevState,
        KeyErrorStatusOperations: true,
      }));
    }

    if (value !== "") {
      if (alphanumericRegWithHyphen.test(value)) {
        if (name === "expertKey") {
          setKeyExpert(prevState => ({
            ...prevState,
            [name]: value,
          }));
        } else {
          setKeyOperations(prevState => ({
            ...prevState,
            [name]: value,
          }));
        }
      }
    } else {
      if (name === "expertKey") {
        setKeyExpert(prevState => ({
          ...prevState,
          [name]: "",
        }));
      } else {
        setKeyOperations(prevState => ({
          ...prevState,
          [name]: "",
        }));
      }
    }
  };

  const validateExpertKey = () => {
    if (keyExpert.expertKey.trim() !== "") {
      saveExpertKey();
    } else {
      callSnackbar("error", "License Key is mandatory");
    }
  };

  const saveExpertKey = () => {
    setLoading(true);

    let jsonBody = {
      projectId: sessionStorage.getItem("projectId"),
      activationKey: keyExpert.expertKey.toUpperCase(),
      type: "EXPERT",
    };

    setKeyExpert(prevState => ({
      ...prevState,
      expertKeyInvalid: false,
    }));

    apiSession
      .activateLicenseKey(jsonBody)
      .then(response => {
        setKeyExpert(prevState => ({
          ...prevState,
          expertKey: "",
          expertStatus: true,
          KeyErrorStatusExpert: false,
        }));
        callSnackbar("success", response.message);
        getDeploymentStatus();
      })
      .catch(error => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            setKeyExpert(prevState => ({
              ...prevState,
              expertKeyInvalid: true,
            }));
          }
          callSnackbar("error", error.response.data.message);
        } else {
          callSnackbar("error", "Error calling license API");
        }
        setLoading(false);

        setKeyExpert(prevState => ({
          ...prevState,
          expertStatus: false,
        }));
      });
  };

  const validateOpertaionsKey = () => {
    if (keyOperations.operationKey.trim() !== "") {
      saveOperationsKey();
    } else {
      callSnackbar("error", "License Key is mandatory");
    }
  };

  const saveOperationsKey = () => {
    setLoading(true);

    let jsonBody = {
      projectId: sessionStorage.getItem("projectId"),
      activationKey: keyOperations.operationKey.toUpperCase(),
      type: "OPERATIONS",
    };

    setKeyOperations(prevState => ({
      ...prevState,
      operationKeyInvalid: false,
    }));

    apiSession
      .activateLicenseKey(jsonBody)
      .then(response => {
        setKeyOperations(prevState => ({
          ...prevState,
          operationKey: "",
          operationsStatus: true,
          KeyErrorStatusOperations: false,
        }));
        callSnackbar("success", response.message);
        getDeploymentStatus();
      })
      .catch(error => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            setKeyOperations(prevState => ({
              ...prevState,
              operationKeyInvalid: true,
            }));
          }
          callSnackbar("error", error.response.data.message);
        } else {
          callSnackbar("error", "Error calling license API");
        }

        setLoading(false);
        setKeyOperations(prevState => ({
          ...prevState,
          operationsStatus: false,
        }));
      });
  };

  //* unlock

  const unlockDeployHandler = data => {
    if (UserScope.access.unlock === true) {
      apiSession
        .unlockDeploy(sessionStorage.getItem("projectId"), data.detail.selected)
        .then(response => {
          setUnlockDeploy(data.detail.selected);
          setKeyExpert(prevState => ({
            ...prevState,
            KeyErrorStatusExpert: false,
          }));
          setKeyOperations(prevState => ({
            ...prevState,
            KeyErrorStatusOperations: false,
          }));
          callSnackbar("success", "Unlock updated successfully");
        })
        .catch(error => {
          if (data.detail.selected === true) {
            setUnlockDeploy(false);
          }

          if (error.response.status === 412) {
            callSnackbar(
              "error",
              "Please ensure Assets Configuration is complete to deploy"
            );
          } else {
            callSnackbar("error", "Error in updating unlock");
          }
        });
    } else {
      callSnackbar("error", "Not Authorized");
    }
  };

  const confirmResetPlc = () => {
    setLoading(true);
    apiSession
      .resetPLC({ projectId: sessionStorage.getItem("projectId") })
      .then(() => {
        setResetPlcPopUpStatus(false);
        getDeploymentStatus();
        setLoading(false);
        callSnackbar("success", "Cleared project PLC count successfully");
      })
      .catch(() => {
        setLoading(false);
        setResetPlcPopUpStatus(false);
        callSnackbar("error", "Error in clear PLC count");
      });
  };

  //* alerts
  const callSnackbar = useCallback((type, message) => {
    setSnackBar({ type, message });
    snackBarRef.current.open = true;
  }, []);

  const setAlertMessage = (title, isRoute, routePath, message) => {
    setAlert({
      title,
      isRoute,
      routePath,
      message,
      alertPopUpStatus: true,
    });
  };

  const closeAlertMessage = useCallback(() => {
    setAlert({
      message: "",
      alertPopUpStatus: false,
      title: "",
      isRoute: false,
      routePath: "",
    });
    if (alert.isRoute) {
      let { history } = props;
      history.push({
        pathname: `/${alert.routePath}`,
      });
    }
  }, [alert, props]);

  return (
    <div className="container-fluid h-100">
      <se-loading loading={loading}></se-loading>
      {siteType && (
        <se-container option="centered">
          <div className="container mb-1 mt-1">
            <Services
              UserScope={UserScope}
              gridSetting={gridSetting}
              unlockDeploy={unlockDeploy}
              siteType={siteType}
              clientType={clientType}
              architectureType={architectureType}
              keyExpert={keyExpert}
              keyOperations={keyOperations}
              emaSiteStatus={emaSiteStatus}
              versions={versions}
              createSiteInEMA={createSiteInEMA}
              deleteSiteInEMA={deleteSiteInEMA}
              downloadIpcFile={downloadIpcFile}
              handleSerialNoPopUp={handleSerialNoPopUp}
              downloadHMI={downloadHMI}
            />
            <LicenceKeys
              UserScope={UserScope}
              gridSetting={gridSetting}
              unlockDeploy={unlockDeploy}
              siteType={siteType}
              architectureType={architectureType}
              keyExpert={keyExpert}
              keyOperations={keyOperations}
              handleLicenseChange={handleLicenseChange}
              validateExpertKey={validateExpertKey}
              validateOpertaionsKey={validateOpertaionsKey}
            />

            {UserScope.access.unlock === true ? (
              <div className="row mt-2 justify-content-center">
                <div className=" col-md-4 tab-content-deploy">
                  <div className="se-deploy-div">
                    <label>Unlock Deployment</label>&nbsp;&nbsp;&nbsp;&nbsp;
                    <SeCheckbox
                      value="true"
                      disabled={UserScope.access.unlock === true ? false : true}
                      option="switch"
                      selected={unlockDeploy}
                      onDidChange={e => unlockDeployHandler(e)}
                    ></SeCheckbox>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {((siteType === "GRID_CONNECTED"
              ? keyExpert.expertStatus
              : keyOperations.operationsStatus) ||
              unlockDeploy === true ||
              UserScope.access.unlockBypass === true) &&
            UserScope.access.resetPLC === true ? (
              <div className="row mt-2 justify-content-center">
                <div className="col-md-4 p-2">
                  <div className="row">
                    <div className="col-md-6 mt-1 my-auto text-right">
                      <span className="bold">Reset PLC Count</span>
                    </div>
                    <div className="col-md-6 mt-1 my-auto">
                      <se-button
                        size="small"
                        title="Reset PLC Count"
                        onClick={() => setResetPlcPopUpStatus(true)}
                      >
                        <se-icon size="small">data_refresh_stroke</se-icon>
                      </se-button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          {emoController.plcKeyPopUp && (
            <EmoController
              emoController={emoController}
              emoSerialNumber={emoSerialNumber}
              handleSerialNo={handleSerialNoChange}
              handleSerialNoPopUpClose={handleSerialNoPopUpClose}
              handleSerialNoSave={handleSerialNoSave}
            />
          )}

          <Snackbar snackBar={snackBar} ref={snackBarRef} />
          <AlertIcon alert={alert} onClose={closeAlertMessage} />

          <se-dialog
            id="no-project-alert-popup"
            open={confirm.deleteSiteAlertPopUp}
            can-backdrop="false"
          >
            <se-dialog-header>Confirmation</se-dialog-header>
            <se-dialog-content className="se-header-font-size-16px ">
              <se-icon color="primary" size="small">
                information_circle
              </se-icon>
              <span>
                &nbsp; EMA website is running for this project, Do you want to
                delete this EMA website ? If you delete, All site historical
                data will be lost.
              </span>
            </se-dialog-content>
            <se-dialog-footer>
              <se-button onClick={cancelDeleteSite}>NO</se-button>
              <se-button onClick={confirmDeleteSite}>Yes</se-button>
            </se-dialog-footer>
          </se-dialog>

          <se-dialog
            id="no-project-alert-popup"
            open={resetPlcPopUpStatus}
            can-backdrop="false"
          >
            <se-dialog-header>Confirmation</se-dialog-header>
            <se-dialog-content>
              <span className="se-font-14px">
                Do you confirm to clear count ?
              </span>
            </se-dialog-content>
            <se-dialog-footer>
              <se-button onClick={() => setResetPlcPopUpStatus(false)}>
                NO
              </se-button>
              <se-button onClick={confirmResetPlc}>Yes</se-button>
            </se-dialog-footer>
          </se-dialog>
        </se-container>
      )}
    </div>
  );
};

export default Deploy;
