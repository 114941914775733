import React, { Component } from "react";

class SLDEcc extends Component {
  render() {
    return [
      
      <svg>
        <g id="ECC">
          <svg x="553"
            y="294" >
            <g
              id="ECC"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g id="ECC" transform="translate(-532.000000, -514.000000)">
                <g
                  id="SLD/Infos/Microgrid_Background"
                  transform="translate(532.000000, 514.000000)"
                >
                  <circle
                        id="Oval"
                        stroke-width="3"
                        fill="#FFFFFF"
                        cx="48"
                        cy="56"
                        r="46.5"
                        stroke="#333333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></circle>
                      <text
                        id="segment_energy_infra"
                        font-family="se-icon"
                        font-size="56"
                        font-weight="normal"
                        fill="#333333"
                      >
                        <tspan x="20" y="76">
                        segment_electrical_energy
                        </tspan>
                      </text>
                </g>
              </g>
            </g>
          </svg>
        </g>
        
      </svg>
    ];
  }
}

export default SLDEcc;
