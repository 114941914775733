import React, { Component } from "react";
import Moment from "react-moment";
import moment from "moment";

class SECard extends Component {
  deleteProject = (e, projectId, projectName) => {
    this.props.onDeleteProject(e, projectId, projectName);
    e.stopPropagation();
  };

  copyProject = (e, projectId, projectName) => {
    this.props.onCopyProject(e, projectId, projectName);
    e.stopPropagation();
  };

  favouriteProject = (e, projectId, favouriteStatus) => {
    this.props.onFavouriteProject(e, projectId, favouriteStatus);
    e.stopPropagation();
  };

  getFirstCharF(str) {
    if (str === null || str === "") {
      str = "N";
    }
    return str.charAt(0).toUpperCase();
  }

  getFirstCharL(str) {
    if (str === null || str === "") {
      str = "A";
    }
    return str.charAt(0).toUpperCase();
  }

  render() {
    const { data, onCardSelect, deleteProjectStatus, copyProjectStatus } =
      this.props;
    data.createdOn = moment(
      new Date(data.createdOn + " UTC").toString()
    ).format("YYYY/MM/DD");
    data.lastModificationDate = moment(
      new Date(data.lastModificationDate + " UTC").toString()
    ).format("YYYY/MM/DD");
    return [
      <div
        className="col-sm-12 col-md-12 col-lg-12 col-xl-12 border listStyle mb-1 pt-2 pb-2"
        onClick={onCardSelect}
      >
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
          <div className="projectTitle">
            <div className="row">
              <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                <span className="upper bold">{data.projectName}</span>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4 text-right">
                <span className={data.favourite === true ? "favourite" : ""}>
                  <se-icon
                    className="cheatSheetIcon se-icon icon_24 "
                    onClick={e =>
                      this.favouriteProject(e, data.projectId, data.favourite)
                    }
                  >
                    {data.favourite === true ? "star_plain" : "star_stroke"}
                  </se-icon>
                </span>
                <div style={{ width: "10px !important", float: "right" }}>
                  <se-dropdown alignment="right">
                    <se-icon slot="trigger" option="button">
                      other_vertical
                    </se-icon>
                    <se-list option="dropdown">
                      {deleteProjectStatus === true ? (
                        <se-list-item
                          item="Delete"
                          onClick={e =>
                            this.deleteProject(
                              e,
                              data.projectId,
                              data.projectName
                            )
                          }
                        ></se-list-item>
                      ) : (
                        ""
                      )}
                      {copyProjectStatus && (
                        <se-list-item
                          item="Copy"
                          onClick={e =>
                            this.copyProject(
                              e,
                              data.projectId,
                              data.projectName
                            )
                          }
                        ></se-list-item>
                      )}
                    </se-list>
                  </se-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
          <div className="row">
            <div className="col-sm-5 col-md-5 col-lg-5 col-xl-4 project-avatar-container my-auto">
              <span
                className={
                  data.userCount === 0
                    ? "icon-display-no-count"
                    : "icon-display"
                }
              >
                <se-icon color="primary">user_standard</se-icon>
              </span>
              <span className="pl-1 pr-1 mr-1">{data.userCount}</span>
              {data.users.map(userdetails =>
                this.props.userImages[userdetails.userId] === "" ||
                this.props.userImages[userdetails.userId] === null ||
                this.props.userImages[userdetails.userId] === undefined ? (
                  <div className="user-avatar -small -initial">
                    {this.getFirstCharF(userdetails.firstName)}
                    {this.getFirstCharL(userdetails.lastName)}
                  </div>
                ) : (
                  <div
                    className="user-avatar -small"
                    style={{
                      backgroundImage:
                        "url(" +
                        this.props.userImages[userdetails.userId] +
                        ")",
                    }}
                  ></div>
                )
              )}
            </div>

            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 px-0 text-right">
                  <span> Created </span>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 text-left">
                  <span>
                    <Moment format="DD/MM/YYYY" date={data.createdOn} />
                  </span>
                </div>
                <div className="offset-md-6 col-sm-6 col-md-6 col-lg-6">
                  <span className="text-left break-word">
                    {data.createdBy.firstName} {data.createdBy.lastName}
                  </span>
                </div>
              </div>
            </div>

            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 px-0 text-right">
                  <span>Last modification</span>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 text-left">
                  <span>
                    <Moment
                      format="DD/MM/YYYY"
                      date={data.lastModificationDate}
                    />
                  </span>
                </div>
                <div className="offset-md-6 col-sm-6 col-md-6 col-lg-6 pr-0">
                  <span className="text-left break-word">
                    {data.lastModifiedBy.firstName}{" "}
                    {data.lastModifiedBy.lastName}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>,
    ];
  }
}

export default SECard;
