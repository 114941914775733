import React, { useContext } from "react";
import embLabels from "../../config/emb-labels-en.json";
import gensetImage from "../../images/Genset-image-SE.jpg";

import { AssetBlockContext } from "../CustomHooks/ContextApi";
import isTouchDevice from "../SE-Component/Tooltip";
const GensetAsset = () => {
  const {
    siteType,
    assetDetailsDisplay,
    setAssetSelection,
    configData,
    handleGensetChange,
    defaultsMinMax,
    updateGensetConfig,
    checkGensetRemoveCondition,
  } = useContext(AssetBlockContext);

  const setAssetHandler = () => setAssetSelection(embLabels.GENERATOR);

  const gensetChangeHandler = e => {
    handleGensetChange(e);
  };
  return (
    <div name="genset" className="col-md-12 p-0">
      <div className="col-md-12 pt-2 se-white-background">
        <div className="row">
          <div className="col-md-8 pb-2 pl-0 ">
            <span>
              <se-icon
                color="primary"
                className="se-icon icon_button se-life-green"
                size="medium"
              >
                {" "}
                genset_01{" "}
              </se-icon>
              <span className="ml-2 bold align-top line-height-30px">
                Genset
              </span>
            </span>
          </div>
          <div className="col-md-4">
            <span className="float-right">
              <button
                type="button"
                className={`btn btn-secondary btn-sm ${
                  siteType === embLabels.PV_HYBRIDIZATION
                    ? "notAllowed"
                    : "pointer"
                }`}
                onClick={checkGensetRemoveCondition}
                disabled={
                  siteType === embLabels.PV_HYBRIDIZATION ? "disabled" : ""
                }
              >
                Remove
              </button>
            </span>
          </div>
        </div>
      </div>
      <div className="col-md-12 col-sm-12 pt-2 pb-1 se-grey-background se-border">
        <div
          className="row"
          style={assetDetailsDisplay.manufacturer ? {} : { display: "none" }}
        >
          <div className="col-md-3">
            {assetDetailsDisplay.imageLink === null ? (
              assetDetailsDisplay.manufacturer ===
              "STANDARD GENSET TEMPLATE" ? (
                <img
                  src={gensetImage}
                  className="asset-image-display"
                  alt={assetDetailsDisplay.manufacturer}
                />
              ) : (
                <se-icon color="primary" size="large">
                  genset_01
                </se-icon>
              )
            ) : (
              <img
                src={assetDetailsDisplay.imageLink}
                className="asset-image-display"
                alt={assetDetailsDisplay.manufacturer}
              />
            )}
          </div>

          <div className="col-md-9">
            <div className="row">
              <div className="col-md-12 bold ml-0">
                {assetDetailsDisplay.manufacturer}
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 text-break">
                {assetDetailsDisplay.communicationModel}
              </div>
              <div className="col-md-4 text-right">
                <se-tooltip
                  position="left"
                  action={isTouchDevice() ? "click" : "hover"}
                >
                  <se-icon option="raised" color="secondary" slot="tooltip">
                    information_circle
                  </se-icon>

                  <se-tooltip-content>Genset</se-tooltip-content>
                </se-tooltip>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={assetDetailsDisplay.manufacturer ? { display: "none" } : {}}
        >
          <div className="ml-2 mb-2 asset-selection"> No assets selected</div>
        </div>
        <div className="row">
          <div className=" col-md col-sm col-lg text-center">
            <se-button option="outline" size="small" onClick={setAssetHandler}>
              Asset Selection
            </se-button>
          </div>
        </div>
      </div>
      <div style={assetDetailsDisplay.manufacturer ? {} : { display: "none" }}>
        <div className="col-md-12 se-white-background align-middle se-border">
          <div className="row">
            <label className=" col-md-6 bold mt-2 mb-2 pl-1 se-font-14px">
              Asset Settings
            </label>
            <div className="col-md-6 mt-1 text-right">
              <button
                type="button"
                className="btn btn-secondary btn-sm se-btn-primary"
                onClick={updateGensetConfig}
              >
                <se-icon size="nano">action_save</se-icon>
                <span>&nbsp;</span>Save
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12 pt-2 pb-2 se-grey-background">
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Name <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              name="name"
              maxLength="50"
              placeholder="Name"
              value={configData.configData.name}
              onChange={gensetChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={!configData.configData.name ? {} : { display: "none" }}
            >
              {embLabels.nameRequired}
            </div>
          </div>
          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">Description</label>
            <input
              type="text"
              autoComplete="none"
              className="form-control form-control-sm"
              id="exampleFormControlInput1"
              name="description"
              maxLength="100"
              placeholder="Description"
              value={configData.configData.description}
              onChange={gensetChangeHandler}
            />
          </div>

          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput1">
              Max Power(kW) <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput1"
              name="max_power"
              maxLength="4"
              placeholder="Max Power(kW)"
              value={configData.configData.max_power}
              onChange={gensetChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={
                !configData.configData.max_power ? {} : { display: "none" }
              }
            >
              Max Power(kW) is required.
            </div>
            {configData.configData.max_power !== "" &&
            (parseInt(configData.configData.max_power) <
              defaultsMinMax.maxPowerGensetMin ||
              parseInt(configData.configData.max_power) >
                defaultsMinMax.maxPowerGensetMax) ? (
              <div className="invalid-feedback-custom">
                Max Power should be in range(
                {defaultsMinMax.maxPowerGensetMin}-
                {defaultsMinMax.maxPowerGensetMax} kW).
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput2">
              {" "}
              Start-up Duration (s) <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput2"
              name="starting_timer"
              maxLength="10"
              placeholder="Start-up duration in s"
              value={configData.configData.starting_timer}
              onChange={gensetChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={
                !configData.configData.starting_timer ? {} : { display: "none" }
              }
            >
              Start-up duration in s is required.
            </div>
            {configData.configData.starting_timer !== "" &&
            (parseInt(configData.configData.starting_timer) <
              defaultsMinMax.startUpGensetMin ||
              parseInt(configData.configData.starting_timer) >
                defaultsMinMax.startUpGensetMax) ? (
              <div className="invalid-feedback-custom">
                Start-up duration should be in range(
                {defaultsMinMax.startUpGensetMin}-
                {defaultsMinMax.startUpGensetMax} s).
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput2">
              Cooling Duration (s) <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput2"
              name="cooling_timer"
              maxLength="10"
              placeholder="Cooling Duration in s"
              value={configData.configData.cooling_timer}
              onChange={gensetChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={
                configData.configData.cooling_timer === ""
                  ? {}
                  : { display: "none" }
              }
            >
              Cooling Duration in s is required.
            </div>
            {configData.configData.cooling_timer !== "" &&
            (parseInt(configData.configData.cooling_timer) <
              defaultsMinMax.coolingDurationGensetMin ||
              parseInt(configData.configData.cooling_timer) >
                defaultsMinMax.coolingDurationGensetMax) ? (
              <div className="invalid-feedback-custom">
                Cooling Duration should be in range(
                {defaultsMinMax.coolingDurationGensetMin}-
                {defaultsMinMax.coolingDurationGensetMax} s).
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput2">
              Minimum running duration (s){" "}
              <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput2"
              name="minimum_running_period"
              maxLength="10"
              placeholder="Minimum running duration in s"
              value={configData.configData.minimum_running_period}
              onChange={gensetChangeHandler}
            />
            <div
              className="invalid-feedback-custom"
              style={
                configData.configData.minimum_running_period === ""
                  ? {}
                  : { display: "none" }
              }
            >
              Minimum running duration in s is required.
            </div>
            {configData.configData.minimum_running_period !== "" &&
            (parseInt(configData.configData.minimum_running_period) <
              defaultsMinMax.minRunningDurationGensetMin ||
              parseInt(configData.configData.minimum_running_period) >
                defaultsMinMax.minRunningDurationGensetMax) ? (
              <div className="invalid-feedback-custom">
                Minimum running duration should be in range(
                {defaultsMinMax.minRunningDurationGensetMin}-
                {defaultsMinMax.minRunningDurationGensetMax} s).
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="form-group mb-1">
            <label htmlFor="exampleFormControlInput2">
              Genset CO2 Rate (kg/MWh) <span className="se-mandatory">*</span>
            </label>
            <input
              type="text"
              autoComplete="none"
              className={"form-control form-control-sm "}
              id="exampleFormControlInput2"
              name="gensetCO2"
              maxLength="4"
              placeholder="Genset CO2 Rate"
              value={configData.configData.gensetCO2}
              onChange={gensetChangeHandler}
            />
            {configData.configData.gensetCO2 === "" ? (
              <div className="invalid-feedback-custom">
                Genset CO2(kg/MWh) is required.
              </div>
            ) : (
              ""
            )}

            {configData.configData.gensetCO2 !== "" &&
            (parseInt(configData.configData.gensetCO2) <
              defaultsMinMax.gensetCO2Min ||
              parseInt(configData.configData.gensetCO2) >
                defaultsMinMax.gensetCO2Max) ? (
              <div className="invalid-feedback-custom">
                Genset CO2 should be in range(
                {defaultsMinMax.gensetCO2Min}-{defaultsMinMax.gensetCO2Max}{" "}
                kg/MWh).
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GensetAsset;
