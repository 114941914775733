import React, { useState, useCallback, useRef } from "react";
import moment from "moment";

//* Custom hooks
import userScope from "./CustomHooks/userScope";
import Snackbar from "./SE-Component/Snackbar";
import Confirm from "./SE-Component/Confirm";
import Alert from "./SE-Component/Alert";
import useMountEffect from "./CustomHooks/useMountEffect";
import { InformationPageProvider } from "./CustomHooks/ContextApi";
import embLabels from "../config/emb-labels-en.json";

//* child import
import ProjectInformation from "./InformationPages/ProjectInformation";
import ProjectType from "./InformationPages/ProjectType";
import SiteInformation from "./InformationPages/SiteInformation";
import InviteUser from "../components/InviteUser";

import _ from "lodash";
import LocationFinder from "mg-ui-location";

//*api call
import ApiSession from "../api/ApiSession";
const apiSession = new ApiSession();

const alphanumericRegWithHyphen = /^[-_0-9a-zA-Z]+$/;

const InformationHook = props => {
  //* State hooks
  const [UserScope] = userScope();

  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(true);
  const [mandatoryMsgDisplay, setMandatoryMsgDisplay] = useState(false);
  const [disableValidateProject, setDisableValidateProject] = useState(false);
  const [projectCreateStatus, setProjectCreateStatus] = useState(true);
  const [renderLocationComponent, setRenderLocationComponent] = useState(false);
  const [siteCreated, setSiteCreated] = useState(false);
  const [projectCreated] = useState(
    JSON.parse(sessionStorage.getItem("projectId")) ? true : false
  );

  const [createdOn, setCreatedOn] = useState(
    moment(new Date().toString()).format("YYYY/MM/DD HH:mm:ss")
  );
  const [projectType, setProjectType] = useState("");
  const [siteType, setSiteType] = useState("");
  const [clientType, setClientType] = useState("");
  const [architectureType, setArchitectureType] = useState(null);
  const [optimizationEngine, setOptimizationEngine] = useState("");
  const [otherOptimizationStrategy, setOtherOptimizationStrategy] =
    useState("");

  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [currencyList, setCurrencyList] = useState([]);
  const [users, setUsers] = useState([]);

  const [projectInformation, setProjectInformation] = useState({
    projectName: "",
    clientName: "",
    siteName: "",
    currency: "Dollars",
    temperatureUnit: "Fahrenheit °F",
    description: "",
    externalId: "",
  });
  const [informationToolTIP, setInformationToolTIP] = useState({
    standardToolTipData: "",
    packagedToolTipData: "",
    gridConnectedTooltipData: "",
    islandableTooltipData: "",
    offGridTooltipData: "",
    pvHybridizationToolTip: "",
    pvGensetAtsToolTip: "",
    demandChargeTooltipData: "",
    tariffManagementTooltipData: "",
    exportRealTimeTooltipData: "",
    exportOptimizationTooltipData: "",
    selfConsumptionTooltipData: "",
    fuelSaverTooltipData: "",
    noFuelTooltipData: "",
    fuelEraserTooltipData: "",
    stromPreparednessTooltipData: "",
    remoteMonitoringTooltipData: "",
  });
  const [disabledFeatures, setDisabledFeatures] = useState({
    disableCloud: false,
    disableOpertaions: false,
  });
  const [gridSettings, setGridSettings] = useState({
    demandCharge: false,
    tariffManagement: false,
    selfConsumption: false,
    exportOptimization: false,
    noExport: false,
    remoteMonitoringControl: false,
    mode: "",
    gridTransitionOutage: "OPEN",
    manualSwitch: "OPEN",
    gridTransitionOnline: "OPEN",
    gensetBattery: "OPEN",
  });
  const [siteInformation, setSiteInformation] = useState({
    userInterface: "12",
    gridNominalFrequency: 60,
    gridNominalVoltage: 480,
  });
  const [locationData, setLocationData] = useState({
    zipCode: "",
    timezone: "",
    address: "",
    country: "",
    city: "",
    stateProvince: "",
    latitude: "",
    longitude: "",
  });
  const [userRoles, setUserRoles] = useState({
    type: "addUserToProject",
    addUserPopUpstatus: false,
    addUserPopUpLoading: false,
    addUserPopUpList: [],
    rolesList: [],
    rolesPermission: {},
  });
  const [deletingUserInfo, setDeletingUserInfo] = useState({
    deleteingRole: "",
    deleteingUserId: "",
  });
  const [previousValues, setPreviousValues] = useState({
    projectType: "",
    siteType: "",
    useCaseChanged: false,
    projectChanged: false,
    demandCharge: false,
    tariffManagement: false,
    selfConsumption: false,
    exportOptimization: false,
    noExport: false,
    mode: "",
    gridTransitionOnline: "",
    gridTransitionOutage: "",
    manualSwitch: "",
    gensetBattery: "",
    userInterface: "",
    remoteMonitoringControl: {
      GRID_CONNECTED: false,
      ISLANDABLE: true,
      OFF_GRID: true,
      PV_HYBRIDIZATION: true,
    },
  });

  const [snackBar, setSnackBar] = useState({ type: "", message: "" });
  const [deleteImagePopUpStatus, setDeleteImagePopUpStatus] = useState({
    confirmMessage: "",
    confirmPopupStatus: false,
  });
  const [deleteInvitedUserPopUpStatus, setDeleteInvitedUserPopUpStatus] =
    useState({
      confirmMessage: "",
      confirmPopupStatus: false,
    });
  const [alert, setAlert] = useState({
    message: "",
    alertPopUpStatus: false,
    title: "",
    isRoute: false,
    routePath: "",
  });

  const snackBarRef = useRef(null);

  //* Effect hook

  useMountEffect(() => {
    setLoading(true);
    localStorage.setItem("currency", "$");
    localStorage.setItem("useCaseChanged", false);
    localStorage.setItem("projectChanged", false);
    localStorage.setItem("userCountry", "France");
    getCurrencyList();
    loadToolTip();
  }, []);

  const getCurrencyList = () => {
    apiSession
      .getDefaultInfo()
      .then(json => {
        json.sort((a, b) => a.status.localeCompare(b.status));
        setCurrencyList(json);
        if (sessionStorage.getItem("projectId") !== "null") {
          setProjectCreateStatus(false);
          fetchProject(json);
        } else {
          localStorage.setItem("pname", null);
          setLoadingImage(false);
          setOptimizationEngine("EMA");
          setRenderLocationComponent(true);
          setLoading(false);
          if (UserScope.role === embLabels.GREENSTRUXURE) {
            setProjectType("GREENSTRUXURE_MICROGRID");
          }
        }
      })
      .catch(() => {});
  };

  const loadToolTip = () => {
    setLoading(true);
    apiSession
      .getToolTip("information", "EN")
      .then(json => {
        setInformationToolTIP({
          standardToolTipData: json.standardToolTipData.data,
          packagedToolTipData: json.packagedToolTipData.data,
          gridConnectedTooltipData: json.gridConnected.data,
          islandableTooltipData: json.islandable.data,
          offGridTooltipData: json.offGrid.data,
          pvHybridizationToolTip: json.pvHybridizationToolTip.data,
          pvGensetAtsToolTip: json.pvGensetAtsToolTip.data,
          demandChargeTooltipData: json.demandCharge.data,
          tariffManagementTooltipData: json.tariffManagement.data,
          exportRealTimeTooltipData: json.exportRealTime.data,
          exportOptimizationTooltipData: json.exportOptimization.data,
          selfConsumptionTooltipData: json.selfConsumption.data,
          fuelSaverTooltipData: json.fuelSaver.data,
          noFuelTooltipData: json.noFuel.data,
          fuelEraserTooltipData: json.fuelEraser.data,
          stromPreparednessTooltipData: json.stromPreparedness.data,
          remoteMonitoringTooltipData: json.remoteMonitoring.data,
        });
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchProject = currencies => {
    setLoading(true);
    apiSession
      .getProject(sessionStorage.getItem("projectId"))
      .then(json => {
        downloadImage();
        setProjectDetails(currencies, json);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const downloadImage = e => {
    setLoadingImage(true);
    apiSession
      .getProjectImage(sessionStorage.getItem("projectId"))
      .then(response => {
        let arrayBufferView = new Uint8Array(response);
        let blob = new Blob([arrayBufferView], { type: "image/jpeg" });
        let urlCreator = window.URL || window.webkitURL;
        let imageUrl = urlCreator.createObjectURL(blob);

        setImagePreviewUrl(imageUrl);
        setLoadingImage(false);
      })
      .catch(() => {
        setLoadingImage(false);
      });
  };

  const setProjectDetails = (currencies, projectDetails) => {
    localStorage.setItem("programPage", projectDetails.programPage);
    localStorage.setItem("deploymentReady", projectDetails.deploymentReady);
    localStorage.setItem("pname", projectDetails.projectName);
    localStorage.setItem("communicationValidated", projectDetails.systemsPage);

    let rawDate = new Date(projectDetails.createdOn + " UTC");
    let createdOnDate = rawDate.toString();
    createdOnDate = moment(createdOnDate).format("YYYY/MM/DD HH:mm:ss");
    setCreatedOn(createdOnDate);

    currencies.forEach(currencyData => {
      if (projectDetails.projectInfo.currency === currencyData.name) {
        localStorage.setItem("currency", currencyData.symbol);
      }
    });

    setProjectInformation(prevState => ({
      ...prevState,
      projectName: projectDetails.projectName,
      currency: projectDetails.projectInfo.currency,
      temperatureUnit: projectDetails.projectInfo.temperatureUnit,
      description: projectDetails.projectInfo.description,
      clientName: projectDetails.projectInfo.clientName,
      siteName: projectDetails.projectInfo.siteName,
      externalId: projectDetails.externalId,
    }));

    setProjectType(projectDetails.projectInfo.projectType);
    setSiteType(projectDetails.projectInfo.siteType);
    setClientType(projectDetails.clientType);
    setArchitectureType(projectDetails.architecture);

    const disableCloud = projectDetails.licenses.indexOf("EXPERT");
    const disableOpertaions = projectDetails.licenses.indexOf("OPERATIONS");
    setDisabledFeatures(prevState => ({
      ...prevState,
      disableOpertaions: disableOpertaions === -1 ? false : true,
      disableCloud: disableCloud === -1 ? false : true,
    }));

    setOptimizationEngine(
      projectDetails.server === null ||
        projectDetails.server.optimizationType === "EMA"
        ? "EMA"
        : "OTHER"
    );
    setOtherOptimizationStrategy(
      projectDetails.server.optimizationType === "AUTOGRID" ? "AUTOGRID" : ""
    );
    setGridSettings(prevState => ({
      ...prevState,
      demandCharge: projectDetails.projectInfo.gridSettings.demandCharge,
      tariffManagement:
        projectDetails.projectInfo.gridSettings.tariffManagement,
      selfConsumption: projectDetails.projectInfo.gridSettings.selfConsumption,
      exportOptimization:
        projectDetails.projectInfo.gridSettings.exportOptimization,
      noExport: projectDetails.projectInfo.gridSettings.noExport,
      remoteMonitoringControl:
        projectDetails.projectInfo.gridSettings.remoteMonitoringControl,
      mode: projectDetails.projectInfo.gridSettings.mode,
      gridTransitionOnline:
        projectDetails.projectInfo.gridSettings.gridTransitionOnline !== null
          ? projectDetails.projectInfo.gridSettings.gridTransitionOnline
          : gridSettings.gridTransitionOnline,
      gridTransitionOutage:
        projectDetails.projectInfo.gridSettings.gridTransitionOutage !== null
          ? projectDetails.projectInfo.gridSettings.gridTransitionOutage
          : gridSettings.gridTransitionOutage,
      manualSwitch:
        projectDetails.projectInfo.gridSettings.manualSwitch !== null
          ? projectDetails.projectInfo.gridSettings.manualSwitch
          : gridSettings.manualSwitch,
      gensetBattery:
        projectDetails.projectInfo.gridSettings.gensetBattery !== null
          ? projectDetails.projectInfo.gridSettings.gensetBattery
          : gridSettings.gensetBattery,
    }));

    setSiteInformation(prevState => ({
      ...prevState,
      userInterface: projectDetails.projectInfo.userInterface,
      gridNominalFrequency: projectDetails.projectInfo.gridNominalFrequency,
      gridNominalVoltage: projectDetails.projectInfo.gridNominalVoltage,
    }));

    setUsers(projectDetails.users);

    setPreviousValues(prevState => ({
      ...prevState,
      projectType: projectDetails.projectInfo.projectType,
      siteType: projectDetails.projectInfo.siteType,
      demandCharge: projectDetails.projectInfo.gridSettings.demandCharge,
      tariffManagement:
        projectDetails.projectInfo.gridSettings.tariffManagement,
      selfConsumption: projectDetails.projectInfo.gridSettings.selfConsumption,
      exportOptimization:
        projectDetails.projectInfo.gridSettings.exportOptimization,
      noExport: projectDetails.projectInfo.gridSettings.noExport,
      mode: projectDetails.projectInfo.gridSettings.mode,
      gridTransitionOnline:
        projectDetails.projectInfo.gridSettings.gridTransitionOnline,
      gridTransitionOutage:
        projectDetails.projectInfo.gridSettings.gridTransitionOutage,
      manualSwitch: projectDetails.projectInfo.gridSettings.manualSwitch,
      gensetBattery: projectDetails.projectInfo.gridSettings.gensetBattery,
      userInterface: projectDetails.projectInfo.userInterface,
      remoteMonitoringControl: {
        ...previousValues.remoteMonitoringControl,
        [projectDetails.projectInfo.siteType]:
          projectDetails.projectInfo.gridSettings.remoteMonitoringControl,
      },
    }));

    //* SETTING UP THE LOCATION

    localStorage.setItem("userCountry", projectDetails.location.country);

    setLocationData(prevState => ({
      ...prevState,
      address: projectDetails.location.address,
      city: projectDetails.location.city,
      zipCode: projectDetails.location.zipCode,
      stateProvince: projectDetails.location.stateProvince,
      country: projectDetails.location.country,
      latitude: projectDetails.location.latitude,
      longitude: projectDetails.location.longitude,
      timezone: projectDetails.location.timezone,
    }));
    setRenderLocationComponent(true);

    setSiteCreated(projectDetails.siteCreated);

    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  //* Project infromation START

  const projectChangeHandler = e => {
    const value = e.target.value.replace(/ +(?= )/g, "");
    const name = e.target.name;
    if (name === "externalId") {
      if (value.trim() !== "") {
        if (alphanumericRegWithHyphen.test(value)) {
          setProjectInformation(prevState => ({
            ...prevState,
            [name]: value,
          }));
        }
      } else {
        setProjectInformation(prevState => ({
          ...prevState,
          externalId: "",
        }));
      }
    } else {
      setProjectInformation(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const fileChangedHandler = e => {
    if (e.target.files.length) {
      if (e.target.files[0].size > 1000000) {
        callSnackbar(`error`, `Image size should be less than 1MB`);
        e.target.value = null;
      } else if (
        e.target.files[0].type !== "image/png" &&
        e.target.files[0].type !== "image/jpg" &&
        e.target.files[0].type !== "image/jpeg" &&
        e.target.files[0].type !== "image/gif" &&
        e.target.files[0].type !== "image/tiff"
      ) {
        callSnackbar(
          `error`,
          `Invalid file format, Please select .jpg/.jpeg/.png/.tiff/.gif file`
        );
        e.target.value = null;
      } else {
        setSelectedFile(e.target.files[0]);
        if (sessionStorage.getItem("projectId") === "null") {
          //* do nothing
        } else {
          uploadProjectImage(e.target.files[0]);
        }
        let reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  //* Before project is validated
  const uploadProjectImage = uploadFile => {
    let fd = new FormData();
    fd.append("file", uploadFile);
    fd.append("projectId", sessionStorage.getItem("projectId"));

    apiSession
      .uploadProjectImage(fd)
      .then(() => {
        callSnackbar("success", "Project image uploaded successfully");
      })
      .catch(() => {
        callSnackbar("error", "Error in upload");
      });
  };

  //* after project is validated

  const uploadImage = () => {
    let fd = new FormData();
    fd.append("file", selectedFile);
    fd.append("projectId", sessionStorage.getItem("projectId"));

    apiSession
      .uploadProjectImage(fd)
      .then(() => {
        setAlertMessage(
          `Project Status`,
          true,
          `assets`,
          `Project ${
            projectCreateStatus ? "created" : "updated"
          } successfully. `
        );
      })
      .catch(() => {});
  };

  const removeProjectImagePopUp = () => {
    setDeleteImage(true, `Do you confirm to delete project image ?`);
  };

  const closeDeleteImagePopUp = () => {
    setDeleteImage(false, ``);
  };

  const removeProjectImage = () => {
    if (sessionStorage.getItem("projectId") === "null") {
      setSelectedFile(null);
      setImagePreviewUrl(null);
      setDeleteImage(false, ``);
    } else {
      setLoading(true);
      setDeleteImage(false, ``);

      apiSession
        .deleteProjectImage(sessionStorage.getItem("projectId"))
        .then(json => {
          setSelectedFile(null);
          setImagePreviewUrl(null);
          callSnackbar("success", "Project image deleted successfully");
          setLoading(false);
        })
        .catch(() => {
          callSnackbar("error", "Error in deletion");
          setLoading(false);
        });
    }
  };

  //* Project infromation END

  //* PROJECT TYPE START

  const handleProjectTypeChange = e => {
    const { value } = e.target;
    setProjectType(e.target.value);
    setSiteType(sessionStorage.projectId === "null" ? "" : siteType);
    setOptimizationEngine(
      e.target.value === embLabels.PACKAGED_MICROGRID
        ? "EMA"
        : optimizationEngine
    );

    if (
      sessionStorage.projectId === "null" &&
      e.target.value === embLabels.PACKAGED_MICROGRID
    ) {
      setGridSettings(prevState => ({
        ...prevState,
        demandCharge: false,
        tariffManagement: false,
        selfConsumption: false,
        exportOptimization: false,
        noExport: true,
        remoteMonitoringControl: true,
        mode: "",
        gridTransitionOutage: "OPEN",
        manualSwitch: "OPEN",
        gridTransitionOnline: "OPEN",
        gensetBattery: "OPEN",
      }));
      setSiteInformation(prevState => ({
        ...prevState,
        userInterface: "none",
        gridNominalFrequency: 60,
        gridNominalVoltage: 480,
      }));
    }

    if (
      sessionStorage.projectId === "null" &&
      e.target.value === embLabels.STANDARD_MICROGRID
    ) {
      setGridSettings(prevState => ({
        ...prevState,
        demandCharge: false,
        tariffManagement: false,
        selfConsumption: false,
        exportOptimization: false,
        noExport: false,
        remoteMonitoringControl: true,
        mode: "",
        gridTransitionOutage: "OPEN",
        manualSwitch: "OPEN",
        gridTransitionOnline: "OPEN",
        gensetBattery: "OPEN",
      }));
      setSiteInformation(prevState => ({
        ...prevState,
        userInterface: "12",
        gridNominalFrequency: 60,
        gridNominalVoltage: 480,
      }));
    }

    if (
      sessionStorage.projectId !== "null" &&
      previousValues.projectType !== e.target.value
    ) {
      setAlertMessage(
        `Project Change Alert`,
        false,
        "",
        `Project change will affect asset's design and configuration !`
      );
      setPreviousValues(prevState => ({
        ...prevState,
        projectChanged: true,
        useCaseChanged: true,
      }));
      setSiteType("");
      setGridSettings(prevState => ({
        ...prevState,
        demandCharge: false,
        tariffManagement: false,
        selfConsumption: false,
        exportOptimization: false,
        noExport: value === embLabels.PACKAGED_MICROGRID,
        remoteMonitoringControl: true,
        mode: "",
        gridTransitionOnline: "OPEN",
        gridTransitionOutage: "OPEN",
        manualSwitch: "OPEN",
        gensetBattery: "OPEN",
      }));

      setSiteInformation(prevState => ({
        ...prevState,
        userInterface: value === embLabels.PACKAGED_MICROGRID ? "none" : "12",
      }));
    }
    if (
      sessionStorage.projectId !== "null" &&
      previousValues.projectType === value
    ) {
      setSiteType(previousValues.siteType);
      setPreviousValues(prevState => ({
        ...prevState,
        projectChanged: false,
        useCaseChanged: false,
      }));
      setGridSettings(prevState => ({
        ...prevState,
        demandCharge: previousValues.demandCharge,
        tariffManagement: previousValues.tariffManagement,
        selfConsumption: previousValues.selfConsumption,
        exportOptimization: previousValues.exportOptimization,
        noExport: previousValues.noExport,
        remoteMonitoringControl: true,
        gridTransitionOutage: previousValues.gridTransitionOutage,
        mode: previousValues.mode,
        gridTransitionOnline: previousValues.gridTransitionOnline,
        manualSwitch: previousValues.manualSwitch,
        gensetBattery: previousValues.gensetBattery,
      }));

      setSiteInformation(prevState => ({
        ...prevState,
        userInterface: previousValues.userInterface,
      }));
    }
  };

  const handleSiteTypeChange = e => {
    const { value } = e.target;
    setSiteType(e.target.value);
    if (e.target.value === embLabels.OFF_GRID) {
      setGridSettings(prevState => ({
        ...prevState,
        mode: "",
      }));
    }

    if (sessionStorage.projectId === "null") {
      const remoteMonitoringControl =
        previousValues.remoteMonitoringControl[e.target.value];
      setGridSettings(prevState => ({
        ...prevState,
        remoteMonitoringControl,
      }));
      setSiteInformation(prevState => ({
        ...prevState,
        userInterface: value === embLabels.GRID_CONNECTED ? "none" : "12",
      }));
    }

    if (
      sessionStorage.projectId !== "null" &&
      projectType === previousValues.projectType &&
      previousValues.siteType !== value
    ) {
      setAlertMessage(
        `Use Case Change Alert`,
        false,
        "",
        `Use case change will affect asset's design and configuration !`
      );
      setPreviousValues(prevState => ({ ...prevState, useCaseChanged: true }));

      setGridSettings(prevState => ({
        ...prevState,
        demandCharge: false,
        tariffManagement: false,
        selfConsumption: false,
        exportOptimization: false,
        noExport: false,
        mode: "",
        gridTransitionOnline: "OPEN",
        gridTransitionOutage: "OPEN",
        manualSwitch: "OPEN",
        gensetBattery: "OPEN",
        remoteMonitoringControl: value === embLabels.isIslandable,
      }));
    }

    if (
      sessionStorage.projectId !== "null" &&
      previousValues.siteType === value
    ) {
      setPreviousValues(prevState => ({ ...prevState, useCaseChanged: false }));

      setGridSettings(prevState => ({
        ...prevState,
        demandCharge: previousValues.demandCharge,
        tariffManagement: previousValues.tariffManagement,
        selfConsumption: previousValues.selfConsumption,
        exportOptimization: previousValues.exportOptimization,
        noExport: previousValues.noExport,
        remoteMonitoringControl: previousValues.remoteMonitoringControl[value],
        gridTransitionOutage: previousValues.gridTransitionOutage,
        mode: previousValues.mode,
        gridTransitionOnline: previousValues.gridTransitionOnline,
        manualSwitch: previousValues.manualSwitch,
        gensetBattery: previousValues.gensetBattery,
      }));
    }
  };

  const handlePackageMGSiteTypeChange = e => {
    setSiteType(e.target.value);
    if (
      sessionStorage.projectId !== "null" &&
      previousValues.siteType !== e.target.value
    ) {
      setPreviousValues(prevState => ({ ...prevState, useCaseChanged: true }));
    }
    if (
      sessionStorage.projectId !== "null" &&
      previousValues.siteType === e.target.value
    ) {
      setPreviousValues(prevState => ({ ...prevState, useCaseChanged: false }));
    }
  };

  const handleOptimizationChange = e => {
    setOptimizationEngine(e.target.value);

    if (e.target.value === "EMA") {
      setOtherOptimizationStrategy("");
    }
    if (e.target.value === "OTHER") {
      setOtherOptimizationStrategy("AUTOGRID");
      setGridSettings(prevState => ({
        ...prevState,
        demandCharge: false,
        tariffManagement: false,
        selfConsumption: false,
        exportOptimization: false,
        noExport: false,
      }));
    }
  };

  const handleOtherOptimizationChange = e => {
    setOtherOptimizationStrategy(e.target.value);
  };

  const handleOnGridFeatureChange = e => {
    const { name, checked } = e.target;
    setGridSettings(prevState => ({
      ...prevState,
      [name]: checked,
    }));

    if (
      gridSettings.demandCharge ||
      gridSettings.tariffManagement ||
      gridSettings.selfConsumption ||
      gridSettings.exportOptimization ||
      ([
        "demandCharge",
        "tariffManagement",
        "selfConsumption",
        "exportOptimization",
      ].includes(name) &&
        checked)
    ) {
      setGridSettings(prevState => ({
        ...prevState,
        remoteMonitoringControl: true,
      }));
    }

    if (previousValues.siteType === siteType) {
      setPreviousValues(prevState => ({
        ...prevState,
        [name]: checked,
      }));
    }
  };

  const handleModeChange = e => {
    const { name, value } = e.target;
    setGridSettings(prevState => ({
      ...prevState,
      [name]: value,
    }));

    if (
      sessionStorage.projectId !== "null" &&
      siteType === embLabels.ISLANDABLE &&
      value !== previousValues.mode &&
      previousValues.siteType !== embLabels.GRID_CONNECTED &&
      previousValues.projectType === projectType
    ) {
      setAlertMessage(
        `Use Case Change Alert`,
        false,
        "",
        `Use case change will affect asset's design and configuration !`
      );
      setPreviousValues(prevState => ({ ...prevState, useCaseChanged: true }));
    }
  };

  const handleTransitionTypeChange = e => {
    const { name, value } = e.target;
    if (["FAST", "CLOSE"].includes(value)) {
      setAlertMessage(
        `Alert`,
        false,
        "",
        "Option not available for configuration"
      );
    } else {
      setGridSettings(prevState => ({
        ...prevState,
        [name]: value,
      }));

      setPreviousValues(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleRemoteMonitoringChange = e => {
    const { name, checked } = e.target;

    if (
      gridSettings.demandCharge ||
      gridSettings.tariffManagement ||
      gridSettings.selfConsumption ||
      gridSettings.exportOptimization
    ) {
      setGridSettings(prevState => ({
        ...prevState,
        remoteMonitoringControl: true,
      }));
      setPreviousValues(prevState => ({
        ...prevState,
        remoteMonitoringControl: {
          ...prevState.remoteMonitoringControl,
          [siteType]: true,
        },
      }));
    } else {
      setGridSettings(prevState => ({ ...prevState, [name]: checked }));
      setPreviousValues(prevState => ({
        ...prevState,
        remoteMonitoringControl: {
          ...prevState.remoteMonitoringControl,
          [siteType]: checked,
        },
      }));

      if (!checked) {
        setOptimizationEngine("EMA");
        setOtherOptimizationStrategy("");
      }
    }
  };

  const handleSiteTypeChangeGSX = e => {
    const { value } = e.target;
    if (value === embLabels.GRID_CONNECTED_GSX) {
      setAlertMessage(
        `Alert`,
        false,
        "",
        `Grid connected site is coming soon !`
      );
    } else {
      setSiteType(value);
      if (!projectCreated) {
        setSiteInformation(prevState => ({
          ...prevState,
          userInterface: "none",
          gridNominalFrequency: 60,
          gridNominalVoltage: 480,
        }));
      }
    }
  };

  const handleArchitectureTypeChangeGSX = e => {
    const { value } = e.target;
    setArchitectureType(value);
    if (!projectCreated) {
      if (value === embLabels.PV_GENSET_ATS) {
        setGridSettings(prevState => ({
          ...prevState,
          demandCharge: false,
          tariffManagement: false,
          selfConsumption: false,
          exportOptimization: false,
          noExport: true,
          remoteMonitoringControl: true,
          mode: "",
          gridTransitionOutage: "OPEN",
          manualSwitch: "OPEN",
          gridTransitionOnline: "OPEN",
          gensetBattery: "OPEN",
        }));
      }
    }
  };

  const handleOnGridFeatureChangeGSX = e => {
    const { name, checked } = e.target;
    setGridSettings(prevState => ({
      ...prevState,
      [name]: checked,
    }));
  };

  //* PROJECT TYPE END

  //* SITE INFO START

  const handlSiteInformationChange = e => {
    const { name, value } = e.target;
    if (name === "gridNominalVoltage") {
      if (!isNaN(value)) {
        setSiteInformation(prevState => ({ ...prevState, [name]: value }));
      }
    } else {
      setSiteInformation(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const locationCallback = data => {
    setLocationData(data);
  };

  const addUserProject = () => {
    setLoading(true);
    apiSession
      .getProjectRoles()
      .then(response => {
        setUserRoles(prevState => ({
          ...prevState,
          addUserPopUpstatus: true,
          rolesList: response.userRoles,
          rolesPermission: response.roleDetails,
        }));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        callSnackbar("error", "Error in loading user roles");
      });
  };

  const addUserPopUp = (email, role) => {
    setUserRoles(prevState => ({ ...prevState, addUserPopUpLoading: true }));
    const userData = [...userRoles.addUserPopUpList];
    let findIndex = -1;
    let findIndexUser = -1;
    if (userData.length) {
      findIndex = userData.findIndex(
        obj => obj.email.toLowerCase() === email.toLowerCase()
      );
    }
    if (findIndex === -1) {
      const usersList = [...users];
      if (usersList.length) {
        findIndexUser = usersList.findIndex(
          obj => obj.email.toLowerCase() === email.toLowerCase()
        );
      }
      if (findIndexUser === -1) {
        let popUpUsers = {};
        popUpUsers.email = email;
        popUpUsers.role = role;
        userData.push(popUpUsers);
      } else {
        callSnackbar("error", "User is already added in the project");
      }
    } else {
      callSnackbar("error", "User is already added in the project");
    }
    setUserRoles(prevState => ({ ...prevState, addUserPopUpList: userData }));
    setUserRoles(prevState => ({ ...prevState, addUserPopUpLoading: false }));
  };

  const deleteUserPopUp = email => {
    const userData = userRoles.addUserPopUpList.filter(
      obj => obj.email.toLowerCase() !== email.toLowerCase()
    );
    setUserRoles(prevState => ({ ...prevState, addUserPopUpList: userData }));
  };

  const closeAddUser = () => {
    setUserRoles(prevState => ({
      ...prevState,
      addUserPopUpstatus: false,
      addUserPopUpList: [],
    }));
  };

  const addUserToProjectList = () => {
    setUserRoles(prevState => ({ ...prevState, addUserPopUpLoading: true }));

    const projectUsersList = [...users];
    let usersListToBeAdded = [];

    userRoles.addUserPopUpList.forEach(userData => {
      usersListToBeAdded.push(userData);
    });

    if (usersListToBeAdded.length) {
      if (sessionStorage.getItem("projectId") !== "null") {
        apiSession
          .addUsersToProject({
            projectId: parseInt(sessionStorage.getItem("projectId")),
            users: usersListToBeAdded,
          })
          .then(response => {
            let userData = response;
            usersListToBeAdded.map(usrData => {
              var indexUsr = userData.findIndex(
                obj => obj.email.toLowerCase() === usrData.email.toLowerCase()
              );
              if (indexUsr !== -1) {
                userData[indexUsr].role = usrData.role;
                userData[indexUsr].status = "I";
                projectUsersList.push(userData[indexUsr]);
              }
              return null;
            });
            setUserRoles(prevState => ({
              ...prevState,
              addUserPopUpstatus: false,
              addUserPopUpLoading: false,
              addUserPopUpList: [],
            }));
            setUsers(projectUsersList);
            callSnackbar("success", "User invited  to the project.");
          })
          .catch(error => {
            if (error.response !== undefined) {
              if (error.response.status === 404) {
                setAlertMessage(
                  `Alert`,
                  false,
                  "",
                  "You are not authorized to invite any user, as you are not part of this project. Please contact Admin."
                );
              } else {
                callSnackbar("error", "Error in inviting to the project.");
              }
            } else {
              callSnackbar("error", "Error in inviting to the project.");
            }
            setUserRoles(prevState => ({
              ...prevState,
              addUserPopUpstatus: false,
              addUserPopUpLoading: false,
              addUserPopUpList: [],
            }));
          });
      } else {
        usersListToBeAdded.map((usrData, index) => {
          let indexUsr = users.findIndex(
            obj => obj.email.toLowerCase() === usrData.email.toLowerCase()
          );
          if (indexUsr === -1) {
            usersListToBeAdded[index].firstName = "";
            usersListToBeAdded[index].lastName = "";
            usersListToBeAdded[index].phoneNumber = "";
            usersListToBeAdded[index].employer = "";
            usersListToBeAdded[index].userId = 0;
            usersListToBeAdded[index].status = "I";
            projectUsersList.push(usersListToBeAdded[index]);
          }
          return null;
        });

        setUserRoles(prevState => ({
          ...prevState,
          addUserPopUpstatus: false,
          addUserPopUpLoading: false,
          addUserPopUpList: [],
        }));
        setUsers(projectUsersList);
        callSnackbar(
          "success",
          "User invited  to the project, Click validate to update poject."
        );
      }
    } else {
      setUserRoles(prevState => ({
        ...prevState,
        addUserPopUpLoading: false,
        addUserPopUpList: [],
        addUserPopUpStatus: false,
      }));
    }
  };

  const removeInvitedUserPopUp = (userId, role) => {
    setDeleteInvitedUser(
      true,
      `Do you confirm to delete ${role} from the project ?`
    );
    setDeletingUserInfo({
      deleteingRole: role,
      deleteingUserId: userId,
    });
  };

  const closeDeleteInvitedUserPopUp = () => {
    setDeleteInvitedUser(false, ``);
  };

  const removeInvitedUser = () => {
    if (deletingUserInfo.deleteingUserId) {
      removeUserFromProject(deletingUserInfo.deleteingUserId);
    }
    closeDeleteInvitedUserPopUp();
  };

  const removeUserFromProject = userId => {
    if (userId === 0) {
      let userData = [...users];
      var index = userData.findIndex(obj => obj.userId === userId);
      if (index !== -1) {
        delete userData[index];
        userData = userData.filter(function (dataElement) {
          return dataElement !== undefined;
        });
        setUsers(userData);
        callSnackbar(
          "success",
          "User is removed from the project, Please Click validate to update."
        );
      }
    } else {
      apiSession
        .removeUserFromProject({
          userId: userId,
          projectId: sessionStorage.getItem("projectId"),
        })
        .then(() => {
          let userData = [...users];
          let index = userData.findIndex(obj => obj.userId === userId);
          if (index !== -1) {
            delete userData[index];
            userData = userData.filter(function (dataElement) {
              return dataElement !== undefined;
            });
            setUsers(userData);
            callSnackbar("success", "User is removed from the project.");
          }
        })
        .catch(error => {
          if (error.response !== undefined) {
            if (error.response.status === 404) {
              setAlertMessage(
                `Alert`,
                false,
                "",
                "You are not authorized to delete any user, as you are not part of this project. Please contact Admin."
              );
            } else {
              callSnackbar(
                "error",
                "Error in removing the user from the project"
              );
            }
          } else {
            callSnackbar(
              "error",
              "Error in removing the user from the project"
            );
          }
        });
    }
  };

  const reinviteToProject = userData => {
    let usersListToBeAdded = userData;
    setLoading(true);
    apiSession
      .reinviteToProject({
        projectId: parseInt(sessionStorage.getItem("projectId")),
        email: userData.email,
        role: userData.role,
      })
      .then(() => {
        updateReInvitedUsers(usersListToBeAdded);
        callSnackbar("success", "Successfully  Re invited to the project.");
        setLoading(false);
      })
      .catch(() => {
        callSnackbar("error", "Error in inviting to the project.");
        setLoading(false);
      });
  };

  const updateReInvitedUsers = userReinvited => {
    let projectUsersList = [...users];
    projectUsersList.map((userData, index) => {
      if (userData.email === userReinvited.email) {
        projectUsersList[index].status = "I";
      }
      return null;
    });
    setUsers(projectUsersList);
  };

  //* SITE INFO END

  //* Validation Start

  const onBackButtonClick = () => {
    let { history } = props;
    history.push({
      pathname: "/project",
    });
  };

  const onValidateClick = () => {
    const projectStatus = findProjectTypeHandler();
    setDisableValidateProject(true);
    if (UserScope.access.saveProjects) {
      setMandatoryMsgDisplay(true);

      let result = null;
      let locationDataValidate = _.cloneDeep(locationData);
      result = LocationFinder.validateLocation(locationDataValidate);
      const LANGUAGE = "en";
      const VALIDATION_LABELS =
        LocationFinder.getValidationLabelsForLanguage(LANGUAGE);

      //* project info
      if (!projectInformation.projectName) {
        callSnackbar(`error`, `Project name is mandatory`);
        enableValidateFunction();
      } else if (!projectInformation.clientName) {
        callSnackbar(`error`, `Client name is mandatory`);
        enableValidateFunction();
      } else if (!projectInformation.siteName) {
        callSnackbar(`error`, `Site name is mandatory`);
        enableValidateFunction();
      } else if (projectInformation.currency === "") {
        callSnackbar(`error`, `Currency is mandatory`);
        enableValidateFunction();
      } else if (projectInformation.temperatureUnit === "") {
        callSnackbar(`error`, `Temerature unit is mandatory`);
        enableValidateFunction();
      }
      //* project type
      else if (!projectType) {
        callSnackbar(`error`, `Project Type is mandatory`);
        enableValidateFunction();
      } else if (!siteType) {
        callSnackbar(`error`, `Site Type  selection is mandatory`);
        enableValidateFunction();
      } else if (siteType === embLabels.OFF_GRID) {
        callSnackbar(
          `error`,
          `Your have selected Coming soon Site Type, Please select  Always grid connected site or Islandable site`
        );
        enableValidateFunction();
      } else if (
        projectStatus === embLabels.GENERIC_PROJECT &&
        siteType === embLabels.GRID_CONNECTED &&
        !gridSettings.remoteMonitoringControl
      ) {
        callSnackbar(
          `error`,
          `Remote energy monitoring and forecast is mandatory for Always grid connected site usecase`
        );
        enableValidateFunction();
      } else if (
        projectStatus === embLabels.GENERIC_PROJECT &&
        siteType === embLabels.ISLANDABLE &&
        gridSettings.mode === ""
      ) {
        callSnackbar(
          `error`,
          `Islandable site Asset Configuration selection is mandatory`
        );
        enableValidateFunction();
      } else if (
        projectStatus === embLabels.GREENSTRUXURE_PROJECT &&
        siteType === embLabels.ISLANDABLE &&
        architectureType === null
      ) {
        callSnackbar(`error`, `Architecture Type is mandatory`);
        enableValidateFunction();
      }
      //* site info
      else if (!siteInformation.gridNominalFrequency) {
        callSnackbar(`error`, `Grid nominal frequency is mandatory`);
        enableValidateFunction();
      } else if (!siteInformation.gridNominalVoltage) {
        callSnackbar(`error`, `Grid nominal voltage is mandatory`);
        enableValidateFunction();
      } else if (
        parseInt(siteInformation.gridNominalVoltage) < 100 ||
        parseInt(siteInformation.gridNominalVoltage) > 700
      ) {
        callSnackbar(
          `error`,
          `Grid nominal voltage should be in range (100-700 V)`
        );
        enableValidateFunction();
      } else if (result) {
        callSnackbar(`error`, VALIDATION_LABELS[result]);
        enableValidateFunction();
      } else {
        createProject();
      }
    } else {
      callSnackbar(
        `error`,
        `${UserScope.role} is not authorized to perform action on this page`
      );
      enableValidateFunction();
    }
  };

  const enableValidateFunction = () => setDisableValidateProject(false);

  const createProject = () => {
    const usersList = [];
    users.forEach(userData => {
      let data = {
        email: userData.email,
        role: userData.role,
      };
      usersList.push(data);
    });

    const jsonBody = {
      projectName: projectInformation.projectName,
      createdBy: "1",
      createdOn: createdOn,
      programPage: true,
      diagramChangeRequired:
        siteType === previousValues.siteType ? false : true,
      projectInfo: {
        projectType: projectType,
        siteType: siteType,
        gridSettings: {
          demandCharge: gridSettings.demandCharge,
          tariffManagement: gridSettings.tariffManagement,
          selfConsumption: gridSettings.selfConsumption,
          exportOptimization: gridSettings.exportOptimization,
          noExport: gridSettings.noExport,
          remoteMonitoringControl: gridSettings.remoteMonitoringControl,
          mode:
            findProjectTypeHandler() === embLabels.GENERIC_PROJECT
              ? siteType === embLabels.ISLANDABLE
                ? gridSettings.mode
                : "NO_FUEL"
              : null,

          isIslandable: siteType === embLabels.ISLANDABLE ? true : false,
        },
        clientName: projectInformation.clientName,
        siteName: projectInformation.siteName,
        currency: projectInformation.currency,
        temperatureUnit: projectInformation.temperatureUnit,
        description: projectInformation.description,
        userInterface: siteInformation.userInterface,
        gridNominalFrequency: siteInformation.gridNominalFrequency,
        gridNominalVoltage: siteInformation.gridNominalVoltage,
      },
      location: locationData,
      server: {
        optimizationType:
          optimizationEngine === "EMA"
            ? optimizationEngine
            : otherOptimizationStrategy,
      },
      architecture: architectureType,
      clientType: findProjectTypeHandler(),
      externalId: projectInformation.externalId
        ? projectInformation.externalId
        : null,
    };

    currencyList.forEach(currencyData => {
      if (currencyData.name === projectInformation.currency) {
        localStorage.setItem("currency", currencyData.symbol);
      }
    });

    if ([embLabels.ISLANDABLE, embLabels.OFF_GRID].includes(siteType)) {
      jsonBody.projectInfo.gridSettings.gridTransitionOutage =
        gridSettings.gridTransitionOutage;
      jsonBody.projectInfo.gridSettings.gridTransitionOnline =
        gridSettings.gridTransitionOnline;
      jsonBody.projectInfo.gridSettings.manualSwitch =
        gridSettings.manualSwitch;
      if (gridSettings.mode === "FUEL_ERASER") {
        jsonBody.projectInfo.gridSettings.gensetBattery =
          gridSettings.gensetBattery;
      }
    }

    localStorage.setItem("userCountry", locationData.country);

    if (sessionStorage.getItem("projectId")) {
      jsonBody.projectId = sessionStorage.getItem("projectId");
      jsonBody.lastModifiedBy = 1;
    }
    apiSession
      .updateProject(jsonBody)
      .then(json => {
        localStorage.setItem("pname", projectInformation.projectName);
        sessionStorage.setItem("projectId", json.projectId);
        localStorage.setItem("programPage", true);
        localStorage.setItem("useCaseChanged", previousValues.useCaseChanged);
        localStorage.setItem("projectChanged", previousValues.projectChanged);

        if (json.projectId) {
          apiSession
            .addUsersToProject({ projectId: json.projectId, users: usersList })
            .then(() => {})
            .catch(() => {});
        }
        if (selectedFile) {
          uploadImage();
        } else {
          setAlertMessage(
            `Project Status`,
            true,
            `assets`,
            `Project ${
              projectCreateStatus ? "created" : "updated"
            } successfully. `
          );
        }
        enableValidateFunction();
      })
      .catch(error => {
        enableValidateFunction();
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            callSnackbar(`error`, `Error in validation`);
          } else {
            callSnackbar(`error`, `${error.response.data.message}`);
          }
        } else {
          callSnackbar(`error`, `Error in validation`);
        }
      });
  };

  //* Validation End

  const callSnackbar = useCallback((type, message) => {
    setSnackBar({ type, message });
    snackBarRef.current.open = true;
  }, []);

  const setDeleteImage = (type, message) => {
    setDeleteImagePopUpStatus({
      confirmPopupStatus: type,
      confirmMessage: message,
    });
  };

  const setDeleteInvitedUser = (type, message) => {
    setDeleteInvitedUserPopUpStatus({
      confirmPopupStatus: type,
      confirmMessage: message,
    });
  };

  const setAlertMessage = (title, isRoute, routePath, message) => {
    setAlert({
      title,
      isRoute,
      routePath,
      message,
      alertPopUpStatus: true,
    });
  };

  const closeAlertMessage = useCallback(() => {
    setAlert({
      message: "",
      alertPopUpStatus: false,
      title: "",
      isRoute: false,
      routePath: "",
    });
    if (alert.isRoute) {
      let { history } = props;
      history.push({
        pathname: `/${alert.routePath}`,
      });
    }
  }, [alert, props]);

  const findProjectTypeHandler = () => {
    const status = projectCreated
      ? clientType === embLabels.GENERIC_PROJECT
        ? embLabels.GENERIC_PROJECT
        : clientType === embLabels.GREENSTRUXURE_PROJECT
        ? embLabels.GREENSTRUXURE_PROJECT
        : "none"
      : [
          embLabels.ADMIN,
          embLabels.SCHNEIDER_BUSINESS,
          embLabels.TEE,
          embLabels.EXECUTION_ENGINEER,
          embLabels.PANEL_BUILDER,
          embLabels.SPECIFIER,
        ].includes(UserScope.role)
      ? embLabels.GENERIC_PROJECT
      : UserScope.role === embLabels.GREENSTRUXURE
      ? embLabels.GREENSTRUXURE_PROJECT
      : "none";
    return status;
  };

  //*JSX
  return (
    <div className={loading || siteCreated ? "se-block-disabled" : ""}>
      <se-loading loading={loading}></se-loading>
      <se-container option="centered">
        <div className="container mb-1">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background pr-5 pl-5">
              {!UserScope.access.saveProjects && (
                <div className="col-sm-12 text-center mt-1">
                  <span className="se-error-message-note text-center">
                    {"Information: " +
                      UserScope.role +
                      " is not authorized to perform action on this page but he can invite new user to the Project to be a collaborator"}
                  </span>
                </div>
              )}

              {UserScope.access.saveProjects && siteCreated && (
                <div className="col-sm-12 text-center mt-1">
                  <span className="se-error-message-note text-center">
                    {`Site already created for the project, no more modifications allowed`}
                  </span>
                </div>
              )}

              {/* PROJECT INFO*/}

              <ProjectInformation
                UserScope={UserScope}
                projectInformation={projectInformation}
                currencyList={currencyList}
                loadingImage={loadingImage}
                imagePreviewUrl={imagePreviewUrl}
                mandatoryMsgDisplay={mandatoryMsgDisplay}
                onProjectChangeHandler={projectChangeHandler}
                onFileChangedHandler={fileChangedHandler}
                onRemoveProjectImagePopUp={removeProjectImagePopUp}
                onFindProjectTypeHandler={findProjectTypeHandler}
              />

              {/*PROJECT TYPE*/}
              <div className="row">
                <InformationPageProvider
                  value={{
                    UserScope,
                    projectType,
                    siteType,
                    informationToolTIP,
                    disabledFeatures,
                    optimizationEngine,
                    otherOptimizationStrategy,
                    gridSettings,
                    siteInformation,
                    locationData,
                    mandatoryMsgDisplay,
                    users,
                    disableValidateProject,
                    renderLocationComponent,
                    architectureType,
                    handleProjectTypeChange,
                    handleSiteTypeChange,
                    handleSiteTypeChangeGSX,
                    handleArchitectureTypeChangeGSX,
                    handleOnGridFeatureChangeGSX,
                    handlePackageMGSiteTypeChange,
                    handleOptimizationChange,
                    handleOtherOptimizationChange,
                    handleOnGridFeatureChange,
                    handleModeChange,
                    handleTransitionTypeChange,
                    handleRemoteMonitoringChange,
                    handlSiteInformationChange,
                    locationCallback,
                    addUserProject,
                    removeInvitedUserPopUp,
                    reinviteToProject,
                    onBackButtonClick,
                    onValidateClick,
                    findProjectTypeHandler,
                  }}
                >
                  <ProjectType />
                  <SiteInformation />
                </InformationPageProvider>
              </div>
            </div>
          </div>
        </div>
        <InviteUser
          userRoles={userRoles}
          addUserPopUp={addUserPopUp}
          deleteUserPopUp={deleteUserPopUp}
          closeAddUser={closeAddUser}
          addUserToProjectList={addUserToProjectList}
        />

        <Snackbar snackBar={snackBar} ref={snackBarRef} />

        <Alert alert={alert} onClose={closeAlertMessage} />

        {deleteImagePopUpStatus.confirmPopupStatus && (
          <Confirm
            confirm={deleteImagePopUpStatus}
            onOk={removeProjectImage}
            onClose={closeDeleteImagePopUp}
          />
        )}

        {deleteInvitedUserPopUpStatus.confirmPopupStatus && (
          <Confirm
            confirm={deleteInvitedUserPopUpStatus}
            onOk={removeInvitedUser}
            onClose={closeDeleteInvitedUserPopUp}
          />
        )}
      </se-container>
    </div>
  );
};

export default InformationHook;
