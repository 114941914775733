import React, { Component } from "react";

class ProjectAddUser extends Component {

    constructor(props) {
        super(props);
        this.state ={
            emailAddress : "",
            role : 0,
            mandatoryMsgDisplay : false,
            addStatus : false,
            emailErrorMessage: "Email Required.",
            rolePermission : "",
            rolesListInside : [],
            roleName : ""
        };
    }
    componentWillReceiveProps(){
        this.setState({rolePermission: "" });
        this.setState({role: 0 });
        this.setState({roleName: "" });
        this.setState({emailAddress: "" });
        let  rolesListInsideNew = Object.assign([], this.state.rolesListInside)
        rolesListInsideNew = [];
        this.props.rolesList.map((role)=>{
                role.permission = this.props.rolesPermission[role.role];
                rolesListInsideNew.push(role);
                return null;
        });
        this.setState({rolesListInside : rolesListInsideNew});
         
    }
    
    addUser(){

            var patternCheckRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/igm;
        
            if(patternCheckRegex.test(this.state.emailAddress))
            {
                this.props.onAddUserPopUp(this.state.emailAddress, this.state.role);
                this.setState({mandatoryMsgDisplay : false});
                this.setState({emailAddress: ""});
                this.setState({role: 0});
            }
            else{
                this.setState({emailAddress: ""});
                this.setState({role: 0});
                this.setState({emailErrorMessage : "Enter Valid Email."});
                this.setState({mandatoryMsgDisplay : true});
            }
        
        
    }
    deleteUser(e){
        this.props.onDeleteUserPopUp(e.target.getAttribute('refs'));
    }
    handleEmailChange(e){
        this.setState({emailAddress : e.target.value})
    }

    handleRoleChange(e){
        this.setState({role : e.target.value});
        this.props.rolesList.map((role)=>{
            if(role.tag === e.target.value){
                this.setState({rolePermission : this.props.rolesPermission[role.role] });
            }
            return null;

        });
    }
    handleRoleChangeCustom(value){
        this.setState({role : value});
        this.props.rolesList.map((role)=>{
            if(role.tag === value){
                this.setState({rolePermission : this.props.rolesPermission[role.role] });
                this.setState({roleName : role.role});
            }
            return null;

        });

    }


  render() {
    const { addUserPopUpstatus, closeAddUser, addUserPopUpList, rolesList, addUserPopUpLoading, addUserProjectList} = this.props;
    return [
        <se-dialog id="dialog-complex" size ="medium" open ={addUserPopUpstatus} can-backdrop="false">
            <se-dialog-header>
                {
                    this.props.type === "usermanagement" ? "Invite User(s)" : "Invite User(s) to the Project"
                } 
              
            </se-dialog-header>
                    
            <se-dialog-content className = "se-dialog-content">
            <se-loading loading={addUserPopUpLoading}></se-loading>
            <se-loading loading={(rolesList.length === 0)? true : false}></se-loading>
                {/* <div className="row">
    <div className="col-md-12 col-sm-12 col-lg-12" >
        <p>{(type==="usermanagement")?"Enter email address to add him to application":"Enter email address to add him on the project"}
    </p></div> */}
                {/* </div> */}
                <div className="row">
                <div className="col-md-12">
                        <div className="form-group row mb-0">
                            <label htmlFor="emailaddress" className="col-sm-9 col-form-label ml-3">Enter e-mail address</label>
                            <div className="col-md-2 col-sm-2 col-lg-2 text-right ">
                            <se-button option="flat" size="nano" disabled={(this.state.emailAddress!== "" && this.state.role !==0 && addUserPopUpList.length !==5)?false: true}
                            onClick={this.addUser.bind(this)}>Add</se-button>
                            </div>
                            </div>
                            <div className="form-group">
                            <div className="col-sm-12">
                            <input type="text" autoComplete="off" className="form-control form-control-sm" id="emailaddress" name="emailAddress" maxlength="50"
                            value={this.state.emailAddress} onChange={e => this.handleEmailChange(e)}/>
                            <div className="invalid-feedback-custom" style={(!this.state.emailAddress && this.state.mandatoryMsgDisplay)?{}:{display:"none"} }>
                                        {this.state.emailErrorMessage}
                                        </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group ">
                                {/* <label htmlFor="roleSelection" className="col-sm-12 col-form-label">Invited as<span className="se-mandatory">*</span></label>*/}
                                <div className="col-sm-12"> 

                                {/* <se-dropdown alignment="left" max-width="500px">
                            <se-button slot="trigger" option="inherit" icon="" icon-color="primary">Invited as {this.state.roleName}</se-button>
                            <se-list option="dropdown">
                            { this.state.rolesListInside.map((data)=>(
                                <se-list-item  item={"Invited as "+data.role} description={'"'+data.permission+'"'}
                                onClick={this.handleRoleChangeCustom.bind(this,data.tag)}>
                                </se-list-item>
                                ))
                                    }
                            </se-list>
                        </se-dropdown> */}

                                <select className="form-control form-control-sm " name="role" value={this.state.role} id="roleSelection" 
                                onChange={e => this.handleRoleChange(e)}>
                                    <option value="0">Select the role</option>
                                    { this.state.rolesListInside.map((data)=>(
                                                    <option value={data.tag}> Invited as {data.role} - "{data.permission}"</option>
                                                ))
                                    }
                                    
                                    
                                </select>
                                <div className="invalid-feedback-custom" style={(!this.state.role !== 0 && this.state.mandatoryMsgDisplay)?{}:{display:"none"} }>
                                        Role is required.
                                        </div>
                                
                                </div>
                            </div>
                    </div>
                    
                </div>
                {/* <div className="row">
                <div className="col-md-2">
                    <span>Permission:</span>
                    </div>
                    <div className="col-md-10">
                    <span>{this.state.rolePermission}</span>
                    </div>

                </div> */}
                
                <div className="row" style={(addUserPopUpList.length === 0)? {display:"none"}: {}}>
                <div className="col-md-12 col-sm-12 col-lg-12 "><se-divider></se-divider></div>
                </div>
                {
                    addUserPopUpList.length !== 0 ?
                    <div className="row mt-1">
                <div className="col-md-12 col-sm-12 col-lg-12 ">
                    <table className="table table-bordered">
                        <tr>
                            <th>Email Address</th>
                            <th>Invites as</th>
                            <th>Action</th>
                        </tr>
                        {
                            addUserPopUpList.map((userData)=>(
                                <tr>
                                    <td>{userData.email}</td>
                                    <td>{userData.role}</td>
                                    <td>
                                    <span className="pointer">
                                    <se-icon size="small" refs={userData.email}
                                        onClick={this.deleteUser.bind(this)} >action_delete</se-icon>
                                    </span>
                                    </td>
                                </tr>
                            ))
                        }

                    </table>
                    </div>
                </div>
                : ""
                }
                

                {/* {
                        addUserPopUpList.map((userData)=>(

                            <div className="row mt-1">
                                <div className="col-md-5 col-sm-5 col-lg-5 ">
                                    <div className="form-group row">
                                            <label htmlFor="emailaddress" className="col-sm-4 col-form-label">Email Address</label>
                                            <div className="col-sm-8">
                                            <label htmlFor="emailaddress" className="col-sm-4 col-form-label">{userData.email}</label>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-md-5 col-sm-5 col-lg-5 ">
                                <div className="form-group row">
                                            <label htmlFor="emailaddress" className="col-sm-4 col-form-label">Invites as</label>
                                            <div className="col-sm-8">
                                            <label htmlFor="emailaddress" className="col-sm-4 col-form-label">{userData.role}</label>
                                            </div>
                                        </div>

                                </div>
                                <div className="col-md-2 col-sm-2 col-lg-2 pointer">
                                 <span className="pointer" refs={userData.userId}
                                 onClick={this.deleteUser.bind(this)}> 
                                    <se-icon size="small" refs={userData.email}
                                onClick={this.deleteUser.bind(this)} >action_delete</se-icon>
                                </span>
                                </div>

                            </div>

                        ))
                } */}

                
                
            </se-dialog-content>
            <se-dialog-footer>
                <se-button option="outline" onClick={closeAddUser} >
                Cancel</se-button>
                
                <se-button option="flat" disabled= {(addUserPopUpList.length === 0)?true : false} onClick={addUserProjectList}>
                Send Invitation</se-button>
                
            </se-dialog-footer>
            </se-dialog>
    ]
}

}

export default ProjectAddUser;