import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import loginBackground from '../images/EMB.png'; 

class Logout extends Component {   
    onButtonClick() {  
        let { history } = this.props;
        history.push({
            pathname: '/'
        });
    }
    componentDidMount(){
        localStorage.clear();
    }

    render() {
        const src= "url("+loginBackground+")";
        return (
            <div>
                <se-authentication app-title="Microgrid Build" version={process.env.REACT_APP_VERSION} image-url={src}>
                <p>Logged out successfully !</p><se-link url="/">Click here to login </se-link>
                </se-authentication>
                
            </div>
        );
    }
}

export default withRouter(Logout);