import React from "react";
import { useState } from "react";

const SelectTemplate = ({
  isOpen,
  templates,
  disableTemplatePopUp,
  selectTemplate,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const templateSelectHandler = template => {
    setSelectedTemplate(template);
  };
  return (
    <se-dialog id="dialog-complex" open={isOpen} can-backdrop="false">
      <se-dialog-header>Select template</se-dialog-header>

      <se-dialog-content className="se-dialog-content">
        <div className="row">
          {templates.map(template => (
            <div
              className="col-sm-12 col-md-12 col-lg-12 col-xl-12 border-bottom"
              key={template.id}
            >
              <div className="row mt-2 mb-2 ">
                <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                  <div className="custom-control custom-radio mt-1">
                    <input
                      type="radio"
                      className="custom-control-input islandableRadio  asCompatible"
                      value={template.title}
                      name={template.title}
                      id={template.id}
                      checked={selectedTemplate.id === template.id}
                      onChange={() => templateSelectHandler(template)}
                    />
                    <label
                      className="custom-control-label line-height-25"
                      htmlFor={template.id}
                    ></label>
                  </div>
                </div>

                <div className="col-sm col-md col-lg col-xl">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-1">
                      {template.title}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </se-dialog-content>
      <se-dialog-footer>
        <se-button onClick={disableTemplatePopUp}>Cancel</se-button>

        <se-button
          disabled={selectedTemplate ? "false" : "true"}
          onClick={() => selectTemplate(selectedTemplate.demandCharge)}
          color="primary"
        >
          Select
        </se-button>
      </se-dialog-footer>
    </se-dialog>
  );
};

export default SelectTemplate;
