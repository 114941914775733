import React, { useContext } from "react";
import { DesignAssetContext } from "../../pages/CustomHooks/ContextApi";

import ACTIONS from "../../pages/DesignAssets/Design_Asset_Actions.json";
import embLabels from "../../config/emb-labels-en.json";

const Genset = () => {
  const {
    CBAssetList: [, { uiData, configData }],
    UserScope,
    CBAssetListDispatch,
    assetDetailsReset,
    callSnackbar,
    selectedValuesHandler,
    assetConfigDataHandler,
    selectedAssetHandler,
  } = useContext(DesignAssetContext);

  const gensetSelectHandler = () => {
    assetDetailsReset();
    if (UserScope.access.saveSLD) {
      const assetBlock = "assetGensetDetailsBlock";
      const selectedAssetValue = "selectedAsset";
      CBAssetListDispatch({
        type: ACTIONS.ASSET_SELECT,
        payload: {
          index: 1,
        },
      });
      selectedValuesHandler(1, selectedAssetValue, assetBlock);
      selectedAssetHandler(embLabels.ASSETS, 1);
      assetConfigDataHandler(1);
    } else {
      callSnackbar(
        "error",
        `${UserScope.role} is not authorized to perform action on this page`
      );
    }
  };

  return (
    <svg className="pointer">
      <g id="genset">
        <svg
          x={uiData.xAxis}
          y={uiData.yAxis}
          width="195px"
          height="130px"
          viewBox="0 0 10 149"
          onClick={gensetSelectHandler}
        >
          <title className="se-custom-tooltip">{configData.name}</title>
          <g id="Design" fill="none">
            <g
              id="Design/Assets-_-00"
              transform="translate(-748.000000, -372.000000)"
            >
              <g
                id="SLD/Genset/Uniline/OK"
                transform="translate(748.000000, 372.000000)"
              >
                <rect id="Rectangle" x="0" y="0" width="96" height="144"></rect>

                <circle
                  id="Oval"
                  stroke="#42B4E6"
                  strokeWidth="3"
                  cx="48"
                  cy="56"
                  r="55"
                  style={uiData.selected ? {} : { display: "none" }}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="rgba(0, 135, 205, 0.2)"
                ></circle>
                <circle
                  id="Oval"
                  strokeWidth="3"
                  stroke={uiData.configured ? "#333" : "#E47F00"}
                  fill="#FFFFFF"
                  fillRule="evenodd"
                  cx="48"
                  cy="56"
                  r="46.5"
                ></circle>
                <text
                  id="segment_energy_infra"
                  fontFamily="se-icon"
                  fontSize="50"
                  stroke="#333"
                  fontWeight="normal"
                  fill="#0F0F0F"
                >
                  <tspan x="25" y="74">
                    genset_01
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </svg>
        <line
          x1={uiData.x1pos}
          y1={uiData.y1pos}
          x2={uiData.x2pos}
          y2={uiData.y2pos}
          id="Line-2"
          strokeWidth="4"
          strokeDasharray="6 8"
          strokeLinecap="round"
          stroke={uiData.configured ? "#333333" : "#E47F00"}
        ></line>
      </g>
    </svg>
  );
};

export default Genset;
