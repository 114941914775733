import React, { Component } from "react";
import Pagination from "react-js-pagination";
import APISESSION from '../api/ApiSession';
import ProjectAddUser from '../components/projectadduser';
import EditRole from '../components/editrole';
//import { SeDropdown } from '@se/web-ui-react';


const apiSession = new APISESSION();

class UserManagement extends Component {
    constructor(props) {
        super(props);
        let userSampleData = {
            name: "",
            role: "",
            access: {}
        }
        this.state = {
            users: [],
            addUserPopUpStatus: false,
            addUserPopUpList: [],
            rolesList: [],
            addUserPopUpLoading: false,
            type: '',
            message: '',
            editUserRolePopUpStatus: false,
            editUserRolesPopUpLoading: false,
            selectedUserId: 0,
            selectedRole: "",
            selectedEmail: "",
            userScope: JSON.parse(localStorage.getItem("scope")) !== null ? JSON.parse(localStorage.getItem("scope")) : userSampleData,
            searchUserInput: "",
            sortUserOption: 0,
            usersData: [],
            UsersTotalCount: 0,
            activePage: 1,
            itemSliceFirst: 0,
            itemSliceLast: 10,
            itemsPerPage: 10,
            deleteConfirmPopUpStatus: false,
            deleteingEmail: "",
            deleteingUserId: "",
            filteredUser: 1,
            rolesPermission: {},
            loading: false,
            reinviteConfirmPopUpStatus: false,
            reinviteEmail: "",
            reinviteRole: ""
        }
    }
    componentDidMount() {
        this.setState({ loading: true });
        this.listUsers();
    }
    listUsers() {
        this.setState({ loading: true });
        apiSession.getUsers().then(response => {
            let activeUsers = [];
            let inActiveUsers = [];
            let invitedUsers = [];
            let cancelledUsers = [];
            response.map((user) => {
                if (user.status === "A") {
                    activeUsers.push(user);
                }
                else if (user.status === "D") {
                    inActiveUsers.push(user);
                }
                else if (user.status === "I" || user.status === "E") {

                    invitedUsers.push(user);
                }
                else if (user.status === "C") {
                    cancelledUsers.push(user);
                }
                return null;
            })
            this.setState({
                users: activeUsers,
                usersData: response,
                activeUsers: activeUsers,
                activeUsersData: activeUsers,
                inActiveUsers: inActiveUsers,
                inActiveUsersData: inActiveUsers,
                invitedUsers: invitedUsers,
                invitedUsersData: invitedUsers,
                cancelledUsers: cancelledUsers,
                cancelledUsersData: cancelledUsers
            });
            this.setState({ loading: false });


        }).catch(error => {
            this.setState({ loading: false });
        })
    }

    handlePageChange(pageNumber) {
        let itemLast = pageNumber * this.state.itemsPerPage;
        let itemFirst = itemLast - this.state.itemsPerPage;
        this.setState({ activePage: pageNumber });
        this.setState({ itemSliceLast: itemLast }, () => {
            this.setState({ itemSliceFirst: itemFirst })
        });

    }

    handleUserSearchInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
        let search = e.target.value;
        let searchUsersList = [];
        switch (this.state.filteredUser) {
            case '1':
                searchUsersList = Object.assign([], this.state.activeUsersData);
                break;
            case '2':
                searchUsersList = Object.assign([], this.state.invitedUsersData);
                break;
            case '3':
                searchUsersList = Object.assign([], this.state.inActiveUsersData);
                break;
            case '4':
                searchUsersList = Object.assign([], this.state.cancelledUsersData);
                break;
            default:
                searchUsersList = Object.assign([], this.state.activeUsersData);
                break;

        }
        this.setState({ users: searchUsersList }, () => {
            const filteredUsers = this.state.users.filter(userData => {
                if (userData.name !== "null null") {
                    return userData.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                        || userData.role.toLowerCase().indexOf(search.toLowerCase()) !== -1
                        || userData.employer.toLowerCase().indexOf(search.toLowerCase()) !== -1
                        || userData.email.toLowerCase().indexOf(search.toLowerCase()) !== -1

                } else if (userData.email !== "" && userData.role !== "" && userData.employer !== null) {
                    return userData.role.toLowerCase().indexOf(search.toLowerCase()) !== -1
                        || userData.employer.toLowerCase().indexOf(search.toLowerCase()) !== -1
                        || userData.email.toLowerCase().indexOf(search.toLowerCase()) !== -1

                }
                else if (userData.email !== "" && userData.role !== "") {
                    return userData.role.toLowerCase().indexOf(search.toLowerCase()) !== -1
                        || userData.email.toLowerCase().indexOf(search.toLowerCase()) !== -1
                }
                else {
                    return userData.name ? (userData.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) : false
                        || userData.role.toLowerCase().indexOf(search.toLowerCase()) !== -1
                        || userData.employer.toLowerCase().indexOf(search.toLowerCase()) !== -1
                        || userData.email.toLowerCase().indexOf(search.toLowerCase()) !== -1
                }

            });
            this.setState({ users: filteredUsers });

        });
        this.setState({ activePage: 1 });
        this.setState({ itemSliceFirst: 0 });
        this.setState({ itemSliceLast: this.state.itemsPerPage });
    }

    

    resetUserList() {
        this.setState({ users: this.state.activeUsersData });
        this.setState({ invitedUsers: this.state.invitedUsersData });
        this.setState({ inActiveUsers: this.state.inActiveUsersData });
        this.setState({ cancelledUsers: this.state.cancelledUsersData });

        this.setState({ searchUserInput: '' });
        this.setState({ filteredUser: 1 });
        this.setState({ activePage: 1 });
        this.setState({ itemSliceFirst: 0 });
        this.setState({ itemSliceLast: this.state.itemsPerPage });

    }

    onChangeSortUserOption = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });

        if (e.target.value) {
            let usersListData = Object.assign([], this.state.users);
            switch (e.target.value) {
                case '1':
                    usersListData.sort(function (a, b) {
                        var userNameA = a.name.toLowerCase(), userNameB = b.name.toLowerCase()
                        if (userNameA < userNameB) //sort string ascending
                            return -1
                        if (userNameA > userNameB)
                            return 1
                        return 0
                    });
                    break;
                case '2':
                    usersListData.sort(function (a, b) {
                        var userNameA = a.name.toLowerCase(), userNameB = b.name.toLowerCase()
                        if (userNameA > userNameB) //sort string ascending
                            return -1
                        if (userNameA < userNameB)
                            return 1
                        return 0
                    });
                    break;

                default:
                    break;


            }
            this.setState({ users: usersListData });
            this.setState({ activePage: 1 });
            this.setState({ itemSliceFirst: 0 });
            this.setState({ itemSliceLast: this.state.itemsPerPage });
        }

    }

    onChangefilteredUser(e) {
        this.resetUserList();
        this.setState({ [e.target.name]: e.target.value });
        this.setState({ searchUserInput: "" });
        switch (e.target.value) {
            case '1':
                this.setState({ users: this.state.activeUsersData });
                break;
            case '2':
                this.setState({ users: this.state.invitedUsersData });
                break;
            case '3':
                this.setState({ users: this.state.inActiveUsersData });
                break;
            case '4':
                this.setState({ users: this.state.cancelledUsersData });
                break;
            default:
                this.setState({ users: this.state.activeUsersData });
                break;

        }
    }


    closeAddUser = () => {
        this.setState({ addUserPopUpStatus: false });
        this.setState({ addUserPopUpList: [] });
    }

    addUserProject = () => {
        this.setState({ loading: true });
        apiSession.getRoles().then(response => {
            this.setState({ rolesList: response.userRoles });
            this.setState({ rolesPermission: response.roleDetails });
            this.setState({ loading: false });
            this.setState({ addUserPopUpStatus: true });
        })
            .catch(error => {
                this.setState({ addUserPopUpStatus: false });
                this.setState({ loading: false });
                this.callSnackbar("error", "Unable to load user roles");
            })

    }
    onAddUserPopUp(email, role) {
        if (this.state.userScope.access.addUsers === true) {
            this.setState({ addUserPopUpLoading: true });
            let userData = Object.assign([], this.state.addUserPopUpList);
            var findIndex = -1;
            var findIndexUser = -1;
            if (userData.length) {
                findIndex = userData.findIndex(obj => obj.email.toLowerCase() === email.toLowerCase());
            }
            if (findIndex === -1) {
                let usersList = Object.assign([], this.state.users);
                if (usersList.length) {
                    findIndexUser = usersList.findIndex(obj => obj.email.toLowerCase() === email.toLowerCase());
                }
                if (findIndexUser === -1) {
                    let popUpUsers = {};
                    popUpUsers.email = email;
                    popUpUsers.role = role
                    userData.push(popUpUsers);
                }
                else {
                    this.callSnackbar("error", "User is already present in EMB");
                }

            } else {
                this.callSnackbar("error", "User is already added to the list");
            }
            this.setState({ addUserPopUpList: userData });
            this.setState({ addUserPopUpLoading: false });

        } else {
            this.callSnackbar("error", "Not Authorized");
        }

    }
    onDeleteUserPopUp(email) {
        let userData = Object.assign([], this.state.addUserPopUpList);

        var index = userData.findIndex(obj => obj.email.toLowerCase() === email.toLowerCase());
        if (index !== -1) {
            delete userData[index];
            userData = userData.filter(function (dataElement) {
                return dataElement !== undefined;
            });
            this.setState({ addUserPopUpList: userData });
        }

    }

    addUserToApplication() {
        if (this.state.userScope.access.addUsers === true) {
            this.setState({ addUserPopUpLoading: true });
            let projectUsersList = Object.assign([], this.state.activeUsersData);
            let usersListToBeAdded = [];
            this.state.addUserPopUpList.map((userData) => {
                usersListToBeAdded.push(userData);
                return null;
            });
            if (usersListToBeAdded.length) {
                apiSession.addUsersToApp({ "users": usersListToBeAdded }).then(response => {
                    usersListToBeAdded.map((userData) => {
                        userData.employer = "";
                        userData.name = "";
                        userData.phoneNumber = "";
                        userData.status = "I";
                        projectUsersList.push(userData);
                        return null;
                    });
                    
                    this.callSnackbar("success", "User added successfully.");
                    this.setState({ addUserPopUpLoading: false });
                    this.listUsers();
                    this.setState({ addUserPopUpList: [] });
                    this.setState({ addUserPopUpStatus: false });
                    this.resetUserList();

                }).catch(error => {
                    this.callSnackbar("error", "Error in adding user");
                    this.setState({ addUserPopUpLoading: false });
                    this.setState({ addUserPopUpList: [] });
                    this.setState({ addUserPopUpStatus: false });
                })

            } else {
                this.setState({ addUserPopUpLoading: false });
                this.setState({ addUserPopUpList: [] });
                this.setState({ addUserPopUpStatus: false });
            }
        } else {
            this.callSnackbar("error", "Not Authorized");
        }


    }

    callSnackbar = (errorType, Body) => {
        this.setState({
            type: errorType,
            message: Body
        });
        document.getElementById('snackbar-custom').open = true;
    }

    removeUserConfirmation(e, userId, emailId) {
        this.setState({ deleteConfirmPopUpStatus: true });
        this.setState({ deleteingEmail: emailId });
        this.setState({ deleteingUserId: userId });

    }
    removeUserConfirm() {
        if (this.state.deleteingUserId) {
            this.removeUser(this.state.deleteingUserId);
        }
        this.closeDeleteUserPopUp();
    }

    removeUser = (userId) => {
        if (this.state.userScope.access.deleteUser === true) {
            apiSession.removeUser({ "userId": userId }).then(response => {
                let userData = Object.assign([], this.state.users);
                var index = userData.findIndex(obj => obj.userId === userId);
                if (index !== -1) {
                    delete userData[index];
                    userData = userData.filter(function (dataElement) {
                        return dataElement !== undefined;
                    });
                    this.setState({ users: userData });
                    this.setState({ usersData: userData });
                    this.callSnackbar("success", "User deleted successfully.");
                }
            }).catch(error => {
                if (error.response !== undefined) {
                    if (error.response.status === 403) {
                        this.callSnackbar("error", "Error in deleting user in the application contact administrator");
                    }
                    else {
                        this.callSnackbar("error", "Not Authorized");
                    }
                } else {
                    this.callSnackbar("error", "Error in network");
                }

            });
        } else {
            this.callSnackbar("error", "Not Authorized");
        }

    }
    closeDeleteUserPopUp() {
        this.setState({ deleteConfirmPopUpStatus: false });
        this.setState({ deleteingEmail: "" });
        this.setState({ deleteingUserId: "" });
    }

    closeEditRole = () => {
        this.setState({ editUserRolePopUpStatus: false });
    }

    editUser = (e, userId, email, role) => {
        if (this.state.userScope.access.changeRole === true) {
            this.setState({ selectedEmail: email, selectedRole: role, selectedUserId: userId });
            apiSession.getRoles().then(response => {
                this.setState({ rolesList: response.userRoles });
                this.setState({ rolesPermission: response.roleDetails });
                this.setState({ editUserRolePopUpStatus: true });
            })
                .catch(error => {
                    this.setState({ editUserRolePopUpStatus: false });
                })
        } else {
            this.callSnackbar("error", "Not Authorized");
        }
    }
    onEditUserPopUpSave = (id, role) => {
        this.setState({ editUserRolesPopUpLoading: true });
        apiSession.changeRole({ "userId": parseInt(id), "role": role }).then(response => {
            let userData = Object.assign([], this.state.users);
            var index = userData.findIndex(obj => obj.userId === id);
            userData[index].role = role;
            this.setState({ "users": userData });
            this.listUsers();
            this.setState({ editUserRolesPopUpLoading: false });
            this.setState({ editUserRolePopUpStatus: false });
            this.callSnackbar("success", "Updated successfully");
        }).catch(error => {
            this.setState({ editUserRolesPopUpLoading: false });
            this.setState({ editUserRolePopUpStatus: false });
            this.callSnackbar("error", "Error in role update");
        })
    }

    openReinviteConfirm(e, userData) {
        this.setState({ reinviteConfirmPopUpStatus: true });
        this.setState({ reinviteEmail: userData.email });
        this.setState({ reinviteRole: userData.role });
    }

    reinviteUser(email, role) {
        this.setState({ loading: true });
        apiSession.reinviteToEMB({ "email": email, "role": role }).then(response => {
            this.callSnackbar("success", "Successfully  Re invited to EMB.");
            this.resetUserList();
            this.listUsers();
            this.setState({ loading: false });
        }).catch(error => {
            this.callSnackbar("error", "Error in re inviting to EMB.");
            this.setState({ loading: false });
        })

    }

    closeReinvitePopUp() {
        this.setState({ reinviteConfirmPopUpStatus: false });
        this.setState({ reinviteEmail: "" });
        this.setState({ reinviteRole: "" });
    }
    reinviteConfirm() {
        if (this.state.reinviteEmail !== "") {
            this.reinviteUser(this.state.reinviteEmail, this.state.reinviteRole);
        }
        this.closeReinvitePopUp();
    }

    openInviteDeletePopUp(e, userId) {
        this.setState({
            invitedDeleteConfirmPopUpStatus: true
        });
        this.setState({
            deleteingUserId: userId
        });
    }

    closeInviteDeletePopUp() {
        this.setState({
            invitedDeleteConfirmPopUpStatus: false
        })
        this.setState({
            deleteingUserId: ""
        });

    }

    inviteDeleteConfirm() {
        if (this.state.invitedDeleteConfirmPopUpStatus === true) {
            this.setState({ loading: true });
            this.setState({
                invitedDeleteConfirmPopUpStatus: false
            }, () => {
                if (this.state.invitedDeleteConfirmPopUpStatus === false) {

                    apiSession.deleteUsers(this.state.deleteingUserId).then(response => {
                        this.callSnackbar("success", "User deleted successfully.");
                        this.closeInviteDeletePopUp();
                        this.resetUserList();
                        this.listUsers();
                        this.setState({ loading: false });
                    }).catch(error => {
                        this.callSnackbar("error", "error in deleting user.");
                        this.closeInviteDeletePopUp();
                        this.setState({ loading: false });
                    })

                }

            });
        }


    }

    openCancelledDeletePopUp(e, userId) {
        this.setState({
            cancelledDeleteConfirmPopUpStatus: true
        });
        this.setState({
            deleteingUserId: userId
        });
    }

    closeCancelledDeletePopUp() {
        this.setState({
            cancelledDeleteConfirmPopUpStatus: false
        });
        this.setState({
            deleteingUserId: ""
        });

    }
    cancelledDeleteConfirm() {
        if (this.state.cancelledDeleteConfirmPopUpStatus === true) {
            this.setState({
                loading: true
            });
            this.setState({
                cancelledDeleteConfirmPopUpStatus: false
            }, () => {
                if (this.state.cancelledDeleteConfirmPopUpStatus === false) {
                    apiSession.deleteUsers(this.state.deleteingUserId).then(response => {
                        this.callSnackbar("success", "User deleted successfully.");
                        this.closeCancelledDeletePopUp();
                        this.resetUserList();
                        this.listUsers();
                        this.setState({ loading: false });
                    }).catch(error => {
                        this.callSnackbar("error", "error in deleting user.");
                        this.closeCancelledDeletePopUp();
                        this.setState({ loading: false });
                    })
                }

            });
        }
    }




    render() {
        return (
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background">
                <se-loading loading={this.state.loading}></se-loading>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1 px-0 text-right pt-2 pr-2">
                        <div className="form-row justify-content-end align-items-center" >

                            <span className="pointer" style={this.state.searchUserInput ? {} : { display: "none" }} onClick={this.resetUserList.bind(this)}><se-icon className="cheatSheetIcon se-icon icon_button " >
                                data_refresh</se-icon></span>&nbsp;&nbsp;
                        <input className="form-control form-control-sm mr-sm-2 col-md-3 inline" type="text" name="searchUserInput" value={this.state.searchUserInput}
                                placeholder="Search" onChange={e => this.handleUserSearchInput(e)} aria-label="Search" autoComplete="none" />

                            <select className="form-control form-control-sm col-md-2 inline" id="exampleFormControlSelect1" name="sortUserOption"
                                value={this.state.sortUserOption} onChange={this.onChangeSortUserOption.bind(this)}>
                                <option value="0">Sort</option>
                                <option value="1">Name Asc</option>
                                <option value="2">Name Desc</option>
                            </select>
                            <span>&nbsp;</span>
                            <select className="form-control form-control-sm col-md-2 inline" id="exampleFormControlSelect2" name="filteredUser"
                                value={this.state.filteredUser} onChange={this.onChangefilteredUser.bind(this)} >
                                <option value="1">Active Users</option>
                                <option value="2">Invited Users</option>
                                <option value="3">Deleted Users</option>
                                <option value="4">Cancelled Users</option>
                            </select>

                            <se-button option="raised" size="small" color="primary" icon="action_add_flat" onClick={this.addUserProject.bind(this)}
                                disabled={(this.state.userScope.access.addUsers === true) ? false : true}>Add user</se-button>

                        </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">

                        <div className="table-responsive">
                            <table className="table table-bordered mb-0">
                                <thead>
                                    <tr>
                                        <th width="13%">Name</th>
                                        <th width="10%">Role</th>
                                        <th width="10%">Employer</th>
                                        <th width="10%">Phone Number</th>
                                        <th width="20%">Email</th>
                                        {
                                            (this.state.userScope.access.changeRole === true || this.state.userScope.access.deleteUser === true) ?
                                                <th width="10%" className="text-center">Action</th>
                                                : ""
                                        }

                                    </tr>
                                </thead>
                                <tbody>

                                    {this.state.users.slice(this.state.itemSliceFirst, this.state.itemSliceLast).map((userData, key) => (


                                        <tr>

                                            <td width="13%" className="assetListTable" title={userData.name !== null && userData.name !== "null null" ? userData.name : ""}>
                                                {userData.name !== null && userData.name !== "null null" ? userData.name : ""}
                                            </td>
                                            <td width="10%" className="assetListTable" title={userData.role}>{userData.role}</td>
                                            <td width="10%" className="assetListTable" title={userData.employer !== undefined ? userData.employer : "Invited"}>
                                                {userData.employer !== undefined ? userData.employer : "Invited"}
                                            </td>
                                            <td width="10%" className="assetListTable" title={userData.phoneNumber !== undefined ? userData.phoneNumber : "Invited"}>
                                                {userData.phoneNumber !== undefined ? userData.phoneNumber : "Invited"}
                                            </td>
                                            <td width="10%" className="assetListTable" title={userData.email}> {userData.email}</td>


                                            {/* parseInt(this.state.filteredUser) === 1  */}
                                            {
                                                (this.state.userScope.access.changeRole === true || this.state.userScope.access.deleteUser === true) && userData.status === "A" ?
                                                    <td width="10%" className="align-middle text-center" >
                                                        {

                                                            this.state.userScope.access.changeRole === true ?
                                                                <span className="pointer"><se-icon size="small" color="primary" title="Edit"
                                                                    onClick={(e) => this.editUser(e, userData.userId, userData.email, userData.role)} >action_editor</se-icon></span>
                                                                : ""

                                                        }


                                                        <span>&nbsp; &nbsp;</span>
                                                        {
                                                            this.state.userScope.access.deleteUser === true ?
                                                                <span className="pointer"><se-icon size="small" title="Delete" color="primary"
                                                                    onClick={(e) => this.removeUserConfirmation(e, userData.userId, userData.email)} >action_delete</se-icon></span>
                                                                :
                                                                ""
                                                        }

                                                    </td>
                                                    : ""
                                            }

                                            {
                                                (userData.status === "C" ?
                                                    <td width="15%" className="align-middle text-center" >
                                                        <span className="error-msg">Invitation Cancelled</span>
                                                        <span>&nbsp;|&nbsp;</span>
                                                        <span className="pointer"><se-icon size="small" title="Re-Invite" color="primary"
                                                            onClick={(e) => this.openReinviteConfirm(e, userData)} >user_add</se-icon></span>
                                                        <span>&nbsp;|&nbsp;</span>
                                                        <span className="pointer"><se-icon size="small" title="delete invitation cancelled user" color="primary"
                                                            onClick={(e) => this.openCancelledDeletePopUp(e, userData.userId)}
                                                        >action_delete</se-icon></span>
                                                    </td> : ""
                                                )
                                            }

                                            {
                                                (userData.status === "D" ?
                                                    <td width="15%" className="align-middle text-center" >
                                                        <span className="error-msg">User Deleted</span>
                                                        <span>&nbsp;|&nbsp;</span>
                                                        <span className="pointer"><se-icon size="small" title="Re-Invite" color="primary"
                                                            onClick={(e) => this.openReinviteConfirm(e, userData)} >user_add</se-icon></span>
                                                    </td> : ""
                                                )
                                            }

                                            {
                                                (userData.status === "E" ?
                                                    <td width="20%" className="align-middle text-center" >
                                                        <span className="error-msg">Invitation Link Expired</span>
                                                        <span>&nbsp;|&nbsp;</span>
                                                        <span className="pointer"><se-icon size="small" title="Re-Invite" color="primary"
                                                            onClick={(e) => this.openReinviteConfirm(e, userData)} >user_add</se-icon></span>
                                                        <span>&nbsp;|&nbsp;</span>
                                                        <span className="pointer"><se-icon size="small" title="delete invited user" color="primary"
                                                            onClick={(e) => this.openInviteDeletePopUp(e, userData.userId)}
                                                        >action_delete</se-icon></span>
                                                    </td> : ""
                                                )
                                            }

                                            {
                                                (userData.status === "I" ?
                                                    <td width="15%" className="align-middle text-center" >
                                                        <span className="success-msg">Invitation sent</span>
                                                        <span>&nbsp;|&nbsp;</span>
                                                        <span className="pointer"><se-icon size="small" title="delete invited user" color="primary"
                                                            onClick={(e) => this.openInviteDeletePopUp(e, userData.userId)}
                                                        >action_delete</se-icon></span>

                                                    </td> : ""
                                                )
                                            }


                                        </tr>

                                    ))}


                                </tbody>
                            </table>
                        </div>
                        {
                            this.state.users.length === 0 && this.state.loading === true ?
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 border listStyle mb-1 pt-2" >
                                    <p className="text-center se-font-16px-bold">Please Wait... Loding Users</p>
                                </div>
                                :
                                this.state.users.length === 0 && this.state.loading === false ?
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 border listStyle mb-1 pt-2" >
                                        <p className="text-center se-font-16px-bold">No users available</p>
                                    </div>
                                    : ""

                        }


                        {this.state.users.length > this.state.itemsPerPage ? <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={this.state.itemsPerPage}
                            totalItemsCount={this.state.users.length}
                            pageRangeDisplayed={5}
                            innerclassName="pagination pagination-users"
                            onChange={this.handlePageChange.bind(this)}
                        /> : ""
                        }
                        <span className="float-right">
                            users: {this.state.users.length}
                        </span>

                    </div>
                </div>

                <ProjectAddUser addUserPopUpstatus={this.state.addUserPopUpStatus} closeAddUser={this.closeAddUser}
                    addUserPopUpList={this.state.addUserPopUpList} rolesList={this.state.rolesList}
                    rolesPermission={this.state.rolesPermission}
                    onAddUserPopUp={this.onAddUserPopUp.bind(this)} addUserPopUpLoading={this.state.addUserPopUpLoading}
                    onDeleteUserPopUp={this.onDeleteUserPopUp.bind(this)} addUserProjectList={this.addUserToApplication.bind(this)} type={"usermanagement"} />

                <se-snackbar id="snackbar-custom" type={this.state.type} icon="information_stroke" message={this.state.message} duration="3000" ></se-snackbar>

                <EditRole editUserRolePopUpStatus={this.state.editUserRolePopUpStatus} editUserRolesPopUpLoading={this.state.editUserRolesPopUpLoading}
                    closeEditUserRolePopUp={this.closeEditRole} rolesList={this.state.rolesList} rolesPermission={this.state.rolesPermission} selectedRole={this.state.selectedRole}
                    selectedEmail={this.state.selectedEmail} selectedUserId={this.state.selectedUserId}
                    onEditUserPopUp={this.onEditUserPopUpSave.bind(this)}
                />

                <se-dialog id="dialog-complex-delete-confirm" open={this.state.deleteConfirmPopUpStatus} can-backdrop="false">
                    <se-dialog-header>Confirmation</se-dialog-header>
                    <se-dialog-content
                    >
                        <span className="se-font-14px">Do you confirm to delete the user - {this.state.deleteingEmail} ?</span>
                    </se-dialog-content>
                    <se-dialog-footer>
                        <se-button onClick={this.closeDeleteUserPopUp.bind(this)}>Cancel</se-button
                        >
                        <se-button onClick={this.removeUserConfirm.bind(this)}>OK</se-button
                        >
                    </se-dialog-footer>
                </se-dialog>



                <se-dialog id="dialog-complex-delete-confirm" open={this.state.reinviteConfirmPopUpStatus} can-backdrop="false">
                    <se-dialog-header>Confirmation</se-dialog-header>
                    <se-dialog-content
                    >
                        <span className="se-font-14px">Do you confirm to re-invite the user - <span className="success-msg"><b>{this.state.reinviteEmail}</b></span> with role <span className="success-msg"><b>{this.state.reinviteRole}</b></span> to Ecostruxure Microgrid Build ?</span>
                    </se-dialog-content>
                    <se-dialog-footer>
                        <se-button onClick={this.closeReinvitePopUp.bind(this)}>Cancel</se-button
                        >
                        <se-button onClick={this.reinviteConfirm.bind(this)}>OK</se-button
                        >
                    </se-dialog-footer>
                </se-dialog>


                <se-dialog id="dialog-complex-delete-confirm" open={this.state.invitedDeleteConfirmPopUpStatus} can-backdrop="false">
                    <se-dialog-header>Confirmation</se-dialog-header>
                    <se-dialog-content
                    >
                        <span className="se-font-14px">Do you confirm to delete invited  user from Ecostruxure Microgrid Build ?</span>
                    </se-dialog-content>
                    <se-dialog-footer>
                        <se-button onClick={this.closeInviteDeletePopUp.bind(this)}>Cancel</se-button
                        >
                        <se-button onClick={this.inviteDeleteConfirm.bind(this)}>OK</se-button
                        >
                    </se-dialog-footer>
                </se-dialog>


                <se-dialog id="dialog-complex-delete-confirm" open={this.state.cancelledDeleteConfirmPopUpStatus} can-backdrop="false">
                    <se-dialog-header>Confirmation</se-dialog-header>
                    <se-dialog-content
                    >
                        <span className="se-font-14px">Do you confirm to delete invite cancelled  user from Ecostruxure Microgrid Build ?</span>
                    </se-dialog-content>
                    <se-dialog-footer>
                        <se-button onClick={this.closeCancelledDeletePopUp.bind(this)}>Cancel</se-button
                        >
                        <se-button onClick={this.cancelledDeleteConfirm.bind(this)}>OK</se-button
                        >
                    </se-dialog-footer>
                </se-dialog>



            </div>
        );
    }
}
export default UserManagement;
