import Axios from "axios";
import API_CONFIG from "./API_CONFIG";
import _ from "lodash";

class ApiSession {
  constructor(accessToken) {
    //let accesstoken = localStorage.getItem('se-auth0-access-token');
    Axios.defaults.baseURL = API_CONFIG.baseUrl;
    Axios.defaults.headers.common["token"] = localStorage.getItem(
      "se-auth0-access-token"
    );
  }

  /* to get project list */
  getProjectList = (page, size) => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.projectList, {}, { page, size });
  };

  /* to get scope */
  getScope = () => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.getScope, {}, {});
  };

  /* to load tool tip */
  getToolTip = (page, language) => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.getToolTip, {}, { page, language });
  };

  /* to get project */
  getProject = projectId => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.getProject, {}, { projectId });
  };

  /* to get project image */
  getProjectImage = projectId => {
    Axios.defaults.responseType = "arraybuffer";
    return this.apiGet(API_CONFIG.endpoints.getProjectImage, {}, { projectId });
  };

  /* to upload poject image */
  uploadProjectImage = fb => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.uploadProjectImage, {}, fb);
  };

  /* to update poject */
  updateProject = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.updateProject, {}, data);
  };

  /* to get default in asset page */
  getAssetDefaultValues = projectId => {
    Axios.defaults.responseType = "json";
    return this.apiGet(
      API_CONFIG.endpoints.getAssetDefaultValues,
      {},
      { projectId }
    );
  };

  /* to get usecase */
  fetchUseCase = projectId => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.useCase, {}, { projectId });
  };

  /* to get Components in asset page */
  getComponents = projectId => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.components, {}, { projectId });
  };

  /* to update components in asset page */
  updateComponents = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.components, {}, data);
  };

  /* to get asset list in asset page */
  getAssetsList = assetType => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.getAssetList, {}, { assetType });
  };

  /* to generate plc program */
  generatePLCProgram = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.generatePLCProgram, {}, data);
  };

  /* to download PLC program */
  downloadPLCProgram = (projectId, userId) => {
    Axios.defaults.responseType = "arraybuffer";
    return this.apiGet(
      API_CONFIG.endpoints.downloadPLCProgram,
      {},
      { projectId, userId }
    );
  };

  /* to download HMI */
  downloadHMI = projectId => {
    Axios.defaults.responseType = "arraybuffer";
    return this.apiGet(API_CONFIG.endpoints.downloadHMI, {}, { projectId });
  };

  /* to delete the project */
  deleteProject = projectId => {
    Axios.defaults.responseType = "json";
    return this.apiDelete(
      API_CONFIG.endpoints.deleteProject,
      {},
      { projectId }
    );
  };

  /* to get all the users in user management */
  getUsers = () => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.getUsers, {}, {});
  };

  /* to get roles */
  getRoles = () => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.getRoles, {}, {});
  };
  /* to getAdd UserDetails */
  getAddUserDetails = users => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.getAddUserDetails, {}, { users });
  };

  /* to add users to the project*/
  addUsersToProject = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.addUsersToProject, {}, data);
  };

  /* to remove user from the project */
  removeUserFromProject = data => {
    Axios.defaults.responseType = "json";
    return this.apiDelete(API_CONFIG.endpoints.removeUserFromProject, {}, data);
  };

  /* remove user from the application */
  removeUser = data => {
    Axios.defaults.responseType = "json";
    return this.apiDelete(API_CONFIG.endpoints.removeUser, {}, data);
  };

  /* add user to the Application */
  addUsersToApp = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.addUsers, {}, data);
  };

  /* change Role */
  changeRole = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.changeRole, {}, data);
  };

  /* get project roles */
  getProjectRoles = data => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.getProjectRoles, {}, {});
  };

  /* get deployment status */
  getDeploymentStatus = projectId => {
    Axios.defaults.responseType = "json";
    return this.apiGet(
      API_CONFIG.endpoints.deploymentStatus,
      {},
      { projectId }
    );
  };

  /* to  unlock Deploy */
  unlockDeploy = (projectId, value) => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.unlock, {}, { projectId, value });
  };

  /* to get resgistration details */
  getRegistrationDetails = token => {
    Axios.defaults.headers.common["token"] = token;
    return this.apiGet(API_CONFIG.endpoints.getRegistrationDetails, {}, {});
  };

  /* to save Registration */
  saveRegistration = (data, token) => {
    Axios.defaults.headers.common["token"] = token;
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.saveRegistration, {}, data);
  };

  /* get Utc List */
  getUtcData = country => {
    Axios.defaults.responseType = "json";
    return this.apiGet(
      API_CONFIG.endpoints.getTimezone,
      { country },
      { country }
    );
  };

  /* get program data  */
  getProgramData = projectId => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.programData, {}, { projectId });
  };

  /* update program data */
  updateProgramData = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.programData, {}, data);
  };
  /* to get system info DER */
  getSystemInfo = projectId => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.getSystemInfo, {}, { projectId });
  };

  /* to update system info DER */
  updateSystemInfo = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.saveSystemInfo, {}, data);
  };
  /* to get selected usecase */
  getselectedUsecase = projectId => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.getTypeList, {}, { projectId });
  };
  /*  to get map details */
  getMapDetails = () => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.mapDetails, {}, {});
  };
  /* to create site in EMA */
  createEMAsite = projectId => {
    Axios.defaults.responseType = "json";
    return this.apiGet(
      API_CONFIG.endpoints.createEMASiteAPI,
      {},
      { projectId }
    );
  };

  /* to accept invitation */
  acceptInvitation = token => {
    Axios.defaults.headers.common["token"] = token;
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.acceptInvitationURL, {}, {});
  };

  /* to accept invitation */
  cancelInvitation = token => {
    Axios.defaults.headers.common["token"] = token;
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.cancelInvitationURL, {}, {});
  };
  /* to change password */
  changePassword = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.changePasswordURL, {}, data);
  };

  /* to get user image */
  getUserImage = () => {
    Axios.defaults.responseType = "arraybuffer";
    return this.apiGet(API_CONFIG.endpoints.getUserImage, {}, {});
  };
  /* to get user details */
  getUserProfile = () => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.getUserDetails, {}, {});
  };

  /* to save user details */
  saveDetails = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.saveUserDetails, {}, data);
  };

  /* to upload user image */
  uploadUserImage = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.uploadUserImage, {}, data);
  };

  /* to get BOM list */
  getBomList = projectId => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.getBom, {}, { projectId });
  };

  /* to get global parameter list */
  listglobalParams = () => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.globalParams, {}, {});
  };

  /* to update global parameter */
  updateGlobalParams = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.globalParams, {}, data);
  };

  /* to activate licence key */
  activateLicenseKey = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.activateLicense, {}, data);
  };

  /* to send query */
  sendContactAPI = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.contactAPI, {}, data);
  };

  /* to get assets list */
  getAssets = () => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.assetsListAPI, {}, {});
  };

  /* to get assets list type*/
  getAssetType = () => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.assetsTypeAPI, {}, {});
  };

  /* to save assets */
  saveAssets = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.saveAssetsAPI, {}, data);
  };

  /* to get  asset details */
  getAssetById = assetId => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.getAssetByIdAPI, {}, { assetId });
  };

  /* to upload PLC package */
  uploadPLCPackage = fb => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.uploadPLCPackageAPI, {}, fb);
  };

  /* to upload PLC package */
  uploadHMIPackage = fb => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.uploadHMIPackageAPI, {}, fb);
  };

  /* to download IPC  */
  downloadIPC = projectId => {
    Axios.defaults.responseType = "arraybuffer";
    return this.apiGet(API_CONFIG.endpoints.downloadIpcAPI, {}, { projectId });
  };

  /* to get  EMA Site details */
  getEMASite = projectId => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.getEmaSiteAPI, {}, { projectId });
  };

  /* to delete  EMA Site details */
  deleteEMASite = projectId => {
    Axios.defaults.responseType = "json";
    return this.apiDelete(
      API_CONFIG.endpoints.deleteEmaSiteAPI,
      {},
      { projectId }
    );
  };

  /* to save  favourite project */
  saveFavourite = projectId => {
    Axios.defaults.responseType = "json";
    return this.apiPost(
      API_CONFIG.endpoints.saveFavouriteAPI,
      {},
      { projectId }
    );
  };

  /* to delete  favourite project */
  deleteFavourite = projectId => {
    Axios.defaults.responseType = "json";
    return this.apiDelete(
      API_CONFIG.endpoints.deleteFavouriteAPI,
      {},
      { projectId }
    );
  };

  /* to get news */
  getNews = () => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.newsAPI, {}, {});
  };

  /* to update news */
  updateNews = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.newsAPI, {}, data);
  };

  /* to delete news */
  deleteNews = newsId => {
    Axios.defaults.responseType = "json";
    return this.apiDelete(API_CONFIG.endpoints.newsAPI, {}, { newsId });
  };

  /* to re-invite to project*/
  reinviteToProject = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.reinviteToProjectAPI, {}, data);
  };

  /* to re-invite to project*/
  reinviteToEMB = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.reinviteToEMBAPI, {}, data);
  };

  /* to get contact */
  getContacts = () => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.getContactAPI, {}, {});
  };

  /* to update contact */
  updateContact = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.saveContactAPI, {}, data);
  };

  /* to delete contact */
  deleteContact = contactId => {
    Axios.defaults.responseType = "json";
    return this.apiDelete(
      API_CONFIG.endpoints.deleteContactAPI,
      {},
      { contactId }
    );
  };

  /* to logout */
  logout = () => {
    Axios.defaults.responseType = "json";
    return this.apiDelete(API_CONFIG.endpoints.logoutAPI, {}, {});
  };

  /* to get user audit */
  getAuditByUser = userId => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.auditAPI, {}, { userId });
  };

  /* to load default */
  getDefaultInfo = () => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.defaultsAPI, {}, {});
  };

  /* to reset PLC counter */
  resetPLC = data => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.resetPLCAPI, {}, data);
  };

  /* to get analytics */
  getAnalytics = () => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.analyticsAPI, {}, {});
  };

  /* to generate report */
  generateReport = data => {
    Axios.defaults.responseType = "arraybuffer";
    return this.apiPost(API_CONFIG.endpoints.reportAPI, {}, data);
  };

  /* to delete users */
  deleteUsers = userId => {
    Axios.defaults.responseType = "json";
    return this.apiDelete(API_CONFIG.endpoints.deleteUserAPI, {}, { userId });
  };

  /* to upload SLD image */
  uploadSLDImage = fb => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.uploadSLDAPI, {}, fb);
  };

  /* to save Draft program */
  saveDraftProgram = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.programSaveAPI, {}, data);
  };

  /* to remove project image */
  deleteProjectImage = projectId => {
    Axios.defaults.responseType = "json";
    return this.apiDelete(
      API_CONFIG.endpoints.deleteProjectImageAPI,
      {},
      { projectId }
    );
  };

  /* to add user to site */
  addSiteUser = data => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.addSiteUserAPI, {}, data);
  };

  /* to update system settings */
  updateSystemSettings = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.saveSystemSettings, {}, data);
  };

  /*validate system settings*/

  validateSystemSettings = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.validateSystems, {}, data);
  };

  /* copy project */

  copyProject = data => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.copyProject, {}, data);
  };

  /* to delete site users */
  deleteSiteUser = id => {
    Axios.defaults.responseType = "json";
    return this.apiDelete(API_CONFIG.endpoints.deleteSiteUserAPI, {}, { id });
  };

  //* Get regions for contact mail

  getRegions = () => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.getRegions, {}, {});
  };

  //* Get template for DC

  getTemplate = () => {
    Axios.defaults.responseType = "json";
    return this.apiGet(API_CONFIG.endpoints.getTemplate, {}, {});
  };

  //* create new template
  createTemplate = demandCharge => {
    Axios.defaults.responseType = "json";
    return this.apiPost(API_CONFIG.endpoints.createTemplate, {}, demandCharge);
  };

  //* Delete the template

  deleteTemplate = id => {
    Axios.defaults.responseType = "json";
    return this.apiDelete(API_CONFIG.endpoints.deleteTemplate, {}, { id });
  };

  //* edit the template

  updateTemplate = demandCharge => {
    Axios.defaults.responseType = "json";
    return this.apiPut(API_CONFIG.endpoints.updateTemplate, {}, demandCharge);
  };

  /* methods for http call */
  apiGet = (uriTemplate, uriParameters, params) => {
    const url = this.parametrizedUri(uriTemplate, uriParameters);
    return this.apiAny({ method: "get", url, params });
  };

  apiPost = (uriTemplate, uriParameters, data) => {
    const url = this.parametrizedUri(uriTemplate, uriParameters);
    return this.apiAny({ method: "post", url, data });
  };

  apiPut = (uriTemplate, uriParameters, data) => {
    const url = this.parametrizedUri(uriTemplate, uriParameters);
    return this.apiAny({ method: "put", url, data });
  };

  apiDelete = (uriTemplate, uriParameters, params) => {
    const url = this.parametrizedUri(uriTemplate, uriParameters);
    return this.apiAny({ method: "delete", url, params });
  };

  apiAny = ({ method, url, params, data }) => {
    let result = Promise.resolve(url);
    result.cancel = _.noop;
    if (_.isString(url)) {
      const cancelSource = Axios.CancelToken.source();
      const cancelToken = cancelSource.token;
      result = Axios.request({ method, url, params, data, cancelToken }).then(
        _.property("data")
      );
      result.cancel = cancelSource.cancel;
    }
    result
      .then(response => {
        //do nothing
      })
      .catch(error => {
        if (error.response !== undefined) {
          if (error.response.data !== undefined) {
            if (error.response.data !== null) {
              if (
                error.response.data.httpCodeMessage === "Forbidden" &&
                error.response.data.message === "Unauthorized"
              ) {
                window.location.replace("/");
              }
            }
          }
        }
      });
    return result;
  };

  parametrizedUri = (uriTemplate, parameters) => {
    return _.reduce(
      parameters,
      (uri, value, key) => {
        return _.replace(uri, new RegExp(`{${key}}`), value);
      },
      uriTemplate
    );
  };
}

export default ApiSession;
