import React, { Component } from "react";
import APISESSION from "../api/ApiSession";
const apiSession = new APISESSION();

class BOM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bomList: [],
      loading: true,
      showDetailsLience: -1,
      showDetailsController: -1,
    };
  }
  componentDidMount() {
    this.loadBOM();
  }

  loadBOM() {
    apiSession
      .getBomList(sessionStorage.getItem("projectId"))
      .then(response => {
        let bomListNew = Object.assign([], this.state.bomList);
        bomListNew = response;
        this.setState({ bomList: bomListNew });
        this.setState({ loading: false });
      })
      .catch(error => {
        //do nothing
        this.setState({ loading: false });
      });
  }
  showLicenseMore(index) {
    this.setState({
      showDetailsLience: index,
    });
  }

  showControllerMore(index) {
    this.setState({
      showDetailsController: index,
    });
  }

  render() {
    return (
      <div>
        <se-loading loading={this.state.loading}></se-loading>

        <se-container option="centered" className="mt-2">
          <div className="container se-white-background pb-2">
            {/* <div className="row pt-1 mb-1">
                            <div className="col-md-11 text-right">
                                <input className="form-control form-control-sm mr-sm-2 col-md-2 inline" type="text" name="searchInput" value=""
                                    placeholder="Search" aria-label="Search" />
                            </div>
                            <div className="col-md-1">
                                <se-icon size="small">action_print</se-icon>
                                <se-icon size="small">exit_presentation</se-icon>
                            </div>


                        </div> */}

            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-1">
              <div className="table-responsive">
                <table className="table table-bordered mb-0 se-font-14px">
                  <thead>
                    <tr>
                      <th>Part Number</th>
                      <th>Description</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colspan="3">Controller</td>
                    </tr>
                    {this.state.bomList.map((bomData, index) =>
                      bomData.type === "controller" ? (
                        <tr>
                          <td> {bomData.partNumber}</td>
                          <td> {bomData.description}</td>
                          <td> {bomData.quantity}</td>
                        </tr>
                      ) : (
                        ""
                      )
                    )}

                    <tr>
                      <td colspan="3">License</td>
                    </tr>

                    {this.state.bomList.map((bomData, index) =>
                      bomData.type === "license" ? (
                        <tr>
                          <td> {bomData.partNumber}</td>
                          <td> {bomData.description}</td>
                          <td> {bomData.quantity}</td>
                        </tr>
                      ) : (
                        ""
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1 px-0 text-right ">
                            

                                
                                    
                            
                            
                            
                        </div> */}

            {/* <se-block >
                            <se-table>
                                <se-table-group-header>
                                    <se-table-item-header width="53px"></se-table-item-header>
                                    <se-table-item-header flex="1" min-width="100px" sort="asc">Part Number</se-table-item-header>
                                    <se-table-item-header flex="3" min-width="600px" >Description</se-table-item-header>
                                    <se-table-item-header flex="1" min-width="100px" >Quantity</se-table-item-header>
                                </se-table-group-header>


                                <se-table-group >
                                    <div slot="detail">
                                        <div style={{ padding: "5px 24px" }}>
                                            <span><se-icon option="button">arrow2_down</se-icon>Controllers</span>
                                        </div>
                                    </div>
                                </se-table-group>

                                {this.state.bomList.map((bomData, index) => (
                                    bomData.type === "controller" ?
                                        <se-table-group selectable="true">
                                            <se-table-item width="53px">
                                                {this.state.showDetailsController === index ?
                                                    <se-icon option="button" onClick={() => this.showControllerMore(-1)}>arrow2_up</se-icon>
                                                    :
                                                    <se-icon option="button" onClick={() => this.showControllerMore(index)}>arrow2_down</se-icon>
                                                }

                                            </se-table-item>
                                            <se-table-item flex="1" min-width="100px">{bomData.partNumber}</se-table-item>
                                            <se-table-item flex="3" min-width="600px">{bomData.description}</se-table-item>
                                            <se-table-item flex="1" min-width="100px">{bomData.quantity}</se-table-item>
                                            <div slot="detail" style={this.state.showDetailsController === index ? {} : { display: "none" }}>
                                                <div style={{ padding: "12px 24px" }}>
                                                    <p>{bomData.partNumber}</p>
                                                    <p>{bomData.description}</p>
                                                </div>
                                            </div>
                                        </se-table-group>
                                        : ""
                                ))

                                }

                                <se-table-group >
                                    <div slot="detail">
                                        <div style={{ padding: "5px 24px" }}>
                                            <span><se-icon option="button">arrow2_down</se-icon>Licenses</span>
                                        </div>
                                    </div>
                                </se-table-group>

                                {this.state.bomList.map((bomData, index) => (
                                    bomData.type === "license" ?
                                        <se-table-group selectable="true">
                                            <se-table-item width="53px">
                                                {this.state.showDetailsLience === index ?
                                                    <se-icon option="button" onClick={() => this.showLicenseMore(-1)}>arrow2_up</se-icon>
                                                    :
                                                    <se-icon option="button" onClick={() => this.showLicenseMore(index)}>arrow2_down</se-icon>
                                                }

                                            </se-table-item>
                                            <se-table-item flex="1" min-width="100px">{bomData.partNumber}</se-table-item>
                                            <se-table-item flex="3" min-width="600px">{bomData.description}</se-table-item>
                                            <se-table-item flex="1" min-width="100px">{bomData.quantity}</se-table-item>
                                            <div slot="detail" style={this.state.showDetailsLience === index ? {} : { display: "none" }}>
                                                <div style={{ padding: "12px 24px" }}>
                                                    <p>{bomData.partNumber}</p>
                                                    <p>{bomData.description}</p>
                                                </div>
                                            </div>
                                        </se-table-group>
                                        : ""
                                ))

                                }



                            </se-table>
                        </se-block> */}
          </div>
        </se-container>
      </div>
    );
  }
}
export default BOM;
