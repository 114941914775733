import React, { Component } from "react";

class SLDPv1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOverData: false,
    };
  }
  onCBMouseOver() {
    this.setState({ mouseOverData: !this.state.mouseOverData });
  }
  padNumberFormat(number) {
    return (number < 10 ? "0" : "") + number;
  }
  render() {
    const { pv1, onPv1Select, pvGroupName } = this.props;
    return [
      <svg className="pointer" style={pv1.status ? {} : { display: "none" }}>
        <g id="pv1">
          <svg
            x={pv1.xAxis}
            y={pv1.yAxis}
            // x="460"
            // y="530"

            width="195px"
            height="130px"
            viewBox="0 0 10 149"
            onClick={onPv1Select}
          >
            <title>{pvGroupName}</title>
            <g
              id="Design"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Design/Assets-_-00"
                transform="translate(-1036.000000, -372.000000)"
              >
                <g
                  id="SLD/PV/Uniline/Multiple_Done"
                  transform="translate(1036.000000, 372.000000)"
                >
                  <circle
                    id="Oval"
                    stroke="#42B4E6"
                    stroke-width="3"
                    cx="48"
                    cy="56"
                    r="55"
                    style={pv1.selected ? {} : { display: "none" }}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    fill="rgba(0, 135, 205, 0.2)"
                  ></circle>

                  <circle
                    id="Oval"
                    stroke={pv1.configured ? "#333" : "#E47F00"}
                    stroke-width="3"
                    fill="#FFFFFF"
                    fill-rule="evenodd"
                    cx="57"
                    cy="59"
                    r="43.5"
                  ></circle>

                  {/* <line
                    x1="80"
                    y1="100"
                    x2="150"
                    y2="220"
                    id="Line-2"
                    stroke={pv1.configured ? "#333333" : "#E47F00"}
                    stroke-width="4"
                    strokeDasharray="5 2"
                    // stroke-linejoin="round"
                  ></line> */}
                  <circle
                    id="Oval"
                    stroke={pv1.configured ? "#333" : "#E47F00"}
                    stroke-width="3"
                    fill="#FFFFFF"
                    fill-rule="evenodd"
                    cx="48"
                    cy="55"
                    r="46.5"
                  ></circle>

                  <circle
                    id="Oval"
                    stroke={pv1.configured ? "#333" : "#E47F00"}
                    stroke-width="3"
                    fill="#FFFFFF"
                    fill-rule="evenodd"
                    cx="48"
                    cy="55"
                    r="46.5"
                  ></circle>

                  <g
                    id="999🔒Atoms/Notifications/Done"
                    transform="translate(66.000000, 2.000000)"
                  >
                    <g
                      id="999🔒Atoms/Background/Round"
                      stroke-width="1"
                      fill-rule="evenodd"
                      fill={pv1.configured ? "#333333" : "#E47F00"}
                      stroke="#FFFFFF"
                    >
                      <circle
                        id="Oval"
                        stroke-width="2.66666667"
                        cx="16"
                        cy="16"
                        r="18"
                      ></circle>
                    </g>

                    <text
                      id="3"
                      font-family="Nunito-Regular, Nunito"
                      font-size="24"
                      font-weight="normal"
                      fill="#FFFFFF"
                    >
                      <tspan x="2" y="24">
                        {this.padNumberFormat(pv1.count)}
                      </tspan>
                    </text>
                  </g>
                  <text
                    id="solar_panel1"
                    font-family="se-icon"
                    font-size="56"
                    font-weight="normal"
                    fill="#333333"
                  >
                    <tspan x="20" y="69">
                      solar_panel1
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </svg>
          <line
            x1={pv1.x1pos}
            y1={pv1.y1pos}
            x2={pv1.x2pos}
            y2={pv1.y2pos}
            id="Line-2"
            stroke-width="4"
            stroke-dasharray="6 8"
            stroke-linecap="round"
            stroke={pv1.configured ? "#333333" : "#E47F00"}
            // stroke-linejoin="round"
          ></line>
        </g>
      </svg>,
    ];
  }
}

export default SLDPv1;
