import ACTIONS from "../Design_Asset_Actions.json";
import _ from "lodash";

import embLabels from "../../../config/emb-labels-en.json";

const communicationDataSample = {
  ipaddress: "",
  subnetmask: "",
  defaultGateway: "",
  modbusSlaveId: "",
};

const gensetAssetSample = {
  assetAttributes: [],
  assetEnabled: true,
  assetId: "",
  assetInfo: "",
  assetProperties: [],
  assetType: "",
  comments: "",
  communicationModel: "",
  imageLink: null,
  manufacturer: "",
};

const initialCBAssetList = [
  {
    modbusSlaveId: "255",
    assetType: "Utility",
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
    },
    configData: {
      name: "",
      description: "",
      utilityCO2: "",
      maximum_available_power: 0,
    },
    uiData: {
      configured: false,
      configurable: false,
      status: false,
      detailsBlock: false,
      selected: false,
      CBSelected: false,
      CBConfigured: true,
      pmSelected: false,
      pmConfigured: true,
      xAxisCB: 250,
      yAxisCB: 192,
      xAxis: 150,
      yAxis: 70,
      x1pos: 600,
      y1pos: 301,
      x2pos: 600,
      y2pos: 156,
    },
    communicationData: {},
    powerMeterData: {
      name: "",
      description: "",
      powerMeterModbusId: "",
      ipAddress: "",
      gatewayAddress: "",
      mask: "",
      pmAsset: {},
    },
  },
  {
    modbusSlaveId: "2",
    assetType: "Genset",
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
    },
    configData: {
      name: "",
      description: "",
      min_power: "",
      max_power: "",
      gensetCO2: "",
      starting_timer: "",
      cooling_timer: "",
      minimum_running_period: "",
    },
    uiData: {
      configured: false,
      status: false,
      configurable: false,
      detailsBlock: false,
      selected: false,
      CBSelected: false,
      CBConfigured: true,
      pmSelected: false,
      pmConfigured: true,
      xAxisCB: 360,
      yAxisCB: 192,
      xAxis: 260,
      yAxis: 70,
      x1pos: 675,
      y1pos: 350,
      x2pos: 790,
      y2pos: 350,
    },
    assetDetails: [],
    communicationData: {},
    powerMeterData: {
      name: "",
      description: "",
      powerMeterModbusId: "",
      ipAddress: "",
      gatewayAddress: "",
      mask: "",
      pmAsset: {},
    },
  },
  {
    modbusSlaveId: "3",
    assetType: "BESS",
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
    },
    configData: {
      name: "",
      description: "",
      max_power: "",
      capacity: 0,
      soc_max: "",
      soc_min: "",
      starting_timer: "",
    },
    uiData: {
      configured: false,
      status: false,
      detailsBlock: false,
      selected: false,
      CBConfigured: true,
      pmSelected: false,
      pmConfigured: true,
      xAxisCB: 470,
      yAxisCB: 192,
      xAxis: 370,
      yAxis: 70,
      x1pos: 600,
      y1pos: 301,
      x2pos: 600,
      y2pos: 156,
    },
    assetDetails: [],
    communicationData: {},
  },
  {
    modbusSlaveId: "4",
    assetType: "Inverter",
    pv: 1,
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
    },
    configData: {
      name: "",
      description: "",
    },
    uiData: {
      set: 1,
      configured: false,
      status: false,
      detailsBlock: false,
      selected: false,
      CBConfigured: true,
      pmSelected: false,
      pmConfigured: true,
      count: 0,
      xAxisCB: 470,
      yAxisCB: 192,
      xAxis: 370,
      yAxis: 70,
      x1pos: 600,
      y1pos: 301,
      x2pos: 600,
      y2pos: 156,
    },
    communicationData: {},
    subPvData: [],
    powerMeterData: {
      name: "",
      description: "",
      powerMeterModbusId: "",
      ipAddress: "",
      gatewayAddress: "",
      mask: "",
      pmAsset: {},
    },
  },
  {
    modbusSlaveId: "5",
    assetType: "Inverter",
    pv: 2,
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
    },
    configData: {
      name: "",
      description: "",
    },
    uiData: {
      set: 2,
      configured: false,
      status: false,
      detailsBlock: false,
      selected: false,
      CBConfigured: true,
      pmSelected: false,
      pmConfigured: true,
      count: 0,
      xAxisCB: 470,
      yAxisCB: 192,
      xAxis: 370,
      yAxis: 70,
    },
    communicationData: {},
    subPvData: [],
  },
  {
    modbusSlaveId: "6",
    assetType: "Inverter",
    pv: 3,
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
    },
    configData: {
      name: "",
      description: "",
    },
    uiData: {
      set: 3,
      configured: false,
      status: false,
      detailsBlock: false,
      selected: false,
      CBConfigured: true,
      pmSelected: false,
      pmConfigured: true,
      count: 0,
      xAxisCB: 470,
      yAxisCB: 192,
      xAxis: 370,
      yAxis: 70,
    },
    communicationData: {},
    subPvData: [],
  },
  {
    modbusSlaveId: "7",
    assetType: "CHP",
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
    },
    configData: {
      name: "",
      description: "",
      nominal_power: "",
    },
    uiData: {
      configured: false,
      status: false,
      configurable: false,
      detailsBlock: false,
      selected: false,
      CBSelected: false,
      CBConfigured: true,
      pmSelected: false,
      pmConfigured: true,
      xAxisCB: 360,
      yAxisCB: 192,
      xAxis: 260,
      yAxis: 70,
      x1pos: 600,
      y1pos: 301,
      x2pos: 600,
      y2pos: 156,
    },
    assetDetails: [],
    communicationData: {},
    powerMeterData: {
      name: "",
      description: "",
      powerMeterModbusId: "",
    },
  },
];

const CBAssetListReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ASSIGN_DEFAULT_VALUES:
      return assignDefaultValuesCBAssetList(
        state,
        action.payload.defaultValues,
        action.payload.type,
        action.payload.architecture,
        action.payload.genset,
        action.payload.pmUtility,
        action.payload.pmGenset,
        action.payload.pmPv
      );
    case ACTIONS.ASSIGN_USECASE_UTILITY:
      return assignUseCaseDer(state, 0);
    case ACTIONS.ASSIGN_USECASE_GENSET:
      return assignUseCaseDer(state, 1);
    case ACTIONS.ASSIGN_USECASE_BESS:
      return assignUseCaseDer(state, 2);
    case ACTIONS.ASSIGN_USECASE_PV:
      return assignUseCaseDer(state, 3);
    case ACTIONS.UPDATE_CBASSETLIST_COORDINATES:
      return updateCbAssetListCoordinates(state, action.payload.coordinates);
    case ACTIONS.UPDATE_CBASSETLIST_COORDINATES_GRIDCONNECTED:
      return updateCbAssetListCoordinatesGridConnected(
        state,
        action.payload.index,
        action.payload.positions,
        action.payload.petal
      );
    case ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVHYBRIDIZATION:
      return updateCbAssetListCoordinatesPvHybridization(
        state,
        action.payload.coordinates
      );
    case ACTIONS.UPDATE_CBASSETLIST_COORDINATES_PVGENSET_ATS:
      return updateCbAssetListCoordinatesPvGensetAts(state);
    case ACTIONS.UPDATE_UTILITY:
      return updateUtility(state, action.payload.componentsData);
    case ACTIONS.UPDATE_GENSET:
      return updateGenset(state, action.payload.componentsData);
    case ACTIONS.UPDATE_BESS:
      return updateBess(
        state,
        action.payload.componentsData,
        action.payload.batteryCommunicationData,
        action.payload.setBatteryCommunicationData
      );
    case ACTIONS.UPDATE_CHP:
      return updateChp(state, action.payload.componentsData);
    case ACTIONS.UPDATE_PV:
      return updatePv(
        state,
        action.payload.componentsData,
        action.payload.pvArray
      );
    case ACTIONS.ADD_GENSET:
      return addGenset(
        state,
        action.payload.defaultPreFill,
        action.payload.configData
      );
    case ACTIONS.ADD_BESS:
      return addBess(
        state,
        action.payload.defaultPreFill,
        action.payload.configData
      );
    case ACTIONS.ADD_CHP:
      return addChp(
        state,
        action.payload.defaultPreFill,
        action.payload.configData,
        action.payload.siteType
      );
    case ACTIONS.REMOVE_ASSET_DER:
      return removeAssetDer(
        state,
        action.payload.index,
        action.payload.configData
      );
    case ACTIONS.REMOVE_ASSET_DER_PV:
      return removeAssetDerPv(state, action.payload.selectedPv);
    case ACTIONS.UPDATE_DER_CB:
      return updateDerCb(
        state,
        action.payload.componentsData,
        action.payload.value
      );
    case ACTIONS.UPDATE_DER_PM:
      return updateDerPm(
        state,
        action.payload.componentsData,
        action.payload.value
      );
    case ACTIONS.UPDATE_PV_CONFIRGURED:
      return updatePvConfigured(state, action.payload.PVDetails);
    case ACTIONS.CLOSEDETAILS_ASSET:
      return closeDetailsAsset(state);
    case ACTIONS.ASSET_SELECT:
      return assetSelect(state, action.payload.index);
    case ACTIONS.UPDATE_ASSET_CONFIG:
      return updateAssetConfig(
        state,
        action.payload.selectedAsset,
        action.payload.configData
      );
    case ACTIONS.DUPLICATE_DER_UPDATE:
      return duplicateDerUpdate(state, action.payload.selectedAsset);
    case ACTIONS.ASSET_CB_SELECT:
      return assetCbSelect(state, action.payload.index);
    case ACTIONS.ASSET_PM_SELECT:
      return assetPmSelect(state, action.payload.index);
    case ACTIONS.UPDATE_CB_CONFIG:
      return updateCbConfig(
        state,
        action.payload.selectedAssetCB,
        action.payload.cbConfigData
      );
    case ACTIONS.UPDATE_POWERMETER_CONFIG:
      return updatePowerMeterConfig(
        state,
        action.payload.selectedAssetPM,
        action.payload.cbConfigData
      );
    case ACTIONS.SELECT_DER_ASSET:
      return selectDerAsset(
        state,
        action.payload.index,
        action.payload.selectedAsset
      );
    case ACTIONS.SELECT_SERIAL_ASSET:
      return selectSerialAsset(
        state,
        action.payload.index,
        action.payload.modbusSlaveId
      );
    case ACTIONS.SELECT_COMMUNICATING_ASSET:
      return selectCommunicatingAsset(
        state,
        action.payload.index,
        action.payload.preFillDefault
      );
    case ACTIONS.INDIVIDUAL_PV_ASSET_SELECT:
      return individualPvAssetSelect(state, action.payload.selectedPv);
    case ACTIONS.REDUCE_PV_COUNT_FROM_CB:
      return reducePvCountFromCb(
        state,
        action.payload.selectedPv,
        action.payload.pvConStatusOne
      );
    case ACTIONS.ADD_PV_TO_CBASSET_LIST:
      return addPvToCbAssetList(
        state,
        action.payload.selectedPv,
        action.payload.PVDetails,
        action.payload.configData,
        action.payload.preFillDefault
      );
    default:
      return state;
  }
};

const assignDefaultValuesCBAssetList = (
  CBAssetList,
  defaultValues,
  type,
  architectureType,
  genset,
  pmUtility,
  pmGenset,
  pmPv
) => {
  const derList = _.cloneDeep(CBAssetList);
  let circuitBreakersModbusSlaveIdList = defaultValues["Circuit Breaker"];

  //* utility
  derList[0].configData.name = defaultValues.Utility.name;
  derList[0].configData.utilityCO2 = defaultValues.Utility.utilityCO2;
  derList[0].cbConfigData.name = `${defaultValues["Circuit Breaker"].name}_${defaultValues.Utility.name}`;
  derList[0].modbusSlaveId = circuitBreakersModbusSlaveIdList["modbusId_CB_10"];

  //* generator
  derList[1].configData.name = defaultValues.Generator.name;
  derList[1].configData.gensetCO2 = defaultValues.Generator.gensetCO2;
  derList[1].configData.starting_timer = defaultValues.Generator.starting_timer;
  derList[1].configData.cooling_timer = defaultValues.Generator.cooling_timer;
  derList[1].configData.minimum_running_period =
    defaultValues.Generator.minimum_running_period;
  derList[1].cbConfigData.name = `${defaultValues["Circuit Breaker"].name}_${defaultValues.Generator.name}`;
  derList[1].modbusSlaveId = circuitBreakersModbusSlaveIdList["modbusId_CB_20"];
  derList[1].communicationData = { ...communicationDataSample };
  derList[1].communicationData.ipaddress = defaultValues.Generator.ipAddress;
  derList[1].communicationData.subnetmask = defaultValues.Generator.subnetMask;
  derList[1].communicationData.defaultGateway =
    defaultValues.Generator.defaultGateway;
  derList[1].communicationData.modbusSlaveId = defaultValues.Generator.modbusId;

  //* battery

  derList[2].configData.name = defaultValues.BESS.name;
  derList[2].configData.soc_max = defaultValues.BESS.soc_max;
  derList[2].configData.soc_min = defaultValues.BESS.soc_min;
  derList[2].cbConfigData.name = `${defaultValues["Circuit Breaker"].name}_${defaultValues.BESS.name}`;
  derList[2].modbusSlaveId = circuitBreakersModbusSlaveIdList["modbusId_CB_30"];
  derList[2].communicationData = { ...communicationDataSample };
  derList[2].communicationData.ipaddress = defaultValues.BESS.ipAddress;
  derList[2].communicationData.subnetmask = defaultValues.BESS.subnetMask;
  derList[2].communicationData.defaultGateway =
    defaultValues.BESS.defaultGateway;
  derList[2].communicationData.modbusSlaveId = defaultValues.BESS.modbusId;

  //* inverters

  derList[3].cbConfigData.name = `${defaultValues["Circuit Breaker"].name}_${defaultValues.PV_inverter.groupName} 1`;
  derList[3].modbusSlaveId = circuitBreakersModbusSlaveIdList["modbusId_CB_40"];
  derList[4].cbConfigData.name = `${defaultValues["Circuit Breaker"].name}_${defaultValues.PV_inverter.groupName} 2`;
  derList[4].modbusSlaveId = circuitBreakersModbusSlaveIdList["modbusId_CB_41"];
  derList[5].cbConfigData.name = `${defaultValues["Circuit Breaker"].name}_${defaultValues.PV_inverter.groupName} 3`;
  derList[5].modbusSlaveId = circuitBreakersModbusSlaveIdList["modbusId_CB_42"];

  //*CHP

  derList[6].configData.name = defaultValues.CHP.name;
  derList[6].configData.nominal_power = defaultValues.CHP.nominalPower;
  derList[6].cbConfigData.name = `${defaultValues["Circuit Breaker"].name}_${defaultValues.CHP.name}`;
  derList[6].modbusSlaveId = circuitBreakersModbusSlaveIdList["modbusId_CB_80"];
  derList[6].communicationData = { ...communicationDataSample };
  derList[6].communicationData.ipaddress = defaultValues.CHP.ipAddress;
  derList[6].communicationData.subnetmask = defaultValues.CHP.subnetMask;
  derList[6].communicationData.defaultGateway =
    defaultValues.CHP.defaultGateway;
  derList[6].communicationData.modbusSlaveId = defaultValues.CHP.modbusId;

  if (type === embLabels.PV_HYBRIDIZATION) {
    // power meter
    derList[0].powerMeterData.name = defaultValues["Power Meter"].nameUtility;
    derList[0].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_Utility;
    derList[1].powerMeterData.name = defaultValues["Power Meter"].nameGenset;
    derList[1].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_Genset;
    derList[1].configData.max_power = defaultValues.Generator.max_power;
    derList[3].powerMeterData.name = defaultValues["Power Meter"].namePV;
    derList[3].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_PV;
    derList[6].powerMeterData.name = defaultValues["Power Meter"].nameCHP;
    derList[6].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_CHP;

    // genset asset

    derList[1].assetDetails[0] = { ...gensetAssetSample };
    derList[1].assetDetails[0].assetAttributes = genset.assetAttributes;
    derList[1].assetDetails[0].assetEnabled = genset.assetEnabled;
    derList[1].assetDetails[0].assetId = genset.assetId;
    derList[1].assetDetails[0].assetInfo = genset.assetInfo;
    derList[1].assetDetails[0].assetProperties = genset.assetProperties;
    derList[1].assetDetails[0].assetType = genset.assetType;
    derList[1].assetDetails[0].comments = genset.comments;
    derList[1].assetDetails[0].communicationModel = genset.communicationModel;
    derList[1].assetDetails[0].imageLink = genset.imageLink;
    derList[1].assetDetails[0].manufacturer = genset.manufacturer;
  }

  if (
    type === embLabels.ISLANDABLE_GSX &&
    architectureType === embLabels.PV_GENSET_ATS
  ) {
    // power meter
    derList[0].powerMeterData.name = defaultValues["Power Meter"].nameUtility;
    derList[0].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_Utility;
    derList[0].powerMeterData.pmAsset = { ...pmUtility };
    derList[1].powerMeterData.name = defaultValues["Power Meter"].nameGenset;
    derList[1].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_Genset;
    derList[1].configData.max_power = defaultValues.Generator.max_power;
    derList[1].powerMeterData.pmAsset = { ...pmGenset };
    derList[3].powerMeterData.name = defaultValues["Power Meter"].namePV;
    derList[3].powerMeterData.powerMeterModbusId =
      defaultValues["Power Meter"].modbusId_PM_PV_Communicating;
    derList[3].powerMeterData.ipAddress =
      defaultValues["Power Meter"].ipAddress_PM_Communicating;
    derList[3].powerMeterData.gatewayAddress = "192.168.50.254";
    derList[3].powerMeterData.mask = "255.255.255.0";
    derList[3].powerMeterData.pmAsset = { ...pmPv };

    // genset asset

    derList[1].assetDetails[0] = { ...gensetAssetSample };
    derList[1].assetDetails[0].assetAttributes = genset.assetAttributes;
    derList[1].assetDetails[0].assetEnabled = genset.assetEnabled;
    derList[1].assetDetails[0].assetId = genset.assetId;
    derList[1].assetDetails[0].assetInfo = genset.assetInfo;
    derList[1].assetDetails[0].assetProperties = genset.assetProperties;
    derList[1].assetDetails[0].assetType = genset.assetType;
    derList[1].assetDetails[0].comments = genset.comments;
    derList[1].assetDetails[0].communicationModel = genset.communicationModel;
    derList[1].assetDetails[0].imageLink = genset.imageLink;
    derList[1].assetDetails[0].manufacturer = genset.manufacturer;
  }

  // return new state
  return derList;
};

const assignUseCaseDer = (CBAssetList, der) => {
  const derList = _.cloneDeep(CBAssetList);

  derList[der].uiData.status = true;
  derList[der].uiData.CBConfigured = true;
  derList[der].uiData.pmConfigured = true;
  if (der === 0) {
    derList[der].uiData.configured = false;
  }
  if (der === 3) {
    derList[3].uiData.status = true;
    derList[3].uiData.count = 3;
  }

  // return new state
  return derList;
};

const updateCbAssetListCoordinates = (CBAssetList, coordinates) => {
  let CBAssestListCor = Object.assign([], CBAssetList);
  let xDer = coordinates.xDer;
  let xDerCB = coordinates.xDerCB;
  if (CBAssetList[0].uiData.status) {
    CBAssestListCor[0].uiData.xAxis = coordinates.xDer;
    CBAssestListCor[0].uiData.xAxisCB = coordinates.xDerCB;
  }

  if (CBAssetList[1].uiData.status) {
    xDer = coordinates.derDiff + xDer;
    xDerCB = coordinates.derDiff + xDerCB;
    CBAssestListCor[1].uiData.yAxis = 70;
    CBAssestListCor[1].uiData.xAxis = xDer;
    CBAssestListCor[1].uiData.xAxisCB = xDerCB;
  }

  if (CBAssetList[2].uiData.status) {
    xDer = coordinates.derDiff + xDer;
    xDerCB = coordinates.derDiff + xDerCB;
    CBAssestListCor[2].uiData.yAxis = 70;
    CBAssestListCor[2].uiData.xAxis = xDer;
    CBAssestListCor[2].uiData.xAxisCB = xDerCB;
  }
  if (CBAssetList[3].uiData.status) {
    xDer = coordinates.derDiff + xDer;
    xDerCB = coordinates.derDiff + xDerCB;
    CBAssestListCor[3].uiData.yAxis = 70;
    CBAssestListCor[3].uiData.xAxis = xDer;
    CBAssestListCor[3].uiData.xAxisCB = xDerCB;
  }
  if (CBAssetList[4].uiData.status) {
    xDer = coordinates.derDiff + xDer;
    xDerCB = coordinates.derDiff + xDerCB;
    CBAssestListCor[4].uiData.yAxis = 70;
    CBAssestListCor[4].uiData.xAxis = xDer;
    CBAssestListCor[4].uiData.xAxisCB = xDerCB;
  }
  if (CBAssetList[5].uiData.status) {
    xDer = coordinates.derDiff + xDer;
    xDerCB = coordinates.derDiff + xDerCB;
    CBAssestListCor[5].uiData.yAxis = 70;
    CBAssestListCor[5].uiData.xAxis = xDer;
    CBAssestListCor[5].uiData.xAxisCB = xDerCB;
  }
  if (CBAssetList[6].uiData.status) {
    xDer = coordinates.derDiff + xDer;
    xDerCB = coordinates.derDiff + xDerCB;
    CBAssestListCor[6].uiData.yAxis = 70;
    CBAssestListCor[6].uiData.xAxis = xDer;
    CBAssestListCor[6].uiData.xAxisCB = xDerCB;
  }

  // return new state
  return CBAssestListCor;
};

const updateCbAssetListCoordinatesGridConnected = (
  CBAssetList,
  index,
  positions,
  petal
) => {
  const CBAssestListCor = [...CBAssetList];
  const [x1pos, y1pos, x2pos, y2pos] = positions;
  const [xAxis, yAxis] = petal;

  CBAssestListCor[index].uiData.xAxis = xAxis;
  CBAssestListCor[index].uiData.yAxis = yAxis;
  CBAssestListCor[index].uiData.x1pos = x1pos;
  CBAssestListCor[index].uiData.y1pos = y1pos;
  CBAssestListCor[index].uiData.x2pos = x2pos;
  CBAssestListCor[index].uiData.y2pos = y2pos;
  return CBAssestListCor;
};

const updateCbAssetListCoordinatesPvHybridization = (
  CBAssetList,
  coordinates
) => {
  let CBAssestListCor = Object.assign([], CBAssetList);
  let xDer = coordinates.xDerPvHyb;
  let xDerCBPvHyb = coordinates.xDerCBPvHyb;

  if (CBAssetList[0].uiData.status) {
    CBAssestListCor[0].uiData.xAxis = xDer;
    CBAssestListCor[0].uiData.xAxisCB = xDerCBPvHyb;
    CBAssestListCor[0].uiData.yAxis = 141;
  }

  if (CBAssetList[0].uiData.status) {
    xDer = coordinates.derDiffPvHyb + xDer;
    xDerCBPvHyb = coordinates.derDiffPvHyb + xDerCBPvHyb;
    CBAssestListCor[1].uiData.xAxis = xDer;
    CBAssestListCor[1].uiData.yAxis = 141;
    CBAssestListCor[1].uiData.xAxisCB = xDerCBPvHyb;
    CBAssestListCor[1].uiData.yAxisCB = 270;
  }
  if (CBAssetList[3].uiData.status) {
    xDer = coordinates.derDiffPvHyb + xDer;
    xDerCBPvHyb = coordinates.derDiffPvHyb + xDerCBPvHyb;
    CBAssestListCor[3].uiData.xAxis = xDer;
    CBAssestListCor[3].uiData.yAxis = 142;
    CBAssestListCor[3].uiData.xAxisCB = xDerCBPvHyb;
    CBAssestListCor[3].uiData.yAxisCB = 270;
  }
  if (CBAssetList[6].uiData.status) {
    xDer = coordinates.derDiffPvHyb + xDer;
    xDerCBPvHyb = coordinates.derDiffPvHyb + xDerCBPvHyb;
    CBAssestListCor[6].uiData.xAxis = xDer;
    CBAssestListCor[6].uiData.yAxis = 141;
    CBAssestListCor[6].uiData.xAxisCB = xDerCBPvHyb;
    CBAssestListCor[6].uiData.yAxisCB = 270;
  }

  // return new state
  return CBAssestListCor;
};

const updateCbAssetListCoordinatesPvGensetAts = CBAssetList => {
  let CBAssestListCor = Object.assign([], CBAssetList);
  if (CBAssetList[0].uiData.status) {
    CBAssestListCor[0].uiData.xAxis = "124.5";
    CBAssestListCor[0].uiData.yAxis = "39";
  }
  if (CBAssetList[1].uiData.status) {
    CBAssestListCor[1].uiData.xAxis = "245.5";
    CBAssestListCor[1].uiData.yAxis = "39";
  }
  if (CBAssetList[3].uiData.status) {
    CBAssestListCor[3].uiData.xAxis = "645";
    CBAssestListCor[3].uiData.yAxis = "205";
    CBAssestListCor[3].uiData.xAxisCB = "745";
    CBAssestListCor[3].uiData.yAxisCB = "333";
  }
  // return new state
  return CBAssestListCor;
};

const updateUtility = (CBAssetList, componentsData) => {
  const CBAssestListData = _.cloneDeep(CBAssetList);
  const attributes = JSON.parse(componentsData.attributes);
  CBAssestListData[0].uiData = JSON.parse(componentsData.uiAttributes);
  CBAssestListData[0].uiData.selected = false;
  CBAssestListData[0].uiData.CBSelected = false;
  CBAssestListData[0].uiData.pmSelected = false;
  CBAssestListData[0].configData = attributes;
  CBAssestListData[0].configData.name = componentsData.componentName;
  CBAssestListData[0].configData.description = attributes.description;
  CBAssestListData[0].configData.utilityCO2 = attributes.utilityCO2;
  CBAssestListData[0].configData.maximum_available_power =
    attributes.maximum_available_power;
  CBAssestListData[0].assetType = componentsData.componentType;
  // new state
  return CBAssestListData;
};
const updateGenset = (CBAssetList, componentsData) => {
  const CBAssestListData = _.cloneDeep(CBAssetList);
  const attributes = JSON.parse(componentsData.attributes);
  CBAssestListData[1].uiData = JSON.parse(componentsData.uiAttributes);
  CBAssestListData[1].uiData.selected = false;
  CBAssestListData[1].uiData.CBSelected = false;
  CBAssestListData[1].uiData.pmSelected = false;
  CBAssestListData[1].configData = attributes;
  CBAssestListData[1].configData.name = componentsData.componentName;
  CBAssestListData[1].configData.description = attributes.description;
  CBAssestListData[1].assetType = componentsData.componentType;
  const communicationDataNew = { ...communicationDataSample };
  if (componentsData.communications.length) {
    communicationDataNew.ipaddress = componentsData.communications[0].ip;
    communicationDataNew.subnetmask =
      componentsData.communications[0].subnetmask;
    communicationDataNew.defaultGateway =
      componentsData.communications[0].defaultGateway;
    communicationDataNew.modbusSlaveId =
      componentsData.communications[0].modbusSlaveId;
    CBAssestListData[1].communicationData = communicationDataNew;
  }
  if (componentsData.asset !== undefined) {
    CBAssestListData[1].assetDetails = [componentsData.asset];
  }

  // new state
  return CBAssestListData;
};
const updateBess = (
  CBAssetList,
  componentsData,
  batteryCommunicationData,
  setBatteryCommunicationData
) => {
  const CBAssestListData = _.cloneDeep(CBAssetList);
  const attributes = JSON.parse(componentsData.attributes);
  CBAssestListData[2].uiData = JSON.parse(componentsData.uiAttributes);
  CBAssestListData[2].uiData.selected = false;
  CBAssestListData[2].uiData.CBSelected = false;
  CBAssestListData[2].uiData.pmSelected = false;
  CBAssestListData[2].configData = attributes;
  CBAssestListData[2].configData.name = componentsData.componentName;
  CBAssestListData[2].configData.description = attributes.description;
  CBAssestListData[2].configData.max_power = attributes.max_power;
  CBAssestListData[2].configData.capacity = attributes.capacity;
  CBAssestListData[2].configData.soc_max = attributes.soc_max;
  CBAssestListData[2].configData.soc_min = attributes.soc_min;
  CBAssestListData[2].assetType = componentsData.componentType;
  const newBatteryCom = { ...batteryCommunicationData };
  if (componentsData.communications.length) {
    newBatteryCom.ipaddress = componentsData.communications[0].ip;
    newBatteryCom.subnetmask = componentsData.communications[0].subnetmask;
    newBatteryCom.defaultGateway =
      componentsData.communications[0].defaultGateway;
    newBatteryCom.modbusSlaveId =
      componentsData.communications[0].modbusSlaveId;
    setBatteryCommunicationData(newBatteryCom);
  }
  CBAssestListData[2].communicationData = newBatteryCom;
  if (componentsData.asset !== undefined) {
    CBAssestListData[2].assetDetails.push(componentsData.asset);
  }

  // new state
  return CBAssestListData;
};

const updatePv = (CBAssetList, componentsData, pvArray) => {
  const CBAssestListData = _.cloneDeep(CBAssetList);
  CBAssestListData[pvArray].uiData = JSON.parse(componentsData.uiAttributes);
  CBAssestListData[pvArray].uiData.selected = false;
  CBAssestListData[pvArray].uiData.CBSelected = false;
  CBAssestListData[pvArray].uiData.pmSelected = false;
  CBAssestListData[pvArray].assetType = componentsData.componentType;
  // return state
  return CBAssestListData;
};

const updateChp = (CBAssetList, componentsData) => {
  const CBAssestListData = _.cloneDeep(CBAssetList);
  const attributes = JSON.parse(componentsData.attributes);
  CBAssestListData[6].uiData = JSON.parse(componentsData.uiAttributes);
  CBAssestListData[6].uiData.selected = false;
  CBAssestListData[6].uiData.CBSelected = false;
  CBAssestListData[6].uiData.pmSelected = false;
  CBAssestListData[6].configData = attributes;
  CBAssestListData[6].configData.name = componentsData.componentName;
  CBAssestListData[6].configData.description = attributes.description;
  CBAssestListData[6].configData.nominal_power = attributes.nominal_power;
  CBAssestListData[6].assetType = componentsData.componentType;

  CBAssestListData[6].communicationData = {
    ipaddress: componentsData.communications[0].ip,
    subnetmask: componentsData.communications[0].subnetmask,
    defaultGateway: componentsData.communications[0].defaultGateway,
    modbusSlaveId: componentsData.communications[0].modbusSlaveId,
  };

  if (componentsData.asset !== undefined) {
    CBAssestListData[6].assetDetails.push(componentsData.asset);
  }

  return CBAssestListData;
};

const addGenset = (CBAssetList, defaultPreFill, configData) => {
  const addGensetAdd = _.cloneDeep(CBAssetList);
  addGensetAdd[1].uiData.status = true;
  addGensetAdd[1].uiData.configured = false;
  addGensetAdd[1].uiData.CBConfigured = true;
  addGensetAdd[1].configData = { ...configData.gensetConfigData };
  addGensetAdd[1].configData.name = defaultPreFill.Generator.name;
  addGensetAdd[1].configData.gensetCO2 = defaultPreFill.Generator.gensetCO2;
  addGensetAdd[1].configData.starting_timer =
    defaultPreFill.Generator.starting_timer;
  addGensetAdd[1].configData.cooling_timer =
    defaultPreFill.Generator.cooling_timer;
  addGensetAdd[1].configData.minimum_running_period =
    defaultPreFill.Generator.minimum_running_period;
  addGensetAdd[1].cbConfigData = { ...configData.sampleCbConfigData };
  addGensetAdd[1].cbConfigData.name =
    defaultPreFill["Circuit Breaker"].name +
    "_" +
    defaultPreFill.Generator.name;
  addGensetAdd[1].assetDetails = [];
  addGensetAdd[1].communicationData = { ...communicationDataSample };
  addGensetAdd[1].communicationData.ipaddress =
    defaultPreFill.Generator.ipAddress;
  addGensetAdd[1].communicationData.subnetmask =
    defaultPreFill.Generator.subnetMask;
  addGensetAdd[1].communicationData.defaultGateway =
    defaultPreFill.Generator.defaultGateway;
  addGensetAdd[1].communicationData.modbusSlaveId =
    defaultPreFill.Generator.modbusId;

  return addGensetAdd;
};

const addBess = (CBAssetList, defaultPreFill, configData) => {
  const addBessAdd = _.cloneDeep(CBAssetList);

  addBessAdd[2].uiData.status = true;
  addBessAdd[2].uiData.configured = false;
  addBessAdd[2].uiData.CBConfigured = true;

  addBessAdd[2].configData = { ...configData.batteryConfigData };
  addBessAdd[2].configData.name = defaultPreFill.BESS.name;
  addBessAdd[2].configData.max_power = "";
  addBessAdd[2].configData.capacity = 0;
  addBessAdd[2].configData.soc_max = defaultPreFill.BESS.soc_max;
  addBessAdd[2].configData.soc_min = defaultPreFill.BESS.soc_min;

  addBessAdd[2].cbConfigData = { ...configData.sampleCbConfigData };
  addBessAdd[2].cbConfigData.name =
    defaultPreFill["Circuit Breaker"].name + "_" + defaultPreFill.BESS.name;

  addBessAdd[2].assetDetails = [];

  addBessAdd[2].communicationData = { ...communicationDataSample };
  addBessAdd[2].communicationData.ipaddress = defaultPreFill.BESS.ipAddress;
  addBessAdd[2].communicationData.subnetmask = defaultPreFill.BESS.subnetMask;
  addBessAdd[2].communicationData.defaultGateway =
    defaultPreFill.BESS.defaultGateway;
  addBessAdd[2].communicationData.modbusSlaveId = defaultPreFill.BESS.modbusId;

  return addBessAdd;
};

const addChp = (CBAssetList, defaultPreFill, configData, siteType) => {
  const addChpAdd = _.cloneDeep(CBAssetList);

  addChpAdd[6].uiData.status = true;
  addChpAdd[6].uiData.configured = false;
  addChpAdd[6].uiData.CBConfigured = true;

  addChpAdd[6].configData = { ...configData.chpConfigData };

  addChpAdd[6].configData.name = defaultPreFill.CHP.name;
  addChpAdd[6].configData.nominal_power = defaultPreFill.CHP.nominalPower;

  if (siteType === embLabels.ISLANDABLE) {
    addChpAdd[6].cbConfigData = { ...configData.sampleCbConfigData };
    addChpAdd[6].cbConfigData.name = `${defaultPreFill["Circuit Breaker"].name}_${defaultPreFill.CHP.name}`;
  }
  if (siteType === embLabels.PV_HYBRIDIZATION) {
    addChpAdd[6].powerMeterData = { ...configData.powerMeterData };
    addChpAdd[6].powerMeterData.name = `${defaultPreFill["Power Meter"].nameCHP}`;
    addChpAdd[6].powerMeterData.powerMeterModbusId = `${defaultPreFill["Power Meter"].modbusId_PM_CHP}`;
  }

  addChpAdd[6].assetDetails = [];
  addChpAdd[6].communicationData = {
    ...communicationDataSample,
  };
  addChpAdd[6].communicationData.ipaddress = defaultPreFill.CHP.ipAddress;
  addChpAdd[6].communicationData.subnetmask = defaultPreFill.CHP.subnetMask;
  addChpAdd[6].communicationData.defaultGateway =
    defaultPreFill.CHP.defaultGateway;
  addChpAdd[6].communicationData.modbusSlaveId = defaultPreFill.CHP.modbusId;

  return addChpAdd;
};

const removeAssetDer = (CBAssetList, index, configData) => {
  const removeDerData = _.cloneDeep(CBAssetList);
  removeDerData[index].uiData.status = false;
  removeDerData[index].uiData.detailsBlock = false;
  removeDerData[index].uiData.selected = false;
  removeDerData[index].uiData.configured = false;
  removeDerData[index].uiData.CBConfigured = true;
  removeDerData[index].cbConfigData = { ...configData.cbConfigData };
  removeDerData[index].powerMeterData = { ...configData.powerMeterData };
  removeDerData[index].assetDetails = [];
  removeDerData[index].communicationData = {};
  if (index === 1) {
    removeDerData[1].configData = { ...configData.gensetConfigData };
  } else if (index === 2) {
    removeDerData[2].configData = { ...configData.batteryConfigData };
  }
  return removeDerData;
};

const removeAssetDerPv = (CBAssetList, selectedPv) => {
  let newPvData = [...CBAssetList];
  newPvData[selectedPv].uiData.status = false;
  newPvData[selectedPv].uiData.detailsBlock = false;
  newPvData[selectedPv].uiData.selected = false;
  newPvData[selectedPv].uiData.configured = false;
  newPvData[selectedPv].uiData.CBConfigured = true;
  newPvData[selectedPv].uiData.groupName = "";
  newPvData[selectedPv].uiData.count = 0;
  newPvData[selectedPv].configData = {};
  newPvData[selectedPv].cbConfigData = {};

  return newPvData;
};

const updateDerCb = (CBAssetList, componentsData, value) => {
  let CBAssestListData = _.cloneDeep(CBAssetList);
  const attributes = JSON.parse(componentsData.attributes);
  CBAssestListData[value].cbConfigData.name = componentsData.componentName;
  CBAssestListData[value].cbConfigData.description = attributes.description;
  CBAssestListData[value].cbConfigData.motorized =
    parseInt(attributes.motorized) === 1 ? true : false;
  CBAssestListData[value].cbConfigData.loadCategory = parseInt(
    attributes.loadCategory
  );
  CBAssestListData[value].cbConfigData.loadPriority = parseInt(
    attributes.loadPriority
  );
  return CBAssestListData;
};

const updateDerPm = (CBAssetList, componentsData, value) => {
  let CBAssestListData = _.cloneDeep(CBAssetList);
  const attributes = JSON.parse(componentsData.attributes);
  CBAssestListData[value].powerMeterData.name = componentsData.componentName;
  CBAssestListData[value].powerMeterData.powerMeterModbusId =
    componentsData.communications[0].modbusSlaveId;
  CBAssestListData[value].powerMeterData.ipAddress =
    componentsData.communications[0].ip;
  CBAssestListData[value].powerMeterData.gatewayAddress =
    componentsData.communications[0].defaultGateway;
  CBAssestListData[value].powerMeterData.mask =
    componentsData.communications[0].subnetmask;
  CBAssestListData[value].powerMeterData.description = attributes.description;
  return CBAssestListData;
};

const updatePvConfigured = (CBAssetList, PVDetails) => {
  const newPvData = _.clone(CBAssetList);
  const pvStatus1 = PVDetails.pvset1Data.every(data => data.status);
  newPvData[3].uiData.configured = pvStatus1;

  const pvStatus2 = PVDetails.pvset2Data.every(data => data.status);
  newPvData[4].uiData.configured = pvStatus2;

  const pvStatus3 = PVDetails.pvset3Data.every(data => data.status);
  newPvData[5].uiData.configured = pvStatus3;

  return newPvData;
};

const closeDetailsAsset = CBAssetList => {
  const newCBAssetList = _.cloneDeep(CBAssetList);
  newCBAssetList.forEach(assetCb => {
    assetCb.uiData.CBSelected = false;
    assetCb.uiData.selected = false;
    assetCb.uiData.detailsBlock = false;
    assetCb.uiData.pmSelected = false;
  });

  return newCBAssetList;
};

const assetSelect = (CBAssetList, index) => {
  const newCBAssetList = _.cloneDeep(CBAssetList);
  newCBAssetList[index].uiData.selected = true;
  return newCBAssetList;
};

const updateAssetConfig = (CBAssetList, selectedAsset, configData) => {
  const newCBAssetList = _.cloneDeep(CBAssetList);
  newCBAssetList[selectedAsset].uiData.configured = true;
  newCBAssetList[selectedAsset].configData = configData;
  if (
    selectedAsset === 1 &&
    newCBAssetList[selectedAsset].assetDetails[0].assetInfo === "Hardwired"
  ) {
    newCBAssetList[selectedAsset].communicationData = {};
  }
  return newCBAssetList;
};
const duplicateDerUpdate = (CBAssetList, selectedAsset) => {
  const newCBAssetList = _.cloneDeep(CBAssetList);
  newCBAssetList[selectedAsset].uiData.configured = false;

  return newCBAssetList;
};

const assetCbSelect = (CBAssetList, index) => {
  const newCBAssetList = _.cloneDeep(CBAssetList);
  newCBAssetList[index].uiData.CBSelected = true;
  return newCBAssetList;
};

const assetPmSelect = (CBAssetList, index) => {
  const newCBAssetList = _.cloneDeep(CBAssetList);
  newCBAssetList[index].uiData.pmSelected = true;
  return newCBAssetList;
};

const updateCbConfig = (CBAssetList, selectedAssetCB, cbConfigData) => {
  const newUtilitytData = _.cloneDeep(CBAssetList);
  newUtilitytData[selectedAssetCB].uiData.CBConfigured = true;
  newUtilitytData[selectedAssetCB].cbConfigData = cbConfigData;
  return newUtilitytData;
};
const updatePowerMeterConfig = (CBAssetList, selectedAssetPM, cbConfigData) => {
  const newPowerMeterData = _.cloneDeep(CBAssetList);
  newPowerMeterData[selectedAssetPM].uiData.pmConfigured = true;
  newPowerMeterData[selectedAssetPM].powerMeterData.name = cbConfigData.name;
  newPowerMeterData[selectedAssetPM].powerMeterData.description =
    cbConfigData.description;

  return newPowerMeterData;
};

const selectDerAsset = (CBAssetList, index, selectedAsset) => {
  const newCBassetList = _.cloneDeep(CBAssetList);
  newCBassetList[index].assetDetails[0] = selectedAsset;
  newCBassetList[index].uiData.configured = false;
  return newCBassetList;
};

const selectSerialAsset = (CBAssetList, index, modbusSlaveId) => {
  const newSerialData = _.cloneDeep(CBAssetList);
  newSerialData[index].communicationData.ipaddress = "";
  newSerialData[index].communicationData.subnetmask = "";
  newSerialData[index].communicationData.defaultGateway = "";
  newSerialData[index].communicationData.modbusSlaveId = modbusSlaveId;
  return newSerialData;
};

const selectCommunicatingAsset = (CBAssetList, index, preFillDefault) => {
  const newCommunicatingData = _.cloneDeep(CBAssetList);

  newCommunicatingData[index].communicationData.ipaddress =
    preFillDefault.ipAddress;
  newCommunicatingData[index].communicationData.subnetmask =
    preFillDefault.subnetMask;
  newCommunicatingData[index].communicationData.defaultGateway =
    preFillDefault.defaultGateway;
  newCommunicatingData[index].communicationData.modbusSlaveId =
    preFillDefault.modbusId;

  return newCommunicatingData;
};

const individualPvAssetSelect = (CBAssetList, selectedPv) => {
  let pvData = _.cloneDeep(CBAssetList);
  pvData[selectedPv].uiData.configured = false;
  return pvData;
};

const reducePvCountFromCb = (CBAssetList, selectedPv, pvConStatusOne) => {
  let newPvData = [...CBAssetList];
  newPvData[selectedPv].uiData.count = newPvData[selectedPv].uiData.count - 1;

  newPvData[selectedPv].uiData.configured = pvConStatusOne;

  return newPvData;
};

const addPvToCbAssetList = (
  CBAssetList,
  selectedPv,
  PVDetails,
  configData,
  preFillDefault
) => {
  let newPvData = [...CBAssetList];

  let selectedCb = selectedPv === 3 ? 1 : selectedPv === 4 ? 2 : 3;

  newPvData[selectedPv].uiData.status = true;
  newPvData[selectedPv].uiData.configured = false;

  newPvData[selectedPv].uiData.count =
    newPvData[selectedPv].uiData.count + PVDetails.pvCount;

  newPvData[selectedPv].configData = {};

  if (PVDetails.existOrNew === "new") {
    newPvData[selectedPv].uiData.CBConfigured = true;
    newPvData[selectedPv].cbConfigData = Object.assign(
      {},
      configData.sampleCbConfigData
    );

    newPvData[
      selectedPv
    ].cbConfigData.name = `${preFillDefault["Circuit Breaker"].name}_${preFillDefault.PV_inverter.groupName} ${selectedCb} `;
  }

  return newPvData;
};

export { initialCBAssetList, CBAssetListReducer };
