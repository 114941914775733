import React from "react";
import SLDGenerate from "../../pages/designAssetSLD";
//import SLDGenerate from "../../pages/DesignAssetReport";

const GenerateReport = ({
  status,
  reportStatus,
  closeOpenReportPopUp,
  handleCheckBoxChange,
  handleCheckBoxChangeAll,
  uploadReport,
  uploadSLDStatus,
}) => {
  return (
    <se-dialog id="AlertDialog" open={true} can-backdrop="false">
      <se-dialog-header>Generate Report</se-dialog-header>
      <se-dialog-content>
        <div className="col-md-12 generateReportDiv">
          <div className="row">
            <div className="col-md-12">
              {reportStatus.showSeletOption === true && (
                <div className="form-group row mb-0 text-center">
                  <div className="col-md-12">
                    <label className="col-sm-12 col-form-label infoText">
                      <se-icon size="nano" color="secondary">
                        information_circle
                      </se-icon>
                      <span>
                        {" "}
                        At least one option should be selected to download
                        report{" "}
                      </span>
                    </label>
                  </div>
                </div>
              )}

              <div className="form-group row">
                <div className="col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input asCompatible"
                      id="Checkbox1"
                      name="selectAll"
                      checked={reportStatus.selectAll}
                      onChange={handleCheckBoxChangeAll}
                    />
                    <label
                      className="custom-control-label line-height-25"
                      htmlFor="Checkbox1"
                    >
                      Select all options
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input asCompatible"
                      id="Checkbox2"
                      name="information"
                      checked={reportStatus.information}
                      onChange={handleCheckBoxChange}
                    />
                    <label
                      className="custom-control-label line-height-25"
                      htmlFor="Checkbox2"
                    >
                      Information
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input asCompatible"
                      id="Checkbox3"
                      name="sld"
                      checked={reportStatus.sld}
                      onChange={handleCheckBoxChange}
                    />
                    <label
                      className="custom-control-label line-height-25"
                      htmlFor="Checkbox3"
                    >
                      Configure DER's
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input asCompatible"
                      id="Checkbox4"
                      name="program"
                      checked={reportStatus.program}
                      onChange={handleCheckBoxChange}
                    />
                    <label
                      className="custom-control-label line-height-25"
                      htmlFor="Checkbox4"
                    >
                      Configure usecases
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input asCompatible"
                      id="Checkbox5"
                      name="bom"
                      checked={reportStatus.bom}
                      onChange={handleCheckBoxChange}
                    />
                    <label
                      className="custom-control-label line-height-25"
                      htmlFor="Checkbox5"
                    >
                      BOM
                    </label>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <div className="col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input asCompatible"
                      id="Checkbox6"
                      name="system"
                      checked={reportStatus.system}
                      onChange={handleCheckBoxChange}
                    />
                    <label
                      className="custom-control-label line-height-25"
                      htmlFor="Checkbox6"
                    >
                      System settings
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input asCompatible"
                      id="Checkbox7"
                      name="deploy"
                      checked={reportStatus.deploy}
                      onChange={handleCheckBoxChange}
                    />
                    <label
                      className="custom-control-label line-height-25"
                      htmlFor="Checkbox7"
                    >
                      Deploy
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {status.reportReadyStatus === true && (
            <SLDGenerate sldStatusCheck={uploadSLDStatus} />
          )}
        </div>
      </se-dialog-content>
      <se-dialog-footer>
        <se-button onClick={closeOpenReportPopUp}>Cancel</se-button>

        <se-button onClick={uploadReport}>Download Report</se-button>
      </se-dialog-footer>
    </se-dialog>
  );
};

export default GenerateReport;
