import React, { Component } from "react";

class SLDBattery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOverData: false,
    };
  }
  onCBMouseOver() {
    this.setState({ mouseOverData: !this.state.mouseOverData });
  }

  render() {
    const {
      battery,
      onBatterySelect,
      onBatteryCBSelect,
      batteryName,
      batteryCbName,
    } = this.props;
    return [
      <svg
        className="pointer"
        style={battery.status ? {} : { display: "none" }}
      >
        <g id="battery">
          <svg
            x={battery.xAxis}
            y={battery.yAxis}
            width="195px"
            height="130px"
            viewBox="0 0 10 149"
            onClick={onBatterySelect}
          >
            <title>{batteryName}</title>
            <g id="Design" fill="none" fill-rule="evenodd">
              <g
                id="Design/Assets-_-00"
                transform="translate(-892.000000, -372.000000)"
              >
                <g
                  id="SLD/Production/Uniline/Tobedefine"
                  transform="translate(892.000000, 372.000000)"
                >
                  <g id="Group-7">
                    <rect
                      id="Rectangle"
                      x="0"
                      y="0"
                      width="96"
                      height="144"
                    ></rect>
                    <line
                      x1="48.5"
                      y1="96"
                      x2="48.5"
                      y2="144"
                      id="Line-2"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke={battery.configured ? "#333333" : "#E47F00"}
                      stroke-linejoin="round"
                    ></line>
                    <circle
                      id="Oval"
                      stroke="#42B4E6"
                      stroke-width="3"
                      cx="48"
                      cy="56"
                      r="55"
                      style={battery.selected ? {} : { display: "none" }}
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      fill="rgba(0, 135, 205, 0.2)"
                    ></circle>
                    <circle
                      id="Oval"
                      stroke-width="3"
                      fill="#FFFFFF"
                      cx="48"
                      cy="55"
                      r="46.5"
                      stroke={battery.configured ? "#333333" : "#E47F00"}
                    ></circle>
                    <text
                      id="segment_energy_infra"
                      font-family="se-icon"
                      stroke="#333333"
                      font-size="52"
                      font-weight="normal"
                      fill="#0F0F0F"
                    >
                      <tspan x="22" y="76">
                        battery2_general
                      </tspan>
                    </text>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </g>
        <g id="CB3">
          <svg
            width="75px"
            height="100px"
            viewBox="0 0 10 149"
            x={battery.xAxisCB}
            y={battery.yAxisCB}
            onClick={onBatteryCBSelect}
          >
            <title>{batteryCbName}</title>
            <g
              id="Circuit Breaker"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Circuit Breaker 1"
                transform="translate(-651.000000, -512.000000)"
              >
                <g
                  id="Circuit Breaker - closed"
                  transform="translate(604.000000, 514.000000)"
                  onMouseOver={this.onCBMouseOver.bind(this)}
                  onMouseOut={this.onCBMouseOver.bind(this)}
                >
                  <g
                    id="Group-15"
                    stroke-width="4"
                    fill-rule="evenodd"
                    fill={
                      battery.CBSelected
                        ? "rgba(65, 181, 230, 0.35)"
                        : this.state.mouseOverData
                        ? "rgba(65, 181, 230, 0.35)"
                        : "none"
                    }
                    stroke={battery.CBSelected ? "#42B4E6" : "none"}
                  >
                    <rect
                      id="Rectangle-Copy-20"
                      x="0"
                      y="12"
                      width="96"
                      height="122"
                    ></rect>
                    <g
                      id="Group-2"
                      transform="translate(48.000000, 0.000000)"
                      stroke={battery.CBConfigured ? "#333333" : "#E47F00"}
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="4"
                    >
                      <line
                        x1="0.5"
                        y1="0"
                        x2="0.5"
                        y2="47.8298611"
                        id="Line-2"
                      ></line>
                    </g>
                  </g>
                  <line
                    x1="48.5"
                    y1="97.1701389"
                    x2="48.501111"
                    y2="145"
                    id="Line-2-Copy-15"
                    stroke={battery.CBConfigured ? "#333333" : "#E47F00"}
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></line>
                  <path
                    d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                    id="Path-11"
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke={battery.CBConfigured ? "#333333" : "#E47F00"}
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </g>
      </svg>,
    ];
  }
}

export default SLDBattery;
