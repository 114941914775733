import React, { useContext } from "react";

import isTouchDevice from "../SE-Component/Tooltip";
import embLabels from "../../config/emb-labels-en.json";
import { InformationPageContext } from "../CustomHooks/ContextApi";

const ArchitectureType = () => {
  const {
    architectureType,
    informationToolTIP,
    siteType,
    handleArchitectureTypeChangeGSX,
  } = useContext(InformationPageContext);
  return (
    <>
      {siteType && (
        <div className="row">
          <div className="col-md-12 mt-1">
            <span className="labelCircle mr-2 ">2</span>
            <span>Select your architecture</span>
            <se-tooltip
              action={isTouchDevice() ? "click" : "hover"}
              position="right"
            >
              <se-icon
                option="raised"
                color="secondary"
                size="small"
                slot="tooltip"
              >
                information_circle
              </se-icon>
              <se-tooltip-content>Select your architecture</se-tooltip-content>
            </se-tooltip>
          </div>
        </div>
      )}

      {siteType && (
        <div className="row mt-2">
          <div className="col-md-4">
            <div
              className={`col-md-12 ${
                architectureType === embLabels.PV_GENSET_ATS
                  ? "border-green-radius-5px"
                  : "border-radius-5px"
              }`}
            >
              <div className="row align-items-center">
                <div className="col-md-2">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="customRadio1"
                        name="architectureType"
                        className="custom-control-input islandableRadio asCompatible"
                        value="PV_GENSET_ATS"
                        checked={
                          architectureType === embLabels.PV_GENSET_ATS
                            ? true
                            : false
                        }
                        onChange={handleArchitectureTypeChangeGSX}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadio1"
                      >
                        &zwnj;
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-10 p-2">
                  <div className="row">
                    <div className="col-md-12 mt-1">
                      <span className="bold">PV + backup Genset with ATS</span>
                    </div>
                    <div className="col-md-12 mt-1">
                      <span>"{informationToolTIP.pvGensetAtsToolTip}"</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ArchitectureType;
