import ACTIONS from "../Design_Asset_Actions.json";

const communicationDataSample = {
  ipaddress: "",
  subnetmask: "",
  defaultGateway: "",
  modbusSlaveId: "",
};
const initialCBEVList = [
  {
    assetConnected: "EV",
    modbusSlaveId: "31",
    configData: {
      name: "",
      description: "",
      maximum_available_power: 0,
    },
    CBId: "CB_60",
    uiData: {
      status: false,
      CBSelected: false,
      CBConfigured: true,
      xAxisCB: 645,
      yAxisCB: 290,
      xAxis: 600,
      yAxis: 292,
    },
    cbConfigData: {
      name: "",
      description: "",
      motorized: true,
      loadCategory: 0,
      loadPriority: 0,
    },
    communicationData: {},
    assetDetails: [],
  },
];

const CBEVListReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.ASSIGN_DEFAULT_VALUES:
      return assignDefaultValuesCBEVList(state, action.payload.defaultValues);
    case ACTIONS.ADD_EV:
      return addEv(
        state,
        action.payload.defaultPreFill,
        action.payload.configData,
        action.payload.count
      );
    case ACTIONS.UPDATE_CBEVLIST_COORDINATES:
      return updateCbEvListCoordinates(
        state,
        action.payload.coordinates,
        action.payload.count
      );
    case ACTIONS.UPDATE_CBEVLIST_COORDINATES_GRIDCONNECTED:
      return updateCbEvListCoordinatesGridConnected(
        state,
        action.payload.index,
        action.payload.positions,
        action.payload.petal
      );
    case ACTIONS.UPDATE_EV:
      return updateEv(
        state,
        action.payload.componentsData,
        action.payload.evCommunicationData,
        action.payload.setEvCommunicationData
      );
    case ACTIONS.UPDATE_EV_CB:
      return updateEvCb(state, action.payload.componentsData);
    case ACTIONS.CLOSEDETAILS_EV:
      return closeDetailsEv(state);
    case ACTIONS.EV_SELECT:
      return evSelect(state, action.payload.index);
    case ACTIONS.EV_CB_SELECT:
      return evCbSelect(state, action.payload.index);
    case ACTIONS.UPDATE_EV_CONFIG:
      return updateEvConfig(
        state,
        action.payload.selectedEv,
        action.payload.configData
      );
    case ACTIONS.DUPLICATE_EV_UPDATE:
      return duplicateEvUpdate(state, action.payload.selectedEv);
    case ACTIONS.UPDATE_EV_CB_CONFIG:
      return updateEvCbConfig(
        state,
        action.payload.selectedEvCB,
        action.payload.cbConfigData
      );
    case ACTIONS.SELECT_EV_ASSET:
      return selectEvAsset(
        state,
        action.payload.index,
        action.payload.selectedAsset
      );
    case ACTIONS.SELECT_SERIAL_ASSET:
      return selectSerialAsset(
        state,
        action.payload.index,
        action.payload.modbusSlaveId
      );
    case ACTIONS.SELECT_COMMUNICATING_ASSET:
      return selectCommunicatingAsset(
        state,
        action.payload.index,
        action.payload.preFillDefault
      );
    case ACTIONS.REMOVE_ASSET_EV:
      return removeAssetEv(
        state,
        action.payload.index,
        action.payload.configData
      );
    default:
      return state;
  }
};

const assignDefaultValuesCBEVList = (CBEVList, defaultValues) => {
  const evList = [...CBEVList];
  let circuitBreakersModbusSlaveIdList = defaultValues["Circuit Breaker"];

  evList[0].configData.name = defaultValues.EV_charging_station.name;
  evList[0].cbConfigData.name = `${defaultValues["Circuit Breaker"].name}_${defaultValues.EV_charging_station.name}`;
  evList[0].communicationData = { ...communicationDataSample };
  evList[0].communicationData.ipaddress =
    defaultValues.EV_charging_station.ipAddress;
  evList[0].communicationData.subnetmask =
    defaultValues.EV_charging_station.subnetMask;
  evList[0].communicationData.defaultGateway =
    defaultValues.EV_charging_station.defaultGateway;
  evList[0].communicationData.modbusSlaveId =
    defaultValues.EV_charging_station.modbusId;
  evList[0].modbusSlaveId = circuitBreakersModbusSlaveIdList["modbusId_CB_60"];
  evList[0].uiData.status = false;

  // return new state
  return evList;
};

const addEv = (CBEVList, defaultPreFill, configData, count) => {
  let newEvData = [...CBEVList];
  const totalLoads = count.loadCount + count.derCount + count.evCount;

  newEvData[0].modbusSlaveId =
    totalLoads % 7 === 0
      ? "255"
      : defaultPreFill["Circuit Breaker"].modbusId_CB_60;
  newEvData[0].uiData.status = true;
  newEvData[0].uiData.configured = false;
  newEvData[0].uiData.CBConfigured = true;
  newEvData[0].cbConfigData = { ...configData.sampleCbConfigData };
  newEvData[0].cbConfigData.name = `${defaultPreFill["Circuit Breaker"].name}_${defaultPreFill.EV_charging_station.name}`;
  newEvData[0].configData = { ...configData.evConfigData };
  newEvData[0].configData.name = defaultPreFill.EV_charging_station.name;
  newEvData[0].communicationData = { ...communicationDataSample };
  newEvData[0].communicationData.ipaddress =
    defaultPreFill.EV_charging_station.ipAddress;
  newEvData[0].communicationData.subnetmask =
    defaultPreFill.EV_charging_station.subnetMask;
  newEvData[0].communicationData.defaultGateway =
    defaultPreFill.EV_charging_station.defaultGateway;
  newEvData[0].communicationData.modbusSlaveId =
    defaultPreFill.EV_charging_station.modbusId;

  return newEvData;
};

const updateCbEvListCoordinates = (CBEVList, coordinates, count) => {
  const evCoordinates = [...CBEVList];
  let xLoad = coordinates.xLoad;
  let xLoadCB = coordinates.xLoadCB;
  let xLoadNew = coordinates.xLoadNew;
  let xLoadCBNew = coordinates.xLoadCBNew;

  let yLoad = 290;
  const totalLoads = count.loadCount + count.evCount;
  if (evCoordinates[0].uiData.status) {
    xLoad =
      count.loadCount > 11
        ? coordinates.loadDiff + (count.loadCount - 10.5) * 110 - 100
        : coordinates.xLoad + count.loadCount * 110;
    xLoadCB =
      count.loadCount > 11
        ? coordinates.loadDiff + (count.loadCount - 11) * 110
        : coordinates.xAxisLoadCBDiff + count.loadCount * 110;
    if (totalLoads === 12) {
      xLoad = xLoadNew;
      xLoadCB = xLoadCBNew;
    }
    yLoad = totalLoads > 11 ? 377 : 290;
    evCoordinates[0].uiData.xAxis = xLoad;
    evCoordinates[0].uiData.yAxis = yLoad;
    evCoordinates[0].uiData.xAxisCB = xLoadCB;
  }

  return evCoordinates;
};

const updateCbEvListCoordinatesGridConnected = (
  CBEVList,
  index,
  positions,
  petal
) => {
  const cbEvCor = [...CBEVList];
  const [x1pos, y1pos, x2pos, y2pos] = positions;
  const [xAxis, yAxis] = petal;

  cbEvCor[index].uiData.xAxis = xAxis;
  cbEvCor[index].uiData.yAxis = yAxis;
  cbEvCor[index].uiData.x1pos = x1pos;
  cbEvCor[index].uiData.y1pos = y1pos;
  cbEvCor[index].uiData.x2pos = x2pos;
  cbEvCor[index].uiData.y2pos = y2pos;
  return cbEvCor;
};

const updateEv = (
  CBEVList,
  componentsData,
  evCommunicationData,
  setEvCommunicationData
) => {
  const newEvData = [...CBEVList];
  const attributes = JSON.parse(componentsData.attributes);

  newEvData[0].uiData = JSON.parse(componentsData.uiAttributes);
  newEvData[0].uiData.selected = false;
  newEvData[0].uiData.CBSelected = false;
  newEvData[0].configData.name = componentsData.componentName;
  newEvData[0].configData.description = attributes.description;
  newEvData[0].configData.maximum_available_power =
    attributes.maximum_available_power;
  newEvData[0].assetType = componentsData.componentType;
  const newEvCom = { ...evCommunicationData };
  if (componentsData.communications[0] !== undefined) {
    newEvCom.ipaddress = componentsData.communications[0].ip;
    newEvCom.subnetmask = componentsData.communications[0].subnetmask;
    newEvCom.defaultGateway = componentsData.communications[0].defaultGateway;
    newEvCom.modbusSlaveId = componentsData.communications[0].modbusSlaveId;
    setEvCommunicationData(newEvCom);
  }
  newEvData[0].communicationData = newEvCom;
  if (componentsData.asset !== undefined) {
    newEvData[0].assetDetails.push(componentsData.asset);
  }
  return newEvData;
};

const updateEvCb = (CBEVList, componentsData) => {
  const newEvData = [...CBEVList];
  const attributes = JSON.parse(componentsData.attributes);
  newEvData[0].cbConfigData.name = componentsData.componentName;
  newEvData[0].cbConfigData.description = attributes.description;
  newEvData[0].cbConfigData.motorized =
    parseInt(attributes.motorized) === 1 ? true : false;
  newEvData[0].cbConfigData.loadCategory = parseInt(attributes.loadCategory);
  newEvData[0].cbConfigData.loadPriority = parseInt(attributes.loadPriority);
  newEvData[0].modbusSlaveId = componentsData.communications[0].modbusSlaveId;

  return newEvData;
};

const closeDetailsEv = CBEVList => {
  const newCBEVList = Object.assign([], CBEVList);
  newCBEVList.forEach(ev => {
    ev.uiData.CBSelected = false;
    ev.uiData.selected = false;
    ev.uiData.detailsBlock = false;
  });
  return newCBEVList;
};

const evSelect = (CBEVList, index) => {
  const newCBEVList = [...CBEVList];
  newCBEVList[index].uiData.selected = true;
  return newCBEVList;
};

const evCbSelect = (CBEVList, index) => {
  const newEvData = [...CBEVList];
  newEvData[index].uiData.CBSelected = true;
  return newEvData;
};

const updateEvConfig = (CBEVList, selectedEv, configData) => {
  const newCBEVList = [...CBEVList];

  newCBEVList[selectedEv].uiData.configured = true;
  newCBEVList[selectedEv].configData = configData;

  // newCBEVList[this.state.selectedEv].communicationData =
  //   this.state.evCommunicationData;
  return newCBEVList;
};
const duplicateEvUpdate = (CBEVList, selectedEv) => {
  const newCBEVList = [...CBEVList];
  newCBEVList[selectedEv].uiData.configured = false;

  return newCBEVList;
};

const updateEvCbConfig = (CBEVList, selectedEvCB, cbData) => {
  const newCBEVList = [...CBEVList];
  newCBEVList[selectedEvCB].cbConfigData = cbData;
  newCBEVList[selectedEvCB].uiData.CBConfigured = true;

  return newCBEVList;
};

const selectEvAsset = (CBEVList, index, selectedAsset) => {
  const evData = [...CBEVList];
  evData[index].assetDetails[0] = selectedAsset;
  evData[index].uiData.configured = false;
  return evData;
};

const selectSerialAsset = (CBEVList, index, modbusSlaveId) => {
  const evData = [...CBEVList];
  evData[index].communicationData.ipaddress = "";
  evData[index].communicationData.subnetmask = "";
  evData[index].communicationData.defaultGateway = "";
  evData[index].communicationData.modbusSlaveId = modbusSlaveId;

  return evData;
};

const selectCommunicatingAsset = (CBEVList, index, preFillDefault) => {
  const evData = [...CBEVList];

  evData[index].communicationData.ipaddress = preFillDefault.ipAddress;
  evData[index].communicationData.subnetmask = preFillDefault.subnetMask;
  evData[index].communicationData.defaultGateway =
    preFillDefault.defaultGateway;
  evData[index].communicationData.modbusSlaveId = preFillDefault.modbusId;

  return evData;
};

const removeAssetEv = (CBEVList, index, configData) => {
  let newEvData = [...CBEVList];
  newEvData[index].uiData.status = false;
  newEvData[index].uiData.detailsBlock = false;
  newEvData[index].uiData.selected = false;
  newEvData[index].uiData.configured = false;
  newEvData[index].uiData.CBConfigured = true;
  newEvData[index].cbConfigData = { ...configData.sampleCbConfigData };
  newEvData[index].configData = { ...configData.evConfigData };
  newEvData[index].assetDetails = [];
  newEvData[index].communicationData = {};

  return CBEVList;
};

export { initialCBEVList, CBEVListReducer };
