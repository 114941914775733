import React, { Component } from "react";

class EditRole extends Component {

    constructor(props) {
        super(props);
        this.state ={
            emailAddress : "",
            role : "0",
            rolePermission : "",
            rolesListInside : []

        };
    }
    componentWillReceiveProps(){
        this.setState({role: this.props.selectedRole });
        let  rolesListInsideNew = Object.assign([], this.state.rolesListInside)
        rolesListInsideNew = [];
        this.props.rolesList.map((role)=>{
            if(role.tag === this.props.selectedRole){
                this.setState({rolePermission : this.props.rolesPermission[role.role] });
            }
            role.permission = this.props.rolesPermission[role.role];
            rolesListInsideNew.push(role);
            return null;

        });
        this.setState({rolesListInside : rolesListInsideNew});
        
    }
    editUser(){
            if(this.state.role !== "0")
            {
                this.props.onEditUserPopUp(this.props.selectedUserId, this.state.role);
                this.setState({emailAddress: ""});
                this.setState({role: ""});
            } 
    }
   

    handleRoleChange(e){
        this.setState({role : e.target.value});
        this.props.rolesList.map((role)=>{
            if(role.tag === e.target.value){
                this.setState({rolePermission : this.props.rolesPermission[role.role] });
            }
            return null;

        });
        
    }

  render() {
    const { editUserRolePopUpStatus, editUserRolesPopUpLoading, rolesList, selectedEmail, selectedRole, closeEditUserRolePopUp} = this.props;
    
    return [
        <se-dialog id="dialog-complex" size ="medium" open ={editUserRolePopUpStatus} can-backdrop="false">
            <se-dialog-header>Edit User
              
            </se-dialog-header>
                    
            <se-dialog-content className = "se-dialog-content">
            <se-loading loading={editUserRolesPopUpLoading}></se-loading>
            <se-loading loading={(rolesList.length === 0)? true : false}></se-loading>
                
                <div className="row">
                <div className="col-md-12">
                        <div className="form-group row mb-0">
                            <label htmlFor="emailaddress" className="col-sm-9 col-form-label ml-3">Invited people</label>
                            
                            </div>
                            <div className="form-group">
                            <div className="col-sm-12">
                            <label htmlFor="emailaddress" className="col-sm-5 col-form-label pl-0">{selectedEmail}</label>
                            </div>
                        </div>
                    </div>
                {/* <div className="col-md-5 col-sm-5 col-lg-5 ">
                        <div className="form-group row">
                            <label htmlFor="emailaddress" className="col-sm-5 col-form-label">Invited people<span className="se-mandatory">*</span></label>
                            <div className="col-sm-7">
                            <label htmlFor="emailaddress" className="col-sm-5 col-form-label">{selectedEmail}</label>
                            
                        </div>
                        </div>
                    </div> */}
                    <div className="col-md-12 col-sm-12 col-lg-12 ">
                        <div className="form-group">
                                {/* <label htmlFor="roleSelection" className="col-sm-4 col-form-label">Role<span className="se-mandatory">*</span></label> */}
                                <div className="col-sm-12">
                                <select className="form-control form-control-sm" name="role" value={this.state.role} id="roleSelection" 
                                onChange={e => this.handleRoleChange(e)}>
                                    <option value={"0"}>select role</option>
                                    { this.state.rolesListInside.map((data)=>(
                                                    <option value={data.tag} selected={selectedRole === data.tag?"selected":"" }>Invited as {data.role} - "{data.permission}"</option>
                                                ))
                                    }
                                    
                                </select>
                                
                                </div>
                            </div>
                    </div>
                    
                </div>
                
                

                
                
            </se-dialog-content>
            <se-dialog-footer>
                <se-button option="outline"  onClick={closeEditUserRolePopUp} >
                Cancel</se-button>
                <se-button option="primary"  disabled={(selectedEmail !== ""&& this.state.role !=="0" && this.state.role !== selectedRole )?false: true}
                     onClick={this.editUser.bind(this)}>Update</se-button>
                
            </se-dialog-footer>
            </se-dialog>
    ]
}

}

export default EditRole;