import React, { Component } from "react";

class SLDEv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseOverData: false,
    };
  }
  onCBMouseOver() {
    this.setState({ mouseOverData: !this.state.mouseOverData });
  }

  render() {
    const { ev, onEvSelect, onEvCBSelect, evName, evCbName } = this.props;
    return [
      <svg className="pointer" style={ev.status ? {} : { display: "none" }}>
        <g id="ev">
          <svg
            x={ev.xAxis}
            y={ev.yAxis}
            width="149px"
            height="475px"
            viewBox="0 0 90 550"
            onClick={onEvSelect}
          >
            <title>{evName}</title>
            <g
              id="Design"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Design/Assets-_-00"
                transform="translate(-604.000000, -372.000000)"
              >
                <g
                  id="SLD/Production/Uniline/Undefined"
                  transform="translate(604.000000, 372.000000)"
                >
                  <g id="SLD/Production/Uniline/Tobedefine">
                    <g id="Group-7">
                      <rect
                        id="Rectangle"
                        x="0"
                        y="95"
                        width="96"
                        height="148"
                      ></rect>
                      <line
                        x1="48.5"
                        // y1="96"
                        y1={ev.yAxis === 377 ? "0" : "96"}
                        x2="48.5"
                        y2="144"
                        id="Line-2"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke={ev.configured ? "#333333" : "#E47F00"}
                      ></line>

                      <circle
                        id="Oval"
                        stroke="#42B4E6"
                        stroke-width="3"
                        cx="48"
                        cy="193.5"
                        r="55"
                        style={ev.selected ? {} : { display: "none" }}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        fill="rgba(0, 135, 205, 0.2)"
                      ></circle>

                      <circle
                        id="Oval"
                        stroke-width="3"
                        fill="#FFFFFF"
                        cx="48"
                        cy="193.5"
                        r="46.5"
                        stroke={ev.configured ? "#333333" : "#E47F00"}
                      ></circle>

                      <text
                        id="segment_energy_infra"
                        font-family="se-icon"
                        font-size="56"
                        font-weight="normal"
                        fill="#333333"
                      >
                        <tspan x="20" y="210">
                          electric_vehicle_terminal
                        </tspan>
                      </text>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </g>
        <g id="ev">
          <svg
            width="70px"
            height="84px"
            viewBox="0 0 10 149"
            x={ev.xAxisCB}
            y={ev.yAxisCB}
            onClick={onEvCBSelect}
          >
            <title>{evCbName}</title>
            <g
              id="Circuit Breaker"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              <g
                id="Circuit Breaker 1"
                transform="translate(-651.000000, -512.000000)"
              >
                <g
                  id="Circuit Breaker - closed"
                  transform="translate(604.000000, 514.000000)"
                  onMouseOver={this.onCBMouseOver.bind(this)}
                  onMouseOut={this.onCBMouseOver.bind(this)}
                >
                  <g
                    id="Group-15"
                    stroke-width="4"
                    fill={
                      ev.CBSelected
                        ? "rgba(65, 181, 230, 0.35)"
                        : this.state.mouseOverData
                        ? "rgba(65, 181, 230, 0.35)"
                        : "none"
                    }
                    stroke={ev.CBSelected ? "#42B4E6" : "none"}
                  >
                    <rect
                      id="Rectangle-Copy-20"
                      x="0"
                      y="8"
                      width="96"
                      height="132"
                    ></rect>
                    <g
                      id="Group-2"
                      transform="translate(48.000000, 0.000000)"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke={ev.CBConfigured ? "#333333" : "#E47F00"}
                      stroke-width="4"
                    >
                      <line
                        x1="0.5"
                        y1="0"
                        x2="0.5"
                        y2="47.8298611"
                        id="Line-2"
                      ></line>
                    </g>
                  </g>
                  <line
                    x1="48.5"
                    y1="97.1701389"
                    x2="48.501111"
                    y2="145"
                    id="Line-2-Copy-15"
                    stroke={ev.CBConfigured ? "#333333" : "#E47F00"}
                    stroke-width="4"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></line>
                  <path
                    d="M49,48.6645651 C56.9440104,64.554855 56.9440104,80.5555556 49,96.6666667"
                    id="Path-11"
                    stroke={ev.CBConfigured ? "#333333" : "#E47F00"}
                    stroke-width="4"
                    stroke-linecap="round"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </g>
      </svg>,
    ];
  }
}

export default SLDEv;
