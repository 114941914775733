import React from "react";

const BessManagement = () => {
  return (
    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 se-white-background mt-1">
      <div className="dropdown-divider"></div>

      <div className="row">
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
          <div className="form-group row">
            <label htmlFor="inputEmail11" className="col-sm-5 col-form-label">
              Maximum SoC of the battery(%)
              <span className="se-mandatory">*</span>
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-md"
                name="soc_max"
                placeholder="Maximum SoC of the battery(%)"
                maxLength="3"
                // value={this.state.BESS.soc_max}
                //onChange={e => this.handleChangeBESS(e)}
                disabled
              />
              <div
                className="invalid-feedback-custom"
                // style={
                //   this.state.BESS.soc_max.toString() !== ""
                //     ? { display: "none" }
                //     : {}
                // }
              >
                Maximum SoC of the battery(%) is required.
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
          <div className="form-group row">
            <label htmlFor="inputEmail11" className="col-sm-5 col-form-label">
              Minimum SoC of the battery(%)
              <span className="se-mandatory">*</span>
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-md"
                name="soc_min"
                placeholder="Minimum SoC of the battery(%)"
                maxLength="3"
                //value={this.state.BESS.soc_min}
                //onChange={e => this.handleChangeBESS(e)}
                disabled
              />
              <div
                className="invalid-feedback-custom"
                // style={
                //   this.state.BESS.soc_min.toString() !== ""
                //     ? { display: "none" }
                //     : {}
                // }
              >
                Minimum SoC of the battery(%) is required.
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
          <div className="form-group row">
            <label htmlFor="inputEmail11" className="col-sm-5 col-form-label">
              Period of BESS (Day)
              <span className="se-mandatory">*</span>
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-md"
                name="cycle_period"
                placeholder="Period of BESS (Day)"
                maxLength="3"
                //value={this.state.BESS.cycle_period}
                //onChange={e => this.handleChangeBESS(e)}
              />
              <div
                className="invalid-feedback-custom"
                // style={
                //   this.state.BESS.cycle_period.toString() !== ""
                //     ? { display: "none" }
                //     : {}
                // }
              >
                Period of BESS (Day) is required.
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
          <div className="form-group row">
            <label htmlFor="inputEmail11" className="col-sm-5 col-form-label">
              SoC Hysteresis(%)
              <span className="se-mandatory">*</span>
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-md"
                name="soc_hysteresis"
                placeholder="SoC Hysteresis(%)"
                maxLength="3"
                // value={this.state.BESS.soc_hysteresis}
                //onChange={e => this.handleChangeBESS(e)}
              />
              <div
                className="invalid-feedback-custom"
                // style={
                //   this.state.BESS.soc_hysteresis.toString() !==
                //   ""
                //     ? { display: "none" }
                //     : {}
                // }
              >
                SoC Hysteresis(%) is required.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-1">
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
          <div className="form-group row">
            <label htmlFor="inputEmail11" className="col-sm-5 col-form-label">
              SoC Emergency(%)
              <span className="se-mandatory">*</span>
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-md"
                name="soc_emergency"
                placeholder="SoC Emergency(%)"
                maxLength="3"
                // value={this.state.BESS.soc_emergency}
                //onChange={e => this.handleChangeBESS(e)}
              />
              <div
                className="invalid-feedback-custom"
                // style={
                //   this.state.BESS.soc_emergency.toString() !==
                //   ""
                //     ? { display: "none" }
                //     : {}
                // }
              >
                SoC Emergency(%) is required.
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 ">
          <div className="form-group row">
            <label htmlFor="inputEmail11" className="col-sm-5 col-form-label">
              SoC Essential(%)
              <span className="se-mandatory">*</span>
            </label>
            <div className="col-sm-7">
              <input
                type="text"
                autoComplete="none"
                className="form-control form-control-md"
                name="soc_essential"
                placeholder="SoC Essential(%)"
                maxLength="3"
                // value={this.state.BESS.soc_essential}
                //onChange={e => this.handleChangeBESS(e)}
              />
              <div
                className="invalid-feedback-custom"
                // style={
                //   this.state.BESS.soc_essential.toString() !==
                //   ""
                //     ? { display: "none" }
                //     : {}
                // }
              >
                SoC Essential(%) is required.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BessManagement;
